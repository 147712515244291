import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [40.798607, -73.954538, 8.2],
  [40.798722, -73.954747, 8.8],
  [40.798829, -73.954934, 9.2],
  [40.798931, -73.955138, 9.8],
  [40.79903, -73.955337, 10.2],
  [40.799122, -73.955546, 10.8],
  [40.79921, -73.955766, 11.6],
  [40.799274, -73.955986, 12.4],
  [40.799328, -73.9562, 13.4],
  [40.799371, -73.956431, 14.6],
  [40.799409, -73.956651, 15.6],
  [40.799443, -73.956866, 16.8],
  [40.799478, -73.957086, 18],
  [40.799527, -73.9573, 19],
  [40.79958, -73.957504, 20],
  [40.799618, -73.957719, 21],
  [40.799596, -73.957933, 21.4],
  [40.799513, -73.958126, 22.2],
  [40.799387, -73.958271, 23],
  [40.799245, -73.958384, 23.8],
  [40.799097, -73.95848, 24.4],
];

export default stream;
