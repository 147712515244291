import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [51.496444, -0.073105, 26.2],
  [51.496616, -0.073014, 26],
  [51.496788, -0.072979, 25.6],
  [51.496954, -0.073026, 24.8],
  [51.497104, -0.07315, 24.2],
  [51.497238, -0.07331, 23.6],
  [51.497362, -0.073491, 23],
  [51.497474, -0.073684, 22.2],
  [51.497576, -0.073889, 21.6],
  [51.497662, -0.07411, 20.6],
  [51.49771, -0.074362, 19.6],
  [51.497726, -0.074629, 18.6],
  [51.497732, -0.074896, 17.4],
  [51.497764, -0.075159, 16.2],
  [51.497812, -0.075412, 15.2],
  [51.497882, -0.075661, 13.8],
  [51.497995, -0.075869, 12.6],
  [51.498134, -0.076026, 11.6],
];

export default stream;
