import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.74794, 165.854802, 130.6],
  [-10.748134, 165.85477, 130.4],
  [-10.748317, 165.854727, 130.4],
  [-10.748489, 165.85463, 130.2],
  [-10.748655, 165.854523, 130.2],
  [-10.748831, 165.854448, 130.4],
  [-10.74902, 165.85447, 130.8],
  [-10.749199, 165.854512, 131.4],
  [-10.749384, 165.854545, 131.8],
  [-10.749566, 165.854577, 132.2],
  [-10.749721, 165.854652, 132.4],
  [-10.749783, 165.854834, 131.6],
  [-10.749797, 165.855017, 130.8],
  [-10.749787, 165.855199, 130],
  [-10.749741, 165.855392, 129.6],
  [-10.749624, 165.855553, 130.2],
  [-10.749478, 165.85566, 130.8],
  [-10.749313, 165.855746, 131.6],
  [-10.74918, 165.855854, 132.4],
  [-10.749187, 165.856036, 133],
  [-10.749244, 165.856208, 133.6],
  [-10.74929, 165.856369, 134.2],
  [-10.749268, 165.856551, 134.8],
  [-10.749197, 165.856712, 135.6],
  [-10.749121, 165.856862, 136.4],
  [-10.749103, 165.857034, 137.4],
  [-10.74916, 165.857184, 138.2],
  [-10.749277, 165.857302, 139],
  [-10.749433, 165.857345, 139.6],
  [-10.749598, 165.857334, 140.2],
  [-10.749749, 165.85727, 140.8],
  [-10.749889, 165.857184, 141.4],
  [-10.750042, 165.85712, 142],
  [-10.750187, 165.857195, 143.2],
  [-10.750288, 165.857323, 144.2],
  [-10.750377, 165.857452, 145.2],
  [-10.750474, 165.857581, 146],
  [-10.750622, 165.857592, 146.8],
  [-10.750731, 165.857484, 147.8],
  [-10.750797, 165.857334, 149.4],
  [-10.750873, 165.857216, 150.8],
  [-10.751013, 165.857152, 151.4],
];

export default stream;
