import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.647114, 166.949165, 10.4],
  [-11.646952, 166.949058, 10.4],
  [-11.646791, 166.94895, 10.4],
  [-11.646633, 166.948843, 10.4],
  [-11.646475, 166.948736, 10.4],
  [-11.646315, 166.948628, 10.4],
  [-11.646154, 166.948521, 10.4],
  [-11.645997, 166.948414, 10.4],
  [-11.645836, 166.948296, 10.4],
  [-11.645678, 166.948189, 10.4],
  [-11.645537, 166.94806, 10.2],
  [-11.645438, 166.947899, 9.8],
  [-11.645274, 166.947824, 10],
  [-11.64513, 166.947942, 11.2],
  [-11.645029, 166.948092, 12.2],
  [-11.644943, 166.948264, 13.4],
  [-11.64486, 166.948414, 14.6],
  [-11.64478, 166.948586, 15.6],
  [-11.644702, 166.948736, 16.8],
  [-11.644623, 166.948907, 17.8],
  [-11.644546, 166.949036, 18.8],
  [-11.644464, 166.949197, 19.8],
  [-11.644376, 166.949347, 20.6],
  [-11.644285, 166.949487, 21.4],
  [-11.64418, 166.949615, 22.2],
  [-11.644057, 166.949723, 22.8],
  [-11.643913, 166.949819, 23],
  [-11.643748, 166.949862, 23],
  [-11.643579, 166.949894, 22.6],
  [-11.643398, 166.949905, 22],
  [-11.643217, 166.949894, 21.2],
  [-11.643033, 166.949852, 20.4],
  [-11.64285, 166.949798, 19.2],
  [-11.642685, 166.949691, 18],
  [-11.64261, 166.949508, 16.6],
  [-11.642623, 166.949304, 15.2],
  [-11.642678, 166.9491, 14],
  [-11.64276, 166.948907, 13.2],
  [-11.642886, 166.948736, 12.8],
  [-11.643058, 166.948618, 13],
  [-11.643239, 166.948521, 13.4],
  [-11.643409, 166.948414, 14],
  [-11.643441, 166.948231, 14.8],
  [-11.643324, 166.948081, 15.4],
  [-11.643184, 166.947942, 15.8],
  [-11.643039, 166.947824, 15.8],
  [-11.642877, 166.947716, 13.8],
  [-11.642717, 166.947609, 14.4],
  [-11.642545, 166.94762, 15.4],
  [-11.642421, 166.94777, 15.2],
  [-11.642288, 166.94791, 14.8],
  [-11.642105, 166.947974, 14.8],
  [-11.641942, 166.948049, 14.8],
  [-11.641891, 166.948242, 14.6],
  [-11.641844, 166.948425, 14.2],
  [-11.641745, 166.948596, 13.4],
  [-11.641621, 166.948768, 12.4],
  [-11.641458, 166.948886, 11.6],
  [-11.641262, 166.948907, 12.6],
  [-11.641077, 166.948843, 14.2],
  [-11.640932, 166.948714, 15.2],
  [-11.640829, 166.948564, 15.4],
  [-11.640767, 166.948382, 15.4],
  [-11.640798, 166.948199, 14.8],
  [-11.640952, 166.948092, 13.4],
  [-11.64113, 166.948006, 12.2],
  [-11.641258, 166.947845, 11.6],
  [-11.641236, 166.947652, 12.4],
  [-11.641208, 166.94747, 13.6],
  [-11.64131, 166.947298, 14.4],
  [-11.641476, 166.947223, 14.8],
  [-11.641652, 166.947148, 15],
  [-11.64181, 166.947051, 15.4],
  [-11.641816, 166.94689, 15.2],
  [-11.641664, 166.946783, 15.4],
  [-11.641535, 166.946644, 15.6],
  [-11.641572, 166.946461, 15.6],
  [-11.641537, 166.94629, 15],
  [-11.641362, 166.946214, 14.4],
  [-11.64118, 166.946161, 14],
  [-11.640987, 166.946107, 13.6],
  [-11.640795, 166.946107, 13.2],
];

export default stream;
