import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.63941, 166.954776, 36.2],
  [-11.639547, 166.954851, 35.8],
  [-11.639618, 166.955001, 36.2],
  [-11.639622, 166.955173, 37.2],
  [-11.639587, 166.955323, 38.6],
  [-11.639539, 166.955484, 39.8],
  [-11.639485, 166.955645, 40.8],
  [-11.63943, 166.955785, 42],
  [-11.639371, 166.955935, 43.2],
  [-11.639315, 166.956085, 44.4],
  [-11.639257, 166.956246, 45.4],
  [-11.639199, 166.956375, 46.6],
  [-11.63914, 166.956536, 47.8],
  [-11.639084, 166.956686, 48.8],
  [-11.639033, 166.956825, 50],
  [-11.638983, 166.956975, 51],
  [-11.63894, 166.957126, 52.2],
  [-11.638918, 166.957276, 53.4],
  [-11.63894, 166.957437, 54.4],
  [-11.639072, 166.957512, 54.4],
  [-11.63923, 166.957523, 54.4],
  [-11.639391, 166.957566, 54.8],
  [-11.639551, 166.957619, 55.6],
  [-11.639713, 166.95763, 56.4],
  [-11.639878, 166.957641, 57],
  [-11.640044, 166.957673, 57.6],
  [-11.640202, 166.957737, 57.8],
  [-11.640355, 166.957812, 58],
  [-11.640503, 166.957909, 57.8],
  [-11.640641, 166.958016, 57.6],
  [-11.64078, 166.958123, 57.2],
  [-11.640916, 166.958241, 56.8],
  [-11.641055, 166.958381, 56.2],
  [-11.641193, 166.958499, 55.6],
  [-11.641334, 166.958638, 55.2],
  [-11.641476, 166.958756, 54.6],
  [-11.641624, 166.958896, 54],
  [-11.641777, 166.959014, 53.6],
  [-11.641938, 166.959143, 53],
  [-11.642108, 166.95925, 52.8],
  [-11.642289, 166.959325, 52.8],
  [-11.642478, 166.959389, 52.8],
  [-11.642674, 166.959389, 53.2],
  [-11.642869, 166.959389, 53.4],
  [-11.643062, 166.959368, 53.6],
  [-11.643256, 166.959368, 53.8],
  [-11.643445, 166.959368, 54],
  [-11.643636, 166.959357, 54.2],
  [-11.64383, 166.959336, 54.4],
  [-11.644018, 166.959325, 54.6],
  [-11.644206, 166.959304, 54.6],
  [-11.644393, 166.959261, 54.8],
  [-11.644578, 166.959229, 55],
  [-11.644758, 166.959175, 55],
  [-11.644926, 166.9591, 55.2],
  [-11.645081, 166.958971, 55],
  [-11.645211, 166.958832, 54.8],
  [-11.645324, 166.958681, 54.4],
  [-11.645418, 166.95852, 54.2],
  [-11.645488, 166.958327, 53.8],
  [-11.645537, 166.958134, 53.4],
  [-11.64556, 166.95793, 53],
  [-11.645548, 166.957737, 52.8],
  [-11.645473, 166.957555, 52.8],
  [-11.645354, 166.957405, 52.8],
  [-11.645215, 166.957265, 52.8],
  [-11.645075, 166.957126, 53],
  [-11.644934, 166.956986, 53],
  [-11.644805, 166.956836, 53],
  [-11.644695, 166.956686, 52.8],
  [-11.644612, 166.956503, 52.4],
  [-11.644566, 166.956321, 51.8],
  [-11.64455, 166.956117, 51],
  [-11.64455, 166.955903, 50.2],
  [-11.64456, 166.955709, 49.4],
  [-11.644576, 166.955506, 48.4],
  [-11.644595, 166.955291, 47.4],
  [-11.644612, 166.955087, 46.6],
  [-11.644627, 166.954873, 45.6],
  [-11.644625, 166.954658, 44.8],
  [-11.644586, 166.954454, 44],
  [-11.644431, 166.954315, 44.2],
  [-11.644228, 166.954368, 45.2],
  [-11.644049, 166.954443, 46.4],
  [-11.643872, 166.954529, 47.6],
  [-11.643699, 166.954604, 48.6],
  [-11.64352, 166.954637, 49.6],
  [-11.643338, 166.954626, 50.2],
  [-11.64318, 166.954529, 50.4],
  [-11.643044, 166.954411, 50.4],
  [-11.642919, 166.954272, 50.4],
  [-11.642803, 166.954122, 50.2],
  [-11.642685, 166.953971, 50],
  [-11.642556, 166.953832, 49.6],
  [-11.642411, 166.953714, 49.4],
  [-11.642236, 166.953628, 49],
  [-11.64205, 166.953574, 48.4],
  [-11.641856, 166.953542, 47.6],
  [-11.641658, 166.953521, 47],
  [-11.641464, 166.953499, 46.6],
  [-11.641266, 166.953446, 46.2],
  [-11.641074, 166.953403, 45.8],
  [-11.640878, 166.95336, 45.6],
  [-11.640681, 166.953317, 45.4],
  [-11.640487, 166.953263, 45],
  [-11.640294, 166.95322, 44.8],
  [-11.640096, 166.953156, 44.4],
  [-11.639902, 166.953113, 44],
  [-11.63971, 166.95307, 43.2],
  [-11.639512, 166.953006, 42],
  [-11.639317, 166.952941, 40.6],
];

export default stream;
