import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-21.738395, 166.176775, 13],
  [-21.738471, 166.176592, 13],
  [-21.738544, 166.176431, 13],
  [-21.738604, 166.17626, 13],
  [-21.738666, 166.176088, 13],
  [-21.738722, 166.175938, 13],
  [-21.738776, 166.175787, 13],
  [-21.738827, 166.175648, 13],
  [-21.738871, 166.175498, 13],
  [-21.738894, 166.175358, 13],
  [-21.738883, 166.175219, 13],
  [-21.738833, 166.175112, 13],
  [-21.73877, 166.175026, 13],
  [-21.738715, 166.174972, 13],
  [-21.738671, 166.174918, 13],
  [-21.738627, 166.174865, 13],
  [-21.738583, 166.174822, 13],
  [-21.73854, 166.174779, 13],
  [-21.738495, 166.174736, 13],
  [-21.73845, 166.174672, 13],
  [-21.738406, 166.174629, 13],
  [-21.738359, 166.174564, 13],
  [-21.738312, 166.174521, 13],
  [-21.738265, 166.174468, 13],
  [-21.738216, 166.174414, 13],
  [-21.738169, 166.174371, 13],
  [-21.738131, 166.174318, 13],
  [-21.738106, 166.174285, 13],
  [-21.738094, 166.174275, 13],
  [-21.738093, 166.174275, 13],
  [-21.738089, 166.174275, 13],
  [-21.738078, 166.174264, 13],
  [-21.738072, 166.174264, 13],
  [-21.738062, 166.174264, 13],
  [-21.738062, 166.174242, 13],
  [-21.738057, 166.174242, 13],
  [-21.738054, 166.174242, 13],
  [-21.738047, 166.174242, 13],
  [-21.738047, 166.174242, 13],
  [-21.738038, 166.174232, 13],
  [-21.738021, 166.17421, 13],
  [-21.737999, 166.174178, 13],
  [-21.737976, 166.174157, 13],
  [-21.73795, 166.174124, 13],
  [-21.737921, 166.174092, 13],
  [-21.737885, 166.174049, 13],
  [-21.737836, 166.173985, 13],
  [-21.737777, 166.17391, 13],
  [-21.737715, 166.173813, 13],
  [-21.737665, 166.173706, 13],
  [-21.737642, 166.173588, 13],
  [-21.73766, 166.173449, 13],
  [-21.737709, 166.173309, 13],
  [-21.737782, 166.17318, 13],
  [-21.73787, 166.173052, 13],
  [-21.737962, 166.172934, 13],
  [-21.738054, 166.172794, 13],
  [-21.738147, 166.172655, 13],
  [-21.738239, 166.172515, 13],
  [-21.738336, 166.172386, 13],
  [-21.738431, 166.172247, 13],
  [-21.738528, 166.172097, 13],
  [-21.738627, 166.171957, 13],
  [-21.738726, 166.171807, 13],
  [-21.738825, 166.171646, 13],
  [-21.738925, 166.171496, 13],
  [-21.739018, 166.171346, 13],
  [-21.739102, 166.171196, 13],
  [-21.739173, 166.171013, 13],
  [-21.739233, 166.170841, 13],
  [-21.739295, 166.170659, 13],
  [-21.73935, 166.170487, 13],
  [-21.739389, 166.170305, 13],
  [-21.739417, 166.170123, 13],
  [-21.739438, 166.16993, 13],
  [-21.739452, 166.169747, 13],
  [-21.739461, 166.169543, 13],
  [-21.739465, 166.169361, 13],
  [-21.739468, 166.169178, 13],
  [-21.73947, 166.168975, 13],
  [-21.739472, 166.168792, 13],
  [-21.73947, 166.168599, 13],
  [-21.739466, 166.168417, 13],
  [-21.739454, 166.168213, 13],
  [-21.739415, 166.168031, 13],
  [-21.739362, 166.167848, 13],
  [-21.739303, 166.167666, 13],
  [-21.73923, 166.167505, 13],
  [-21.739151, 166.167333, 13],
  [-21.739077, 166.167151, 13],
  [-21.739001, 166.166979, 13],
  [-21.738912, 166.166818, 13],
  [-21.738811, 166.166657, 13],
  [-21.738682, 166.166528, 13],
  [-21.738537, 166.166421, 13],
  [-21.738385, 166.166314, 13],
  [-21.738242, 166.166207, 13],
  [-21.738131, 166.166046, 13],
  [-21.738076, 166.165863, 13],
  [-21.738058, 166.16567, 13],
  [-21.738053, 166.165488, 13],
  [-21.738053, 166.165305, 13],
  [-21.738057, 166.165102, 13],
  [-21.738062, 166.164919, 13],
  [-21.738058, 166.164726, 13],
  [-21.738035, 166.164544, 13],
  [-21.738009, 166.16434, 13],
  [-21.738013, 166.164157, 13],
  [-21.738013, 166.163964, 13],
  [-21.738002, 166.163782, 13],
  [-21.738013, 166.163578, 13],
  [-21.738053, 166.163396, 13],
  [-21.738129, 166.163213, 13],
  [-21.738237, 166.163063, 13],
  [-21.738368, 166.162934, 13],
  [-21.738509, 166.162816, 13],
  [-21.738643, 166.162698, 13],
  [-21.738772, 166.162559, 13],
  [-21.738903, 166.16243, 13],
  [-21.739037, 166.162301, 13],
  [-21.739177, 166.162173, 13],
  [-21.739317, 166.162065, 13],
  [-21.739453, 166.161947, 13],
  [-21.739579, 166.161808, 13],
  [-21.739693, 166.161658, 13],
  [-21.739828, 166.161529, 13],
  [-21.739941, 166.161379, 13],
  [-21.740036, 166.161228, 13],
  [-21.740136, 166.161057, 13],
  [-21.740252, 166.160928, 13],
  [-21.740393, 166.160799, 13],
  [-21.740547, 166.160713, 13],
  [-21.740706, 166.160617, 13],
  [-21.740861, 166.160531, 13],
  [-21.74101, 166.160424, 13],
  [-21.741137, 166.160284, 13],
  [-21.741233, 166.160113, 13],
  [-21.741304, 166.159952, 13],
  [-21.74136, 166.159759, 13],
  [-21.741376, 166.159565, 13],
  [-21.741399, 166.159383, 13],
  [-21.741435, 166.159201, 13],
  [-21.741486, 166.159018, 13],
  [-21.741564, 166.158847, 12.8],
  [-21.741672, 166.158675, 12.8],
  [-21.741789, 166.158546, 12.6],
  [-21.741906, 166.158385, 12.6],
  [-21.742018, 166.158235, 12.6],
  [-21.742131, 166.158096, 12.6],
  [-21.742245, 166.157945, 12.8],
  [-21.742359, 166.157795, 12.8],
  [-21.742472, 166.157656, 12.8],
  [-21.742587, 166.157506, 12.8],
  [-21.742701, 166.157355, 12.8],
  [-21.742814, 166.157216, 12.8],
  [-21.742928, 166.157066, 12.8],
  [-21.743044, 166.156926, 12.8],
  [-21.743162, 166.156776, 12.6],
  [-21.743275, 166.156636, 12.6],
  [-21.743388, 166.156486, 12.6],
  [-21.743506, 166.156325, 12.6],
  [-21.743641, 166.156207, 12.6],
  [-21.743798, 166.156121, 12.8],
  [-21.743958, 166.156036, 13],
  [-21.744092, 166.155918, 13],
  [-21.744229, 166.1558, 13],
  [-21.744349, 166.15566, 13],
  [-21.744386, 166.155478, 13],
  [-21.74438, 166.155295, 13],
  [-21.744355, 166.155092, 13],
  [-21.744272, 166.154941, 13],
  [-21.744109, 166.154855, 13],
  [-21.743938, 166.154802, 13],
  [-21.743762, 166.15477, 13],
  [-21.743585, 166.154759, 13],
  [-21.743443, 166.154641, 13],
  [-21.743425, 166.154459, 13],
  [-21.743488, 166.154287, 13],
  [-21.743531, 166.154083, 13],
  [-21.743474, 166.153922, 13],
  [-21.743366, 166.153772, 13],
  [-21.743254, 166.153611, 13],
  [-21.743148, 166.153461, 13],
  [-21.743026, 166.153321, 13],
  [-21.742885, 166.153203, 13],
  [-21.742772, 166.153064, 13],
  [-21.742716, 166.152881, 13],
  [-21.742693, 166.152688, 13],
  [-21.742692, 166.152495, 13],
  [-21.742709, 166.152302, 13],
  [-21.74276, 166.15212, 13],
  [-21.742878, 166.15198, 13],
  [-21.743046, 166.151916, 13],
  [-21.743225, 166.151916, 13],
  [-21.7434, 166.151969, 13],
  [-21.743565, 166.152045, 13],
  [-21.743726, 166.15212, 13],
  [-21.743888, 166.152205, 13],
  [-21.744052, 166.152291, 13],
  [-21.744227, 166.152313, 13],
  [-21.7444, 166.152291, 13],
  [-21.744544, 166.152163, 13],
  [-21.744658, 166.152023, 13],
  [-21.744756, 166.151862, 13],
  [-21.744825, 166.15169, 13],
  [-21.744875, 166.151508, 13],
  [-21.744891, 166.151315, 13],
  [-21.744833, 166.151143, 13],
  [-21.744706, 166.151004, 13],
  [-21.744541, 166.150929, 13],
  [-21.74438, 166.150854, 13],
  [-21.744265, 166.150703, 13],
  [-21.744258, 166.150521, 13],
  [-21.74432, 166.150339, 13],
  [-21.744399, 166.150167, 13],
  [-21.744469, 166.149985, 13],
  [-21.7445, 166.149802, 13],
  [-21.744462, 166.14962, 13],
  [-21.744383, 166.149448, 13],
  [-21.744286, 166.149276, 13],
  [-21.744179, 166.149126, 13],
  [-21.744056, 166.148987, 13],
  [-21.743889, 166.148933, 13],
  [-21.743718, 166.148976, 13],
  [-21.743569, 166.149062, 13],
  [-21.743409, 166.149158, 13],
  [-21.743231, 166.149191, 13],
  [-21.743054, 166.149201, 13],
  [-21.742877, 166.149169, 13],
  [-21.742733, 166.149062, 13],
  [-21.742634, 166.148901, 13],
  [-21.742531, 166.148751, 13],
  [-21.742363, 166.148697, 13],
  [-21.742186, 166.148719, 13],
  [-21.74202, 166.148654, 13],
  [-21.741887, 166.148515, 13],
  [-21.741765, 166.148375, 13],
  [-21.741621, 166.148279, 13],
  [-21.741446, 166.148247, 13],
  [-21.741296, 166.148354, 13],
  [-21.741204, 166.148504, 13],
  [-21.741132, 166.148686, 13],
  [-21.741038, 166.148858, 13],
  [-21.740876, 166.148912, 13],
  [-21.740699, 166.148901, 13],
  [-21.740524, 166.148944, 13],
  [-21.740357, 166.149008, 13],
  [-21.740188, 166.149083, 13],
  [-21.740022, 166.149148, 13],
  [-21.739855, 166.149223, 13],
  [-21.739693, 166.149309, 13],
  [-21.739543, 166.149405, 13],
  [-21.739425, 166.149545, 13],
  [-21.739385, 166.149727, 13],
  [-21.739426, 166.149909, 13],
  [-21.739543, 166.15006, 13],
  [-21.739701, 166.150146, 13],
  [-21.739855, 166.150242, 13],
  [-21.739959, 166.150392, 13],
  [-21.739991, 166.150575, 13],
  [-21.739987, 166.150779, 13],
  [-21.739946, 166.150961, 13],
  [-21.739838, 166.151111, 13],
  [-21.739669, 166.151154, 13],
  [-21.739489, 166.151154, 13],
  [-21.739309, 166.151154, 13],
  [-21.739136, 166.151186, 13],
  [-21.739033, 166.151336, 13],
  [-21.739016, 166.151519, 13],
  [-21.739002, 166.151723, 13],
  [-21.738921, 166.151894, 13],
  [-21.73879, 166.152012, 13],
  [-21.738639, 166.15212, 13],
  [-21.738477, 166.152195, 13.2],
  [-21.738309, 166.152259, 13.4],
  [-21.738139, 166.152313, 13.6],
  [-21.737971, 166.152366, 13.8],
  [-21.737803, 166.15242, 14],
  [-21.737638, 166.152484, 14.2],
  [-21.737492, 166.152592, 14.2],
  [-21.737331, 166.152667, 14.2],
  [-21.737162, 166.15271, 14.2],
  [-21.736991, 166.152763, 14.2],
  [-21.736819, 166.152806, 14.2],
  [-21.736647, 166.152849, 14.2],
  [-21.736476, 166.152892, 14.2],
  [-21.736304, 166.152924, 14.2],
  [-21.736132, 166.152978, 14.2],
  [-21.735958, 166.153021, 14.2],
  [-21.735786, 166.153064, 14.2],
  [-21.735613, 166.153096, 14.2],
  [-21.73544, 166.153139, 14.2],
  [-21.735269, 166.153182, 14.2],
  [-21.735097, 166.153214, 14.2],
  [-21.734925, 166.153268, 14.2],
  [-21.734751, 166.153311, 14.2],
  [-21.734575, 166.153321, 14.2],
  [-21.734406, 166.153268, 14],
  [-21.734252, 166.153171, 13.6],
  [-21.73408, 166.153107, 13.2],
  [-21.733898, 166.153096, 13],
  [-21.733717, 166.153107, 13],
  [-21.733536, 166.153128, 13.2],
  [-21.733358, 166.15316, 13],
  [-21.733175, 166.153139, 12.8],
  [-21.732994, 166.153128, 12.4],
  [-21.732813, 166.153096, 12.2],
  [-21.732631, 166.153096, 12.4],
  [-21.73245, 166.153107, 13],
  [-21.73227, 166.153107, 13.2],
  [-21.732093, 166.153128, 13],
  [-21.731928, 166.153203, 13],
  [-21.731782, 166.153311, 13],
  [-21.731633, 166.153418, 13],
  [-21.731471, 166.153493, 13],
  [-21.731318, 166.153579, 13],
  [-21.731173, 166.153708, 13],
  [-21.731028, 166.153815, 13],
  [-21.730879, 166.153922, 13],
  [-21.730722, 166.154008, 13],
  [-21.730563, 166.154104, 13.2],
  [-21.7304, 166.15418, 13.2],
  [-21.730234, 166.154244, 13.2],
  [-21.730065, 166.154298, 13.4],
  [-21.729891, 166.154351, 13.6],
  [-21.729714, 166.154351, 13.8],
  [-21.729542, 166.154298, 14],
  [-21.729383, 166.154222, 14.4],
  [-21.729239, 166.154115, 14.6],
  [-21.729145, 166.153965, 14.6],
  [-21.729045, 166.153815, 14.6],
  [-21.728881, 166.15375, 14.8],
  [-21.728709, 166.15374, 15.4],
  [-21.728535, 166.15374, 16],
  [-21.728366, 166.15375, 16.4],
  [-21.728197, 166.153783, 16.6],
  [-21.728032, 166.153826, 16.4],
  [-21.727879, 166.153901, 15.8],
  [-21.727725, 166.153997, 15.2],
  [-21.727564, 166.154072, 14.2],
  [-21.727386, 166.154115, 13.6],
  [-21.727203, 166.154083, 13.4],
  [-21.727034, 166.154008, 13.4],
  [-21.726876, 166.153901, 13.4],
  [-21.726725, 166.153793, 13.6],
  [-21.726577, 166.153686, 13.6],
  [-21.726428, 166.153568, 13.8],
  [-21.726283, 166.153461, 14.2],
  [-21.726148, 166.153343, 14.4],
  [-21.726012, 166.153214, 14.8],
  [-21.725865, 166.153107, 14.8],
  [-21.725708, 166.153021, 14.8],
  [-21.725547, 166.152946, 14.6],
  [-21.725385, 166.152871, 14.4],
  [-21.72522, 166.152796, 14],
  [-21.725051, 166.15271, 13.4],
  [-21.724874, 166.152667, 13],
  [-21.724691, 166.152624, 12.6],
  [-21.724513, 166.152592, 12.2],
  [-21.724332, 166.152549, 12],
  [-21.724156, 166.152484, 12],
  [-21.723989, 166.152399, 12.4],
  [-21.723826, 166.152302, 13],
  [-21.723669, 166.152205, 13.4],
  [-21.72351, 166.15213, 13.8],
  [-21.723345, 166.152077, 14],
  [-21.723177, 166.152012, 13.8],
  [-21.723008, 166.151948, 13.6],
  [-21.722837, 166.151905, 13.2],
  [-21.722661, 166.151862, 13],
  [-21.722481, 166.15183, 12.8],
  [-21.722304, 166.151862, 13],
  [-21.722134, 166.151916, 13.4],
  [-21.721956, 166.151905, 13.4],
  [-21.721777, 166.151873, 13.2],
  [-21.721601, 166.151841, 13.2],
  [-21.721424, 166.151809, 13],
  [-21.721247, 166.151787, 13],
  [-21.72107, 166.151755, 13],
  [-21.720895, 166.151723, 13],
  [-21.720732, 166.151648, 13],
  [-21.720588, 166.151519, 13],
  [-21.720429, 166.151444, 13],
  [-21.720254, 166.151401, 13],
  [-21.720074, 166.15139, 13],
  [-21.719897, 166.151422, 13],
  [-21.719736, 166.151497, 13.2],
  [-21.719591, 166.151605, 13.6],
  [-21.719458, 166.151723, 13.8],
  [-21.719332, 166.151862, 14.2],
  [-21.71922, 166.152002, 14.6],
  [-21.719102, 166.15213, 14.8],
  [-21.718954, 166.152238, 14.8],
  [-21.718809, 166.152334, 14.6],
  [-21.718706, 166.152484, 14],
  [-21.718623, 166.152667, 13.4],
  [-21.718525, 166.152817, 13],
  [-21.718388, 166.152956, 13.6],
  [-21.718245, 166.153064, 14.2],
  [-21.718113, 166.153182, 14.2],
  [-21.717986, 166.153321, 14.2],
  [-21.717883, 166.153482, 14],
  [-21.717806, 166.153643, 13.6],
  [-21.717742, 166.153826, 13.2],
  [-21.717661, 166.153997, 13],
  [-21.717545, 166.154147, 13],
  [-21.717402, 166.154265, 13],
  [-21.71725, 166.154373, 13],
  [-21.717093, 166.154469, 13],
  [-21.716928, 166.154544, 13],
  [-21.716752, 166.154587, 13],
  [-21.716572, 166.154609, 13],
  [-21.716393, 166.154609, 13],
  [-21.716212, 166.154609, 13],
  [-21.716035, 166.154577, 13],
  [-21.715863, 166.154512, 13],
  [-21.715695, 166.154459, 13],
  [-21.715534, 166.154362, 13],
  [-21.715375, 166.154265, 12.8],
  [-21.715203, 166.154222, 12.8],
  [-21.715022, 166.154212, 12.4],
  [-21.714846, 166.154244, 12],
  [-21.714679, 166.154319, 11.6],
  [-21.714524, 166.154426, 11.4],
  [-21.714372, 166.154534, 11.2],
  [-21.714224, 166.154652, 11],
  [-21.714111, 166.154802, 10.8],
  [-21.714059, 166.154984, 10.6],
  [-21.714047, 166.155188, 10.4],
  [-21.714075, 166.155381, 10.8],
  [-21.714148, 166.155564, 11.4],
  [-21.714232, 166.155735, 12],
  [-21.714296, 166.155907, 12.6],
  [-21.714368, 166.156068, 13],
  [-21.714461, 166.15624, 13.2],
  [-21.714549, 166.15639, 13],
  [-21.714593, 166.156572, 13],
  [-21.714613, 166.156754, 13],
  [-21.714626, 166.156958, 13],
  [-21.71464, 166.157141, 13],
  [-21.714616, 166.157323, 13],
  [-21.714545, 166.157495, 13],
  [-21.714448, 166.157656, 13],
  [-21.714327, 166.157795, 13],
  [-21.714169, 166.15787, 13],
  [-21.713996, 166.157913, 13],
  [-21.713849, 166.15802, 13],
  [-21.713748, 166.158181, 13],
  [-21.713663, 166.158342, 13],
  [-21.713586, 166.158514, 13],
  [-21.713507, 166.158696, 13],
  [-21.713438, 166.158868, 13],
  [-21.713364, 166.15905, 13],
  [-21.713273, 166.159201, 13],
  [-21.713179, 166.159362, 13],
  [-21.713091, 166.159533, 13],
  [-21.712981, 166.159683, 13],
  [-21.71282, 166.159759, 13],
  [-21.712643, 166.15978, 13],
  [-21.712465, 166.159791, 13],
  [-21.712285, 166.159791, 13],
  [-21.712108, 166.159791, 13],
  [-21.711928, 166.159791, 13],
  [-21.711748, 166.159791, 13],
  [-21.71157, 166.159791, 13],
  [-21.71139, 166.159791, 13],
  [-21.711215, 166.159791, 13],
  [-21.711035, 166.159791, 13],
  [-21.710858, 166.159791, 13],
  [-21.710678, 166.15978, 13],
  [-21.7105, 166.15978, 13],
  [-21.71032, 166.15978, 13],
  [-21.71014, 166.159759, 13],
  [-21.709963, 166.159759, 13],
  [-21.709793, 166.159823, 13],
  [-21.709723, 166.159995, 13],
  [-21.709718, 166.160177, 13],
  [-21.709719, 166.16037, 13],
  [-21.709768, 166.160553, 13],
  [-21.709914, 166.16066, 13.2],
  [-21.710061, 166.160767, 13.2],
  [-21.71014, 166.160939, 13.8],
  [-21.710244, 166.161089, 14.6],
  [-21.710325, 166.161239, 15.4],
  [-21.710362, 166.161422, 16.2],
  [-21.710411, 166.161593, 16.2],
  [-21.710469, 166.161765, 15.8],
  [-21.710503, 166.161947, 15],
  [-21.710533, 166.16213, 14.2],
  [-21.710574, 166.162323, 13.2],
  [-21.710591, 166.162505, 13],
  [-21.710583, 166.162709, 13],
  [-21.710572, 166.162902, 13],
  [-21.710587, 166.163106, 13],
  [-21.710694, 166.163256, 13],
  [-21.710835, 166.163385, 13],
  [-21.710975, 166.163503, 13],
  [-21.711094, 166.163653, 13],
  [-21.711227, 166.163782, 13],
  [-21.711365, 166.163911, 13],
  [-21.71149, 166.16405, 13],
  [-21.711618, 166.164168, 13],
  [-21.71176, 166.164297, 13],
  [-21.711848, 166.164469, 13],
  [-21.711869, 166.164662, 13],
  [-21.711866, 166.164844, 13],
  [-21.711856, 166.165048, 13],
  [-21.711797, 166.165209, 13],
  [-21.711652, 166.165316, 13],
  [-21.711473, 166.165348, 13],
  [-21.711295, 166.165348, 13],
  [-21.711115, 166.165348, 13],
  [-21.710936, 166.165359, 13],
  [-21.710759, 166.165359, 13],
  [-21.710579, 166.165359, 13],
  [-21.710401, 166.165348, 13],
  [-21.710221, 166.165359, 13],
  [-21.710041, 166.165359, 13],
  [-21.709863, 166.165359, 13],
  [-21.709683, 166.165348, 13],
  [-21.709506, 166.165338, 13],
  [-21.709326, 166.165338, 13],
  [-21.709147, 166.165348, 13],
  [-21.708968, 166.165348, 13],
  [-21.708789, 166.165359, 13],
  [-21.708612, 166.165348, 13],
  [-21.708432, 166.165348, 13],
  [-21.708283, 166.165456, 13],
  [-21.70822, 166.165638, 13],
  [-21.708228, 166.16582, 13],
  [-21.70827, 166.166003, 13],
  [-21.70831, 166.166185, 13],
  [-21.708287, 166.166389, 13],
  [-21.708216, 166.16655, 13],
  [-21.708099, 166.166711, 13],
  [-21.707957, 166.166818, 13],
  [-21.70783, 166.166947, 13],
  [-21.707722, 166.167108, 13],
  [-21.707596, 166.167237, 13],
  [-21.707453, 166.167344, 13],
  [-21.707304, 166.167451, 13],
  [-21.707139, 166.167526, 13],
  [-21.706961, 166.167548, 13],
  [-21.706782, 166.167548, 13],
  [-21.706603, 166.167558, 13],
  [-21.706426, 166.16758, 13],
  [-21.706246, 166.167591, 13],
  [-21.706066, 166.167601, 13],
  [-21.705888, 166.167623, 13],
  [-21.705709, 166.167634, 13],
  [-21.705532, 166.167666, 13.4],
  [-21.705361, 166.167698, 13.6],
  [-21.705188, 166.16773, 14],
  [-21.705013, 166.167762, 14.2],
  [-21.704842, 166.167794, 14.6],
  [-21.704674, 166.167837, 14.8],
  [-21.704508, 166.167902, 15],
  [-21.704347, 166.167955, 15.2],
  [-21.704177, 166.167998, 15],
  [-21.704004, 166.16802, 15],
  [-21.703833, 166.168063, 15.2],
  [-21.703667, 166.168127, 15.6],
  [-21.703502, 166.16817, 15.6],
  [-21.703333, 166.168234, 15.6],
  [-21.703165, 166.168288, 14.8],
  [-21.703006, 166.168385, 13.8],
  [-21.702866, 166.168503, 13.2],
  [-21.702731, 166.168642, 13],
  [-21.702599, 166.168782, 13],
  [-21.702468, 166.168921, 13],
  [-21.702328, 166.169039, 13],
  [-21.702165, 166.169136, 13],
  [-21.701991, 166.169178, 13],
  [-21.701815, 166.169221, 13],
  [-21.701638, 166.169275, 13],
  [-21.701462, 166.169318, 13],
  [-21.701286, 166.169361, 13],
  [-21.701113, 166.169425, 13],
  [-21.700939, 166.169468, 13],
  [-21.700766, 166.169511, 13],
  [-21.70059, 166.169565, 13],
  [-21.700413, 166.169608, 13],
  [-21.700236, 166.16964, 13],
  [-21.700062, 166.169683, 13],
  [-21.699886, 166.169726, 13],
  [-21.699713, 166.169769, 13],
  [-21.699538, 166.169822, 13],
  [-21.699389, 166.169908, 13],
  [-21.699283, 166.170058, 13],
  [-21.699235, 166.170251, 13],
  [-21.699247, 166.170444, 13],
  [-21.699275, 166.170627, 13],
  [-21.699306, 166.170831, 13],
  [-21.699307, 166.171013, 13],
  [-21.699231, 166.171174, 13],
  [-21.69909, 166.171303, 13],
  [-21.698933, 166.171389, 13],
  [-21.698775, 166.171464, 13],
  [-21.698619, 166.17156, 13],
  [-21.698463, 166.171646, 13],
  [-21.698315, 166.171753, 13],
  [-21.698172, 166.171861, 13],
  [-21.69805, 166.172, 13],
  [-21.697951, 166.172172, 13],
  [-21.697875, 166.172333, 13],
  [-21.697837, 166.172515, 13],
  [-21.69783, 166.172719, 13],
  [-21.697863, 166.172901, 13],
  [-21.697942, 166.173073, 13],
  [-21.698052, 166.173223, 13],
  [-21.698186, 166.173341, 13],
  [-21.698344, 166.173416, 13],
  [-21.698516, 166.17347, 13],
  [-21.698689, 166.173513, 13],
  [-21.698855, 166.173588, 13],
  [-21.699009, 166.173663, 13],
  [-21.699167, 166.17376, 13],
  [-21.699311, 166.173867, 13],
  [-21.699382, 166.174028, 13],
  [-21.699385, 166.174232, 13],
  [-21.699371, 166.174414, 13],
  [-21.699357, 166.174597, 13],
  [-21.699339, 166.17479, 13],
  [-21.699362, 166.174972, 13],
  [-21.699468, 166.175133, 13],
  [-21.699632, 166.175187, 13],
  [-21.699803, 166.175251, 13],
  [-21.699979, 166.175283, 13],
  [-21.700151, 166.175326, 13],
  [-21.700227, 166.175476, 13],
  [-21.700211, 166.17568, 13],
  [-21.700297, 166.175841, 13],
  [-21.70045, 166.175938, 13],
  [-21.700617, 166.176002, 13],
  [-21.700767, 166.176109, 13],
  [-21.70093, 166.176184, 13],
  [-21.701105, 166.176195, 13],
  [-21.701272, 166.17626, 13],
  [-21.701402, 166.176399, 13],
  [-21.70151, 166.176549, 13],
  [-21.701619, 166.176699, 13],
  [-21.701748, 166.176828, 13],
  [-21.701907, 166.176914, 13],
  [-21.702075, 166.176978, 13],
  [-21.702239, 166.177053, 13],
  [-21.702405, 166.177129, 13],
  [-21.702582, 166.177161, 13],
  [-21.702759, 166.177161, 13],
  [-21.702939, 166.177139, 13],
  [-21.703118, 166.177129, 13],
  [-21.703297, 166.177139, 12.8],
  [-21.703478, 166.177129, 12.8],
  [-21.703656, 166.177118, 13],
  [-21.703833, 166.177096, 13.2],
  [-21.704009, 166.177064, 13.4],
  [-21.704185, 166.177096, 13.6],
  [-21.704347, 166.177171, 13.8],
  [-21.704485, 166.177279, 14.2],
  [-21.704594, 166.177418, 14.6],
  [-21.704661, 166.17759, 15.2],
  [-21.704677, 166.177772, 15.8],
  [-21.704604, 166.177933, 15.8],
  [-21.704472, 166.178062, 16],
  [-21.704317, 166.178137, 16.6],
  [-21.704159, 166.178201, 17.6],
  [-21.704005, 166.178255, 18.4],
  [-21.703877, 166.178362, 19.2],
  [-21.703825, 166.178513, 19.4],
  [-21.703887, 166.178684, 19.4],
  [-21.70402, 166.178792, 19.4],
  [-21.704167, 166.178867, 19.6],
  [-21.704316, 166.178963, 19.6],
  [-21.704439, 166.179081, 19.8],
  [-21.704497, 166.179253, 20],
  [-21.704449, 166.179414, 20.6],
  [-21.704323, 166.179543, 21.4],
  [-21.704177, 166.179618, 22.2],
  [-21.704028, 166.179693, 23],
  [-21.703888, 166.179768, 23.8],
  [-21.703788, 166.179907, 24.4],
  [-21.703765, 166.180068, 24.4],
  [-21.703821, 166.180229, 24.6],
  [-21.703911, 166.180379, 24.6],
  [-21.704005, 166.18053, 24.6],
  [-21.704069, 166.18068, 24.8],
  [-21.704076, 166.180862, 25],
  [-21.704014, 166.181034, 25.6],
  [-21.703906, 166.181173, 26.4],
  [-21.703769, 166.181259, 27.2],
  [-21.703625, 166.181334, 28.2],
  [-21.70348, 166.18142, 29],
  [-21.703341, 166.181495, 29.8],
  [-21.703202, 166.181581, 30.8],
  [-21.703062, 166.181656, 31.6],
  [-21.702933, 166.181731, 32.4],
  [-21.702825, 166.18186, 33.2],
  [-21.702799, 166.18201, 33.8],
  [-21.702853, 166.18216, 34],
  [-21.702943, 166.1823, 34],
  [-21.703042, 166.182439, 34],
  [-21.703139, 166.182579, 34],
  [-21.703234, 166.182708, 34],
  [-21.703332, 166.182868, 34],
  [-21.703431, 166.183008, 34],
  [-21.703534, 166.183158, 34],
  [-21.703635, 166.183298, 34],
  [-21.703738, 166.183448, 34],
  [-21.703841, 166.183598, 34],
  [-21.703943, 166.183738, 34],
  [-21.704046, 166.183888, 34],
  [-21.70415, 166.184038, 34],
  [-21.704254, 166.184188, 34],
  [-21.704362, 166.184349, 34],
  [-21.704465, 166.184499, 34],
  [-21.70458, 166.184639, 34],
  [-21.704742, 166.184692, 33.8],
  [-21.704905, 166.184639, 33.8],
  [-21.70504, 166.18451, 33.2],
  [-21.705176, 166.184381, 32.4],
  [-21.705314, 166.184253, 31.4],
  [-21.705455, 166.184113, 30.2],
  [-21.705605, 166.183995, 29.4],
  [-21.705775, 166.183898, 29.4],
  [-21.705964, 166.183877, 29.6],
  [-21.706139, 166.183952, 29.4],
  [-21.706269, 166.184081, 30],
  [-21.706341, 166.184274, 30.2],
  [-21.706363, 166.184467, 29.4],
  [-21.706372, 166.184671, 28.6],
  [-21.706435, 166.184853, 27.8],
  [-21.70658, 166.184982, 27.8],
  [-21.706765, 166.184982, 27.8],
  [-21.706937, 166.184907, 27.6],
  [-21.707095, 166.1848, 27.6],
  [-21.707253, 166.184692, 27.6],
  [-21.707425, 166.184617, 27.4],
  [-21.707608, 166.184639, 27.2],
  [-21.707773, 166.184714, 26.8],
  [-21.707885, 166.184875, 26.2],
  [-21.70794, 166.185068, 25.4],
  [-21.707952, 166.185272, 24.6],
  [-21.707949, 166.185476, 23.6],
  [-21.707945, 166.18569, 22.6],
  [-21.707948, 166.185883, 21.8],
  [-21.707984, 166.186098, 20.8],
  [-21.708097, 166.18627, 20.6],
  [-21.708274, 166.186345, 20.8],
  [-21.708467, 166.186355, 20.6],
  [-21.708653, 166.186312, 20.2],
  [-21.70883, 166.186237, 19.6],
  [-21.709004, 166.186141, 19],
  [-21.709177, 166.186055, 18.2],
  [-21.709359, 166.18598, 17.6],
  [-21.709553, 166.185948, 16.8],
  [-21.709737, 166.186023, 15.8],
  [-21.709886, 166.186162, 15],
  [-21.709982, 166.186345, 14.4],
  [-21.710014, 166.186538, 14],
  [-21.709982, 166.186752, 13.6],
  [-21.709908, 166.186956, 13.6],
  [-21.70982, 166.187139, 13.6],
  [-21.709727, 166.18731, 13.6],
  [-21.709632, 166.187493, 13.6],
  [-21.709536, 166.187654, 13.6],
  [-21.709444, 166.187836, 13.6],
  [-21.709371, 166.188018, 13.6],
  [-21.709317, 166.188201, 13.6],
  [-21.7093, 166.188405, 13.6],
  [-21.709355, 166.188587, 13.4],
  [-21.709489, 166.188726, 13.2],
  [-21.709652, 166.188812, 13.2],
  [-21.709815, 166.188909, 13],
  [-21.709929, 166.189059, 13],
  [-21.709988, 166.189241, 13],
  [-21.710026, 166.189424, 13],
  [-21.710063, 166.189628, 13],
  [-21.710099, 166.189821, 13],
  [-21.710135, 166.190003, 13],
  [-21.710198, 166.190186, 13],
  [-21.710338, 166.190314, 13],
  [-21.710492, 166.1904, 13],
  [-21.710596, 166.190561, 13],
  [-21.710627, 166.190754, 13],
  [-21.71057, 166.190937, 13],
  [-21.71047, 166.191087, 13],
  [-21.710362, 166.191248, 13],
  [-21.710262, 166.191409, 13],
  [-21.710234, 166.191591, 13],
  [-21.710303, 166.191763, 13],
  [-21.710391, 166.191934, 13],
  [-21.710456, 166.192117, 13],
  [-21.710493, 166.192299, 13],
  [-21.710552, 166.192482, 13],
  [-21.710626, 166.192664, 13],
  [-21.710704, 166.192825, 13],
  [-21.710795, 166.192997, 13],
  [-21.710895, 166.193147, 13],
  [-21.710985, 166.193318, 13],
  [-21.711064, 166.19349, 13],
  [-21.711117, 166.193672, 13],
  [-21.711177, 166.193855, 13],
  [-21.7113, 166.193994, 13],
  [-21.711463, 166.194069, 13],
  [-21.711632, 166.194016, 13],
  [-21.711803, 166.193962, 13],
  [-21.711978, 166.194005, 13],
  [-21.712145, 166.194069, 13],
  [-21.712313, 166.194144, 13],
  [-21.712477, 166.19422, 13],
  [-21.712638, 166.194295, 13],
  [-21.712809, 166.194338, 13],
  [-21.712982, 166.194402, 13],
  [-21.713149, 166.194466, 13],
  [-21.713305, 166.194552, 13],
  [-21.713454, 166.194659, 13],
  [-21.713617, 166.194756, 13],
  [-21.71378, 166.194831, 13],
  [-21.713926, 166.194938, 13],
  [-21.713993, 166.195099, 13],
  [-21.714035, 166.195282, 13],
  [-21.714129, 166.195453, 13],
  [-21.714283, 166.19555, 13],
  [-21.714459, 166.19555, 13],
  [-21.7146, 166.195443, 13],
  [-21.714684, 166.195271, 13],
  [-21.714684, 166.195067, 13],
  [-21.714586, 166.194917, 13],
  [-21.714461, 166.194777, 13],
  [-21.714417, 166.194595, 13],
  [-21.714464, 166.194413, 13],
  [-21.714558, 166.194263, 13],
  [-21.714636, 166.19408, 13],
  [-21.714688, 166.193898, 13],
  [-21.714807, 166.193758, 13],
  [-21.714964, 166.193672, 13],
  [-21.71514, 166.19364, 13],
  [-21.715317, 166.193651, 13],
  [-21.715493, 166.193694, 13],
  [-21.715666, 166.193748, 13],
  [-21.715837, 166.193801, 13],
  [-21.716009, 166.193855, 13],
  [-21.716178, 166.193908, 13],
  [-21.716346, 166.193994, 13],
  [-21.716521, 166.194016, 13],
  [-21.7167, 166.194037, 13],
  [-21.716862, 166.194112, 13],
  [-21.716993, 166.194252, 13],
  [-21.717105, 166.194402, 13],
  [-21.717196, 166.194552, 13],
  [-21.717303, 166.194702, 13],
  [-21.717448, 166.194831, 13],
  [-21.71761, 166.194906, 13],
  [-21.717774, 166.194971, 13],
  [-21.717951, 166.194981, 13],
  [-21.718096, 166.194874, 13],
  [-21.718198, 166.194702, 13],
  [-21.71829, 166.194541, 13],
  [-21.718384, 166.194381, 13],
  [-21.718475, 166.19422, 13],
  [-21.718545, 166.194037, 13],
  [-21.718587, 166.193855, 13],
  [-21.71871, 166.193726, 13],
  [-21.718859, 166.193619, 13],
  [-21.718907, 166.193436, 13],
  [-21.718899, 166.193243, 13],
  [-21.718907, 166.193061, 13],
  [-21.718962, 166.192878, 13],
  [-21.719037, 166.192707, 13],
  [-21.719112, 166.192524, 13],
];

export default stream;
