import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.63658, 166.96955, 11.8],
  [-11.63665, 166.969743, 11.8],
  [-11.636703, 166.969957, 11.8],
  [-11.636781, 166.970161, 11.6],
  [-11.636887, 166.970354, 11.6],
  [-11.636961, 166.970558, 11.8],
  [-11.637009, 166.970773, 12],
  [-11.637052, 166.970977, 12.2],
  [-11.637083, 166.971191, 12.6],
  [-11.637092, 166.971406, 12.8],
  [-11.637059, 166.97162, 13],
  [-11.636984, 166.971824, 13],
  [-11.63688, 166.972007, 13],
  [-11.636753, 166.972167, 13],
  [-11.636603, 166.972328, 12.8],
  [-11.636416, 166.972436, 13],
  [-11.636209, 166.972479, 13],
  [-11.635998, 166.972522, 13],
  [-11.635797, 166.972586, 13],
  [-11.635641, 166.972736, 13],
  [-11.635534, 166.972919, 13],
  [-11.635452, 166.973122, 13],
  [-11.635373, 166.973315, 13],
  [-11.635287, 166.973519, 13],
];

export default stream;
