import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.653804, 166.955484, 0.8],
  [-11.653985, 166.955452, 1],
  [-11.654158, 166.955398, 1],
  [-11.654332, 166.955355, 1],
  [-11.654504, 166.955312, 1],
  [-11.654678, 166.95527, 1],
  [-11.654853, 166.955216, 1.2],
  [-11.655022, 166.955173, 1.4],
  [-11.655193, 166.95513, 1.8],
  [-11.655362, 166.955098, 2.2],
  [-11.655535, 166.955055, 2.6],
  [-11.655706, 166.955001, 2.8],
  [-11.655876, 166.954958, 3.2],
  [-11.656048, 166.954916, 3.6],
  [-11.656217, 166.954873, 4],
  [-11.656386, 166.954819, 4.4],
  [-11.656556, 166.954776, 4.8],
  [-11.656727, 166.954733, 5.2],
  [-11.656895, 166.95469, 5.6],
  [-11.657064, 166.954637, 6],
  [-11.657234, 166.954604, 6.6],
  [-11.657405, 166.954561, 7],
  [-11.657576, 166.954519, 7.4],
  [-11.657744, 166.954476, 7.4],
  [-11.657916, 166.954422, 7.4],
  [-11.658087, 166.954379, 7.4],
  [-11.658263, 166.954336, 7.4],
  [-11.658437, 166.954293, 7.4],
  [-11.658613, 166.95424, 7.6],
  [-11.658793, 166.954197, 7.8],
  [-11.658967, 166.954154, 8.4],
  [-11.659124, 166.954057, 9.6],
  [-11.659203, 166.953907, 11.6],
  [-11.659218, 166.953735, 13.2],
  [-11.659215, 166.953574, 14.6],
  [-11.659211, 166.953403, 16.2],
  [-11.659217, 166.953253, 17.8],
  [-11.659221, 166.953102, 19.6],
  [-11.659229, 166.952941, 21.2],
  [-11.659274, 166.952813, 23],
  [-11.659379, 166.952705, 24.6],
  [-11.659513, 166.952652, 26],
  [-11.659648, 166.952684, 27.4],
  [-11.659742, 166.952791, 28.8],
  [-11.659784, 166.952931, 30],
  [-11.659796, 166.95307, 31.2],
  [-11.659801, 166.95322, 32.2],
  [-11.659805, 166.95336, 33.4],
  [-11.65982, 166.953499, 34.4],
  [-11.65985, 166.95365, 35.6],
  [-11.659899, 166.953789, 36.4],
  [-11.659961, 166.953907, 37.2],
  [-11.660032, 166.954046, 37.8],
  [-11.660108, 166.954165, 38.4],
  [-11.660194, 166.954304, 39],
  [-11.660289, 166.954422, 39.8],
  [-11.660413, 166.954519, 41],
  [-11.660554, 166.954476, 43],
  [-11.66065, 166.954347, 44.4],
  [-11.660712, 166.954229, 45.8],
  [-11.660725, 166.954079, 46.8],
  [-11.660699, 166.953939, 47.6],
  [-11.660657, 166.953789, 48.2],
  [-11.660617, 166.95365, 49],
  [-11.660589, 166.953499, 50],
  [-11.660562, 166.953338, 51.2],
  [-11.660527, 166.953188, 52.6],
  [-11.660491, 166.953049, 54],
  [-11.660471, 166.952899, 55.4],
  [-11.660468, 166.952748, 56.8],
  [-11.660495, 166.952609, 58.2],
  [-11.66054, 166.952469, 59.4],
  [-11.660597, 166.952341, 60.4],
  [-11.660689, 166.952233, 61.4],
  [-11.660807, 166.952147, 62.2],
  [-11.660933, 166.952072, 62.8],
  [-11.661069, 166.952019, 63.4],
  [-11.661208, 166.951954, 64.2],
  [-11.661353, 166.95189, 64.8],
  [-11.661498, 166.951836, 65.2],
  [-11.661647, 166.951783, 65.8],
  [-11.661796, 166.95174, 66.4],
  [-11.661947, 166.951675, 67],
  [-11.662103, 166.951632, 67.6],
  [-11.662257, 166.95159, 68],
  [-11.662412, 166.951547, 68.6],
  [-11.662567, 166.951482, 69.2],
  [-11.662726, 166.951439, 69.6],
  [-11.662883, 166.951386, 70.2],
  [-11.663044, 166.951343, 70.8],
  [-11.663205, 166.9513, 71.2],
  [-11.663363, 166.951257, 71.8],
  [-11.663526, 166.951203, 72.4],
  [-11.663688, 166.95115, 72.8],
  [-11.66385, 166.951096, 73.4],
  [-11.66401, 166.951053, 74],
  [-11.664173, 166.95101, 74.4],
  [-11.664336, 166.950967, 74.8],
  [-11.664497, 166.950903, 75.4],
  [-11.664659, 166.95086, 75.6],
];

export default stream;
