import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.665518, 166.951257, 78.4],
  [-11.665627, 166.951332, 79.2],
  [-11.665746, 166.951364, 79.8],
  [-11.665871, 166.951375, 80.6],
  [-11.665992, 166.951375, 81.4],
  [-11.6661, 166.951311, 82.8],
  [-11.66615, 166.951225, 84.6],
  [-11.666159, 166.951118, 86.2],
  [-11.666154, 166.95101, 87.4],
  [-11.666139, 166.950914, 88.6],
  [-11.666119, 166.950828, 89.6],
  [-11.666095, 166.950731, 90.8],
  [-11.666066, 166.950645, 91.8],
  [-11.666031, 166.95057, 92.6],
  [-11.665995, 166.950485, 93.6],
  [-11.665957, 166.950399, 94.4],
  [-11.665918, 166.950324, 95.2],
  [-11.665881, 166.950248, 96.2],
  [-11.665843, 166.950173, 97],
  [-11.665804, 166.950088, 97.8],
  [-11.665767, 166.950002, 98.6],
  [-11.665728, 166.949927, 99.4],
  [-11.665693, 166.949852, 100.4],
  [-11.665661, 166.949776, 101.2],
  [-11.665631, 166.94968, 102],
  [-11.665607, 166.949605, 102.8],
  [-11.665585, 166.949508, 103.8],
  [-11.665568, 166.949433, 104.6],
  [-11.665552, 166.949347, 105.4],
  [-11.665543, 166.949251, 106.4],
  [-11.665541, 166.949176, 107.2],
  [-11.665548, 166.94909, 108],
  [-11.665568, 166.949004, 108.8],
  [-11.665598, 166.948918, 109.8],
  [-11.66564, 166.948843, 110.4],
  [-11.665689, 166.948779, 111.2],
  [-11.665744, 166.948704, 111.6],
  [-11.665804, 166.948639, 112.2],
  [-11.66587, 166.948564, 112.8],
  [-11.665944, 166.94851, 113.4],
  [-11.666027, 166.948457, 114],
  [-11.666119, 166.948446, 114.8],
  [-11.66621, 166.948457, 115.8],
  [-11.666279, 166.948521, 116.8],
  [-11.666328, 166.948596, 117.8],
  [-11.666367, 166.948693, 118.6],
  [-11.666401, 166.948779, 119.4],
  [-11.666432, 166.948854, 120.2],
  [-11.66646, 166.94895, 120.8],
  [-11.666487, 166.949036, 121.6],
  [-11.666511, 166.949133, 122.2],
  [-11.666535, 166.949219, 122.8],
  [-11.666559, 166.949315, 123.4],
  [-11.666582, 166.949401, 124],
  [-11.666605, 166.949508, 124.4],
  [-11.666628, 166.949605, 124.8],
  [-11.666652, 166.949712, 125],
  [-11.666673, 166.949819, 125.2],
  [-11.666689, 166.949927, 125.2],
  [-11.666703, 166.950045, 125],
  [-11.666711, 166.950173, 124.6],
  [-11.666719, 166.950291, 124],
  [-11.666725, 166.950431, 123.2],
  [-11.666733, 166.95057, 122.4],
  [-11.666748, 166.950699, 121.4],
  [-11.666776, 166.95086, 120.6],
  [-11.666818, 166.95101, 119.2],
  [-11.666872, 166.95116, 117.6],
  [-11.666937, 166.951311, 115.8],
  [-11.667015, 166.951482, 114.2],
  [-11.6671, 166.951632, 113.6],
  [-11.667185, 166.951783, 114.2],
  [-11.667269, 166.951944, 115.4],
  [-11.667354, 166.952072, 116.8],
  [-11.667442, 166.952212, 118.4],
  [-11.667535, 166.952319, 120],
  [-11.667639, 166.952416, 121.6],
  [-11.667754, 166.952469, 123],
  [-11.667873, 166.952523, 124.2],
  [-11.667994, 166.952534, 125.4],
  [-11.668111, 166.952534, 126.6],
  [-11.668222, 166.952523, 128],
  [-11.668315, 166.952469, 129],
  [-11.668375, 166.952384, 130],
  [-11.6684, 166.952276, 131.2],
  [-11.668404, 166.95218, 132.4],
  [-11.668396, 166.952072, 133.4],
  [-11.668383, 166.951987, 134.4],
  [-11.668364, 166.95189, 135.4],
  [-11.66834, 166.951804, 136.4],
  [-11.668312, 166.951729, 137.4],
  [-11.66828, 166.951632, 138.4],
  [-11.668246, 166.951557, 139.4],
  [-11.668206, 166.951482, 140.2],
  [-11.668163, 166.951407, 141.2],
  [-11.668113, 166.951332, 142],
  [-11.668064, 166.951268, 143],
  [-11.668013, 166.951193, 143.8],
  [-11.667962, 166.951128, 144.6],
  [-11.66791, 166.951053, 145.6],
  [-11.66786, 166.950989, 146.4],
  [-11.667812, 166.950914, 147.2],
  [-11.667766, 166.950839, 148.2],
  [-11.667725, 166.950774, 149],
  [-11.667687, 166.950699, 149.8],
  [-11.667656, 166.950613, 150.8],
  [-11.667629, 166.950538, 151.6],
  [-11.667607, 166.950463, 152.4],
  [-11.667585, 166.950377, 153.4],
  [-11.667568, 166.950291, 154.2],
  [-11.667552, 166.950216, 155],
  [-11.667539, 166.95012, 155.8],
  [-11.667529, 166.950045, 156.6],
  [-11.667522, 166.949959, 157.4],
  [-11.667518, 166.949862, 158.4],
  [-11.667519, 166.949787, 159.2],
  [-11.667526, 166.949691, 160],
  [-11.667539, 166.949615, 161],
  [-11.667562, 166.94954, 161.8],
  [-11.667598, 166.949465, 162.8],
  [-11.667644, 166.94939, 163.8],
  [-11.667698, 166.949326, 164.6],
  [-11.667758, 166.949272, 165.4],
  [-11.667822, 166.949219, 166.2],
  [-11.66789, 166.949165, 167],
  [-11.667958, 166.949111, 167.8],
  [-11.668026, 166.949068, 168.6],
  [-11.668096, 166.949025, 169.4],
  [-11.668162, 166.948961, 170.2],
  [-11.668219, 166.948907, 170.8],
  [-11.66827, 166.948822, 171.4],
  [-11.668317, 166.948746, 172],
  [-11.668362, 166.948671, 172.4],
  [-11.668413, 166.948586, 172.6],
  [-11.668486, 166.948521, 173],
  [-11.668586, 166.94851, 173.2],
  [-11.668683, 166.948553, 172.8],
  [-11.668771, 166.948628, 172.4],
  [-11.668852, 166.948714, 172],
  [-11.668929, 166.948811, 171.6],
  [-11.669003, 166.948918, 171.6],
  [-11.669071, 166.949036, 172],
  [-11.669133, 166.949143, 172.8],
  [-11.669189, 166.949272, 173.8],
  [-11.669236, 166.94939, 175],
  [-11.669278, 166.949497, 176.2],
  [-11.669311, 166.949605, 177.4],
  [-11.669338, 166.949723, 178.4],
  [-11.669361, 166.94983, 179.6],
  [-11.669378, 166.949937, 180.6],
  [-11.66939, 166.950034, 181.6],
  [-11.6694, 166.950141, 182.6],
  [-11.669406, 166.950248, 183.6],
  [-11.669412, 166.950334, 184.6],
  [-11.669416, 166.950431, 185.6],
  [-11.669418, 166.950517, 186.4],
  [-11.669421, 166.950624, 187.4],
  [-11.669422, 166.950721, 188.4],
  [-11.669424, 166.950806, 189.2],
  [-11.669424, 166.950903, 190.2],
  [-11.669422, 166.950989, 191],
  [-11.669421, 166.951064, 192],
  [-11.669421, 166.95116, 192.8],
  [-11.66942, 166.951257, 193.6],
  [-11.669417, 166.951343, 194.6],
  [-11.669413, 166.951439, 195.4],
  [-11.669406, 166.951514, 196.2],
  [-11.669396, 166.9516, 197],
  [-11.669385, 166.951697, 197.8],
  [-11.669371, 166.951772, 198.6],
  [-11.669359, 166.951858, 199.6],
  [-11.669346, 166.951954, 200.4],
  [-11.669334, 166.952051, 201.2],
  [-11.669324, 166.952126, 202],
  [-11.669319, 166.952212, 202.8],
  [-11.669318, 166.952308, 203.6],
  [-11.669322, 166.952394, 204.6],
  [-11.669334, 166.952469, 205.4],
  [-11.669351, 166.952566, 206.2],
  [-11.669371, 166.952641, 207],
  [-11.669398, 166.952738, 207.8],
  [-11.669425, 166.952823, 208.6],
  [-11.669457, 166.952899, 209.2],
  [-11.669491, 166.952974, 210],
  [-11.669527, 166.95307, 210.8],
  [-11.669566, 166.953145, 211.6],
  [-11.669609, 166.95322, 212.4],
  [-11.669653, 166.953295, 213.2],
  [-11.669701, 166.953371, 214.2],
  [-11.669752, 166.953446, 215],
  [-11.66981, 166.953521, 215.8],
  [-11.669874, 166.953585, 216.6],
  [-11.669952, 166.953628, 217.4],
  [-11.670038, 166.953628, 218.6],
  [-11.670116, 166.953607, 220],
  [-11.67018, 166.953553, 220.8],
  [-11.670239, 166.953499, 221.6],
  [-11.670297, 166.953435, 222.4],
  [-11.670352, 166.953371, 223],
  [-11.670403, 166.953295, 223.8],
  [-11.67045, 166.95322, 224.6],
  [-11.670491, 166.953145, 225.4],
  [-11.670527, 166.95307, 226.2],
  [-11.670556, 166.952995, 227],
  [-11.670573, 166.952899, 228],
  [-11.670577, 166.952823, 229.2],
  [-11.670572, 166.952738, 230.2],
  [-11.670558, 166.952652, 231],
  [-11.670542, 166.952577, 232],
  [-11.670522, 166.952491, 233],
  [-11.670501, 166.952416, 233.8],
  [-11.670478, 166.952341, 234.8],
  [-11.670455, 166.952255, 235.6],
  [-11.670432, 166.95218, 236.4],
  [-11.670411, 166.952105, 237.4],
  [-11.670395, 166.952029, 238.2],
  [-11.670379, 166.951944, 239],
  [-11.670365, 166.951858, 240],
  [-11.67035, 166.951783, 240.8],
  [-11.670334, 166.951697, 241.6],
  [-11.670317, 166.951622, 242.4],
  [-11.670301, 166.951547, 243.4],
  [-11.670283, 166.951461, 244.2],
  [-11.670267, 166.951375, 245],
  [-11.670254, 166.9513, 245.8],
  [-11.670243, 166.951203, 246.6],
  [-11.670232, 166.951128, 247.4],
  [-11.670226, 166.951042, 248.2],
  [-11.670223, 166.950967, 249],
  [-11.670223, 166.950871, 249.8],
  [-11.67023, 166.950796, 250.8],
  [-11.670238, 166.950699, 251.6],
  [-11.67025, 166.950613, 252.4],
  [-11.670263, 166.950538, 253.2],
  [-11.670275, 166.950442, 254],
  [-11.670289, 166.950366, 254.8],
  [-11.670303, 166.950281, 255.4],
  [-11.670316, 166.950184, 255.8],
  [-11.670322, 166.950088, 256],
  [-11.670322, 166.949991, 255.8],
  [-11.670316, 166.949884, 255.2],
  [-11.670302, 166.949776, 254.4],
  [-11.670287, 166.949648, 253],
  [-11.670279, 166.949508, 251.4],
  [-11.670289, 166.949358, 249.8],
  [-11.670318, 166.949208, 248.4],
  [-11.670354, 166.949058, 247],
  [-11.670397, 166.948886, 245.4],
  [-11.670452, 166.948714, 243.8],
  [-11.670526, 166.948564, 242],
  [-11.670643, 166.948425, 240],
  [-11.670814, 166.948349, 237.6],
  [-11.671008, 166.948339, 235.2],
  [-11.671197, 166.948382, 233.4],
  [-11.671347, 166.948521, 233],
  [-11.671399, 166.948704, 234.4],
  [-11.671402, 166.948886, 236.6],
  [-11.671395, 166.949058, 238.6],
  [-11.671388, 166.949219, 240.6],
  [-11.671395, 166.949379, 242.6],
  [-11.671414, 166.949508, 244.2],
  [-11.671445, 166.949648, 245.8],
  [-11.671478, 166.949776, 247.2],
  [-11.671515, 166.949884, 248.6],
  [-11.671551, 166.949991, 250],
  [-11.671587, 166.950088, 251.2],
  [-11.67162, 166.950195, 252.4],
  [-11.671653, 166.950302, 253.6],
  [-11.671682, 166.950399, 254.8],
  [-11.671708, 166.950485, 255.8],
  [-11.671728, 166.950581, 257],
  [-11.671747, 166.950678, 258],
  [-11.67176, 166.950763, 259],
  [-11.671768, 166.95086, 260],
  [-11.671775, 166.950946, 261],
  [-11.671779, 166.951021, 262],
  [-11.671781, 166.951118, 262.8],
  [-11.671783, 166.951193, 263.8],
  [-11.671784, 166.951278, 264.8],
  [-11.671784, 166.951375, 265.6],
  [-11.671785, 166.95145, 266.6],
  [-11.671787, 166.951525, 267.4],
  [-11.671789, 166.951622, 268.4],
  [-11.671795, 166.951697, 269.4],
  [-11.671804, 166.951783, 270.2],
  [-11.671816, 166.951858, 271.2],
  [-11.67183, 166.951944, 272],
  [-11.671844, 166.952019, 273],
  [-11.671858, 166.952094, 274],
  [-11.671874, 166.95218, 274.8],
  [-11.67189, 166.952255, 275.8],
  [-11.671909, 166.952341, 276.6],
  [-11.671929, 166.952416, 277.6],
  [-11.671952, 166.952491, 278.4],
  [-11.671977, 166.952566, 279.4],
  [-11.672005, 166.952641, 280.2],
  [-11.672038, 166.952716, 281.2],
  [-11.672075, 166.95278, 282],
  [-11.672114, 166.952856, 282.8],
  [-11.672161, 166.95292, 283.6],
  [-11.672211, 166.952974, 284.2],
  [-11.672263, 166.953049, 285],
  [-11.672321, 166.953113, 285.6],
  [-11.672381, 166.953177, 286.2],
  [-11.672444, 166.953231, 287],
  [-11.672512, 166.953295, 287.6],
  [-11.672583, 166.953338, 288.2],
  [-11.67266, 166.953403, 289],
  [-11.672739, 166.953435, 289.8],
  [-11.672826, 166.953467, 290.8],
  [-11.672917, 166.953467, 291.4],
  [-11.673006, 166.953446, 292.2],
  [-11.673093, 166.953424, 292.8],
  [-11.673171, 166.953371, 293.4],
  [-11.673232, 166.953295, 294.2],
  [-11.673263, 166.95321, 295.2],
  [-11.673267, 166.953113, 296],
  [-11.673262, 166.953027, 296.6],
  [-11.67325, 166.952931, 297.2],
  [-11.673234, 166.952834, 297.8],
  [-11.673212, 166.952738, 298.2],
  [-11.673191, 166.952641, 298.6],
  [-11.673165, 166.952534, 299],
  [-11.673133, 166.952437, 299],
  [-11.673092, 166.952341, 299.2],
  [-11.673046, 166.952233, 299.2],
  [-11.672999, 166.952126, 299],
  [-11.672956, 166.951997, 299],
  [-11.672929, 166.95189, 299],
  [-11.672933, 166.951751, 298.8],
  [-11.67297, 166.951632, 298.6],
  [-11.673033, 166.951514, 298.4],
  [-11.673116, 166.951407, 298.4],
  [-11.673236, 166.951343, 298.8],
  [-11.673367, 166.951375, 300],
  [-11.673478, 166.951439, 301.2],
  [-11.673584, 166.951514, 302.2],
  [-11.67369, 166.951568, 302.8],
  [-11.673792, 166.951654, 303.6],
  [-11.67388, 166.951729, 304.4],
  [-11.673958, 166.951815, 305.6],
  [-11.674029, 166.95189, 306.8],
  [-11.674093, 166.951987, 308],
  [-11.674152, 166.952072, 309],
  [-11.674207, 166.952169, 310.2],
  [-11.674258, 166.952255, 311.4],
  [-11.674307, 166.952341, 312.4],
  [-11.674351, 166.952426, 313.4],
  [-11.674395, 166.952502, 314.4],
  [-11.674437, 166.952598, 315.2],
  [-11.674477, 166.952673, 316.2],
  [-11.674517, 166.952748, 317.2],
  [-11.674555, 166.952834, 318],
  [-11.674592, 166.95292, 319],
  [-11.674629, 166.952995, 319.8],
  [-11.674663, 166.95307, 320.8],
  [-11.674697, 166.953156, 321.6],
  [-11.674729, 166.953231, 322.6],
  [-11.674761, 166.953317, 323.4],
  [-11.674791, 166.953403, 324.4],
  [-11.674819, 166.953478, 325.4],
  [-11.674844, 166.953553, 326.2],
  [-11.674866, 166.953628, 327.2],
  [-11.674887, 166.953725, 328.2],
  [-11.674906, 166.9538, 329],
  [-11.674925, 166.953875, 330],
  [-11.674944, 166.95395, 331],
  [-11.674961, 166.954025, 331.8],
  [-11.67498, 166.954122, 332.8],
  [-11.674997, 166.954197, 333.8],
  [-11.675017, 166.954272, 334.6],
  [-11.675039, 166.954347, 335.6],
  [-11.675062, 166.954422, 336.6],
  [-11.675086, 166.954497, 337.6],
  [-11.675114, 166.954561, 338.4],
  [-11.675145, 166.954637, 339.4],
  [-11.675181, 166.954701, 340.4],
  [-11.675224, 166.954776, 341.4],
  [-11.675276, 166.954819, 342.2],
  [-11.675337, 166.954873, 343.2],
  [-11.675401, 166.954916, 344],
  [-11.675469, 166.954948, 344.8],
  [-11.675542, 166.95498, 345.6],
  [-11.675616, 166.955001, 346.2],
  [-11.675691, 166.955034, 347],
  [-11.675767, 166.955066, 347.8],
  [-11.675842, 166.955098, 348.6],
  [-11.675909, 166.955141, 349.4],
  [-11.675966, 166.955205, 350.4],
  [-11.676003, 166.95528, 351.4],
  [-11.676025, 166.955355, 352.2],
  [-11.67603, 166.955452, 353.2],
  [-11.676023, 166.955527, 354],
  [-11.676011, 166.955602, 354.8],
  [-11.675995, 166.955688, 355.6],
  [-11.675976, 166.955774, 356.4],
  [-11.675958, 166.955849, 357.2],
  [-11.675937, 166.955935, 358.2],
  [-11.675924, 166.95601, 359],
  [-11.675916, 166.956106, 359.8],
  [-11.675916, 166.956182, 360.8],
  [-11.675924, 166.956267, 361.6],
  [-11.675939, 166.956353, 362.6],
  [-11.675958, 166.956428, 363.6],
  [-11.675976, 166.956514, 364.6],
  [-11.676001, 166.956589, 365.6],
  [-11.676023, 166.956664, 366.6],
  [-11.676047, 166.95675, 367.4],
  [-11.676073, 166.956804, 368.4],
  [-11.6761, 166.956879, 369.2],
  [-11.676125, 166.956954, 370],
  [-11.676152, 166.95704, 370.8],
  [-11.676179, 166.957115, 371.4],
  [-11.676206, 166.95719, 372],
  [-11.676231, 166.957276, 372.6],
  [-11.676257, 166.957351, 373],
  [-11.676281, 166.957448, 373.4],
  [-11.676306, 166.957544, 373.6],
  [-11.676332, 166.957641, 373.8],
  [-11.676359, 166.957737, 373.8],
  [-11.676387, 166.957844, 373.8],
  [-11.676416, 166.957963, 373.6],
  [-11.676446, 166.95807, 373.4],
  [-11.676479, 166.958199, 373.2],
];

export default stream;
