import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [47.258613, 11.410159, 280.6],
  [47.258586, 11.409909, 280.2],
  [47.258559, 11.40965, 279.8],
  [47.258533, 11.409398, 279.6],
  [47.258506, 11.409149, 279.8],
  [47.258474, 11.408902, 280.2],
  [47.258441, 11.408657, 280.6],
  [47.258404, 11.408419, 280.8],
  [47.258361, 11.408178, 281.2],
  [47.258318, 11.40795, 281.4],
  [47.258254, 11.407723, 281.6],
  [47.258189, 11.407506, 281.8],
  [47.258125, 11.407295, 282],
  [47.258077, 11.407066, 282],
  [47.258034, 11.406841, 282.2],
  [47.257996, 11.406607, 282.2],
  [47.257964, 11.406377, 282.4],
  [47.257937, 11.406145, 282.6],
  [47.257905, 11.405909, 282.8],
  [47.257873, 11.405677, 283.2],
  [47.257846, 11.405447, 283.4],
  [47.257819, 11.405222, 283.8],
  [47.257792, 11.404993, 284],
  [47.257766, 11.404762, 284.4],
  [47.257739, 11.404535, 284.8],
  [47.257717, 11.404313, 285],
  [47.25769, 11.404089, 285.2],
  [47.257664, 11.403857, 285.6],
  [47.257637, 11.403634, 285.8],
  [47.25761, 11.403412, 286.2],
  [47.257583, 11.403188, 286.4],
  [47.257562, 11.402961, 286.8],
  [47.257535, 11.402736, 287],
  [47.257513, 11.402517, 287.4],
  [47.257492, 11.402289, 287.6],
  [47.257465, 11.402072, 288],
  [47.257438, 11.401848, 288.2],
  [47.257411, 11.401627, 288.4],
  [47.257385, 11.401405, 288.8],
  [47.257352, 11.401177, 289],
  [47.25732, 11.400961, 289.2],
  [47.257288, 11.400737, 289.4],
  [47.257245, 11.400513, 289.6],
  [47.257202, 11.400296, 289.6],
  [47.257149, 11.40008, 289.6],
  [47.257095, 11.399864, 289.6],
  [47.257031, 11.399653, 289.6],
  [47.256961, 11.399438, 289.2],
  [47.256896, 11.399225, 289],
  [47.256837, 11.399001, 288.6],
  [47.256762, 11.398778, 287.8],
  [47.256671, 11.398569, 287],
  [47.256585, 11.398354, 286],
  [47.256537, 11.398101, 284.8],
  [47.256591, 11.397862, 283.8],
  [47.25673, 11.397689, 283.2],
  [47.256891, 11.397563, 282.8],
  [47.257057, 11.39746, 282.4],
  [47.257224, 11.39737, 282.2],
  [47.257395, 11.39728, 281.8],
  [47.257562, 11.397194, 281.6],
  [47.257733, 11.397116, 281.4],
  [47.257905, 11.397049, 281.4],
  [47.258071, 11.39699, 281.4],
  [47.258243, 11.396931, 281.2],
  [47.258415, 11.396882, 281.2],
  [47.258581, 11.396836, 281.2],
  [47.258747, 11.396797, 281.2],
  [47.258919, 11.396796, 281],
  [47.259085, 11.396825, 280.6],
  [47.259257, 11.396846, 280.6],
  [47.259429, 11.3968, 280.4],
  [47.259579, 11.396693, 280.6],
  [47.259713, 11.396541, 280.8],
  [47.259842, 11.396374, 281.2],
  [47.25996, 11.396198, 281.4],
  [47.260067, 11.396028, 281.8],
  [47.260185, 11.395856, 282],
  [47.260303, 11.395696, 282.4],
  [47.260432, 11.395558, 282.6],
  [47.260571, 11.395447, 282.6],
  [47.260727, 11.395364, 282.4],
  [47.260882, 11.395306, 282.2],
  [47.261043, 11.395264, 282],
  [47.261199, 11.395231, 281.8],
  [47.26136, 11.395195, 281.6],
  [47.261526, 11.395145, 281.6],
  [47.261687, 11.395085, 281.6],
  [47.261848, 11.395023, 281.4],
  [47.262003, 11.394964, 281.6],
  [47.262164, 11.394905, 281.6],
  [47.262331, 11.394857, 281.6],
  [47.262486, 11.394821, 281.6],
  [47.262653, 11.394792, 281.6],
  [47.262813, 11.394779, 281.8],
  [47.26298, 11.394783, 281.8],
  [47.263141, 11.394795, 282],
  [47.263302, 11.394812, 282],
  [47.263463, 11.394831, 282],
  [47.263629, 11.394849, 282.2],
  [47.26379, 11.394858, 282.2],
  [47.263956, 11.394857, 282.4],
  [47.264117, 11.394842, 282.4],
  [47.264273, 11.394812, 282.6],
  [47.264434, 11.394766, 282.8],
  [47.264589, 11.394707, 282.8],
  [47.264739, 11.394643, 283],
  [47.264895, 11.394571, 283],
  [47.265045, 11.394495, 283.2],
  [47.265195, 11.394418, 283.2],
  [47.265351, 11.394336, 283.4],
  [47.265496, 11.394261, 283.4],
  [47.265657, 11.394182, 283.6],
  [47.265807, 11.394115, 283.6],
  [47.265962, 11.394052, 283.8],
  [47.266113, 11.393992, 284],
  [47.266268, 11.39393, 284],
  [47.266418, 11.393871, 284.2],
  [47.266574, 11.393812, 284.2],
  [47.266729, 11.393754, 284.4],
  [47.266885, 11.393698, 284.6],
  [47.267046, 11.39364, 284.6],
  [47.267196, 11.393588, 284.8],
  [47.267352, 11.393534, 284.8],
  [47.267507, 11.393487, 285],
  [47.267663, 11.393438, 285],
  [47.267818, 11.393392, 285.4],
  [47.267974, 11.393349, 285.6],
  [47.26813, 11.393308, 286],
  [47.268285, 11.393254, 286.2],
  [47.268435, 11.393199, 286.6],
  [47.268537, 11.393038, 286.6],
  [47.268543, 11.392806, 286.4],
  [47.268516, 11.392573, 286],
  [47.268484, 11.392343, 286],
  [47.268457, 11.392109, 286.2],
  [47.268414, 11.391874, 286.6],
  [47.268323, 11.391688, 286.6],
  [47.268285, 11.391483, 286.8],
  [47.268392, 11.391309, 287],
  [47.26851, 11.391153, 287.2],
  [47.268623, 11.390996, 286.8],
  [47.268741, 11.390832, 286.6],
  [47.268859, 11.39066, 287],
  [47.26895, 11.390473, 287.8],
  [47.268977, 11.390243, 288.4],
  [47.268966, 11.390015, 289],
  [47.26895, 11.389791, 289.6],
  [47.268999, 11.389581, 290.8],
  [47.269095, 11.389415, 292],
  [47.269202, 11.389279, 293.2],
  [47.269315, 11.389164, 294.2],
  [47.269428, 11.38905, 295.4],
  [47.269535, 11.388941, 296.6],
  [47.269637, 11.38883, 297.8],
  [47.269739, 11.388706, 298.8],
  [47.269835, 11.388583, 300],
  [47.269921, 11.388454, 301],
  [47.270007, 11.388328, 302.2],
  [47.270093, 11.388199, 303.2],
  [47.270179, 11.388073, 304.4],
  [47.270259, 11.38795, 305.4],
  [47.27034, 11.387823, 306.4],
  [47.27042, 11.387698, 307.4],
  [47.270501, 11.387573, 308.6],
  [47.270576, 11.387451, 309.6],
  [47.270656, 11.387328, 310.6],
  [47.270737, 11.387206, 311.6],
  [47.270812, 11.387082, 312.6],
  [47.270892, 11.386962, 313.6],
  [47.270973, 11.38684, 314.4],
  [47.271053, 11.386718, 315.4],
  [47.271128, 11.3866, 316.2],
  [47.271219, 11.386479, 316.8],
  [47.271321, 11.386414, 317.2],
  [47.271402, 11.386541, 316.2],
  [47.271397, 11.38674, 314.6],
  [47.271359, 11.386947, 313],
  [47.271343, 11.387183, 311.2],
  [47.271343, 11.387427, 309.6],
  [47.27138, 11.387687, 308],
  [47.271418, 11.387935, 306.6],
  [47.271461, 11.388205, 305],
  [47.271498, 11.388473, 303.4],
  [47.271552, 11.388747, 302],
  [47.271622, 11.389007, 300.4],
  [47.271724, 11.38925, 299],
  [47.271885, 11.389433, 297.6],
  [47.272067, 11.389541, 296.4],
  [47.272266, 11.389604, 295.2],
  [47.272453, 11.389659, 294.4],
  [47.272641, 11.389742, 293.6],
  [47.272813, 11.389885, 293],
  [47.272958, 11.390076, 292.6],
  [47.273086, 11.390282, 292.4],
  [47.27321, 11.390501, 292.4],
  [47.273322, 11.390713, 292.4],
  [47.27343, 11.390926, 292.6],
  [47.273553, 11.391136, 292.8],
  [47.27366, 11.39133, 293.6],
  [47.273773, 11.391529, 294.6],
  [47.273902, 11.391684, 295.6],
  [47.274041, 11.391806, 296.8],
  [47.274181, 11.39189, 297.6],
  [47.274336, 11.391937, 297.8],
  [47.274492, 11.39193, 298],
  [47.274642, 11.391908, 298],
  [47.274798, 11.39189, 297.8],
  [47.274948, 11.391953, 297],
  [47.27498, 11.392184, 295.4],
  [47.274942, 11.392429, 294],
  [47.274878, 11.39268, 292.4],
  [47.274808, 11.392926, 291],
  [47.274712, 11.39316, 289.2],
  [47.274562, 11.393308, 287.2],
  [47.274395, 11.393449, 285.6],
  [47.274245, 11.393646, 283.8],
  [47.2741, 11.393842, 282],
  [47.273993, 11.394099, 280.4],
  [47.274014, 11.394391, 279.6],
  [47.274116, 11.394653, 279.6],
  [47.274245, 11.394869, 279.8],
  [47.274379, 11.395053, 280],
  [47.274529, 11.395222, 280.2],
  [47.274685, 11.395373, 280.4],
  [47.27484, 11.395508, 280.4],
  [47.275001, 11.395624, 280.4],
  [47.275157, 11.395732, 280.4],
  [47.275323, 11.395837, 280.4],
  [47.275473, 11.395935, 280.4],
  [47.275634, 11.396034, 280.4],
  [47.275795, 11.396131, 280.4],
  [47.275951, 11.396227, 280.4],
  [47.276106, 11.396323, 280.6],
  [47.276262, 11.396418, 280.6],
  [47.276412, 11.396521, 280.6],
  [47.276562, 11.39663, 280.8],
  [47.276718, 11.39674, 281],
  [47.276863, 11.396846, 281.2],
  [47.277008, 11.396948, 281.6],
  [47.277153, 11.397053, 281.8],
  [47.277297, 11.397166, 282.2],
  [47.277431, 11.397291, 282.4],
  [47.27756, 11.397425, 282.8],
  [47.277684, 11.397571, 282.8],
  [47.277796, 11.397737, 283],
  [47.277898, 11.397912, 283],
  [47.277995, 11.398102, 283],
  [47.278091, 11.398302, 283],
  [47.278177, 11.398494, 283],
  [47.278274, 11.398686, 283],
  [47.278376, 11.398869, 283.2],
  [47.278483, 11.399044, 283.2],
  [47.278601, 11.399211, 283.4],
  [47.278708, 11.399368, 283.6],
  [47.278826, 11.399534, 284],
  [47.278944, 11.399695, 284.2],
  [47.279052, 11.39986, 284.4],
  [47.279159, 11.400036, 284.6],
  [47.279261, 11.400218, 284.6],
  [47.279352, 11.400408, 284.8],
  [47.279438, 11.400606, 284.8],
  [47.279529, 11.400806, 285],
  [47.279609, 11.401003, 285],
  [47.279685, 11.40121, 285],
  [47.279765, 11.401411, 285],
  [47.279845, 11.401617, 285],
  [47.279926, 11.40182, 285],
  [47.280012, 11.402029, 285],
  [47.280098, 11.402233, 284.8],
  [47.280178, 11.402435, 284.8],
  [47.280269, 11.402646, 284.6],
  [47.280355, 11.402851, 284.6],
  [47.280441, 11.403062, 284.4],
  [47.280521, 11.403273, 284.2],
  [47.280591, 11.403481, 284],
  [47.280666, 11.403705, 283.6],
  [47.280731, 11.403936, 283.2],
  [47.280795, 11.404173, 282.6],
  [47.280843, 11.404416, 281.8],
  [47.280886, 11.404667, 281.2],
  [47.280902, 11.40492, 280.6],
  [47.2808, 11.405124, 280],
  [47.280634, 11.405092, 280.4],
  [47.280478, 11.404985, 281],
  [47.280323, 11.404868, 281.2],
  [47.280178, 11.404772, 281.2],
  [47.280028, 11.404674, 280.8],
  [47.279883, 11.404568, 280.6],
  [47.279754, 11.404459, 280.4],
  [47.279631, 11.404337, 280],
  [47.279518, 11.404195, 279.6],
  [47.279416, 11.404038, 279],
  [47.279325, 11.403881, 278.8],
  [47.279239, 11.403732, 278.8],
  [47.279148, 11.403589, 278.8],
  [47.279062, 11.403444, 278.8],
  [47.278982, 11.40331, 278.8],
  [47.278896, 11.403177, 278.8],
  [47.278821, 11.403048, 278.8],
  [47.278751, 11.402926, 278.8],
  [47.278676, 11.402801, 278.8],
  [47.278606, 11.402685, 279],
  [47.278537, 11.402569, 279],
  [47.278472, 11.402459, 279],
  [47.278408, 11.402352, 279],
  [47.278349, 11.40225, 279.2],
  [47.27829, 11.402144, 279.2],
  [47.278231, 11.402048, 279.2],
  [47.278172, 11.401954, 279.2],
  [47.278118, 11.401859, 279.4],
  [47.278064, 11.401769, 279.4],
  [47.278011, 11.401683, 279.4],
  [47.277957, 11.401598, 279.6],
  [47.277914, 11.401518, 279.6],
  [47.277866, 11.401436, 279.8],
  [47.277818, 11.401361, 279.8],
  [47.277775, 11.401286, 279.8],
  [47.277732, 11.401212, 280],
  [47.277689, 11.401142, 280],
  [47.277646, 11.401074, 280.2],
  [47.277609, 11.401011, 280.2],
  [47.277571, 11.400947, 280.4],
  [47.277533, 11.400885, 280.4],
  [47.277496, 11.400826, 280.6],
  [47.277464, 11.400768, 280.6],
  [47.277431, 11.400713, 280.6],
  [47.277399, 11.400661, 280.8],
  [47.277367, 11.400609, 280.8],
  [47.277335, 11.400559, 281],
  [47.277308, 11.400509, 281],
  [47.277281, 11.400464, 281],
  [47.27726, 11.400421, 281.2],
  [47.277233, 11.400375, 281.2],
  [47.277206, 11.400335, 281.2],
  [47.277179, 11.400295, 281.2],
  [47.277158, 11.400256, 281.4],
  [47.277136, 11.400218, 281.4],
  [47.27711, 11.400181, 281.4],
  [47.277094, 11.400146, 281.6],
  [47.277072, 11.400114, 281.6],
  [47.277056, 11.40008, 281.6],
  [47.277035, 11.400048, 281.6],
  [47.277018, 11.400017, 281.6],
  [47.276997, 11.399988, 281.8],
  [47.276981, 11.399958, 281.8],
  [47.276965, 11.39993, 281.8],
  [47.276949, 11.399903, 281.8],
  [47.276933, 11.399878, 281.8],
  [47.276917, 11.399852, 282],
  [47.2769, 11.399827, 282],
  [47.27689, 11.399803, 282],
  [47.276874, 11.399777, 282],
  [47.276857, 11.399754, 282],
  [47.276847, 11.399732, 282],
  [47.276831, 11.399709, 282.2],
  [47.276815, 11.399687, 282.2],
  [47.276804, 11.399665, 282.2],
  [47.276788, 11.399644, 282.2],
  [47.276777, 11.399622, 282.2],
  [47.276766, 11.399602, 282.2],
  [47.27675, 11.39958, 282.2],
  [47.276739, 11.39956, 282.4],
  [47.276723, 11.39954, 282.4],
  [47.276713, 11.399518, 282.4],
  [47.276697, 11.399493, 282.4],
  [47.276675, 11.399463, 282.4],
  [47.276654, 11.39943, 282.4],
  [47.276632, 11.399396, 282.4],
  [47.276611, 11.399359, 282.6],
  [47.276584, 11.399319, 282.6],
  [47.276557, 11.399278, 282.6],
  [47.276525, 11.399234, 282.6],
  [47.276498, 11.399188, 282.6],
  [47.276466, 11.39914, 282.6],
  [47.276434, 11.399091, 282.6],
  [47.276402, 11.39904, 282.6],
  [47.276364, 11.398986, 282.6],
  [47.276326, 11.398934, 282.6],
  [47.276289, 11.398879, 282.6],
  [47.276251, 11.398824, 282.6],
  [47.276208, 11.398763, 282.6],
  [47.276165, 11.398706, 282.4],
  [47.276123, 11.398648, 282.4],
  [47.276074, 11.398588, 282.4],
  [47.276026, 11.398526, 282.4],
  [47.275978, 11.398463, 282.4],
  [47.275929, 11.398403, 282.4],
  [47.275881, 11.398338, 282.4],
  [47.275833, 11.398277, 282.4],
  [47.275779, 11.398212, 282.6],
  [47.275736, 11.39815, 282.6],
  [47.275688, 11.398087, 282.6],
  [47.275634, 11.398026, 282.8],
  [47.275586, 11.397965, 282.8],
  [47.275543, 11.397905, 283],
  [47.27549, 11.397846, 283],
  [47.275441, 11.397788, 283],
  [47.275393, 11.397729, 283.2],
  [47.275345, 11.397674, 283.2],
  [47.275291, 11.397618, 283.2],
  [47.275243, 11.397562, 283.4],
  [47.275195, 11.397508, 283.4],
  [47.275141, 11.397452, 283.4],
  [47.275093, 11.397399, 283.4],
  [47.275039, 11.397346, 283.4],
  [47.274985, 11.397294, 283.4],
  [47.274932, 11.39724, 283.4],
  [47.274873, 11.397188, 283.6],
  [47.274819, 11.397137, 283.6],
  [47.27476, 11.397083, 283.6],
  [47.274706, 11.397031, 283.6],
  [47.274647, 11.396977, 283.6],
  [47.274588, 11.396922, 283.6],
  [47.274524, 11.396866, 283.6],
  [47.274465, 11.396812, 283.6],
  [47.274406, 11.396756, 283.6],
  [47.274342, 11.396701, 283.6],
  [47.274272, 11.396643, 283.6],
  [47.274207, 11.396585, 283.6],
  [47.274143, 11.396529, 283.6],
  [47.274079, 11.396471, 283.6],
  [47.274014, 11.396416, 283.6],
  [47.273945, 11.396357, 283.6],
  [47.27388, 11.3963, 283.6],
  [47.273811, 11.396242, 283.6],
  [47.273746, 11.396188, 283.6],
  [47.273676, 11.396136, 283.6],
  [47.273601, 11.396088, 283.6],
  [47.273532, 11.396044, 283.6],
  [47.273456, 11.396001, 283.6],
  [47.273381, 11.395954, 283.6],
  [47.273312, 11.395908, 283.6],
  [47.273237, 11.395859, 283.8],
  [47.273161, 11.395813, 283.8],
  [47.273092, 11.395767, 283.8],
  [47.273017, 11.395724, 283.8],
  [47.272941, 11.395686, 283.8],
  [47.272861, 11.395656, 283.8],
  [47.272781, 11.395644, 283.8],
  [47.2727, 11.395639, 283.8],
  [47.27262, 11.395639, 283.8],
  [47.272539, 11.395641, 283.8],
  [47.272453, 11.395644, 283.8],
  [47.272373, 11.395644, 283.8],
  [47.272292, 11.395637, 283.8],
  [47.272212, 11.395628, 283.8],
  [47.272126, 11.395616, 283.8],
  [47.272046, 11.395604, 283.8],
  [47.271965, 11.395592, 283.8],
  [47.271885, 11.395578, 283.8],
  [47.27181, 11.395566, 283.8],
  [47.271734, 11.395555, 283.8],
  [47.271659, 11.395542, 283.8],
  [47.27159, 11.395531, 283.8],
  [47.271515, 11.395519, 283.8],
  [47.271445, 11.395508, 283.8],
  [47.27138, 11.395498, 283.8],
  [47.271311, 11.395487, 283.8],
  [47.271246, 11.395476, 283.8],
  [47.271177, 11.395466, 283.8],
  [47.271118, 11.395456, 283.8],
  [47.271053, 11.395445, 283.8],
  [47.270994, 11.395436, 284],
  [47.27093, 11.395427, 284],
  [47.270876, 11.395419, 284],
  [47.270817, 11.395409, 284],
  [47.270764, 11.395401, 284],
  [47.27071, 11.395393, 284.2],
  [47.270656, 11.395384, 284.2],
  [47.270603, 11.395377, 284.2],
  [47.270554, 11.395369, 284.2],
  [47.270506, 11.395361, 284.2],
  [47.270458, 11.395353, 284.4],
  [47.270409, 11.395346, 284.4],
  [47.270367, 11.395338, 284.4],
  [47.270318, 11.395331, 284.4],
  [47.270275, 11.395325, 284.4],
  [47.270232, 11.395318, 284.4],
  [47.27019, 11.395311, 284.6],
  [47.270147, 11.395305, 284.6],
  [47.270098, 11.395298, 284.6],
  [47.270061, 11.395293, 284.6],
  [47.270023, 11.395286, 284.6],
  [47.26998, 11.395281, 284.6],
  [47.269937, 11.395274, 284.6],
  [47.269894, 11.395267, 284.6],
  [47.269852, 11.395262, 284.4],
  [47.269809, 11.395256, 284.4],
  [47.269771, 11.39525, 284.4],
  [47.269728, 11.395244, 284.4],
  [47.269685, 11.395238, 284.4],
  [47.269707, 11.39524, 284.4],
  [47.269664, 11.395234, 284.4],
  [47.269616, 11.395228, 284.4],
  [47.269573, 11.395223, 284.4],
  [47.26953, 11.395217, 284.2],
  [47.269481, 11.395212, 284.2],
  [47.269439, 11.395205, 284.2],
  [47.26939, 11.395199, 284.2],
  [47.269347, 11.395192, 284.2],
  [47.269299, 11.395185, 284],
  [47.269251, 11.395179, 284],
  [47.269202, 11.395172, 284],
  [47.269154, 11.395164, 284],
  [47.26909, 11.395153, 283.8],
  [47.269004, 11.395145, 283.8],
  [47.268913, 11.395136, 283.6],
  [47.268811, 11.39512, 283.6],
  [47.268698, 11.39511, 283.6],
  [47.268591, 11.395176, 283.6],
  [47.268537, 11.395337, 283.6],
  [47.268543, 11.395526, 283.6],
  [47.268564, 11.39572, 283.4],
  [47.268596, 11.395922, 283.2],
  [47.268634, 11.396131, 282.8],
  [47.268661, 11.396335, 282.6],
  [47.268698, 11.396552, 282.4],
  [47.268736, 11.396776, 282.2],
  [47.268768, 11.396996, 281.8],
  [47.268806, 11.397229, 281.6],
  [47.268843, 11.397458, 281.4],
  [47.268881, 11.397689, 281],
  [47.268924, 11.397922, 280.8],
  [47.268966, 11.398157, 280.6],
  [47.26902, 11.398382, 280.2],
  [47.269079, 11.398617, 280],
  [47.269122, 11.398859, 279.8],
  [47.269079, 11.399091, 279.8],
  [47.26894, 11.39921, 280],
  [47.268773, 11.399268, 280.4],
  [47.268623, 11.399325, 280.8],
  [47.268462, 11.39939, 281],
  [47.268307, 11.399437, 281.4],
  [47.268146, 11.399478, 281.6],
  [47.26799, 11.399517, 282],
  [47.267835, 11.399524, 282.4],
  [47.267684, 11.399466, 282.4],
  [47.26754, 11.399367, 282.4],
  [47.2674, 11.399266, 282.6],
  [47.26725, 11.399171, 282.6],
  [47.2671, 11.399113, 282.6],
  [47.266944, 11.399124, 282.6],
  [47.26681, 11.399258, 282.6],
  [47.266729, 11.399463, 282.4],
  [47.266676, 11.399687, 282.2],
  [47.266633, 11.399919, 282],
  [47.266595, 11.400157, 282],
  [47.266563, 11.400394, 281.8],
  [47.266536, 11.400633, 281.6],
  [47.266504, 11.400881, 281.4],
  [47.266477, 11.401122, 281.2],
  [47.266445, 11.401358, 281.2],
  [47.266402, 11.401602, 281],
  [47.266375, 11.401844, 280.8],
  [47.266338, 11.402083, 280.8],
  [47.266231, 11.402269, 280.4],
  [47.26607, 11.402355, 280.2],
  [47.265909, 11.40243, 279.8],
  [47.265764, 11.402563, 279.8],
  [47.26564, 11.402729, 279.8],
  [47.265522, 11.402911, 279.8],
  [47.265415, 11.403095, 279.8],
  [47.265303, 11.403283, 279.8],
  [47.265195, 11.403468, 280],
  [47.265083, 11.403662, 280],
  [47.264981, 11.40385, 280],
  [47.264884, 11.404035, 280.2],
  [47.264782, 11.404236, 280.2],
  [47.264702, 11.404442, 280.4],
  [47.264627, 11.40466, 280.8],
  [47.264552, 11.404875, 280.8],
  [47.264476, 11.405085, 281],
  [47.264407, 11.405289, 281.2],
  [47.264332, 11.405498, 281.6],
  [47.264187, 11.405586, 281.8],
  [47.264037, 11.405528, 281.8],
  [47.263881, 11.405466, 281.8],
  [47.26372, 11.40543, 282],
  [47.263564, 11.405379, 282],
  [47.263409, 11.405329, 282],
  [47.263248, 11.405327, 282],
  [47.263114, 11.405449, 282],
  [47.263071, 11.405673, 281.8],
  [47.263066, 11.405914, 281.8],
  [47.263082, 11.406156, 281.8],
  [47.263103, 11.406394, 281.6],
  [47.26313, 11.406637, 281.4],
  [47.263151, 11.406876, 281.4],
  [47.263168, 11.407117, 281],
  [47.263216, 11.407357, 280.6],
  [47.263259, 11.407591, 280.2],
  [47.263248, 11.40784, 279.8],
  [47.26313, 11.408028, 280],
  [47.26298, 11.408134, 280.2],
  [47.262819, 11.408207, 280.6],
  [47.262663, 11.40827, 280.8],
  [47.262508, 11.40834, 281.2],
  [47.262352, 11.408419, 281.4],
  [47.262202, 11.40849, 281.6],
  [47.262046, 11.408555, 282],
  [47.261891, 11.40862, 282.2],
  [47.261741, 11.40869, 282.4],
  [47.261596, 11.408771, 282.4],
  [47.26144, 11.408861, 282.2],
  [47.261295, 11.408953, 282],
  [47.261145, 11.409044, 281.8],
  [47.261, 11.409141, 281.6],
  [47.260845, 11.409241, 281.6],
  [47.2607, 11.409342, 281.8],
  [47.260555, 11.409441, 282.2],
  [47.26041, 11.409542, 282.6],
  [47.260265, 11.409648, 282.8],
  [47.260121, 11.409752, 283.2],
  [47.259986, 11.409866, 283.4],
  [47.259847, 11.409978, 283.4],
  [47.259707, 11.410076, 283.4],
  [47.259557, 11.410176, 283.2],
  [47.259402, 11.410423, 283],
  [47.259311, 11.410541, 282.8],
  [47.259183, 11.410718, 283],
];

export default stream;
