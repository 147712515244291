import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.383881, 165.801995, 119.4],
  [-10.383837, 165.801995, 119.4],
  [-10.383812, 165.801995, 119.4],
  [-10.383805, 165.801995, 119.4],
  [-10.383803, 165.801995, 119.4],
  [-10.383798, 165.801995, 119.4],
  [-10.383792, 165.801995, 119.4],
  [-10.38376, 165.801995, 119.4],
  [-10.383746, 165.801995, 119.4],
  [-10.383733, 165.801995, 119.4],
  [-10.383719, 165.801995, 119.4],
  [-10.383708, 165.801995, 119.4],
  [-10.383683, 165.801995, 119.4],
  [-10.383658, 165.801995, 119.4],
  [-10.383631, 165.801995, 119.4],
  [-10.383602, 165.801995, 119.4],
  [-10.383573, 165.801995, 119.4],
  [-10.383543, 165.801995, 119.4],
  [-10.38351, 165.801995, 119.4],
  [-10.383459, 165.801995, 119.4],
  [-10.383394, 165.801995, 119.4],
  [-10.383318, 165.801995, 119.4],
  [-10.383232, 165.801995, 119.4],
  [-10.383139, 165.801995, 119.4],
  [-10.383039, 165.801995, 119.4],
  [-10.382933, 165.801995, 119.4],
  [-10.382822, 165.801995, 119.4],
  [-10.382707, 165.801995, 119.4],
  [-10.382588, 165.801995, 119.4],
  [-10.382464, 165.801995, 119.4],
  [-10.382355, 165.801941, 119.4],
  [-10.382354, 165.801823, 119.4],
  [-10.382468, 165.801759, 119.4],
  [-10.382601, 165.801748, 119.4],
  [-10.382737, 165.801748, 119.4],
  [-10.382875, 165.801748, 119.4],
  [-10.383011, 165.801727, 119.6],
  [-10.383146, 165.801705, 120.2],
  [-10.38328, 165.801673, 120.6],
  [-10.383414, 165.801673, 120.4],
  [-10.383544, 165.801727, 119.8],
  [-10.383685, 165.801748, 120],
  [-10.383822, 165.801716, 121.2],
  [-10.383931, 165.801641, 122],
  [-10.38397, 165.801501, 121.4],
  [-10.383988, 165.801362, 120.8],
  [-10.384074, 165.801244, 121.4],
  [-10.38418, 165.801147, 122.6],
  [-10.384254, 165.80104, 122.6],
  [-10.384286, 165.80089, 121.6],
  [-10.384345, 165.800772, 121.8],
  [-10.384439, 165.800664, 123.4],
  [-10.38452, 165.800546, 124.4],
  [-10.38455, 165.800418, 123.6],
  [-10.384519, 165.800278, 123.2],
  [-10.384438, 165.80016, 124.4],
  [-10.38443, 165.800021, 125.4],
  [-10.384508, 165.799913, 125.6],
  [-10.384603, 165.799828, 126],
  [-10.384703, 165.799731, 126],
  [-10.384806, 165.799645, 126.2],
  [-10.384909, 165.799549, 126.4],
  [-10.385013, 165.799463, 126.4],
  [-10.385116, 165.799366, 126.6],
  [-10.385219, 165.79927, 126.6],
  [-10.385323, 165.799173, 126.8],
  [-10.385429, 165.799077, 126.8],
  [-10.385537, 165.79898, 127],
  [-10.385644, 165.798894, 127.2],
  [-10.385755, 165.798798, 127.4],
  [-10.385881, 165.798744, 127.4],
  [-10.386005, 165.798798, 127.2],
  [-10.386037, 165.798937, 127],
  [-10.386036, 165.799087, 127],
  [-10.386034, 165.799248, 127.2],
  [-10.386034, 165.799377, 127.2],
  [-10.386034, 165.799538, 127.2],
  [-10.38603, 165.799688, 127.2],
  [-10.386017, 165.799838, 127.4],
  [-10.385942, 165.799956, 127.2],
  [-10.385796, 165.799989, 126.2],
  [-10.385641, 165.799978, 125.2],
  [-10.385482, 165.799989, 124.2],
  [-10.385354, 165.800085, 123.4],
  [-10.385312, 165.800235, 123.2],
  [-10.385309, 165.800407, 122.6],
  [-10.385311, 165.800568, 121.8],
  [-10.385313, 165.80074, 120.8],
  [-10.385307, 165.800922, 120.2],
  [-10.38519, 165.801029, 120],
  [-10.385022, 165.801029, 119.6],
  [-10.384853, 165.801029, 119.4],
  [-10.384685, 165.801029, 119.4],
  [-10.384579, 165.801136, 119.4],
  [-10.384571, 165.801308, 119.4],
  [-10.384569, 165.801469, 119.4],
  [-10.384573, 165.801641, 119.4],
  [-10.384581, 165.801791, 119.4],
  [-10.384654, 165.80193, 119.4],
  [-10.38481, 165.801963, 119.4],
  [-10.384968, 165.801963, 119.6],
  [-10.385124, 165.801963, 120],
  [-10.385279, 165.801963, 120.4],
  [-10.385431, 165.801963, 120.6],
  [-10.38558, 165.801963, 121],
  [-10.385729, 165.801963, 121.2],
  [-10.385877, 165.801963, 121.6],
  [-10.386003, 165.802016, 121.8],
  [-10.386032, 165.802177, 121.8],
  [-10.386033, 165.802327, 121.6],
  [-10.386034, 165.802478, 121.6],
  [-10.386035, 165.802617, 121.4],
  [-10.386034, 165.802789, 121.2],
  [-10.386032, 165.802939, 121],
  [-10.38603, 165.803089, 120.8],
  [-10.385965, 165.803229, 120.8],
  [-10.385822, 165.803272, 120.8],
  [-10.385668, 165.803272, 120.4],
  [-10.385513, 165.803272, 120.2],
  [-10.38536, 165.803293, 119.8],
  [-10.385234, 165.803379, 119.8],
  [-10.385111, 165.803475, 119.6],
  [-10.384959, 165.803518, 119.6],
  [-10.384801, 165.803518, 119.4],
  [-10.384645, 165.803518, 119.4],
  [-10.384544, 165.803411, 119.4],
  [-10.384535, 165.803239, 119.4],
  [-10.384539, 165.803089, 119.4],
  [-10.384542, 165.802939, 119.4],
  [-10.384544, 165.802789, 119.4],
  [-10.384546, 165.802617, 119.4],
  [-10.384548, 165.802467, 119.4],
  [-10.384548, 165.802306, 119.4],
  [-10.384544, 165.802177, 119.4],
  [-10.384509, 165.802038, 119.4],
  [-10.38439, 165.801973, 119.4],
  [-10.384255, 165.801973, 119.4],
  [-10.384131, 165.801973, 119.4],
  [-10.384027, 165.801973, 119.4],
  [-10.383944, 165.801973, 119.4],
];

export default stream;
