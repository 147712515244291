import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.650538, 166.973723, 65.2],
  [-11.650469, 166.973519, 64.2],
  [-11.650387, 166.973305, 63.2],
  [-11.650265, 166.973101, 62],
  [-11.650093, 166.972961, 60.8],
  [-11.649895, 166.972854, 59.6],
  [-11.64968, 166.972779, 58.6],
  [-11.649461, 166.972725, 57.4],
  [-11.64924, 166.972672, 56.4],
  [-11.649019, 166.97265, 55.4],
  [-11.648792, 166.97265, 54.2],
  [-11.648591, 166.972736, 52.8],
  [-11.648468, 166.972919, 51.8],
  [-11.648366, 166.973122, 51],
  [-11.648181, 166.973251, 50],
  [-11.647969, 166.973337, 49],
  [-11.647801, 166.973487, 47.8],
  [-11.647696, 166.97368, 46.6],
  [-11.647613, 166.973895, 45.4],
  [-11.647536, 166.974109, 44.2],
  [-11.647435, 166.974313, 43.4],
  [-11.647249, 166.97441, 43.4],
  [-11.647065, 166.974292, 43.2],
  [-11.646923, 166.974142, 42.8],
  [-11.646713, 166.974174, 42.6],
];

export default stream;
