import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.662383, 166.983336, 12.8],
  [-11.662585, 166.983325, 12.8],
  [-11.662788, 166.983336, 12.8],
  [-11.662985, 166.983401, 12.8],
  [-11.663167, 166.983497, 12.8],
  [-11.66333, 166.983615, 12.8],
  [-11.663465, 166.983765, 12.8],
  [-11.66355, 166.983948, 12.8],
  [-11.663601, 166.984152, 12.8],
  [-11.663617, 166.984345, 12.8],
  [-11.663583, 166.984549, 12.8],
];

export default stream;
