import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.759215, 165.83887, 68.2],
  [-10.759211, 165.838999, 68.2],
  [-10.759192, 165.839117, 68.2],
  [-10.75915, 165.839224, 68],
  [-10.759101, 165.839342, 67.8],
  [-10.759047, 165.839449, 67.6],
  [-10.75898, 165.839578, 67.6],
  [-10.758898, 165.839664, 67.8],
  [-10.758802, 165.839739, 68.4],
  [-10.758704, 165.839803, 69],
  [-10.758607, 165.839878, 69.6],
  [-10.758526, 165.839953, 69.8],
  [-10.758463, 165.84005, 70.2],
  [-10.758419, 165.840157, 70.2],
  [-10.758385, 165.840265, 70.6],
  [-10.758377, 165.840372, 70.8],
  [-10.758406, 165.840468, 70.6],
  [-10.758461, 165.840576, 70],
  [-10.758528, 165.840672, 69.6],
  [-10.758601, 165.84078, 69],
  [-10.758672, 165.840887, 68.4],
  [-10.758729, 165.840994, 68.2],
  [-10.758759, 165.841134, 68.2],
  [-10.758756, 165.841252, 68.2],
  [-10.758694, 165.84137, 68.2],
  [-10.758601, 165.841445, 68.2],
  [-10.758502, 165.841541, 68.2],
  [-10.758409, 165.841616, 68.2],
  [-10.758344, 165.841724, 68.2],
  [-10.758326, 165.841863, 68.2],
  [-10.758336, 165.841981, 68],
  [-10.758367, 165.84211, 68],
  [-10.758416, 165.842228, 67.8],
  [-10.758486, 165.842335, 68],
  [-10.75858, 165.84241, 68.2],
  [-10.758694, 165.842453, 68.4],
  [-10.758812, 165.842485, 69],
  [-10.75893, 165.842507, 69.4],
  [-10.759046, 165.842518, 69.8],
  [-10.759158, 165.842528, 70.2],
  [-10.759271, 165.84255, 70.6],
  [-10.759378, 165.842582, 71],
  [-10.759471, 165.842625, 71.6],
  [-10.759533, 165.842721, 71.8],
  [-10.759565, 165.842807, 72.2],
  [-10.759601, 165.842915, 72.4],
  [-10.75963, 165.843022, 72.4],
  [-10.759664, 165.843118, 72.6],
  [-10.759739, 165.843194, 72.8],
  [-10.759836, 165.843236, 73],
  [-10.75994, 165.843269, 73.2],
  [-10.760044, 165.843301, 73.4],
  [-10.76015, 165.843312, 73.4],
  [-10.760256, 165.843322, 73.6],
  [-10.760363, 165.843344, 73.8],
  [-10.760468, 165.843376, 74],
  [-10.76057, 165.843408, 74.2],
  [-10.76066, 165.843462, 74.4],
  [-10.760731, 165.843558, 74.6],
  [-10.760778, 165.843644, 74.8],
  [-10.760805, 165.843751, 75.2],
  [-10.760819, 165.843859, 75.4],
  [-10.760807, 165.843966, 76],
  [-10.760764, 165.844063, 76.4],
  [-10.760704, 165.844148, 76.8],
  [-10.760627, 165.844213, 76.6],
  [-10.760536, 165.844256, 76.4],
  [-10.760434, 165.844299, 76.2],
  [-10.760331, 165.844331, 76.4],
  [-10.760223, 165.844363, 76.6],
  [-10.760114, 165.844384, 77],
  [-10.760006, 165.844395, 77.2],
  [-10.759899, 165.844406, 77.6],
  [-10.759793, 165.844427, 77.8],
  [-10.759687, 165.844438, 78],
  [-10.759581, 165.844438, 78.4],
  [-10.759475, 165.84446, 78.6],
  [-10.75937, 165.84447, 78.8],
  [-10.759265, 165.844492, 79],
  [-10.759161, 165.844492, 79.4],
  [-10.759057, 165.844502, 79.6],
  [-10.758955, 165.844535, 79.6],
  [-10.758876, 165.84461, 79.4],
  [-10.758849, 165.844717, 79.6],
  [-10.758847, 165.844824, 80.2],
  [-10.758853, 165.844932, 81.2],
  [-10.75886, 165.845017, 81.6],
  [-10.758867, 165.845125, 81.6],
  [-10.758888, 165.845221, 82],
  [-10.758949, 165.845296, 82.8],
  [-10.759043, 165.845307, 83.2],
  [-10.759138, 165.845307, 83.6],
  [-10.759231, 165.845296, 83.8],
  [-10.759325, 165.845275, 84],
  [-10.75942, 165.845264, 84.2],
  [-10.759496, 165.845329, 84.6],
  [-10.759491, 165.845414, 85.4],
  [-10.759414, 165.845468, 86],
  [-10.759324, 165.845447, 86.4],
  [-10.759234, 165.845436, 86.6],
  [-10.759144, 165.845414, 87],
  [-10.759052, 165.845414, 87.2],
  [-10.758961, 165.845414, 87.6],
  [-10.758869, 165.845436, 88],
  [-10.75878, 165.845447, 88.6],
  [-10.758698, 165.845479, 89],
  [-10.758631, 165.845543, 89.6],
  [-10.758587, 165.845618, 90],
  [-10.75858, 165.845693, 90.6],
  [-10.758612, 165.845779, 91],
  [-10.758682, 165.845833, 91],
  [-10.758768, 165.845811, 91],
  [-10.758814, 165.845726, 91.4],
  [-10.758831, 165.845629, 92],
  [-10.758883, 165.845565, 92.8],
  [-10.758963, 165.845586, 93.4],
  [-10.758997, 165.845661, 94],
  [-10.759032, 165.845736, 94.4],
  [-10.759102, 165.845779, 94.6],
  [-10.759187, 165.845779, 94.4],
  [-10.759276, 165.845779, 94.4],
  [-10.759367, 165.845758, 94.4],
  [-10.75946, 165.845736, 94.4],
  [-10.759554, 165.845704, 94.4],
  [-10.759646, 165.845661, 94.6],
  [-10.759685, 165.845565, 94.2],
  [-10.759687, 165.845468, 93.4],
  [-10.759738, 165.845361, 93.2],
  [-10.759852, 165.845339, 92.6],
  [-10.759963, 165.845296, 92.4],
  [-10.760015, 165.845189, 92.2],
  [-10.760016, 165.845071, 92.4],
  [-10.760105, 165.844985, 92.8],
  [-10.760222, 165.844985, 93.6],
  [-10.76032, 165.845039, 94.6],
  [-10.760362, 165.845146, 94],
  [-10.760396, 165.845253, 93.6],
  [-10.760494, 165.845307, 93.8],
  [-10.760591, 165.845253, 93.8],
  [-10.760653, 165.845146, 94],
  [-10.760714, 165.84505, 94.2],
  [-10.760803, 165.844975, 94.2],
  [-10.760918, 165.844964, 94],
  [-10.761025, 165.845007, 94.2],
  [-10.761102, 165.845082, 94.4],
  [-10.761171, 165.845189, 94.6],
  [-10.761255, 165.845264, 95],
  [-10.761359, 165.845307, 95.4],
  [-10.761469, 165.845329, 95.8],
  [-10.761577, 165.845329, 96.4],
  [-10.761684, 165.845339, 97],
  [-10.761788, 165.845339, 97.4],
  [-10.761891, 165.845339, 97.8],
  [-10.761991, 165.845361, 98.4],
  [-10.762089, 165.845371, 98.8],
  [-10.762186, 165.845382, 99.2],
  [-10.762284, 165.845382, 99.4],
  [-10.762379, 165.845414, 99.4],
];

export default stream;
