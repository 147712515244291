import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.805384, 165.836982, 65.2],
  [-10.805359, 165.8371, 65],
  [-10.805337, 165.837239, 64.8],
  [-10.805344, 165.837357, 64.6],
  [-10.805367, 165.837497, 64.2],
  [-10.805408, 165.837625, 64],
  [-10.80549, 165.837722, 63.6],
  [-10.805579, 165.837829, 63.2],
  [-10.805588, 165.837958, 63],
  [-10.805555, 165.838097, 63],
  [-10.805519, 165.838215, 63],
  [-10.805483, 165.838333, 63],
  [-10.805448, 165.838462, 63],
  [-10.805418, 165.838548, 63],
];

export default stream;
