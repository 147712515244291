import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [55.654839, -5.23471, 26.8],
  [55.65493, -5.23456, 26.8],
  [55.655027, -5.234415, 26.8],
  [55.655118, -5.234277, 26.8],
  [55.655209, -5.234136, 26.8],
  [55.65529, -5.233981, 26.8],
  [55.655365, -5.233818, 26.8],
  [55.655419, -5.233637, 26.8],
  [55.655451, -5.233439, 27],
  [55.655456, -5.233238, 27],
  [55.655451, -5.23304, 27.2],
  [55.655451, -5.232845, 27.4],
  [55.655472, -5.23266, 27.6],
  [55.655537, -5.232506, 27.8],
  [55.655617, -5.232395, 28],
  [55.655708, -5.232305, 28.2],
  [55.6558, -5.232236, 28.2],
  [55.655896, -5.232186, 28.4],
  [55.655998, -5.23215, 28.6],
  [55.656089, -5.232126, 28.6],
  [55.656191, -5.232111, 28.8],
  [55.656288, -5.232103, 29],
  [55.656384, -5.232099, 29],
  [55.656481, -5.232097, 29.2],
  [55.656577, -5.232094, 29.4],
  [55.656669, -5.232093, 29.6],
  [55.656765, -5.232099, 30],
  [55.656851, -5.232123, 30.2],
  [55.656937, -5.232164, 30.6],
  [55.657023, -5.232214, 31],
  [55.657103, -5.232269, 31.4],
  [55.657178, -5.232324, 31.6],
  [55.657253, -5.232377, 32],
  [55.657328, -5.232423, 32.4],
  [55.657409, -5.232455, 32.8],
  [55.657484, -5.232474, 33.2],
  [55.657559, -5.232484, 33.4],
  [55.657634, -5.232486, 33.8],
  [55.657704, -5.23248, 34.2],
  [55.657779, -5.232467, 34.6],
  [55.657843, -5.232447, 35],
  [55.657913, -5.232421, 35.4],
  [55.657977, -5.23239, 35.6],
  [55.658042, -5.232352, 36],
  [55.658106, -5.232311, 36.2],
  [55.658171, -5.232267, 36.4],
  [55.65823, -5.232221, 36.6],
  [55.658294, -5.232175, 36.8],
  [55.658364, -5.232142, 36.8],
  [55.658439, -5.232121, 37],
  [55.658509, -5.232105, 36.8],
  [55.658584, -5.23209, 36.8],
  [55.658664, -5.232068, 36.8],
  [55.658745, -5.232024, 36.8],
  [55.65882, -5.231957, 37],
  [55.658889, -5.23187, 37],
  [55.658948, -5.231765, 37],
  [55.658997, -5.231644, 37.2],
  [55.65904, -5.231508, 37.2],
  [55.659061, -5.231357, 37.2],
  [55.659061, -5.231201, 37.2],
  [55.659045, -5.231045, 37.2],
  [55.659013, -5.230896, 37.2],
  [55.658965, -5.230762, 37.2],
  [55.6589, -5.230648, 37.2],
  [55.658825, -5.230557, 37.4],
  [55.658739, -5.230481, 37.4],
  [55.658653, -5.230413, 37.4],
  [55.658573, -5.230347, 37.6],
  [55.658482, -5.23028, 37.6],
  [55.658401, -5.230203, 37.8],
  [55.658321, -5.230111, 37.6],
  [55.658246, -5.230008, 37.6],
  [55.658171, -5.22991, 37.4],
  [55.658085, -5.22983, 37.2],
  [55.657988, -5.229766, 37],
  [55.657892, -5.229715, 36.6],
  [55.65779, -5.229676, 36.2],
  [55.657682, -5.229643, 35.8],
  [55.65757, -5.229615, 35.2],
  [55.657462, -5.229597, 34.8],
  [55.657344, -5.229585, 34.4],
  [55.657226, -5.229573, 33.8],
  [55.657108, -5.229553, 33.4],
  [55.65699, -5.229514, 32.8],
  [55.656872, -5.229443, 32.6],
  [55.656781, -5.229308, 32.6],
  [55.656717, -5.229128, 32.6],
  [55.656679, -5.22893, 32.6],
  [55.656663, -5.228724, 32.4],
  [55.656685, -5.22852, 32.2],
  [55.656744, -5.228339, 31.8],
  [55.656829, -5.228192, 31.4],
  [55.656931, -5.228072, 31],
  [55.657012, -5.227921, 30.4],
  [55.657023, -5.227704, 29.6],
  [55.656974, -5.227493, 28.8],
  [55.65691, -5.227297, 28],
  [55.656824, -5.227121, 27.4],
  [55.656711, -5.226998, 27],
  [55.656583, -5.226962, 26.8],
  [55.656454, -5.226994, 26.6],
  [55.656331, -5.227073, 26.2],
  [55.656223, -5.227196, 25.8],
  [55.656137, -5.227367, 25.6],
  [55.656078, -5.227572, 25.4],
  [55.656046, -5.22779, 25.4],
  [55.656019, -5.228008, 25.2],
  [55.655977, -5.228216, 25],
  [55.655912, -5.228406, 24.6],
  [55.655837, -5.228581, 24.4],
  [55.655757, -5.228745, 24],
  [55.655671, -5.228911, 23.8],
  [55.65559, -5.229081, 23.6],
  [55.655515, -5.229253, 23.2],
  [55.655413, -5.229372, 22.8],
  [55.65529, -5.229413, 23],
  [55.655172, -5.229369, 23.4],
  [55.655065, -5.229283, 23.8],
  [55.654968, -5.229177, 23.8],
  [55.654877, -5.229056, 23.8],
  [55.654796, -5.22893, 23.8],
  [55.654705, -5.228805, 23.8],
  [55.654625, -5.228685, 23.8],
  [55.654534, -5.228566, 23.8],
];

export default stream;
