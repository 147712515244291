import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.775094, 165.830201, 67.8],
  [-10.774996, 165.830126, 67.8],
  [-10.774879, 165.830094, 67.8],
  [-10.774759, 165.830083, 67.8],
  [-10.774637, 165.830115, 67.8],
  [-10.774525, 165.830147, 67.8],
  [-10.774411, 165.830201, 67.8],
  [-10.774299, 165.830255, 67.8],
  [-10.774184, 165.830297, 67.8],
  [-10.774065, 165.830308, 67.8],
  [-10.773941, 165.83033, 67.8],
  [-10.773819, 165.83033, 67.8],
  [-10.773697, 165.83033, 67.8],
  [-10.773574, 165.83033, 67.8],
  [-10.773452, 165.830308, 67.8],
  [-10.773331, 165.830276, 67.8],
  [-10.773215, 165.830255, 67.8],
  [-10.773099, 165.830201, 67.6],
  [-10.772984, 165.830158, 67.6],
  [-10.772863, 165.830126, 67.2],
  [-10.772737, 165.830115, 66.6],
  [-10.772607, 165.830094, 66.2],
  [-10.772476, 165.830094, 66.4],
  [-10.772348, 165.830094, 66.8],
  [-10.772221, 165.830083, 67.2],
  [-10.772099, 165.830083, 67.8],
  [-10.771983, 165.830115, 68.2],
  [-10.771877, 165.830158, 68.6],
  [-10.771801, 165.830255, 68.8],
  [-10.771758, 165.830362, 68.8],
  [-10.77173, 165.830458, 69],
  [-10.771706, 165.830587, 69],
  [-10.771679, 165.830694, 69],
  [-10.771637, 165.830802, 68.8],
  [-10.771572, 165.830909, 68.6],
  [-10.771491, 165.830995, 68.4],
  [-10.771403, 165.83107, 68.2],
  [-10.771311, 165.831167, 68],
  [-10.771214, 165.831242, 67.6],
  [-10.771113, 165.831317, 67.2],
  [-10.771005, 165.831392, 67],
  [-10.770892, 165.831456, 66.6],
  [-10.770773, 165.831521, 66.2],
  [-10.770646, 165.831542, 66],
  [-10.770515, 165.831531, 66],
  [-10.770393, 165.831467, 65.8],
  [-10.77028, 165.831413, 65.8],
  [-10.77017, 165.831338, 66],
  [-10.770059, 165.831274, 66],
  [-10.769934, 165.831242, 66],
  [-10.76981, 165.831274, 66],
  [-10.7697, 165.831338, 66],
  [-10.769604, 165.831413, 66],
  [-10.769512, 165.831499, 66.2],
  [-10.769423, 165.831596, 66.2],
  [-10.769323, 165.831671, 66.2],
  [-10.769212, 165.831735, 66.2],
  [-10.769095, 165.831778, 66.2],
  [-10.76898, 165.831821, 66.2],
  [-10.768864, 165.831864, 66.2],
  [-10.768754, 165.831928, 66.2],
  [-10.768655, 165.832003, 66.2],
  [-10.768562, 165.832078, 66.2],
  [-10.768472, 165.832175, 66.2],
  [-10.768383, 165.832261, 66.2],
  [-10.768286, 165.832336, 66.2],
  [-10.768176, 165.8324, 66.2],
  [-10.768056, 165.832422, 66.2],
  [-10.767932, 165.8324, 66.2],
  [-10.767819, 165.832357, 66.4],
  [-10.767725, 165.832282, 66.6],
  [-10.767638, 165.832186, 66.8],
  [-10.767552, 165.832111, 67],
  [-10.76746, 165.832036, 67.2],
  [-10.76736, 165.831971, 67.4],
  [-10.767244, 165.83196, 67.6],
  [-10.767128, 165.831971, 67.8],
  [-10.767021, 165.832003, 68.2],
  [-10.766921, 165.832068, 68.4],
  [-10.766827, 165.832132, 68.4],
  [-10.766739, 165.832207, 68.6],
  [-10.766657, 165.832282, 68.8],
  [-10.766587, 165.832368, 68.8],
  [-10.766563, 165.832475, 69],
  [-10.766559, 165.832604, 69.4],
  [-10.76651, 165.83269, 69.6],
  [-10.766432, 165.832765, 70],
  [-10.766336, 165.832829, 70.2],
  [-10.766232, 165.832862, 70.4],
  [-10.766125, 165.832872, 70.6],
  [-10.766021, 165.83284, 70.6],
  [-10.765928, 165.832787, 70.4],
  [-10.765842, 165.832711, 70.2],
  [-10.765747, 165.832647, 69.8],
  [-10.765634, 165.832615, 69.6],
  [-10.765515, 165.832583, 69.4],
  [-10.765405, 165.83254, 69.2],
  [-10.765303, 165.832465, 69],
  [-10.765187, 165.832422, 68.8],
  [-10.765063, 165.832433, 68.6],
  [-10.764938, 165.832475, 68.2],
  [-10.764823, 165.832518, 68],
  [-10.764713, 165.832604, 67.8],
  [-10.764619, 165.832679, 67.6],
  [-10.764546, 165.832797, 67.6],
  [-10.764494, 165.832905, 67.6],
  [-10.764458, 165.833044, 67.6],
  [-10.764454, 165.833162, 67.6],
  [-10.764473, 165.833291, 67.6],
  [-10.764479, 165.83342, 67.6],
  [-10.764462, 165.833548, 67.6],
  [-10.764403, 165.833656, 67.8],
  [-10.764306, 165.833741, 67.6],
  [-10.764196, 165.833806, 67.6],
  [-10.764076, 165.833838, 67.6],
  [-10.763952, 165.833838, 67.6],
  [-10.763837, 165.833795, 67.6],
  [-10.763728, 165.833731, 67.6],
  [-10.763628, 165.833656, 67.6],
  [-10.763532, 165.83358, 67.6],
  [-10.763429, 165.833505, 67.6],
  [-10.763315, 165.833441, 67.6],
  [-10.763201, 165.833398, 67.6],
  [-10.763093, 165.833334, 67.6],
  [-10.763007, 165.833259, 67.6],
  [-10.762947, 165.83313, 67.6],
  [-10.762903, 165.833023, 67.6],
  [-10.762857, 165.832905, 67.6],
  [-10.762788, 165.832797, 67.6],
  [-10.762698, 165.832722, 67.6],
  [-10.762599, 165.832647, 67.6],
  [-10.762494, 165.832583, 67.6],
  [-10.762384, 165.832518, 67.6],
  [-10.762269, 165.832475, 67.6],
  [-10.762148, 165.832465, 67.6],
  [-10.762024, 165.832465, 67.6],
  [-10.761901, 165.832475, 67.6],
  [-10.761778, 165.832475, 67.6],
  [-10.761655, 165.832497, 67.6],
  [-10.761533, 165.832497, 67.6],
  [-10.761409, 165.832508, 67.6],
  [-10.761287, 165.832497, 67.6],
  [-10.761168, 165.832465, 67.6],
  [-10.761051, 165.832422, 67.6],
  [-10.760935, 165.83239, 67.6],
  [-10.760817, 165.832357, 67.6],
  [-10.760695, 165.832336, 67.6],
  [-10.760574, 165.832368, 67.6],
  [-10.760463, 165.832422, 67.6],
  [-10.760361, 165.832497, 67.6],
  [-10.760267, 165.832572, 67.6],
  [-10.760172, 165.832647, 67.6],
  [-10.760078, 165.832733, 67.6],
  [-10.759979, 165.832797, 67.6],
  [-10.759872, 165.832862, 67.6],
  [-10.759757, 165.832905, 67.6],
  [-10.75964, 165.832947, 67.8],
  [-10.759522, 165.83298, 67.8],
  [-10.759405, 165.833012, 67.8],
  [-10.759285, 165.833044, 67.8],
  [-10.759169, 165.833076, 67.8],
  [-10.759062, 165.83313, 68],
  [-10.75898, 165.833226, 68],
  [-10.758942, 165.833334, 68.2],
  [-10.758923, 165.833452, 68.2],
  [-10.758884, 165.83358, 68.2],
  [-10.758833, 165.833688, 68.2],
  [-10.758772, 165.833795, 68.2],
  [-10.758686, 165.833881, 68.2],
  [-10.758583, 165.833945, 68.2],
  [-10.758472, 165.833988, 68],
  [-10.758355, 165.834031, 68],
];

export default stream;
