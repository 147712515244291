import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [40.771138, -73.976006, 40.8],
  [40.771036, -73.976269, 40],
  [40.770905, -73.97651, 38.8],
  [40.770746, -73.976719, 37.2],
  [40.770569, -73.976902, 35.6],
  [40.770379, -73.977073, 33.8],
  [40.770189, -73.977229, 32],
  [40.769987, -73.97739, 30.2],
  [40.769789, -73.97754, 28.4],
  [40.769585, -73.977696, 26.4],
  [40.769384, -73.977862, 24.6],
  [40.769202, -73.978044, 23.4],
  [40.769014, -73.978238, 22],
  [40.768834, -73.978436, 21],
  [40.768665, -73.978624, 20.4],
];

export default stream;
