import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-21.656228, 166.205406, 1543],
  [-21.656235, 166.205354, 1543],
  [-21.656236, 166.205295, 1543],
  [-21.656226, 166.205239, 1543],
  [-21.656205, 166.20519, 1543],
  [-21.656171, 166.205145, 1543],
  [-21.656128, 166.205112, 1543],
  [-21.65608, 166.205092, 1543],
  [-21.656032, 166.205085, 1543],
  [-21.655981, 166.205101, 1543],
  [-21.655939, 166.205134, 1543],
];

export default stream;
