import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.703558, 166.931173, 1.2],
  [-11.703584, 166.931291, 1.2],
  [-11.703618, 166.931419, 1.2],
  [-11.703646, 166.931527, 1.2],
  [-11.703667, 166.931645, 1.2],
  [-11.703688, 166.931763, 1.2],
  [-11.703708, 166.931891, 1.2],
  [-11.703731, 166.932009, 1.2],
  [-11.703755, 166.932117, 1.2],
  [-11.703779, 166.932235, 1.2],
  [-11.703803, 166.932342, 1.2],
  [-11.70383, 166.932471, 1.2],
  [-11.703862, 166.932578, 1.2],
  [-11.7039, 166.932696, 1.4],
  [-11.703934, 166.932793, 1.6],
  [-11.703972, 166.9329, 2],
  [-11.704009, 166.933007, 2.4],
  [-11.704046, 166.933115, 2.8],
  [-11.704079, 166.9332, 3.2],
  [-11.704106, 166.933297, 3.6],
  [-11.70413, 166.933404, 4],
  [-11.704149, 166.93349, 4.4],
  [-11.704161, 166.933587, 4.8],
  [-11.704166, 166.933672, 5.2],
  [-11.704165, 166.933769, 5.6],
  [-11.704156, 166.933855, 5.8],
  [-11.70414, 166.933951, 6.2],
  [-11.704119, 166.934037, 6.6],
  [-11.704093, 166.934112, 6.8],
  [-11.704064, 166.934209, 7.2],
  [-11.704032, 166.934284, 7.6],
  [-11.703999, 166.934359, 7.8],
  [-11.703964, 166.934434, 8.2],
  [-11.703928, 166.934509, 8.6],
  [-11.703893, 166.934584, 8.8],
  [-11.703858, 166.93467, 9.2],
  [-11.703823, 166.934745, 9.6],
  [-11.703791, 166.93482, 9.8],
  [-11.703763, 166.934885, 10.2],
  [-11.703736, 166.93496, 10.6],
  [-11.703718, 166.935035, 11],
  [-11.703731, 166.935121, 11.2],
  [-11.703763, 166.935185, 11.4],
  [-11.7038, 166.93526, 11.6],
  [-11.703839, 166.935335, 11.8],
  [-11.703867, 166.93541, 12],
  [-11.70387, 166.935486, 12.2],
  [-11.703839, 166.935571, 12.8],
  [-11.703786, 166.935625, 13.2],
  [-11.70372, 166.935657, 13.6],
  [-11.70365, 166.935689, 14],
  [-11.703578, 166.935722, 14.4],
  [-11.703503, 166.935722, 14.8],
  [-11.70343, 166.9357, 15.2],
  [-11.703362, 166.935689, 15.6],
  [-11.703296, 166.935657, 16],
  [-11.703232, 166.935625, 16.4],
  [-11.703169, 166.935593, 16.8],
  [-11.703104, 166.935571, 17.2],
  [-11.703037, 166.93555, 17.4],
  [-11.70297, 166.935539, 17.8],
  [-11.702902, 166.935518, 18.2],
  [-11.702833, 166.935539, 18.6],
  [-11.702768, 166.93555, 18.8],
  [-11.702703, 166.935582, 19],
  [-11.702642, 166.935625, 19.2],
  [-11.702584, 166.935668, 19.6],
  [-11.702529, 166.935722, 19.8],
  [-11.702482, 166.935775, 20],
  [-11.702439, 166.93584, 20.4],
  [-11.7024, 166.935904, 20.6],
  [-11.70236, 166.935958, 20.8],
  [-11.70231, 166.936022, 21],
  [-11.702251, 166.936065, 21.2],
  [-11.702186, 166.936119, 21.4],
  [-11.702116, 166.936151, 21.4],
  [-11.702041, 166.936194, 21.6],
  [-11.701964, 166.936226, 21.6],
  [-11.701887, 166.936258, 21.6],
  [-11.70181, 166.936301, 21.8],
  [-11.701742, 166.936355, 21.8],
  [-11.701688, 166.936419, 22.2],
  [-11.701671, 166.936516, 22.6],
  [-11.701709, 166.936591, 23],
  [-11.70177, 166.936644, 23.4],
  [-11.701832, 166.936698, 23.8],
  [-11.701893, 166.936752, 24],
  [-11.701944, 166.936816, 24.4],
  [-11.701984, 166.936891, 24.8],
  [-11.702025, 166.936955, 25],
  [-11.702064, 166.937031, 25.4],
  [-11.702102, 166.937095, 25.6],
  [-11.702141, 166.93717, 26],
  [-11.702172, 166.937245, 26.4],
  [-11.702195, 166.93732, 26.6],
  [-11.702211, 166.937395, 27],
  [-11.702214, 166.93747, 27.4],
  [-11.702199, 166.937535, 27.8],
  [-11.702168, 166.93761, 28.2],
  [-11.702129, 166.937674, 28.4],
  [-11.702085, 166.937728, 28.8],
  [-11.702037, 166.937782, 29.2],
  [-11.70199, 166.937835, 29.6],
  [-11.701946, 166.9379, 29.8],
  [-11.701915, 166.937964, 30.4],
  [-11.701911, 166.938039, 30.8],
  [-11.701947, 166.938082, 31.4],
  [-11.702, 166.938125, 31.6],
  [-11.70206, 166.938157, 32],
  [-11.702125, 166.938179, 32],
  [-11.702195, 166.938189, 32.2],
  [-11.702267, 166.938189, 32.2],
  [-11.702344, 166.938189, 32.4],
  [-11.70242, 166.938189, 32.4],
  [-11.702501, 166.938189, 32.4],
  [-11.702583, 166.938179, 32.4],
  [-11.702666, 166.938189, 32.6],
  [-11.702749, 166.938211, 32.8],
  [-11.702829, 166.938232, 33],
  [-11.702898, 166.938286, 33.2],
  [-11.70295, 166.938361, 33.4],
  [-11.70298, 166.938436, 33.8],
  [-11.70299, 166.938522, 34.2],
  [-11.702988, 166.938608, 34.4],
  [-11.702969, 166.938683, 34.8],
  [-11.702934, 166.938758, 35.2],
  [-11.702882, 166.938812, 35.6],
  [-11.702817, 166.938865, 36],
  [-11.70275, 166.938897, 36.4],
  [-11.70268, 166.938919, 36.8],
  [-11.702608, 166.938951, 37.2],
  [-11.702536, 166.938972, 37.4],
  [-11.702463, 166.939005, 37.8],
  [-11.702391, 166.939015, 38.2],
  [-11.702318, 166.939015, 38.4],
  [-11.702245, 166.939026, 38.8],
  [-11.702172, 166.939048, 39],
  [-11.7021, 166.939058, 39.4],
  [-11.702027, 166.93908, 39.6],
  [-11.701955, 166.93909, 40],
  [-11.701885, 166.939101, 40.4],
  [-11.701815, 166.939133, 40.6],
  [-11.70175, 166.939166, 41],
  [-11.701687, 166.939198, 41.4],
  [-11.701628, 166.939241, 41.8],
  [-11.701578, 166.939294, 42.2],
  [-11.701532, 166.939337, 42.6],
  [-11.701495, 166.939391, 43],
  [-11.701461, 166.939455, 43.4],
  [-11.701431, 166.93952, 43.6],
  [-11.701404, 166.939584, 44],
  [-11.701381, 166.939659, 44.4],
  [-11.701362, 166.939713, 44.6],
  [-11.701346, 166.939777, 45],
  [-11.701333, 166.939852, 45.2],
  [-11.701322, 166.939917, 45.6],
  [-11.701314, 166.939992, 45.8],
  [-11.701308, 166.940067, 46],
  [-11.701306, 166.940131, 46.4],
  [-11.701304, 166.940206, 46.6],
  [-11.701307, 166.940281, 47],
  [-11.70131, 166.940356, 47.2],
  [-11.701317, 166.940432, 47.4],
  [-11.701323, 166.940496, 47.8],
  [-11.701333, 166.940571, 48],
  [-11.701343, 166.940646, 48.4],
  [-11.701355, 166.940721, 48.6],
  [-11.70137, 166.940786, 49],
  [-11.701385, 166.940861, 49.4],
  [-11.701402, 166.940936, 49.6],
  [-11.701422, 166.941, 50],
  [-11.701445, 166.941075, 50.2],
  [-11.701471, 166.94115, 50.4],
  [-11.701499, 166.941215, 50.6],
  [-11.701531, 166.94129, 51],
  [-11.701566, 166.941354, 51.2],
  [-11.701605, 166.941408, 51.4],
  [-11.701646, 166.941483, 51.6],
  [-11.701693, 166.941547, 52],
  [-11.701746, 166.941612, 52.2],
  [-11.701801, 166.941655, 52.6],
  [-11.701861, 166.941698, 52.8],
  [-11.701924, 166.941751, 53.2],
  [-11.701992, 166.941773, 53.6],
  [-11.702064, 166.941794, 54.2],
  [-11.702133, 166.941805, 54.6],
  [-11.702202, 166.941794, 55.2],
  [-11.702267, 166.941773, 55.6],
  [-11.702324, 166.941751, 56],
  [-11.70238, 166.941698, 56.4],
  [-11.70243, 166.941655, 56.6],
  [-11.702479, 166.941612, 57],
  [-11.702529, 166.941558, 57.2],
  [-11.702576, 166.941515, 57.6],
  [-11.702624, 166.941472, 57.8],
  [-11.702672, 166.941429, 58.2],
  [-11.702723, 166.941376, 58.4],
  [-11.702777, 166.941333, 58.8],
  [-11.702836, 166.94129, 59],
  [-11.702894, 166.941258, 59.4],
  [-11.702958, 166.941226, 59.6],
  [-11.703026, 166.941215, 60],
  [-11.703096, 166.941193, 60.2],
  [-11.703166, 166.941193, 60.6],
  [-11.703236, 166.941183, 61],
  [-11.703307, 166.941183, 61.2],
  [-11.703376, 166.941161, 61.6],
  [-11.703446, 166.941161, 61.8],
  [-11.703516, 166.94115, 62.2],
  [-11.703587, 166.94115, 62.6],
  [-11.703658, 166.94115, 62.6],
  [-11.70372, 166.941193, 62.6],
  [-11.703763, 166.941258, 62.6],
  [-11.70379, 166.941333, 62.6],
  [-11.703803, 166.941408, 62.6],
  [-11.703814, 166.941504, 62.6],
  [-11.703823, 166.94159, 62.6],
  [-11.703832, 166.941687, 62.4],
  [-11.703853, 166.941773, 62.4],
  [-11.70389, 166.941848, 62.4],
  [-11.703967, 166.941912, 62.4],
  [-11.70406, 166.941912, 62.4],
  [-11.704157, 166.941912, 62.4],
  [-11.704252, 166.941901, 62.4],
  [-11.704341, 166.941848, 62.4],
  [-11.704412, 166.941773, 62.4],
  [-11.704463, 166.941698, 62.6],
  [-11.704496, 166.94159, 62.6],
  [-11.704514, 166.941504, 62.6],
  [-11.704519, 166.941397, 62.6],
  [-11.704518, 166.94129, 62.6],
  [-11.704516, 166.941193, 62.8],
  [-11.704522, 166.941086, 62.8],
  [-11.704539, 166.940979, 62.8],
  [-11.704563, 166.940893, 63],
  [-11.704597, 166.940786, 63],
  [-11.704634, 166.940689, 63.2],
  [-11.704668, 166.940603, 63.6],
  [-11.70466, 166.940496, 63.8],
  [-11.704609, 166.940421, 64.2],
  [-11.704533, 166.940356, 64.4],
  [-11.704441, 166.940324, 64.6],
  [-11.704346, 166.940324, 64.8],
  [-11.704252, 166.940324, 65],
  [-11.704156, 166.940346, 65],
  [-11.704063, 166.940356, 65.2],
  [-11.703968, 166.940378, 65.4],
  [-11.703873, 166.940356, 65.6],
  [-11.70378, 166.940346, 65.8],
  [-11.703696, 166.940314, 66],
  [-11.703638, 166.940238, 66.2],
  [-11.703607, 166.940142, 66.6],
  [-11.703591, 166.940056, 67],
  [-11.703582, 166.93996, 67.2],
  [-11.70357, 166.939884, 67.6],
  [-11.703524, 166.939809, 68],
  [-11.70344, 166.939809, 68.2],
  [-11.703358, 166.939842, 68.4],
  [-11.703275, 166.939874, 68.6],
  [-11.703191, 166.939884, 68.8],
  [-11.703106, 166.939917, 69],
  [-11.703022, 166.939927, 69.2],
  [-11.702938, 166.939949, 69.2],
  [-11.702852, 166.939981, 69.4],
  [-11.702766, 166.940002, 69.6],
  [-11.702682, 166.940024, 69.8],
  [-11.702595, 166.940056, 70],
  [-11.702509, 166.940067, 70.2],
  [-11.702423, 166.940099, 70.4],
  [-11.702337, 166.94011, 70.4],
  [-11.702251, 166.940142, 70.6],
  [-11.702179, 166.940206, 70.6],
  [-11.702136, 166.940281, 70.4],
  [-11.702144, 166.940378, 70.4],
  [-11.702161, 166.940475, 70.4],
  [-11.70218, 166.940571, 70.2],
  [-11.702199, 166.940678, 70.2],
  [-11.702218, 166.940764, 70],
  [-11.702238, 166.940871, 70],
  [-11.702257, 166.940979, 70],
  [-11.702278, 166.941086, 69.8],
  [-11.70232, 166.941183, 69.8],
  [-11.702377, 166.941268, 69.8],
  [-11.702447, 166.941354, 69.6],
  [-11.702525, 166.941429, 69.6],
  [-11.702607, 166.941504, 69.6],
  [-11.70269, 166.94158, 69.6],
  [-11.702773, 166.941644, 69.4],
  [-11.702856, 166.941719, 69.4],
  [-11.702933, 166.941794, 69.4],
  [-11.703002, 166.94188, 69.4],
  [-11.703059, 166.941977, 69.2],
  [-11.703091, 166.942084, 69.2],
  [-11.703106, 166.942191, 69],
  [-11.703116, 166.942309, 69],
  [-11.703132, 166.942416, 68.8],
  [-11.703173, 166.942524, 68.8],
  [-11.703222, 166.942631, 68.6],
  [-11.703277, 166.942738, 68.6],
  [-11.703344, 166.942824, 68.6],
  [-11.703431, 166.942888, 68.6],
  [-11.703539, 166.942921, 69],
  [-11.703646, 166.94291, 69.2],
  [-11.703744, 166.942856, 69.6],
  [-11.703832, 166.942813, 69.8],
  [-11.703918, 166.942749, 70.2],
  [-11.704007, 166.942695, 70.6],
  [-11.704101, 166.942663, 70.8],
  [-11.704199, 166.942642, 71.2],
  [-11.704292, 166.942674, 71.4],
  [-11.704378, 166.942706, 71.8],
  [-11.704455, 166.94277, 72],
  [-11.70452, 166.942846, 72.2],
  [-11.704574, 166.942921, 72.4],
  [-11.704602, 166.943017, 72.8],
  [-11.704596, 166.943103, 73.2],
  [-11.704555, 166.943178, 73.6],
  [-11.704498, 166.943243, 74],
  [-11.704433, 166.943307, 74.4],
  [-11.704368, 166.943361, 74.8],
  [-11.704306, 166.943425, 75.2],
  [-11.704258, 166.943489, 75.6],
  [-11.704235, 166.943564, 76.2],
  [-11.704228, 166.94364, 76.6],
  [-11.704228, 166.943725, 77],
  [-11.704229, 166.943811, 77.6],
  [-11.704236, 166.943886, 78],
  [-11.704246, 166.94394, 78.4],
  [-11.704259, 166.944015, 78.8],
  [-11.704274, 166.944079, 79.2],
  [-11.704291, 166.944155, 79.6],
  [-11.704311, 166.944219, 79.8],
  [-11.704334, 166.944294, 80],
  [-11.704358, 166.944369, 80.2],
  [-11.704384, 166.944433, 80.2],
  [-11.704409, 166.944509, 80.2],
  [-11.704436, 166.944584, 80.2],
  [-11.704468, 166.944659, 80],
  [-11.704502, 166.944755, 79.8],
  [-11.704539, 166.94483, 79.8],
  [-11.704575, 166.944916, 79.6],
  [-11.704604, 166.945013, 79.6],
  [-11.704621, 166.945099, 79.4],
  [-11.704618, 166.945206, 79.4],
  [-11.704575, 166.945302, 79.2],
  [-11.704499, 166.945378, 79],
  [-11.704404, 166.945421, 78.8],
  [-11.704302, 166.945453, 78.4],
  [-11.704192, 166.945474, 78.2],
  [-11.704082, 166.945485, 78],
  [-11.703968, 166.945485, 77.6],
  [-11.703853, 166.945496, 77.4],
  [-11.703737, 166.945517, 77],
  [-11.703623, 166.945549, 76.6],
  [-11.703511, 166.945592, 76.2],
  [-11.703397, 166.945635, 75.8],
  [-11.703285, 166.945678, 75.4],
  [-11.703177, 166.945764, 75],
  [-11.703073, 166.945817, 74.4],
  [-11.702969, 166.945914, 74],
  [-11.702879, 166.946, 73.4],
  [-11.702789, 166.946096, 72.8],
  [-11.702701, 166.946204, 72.4],
  [-11.702615, 166.946311, 71.8],
  [-11.702529, 166.946418, 71.2],
  [-11.702442, 166.946526, 70.6],
  [-11.702356, 166.946633, 70],
  [-11.702282, 166.946751, 69.2],
  [-11.702267, 166.94689, 68.8],
  [-11.702301, 166.94703, 68.6],
  [-11.702383, 166.947137, 68.4],
  [-11.702493, 166.947223, 68.6],
  [-11.702601, 166.947298, 68.8],
  [-11.702691, 166.947395, 69.2],
  [-11.702754, 166.947502, 69.8],
  [-11.702805, 166.94762, 70.4],
  [-11.702847, 166.947727, 71],
  [-11.70288, 166.947834, 71.4],
  [-11.702911, 166.947942, 71.8],
  [-11.702942, 166.948049, 72.2],
  [-11.702973, 166.948135, 72.6],
  [-11.703004, 166.948242, 73],
  [-11.703029, 166.948339, 73.2],
  [-11.703049, 166.948446, 73.6],
  [-11.703064, 166.948532, 74],
  [-11.703073, 166.948628, 74.2],
  [-11.703075, 166.948736, 74.4],
  [-11.703067, 166.948822, 74.4],
  [-11.703051, 166.948918, 74.6],
  [-11.703013, 166.949004, 74.4],
  [-11.702947, 166.94909, 74],
  [-11.70286, 166.949143, 73.4],
  [-11.702762, 166.949197, 72.8],
  [-11.702659, 166.94924, 72],
  [-11.702561, 166.949304, 71.4],
  [-11.702482, 166.94939, 71.2],
  [-11.702434, 166.949508, 71.2],
  [-11.70243, 166.949615, 71.4],
  [-11.702462, 166.949744, 71.8],
  [-11.702513, 166.949852, 72.2],
  [-11.70257, 166.949937, 72.6],
  [-11.702624, 166.950034, 73],
  [-11.702664, 166.950141, 73.4],
  [-11.70269, 166.950227, 73.6],
  [-11.702698, 166.950334, 74],
  [-11.70269, 166.950442, 74.2],
  [-11.702667, 166.950538, 74.6],
  [-11.702642, 166.950624, 74.8],
  [-11.702615, 166.950731, 75],
  [-11.702588, 166.950828, 75.4],
  [-11.702566, 166.950914, 75.6],
  [-11.702552, 166.95101, 76],
  [-11.702544, 166.951096, 76.2],
  [-11.702544, 166.951193, 76.4],
  [-11.702545, 166.951278, 76.8],
  [-11.702545, 166.951375, 77],
  [-11.702545, 166.951461, 77.2],
  [-11.702548, 166.951557, 77.4],
  [-11.702553, 166.951654, 77.8],
  [-11.702562, 166.95174, 78],
  [-11.702579, 166.951836, 78.2],
  [-11.702601, 166.951922, 78.4],
  [-11.702629, 166.951997, 78.6],
  [-11.702667, 166.952094, 78.8],
  [-11.702726, 166.952147, 79],
  [-11.702815, 166.952169, 79],
  [-11.702906, 166.952169, 79],
  [-11.703, 166.952169, 79],
  [-11.703093, 166.952169, 79],
  [-11.703179, 166.952212, 79],
  [-11.703246, 166.952287, 79.2],
  [-11.703304, 166.952362, 79.6],
  [-11.703355, 166.952437, 79.8],
  [-11.703401, 166.952523, 80],
  [-11.703442, 166.952609, 80.4],
  [-11.70348, 166.952705, 80.6],
  [-11.703512, 166.95278, 80.8],
  [-11.703541, 166.952866, 81.2],
  [-11.70357, 166.952963, 81.4],
  [-11.703595, 166.953038, 81.8],
  [-11.703621, 166.953124, 82],
  [-11.703641, 166.95322, 82.4],
  [-11.703654, 166.953295, 82.6],
  [-11.703653, 166.953392, 83],
  [-11.703621, 166.953467, 83.2],
  [-11.703563, 166.953542, 83.4],
  [-11.703492, 166.953585, 83.6],
  [-11.703423, 166.953628, 83.8],
  [-11.703363, 166.953692, 84.2],
  [-11.703311, 166.953768, 84.4],
  [-11.703269, 166.953843, 84.8],
  [-11.703238, 166.953918, 85.2],
  [-11.703222, 166.954004, 85.4],
  [-11.703222, 166.954089, 85.8],
  [-11.703244, 166.954165, 86],
  [-11.703287, 166.95424, 86.2],
  [-11.703344, 166.954304, 86.4],
  [-11.703407, 166.954347, 86.6],
  [-11.703476, 166.954411, 86.8],
  [-11.703547, 166.954454, 87],
  [-11.703618, 166.954497, 87.2],
  [-11.703689, 166.954551, 87.4],
  [-11.703761, 166.954604, 87.4],
  [-11.703839, 166.954637, 87.6],
  [-11.703918, 166.95469, 87.8],
  [-11.704003, 166.954712, 88.2],
  [-11.704087, 166.954733, 88.6],
  [-11.704173, 166.954733, 89],
  [-11.704255, 166.954712, 89.6],
  [-11.704331, 166.954701, 90.2],
  [-11.704402, 166.954669, 90.8],
  [-11.704469, 166.954637, 91.2],
  [-11.704535, 166.954604, 91.8],
  [-11.704596, 166.954583, 92.2],
  [-11.704656, 166.954529, 92.6],
  [-11.704719, 166.954497, 93],
  [-11.704786, 166.954497, 93.4],
  [-11.704853, 166.954497, 93.8],
  [-11.704913, 166.954529, 94.2],
  [-11.70497, 166.954583, 94.4],
  [-11.705021, 166.954626, 94.6],
  [-11.705069, 166.954669, 94.8],
  [-11.705113, 166.954733, 94.8],
  [-11.705151, 166.954808, 95],
  [-11.705174, 166.954883, 95],
  [-11.705172, 166.954958, 94.8],
  [-11.705145, 166.955034, 94.6],
  [-11.7051, 166.955109, 94.4],
  [-11.705049, 166.955184, 94.2],
  [-11.705025, 166.95528, 94.2],
  [-11.705025, 166.955388, 94.6],
  [-11.705025, 166.955484, 95.2],
  [-11.705026, 166.95557, 95.8],
  [-11.705029, 166.955667, 96.4],
  [-11.705039, 166.955742, 96.8],
  [-11.705057, 166.955817, 97.2],
  [-11.705084, 166.955903, 97.2],
  [-11.705117, 166.955978, 96.8],
  [-11.70516, 166.956064, 96.2],
  [-11.705219, 166.956149, 95.4],
  [-11.705301, 166.956214, 94.8],
  [-11.705407, 166.956224, 94.6],
  [-11.705514, 166.956214, 94.8],
  [-11.705615, 166.956171, 95.2],
  [-11.70571, 166.956117, 95.4],
  [-11.705804, 166.956074, 96],
  [-11.705903, 166.956064, 96.2],
  [-11.706001, 166.956074, 96.6],
  [-11.706096, 166.956085, 97],
  [-11.706186, 166.956139, 97.2],
  [-11.706269, 166.956182, 97.6],
  [-11.706344, 166.956224, 97.8],
  [-11.70641, 166.9563, 98.2],
  [-11.706464, 166.956375, 98.4],
  [-11.7065, 166.95646, 98.8],
  [-11.706521, 166.956546, 99],
  [-11.706531, 166.956643, 99.2],
  [-11.706533, 166.956729, 99.4],
  [-11.706532, 166.956825, 99.8],
  [-11.706531, 166.956911, 100],
  [-11.706536, 166.957008, 100.4],
  [-11.70655, 166.957083, 100.8],
  [-11.706562, 166.957169, 101.2],
  [-11.706574, 166.957244, 101.6],
  [-11.706584, 166.95734, 102.2],
  [-11.706594, 166.957415, 102.6],
  [-11.706603, 166.95749, 103],
  [-11.706615, 166.957566, 103.4],
  [-11.706629, 166.957641, 104],
  [-11.706645, 166.957726, 104.4],
  [-11.706662, 166.95778, 104.8],
  [-11.706681, 166.957855, 105.4],
  [-11.706698, 166.95792, 105.8],
  [-11.706714, 166.957995, 106.2],
  [-11.706729, 166.958059, 106.6],
  [-11.706744, 166.958123, 107],
  [-11.706761, 166.958199, 107.4],
  [-11.706786, 166.958252, 108],
  [-11.706816, 166.958306, 108.4],
  [-11.706859, 166.958349, 109],
  [-11.706909, 166.958381, 109.6],
  [-11.706964, 166.958392, 110.2],
  [-11.707018, 166.958392, 110.6],
  [-11.707071, 166.958392, 111.2],
  [-11.707124, 166.958381, 111.6],
  [-11.707173, 166.958359, 111.8],
  [-11.707223, 166.958327, 112.2],
  [-11.707271, 166.958317, 112.6],
  [-11.707321, 166.958284, 112.8],
  [-11.707372, 166.958252, 113.2],
  [-11.707423, 166.958231, 113.4],
  [-11.707476, 166.958199, 113.8],
  [-11.707533, 166.958177, 114],
  [-11.707592, 166.958145, 114.4],
  [-11.707653, 166.958145, 114.6],
  [-11.707716, 166.958145, 115],
  [-11.707779, 166.958166, 115.2],
  [-11.707841, 166.958177, 115.6],
  [-11.707903, 166.958199, 115.8],
  [-11.707963, 166.958231, 116.2],
  [-11.708025, 166.958252, 116.4],
  [-11.708084, 166.958284, 116.6],
  [-11.708145, 166.958327, 116.8],
  [-11.708204, 166.958359, 117],
  [-11.708263, 166.958413, 117.2],
  [-11.708322, 166.958456, 117.4],
  [-11.708379, 166.958499, 117.6],
  [-11.708432, 166.958563, 117.8],
  [-11.708485, 166.958606, 118],
  [-11.708534, 166.958671, 118.2],
  [-11.708581, 166.958746, 118.4],
  [-11.708623, 166.95881, 118.6],
  [-11.708659, 166.958885, 119],
  [-11.708689, 166.95896, 119.2],
  [-11.708711, 166.959035, 119.4],
  [-11.708722, 166.95911, 119.6],
  [-11.708722, 166.959207, 119.8],
  [-11.708711, 166.959282, 119.8],
  [-11.708693, 166.959368, 120],
  [-11.708666, 166.959443, 120],
  [-11.708632, 166.959518, 120.2],
  [-11.708591, 166.959615, 120.2],
  [-11.708545, 166.95969, 120.4],
  [-11.708493, 166.959754, 120.6],
  [-11.708436, 166.959829, 120.6],
  [-11.708372, 166.959894, 121],
  [-11.708294, 166.959937, 121.2],
  [-11.708206, 166.959947, 121.4],
  [-11.708117, 166.959947, 121.8],
  [-11.708029, 166.959937, 122],
  [-11.707943, 166.959937, 122.2],
  [-11.707856, 166.959915, 122.6],
  [-11.707769, 166.959937, 123],
  [-11.707685, 166.959947, 123.2],
  [-11.707605, 166.95998, 123.4],
  [-11.707526, 166.960012, 123.8],
  [-11.707451, 166.960055, 124],
  [-11.707378, 166.960087, 124.4],
  [-11.707309, 166.960151, 124.8],
  [-11.707246, 166.960194, 125.4],
  [-11.707189, 166.960258, 125.8],
  [-11.70714, 166.960312, 126.2],
  [-11.707091, 166.960366, 126.8],
  [-11.707044, 166.960419, 127.2],
  [-11.706997, 166.960484, 127.8],
  [-11.706946, 166.960527, 128.2],
  [-11.706892, 166.96058, 128.6],
  [-11.706834, 166.960602, 129.2],
  [-11.706771, 166.960634, 129.6],
  [-11.706706, 166.960655, 129.8],
  [-11.706641, 166.960634, 130.2],
  [-11.706579, 166.960602, 130.4],
  [-11.706519, 166.96058, 130.8],
  [-11.706458, 166.960548, 131],
  [-11.706398, 166.960516, 131.2],
  [-11.706335, 166.960484, 131.6],
  [-11.706265, 166.960473, 132.2],
  [-11.706202, 166.960495, 132.8],
  [-11.70615, 166.960527, 133.2],
  [-11.706107, 166.96058, 133.6],
  [-11.706073, 166.960634, 134.2],
  [-11.706047, 166.960698, 134.6],
  [-11.706028, 166.960741, 135],
  [-11.706016, 166.960806, 135.4],
  [-11.706013, 166.96087, 136],
  [-11.706021, 166.960924, 136.6],
  [-11.706048, 166.960977, 137.2],
  [-11.706084, 166.96102, 137.6],
  [-11.706127, 166.961052, 138],
  [-11.70617, 166.961074, 138.4],
  [-11.706216, 166.961095, 138.8],
  [-11.70626, 166.961128, 139.2],
  [-11.706301, 166.96117, 139.4],
  [-11.706335, 166.961213, 139.8],
  [-11.70636, 166.961267, 140.2],
  [-11.706377, 166.96131, 140.4],
  [-11.706383, 166.961364, 140.6],
  [-11.706383, 166.961428, 140.8],
  [-11.706374, 166.961492, 141],
  [-11.706358, 166.961567, 141.2],
  [-11.706331, 166.961632, 141.4],
  [-11.706293, 166.961675, 141.8],
  [-11.706248, 166.961739, 142],
  [-11.706195, 166.961782, 142.4],
  [-11.706135, 166.961814, 142.8],
  [-11.706071, 166.961846, 143],
  [-11.706002, 166.961857, 143.4],
  [-11.705933, 166.961857, 143.6],
  [-11.705863, 166.961846, 144],
  [-11.705799, 166.961825, 144.2],
  [-11.705735, 166.961782, 144.4],
  [-11.705675, 166.961739, 144.6],
  [-11.705615, 166.961685, 144.8],
  [-11.70555, 166.961664, 145.2],
  [-11.705479, 166.961632, 145.4],
  [-11.705407, 166.961632, 145.8],
  [-11.705334, 166.961643, 146],
  [-11.705275, 166.961685, 145.8],
  [-11.705225, 166.961761, 145.6],
  [-11.705179, 166.961846, 145.2],
  [-11.70514, 166.961921, 144.8],
  [-11.705111, 166.962007, 144.2],
  [-11.705088, 166.962115, 143.8],
  [-11.70508, 166.962222, 143.6],
  [-11.705097, 166.962329, 143.4],
  [-11.705147, 166.962426, 143.4],
  [-11.705212, 166.962512, 143.4],
  [-11.705286, 166.962608, 143.4],
  [-11.70536, 166.962683, 143.4],
  [-11.705427, 166.962769, 143.6],
  [-11.705477, 166.962866, 143.6],
  [-11.705514, 166.962973, 143.6],
  [-11.705544, 166.96308, 143.6],
  [-11.705569, 166.963187, 143.6],
  [-11.705589, 166.963295, 143.8],
  [-11.705605, 166.963402, 143.6],
  [-11.705623, 166.963509, 143.6],
  [-11.70564, 166.963617, 143.4],
  [-11.705664, 166.963724, 143.2],
  [-11.705694, 166.963842, 143],
  [-11.705727, 166.963949, 142.8],
  [-11.705761, 166.964056, 142.4],
  [-11.70579, 166.964175, 142.2],
  [-11.705811, 166.964303, 141.8],
  [-11.70582, 166.964421, 141.4],
  [-11.705813, 166.964539, 141],
  [-11.705784, 166.964668, 140.4],
  [-11.70573, 166.964775, 140],
  [-11.705663, 166.964883, 139.6],
  [-11.705583, 166.96499, 139.2],
  [-11.705487, 166.965076, 138.6],
  [-11.70538, 166.965151, 138.2],
  [-11.705257, 166.965194, 137.8],
  [-11.705125, 166.965215, 137.4],
  [-11.704997, 166.965183, 137],
  [-11.704873, 166.965119, 136.6],
  [-11.70474, 166.965119, 136.2],
  [-11.704614, 166.965172, 135.8],
  [-11.704499, 166.965226, 135.6],
  [-11.704397, 166.965322, 135.2],
  [-11.70431, 166.96543, 135],
  [-11.704243, 166.965537, 135],
  [-11.704209, 166.965677, 134.8],
  [-11.704208, 166.965795, 134.8],
  [-11.704211, 166.965934, 134.8],
  [-11.704213, 166.966052, 134.8],
  [-11.704216, 166.966192, 134.8],
  [-11.704216, 166.96631, 134.8],
  [-11.704216, 166.966438, 134.8],
  [-11.704216, 166.966556, 134.8],
  [-11.704192, 166.966674, 134.8],
];
export default stream;
