import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.692556, 166.930518, 0.6],
  [-11.692657, 166.93055, 0.6],
  [-11.692771, 166.93055, 0.6],
  [-11.692881, 166.930529, 0.6],
  [-11.69299, 166.930529, 0.4],
  [-11.693097, 166.930518, 0.6],
  [-11.693214, 166.930518, 0.6],
  [-11.693326, 166.930518, 0.6],
  [-11.693436, 166.930518, 0.6],
  [-11.693542, 166.930518, 0.6],
  [-11.693652, 166.930497, 0.6],
  [-11.693765, 166.930497, 0.6],
  [-11.693876, 166.930486, 0.6],
  [-11.693986, 166.930486, 0.6],
  [-11.694103, 166.930497, 0.6],
  [-11.69421, 166.930497, 0.6],
  [-11.694323, 166.930518, 0.6],
  [-11.694434, 166.930518, 0.6],
  [-11.694541, 166.930518, 0.6],
  [-11.694655, 166.930529, 0.6],
  [-11.694767, 166.93055, 0.6],
  [-11.694877, 166.930561, 0.6],
  [-11.694984, 166.930593, 0.6],
  [-11.69509, 166.930625, 0.6],
  [-11.695192, 166.930668, 0.6],
  [-11.69528, 166.930743, 0.4],
  [-11.695351, 166.93084, 0.4],
  [-11.695408, 166.930926, 0.4],
  [-11.695461, 166.931033, 0.4],
  [-11.695519, 166.93113, 0.4],
  [-11.695593, 166.931216, 0.4],
  [-11.695685, 166.93128, 0.6],
  [-11.695774, 166.931355, 0.6],
  [-11.695862, 166.931419, 0.8],
  [-11.695955, 166.931473, 1],
  [-11.696046, 166.931537, 1],
  [-11.696132, 166.931602, 1.2],
  [-11.696223, 166.931677, 1.4],
  [-11.696317, 166.93172, 1.4],
  [-11.696407, 166.931784, 1.6],
  [-11.696501, 166.931838, 1.6],
  [-11.696593, 166.931891, 1.8],
  [-11.696686, 166.931934, 1.8],
  [-11.696781, 166.931977, 2],
  [-11.69688, 166.932009, 2],
  [-11.696982, 166.932052, 2],
  [-11.697085, 166.932085, 2],
  [-11.69719, 166.932106, 2],
  [-11.697296, 166.932106, 1.8],
  [-11.697409, 166.932085, 1.6],
  [-11.697513, 166.932052, 1.4],
  [-11.697623, 166.932031, 1.2],
  [-11.697732, 166.931999, 1],
  [-11.697844, 166.931967, 0.8],
  [-11.697961, 166.931945, 0.6],
  [-11.698082, 166.931945, 0.6],
  [-11.698193, 166.931967, 0.6],
  [-11.698303, 166.932031, 0.6],
  [-11.698396, 166.932085, 0.6],
  [-11.698488, 166.93216, 0.6],
  [-11.698583, 166.932224, 0.6],
  [-11.698689, 166.932267, 0.6],
  [-11.698802, 166.932299, 0.6],
  [-11.698924, 166.932299, 0.6],
  [-11.699033, 166.932299, 0.6],
  [-11.699152, 166.932288, 0.6],
  [-11.699262, 166.932267, 0.8],
  [-11.699375, 166.932256, 0.8],
  [-11.699487, 166.932235, 1],
  [-11.699591, 166.932213, 1],
  [-11.699699, 166.932181, 1.2],
  [-11.699805, 166.932149, 1.2],
  [-11.699918, 166.932117, 1.2],
  [-11.70002, 166.932074, 1.2],
  [-11.700114, 166.932009, 1.2],
  [-11.700198, 166.931945, 1.2],
  [-11.700279, 166.93187, 1],
  [-11.700364, 166.931784, 1],
  [-11.700456, 166.93172, 0.8],
  [-11.700556, 166.931677, 0.8],
  [-11.700665, 166.931645, 0.8],
  [-11.700781, 166.931645, 0.8],
  [-11.700893, 166.931645, 0.6],
  [-11.701003, 166.931655, 0.6],
  [-11.701115, 166.931677, 0.6],
  [-11.701229, 166.931709, 0.6],
  [-11.701346, 166.931741, 0.6],
  [-11.701452, 166.931763, 0.6],
  [-11.701558, 166.931795, 0.6],
  [-11.701673, 166.931827, 0.6],
  [-11.701786, 166.931859, 0.6],
  [-11.701899, 166.93187, 0.6],
  [-11.702009, 166.931891, 0.6],
  [-11.702123, 166.931902, 0.6],
  [-11.702231, 166.931902, 0.6],
  [-11.702344, 166.931891, 0.8],
  [-11.70245, 166.931838, 0.8],
  [-11.702541, 166.931784, 1],
  [-11.702599, 166.931688, 1.2],
  [-11.70264, 166.93158, 1.4],
  [-11.702671, 166.931473, 1.6],
  [-11.702702, 166.931366, 1.8],
  [-11.702733, 166.931258, 2],
  [-11.702774, 166.931173, 2.2],
  [-11.702832, 166.931076, 2.2],
  [-11.702904, 166.931022, 2.2],
  [-11.702998, 166.930958, 2.2],
  [-11.703091, 166.930915, 2.2],
];
export default stream;
