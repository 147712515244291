import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [55.643799, -5.222557, 27.8],
  [55.643681, -5.222589, 27.8],
  [55.643579, -5.222688, 27.8],
  [55.643483, -5.222806, 27.8],
  [55.643386, -5.222918, 27.8],
];

export default stream;
