import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [37.544647, -77.440401, 53.6],
  [37.544771, -77.440599, 53.6],
  [37.544889, -77.440803, 53.6],
  [37.545009, -77.441002, 53.6],
  [37.54513, -77.4412, 53.6],
  [37.545253, -77.441409, 53.6],
  [37.545374, -77.441608, 53.6],
  [37.545492, -77.441806, 53.6],
  [37.54561, -77.44201, 53.6],
  [37.545731, -77.442209, 53.6],
  [37.545849, -77.442418, 53.6],
  [37.545972, -77.442616, 53.6],
  [37.54609, -77.44282, 53.6],
  [37.546208, -77.443019, 53.8],
  [37.546326, -77.443217, 53.8],
  [37.546444, -77.443416, 53.8],
  [37.546562, -77.443614, 53.8],
  [37.54668, -77.443818, 53.8],
  [37.546798, -77.444016, 53.8],
  [37.546919, -77.444215, 53.6],
  [37.54704, -77.444424, 53.6],
  [37.547155, -77.444623, 53.4],
  [37.547276, -77.444826, 53.2],
  [37.547399, -77.445025, 52.8],
  [37.547517, -77.445229, 52.6],
  [37.547638, -77.445438, 52.4],
  [37.547756, -77.445636, 52.2],
  [37.547882, -77.445851, 52.2],
  [37.548, -77.446049, 52],
  [37.548123, -77.446253, 52],
  [37.548241, -77.446452, 52.2],
  [37.548365, -77.446656, 52.4],
  [37.548485, -77.446865, 52.6],
  [37.548601, -77.447063, 52.6],
  [37.548649, -77.447299, 52.4],
  [37.548539, -77.447503, 52],
  [37.54837, -77.447643, 51.8],
  [37.548198, -77.447771, 51.8],
  [37.548024, -77.4479, 51.8],
  [37.547852, -77.448029, 51.8],
  [37.547681, -77.448158, 51.8],
  [37.547509, -77.448281, 51.8],
  [37.547337, -77.44841, 51.8],
  [37.547166, -77.448544, 52.2],
];

export default stream;
