import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.746081, 165.847077, 101],
  [-10.74604, 165.847217, 101.4],
  [-10.745995, 165.847356, 101.6],
  [-10.745945, 165.847496, 101.8],
  [-10.745889, 165.847646, 102],
  [-10.745849, 165.847785, 102.2],
  [-10.745829, 165.847936, 102.6],
  [-10.745814, 165.848086, 102.8],
  [-10.7458, 165.848236, 103.2],
  [-10.745786, 165.848397, 103.6],
  [-10.745757, 165.848526, 104],
  [-10.745706, 165.848687, 104.4],
  [-10.745627, 165.848805, 105],
  [-10.745533, 165.848923, 105.4],
  [-10.745452, 165.849051, 105.8],
  [-10.745433, 165.849202, 106.4],
  [-10.745474, 165.849341, 106.8],
  [-10.745525, 165.849481, 107.4],
  [-10.745518, 165.849631, 107.8],
  [-10.745432, 165.849738, 108],
  [-10.745334, 165.849856, 108],
  [-10.745258, 165.849985, 108],
  [-10.745218, 165.850135, 107.8],
  [-10.745242, 165.850285, 107.6],
  [-10.745312, 165.850425, 108],
  [-10.745385, 165.850564, 108.6],
  [-10.74536, 165.850714, 109],
  [-10.745293, 165.850854, 109.2],
  [-10.745202, 165.850972, 109.2],
  [-10.745093, 165.85109, 109],
  [-10.745026, 165.851229, 109],
  [-10.744984, 165.85138, 109],
  [-10.744944, 165.851541, 109],
  [-10.744902, 165.851701, 109],
];

export default stream;
