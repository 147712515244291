import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.640519, 166.946021, 12.8],
  [-11.640299, 166.946032, 12.6],
  [-11.64013, 166.946161, 12.6],
  [-11.639982, 166.946322, 12.4],
  [-11.639832, 166.946493, 12.4],
  [-11.639649, 166.946601, 12],
  [-11.639439, 166.946676, 11.4],
  [-11.639219, 166.946654, 10.8],
  [-11.639017, 166.946568, 10.4],
  [-11.638853, 166.946418, 10.2],
  [-11.638749, 166.946214, 10.4],
  [-11.638733, 166.946, 10.4],
  [-11.638778, 166.945775, 10.4],
  [-11.638843, 166.945571, 10.4],
  [-11.638922, 166.945367, 10.4],
  [-11.639006, 166.945163, 10.4],
  [-11.639088, 166.944948, 10.4],
  [-11.639168, 166.944755, 10.4],
  [-11.639253, 166.944551, 10.4],
  [-11.63934, 166.944358, 10.4],
  [-11.639429, 166.944155, 10.4],
  [-11.639517, 166.943961, 10.4],
  [-11.639608, 166.943758, 10.4],
  [-11.639699, 166.943564, 10.4],
  [-11.639792, 166.943361, 10.4],
  [-11.639885, 166.943167, 10.4],
  [-11.639984, 166.942964, 10.4],
  [-11.640084, 166.94277, 10.4],
];

export default stream;
