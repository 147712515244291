import { LatLngAlt } from "../../../types.js";

export const edgeNeokyoG1: LatLngAlt[] = [
  [-10.79115, 165.841037, 78.2],
  [-10.791212, 165.84093, 78.2],
  [-10.791266, 165.840812, 78.2],
  [-10.791321, 165.840683, 78.2],
  [-10.791365, 165.840554, 78.2],
];
export const edgeNeokyoG2: LatLngAlt[] = [
  [-10.791361, 165.840447, 78.2],
  [-10.791388, 165.840576, 78.2],
  [-10.791413, 165.840715, 78.2],
  [-10.791438, 165.840844, 78.2],
  [-10.791471, 165.840962, 78.2],
];
export const edgeNeokyoG3: LatLngAlt[] = [
  [-10.791483, 165.841037, 78.2],
  [-10.791361, 165.840962, 78.2],
  [-10.791225, 165.841005, 78.2],
];
