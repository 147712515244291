import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.799007, 165.843011, 66.4],
  [-10.79884, 165.843011, 66.2],
  [-10.798674, 165.843022, 66.2],
  [-10.79851, 165.843033, 66.2],
  [-10.798344, 165.843065, 66],
  [-10.798177, 165.843097, 66],
  [-10.798015, 165.843118, 66],
  [-10.797846, 165.84314, 66],
  [-10.797679, 165.84314, 65.8],
  [-10.797512, 165.843161, 65.8],
  [-10.797346, 165.843194, 65.8],
  [-10.797179, 165.843204, 65.6],
  [-10.797012, 165.843236, 65.6],
  [-10.796847, 165.843247, 65.8],
];

export default stream;
