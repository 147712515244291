import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.74634, 165.84461, 93.6],
  [-10.746492, 165.844674, 93.6],
  [-10.746641, 165.844728, 93.6],
  [-10.746793, 165.844803, 93.6],
  [-10.746943, 165.844867, 93.6],
  [-10.747093, 165.844942, 93.6],
  [-10.747251, 165.844964, 93.6],
  [-10.747346, 165.844824, 93.6],
  [-10.747411, 165.844674, 93.6],
  [-10.747475, 165.844513, 93.6],
  [-10.747536, 165.844363, 93.6],
  [-10.747596, 165.844191, 93.6],
  [-10.747676, 165.844063, 93.6],
  [-10.747831, 165.84403, 93.6],
  [-10.747986, 165.844073, 93.6],
  [-10.748134, 165.844148, 93.6],
  [-10.74828, 165.844223, 93.6],
  [-10.74843, 165.844288, 93.6],
  [-10.748586, 165.844331, 93.6],
  [-10.748713, 165.844245, 93.6],
  [-10.748782, 165.844095, 93.6],
  [-10.748851, 165.843934, 93.6],
  [-10.74892, 165.843784, 93.6],
  [-10.748987, 165.843633, 93.6],
  [-10.749056, 165.843483, 93.6],
  [-10.749127, 165.843322, 93.6],
  [-10.749198, 165.843194, 93.6],
  [-10.749268, 165.843033, 93.6],
  [-10.749338, 165.842882, 93.6],
  [-10.749408, 165.842732, 93.6],
  [-10.749393, 165.842582, 93.6],
  [-10.749254, 165.842485, 93.6],
  [-10.749108, 165.84241, 93.6],
  [-10.748956, 165.842346, 93.6],
  [-10.748806, 165.842271, 93.6],
  [-10.748656, 165.842217, 93.6],
  [-10.748505, 165.842131, 93.6],
];

export default stream;
