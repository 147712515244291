import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.683906, 166.930207, 26],
  [-11.683837, 166.930025, 25.8],
  [-11.683739, 166.929874, 25.4],
  [-11.683559, 166.929799, 24.8],
  [-11.683378, 166.929864, 24.2],
  [-11.683272, 166.930025, 23.6],
  [-11.683283, 166.930207, 22.8],
  [-11.683398, 166.930379, 22.2],
  [-11.683492, 166.93055, 21.4],
  [-11.683449, 166.930733, 20.6],
  [-11.683316, 166.930883, 20],
  [-11.683194, 166.931033, 19.2],
  [-11.68313, 166.931237, 18.4],
  [-11.683025, 166.931398, 17.6],
  [-11.682939, 166.93158, 16.8],
  [-11.682887, 166.931795, 16.2],
  [-11.682867, 166.931977, 15.4],
  [-11.68289, 166.932192, 14.8],
  [-11.682905, 166.932396, 14.2],
  [-11.682856, 166.932589, 13.6],
  [-11.682833, 166.932803, 13],
  [-11.682709, 166.932943, 12.4],
  [-11.682517, 166.932911, 11.6],
  [-11.682438, 166.932739, 11],
  [-11.682442, 166.932546, 10.4],
  [-11.68232, 166.932374, 9.8],
  [-11.682388, 166.932213, 9.4],
  [-11.682359, 166.932009, 8.6],
  [-11.682217, 166.93187, 8],
  [-11.682194, 166.931677, 7.4],
  [-11.682339, 166.931548, 6.8],
  [-11.682528, 166.931505, 6.2],
  [-11.6826, 166.931323, 5.6],
  [-11.682572, 166.93113, 5.2],
  [-11.682581, 166.930926, 4.6],
  [-11.682698, 166.930776, 4.2],
  [-11.682774, 166.930593, 4],
  [-11.682742, 166.930389, 3.8],
  [-11.682675, 166.930207, 3.6],
  [-11.682603, 166.930025, 3.6],
  [-11.682536, 166.929842, 3.6],
  [-11.682471, 166.92966, 3.6],
  [-11.682414, 166.929477, 3.8],
  [-11.682369, 166.929295, 3.8],
  [-11.682364, 166.929113, 4.2],
  [-11.682455, 166.928941, 4.6],
  [-11.682596, 166.928834, 5.2],
  [-11.68277, 166.92878, 5.8],
  [-11.682942, 166.928726, 6.4],
  [-11.683044, 166.928608, 6.8],
  [-11.683005, 166.928437, 7.2],
  [-11.682846, 166.928383, 7.8],
  [-11.682674, 166.928362, 8.4],
  [-11.682502, 166.928362, 9],
  [-11.68236, 166.928469, 9.6],
  [-11.682239, 166.928576, 10.2],
  [-11.682084, 166.928651, 10.8],
  [-11.681935, 166.928726, 11.4],
  [-11.681826, 166.928855, 12],
  [-11.681757, 166.929005, 12.4],
  [-11.681661, 166.929156, 13],
  [-11.681589, 166.929295, 13.4],
  [-11.681581, 166.929467, 13.8],
  [-11.6816, 166.929628, 14.4],
  [-11.681573, 166.929799, 14.8],
  [-11.68151, 166.92995, 15.2],
  [-11.681385, 166.930057, 15.6],
  [-11.681235, 166.930121, 16.2],
  [-11.681074, 166.930089, 16.6],
  [-11.680955, 166.929971, 17],
  [-11.680897, 166.92981, 17.4],
  [-11.680945, 166.92966, 18],
  [-11.681031, 166.92952, 18.4],
  [-11.681133, 166.929392, 18.8],
  [-11.681236, 166.929263, 19.4],
  [-11.681293, 166.929091, 19.6],
  [-11.681335, 166.928941, 19.6],
  [-11.681401, 166.928791, 19.8],
  [-11.681506, 166.928673, 20],
  [-11.681622, 166.928544, 20],
  [-11.681612, 166.928383, 20.4],
  [-11.681545, 166.928211, 20.8],
  [-11.681529, 166.92804, 21],
  [-11.681533, 166.927879, 21.4],
];

export default stream;
