import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.769084, 165.841284, 69.6],
  [-10.769142, 165.841101, 69.6],
  [-10.769221, 165.840919, 69],
  [-10.769325, 165.840737, 68.4],
  [-10.769373, 165.840533, 67.8],
  [-10.769327, 165.84034, 67.4],
  [-10.769277, 165.840136, 67.2],
  [-10.769316, 165.839943, 66.8],
  [-10.769417, 165.83976, 66.8],
  [-10.769578, 165.839632, 67.2],
  [-10.769725, 165.839492, 67],
  [-10.769846, 165.839342, 66.6],
  [-10.76998, 165.839192, 66.2],
  [-10.770147, 165.839074, 66],
  [-10.770339, 165.839009, 65.8],
  [-10.770535, 165.838966, 65.8],
  [-10.770733, 165.838913, 65.8],
  [-10.770929, 165.83887, 65.6],
  [-10.771125, 165.838838, 65.6],
  [-10.771322, 165.838805, 65.6],
  [-10.771515, 165.838752, 65.4],
  [-10.771689, 165.838655, 65.4],
  [-10.771831, 165.838516, 65.4],
  [-10.771947, 165.838355, 65.4],
  [-10.772048, 165.838172, 65.2],
  [-10.772144, 165.838001, 65.2],
  [-10.772244, 165.837818, 65.2],
  [-10.772358, 165.837668, 65.2],
  [-10.772487, 165.837518, 65],
  [-10.772629, 165.837379, 65],
  [-10.772778, 165.837239, 65],
  [-10.772928, 165.8371, 65],
  [-10.773079, 165.836982, 65],
  [-10.773227, 165.836842, 65],
  [-10.773361, 165.836692, 65.2],
  [-10.77347, 165.836542, 65.8],
  [-10.773516, 165.836338, 66],
  [-10.773488, 165.836155, 65.4],
  [-10.773364, 165.836005, 65.2],
  [-10.773184, 165.83593, 65.8],
  [-10.773052, 165.835791, 65.8],
  [-10.772944, 165.835619, 65.4],
  [-10.772905, 165.835426, 65.2],
  [-10.772983, 165.835243, 65.4],
  [-10.773117, 165.835104, 65.6],
  [-10.773262, 165.834965, 65.8],
  [-10.773406, 165.834825, 66],
  [-10.773534, 165.834686, 66.2],
  [-10.773626, 165.834503, 66.6],
  [-10.773648, 165.834321, 66.6],
  [-10.773617, 165.834128, 66.8],
  [-10.773562, 165.833945, 66.8],
  [-10.773501, 165.833763, 67],
  [-10.773454, 165.833559, 67],
  [-10.77345, 165.833377, 67],
  [-10.773474, 165.833184, 67.2],
  [-10.773528, 165.833001, 67.2],
  [-10.77362, 165.832819, 67.2],
  [-10.773749, 165.832679, 67.4],
  [-10.773915, 165.832572, 67.4],
  [-10.77409, 165.832475, 67.4],
  [-10.774269, 165.8324, 67.6],
  [-10.77444, 165.832314, 67.6],
  [-10.774589, 165.832186, 67.8],
  [-10.774682, 165.832025, 67.8],
  [-10.774726, 165.831821, 67.8],
  [-10.774744, 165.831639, 67.8],
  [-10.774754, 165.831445, 67.8],
  [-10.774776, 165.831242, 67.8],
  [-10.774856, 165.831059, 67.8],
  [-10.774981, 165.830909, 67.8],
  [-10.775078, 165.830737, 67.8],
  [-10.775138, 165.830555, 67.8],
  [-10.775146, 165.830362, 67.8],
];

export default stream;
