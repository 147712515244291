import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.673562, 166.96941, 97.6],
  [-11.673459, 166.969582, 96.2],
  [-11.673353, 166.969743, 94.8],
  [-11.673246, 166.969914, 93.2],
  [-11.673127, 166.970097, 91.6],
  [-11.672996, 166.970268, 90.2],
  [-11.672852, 166.970419, 89],
  [-11.672696, 166.970569, 87.8],
  [-11.672532, 166.970708, 86.8],
  [-11.672367, 166.970848, 85.8],
  [-11.672197, 166.970977, 85],
  [-11.672027, 166.971116, 84.2],
  [-11.671855, 166.971245, 83.6],
  [-11.671682, 166.971363, 82.8],
  [-11.671504, 166.971481, 82.2],
  [-11.671323, 166.97161, 81.4],
  [-11.671142, 166.971717, 80.8],
  [-11.670954, 166.971824, 80],
  [-11.670765, 166.97191, 79.4],
  [-11.670569, 166.971985, 78.6],
  [-11.670373, 166.97206, 77.8],
  [-11.670173, 166.972146, 77.2],
  [-11.66997, 166.9722, 76.6],
  [-11.669767, 166.972264, 75.8],
  [-11.669566, 166.972328, 75.2],
  [-11.669358, 166.972371, 74.4],
  [-11.669153, 166.972404, 73.8],
  [-11.668945, 166.972446, 73.2],
  [-11.668736, 166.972457, 72.4],
  [-11.668528, 166.972489, 71.8],
  [-11.66832, 166.972511, 71.2],
  [-11.668111, 166.972511, 70.4],
  [-11.667898, 166.972511, 69.8],
  [-11.667691, 166.972489, 69],
  [-11.667479, 166.972457, 68.4],
  [-11.667273, 166.972446, 67.6],
  [-11.667067, 166.972414, 67],
  [-11.66686, 166.972371, 66.2],
  [-11.666654, 166.972339, 65.6],
  [-11.666446, 166.972296, 64.8],
  [-11.666241, 166.972253, 64.2],
  [-11.666037, 166.9722, 63.6],
  [-11.665834, 166.972157, 62.8],
  [-11.665628, 166.972114, 62.2],
  [-11.665427, 166.972049, 61.4],
  [-11.665226, 166.971985, 60.8],
  [-11.66503, 166.97191, 60],
  [-11.664833, 166.971835, 59.4],
  [-11.664641, 166.971749, 58.6],
  [-11.664452, 166.971663, 58],
  [-11.664258, 166.971577, 57.2],
  [-11.664073, 166.971481, 56.4],
  [-11.663898, 166.971363, 55.6],
  [-11.663724, 166.971245, 54.6],
  [-11.663522, 166.97118, 53.2],
  [-11.663311, 166.971191, 51.4],
  [-11.663092, 166.971213, 49.6],
  [-11.662875, 166.971191, 47.6],
  [-11.662713, 166.971041, 45.6],
  [-11.662626, 166.970848, 44.2],
  [-11.662634, 166.970612, 42.2],
  [-11.66276, 166.970419, 40],
  [-11.662919, 166.970247, 37.8],
  [-11.663023, 166.970054, 35.6],
  [-11.663008, 166.969807, 34],
  [-11.662976, 166.969582, 32.6],
  [-11.663047, 166.969367, 30.8],
  [-11.663208, 166.969196, 29.2],
  [-11.66339, 166.969045, 27.4],
  [-11.663551, 166.968874, 25.6],
  [-11.663601, 166.968648, 23.8],
  [-11.663555, 166.968423, 22.2],
  [-11.663386, 166.968284, 20.2],
  [-11.663153, 166.968284, 18.4],
  [-11.662965, 166.968423, 16.8],
  [-11.662801, 166.968584, 14.8],
  [-11.662658, 166.968766, 12.8],
  [-11.662496, 166.968949, 10.6],
  [-11.66227, 166.968981, 8.6],
  [-11.662089, 166.968842, 7.4],
  [-11.661947, 166.968659, 7],
  [-11.661787, 166.968498, 6.6],
  [-11.661604, 166.968359, 6.4],
  [-11.661409, 166.968251, 6.2],
  [-11.66122, 166.968144, 6.2],
  [-11.661027, 166.968048, 6.2],
  [-11.660837, 166.967962, 6.4],
  [-11.660646, 166.967887, 6.4],
  [-11.660454, 166.967812, 6.4],
  [-11.660264, 166.967736, 6.4],
  [-11.660074, 166.967651, 6.4],
  [-11.659883, 166.967597, 6.4],
];

export default stream;
