import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-21.656357, 166.204826, 1543],
  [-21.65633, 166.204896, 1543],
  [-21.656311, 166.204968, 1543],
  [-21.656299, 166.205043, 1543],
  [-21.656293, 166.205119, 1543],
  [-21.656289, 166.205195, 1543],
  [-21.656283, 166.205271, 1543],
];

export default stream;
