import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.646514, 166.974549, 42.2],
  [-11.64644, 166.974753, 42.2],
  [-11.646359, 166.974946, 42.2],
  [-11.64624, 166.975118, 41.6],
  [-11.646042, 166.97515, 40.6],
  [-11.645906, 166.975, 41],
  [-11.645903, 166.974785, 42.4],
  [-11.645848, 166.974603, 43.6],
  [-11.645662, 166.974603, 42.8],
  [-11.645481, 166.974689, 41.4],
  [-11.645283, 166.974689, 41],
  [-11.64512, 166.974581, 42.4],
  [-11.644947, 166.974496, 43.2],
  [-11.644781, 166.974614, 41.8],
  [-11.644625, 166.974732, 40.6],
  [-11.644426, 166.974764, 41.4],
  [-11.644257, 166.974657, 41.8],
  [-11.64425, 166.974474, 42.6],
  [-11.644363, 166.974313, 43.8],
  [-11.644497, 166.974174, 45],
  [-11.644629, 166.974034, 46.2],
  [-11.644759, 166.973895, 47.2],
  [-11.644891, 166.973777, 48.2],
  [-11.644949, 166.973605, 49.2],
  [-11.644845, 166.973466, 49.8],
  [-11.644671, 166.973433, 51],
  [-11.644517, 166.97353, 52.2],
  [-11.644348, 166.973562, 53.2],
  [-11.644176, 166.973573, 54],
  [-11.644031, 166.97367, 53.8],
  [-11.643907, 166.973809, 53],
  [-11.643794, 166.973959, 52.2],
  [-11.643704, 166.97412, 50.6],
  [-11.643747, 166.974324, 50.8],
  [-11.643813, 166.974506, 51.6],
  [-11.643716, 166.974657, 52],
  [-11.643538, 166.974753, 50.6],
  [-11.643345, 166.974807, 50.2],
  [-11.643157, 166.974871, 51.6],
  [-11.642969, 166.974903, 51.8],
  [-11.642768, 166.974903, 51.4],
  [-11.642572, 166.974903, 50.6],
  [-11.642371, 166.974871, 49.8],
  [-11.642174, 166.974828, 48.8],
  [-11.641979, 166.974753, 47.6],
  [-11.641865, 166.974571, 46.8],
  [-11.641951, 166.974388, 45.6],
  [-11.642158, 166.974335, 44.2],
  [-11.642368, 166.974388, 43.2],
  [-11.642576, 166.974442, 43.6],
  [-11.642777, 166.974517, 45.2],
  [-11.642921, 166.97441, 45.8],
  [-11.642839, 166.974227, 45],
  [-11.642662, 166.97412, 43.8],
  [-11.642466, 166.974045, 42.6],
  [-11.642276, 166.973959, 41.4],
  [-11.642068, 166.973895, 40.6],
  [-11.641911, 166.973755, 39.6],
  [-11.641975, 166.973562, 38.8],
  [-11.642172, 166.973487, 37.8],
  [-11.642394, 166.973455, 37],
  [-11.642598, 166.97338, 35.6],
  [-11.642771, 166.97324, 34],
  [-11.642877, 166.973058, 34.2],
  [-11.642899, 166.972833, 36],
  [-11.642831, 166.972629, 35.4],
  [-11.642722, 166.972446, 33.8],
  [-11.642708, 166.972232, 32.4],
  [-11.642822, 166.972049, 31.2],
  [-11.643023, 166.971974, 29.4],
  [-11.643243, 166.971942, 27.8],
  [-11.643444, 166.971835, 26.8],
  [-11.643582, 166.971663, 26.6],
  [-11.643609, 166.971438, 25.2],
  [-11.643496, 166.971256, 24.8],
  [-11.643307, 166.971148, 26.4],
  [-11.643143, 166.971009, 26],
  [-11.643001, 166.970848, 24.4],
  [-11.642804, 166.970773, 24.8],
  [-11.642599, 166.970741, 26.4],
  [-11.642443, 166.970612, 26],
  [-11.642358, 166.970419, 25],
  [-11.642332, 166.970215, 26.2],
  [-11.642327, 166.97, 27.8],
  [-11.642215, 166.96985, 27.8],
  [-11.642028, 166.96985, 26.6],
  [-11.641912, 166.970022, 25.2],
  [-11.641826, 166.970204, 24],
  [-11.641719, 166.970387, 22.6],
  [-11.641805, 166.97059, 21.4],
  [-11.641883, 166.970773, 20.2],
  [-11.641892, 166.970998, 19],
  [-11.641863, 166.971223, 18.2],
  [-11.641767, 166.971406, 18.2],
  [-11.641619, 166.971577, 19.4],
  [-11.641438, 166.971685, 20.8],
  [-11.641238, 166.971695, 21.4],
  [-11.641041, 166.971663, 21.4],
  [-11.640851, 166.97161, 21],
  [-11.640691, 166.971481, 20.4],
  [-11.640645, 166.971288, 18.8],
  [-11.640575, 166.971105, 18.4],
  [-11.640428, 166.970955, 19.6],
  [-11.640381, 166.970751, 18.8],
  [-11.640424, 166.970558, 17.4],
  [-11.640574, 166.970397, 18.4],
  [-11.640717, 166.970268, 19.4],
  [-11.640681, 166.970065, 17.8],
  [-11.640685, 166.969872, 17],
  [-11.640818, 166.9697, 18.4],
  [-11.640956, 166.96956, 20],
  [-11.640959, 166.969378, 20.2],
  [-11.640817, 166.969239, 19.6],
  [-11.640648, 166.969131, 18.8],
  [-11.64051, 166.968981, 18],
  [-11.640437, 166.968799, 17.4],
  [-11.640398, 166.968584, 16.6],
  [-11.640363, 166.968391, 15.8],
  [-11.640261, 166.968209, 15.2],
  [-11.640091, 166.968294, 15.2],
  [-11.639976, 166.968466, 14.6],
  [-11.639842, 166.968627, 13.8],
  [-11.639803, 166.968842, 13],
  [-11.639883, 166.969024, 13.2],
  [-11.64002, 166.969196, 13.8],
  [-11.64015, 166.969346, 14.6],
  [-11.640182, 166.96955, 15.2],
  [-11.640032, 166.969657, 15.6],
  [-11.639842, 166.969603, 14.8],
  [-11.639679, 166.969485, 14],
  [-11.639553, 166.969314, 12.8],
  [-11.639423, 166.969163, 11.4],
  [-11.639245, 166.969239, 11.2],
  [-11.639246, 166.969442, 11.4],
  [-11.639297, 166.969657, 11.8],
  [-11.639289, 166.96985, 12.2],
  [-11.63915, 166.96999, 12.8],
  [-11.638962, 166.969947, 13.2],
  [-11.638809, 166.969807, 13.4],
  [-11.638639, 166.969711, 12.8],
  [-11.638506, 166.96956, 12.2],
  [-11.638452, 166.969367, 11.6],
  [-11.638338, 166.969196, 11.4],
  [-11.638157, 166.96911, 11.4],
  [-11.637965, 166.969163, 11.6],
  [-11.637779, 166.969239, 11.6],
  [-11.637587, 166.969217, 11.6],
  [-11.637416, 166.96911, 11.8],
  [-11.637216, 166.969121, 11.8],
  [-11.637027, 166.969185, 11.8],
  [-11.636852, 166.969292, 11.8],
];

export default stream;
