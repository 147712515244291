import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.746217, 165.844696, 93.8],
  [-10.746157, 165.844867, 93.8],
  [-10.746109, 165.845017, 93.8],
  [-10.746087, 165.8452, 93.8],
  [-10.746097, 165.845371, 94.2],
  [-10.746123, 165.845543, 94.8],
  [-10.746149, 165.845693, 95.4],
  [-10.746179, 165.845865, 96.2],
  [-10.746203, 165.846015, 96.8],
  [-10.746201, 165.846165, 97.2],
  [-10.746191, 165.846337, 97.6],
  [-10.746183, 165.846487, 98.2],
  [-10.746173, 165.846637, 98.8],
  [-10.746151, 165.846788, 99.6],
  [-10.746119, 165.846927, 100.6],
];

export default stream;
