import { LatLngAlt } from "../../../types.js";

const streams: LatLngAlt[] = [
  [-11.648194, 166.952716, 15.4],
  [-11.648384, 166.952738, 15.2],
  [-11.64858, 166.952759, 15],
  [-11.648775, 166.952791, 14.8],
  [-11.648968, 166.952823, 14.6],
  [-11.649161, 166.952856, 14.4],
  [-11.649354, 166.952888, 14.2],
  [-11.649547, 166.95292, 14],
  [-11.649742, 166.952941, 13.8],
  [-11.649933, 166.952974, 13.6],
  [-11.650125, 166.953006, 13.4],
  [-11.650317, 166.953049, 13.2],
  [-11.650503, 166.953113, 13],
  [-11.650647, 166.953231, 12.8],
  [-11.650577, 166.953403, 12.4],
  [-11.650387, 166.953446, 12.2],
  [-11.65019, 166.953424, 11.8],
  [-11.649996, 166.953392, 11.6],
  [-11.649805, 166.953338, 11.4],
  [-11.649616, 166.953295, 11.2],
  [-11.649424, 166.953253, 11],
  [-11.649229, 166.95321, 10.8],
  [-11.649039, 166.953145, 10.6],
  [-11.648846, 166.953113, 10.4],
  [-11.648655, 166.95307, 10.2],
  [-11.648461, 166.953038, 9.8],
  [-11.648264, 166.953027, 9.6],
  [-11.648084, 166.953102, 9.4],
  [-11.648012, 166.953285, 9.2],
  [-11.648079, 166.953467, 9],
  [-11.648221, 166.953607, 8.8],
  [-11.648398, 166.953682, 8.6],
  [-11.648588, 166.953735, 8.4],
  [-11.648779, 166.953789, 8.2],
  [-11.648971, 166.953832, 8],
  [-11.649162, 166.953864, 7.8],
  [-11.649357, 166.953907, 7.4],
  [-11.649547, 166.953939, 7.2],
  [-11.649743, 166.953982, 7],
  [-11.649933, 166.954025, 6.8],
  [-11.650125, 166.954079, 6.6],
  [-11.650316, 166.954122, 6.4],
  [-11.650495, 166.954197, 6.2],
  [-11.650642, 166.954336, 6],
  [-11.650624, 166.954519, 5.8],
  [-11.650509, 166.95469, 5.6],
  [-11.650395, 166.95484, 5.6],
  [-11.650302, 166.955023, 5.4],
  [-11.650207, 166.955184, 5.2],
  [-11.650108, 166.955355, 5],
  [-11.65001, 166.955527, 5],
  [-11.649911, 166.955709, 4.8],
  [-11.649815, 166.95587, 4.6],
  [-11.649718, 166.956042, 4.4],
  [-11.649621, 166.956214, 4.2],
  [-11.649524, 166.956396, 4],
  [-11.649427, 166.956557, 4],
  [-11.64933, 166.956729, 3.8],
  [-11.649232, 166.9569, 3.6],
  [-11.649152, 166.957083, 3.4],
  [-11.649102, 166.957276, 3.2],
  [-11.649086, 166.95748, 3],
  [-11.649126, 166.957662, 2.8],
  [-11.649209, 166.957844, 2.4],
  [-11.64933, 166.957995, 2.2],
  [-11.649482, 166.958123, 1.8],
  [-11.649659, 166.958209, 1.6],
  [-11.649852, 166.958209, 1.4],
  [-11.65003, 166.958123, 1.2],
  [-11.650183, 166.957995, 1],
  [-11.650312, 166.957844, 0.8],
  [-11.650423, 166.957694, 0.8],
  [-11.650498, 166.957512, 0.8],
  [-11.650566, 166.95733, 0.8],
  [-11.650638, 166.957126, 0.8],
  [-11.650706, 166.956954, 0.8],
  [-11.650778, 166.956772, 0.8],
  [-11.650848, 166.956589, 0.8],
  [-11.650918, 166.956407, 0.8],
  [-11.650988, 166.956224, 0.8],
  [-11.651056, 166.956042, 0.8],
  [-11.651128, 166.95587, 0.6],
  [-11.651209, 166.955688, 0.6],
  [-11.651305, 166.955527, 0.4],
  [-11.651435, 166.955398, 0.6],
  [-11.651614, 166.955345, 0.8],
  [-11.651796, 166.955345, 0.8],
  [-11.651981, 166.955345, 0.8],
  [-11.652162, 166.955355, 0.8],
  [-11.652346, 166.955377, 0.8],
  [-11.652528, 166.955377, 0.8],
  [-11.652714, 166.955388, 0.8],
  [-11.652897, 166.955388, 0.8],
  [-11.653084, 166.955398, 0.8],
  [-11.653267, 166.95542, 0.8],
  [-11.653446, 166.955463, 1],
  [-11.653626, 166.955495, 1],
];

export default streams;
