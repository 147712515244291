import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.78939, 165.836746, 66],
  [-10.789481, 165.83667, 66],
  [-10.789583, 165.836617, 66],
  [-10.789701, 165.836563, 66],
  [-10.789812, 165.836542, 66],
  [-10.789933, 165.83652, 66.2],
  [-10.790051, 165.836509, 65.8],
  [-10.790173, 165.836488, 65.4],
  [-10.7903, 165.836488, 65],
  [-10.790425, 165.836477, 64.6],
  [-10.790554, 165.836477, 64.4],
  [-10.790682, 165.836488, 64],
  [-10.790812, 165.836509, 63.8],
  [-10.790943, 165.83652, 63.4],
  [-10.791073, 165.836509, 63],
  [-10.791203, 165.836456, 62.8],
  [-10.791319, 165.836402, 62.6],
  [-10.791431, 165.836327, 62.4],
  [-10.791549, 165.836263, 62.4],
  [-10.791674, 165.836231, 62.4],
  [-10.7918, 165.836188, 62.6],
  [-10.791925, 165.836155, 63],
  [-10.792047, 165.836123, 63.2],
  [-10.792165, 165.83608, 63.4],
  [-10.792278, 165.836037, 63.6],
  [-10.792392, 165.836005, 64],
  [-10.792509, 165.835973, 64.2],
  [-10.792626, 165.835952, 64.6],
  [-10.792741, 165.835952, 64.8],
  [-10.792856, 165.835973, 65.2],
  [-10.792966, 165.835984, 65.4],
  [-10.793072, 165.836016, 65.6],
  [-10.793172, 165.836059, 65.8],
  [-10.793269, 165.836123, 66.2],
  [-10.793355, 165.836188, 66.6],
  [-10.793434, 165.836263, 66.8],
  [-10.793507, 165.836338, 66.8],
  [-10.793581, 165.836434, 66.6],
  [-10.793651, 165.836509, 66.6],
  [-10.793719, 165.836595, 66.6],
  [-10.793789, 165.836692, 66.6],
  [-10.793861, 165.836778, 66.6],
  [-10.793933, 165.836874, 66.6],
  [-10.794008, 165.836949, 66.8],
  [-10.794085, 165.837035, 66.8],
  [-10.794166, 165.837121, 66.8],
  [-10.794249, 165.837196, 67],
  [-10.794332, 165.837282, 67],
  [-10.794409, 165.837357, 66.6],
  [-10.794485, 165.837454, 66.4],
  [-10.794564, 165.837539, 66],
  [-10.794645, 165.837636, 65.8],
  [-10.794733, 165.837722, 65.8],
  [-10.794817, 165.837818, 65.6],
  [-10.794906, 165.837915, 65.4],
  [-10.794992, 165.838001, 65.4],
  [-10.795067, 165.838097, 65.4],
  [-10.795124, 165.838215, 65.4],
  [-10.795154, 165.838333, 65.4],
  [-10.795166, 165.838462, 65.4],
  [-10.795174, 165.83858, 65.4],
  [-10.795182, 165.838698, 65.4],
  [-10.795189, 165.838827, 65.2],
  [-10.795197, 165.838945, 65.2],
  [-10.795205, 165.839074, 65.2],
  [-10.795217, 165.839202, 65.4],
  [-10.795232, 165.839331, 65.4],
  [-10.795251, 165.839449, 65.4],
  [-10.795272, 165.839578, 65.6],
  [-10.795294, 165.839685, 65.6],
];

export default stream;
