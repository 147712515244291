import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.640318, 166.978355, 13.2],
  [-11.640205, 166.978197, 13.2],
  [-11.640176, 166.977983, 13.2],
  [-11.640212, 166.977789, 13.2],
  [-11.64029, 166.977607, 13.4],
  [-11.640409, 166.977435, 13.6],
  [-11.640526, 166.977264, 13.8],
  [-11.640571, 166.977071, 14],
  [-11.640503, 166.976888, 14],
  [-11.640389, 166.976717, 14],
  [-11.640257, 166.976566, 14],
  [-11.640117, 166.976416, 14],
  [-11.639964, 166.976287, 14],
  [-11.639776, 166.976212, 14],
  [-11.639575, 166.976202, 14],
  [-11.639375, 166.976169, 14],
  [-11.639178, 166.976126, 14],
  [-11.638978, 166.976094, 14],
  [-11.638777, 166.976062, 14.2],
  [-11.638576, 166.976062, 14.2],
  [-11.638376, 166.976084, 14.4],
  [-11.638188, 166.976159, 14.4],
  [-11.638046, 166.976287, 14.6],
  [-11.63799, 166.976491, 15],
  [-11.638046, 166.976674, 15.2],
  [-11.638156, 166.976856, 15.2],
  [-11.638271, 166.977006, 15.4],
  [-11.638377, 166.977189, 15.6],
];

export default stream;
