import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [51.4994, -0.111121, 21],
  [51.499561, -0.111166, 20.6],
  [51.499733, -0.111214, 20],
  [51.49991, -0.111259, 19.4],
  [51.500087, -0.111309, 18.8],
  [51.500275, -0.111361, 18.2],
  [51.500468, -0.111379, 17.6],
  [51.500655, -0.111378, 17],
  [51.500849, -0.11132, 16.2],
  [51.501031, -0.111202, 15.8],
  [51.501181, -0.111005, 15.2],
  [51.501321, -0.110766, 14.6],
  [51.50146, -0.110542, 14],
  [51.50161, -0.11032, 13.2],
  [51.50175, -0.110078, 12.6],
  [51.501873, -0.109824, 12],
  [51.50198, -0.109543, 11.6],
  [51.502066, -0.109248, 11.4],
  [51.502131, -0.108936, 11.4],
  [51.502174, -0.108621, 11.6],
  [51.5022, -0.108306, 11.8],
  [51.502227, -0.107986, 12.4],
  [51.502238, -0.107674, 12.8],
  [51.502249, -0.107372, 13.2],
  [51.502254, -0.10706, 13.8],
  [51.502259, -0.106757, 14.2],
  [51.502265, -0.106457, 14.6],
  [51.502265, -0.10616, 14.8],
  [51.50227, -0.105861, 15.2],
  [51.50227, -0.105561, 15.4],
  [51.502281, -0.105259, 15.4],
  [51.502281, -0.104965, 15.4],
  [51.502292, -0.104662, 15.2],
  [51.502297, -0.104358, 15],
  [51.502308, -0.104054, 14.8],
  [51.502313, -0.103739, 14.4],
  [51.502318, -0.103436, 14.2],
  [51.502324, -0.103123, 13.8],
  [51.502324, -0.102809, 13.4],
  [51.502329, -0.102499, 13],
  [51.502329, -0.102183, 12.8],
  [51.502329, -0.101865, 12.4],
  [51.502324, -0.101545, 12.2],
  [51.502318, -0.10123, 12.2],
  [51.502308, -0.100916, 12],
  [51.502292, -0.100597, 12.2],
  [51.502275, -0.100288, 12.6],
  [51.502254, -0.099978, 13],
  [51.502227, -0.099675, 13.6],
  [51.5022, -0.099377, 14.4],
  [51.502163, -0.099086, 15.2],
  [51.502115, -0.098796, 16.2],
  [51.502066, -0.098521, 17],
  [51.502007, -0.098248, 17.8],
  [51.501948, -0.097979, 18.6],
  [51.501884, -0.097711, 19.4],
  [51.501819, -0.097457, 20],
  [51.501739, -0.097202, 20.8],
  [51.501659, -0.096953, 21.4],
  [51.501578, -0.096711, 22.2],
  [51.501492, -0.096472, 22.8],
  [51.501406, -0.096228, 23.6],
  [51.501321, -0.09599, 24.2],
  [51.501235, -0.095754, 25],
  [51.501149, -0.095513, 25.6],
  [51.501063, -0.095277, 26.2],
  [51.500977, -0.095035, 27],
  [51.500902, -0.094796, 27.4],
  [51.500827, -0.09455, 28],
  [51.500747, -0.094295, 28.6],
  [51.500677, -0.094052, 28.8],
  [51.500602, -0.093791, 29.2],
  [51.500543, -0.093534, 29.4],
  [51.500484, -0.093269, 29.6],
  [51.50043, -0.092993, 30],
  [51.500376, -0.092722, 30.2],
  [51.500339, -0.092439, 30.6],
  [51.500296, -0.092163, 30.8],
  [51.500258, -0.091885, 31.2],
  [51.500221, -0.091599, 31.6],
  [51.500189, -0.091313, 31.8],
  [51.500151, -0.091037, 32.2],
  [51.500114, -0.09075, 32.4],
  [51.500076, -0.090468, 32.8],
  [51.500033, -0.090182, 33],
  [51.499979, -0.08991, 33.4],
  [51.499904, -0.089639, 33.6],
  [51.499786, -0.089426, 33.8],
  [51.499625, -0.089276, 34],
  [51.499491, -0.089081, 34],
  [51.499427, -0.088809, 34.2],
  [51.4994, -0.088516, 34.2],
  [51.499389, -0.088223, 34.2],
  [51.499379, -0.087923, 34.2],
  [51.499368, -0.087618, 34.2],
  [51.499346, -0.087318, 34.2],
  [51.49933, -0.087022, 34.2],
  [51.49932, -0.086719, 34.2],
  [51.499309, -0.086416, 34],
  [51.499293, -0.086114, 34],
  [51.499271, -0.085804, 34],
  [51.499245, -0.085508, 33.8],
  [51.499212, -0.085204, 33.8],
  [51.499164, -0.084905, 33.6],
  [51.4991, -0.084608, 33.6],
  [51.499025, -0.084328, 33.6],
  [51.498939, -0.084045, 33.4],
  [51.498858, -0.08376, 33.2],
  [51.498783, -0.083473, 32.8],
  [51.49873, -0.083182, 32.2],
  [51.498676, -0.082873, 31.6],
  [51.498633, -0.08256, 30.8],
  [51.498595, -0.082242, 29.8],
  [51.498558, -0.08192, 28.8],
  [51.498526, -0.081592, 27.8],
  [51.498488, -0.081266, 26.8],
  [51.498456, -0.080935, 25.6],
  [51.498413, -0.080599, 24.4],
  [51.498365, -0.080268, 23.4],
  [51.498295, -0.079939, 22.2],
  [51.498215, -0.079629, 21],
  [51.498118, -0.07931, 19.6],
  [51.498027, -0.079001, 18.4],
  [51.497968, -0.078654, 17],
  [51.497973, -0.078303, 15.6],
  [51.498032, -0.077962, 14.2],
  [51.498113, -0.077633, 12.8],
  [51.498172, -0.077292, 12],
  [51.498188, -0.076939, 11.6],
  [51.498193, -0.076584, 11.4],
  [51.498252, -0.076255, 11],
];

export default stream;
