import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.810729, 165.810052, 6],
  [-10.810816, 165.810095, 6],
  [-10.810903, 165.810138, 6],
  [-10.810989, 165.810181, 6],
  [-10.811073, 165.810245, 6],
  [-10.811154, 165.810288, 6],
  [-10.811237, 165.810353, 6],
  [-10.811318, 165.810417, 6],
  [-10.8114, 165.81046, 6],
  [-10.811484, 165.810503, 6],
  [-10.811569, 165.810567, 6],
  [-10.811655, 165.81061, 6],
  [-10.811743, 165.810642, 6],
  [-10.811832, 165.810685, 6],
  [-10.811922, 165.810717, 6],
  [-10.812015, 165.81075, 6],
  [-10.812109, 165.810782, 6],
  [-10.812202, 165.810792, 6],
  [-10.812299, 165.810814, 6],
  [-10.812396, 165.810814, 6],
  [-10.812491, 165.810814, 6],
  [-10.812586, 165.810782, 6],
  [-10.812674, 165.810739, 6.2],
  [-10.812748, 165.810674, 6],
  [-10.812799, 165.810599, 6],
  [-10.812823, 165.810503, 6],
  [-10.81283, 165.810395, 5.8],
  [-10.812825, 165.81031, 5.6],
  [-10.812814, 165.810202, 5.6],
  [-10.812797, 165.810095, 5.4],
  [-10.81278, 165.809999, 5.4],
  [-10.812764, 165.809891, 5.4],
  [-10.812752, 165.809805, 5.4],
  [-10.812752, 165.809698, 5.4],
  [-10.812766, 165.809591, 5.6],
  [-10.812808, 165.809494, 5.8],
  [-10.812877, 165.809441, 6.2],
  [-10.81296, 165.809387, 6.6],
  [-10.813046, 165.809376, 7],
  [-10.813131, 165.809366, 7.6],
  [-10.813214, 165.809376, 8],
  [-10.813292, 165.809376, 8.4],
  [-10.813367, 165.809387, 8.8],
  [-10.81344, 165.809419, 9.2],
  [-10.813509, 165.809441, 9.6],
  [-10.813577, 165.809451, 10],
  [-10.813642, 165.809484, 10.4],
  [-10.813706, 165.809494, 10.8],
  [-10.813769, 165.809516, 11.2],
  [-10.813831, 165.809548, 11.4],
  [-10.813892, 165.809559, 11.8],
  [-10.813952, 165.809569, 12.2],
  [-10.814012, 165.809569, 12.4],
  [-10.814072, 165.809591, 12.8],
  [-10.814131, 165.809591, 13.2],
  [-10.81419, 165.809591, 13.4],
  [-10.814248, 165.809591, 13.8],
  [-10.814305, 165.809591, 14],
  [-10.81436, 165.809569, 14.4],
  [-10.814413, 165.809548, 14.6],
  [-10.814461, 165.809516, 15],
  [-10.814504, 165.809484, 15.2],
  [-10.814541, 165.809441, 15.4],
  [-10.814574, 165.809387, 15.6],
  [-10.814602, 165.809333, 15.8],
  [-10.814627, 165.80928, 16],
  [-10.814649, 165.809226, 16.2],
  [-10.81467, 165.809162, 16.4],
  [-10.81469, 165.809119, 16.6],
  [-10.814711, 165.809054, 16.8],
  [-10.814733, 165.80899, 16.8],
  [-10.814759, 165.808936, 17],
  [-10.814793, 165.808872, 17.2],
  [-10.814832, 165.808808, 17.2],
  [-10.814876, 165.808754, 17.2],
  [-10.814921, 165.8087, 17.2],
  [-10.814967, 165.808647, 17],
  [-10.81502, 165.808582, 16.8],
  [-10.815081, 165.808518, 16.6],
  [-10.815152, 165.808475, 16.2],
  [-10.815235, 165.808443, 16.2],
  [-10.815327, 165.808443, 16],
  [-10.815413, 165.808475, 16],
  [-10.815492, 165.808518, 16],
  [-10.815569, 165.808582, 16],
  [-10.81565, 165.808625, 16],
  [-10.815739, 165.808657, 16],
  [-10.815831, 165.80869, 16],
  [-10.815925, 165.8087, 16],
  [-10.816019, 165.80869, 16],
  [-10.816106, 165.808657, 16.2],
  [-10.816182, 165.808593, 16.4],
  [-10.816249, 165.808518, 16.4],
  [-10.816308, 165.808464, 16.6],
  [-10.816362, 165.808389, 16.4],
  [-10.816415, 165.808293, 16],
  [-10.816469, 165.808218, 15.8],
  [-10.816526, 165.808121, 15.6],
  [-10.816581, 165.808046, 15.8],
  [-10.816636, 165.80796, 15.8],
  [-10.81669, 165.807885, 15.8],
  [-10.816745, 165.807788, 16],
  [-10.816799, 165.807713, 16],
  [-10.816853, 165.807617, 16],
  [-10.816909, 165.807542, 16],
  [-10.816968, 165.807467, 16],
  [-10.817029, 165.807391, 16],
  [-10.817087, 165.807306, 16],
  [-10.817139, 165.80722, 16],
  [-10.817183, 165.807134, 16],
  [-10.817209, 165.807037, 16],
  [-10.817193, 165.80693, 16],
  [-10.817144, 165.806855, 16],
  [-10.817073, 165.80678, 16],
  [-10.816992, 165.806726, 16],
  [-10.816902, 165.806673, 15.8],
  [-10.816804, 165.806662, 15.8],
  [-10.816709, 165.806694, 16],
  [-10.816627, 165.806748, 16.6],
  [-10.816561, 165.806812, 17.2],
  [-10.816504, 165.806887, 17.8],
  [-10.816453, 165.806952, 18.2],
  [-10.816408, 165.807027, 18.8],
  [-10.816367, 165.807091, 19.4],
  [-10.816327, 165.807145, 19.8],
  [-10.816288, 165.807209, 20.2],
  [-10.816245, 165.807273, 20.6],
  [-10.8162, 165.807316, 21],
  [-10.816153, 165.807359, 21.2],
  [-10.816105, 165.807424, 21.2],
  [-10.816055, 165.807467, 21.2],
  [-10.816002, 165.807509, 21],
  [-10.815941, 165.807563, 20.8],
  [-10.815867, 165.807595, 20.8],
  [-10.815787, 165.807595, 21],
  [-10.815712, 165.807563, 21.4],
  [-10.815642, 165.807531, 22],
  [-10.815579, 165.807488, 22.4],
  [-10.815518, 165.807456, 22.8],
  [-10.815463, 165.807413, 23.2],
  [-10.81541, 165.807359, 23.6],
  [-10.815361, 165.807316, 24],
  [-10.815315, 165.807273, 24.4],
  [-10.815272, 165.80722, 24.8],
  [-10.815231, 165.807177, 25],
  [-10.81519, 165.807134, 25.4],
  [-10.815154, 165.807091, 25.8],
  [-10.815117, 165.807037, 26.2],
  [-10.815082, 165.806994, 26.4],
  [-10.815048, 165.806962, 26.8],
  [-10.815023, 165.806909, 27.2],
  [-10.81501, 165.806855, 27.4],
  [-10.81501, 165.806801, 27.6],
  [-10.815019, 165.806748, 27.8],
  [-10.815036, 165.806694, 28.2],
  [-10.815062, 165.80664, 28.4],
  [-10.815096, 165.806597, 28.8],
  [-10.815136, 165.806555, 29.2],
  [-10.815179, 165.806522, 29.4],
  [-10.815223, 165.80649, 29.6],
  [-10.815269, 165.806458, 30],
  [-10.815315, 165.806426, 30.2],
  [-10.815361, 165.806404, 30.6],
  [-10.815405, 165.806372, 30.8],
  [-10.815446, 165.80634, 31.2],
  [-10.815482, 165.806297, 31.4],
  [-10.815509, 165.806243, 31.8],
  [-10.815527, 165.806201, 32],
  [-10.815534, 165.806136, 32.4],
  [-10.815533, 165.806093, 32.8],
  [-10.815526, 165.80604, 33],
  [-10.815515, 165.805986, 33.4],
  [-10.8155, 165.805943, 33.6],
  [-10.815481, 165.805879, 33.8],
  [-10.815457, 165.805836, 34],
  [-10.81543, 165.805793, 34.4],
  [-10.8154, 165.805739, 34.6],
  [-10.815365, 165.805696, 34.8],
  [-10.815327, 165.805664, 35],
  [-10.815285, 165.805621, 35.2],
  [-10.815237, 165.805589, 35.2],
  [-10.815186, 165.805557, 35.4],
  [-10.815127, 165.805546, 35.6],
  [-10.815064, 165.805535, 35.6],
  [-10.814999, 165.805535, 35.6],
  [-10.814932, 165.805546, 35.6],
  [-10.814867, 165.805578, 35.8],
  [-10.814806, 165.80561, 36],
  [-10.814748, 165.805653, 36.2],
  [-10.814696, 165.805696, 36.6],
  [-10.81465, 165.805739, 37],
  [-10.814607, 165.805804, 37.4],
  [-10.814566, 165.805846, 37.6],
  [-10.814528, 165.8059, 38],
  [-10.81449, 165.805954, 38.4],
  [-10.814454, 165.806007, 38.8],
  [-10.814418, 165.80605, 39],
  [-10.814381, 165.806093, 39.4],
  [-10.814342, 165.806136, 39.6],
  [-10.814301, 165.80619, 40],
  [-10.814257, 165.806233, 40.2],
  [-10.814209, 165.806265, 40.4],
  [-10.814156, 165.806297, 40.4],
  [-10.814097, 165.806329, 40.4],
  [-10.814036, 165.80634, 40.6],
  [-10.813971, 165.806372, 40.6],
  [-10.813905, 165.806383, 40.8],
  [-10.813838, 165.806404, 41],
  [-10.813771, 165.806426, 41.4],
  [-10.813704, 165.806447, 41.6],
  [-10.813639, 165.806458, 42],
  [-10.813574, 165.806479, 42.4],
  [-10.81351, 165.806479, 42.8],
  [-10.813448, 165.80649, 43.2],
  [-10.813388, 165.80649, 43.6],
  [-10.813329, 165.80649, 44],
  [-10.813272, 165.80649, 44.4],
  [-10.813216, 165.80649, 44.8],
  [-10.813162, 165.80649, 45.2],
  [-10.81311, 165.80649, 45.4],
  [-10.81306, 165.806479, 45.8],
  [-10.81301, 165.806479, 46.2],
  [-10.812963, 165.806458, 46.6],
  [-10.812916, 165.806447, 46.8],
  [-10.81287, 165.806426, 47.2],
  [-10.812825, 165.806415, 47.4],
  [-10.812782, 165.806383, 47.8],
  [-10.812739, 165.806372, 48],
  [-10.812698, 165.80634, 48.2],
  [-10.81266, 165.806308, 48.4],
  [-10.812625, 165.806265, 48.6],
  [-10.812595, 165.806222, 48.8],
  [-10.812568, 165.806168, 49],
  [-10.812545, 165.806125, 49.2],
  [-10.812525, 165.806061, 49.4],
  [-10.812507, 165.806007, 49.4],
  [-10.812491, 165.805943, 49.6],
  [-10.812478, 165.805879, 49.8],
  [-10.812465, 165.805825, 49.8],
  [-10.812453, 165.805761, 49.8],
  [-10.812441, 165.805686, 50],
  [-10.812427, 165.805621, 50],
  [-10.812412, 165.805546, 50.2],
  [-10.812394, 165.805471, 50.2],
  [-10.812373, 165.805407, 50.4],
  [-10.812346, 165.805331, 50.4],
  [-10.812312, 165.805256, 50.6],
  [-10.812272, 165.805192, 50.6],
  [-10.812223, 165.805138, 50.8],
  [-10.812168, 165.805085, 51],
  [-10.812109, 165.805031, 51.2],
  [-10.812046, 165.804977, 51.2],
  [-10.811981, 165.804935, 51.4],
  [-10.811913, 165.804902, 51.6],
  [-10.811843, 165.804859, 51.8],
  [-10.811772, 165.804827, 51.8],
  [-10.811699, 165.804795, 52],
  [-10.811626, 165.804763, 52.2],
  [-10.811551, 165.804741, 52.2],
  [-10.811477, 165.804709, 52.4],
  [-10.811402, 165.804688, 52.6],
  [-10.811327, 165.804666, 52.6],
  [-10.811252, 165.804634, 52.8],
  [-10.811179, 165.80458, 53],
  [-10.811107, 165.804559, 53],
  [-10.811036, 165.804505, 53.2],
  [-10.810965, 165.804473, 53.2],
  [-10.8109, 165.80442, 53.2],
  [-10.81084, 165.804355, 53.2],
  [-10.810781, 165.804291, 53.4],
  [-10.810721, 165.804237, 53.4],
  [-10.810661, 165.804173, 53.4],
  [-10.810599, 165.804108, 53.4],
  [-10.810537, 165.804055, 53.4],
  [-10.810474, 165.80399, 53.6],
  [-10.810411, 165.803926, 53.6],
  [-10.810348, 165.803872, 53.6],
  [-10.810285, 165.803787, 53.6],
  [-10.810221, 165.803733, 53.8],
  [-10.810157, 165.803668, 53.8],
  [-10.810092, 165.803604, 53.8],
  [-10.810029, 165.803529, 53.8],
  [-10.809963, 165.803475, 54],
  [-10.809898, 165.803411, 54],
  [-10.809834, 165.803347, 54],
  [-10.809769, 165.803272, 54],
  [-10.809703, 165.803207, 54],
  [-10.809638, 165.803154, 54.2],
  [-10.809573, 165.803078, 54.2],
  [-10.809508, 165.803014, 54.2],
  [-10.809442, 165.80295, 54.2],
  [-10.809374, 165.802875, 54.2],
  [-10.809306, 165.80281, 54.2],
  [-10.809241, 165.802757, 54.4],
  [-10.809175, 165.802681, 54.4],
  [-10.809109, 165.802617, 54.4],
  [-10.809041, 165.802542, 54.6],
  [-10.808974, 165.802478, 54.6],
  [-10.808911, 165.802413, 54.6],
  [-10.808852, 165.802338, 54.8],
  [-10.808799, 165.802263, 54.8],
  [-10.808754, 165.802177, 55],
  [-10.80872, 165.802102, 55.2],
  [-10.808693, 165.802006, 55.4],
  [-10.808672, 165.80192, 55.4],
  [-10.808654, 165.801823, 55.6],
  [-10.808637, 165.801727, 55.8],
  [-10.808619, 165.801651, 56],
  [-10.808597, 165.801566, 56.2],
  [-10.808568, 165.801491, 56.4],
  [-10.808532, 165.801394, 56.6],
  [-10.808486, 165.80133, 56.6],
  [-10.808428, 165.801255, 56.8],
  [-10.808362, 165.801212, 57],
  [-10.808288, 165.801169, 57.2],
  [-10.808208, 165.801136, 57.2],
  [-10.808126, 165.801126, 57.4],
  [-10.808042, 165.801104, 57.6],
  [-10.807957, 165.801104, 57.6],
  [-10.807872, 165.801104, 57.8],
  [-10.807787, 165.801104, 57.8],
  [-10.807702, 165.801104, 58],
  [-10.807617, 165.801104, 58],
  [-10.807531, 165.801094, 58.2],
  [-10.807447, 165.801094, 58.2],
  [-10.807364, 165.801061, 58.4],
  [-10.807284, 165.801029, 58.4],
  [-10.80721, 165.800986, 58.6],
  [-10.80715, 165.800922, 58.6],
  [-10.807102, 165.800847, 58.8],
  [-10.807063, 165.800772, 59],
  [-10.807029, 165.800697, 59],
  [-10.806998, 165.8006, 59],
  [-10.806965, 165.800525, 59],
  [-10.80693, 165.80045, 59],
  [-10.806885, 165.800375, 59],
  [-10.806829, 165.8003, 59],
  [-10.806758, 165.800235, 58.8],
  [-10.806676, 165.800192, 58.4],
  [-10.806587, 165.800149, 58],
  [-10.806491, 165.800117, 57.6],
  [-10.80639, 165.800085, 57],
  [-10.806284, 165.800064, 56.4],
  [-10.806173, 165.800042, 55.6],
  [-10.806058, 165.800021, 55],
  [-10.805939, 165.80001, 54.2],
  [-10.805817, 165.799989, 53.6],
  [-10.805694, 165.799978, 53],
  [-10.80557, 165.799956, 52.6],
  [-10.805448, 165.799935, 52.4],
  [-10.805329, 165.799881, 52.4],
  [-10.805216, 165.799838, 52.2],
  [-10.805107, 165.799774, 52],
  [-10.804999, 165.79972, 51.6],
  [-10.80489, 165.799656, 51],
  [-10.80478, 165.799592, 50.6],
  [-10.804666, 165.799549, 50],
  [-10.804545, 165.799506, 49.4],
  [-10.804421, 165.799474, 48.8],
  [-10.804291, 165.799463, 48.2],
  [-10.804161, 165.799474, 47.8],
  [-10.804041, 165.799538, 47.2],
  [-10.803937, 165.799613, 46.8],
  [-10.803851, 165.79972, 46.6],
  [-10.803792, 165.799828, 46.2],
  [-10.803773, 165.799956, 46.2],
  [-10.80379, 165.800085, 46.2],
  [-10.803815, 165.800203, 46.2],
  [-10.803839, 165.800332, 46.2],
  [-10.803862, 165.80045, 46.2],
  [-10.803884, 165.800568, 46],
  [-10.803906, 165.800697, 46],
  [-10.803928, 165.800804, 46],
  [-10.803946, 165.800922, 46],
  [-10.803933, 165.801029, 45.8],
  [-10.803888, 165.801136, 45.4],
  [-10.803826, 165.801244, 44.8],
  [-10.803758, 165.801351, 44.2],
  [-10.803692, 165.801437, 43.6],
  [-10.803644, 165.801566, 43],
  [-10.803627, 165.801684, 42.4],
  [-10.803643, 165.801823, 42.2],
  [-10.803676, 165.801941, 42],
  [-10.803725, 165.802048, 42],
  [-10.803792, 165.802156, 41.8],
  [-10.803883, 165.802231, 41.6],
  [-10.803998, 165.802284, 41.2],
  [-10.804121, 165.802284, 40.4],
  [-10.804245, 165.802252, 39.6],
  [-10.804364, 165.802209, 38.8],
  [-10.804477, 165.802124, 38.2],
  [-10.804582, 165.802048, 37.4],
  [-10.804676, 165.801963, 36.6],
  [-10.804759, 165.801834, 36],
  [-10.804827, 165.801716, 35.2],
  [-10.804877, 165.801598, 34.6],
  [-10.804916, 165.801458, 34.4],
  [-10.80494, 165.801319, 34],
  [-10.804951, 165.801179, 33.8],
  [-10.804945, 165.80104, 33.6],
  [-10.804922, 165.800922, 33.4],
  [-10.804877, 165.800804, 33],
  [-10.804804, 165.800675, 32.6],
  [-10.804706, 165.800589, 31.8],
  [-10.804592, 165.800525, 31.2],
  [-10.804466, 165.800482, 30.4],
  [-10.804333, 165.80045, 29.8],
  [-10.804197, 165.800439, 29],
  [-10.804059, 165.800418, 28.4],
  [-10.80392, 165.800439, 27.6],
  [-10.803781, 165.800439, 27],
  [-10.803642, 165.800461, 26.2],
  [-10.803502, 165.800482, 25.6],
  [-10.803366, 165.800525, 24.8],
  [-10.803247, 165.8006, 24.2],
  [-10.803147, 165.800707, 23.8],
  [-10.803068, 165.800815, 23.2],
  [-10.803009, 165.800954, 22.6],
  [-10.802988, 165.801094, 22.2],
  [-10.803007, 165.801233, 21.6],
  [-10.803039, 165.801362, 21.2],
  [-10.803056, 165.801501, 20.8],
  [-10.803009, 165.801641, 20],
  [-10.802901, 165.801727, 19.4],
  [-10.802772, 165.80178, 19],
  [-10.802638, 165.801823, 18.4],
  [-10.802508, 165.801866, 18],
  [-10.802393, 165.801941, 17.4],
  [-10.802325, 165.80207, 16.8],
  [-10.802304, 165.802209, 16],
  [-10.802319, 165.802338, 15.4],
  [-10.802357, 165.802478, 14.8],
  [-10.802406, 165.802617, 14.4],
  [-10.80246, 165.802735, 13.8],
  [-10.802507, 165.802875, 13.2],
  [-10.802535, 165.803014, 12.8],
  [-10.802538, 165.803154, 12.2],
  [-10.802528, 165.803293, 11.8],
  [-10.802512, 165.803422, 11.2],
  [-10.802489, 165.803561, 10.8],
  [-10.80246, 165.803701, 10.2],
  [-10.802426, 165.80384, 9.8],
  [-10.802386, 165.80398, 9.6],
  [-10.802345, 165.804098, 9.4],
  [-10.8023, 165.804237, 9.2],
  [-10.802254, 165.804355, 9.2],
  [-10.802209, 165.804473, 9.2],
  [-10.802165, 165.804602, 9.2],
  [-10.802122, 165.804709, 9.2],
  [-10.802079, 165.804827, 9.2],
  [-10.802038, 165.804935, 9.2],
  [-10.801997, 165.805053, 9.2],
  [-10.801956, 165.80516, 9.2],
  [-10.801916, 165.805267, 9.2],
  [-10.801875, 165.805374, 9.2],
  [-10.801836, 165.805482, 9.2],
  [-10.801798, 165.805589, 9.2],
  [-10.801759, 165.805696, 9.2],
  [-10.801722, 165.805804, 9.2],
  [-10.801673, 165.8059, 9],
  [-10.801606, 165.805986, 8.8],
  [-10.801525, 165.806061, 8.4],
  [-10.801437, 165.806125, 8.4],
  [-10.801342, 165.80619, 8.2],
  [-10.801243, 165.806243, 8],
  [-10.801142, 165.806297, 7.8],
  [-10.80104, 165.80634, 7.6],
  [-10.800939, 165.806404, 7.6],
  [-10.800838, 165.806447, 7.4],
  [-10.800739, 165.80649, 7.4],
  [-10.800645, 165.806555, 7.2],
  [-10.800558, 165.80663, 7],
  [-10.800479, 165.806705, 7],
  [-10.800411, 165.806801, 6.8],
  [-10.80036, 165.806909, 6.6],
  [-10.800325, 165.807016, 6.6],
  [-10.800306, 165.807112, 6.4],
  [-10.800304, 165.80722, 6.2],
  [-10.800321, 165.807348, 6.2],
  [-10.800354, 165.807456, 6],
  [-10.800404, 165.807542, 6],
  [-10.800466, 165.807638, 6],
  [-10.800536, 165.807724, 5.8],
  [-10.800612, 165.807799, 5.8],
  [-10.800693, 165.807885, 5.6],
  [-10.800779, 165.80796, 5.8],
  [-10.800867, 165.808014, 5.8],
  [-10.80096, 165.808078, 5.8],
];

export default stream;
