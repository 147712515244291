import { LatLngAlt } from "../../../types.js";

export const edgeNeokyoC1: LatLngAlt[] = [
  [-10.795404, 165.839878, 65.6],
  [-10.795388, 165.839996, 65.6],
  [-10.795323, 165.840093, 65.6],
  [-10.795218, 165.840157, 65.6],
];
export const edgeNeokyoC2: LatLngAlt[] = [
  [-10.795198, 165.840168, 65.6],
  [-10.795322, 165.840157, 65.6],
  [-10.79544, 165.840157, 65.6],
  [-10.795559, 165.840179, 65.6],
];
export const edgeNeokyoC3: LatLngAlt[] = [
  [-10.795607, 165.840104, 65.6],
  [-10.795512, 165.840029, 65.6],
  [-10.795441, 165.839943, 65.6],
];
