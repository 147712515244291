import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [47.253276, 11.412742, 297.8],
  [47.253233, 11.412483, 298.2],
  [47.25319, 11.412219, 298.6],
  [47.253147, 11.411952, 299],
  [47.253109, 11.411685, 299.6],
  [47.253066, 11.411422, 299.8],
  [47.253023, 11.411162, 300.6],
  [47.252954, 11.410911, 302.2],
  [47.252884, 11.410678, 304.4],
  [47.252803, 11.410452, 306.6],
  [47.252734, 11.410228, 308.8],
  [47.252669, 11.410021, 310.6],
  [47.252605, 11.409806, 312.4],
  [47.252551, 11.409591, 314],
  [47.252508, 11.409377, 315.2],
  [47.252471, 11.40916, 316.4],
  [47.252428, 11.408934, 317.6],
  [47.25239, 11.408713, 318.6],
  [47.252353, 11.408488, 319.6],
  [47.252315, 11.408265, 321],
  [47.252267, 11.40804, 322.2],
  [47.252219, 11.407817, 323.6],
  [47.252176, 11.407604, 325.2],
  [47.252128, 11.407385, 327],
  [47.252085, 11.407172, 328.6],
  [47.252042, 11.406963, 330.4],
  [47.251999, 11.406748, 332],
  [47.251951, 11.406539, 333.8],
  [47.251902, 11.406343, 335.4],
  [47.251854, 11.406138, 337.2],
  [47.2518, 11.405941, 338.8],
  [47.251741, 11.405752, 340.4],
  [47.251677, 11.405563, 342],
  [47.251596, 11.40539, 343.4],
  [47.2515, 11.405225, 345],
  [47.251398, 11.405085, 346.2],
  [47.251285, 11.404959, 347.6],
  [47.251157, 11.404848, 348.8],
  [47.251028, 11.404754, 349.8],
  [47.250888, 11.404664, 350.8],
  [47.250744, 11.404586, 351.6],
  [47.250588, 11.404537, 352],
  [47.250438, 11.404534, 352],
  [47.250271, 11.404574, 352],
  [47.250111, 11.404649, 352.4],
  [47.24996, 11.404763, 352.4],
  [47.249815, 11.4049, 352.6],
  [47.249671, 11.405056, 352.8],
  [47.249542, 11.40523, 353.2],
  [47.24944, 11.405453, 353],
  [47.249343, 11.405696, 353.2],
  [47.249204, 11.405863, 354.6],
  [47.249038, 11.405957, 356.6],
  [47.248877, 11.406019, 358.8],
  [47.24871, 11.40607, 361.4],
  [47.24856, 11.406106, 363.6],
  [47.248399, 11.406135, 366],
  [47.248254, 11.406156, 368.2],
  [47.24811, 11.406165, 370.2],
  [47.24797, 11.406166, 372.2],
  [47.247831, 11.406154, 373.8],
  [47.247686, 11.406122, 375.4],
  [47.247552, 11.406068, 376.6],
  [47.247412, 11.405997, 377.8],
  [47.247283, 11.405915, 378.8],
  [47.247149, 11.405824, 379.8],
  [47.24701, 11.405722, 380.6],
  [47.246876, 11.405622, 381.4],
  [47.246742, 11.405523, 382.2],
  [47.246597, 11.405434, 383],
  [47.246436, 11.405354, 383.4],
  [47.246291, 11.40528, 383.4],
  [47.24613, 11.405178, 384.2],
  [47.24598, 11.405064, 385.4],
  [47.245835, 11.404943, 386.6],
  [47.24569, 11.404822, 388],
  [47.245551, 11.404703, 389.4],
  [47.245411, 11.40459, 391],
  [47.245266, 11.404483, 393],
  [47.245127, 11.404391, 395],
  [47.244987, 11.404306, 397.2],
  [47.244848, 11.404226, 399.4],
  [47.244719, 11.404148, 401.6],
  [47.244591, 11.404061, 403.8],
  [47.244473, 11.40397, 405.8],
  [47.244349, 11.403868, 407.4],
  [47.244236, 11.403752, 409],
  [47.244129, 11.403636, 410.2],
  [47.244022, 11.40351, 411.4],
  [47.243909, 11.403375, 412.4],
  [47.243802, 11.403232, 413.6],
  [47.243705, 11.403074, 414.6],
  [47.243614, 11.402897, 416],
  [47.243544, 11.402702, 417.6],
  [47.243518, 11.402492, 419.4],
  [47.243512, 11.402276, 421.2],
  [47.243523, 11.402065, 423],
  [47.243534, 11.401853, 424.6],
  [47.243534, 11.401643, 426.4],
  [47.243496, 11.401448, 428.2],
  [47.24341, 11.401286, 429.8],
  [47.243282, 11.401201, 431.6],
  [47.243147, 11.401171, 433.8],
  [47.243013, 11.401176, 436],
  [47.242885, 11.401193, 438.2],
  [47.242756, 11.401214, 440.4],
  [47.242627, 11.4012, 442.6],
  [47.242509, 11.401134, 444.6],
  [47.242407, 11.401022, 446.4],
  [47.242316, 11.400842, 448.8],
  [47.242268, 11.400716, 450],
  [47.242193, 11.400567, 451.8],
  [47.242107, 11.40044, 453.6],
  [47.241989, 11.400355, 454.8],
  [47.24186, 11.400303, 455.6],
  [47.241726, 11.400264, 456.6],
  [47.241586, 11.400233, 457.2],
  [47.241442, 11.4002, 458],
  [47.241297, 11.400169, 458.8],
  [47.241147, 11.400135, 459.6],
  [47.240991, 11.400094, 460.6],
  [47.240852, 11.400024, 462],
  [47.240717, 11.399897, 464.2],
  [47.240589, 11.399816, 466.4],
  [47.240438, 11.399804, 468.4],
  [47.240299, 11.399812, 470.4],
  [47.240154, 11.399827, 472.2],
  [47.24002, 11.399839, 474],
  [47.239881, 11.399842, 476],
  [47.239741, 11.399819, 478],
  [47.239612, 11.399761, 480.2],
  [47.239494, 11.399682, 482.4],
  [47.239382, 11.399595, 484.4],
  [47.239264, 11.399525, 486.4],
  [47.239135, 11.39951, 488],
  [47.239006, 11.399557, 489.2],
  [47.238883, 11.399627, 490],
  [47.238754, 11.399714, 490.6],
  [47.23862, 11.399811, 491],
  [47.238497, 11.399906, 491.4],
  [47.238352, 11.400009, 491.8],
  [47.238212, 11.400111, 492],
  [47.238067, 11.400233, 492],
  [47.237928, 11.400374, 492.2],
  [47.237788, 11.40053, 492.2],
  [47.237654, 11.400685, 492.2],
  [47.237509, 11.40085, 492],
  [47.23737, 11.40102, 492.2],
  [47.237236, 11.401191, 492.2],
  [47.237091, 11.40137, 492.4],
  [47.236952, 11.401553, 493],
  [47.236817, 11.401742, 493.6],
  [47.236689, 11.401932, 494.6],
  [47.23656, 11.402121, 496],
  [47.236442, 11.402315, 498.2],
  [47.236329, 11.402501, 500.6],
  [47.236206, 11.402654, 503.2],
  [47.236061, 11.402738, 505.8],
  [47.235911, 11.402729, 508],
  [47.235777, 11.402682, 510.2],
  [47.235637, 11.402622, 512.2],
  [47.235503, 11.402557, 514],
  [47.235369, 11.402497, 515.6],
  [47.23523, 11.402447, 517],
  [47.235096, 11.402391, 517.8],
  [47.234961, 11.402332, 518.6],
  [47.234811, 11.402296, 519.4],
  [47.234656, 11.402293, 520.6],
  [47.234505, 11.402313, 522.2],
  [47.234361, 11.402345, 523.8],
  [47.23421, 11.402386, 525.4],
  [47.234066, 11.402431, 527.2],
  [47.233921, 11.402485, 528.6],
  [47.233781, 11.402547, 530.2],
  [47.233642, 11.402619, 531.4],
  [47.233513, 11.402706, 532.4],
  [47.233379, 11.402828, 533.2],
  [47.233261, 11.402962, 533.6],
  [47.233137, 11.403105, 534],
  [47.233009, 11.403251, 534.4],
  [47.232864, 11.403373, 535],
  [47.232708, 11.403445, 536],
  [47.232537, 11.403471, 537.2],
  [47.232376, 11.403468, 538.6],
  [47.232215, 11.403459, 539.8],
  [47.232054, 11.403471, 540.8],
  [47.231893, 11.403518, 541.4],
  [47.231732, 11.403577, 542],
  [47.231571, 11.403653, 542.4],
  [47.231416, 11.403748, 542.8],
  [47.231276, 11.403897, 543.4],
  [47.231174, 11.404101, 544.2],
  [47.231099, 11.404336, 544.6],
  [47.231045, 11.404574, 545],
  [47.231002, 11.404825, 545.2],
  [47.230949, 11.405077, 545.6],
  [47.230895, 11.40534, 545.8],
  [47.230847, 11.405591, 545.8],
  [47.230799, 11.40586, 546],
  [47.23074, 11.406117, 546.4],
  [47.23067, 11.406363, 546.8],
  [47.230584, 11.406625, 547.6],
  [47.230498, 11.406838, 548.4],
  [47.230396, 11.407059, 549.4],
  [47.230284, 11.407274, 550.4],
  [47.230171, 11.407482, 551.6],
  [47.230064, 11.407675, 552.8],
  [47.229956, 11.407873, 554],
  [47.229844, 11.408075, 555.4],
  [47.229742, 11.408274, 556.8],
  [47.22964, 11.408461, 558.6],
  [47.229522, 11.408627, 560.4],
  [47.229388, 11.408763, 562.6],
  [47.229243, 11.408802, 564.6],
  [47.229093, 11.408776, 566],
  [47.228943, 11.40873, 567.2],
  [47.228792, 11.408682, 568.4],
  [47.228647, 11.408645, 569.8],
  [47.228497, 11.408602, 571.2],
  [47.228352, 11.408571, 573],
  [47.228202, 11.408564, 574.6],
  [47.228057, 11.408568, 576.2],
  [47.227896, 11.408575, 577.8],
  [47.227757, 11.408584, 579.4],
  [47.227607, 11.408596, 581.2],
  [47.227467, 11.408611, 582.8],
  [47.227328, 11.408631, 584.6],
  [47.227183, 11.408657, 586.4],
  [47.227044, 11.408688, 588],
  [47.226909, 11.408729, 589.8],
  [47.22677, 11.408777, 591.8],
  [47.226641, 11.40883, 593.8],
  [47.226512, 11.408895, 595.8],
  [47.226389, 11.408976, 597.8],
  [47.226276, 11.409067, 600],
  [47.226169, 11.409169, 601.8],
  [47.226062, 11.409288, 604],
  [47.225971, 11.409412, 606.2],
  [47.225879, 11.409546, 608.2],
  [47.225799, 11.40968, 610.4],
  [47.225718, 11.409819, 612.4],
  [47.225638, 11.409958, 614.4],
  [47.225558, 11.4101, 616.2],
  [47.225482, 11.410232, 617.8],
  [47.225397, 11.410373, 619.2],
  [47.225311, 11.410513, 620.4],
  [47.22522, 11.410655, 621.4],
  [47.225123, 11.410802, 622],
  [47.225026, 11.410954, 622.6],
  [47.224925, 11.411115, 623],
  [47.224823, 11.411287, 623.4],
  [47.224715, 11.41145, 623.8],
  [47.224597, 11.411622, 624.2],
  [47.224479, 11.41179, 625],
  [47.224356, 11.411946, 626],
  [47.224227, 11.412091, 627.8],
  [47.224088, 11.412215, 630],
  [47.223954, 11.412318, 632.2],
  [47.223819, 11.412413, 634.8],
  [47.223685, 11.4125, 637.4],
  [47.223557, 11.412589, 639.8],
  [47.223444, 11.412688, 641.6],
  [47.223331, 11.412799, 643.2],
  [47.223224, 11.412925, 644.6],
  [47.223122, 11.413061, 646],
  [47.223026, 11.4132, 647],
  [47.222918, 11.413348, 648.2],
  [47.222816, 11.413491, 649.4],
  [47.222698, 11.41363, 650.8],
  [47.22258, 11.413752, 652.2],
  [47.222446, 11.413843, 653.8],
  [47.222312, 11.413899, 655.8],
  [47.222167, 11.41393, 657.6],
  [47.222028, 11.413943, 659.6],
  [47.221888, 11.413949, 661.4],
  [47.221754, 11.413954, 663.4],
  [47.221615, 11.413978, 665],
  [47.221491, 11.414055, 666.2],
  [47.221395, 11.414212, 666.2],
  [47.221314, 11.414395, 666.6],
  [47.221223, 11.414583, 666.8],
  [47.221094, 11.414724, 667.2],
  [47.220955, 11.414836, 667.6],
  [47.220805, 11.414952, 667.8],
  [47.220654, 11.415064, 668.2],
  [47.220504, 11.415192, 668.8],
  [47.220359, 11.415329, 669.4],
  [47.220215, 11.415468, 670],
  [47.22007, 11.415613, 670.6],
  [47.219925, 11.415756, 671.4],
  [47.21978, 11.415899, 672],
  [47.219635, 11.416043, 672.8],
  [47.21949, 11.41618, 673.8],
  [47.219346, 11.416314, 674.6],
  [47.219195, 11.416446, 675.6],
  [47.219051, 11.416579, 676.8],
  [47.218906, 11.416711, 677.8],
  [47.218755, 11.416843, 679],
  [47.218616, 11.416961, 680.2],
  [47.21846, 11.417076, 681],
  [47.21831, 11.417178, 682],
  [47.218155, 11.417271, 682.6],
  [47.217994, 11.417356, 683.4],
  [47.217838, 11.417429, 683.8],
  [47.217667, 11.417495, 684.4],
  [47.2175, 11.417551, 685],
  [47.217334, 11.417598, 685.6],
  [47.217157, 11.41763, 686.4],
  [47.216985, 11.417643, 687.2],
  [47.216808, 11.417651, 688],
  [47.216637, 11.417661, 688.6],
  [47.216465, 11.417684, 689],
  [47.216288, 11.417717, 689.2],
  [47.216111, 11.417752, 689.4],
  [47.21595, 11.417782, 689.4],
  [47.215794, 11.417812, 689.4],
  [47.215639, 11.417846, 689.4],
  [47.215494, 11.417878, 689.4],
  [47.215354, 11.417913, 689.4],
  [47.215215, 11.417951, 689.4],
  [47.215092, 11.417989, 689.4],
  [47.214968, 11.418042, 689.2],
  [47.214856, 11.418124, 689.2],
  [47.21477, 11.418244, 689],
  [47.214684, 11.418374, 689],
  [47.214614, 11.418507, 689],
  [47.21455, 11.418644, 689],
  [47.214507, 11.418791, 689],
  [47.214512, 11.41895, 689],
  [47.214561, 11.419081, 689],
  [47.214625, 11.419186, 689.2],
  [47.2147, 11.419276, 689.2],
  [47.214775, 11.41936, 689.4],
  [47.214845, 11.419442, 689.8],
  [47.214909, 11.419516, 690.2],
  [47.214968, 11.419584, 690.6],
  [47.215027, 11.419646, 691],
  [47.215086, 11.419699, 691.4],
  [47.21514, 11.419746, 691.6],
  [47.215183, 11.419789, 692],
  [47.215231, 11.419828, 692.2],
  [47.215274, 11.419864, 692.4],
  [47.215312, 11.419898, 692.6],
  [47.215349, 11.419927, 692.8],
  [47.215381, 11.419957, 693],
  [47.215408, 11.419981, 693],
  [47.21544, 11.420004, 693.2],
  [47.215462, 11.420025, 693.4],
  [47.215483, 11.420044, 693.4],
  [47.215499, 11.42006, 693.6],
  [47.215521, 11.420076, 693.6],
  [47.215531, 11.420087, 693.8],
  [47.215548, 11.420099, 693.8],
  [47.215558, 11.42011, 693.8],
  [47.215564, 11.420116, 694],
  [47.21558, 11.420126, 694],
  [47.215585, 11.420133, 694],
  [47.21559, 11.420139, 694],
  [47.215601, 11.420146, 694.2],
  [47.215607, 11.420153, 694.2],
  [47.215617, 11.420158, 694.2],
  [47.215617, 11.420165, 694.2],
  [47.215628, 11.42017, 694.2],
  [47.215633, 11.420177, 694.2],
  [47.215644, 11.420182, 694.4],
  [47.215649, 11.420189, 694.4],
  [47.215655, 11.420194, 694.4],
  [47.215666, 11.420201, 694.4],
  [47.215671, 11.420206, 694.4],
  [47.215682, 11.420212, 694.4],
  [47.215687, 11.420218, 694.6],
  [47.215698, 11.420225, 694.6],
  [47.215703, 11.42023, 694.6],
  [47.215708, 11.420236, 694.6],
  [47.215719, 11.420241, 694.6],
  [47.215725, 11.420248, 694.8],
  [47.215735, 11.420253, 694.8],
  [47.215746, 11.42026, 694.8],
  [47.215751, 11.420265, 694.8],
  [47.215762, 11.420272, 694.8],
  [47.215768, 11.420277, 694.8],
  [47.215778, 11.420284, 695],
  [47.215789, 11.420291, 695],
  [47.215794, 11.420296, 695],
  [47.215805, 11.420303, 695],
  [47.215816, 11.42031, 695],
  [47.215821, 11.420315, 695],
  [47.215832, 11.420322, 695],
  [47.215843, 11.420328, 695.2],
  [47.215853, 11.420335, 695.2],
  [47.215859, 11.420342, 695.2],
  [47.215869, 11.420348, 695.2],
  [47.21588, 11.420355, 695.2],
  [47.215896, 11.420362, 695.2],
  [47.215902, 11.420369, 695.4],
  [47.215912, 11.420375, 695.4],
  [47.215923, 11.420383, 695.4],
  [47.215934, 11.42039, 695.4],
  [47.21595, 11.420397, 695.4],
  [47.215961, 11.420403, 695.4],
  [47.215977, 11.420411, 695.4],
  [47.215987, 11.42042, 695.6],
  [47.215998, 11.420426, 695.6],
  [47.216014, 11.420434, 695.6],
  [47.216025, 11.420442, 695.6],
  [47.216041, 11.420449, 695.6],
  [47.216057, 11.420457, 695.6],
  [47.216073, 11.420465, 695.6],
  [47.216089, 11.420473, 695.6],
  [47.216105, 11.420483, 695.6],
  [47.216122, 11.420491, 695.6],
  [47.216143, 11.420499, 695.6],
  [47.216159, 11.420508, 695.8],
  [47.216181, 11.420516, 695.8],
  [47.216197, 11.420525, 695.8],
  [47.216218, 11.420535, 695.8],
  [47.21624, 11.420544, 695.8],
  [47.216266, 11.420552, 695.8],
  [47.216288, 11.420563, 695.8],
  [47.216309, 11.420572, 695.8],
  [47.216336, 11.420582, 695.8],
  [47.216358, 11.420591, 695.8],
  [47.216384, 11.420601, 695.8],
  [47.216417, 11.420611, 695.6],
  [47.216443, 11.420621, 695.6],
  [47.21647, 11.420631, 695.6],
  [47.216502, 11.420642, 695.6],
  [47.216529, 11.420653, 695.6],
  [47.216567, 11.420664, 695.6],
  [47.216599, 11.420673, 695.6],
  [47.216631, 11.420684, 695.6],
  [47.216669, 11.420694, 695.4],
  [47.216706, 11.420707, 695.4],
  [47.216738, 11.420717, 695.4],
  [47.216781, 11.420729, 695.2],
  [47.216819, 11.42074, 695.2],
  [47.216867, 11.420752, 695.2],
  [47.21691, 11.420764, 695],
  [47.216953, 11.420776, 695],
  [47.217001, 11.42079, 694.8],
  [47.217044, 11.420802, 694.8],
  [47.217098, 11.420815, 694.6],
  [47.217146, 11.420829, 694.4],
  [47.2172, 11.420842, 694.4],
  [47.217253, 11.420855, 694.2],
  [47.217307, 11.42087, 694],
  [47.217361, 11.420885, 694],
  [47.217425, 11.420901, 693.8],
  [47.217484, 11.420917, 693.6],
  [47.217543, 11.42093, 693.4],
  [47.217607, 11.420947, 693.2],
  [47.217672, 11.420961, 692.8],
  [47.217742, 11.420975, 692.6],
  [47.217806, 11.420988, 692.4],
  [47.217876, 11.421004, 692.2],
  [47.217951, 11.421018, 691.8],
  [47.218021, 11.421032, 691.6],
  [47.218101, 11.421047, 691.2],
  [47.218176, 11.421063, 691],
  [47.218257, 11.421078, 690.6],
  [47.218337, 11.421095, 690.4],
  [47.218418, 11.42111, 690],
  [47.218498, 11.421126, 689.6],
  [47.218589, 11.421145, 689.2],
  [47.21867, 11.421164, 689],
  [47.218761, 11.421185, 688.6],
  [47.218852, 11.421208, 688.2],
  [47.218933, 11.421231, 687.8],
  [47.219024, 11.421256, 687.4],
  [47.219115, 11.421285, 687],
  [47.219211, 11.421317, 686.6],
  [47.219303, 11.42135, 686.4],
  [47.219394, 11.421388, 686],
  [47.219485, 11.421427, 685.6],
  [47.219576, 11.421468, 685.2],
  [47.219673, 11.421513, 684.8],
  [47.219764, 11.421559, 684.6],
  [47.219855, 11.421609, 684.2],
  [47.219946, 11.421663, 683.8],
  [47.220038, 11.421718, 683.4],
  [47.220129, 11.421777, 683],
  [47.220215, 11.421837, 682.6],
  [47.220306, 11.421904, 682.2],
  [47.220397, 11.421973, 681.8],
  [47.220488, 11.422044, 681.4],
  [47.220579, 11.422121, 681],
  [47.220676, 11.422213, 680.6],
  [47.220794, 11.422323, 679.8],
  [47.220912, 11.422454, 679.2],
  [47.22103, 11.4226, 678.4],
  [47.221153, 11.422762, 677.6],
  [47.221282, 11.422943, 676.8],
  [47.221406, 11.423139, 676.2],
  [47.221524, 11.423346, 675.6],
  [47.221631, 11.423575, 675.4],
  [47.221733, 11.423823, 675.2],
  [47.221824, 11.424063, 674.8],
  [47.22192, 11.424321, 674.4],
  [47.222017, 11.424577, 674],
  [47.222103, 11.424837, 673.4],
  [47.222189, 11.425107, 673],
  [47.222275, 11.425372, 672.4],
  [47.22236, 11.425644, 671.8],
  [47.222457, 11.425915, 671],
  [47.222564, 11.426193, 670],
  [47.222672, 11.426433, 669.2],
  [47.22279, 11.426692, 668],
  [47.222918, 11.426945, 666.8],
  [47.223052, 11.427196, 665.4],
  [47.223186, 11.427439, 664.2],
  [47.223337, 11.427678, 662.6],
  [47.223487, 11.427904, 660.8],
  [47.223659, 11.428127, 658.8],
  [47.223836, 11.428345, 656.4],
  [47.224013, 11.428544, 654],
  [47.224206, 11.428746, 651.4],
  [47.224399, 11.428937, 648.8],
  [47.224603, 11.429127, 646.6],
  [47.224812, 11.42931, 645],
  [47.225021, 11.429468, 644],
  [47.225236, 11.429603, 643.4],
  [47.225445, 11.429725, 643],
  [47.225665, 11.429842, 642.2],
  [47.225869, 11.429955, 641.2],
  [47.226089, 11.430061, 640.2],
  [47.226298, 11.430143, 639],
  [47.226523, 11.4302, 637.6],
  [47.226754, 11.430238, 635.8],
  [47.226974, 11.430254, 633.8],
  [47.227199, 11.430248, 631.8],
  [47.22743, 11.430219, 629.8],
  [47.22766, 11.43016, 627.8],
  [47.227886, 11.430071, 626],
  [47.228111, 11.42997, 624],
  [47.228336, 11.429864, 621.8],
  [47.228562, 11.429767, 619.8],
  [47.228798, 11.429701, 617.8],
  [47.229034, 11.429689, 615.8],
  [47.22927, 11.429733, 613.8],
  [47.229495, 11.429815, 612],
  [47.229731, 11.4299, 610.4],
  [47.229956, 11.42996, 609.2],
  [47.230198, 11.43002, 607.8],
  [47.230418, 11.430078, 606.8],
  [47.230643, 11.430148, 605.6],
  [47.230868, 11.43022, 604.6],
  [47.231088, 11.430303, 603.6],
  [47.231308, 11.430409, 602.4],
  [47.231512, 11.430541, 601],
  [47.231716, 11.43071, 599.8],
  [47.231898, 11.43089, 598.6],
  [47.232091, 11.43108, 597.2],
  [47.232285, 11.431238, 595.6],
  [47.232504, 11.431371, 593.4],
  [47.232719, 11.431462, 590.6],
  [47.232944, 11.431539, 587.8],
  [47.23318, 11.431623, 585.4],
  [47.233406, 11.43172, 583.6],
  [47.233636, 11.431822, 581.8],
  [47.233872, 11.431924, 580],
  [47.234092, 11.432021, 578.4],
  [47.234318, 11.432121, 576.8],
  [47.234554, 11.432224, 575.2],
  [47.234763, 11.432319, 573.6],
  [47.234988, 11.432416, 572],
  [47.235224, 11.432512, 570.4],
  [47.235444, 11.432601, 568.8],
  [47.235669, 11.432681, 567.2],
  [47.235906, 11.432742, 565.4],
  [47.236136, 11.43277, 563.8],
  [47.236372, 11.432786, 561.8],
  [47.236592, 11.432868, 559.6],
  [47.236807, 11.433017, 557.2],
  [47.237021, 11.433196, 554.4],
  [47.237231, 11.433353, 552],
  [47.237456, 11.433459, 549.8],
  [47.237692, 11.433387, 548.8],
  [47.237906, 11.43325, 548.2],
  [47.238126, 11.433104, 547.6],
  [47.23833, 11.432979, 547.2],
  [47.238529, 11.43305, 545.6],
  [47.238502, 11.433377, 544.4],
  [47.238486, 11.433709, 543.4],
  [47.238625, 11.433954, 542.6],
  [47.238824, 11.434123, 542.2],
  [47.239017, 11.434278, 542],
  [47.239205, 11.434443, 542],
  [47.239392, 11.434591, 542],
  [47.239586, 11.434744, 541.6],
  [47.239768, 11.434902, 541.4],
  [47.23994, 11.43506, 541.2],
  [47.240127, 11.435223, 540.8],
  [47.240304, 11.435374, 540.4],
  [47.240481, 11.435522, 539.6],
  [47.24068, 11.435664, 538.6],
  [47.240873, 11.435791, 537],
  [47.241071, 11.435908, 535],
  [47.241281, 11.436019, 532.4],
  [47.241495, 11.436128, 529.4],
  [47.24171, 11.436232, 526.8],
  [47.24193, 11.436341, 524.2],
  [47.24216, 11.436454, 521.6],
  [47.242386, 11.436556, 519],
  [47.242622, 11.436656, 516.4],
  [47.242847, 11.436757, 514],
  [47.243078, 11.436879, 511.4],
  [47.243292, 11.437038, 508.6],
  [47.243491, 11.437258, 505.6],
  [47.243662, 11.437521, 502.6],
  [47.24384, 11.437789, 499.6],
  [47.244038, 11.438008, 497.4],
  [47.244247, 11.438207, 495.4],
  [47.244456, 11.438397, 493.4],
  [47.244666, 11.438578, 491.6],
  [47.244886, 11.438751, 490],
  [47.245106, 11.438913, 488],
  [47.24532, 11.439062, 486.2],
  [47.245545, 11.439186, 484.2],
  [47.245776, 11.439285, 482.2],
  [47.246001, 11.439377, 480],
  [47.246237, 11.439473, 477.8],
  [47.246463, 11.43958, 475.8],
  [47.246688, 11.439718, 473.8],
  [47.246892, 11.439908, 472.2],
  [47.247069, 11.440142, 471],
  [47.247219, 11.440411, 470.4],
  [47.247342, 11.440708, 470],
  [47.24745, 11.440999, 469.2],
  [47.247557, 11.441296, 468],
  [47.247664, 11.441592, 466.4],
  [47.247772, 11.441889, 464.4],
  [47.247863, 11.442195, 462.4],
  [47.247965, 11.442516, 460.2],
  [47.248056, 11.442827, 458],
  [47.248152, 11.443149, 455.8],
  [47.248254, 11.443468, 453.6],
  [47.248362, 11.443779, 451.4],
  [47.24848, 11.444077, 449.4],
  [47.248614, 11.444366, 447.8],
  [47.248775, 11.44463, 446.6],
  [47.248957, 11.44486, 445.8],
  [47.249145, 11.445037, 445.4],
  [47.249343, 11.445199, 445],
  [47.249547, 11.445348, 444.4],
  [47.249751, 11.445469, 444],
  [47.249966, 11.445568, 443.6],
  [47.250175, 11.44565, 443.2],
  [47.250384, 11.445725, 442.8],
  [47.250599, 11.445796, 442.2],
  [47.250808, 11.445863, 441.6],
  [47.251017, 11.445921, 440.8],
  [47.251232, 11.445962, 440],
  [47.251441, 11.445977, 439.2],
  [47.251655, 11.445931, 438.4],
  [47.251838, 11.445774, 437.6],
  [47.251983, 11.445537, 436.4],
  [47.252117, 11.445288, 434.6],
  [47.252251, 11.445031, 432.6],
  [47.252385, 11.444759, 430.6],
  [47.252514, 11.444472, 428.4],
  [47.252626, 11.444183, 426.4],
  [47.252712, 11.443873, 424.8],
  [47.252723, 11.443528, 423.6],
  [47.252664, 11.443202, 423],
  [47.252584, 11.44289, 422.6],
  [47.252514, 11.442577, 422.2],
  [47.252433, 11.442278, 422],
  [47.252342, 11.441975, 421.8],
  [47.252262, 11.441684, 421.4],
  [47.252181, 11.441396, 421.2],
  [47.252117, 11.441091, 420.6],
  [47.252069, 11.440783, 419.8],
  [47.252036, 11.440472, 418.8],
  [47.252015, 11.440154, 417.8],
  [47.251999, 11.439836, 416.6],
  [47.251988, 11.439511, 415.4],
  [47.251972, 11.439188, 414.2],
  [47.251956, 11.438869, 413],
  [47.25194, 11.438543, 411.8],
  [47.251929, 11.438217, 410.6],
  [47.251918, 11.437886, 409],
  [47.251945, 11.437566, 407],
  [47.252058, 11.437277, 404.8],
  [47.252229, 11.437048, 402.2],
  [47.252455, 11.436981, 399.4],
  [47.252669, 11.436827, 397],
  [47.252814, 11.436566, 394.8],
  [47.252948, 11.436271, 392.8],
  [47.253066, 11.435962, 390.8],
  [47.253174, 11.43564, 388.6],
  [47.253265, 11.435315, 386.2],
  [47.253356, 11.434985, 383.6],
  [47.253442, 11.434645, 381],
  [47.253517, 11.434311, 378.4],
  [47.253581, 11.433955, 376],
  [47.253613, 11.433592, 373.8],
  [47.253597, 11.433234, 372.2],
  [47.253554, 11.432885, 370.8],
  [47.253495, 11.43254, 369.6],
  [47.253458, 11.43219, 368.2],
  [47.253544, 11.431874, 366.2],
  [47.253753, 11.431773, 364.6],
  [47.253951, 11.431937, 362.8],
  [47.254086, 11.432232, 360.8],
  [47.254198, 11.432547, 359.2],
  [47.254295, 11.432862, 357.6],
  [47.254386, 11.433179, 356.2],
  [47.254472, 11.433494, 354.6],
  [47.254536, 11.433824, 353.2],
  [47.254574, 11.434169, 352.2],
  [47.254681, 11.434462, 351],
  [47.25489, 11.434496, 349.6],
  [47.254971, 11.434185, 348],
  [47.25496, 11.433847, 346.8],
  [47.254928, 11.433509, 345.4],
  [47.254896, 11.433179, 344.2],
  [47.254874, 11.432833, 343],
  [47.254874, 11.432504, 341.4],
  [47.254917, 11.432169, 339.6],
  [47.255035, 11.431874, 337.2],
  [47.25518, 11.43161, 334.6],
  [47.255335, 11.431352, 332.2],
  [47.255459, 11.431053, 330],
  [47.25555, 11.430722, 328.4],
  [47.255604, 11.430387, 327.2],
  [47.255641, 11.430037, 326],
  [47.255663, 11.429695, 325],
  [47.255673, 11.429355, 324],
  [47.255679, 11.42902, 323],
  [47.255673, 11.428682, 321.8],
  [47.255668, 11.428339, 320.8],
  [47.255641, 11.42801, 319.6],
  [47.255614, 11.42768, 318.2],
  [47.255571, 11.427337, 316.6],
  [47.255529, 11.427018, 315],
  [47.25548, 11.426686, 313.4],
  [47.255432, 11.426354, 311.6],
  [47.255378, 11.426013, 310],
  [47.255325, 11.425682, 308.4],
  [47.255276, 11.425357, 307],
  [47.255223, 11.425017, 305.8],
  [47.255169, 11.424688, 304.6],
  [47.255126, 11.424369, 303.6],
  [47.255073, 11.42403, 302.6],
  [47.255024, 11.423713, 301.8],
  [47.254971, 11.423386, 301.2],
  [47.254917, 11.42307, 300.6],
  [47.254853, 11.42276, 300],
  [47.254794, 11.422451, 299.8],
  [47.254729, 11.422136, 299.8],
  [47.254665, 11.421841, 299.8],
  [47.254601, 11.42155, 299.8],
  [47.254531, 11.421254, 299.8],
  [47.254456, 11.420968, 299.8],
  [47.254381, 11.420678, 299.6],
  [47.254295, 11.420411, 299.4],
  [47.254193, 11.420146, 299],
  [47.254091, 11.419884, 298.6],
  [47.25401, 11.419604, 298.2],
  [47.253935, 11.419317, 297.8],
  [47.253866, 11.41903, 297.2],
  [47.253796, 11.418736, 296.6],
  [47.253737, 11.418448, 296],
  [47.253683, 11.418152, 295.6],
  [47.253646, 11.417851, 295.2],
  [47.253624, 11.417546, 295.2],
  [47.25364, 11.417237, 295.2],
  [47.253667, 11.416941, 295.4],
  [47.25371, 11.416646, 295.6],
  [47.253748, 11.416363, 295.8],
  [47.253774, 11.416069, 296],
  [47.253769, 11.415778, 296.2],
  [47.253726, 11.41548, 296.4],
  [47.253672, 11.415208, 296.6],
  [47.253619, 11.414932, 296.8],
  [47.253565, 11.414653, 297],
  [47.253517, 11.414368, 297.2],
];

export default stream;
