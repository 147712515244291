import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.642407, 166.939209, 130.8],
  [-11.642434, 166.939026, 131.6],
  [-11.6425, 166.938865, 131.8],
  [-11.642592, 166.938704, 131.6],
  [-11.642702, 166.938543, 131.4],
  [-11.642834, 166.938404, 131],
  [-11.642996, 166.938286, 131],
  [-11.643186, 166.938232, 131],
  [-11.643388, 166.938232, 131],
  [-11.643589, 166.938297, 131],
  [-11.64377, 166.938393, 131],
  [-11.643919, 166.938522, 131],
  [-11.644025, 166.938704, 131],
  [-11.64409, 166.938908, 131],
  [-11.644124, 166.939123, 131],
  [-11.64411, 166.939337, 131],
  [-11.644007, 166.93952, 131],
  [-11.643846, 166.939659, 131],
  [-11.643662, 166.939766, 131],
  [-11.643465, 166.939842, 131],
  [-11.643253, 166.939874, 131],
  [-11.643044, 166.939842, 131],
  [-11.642881, 166.939702, 130.8],
  [-11.642755, 166.93953, 130.6],
  [-11.642599, 166.93938, 129.8],
];

export default stream;
