import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.75821, 165.834203, 68],
  [-10.758215, 165.834321, 68],
  [-10.758224, 165.83445, 68],
  [-10.758246, 165.834568, 68.2],
  [-10.758302, 165.834675, 68.2],
  [-10.758383, 165.834771, 68.2],
  [-10.758472, 165.834846, 68.2],
  [-10.758557, 165.834932, 68.2],
  [-10.758623, 165.83504, 68.2],
  [-10.758673, 165.835147, 68.2],
  [-10.758707, 165.835265, 68.2],
  [-10.758714, 165.835394, 68.2],
  [-10.758684, 165.835512, 68.2],
  [-10.758623, 165.835619, 68.2],
  [-10.758548, 165.835716, 68.2],
  [-10.758472, 165.835823, 68.2],
  [-10.758411, 165.83593, 68.2],
  [-10.758375, 165.836037, 68.2],
  [-10.758357, 165.836166, 68.2],
  [-10.75835, 165.836295, 68.2],
  [-10.75835, 165.836413, 68.2],
  [-10.758363, 165.836542, 68.2],
  [-10.758389, 165.83666, 68.2],
  [-10.758433, 165.836767, 68.2],
  [-10.758501, 165.836874, 68.2],
  [-10.758587, 165.83696, 68.2],
  [-10.758688, 165.837035, 68.2],
  [-10.758791, 165.8371, 68.2],
  [-10.758896, 165.837164, 68.2],
  [-10.759, 165.837228, 68.2],
  [-10.759099, 165.837303, 68.2],
  [-10.759179, 165.837411, 68.2],
  [-10.759231, 165.837518, 68.2],
  [-10.759262, 165.837636, 68.2],
  [-10.759276, 165.837754, 68.2],
  [-10.759283, 165.837883, 68.2],
  [-10.759293, 165.838001, 68.2],
  [-10.759296, 165.838119, 68.2],
  [-10.759283, 165.838248, 68.2],
];

export default stream;
