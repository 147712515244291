import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [44.495157, 11.341121, 297.6],
  [44.495157, 11.341115, 297.6],
  [44.495165, 11.341076, 297.6],
  [44.495171, 11.341017, 297.6],
  [44.495184, 11.340944, 297.6],
  [44.495189, 11.340863, 297.6],
  [44.495203, 11.340771, 297.6],
  [44.495216, 11.340672, 297.6],
  [44.495232, 11.340567, 297.6],
  [44.495251, 11.340455, 297.6],
  [44.49527, 11.340339, 297.6],
  [44.495289, 11.340219, 297.8],
  [44.49531, 11.340095, 297.8],
  [44.495329, 11.339967, 298],
  [44.495348, 11.339838, 298],
  [44.495367, 11.339706, 298.2],
  [44.495385, 11.339574, 298.2],
  [44.495404, 11.339437, 298.2],
  [44.495423, 11.339296, 298.2],
  [44.495442, 11.339154, 298.2],
  [44.495466, 11.33901, 298],
  [44.495487, 11.33886, 297.6],
  [44.495511, 11.33871, 297.2],
  [44.495538, 11.33855, 296.8],
  [44.49556, 11.33839, 296.2],
  [44.495584, 11.338221, 295.8],
  [44.495611, 11.338048, 295.2],
  [44.495637, 11.337875, 294.6],
  [44.495667, 11.337695, 294],
  [44.495704, 11.337516, 293.4],
  [44.495745, 11.337335, 293],
  [44.495785, 11.337157, 292.6],
  [44.495831, 11.336976, 292.2],
  [44.495876, 11.336799, 291.8],
  [44.495927, 11.336618, 291.4],
  [44.495975, 11.336438, 291.2],
  [44.496029, 11.336264, 290.8],
  [44.496088, 11.336085, 290.6],
  [44.496142, 11.33591, 290.4],
  [44.496195, 11.335734, 290.2],
  [44.496252, 11.335562, 290.2],
  [44.496305, 11.335391, 290],
  [44.496362, 11.335223, 290],
  [44.496418, 11.335054, 289.8],
  [44.496477, 11.334888, 289.4],
  [44.496539, 11.33472, 289.2],
  [44.496595, 11.334551, 288.8],
  [44.496657, 11.334384, 288.2],
  [44.496716, 11.334216, 287.8],
  [44.496777, 11.33404, 287.2],
  [44.496839, 11.333871, 287],
  [44.496903, 11.333697, 286.6],
  [44.496965, 11.333529, 286.6],
  [44.497027, 11.333358, 286.4],
  [44.497086, 11.333189, 286.6],
  [44.497148, 11.333024, 286.6],
  [44.497209, 11.332859, 286.6],
  [44.497271, 11.332699, 286.8],
  [44.497327, 11.33254, 286.8],
  [44.497384, 11.332383, 286.8],
  [44.497445, 11.332223, 286.8],
  [44.497499, 11.332069, 286.6],
  [44.497561, 11.331912, 286.4],
  [44.497617, 11.331752, 286.2],
  [44.497679, 11.331594, 285.8],
  [44.49774, 11.331435, 285.6],
  [44.497797, 11.331275, 285.4],
  [44.497858, 11.33111, 285.2],
  [44.497915, 11.33095, 285.2],
  [44.497971, 11.330792, 285.2],
  [44.498033, 11.330634, 285.2],
  [44.498089, 11.330476, 285.2],
  [44.498148, 11.330319, 285.2],
  [44.498204, 11.33016, 285],
  [44.498258, 11.330001, 285],
  [44.498312, 11.329845, 285],
  [44.498368, 11.32969, 285],
  [44.498422, 11.329532, 285],
  [44.498475, 11.329376, 285],
  [44.498529, 11.329222, 285],
  [44.498582, 11.329069, 285],
  [44.498639, 11.328915, 285],
  [44.498692, 11.328759, 284.8],
  [44.498746, 11.328603, 284.6],
  [44.4988, 11.328448, 284.4],
  [44.498856, 11.328288, 283.8],
  [44.49891, 11.328125, 283],
  [44.498966, 11.327957, 282.4],
  [44.499025, 11.327785, 281.6],
  [44.499079, 11.327607, 281],
  [44.499092, 11.327415, 280.6],
  [44.499044, 11.327241, 280.6],
  [44.498928, 11.327142, 280.8],
  [44.498792, 11.327131, 280.8],
  [44.498666, 11.327159, 281],
  [44.49854, 11.327201, 281],
  [44.498414, 11.327244, 281],
  [44.498287, 11.327287, 281],
  [44.498167, 11.327327, 281.2],
  [44.498043, 11.327366, 281.2],
  [44.497923, 11.327405, 281.2],
  [44.497799, 11.327445, 281.2],
  [44.497681, 11.327488, 281.2],
  [44.497563, 11.327531, 281.4],
  [44.497445, 11.327574, 281.4],
  [44.497327, 11.32761, 281.4],
  [44.497209, 11.327586, 281.4],
  [44.497129, 11.327462, 281.2],
  [44.497083, 11.327303, 281.2],
  [44.497046, 11.327142, 281.4],
  [44.497011, 11.326979, 281.6],
  [44.496984, 11.326817, 281.8],
  [44.496968, 11.326654, 282],
  [44.49696, 11.326491, 282.2],
  [44.496965, 11.32633, 282.2],
  [44.496973, 11.326168, 282.4],
  [44.496979, 11.32601, 282.6],
  [44.496987, 11.325848, 282.6],
  [44.496995, 11.325689, 282.8],
  [44.497, 11.325534, 283],
  [44.497005, 11.325375, 283],
  [44.497013, 11.325216, 283.2],
  [44.497021, 11.325058, 283.4],
  [44.497029, 11.324901, 283.4],
  [44.497038, 11.324745, 283.6],
  [44.49704, 11.324588, 283.8],
  [44.497048, 11.324434, 283.8],
  [44.497056, 11.324276, 284],
  [44.497064, 11.324119, 284],
  [44.497072, 11.323965, 284],
  [44.497078, 11.323804, 284],
  [44.497086, 11.323647, 284],
  [44.497094, 11.323487, 284],
  [44.497102, 11.32333, 284],
  [44.49711, 11.323165, 283.8],
  [44.497113, 11.323004, 283.6],
  [44.497121, 11.322837, 283.6],
  [44.497129, 11.322669, 283.4],
  [44.497137, 11.322504, 283.2],
  [44.497145, 11.322331, 283],
  [44.49715, 11.322161, 282.8],
  [44.497156, 11.321989, 282.6],
  [44.497164, 11.321816, 282.4],
  [44.497166, 11.321644, 282.4],
  [44.497172, 11.32147, 282.4],
  [44.497177, 11.321294, 282.4],
  [44.497182, 11.321121, 282.2],
  [44.497185, 11.320948, 282.4],
  [44.49719, 11.320777, 282.4],
  [44.497182, 11.320605, 282.4],
  [44.497174, 11.320439, 282.6],
  [44.497158, 11.320271, 282.8],
  [44.497137, 11.320106, 283],
  [44.497105, 11.319948, 283.2],
  [44.497072, 11.319792, 283.6],
  [44.497038, 11.319641, 283.8],
  [44.497005, 11.319489, 284],
  [44.496984, 11.319336, 284.4],
  [44.496973, 11.319181, 284.6],
  [44.496976, 11.319028, 284.6],
  [44.496995, 11.318876, 284.8],
  [44.497032, 11.318736, 285],
  [44.497086, 11.318597, 285],
  [44.497145, 11.318466, 285],
  [44.497201, 11.318333, 285],
  [44.497257, 11.318195, 285],
  [44.497303, 11.31805, 284.8],
  [44.497338, 11.317896, 284.4],
  [44.49737, 11.317734, 284],
  [44.497397, 11.317568, 283.4],
  [44.497418, 11.317396, 282.8],
  [44.497443, 11.317217, 282.2],
  [44.497464, 11.317039, 281.4],
  [44.497483, 11.316851, 280.6],
  [44.497507, 11.316664, 279.8],
  [44.497526, 11.316468, 279.2],
  [44.497544, 11.316273, 278.6],
  [44.497563, 11.316076, 278],
  [44.497582, 11.315879, 277.8],
  [44.497593, 11.315678, 277.8],
  [44.497598, 11.315487, 277.8],
  [44.497606, 11.315296, 278],
  [44.497614, 11.315108, 278],
  [44.497628, 11.314924, 278],
  [44.497652, 11.314739, 277.8],
  [44.497673, 11.314559, 277.6],
  [44.4977, 11.314381, 277.4],
  [44.497735, 11.3142, 277],
  [44.497764, 11.314019, 276.6],
  [44.497805, 11.313839, 276.2],
  [44.497842, 11.313656, 275.8],
  [44.497885, 11.313477, 275.4],
  [44.497925, 11.313296, 275],
  [44.497971, 11.313116, 274.6],
  [44.498022, 11.312933, 274.2],
  [44.49807, 11.312754, 273.8],
  [44.498121, 11.312573, 273.4],
  [44.498169, 11.312392, 273],
  [44.49822, 11.31221, 272.6],
  [44.498269, 11.312029, 272.4],
  [44.49832, 11.311846, 272.2],
  [44.498368, 11.311668, 272.2],
  [44.498419, 11.311492, 272.2],
  [44.498464, 11.311315, 272.2],
  [44.49851, 11.311142, 272.2],
  [44.498556, 11.310972, 272.2],
  [44.498604, 11.3108, 272.2],
  [44.49865, 11.310633, 272.2],
  [44.498695, 11.310464, 272.2],
  [44.498743, 11.310299, 272.2],
  [44.498784, 11.310134, 272.2],
  [44.498829, 11.309969, 272],
  [44.498875, 11.309801, 272],
  [44.49892, 11.309639, 272],
  [44.498966, 11.309475, 271.8],
  [44.499009, 11.309309, 271.8],
  [44.499055, 11.309147, 271.6],
  [44.4991, 11.308982, 271.6],
  [44.499143, 11.308814, 271.4],
  [44.499189, 11.308651, 271.2],
  [44.499234, 11.308487, 271.2],
  [44.499277, 11.308323, 271],
  [44.499323, 11.308156, 271],
  [44.499368, 11.307991, 270.8],
  [44.499409, 11.307826, 270.8],
  [44.499457, 11.307665, 270.6],
  [44.499497, 11.307496, 270.6],
  [44.49954, 11.307332, 270.6],
  [44.49958, 11.307169, 270.4],
  [44.499631, 11.307006, 270.6],
  [44.49968, 11.306845, 270.6],
  [44.499747, 11.306701, 270.6],
  [44.499824, 11.306572, 270.6],
  [44.499918, 11.306463, 270.8],
  [44.500028, 11.30638, 270.8],
  [44.500144, 11.306334, 270.6],
  [44.500262, 11.30631, 270.6],
  [44.500382, 11.306298, 270.6],
  [44.500506, 11.306287, 270.6],
  [44.500624, 11.306274, 270.6],
  [44.500744, 11.306249, 270.6],
  [44.500854, 11.306188, 270.6],
  [44.500951, 11.306088, 270.6],
  [44.501039, 11.305975, 270.8],
  [44.501123, 11.305858, 270.8],
  [44.501206, 11.305739, 271],
  [44.501286, 11.305616, 271],
  [44.501356, 11.305483, 271.2],
  [44.501404, 11.305333, 271.4],
  [44.501428, 11.305174, 271.4],
  [44.501423, 11.305013, 271.6],
  [44.501412, 11.30485, 271.6],
  [44.501401, 11.30469, 272],
  [44.501391, 11.304535, 272.2],
  [44.501375, 11.304379, 272.6],
  [44.501356, 11.304228, 273],
  [44.501332, 11.304079, 273.2],
  [44.501302, 11.303935, 273.2],
  [44.501249, 11.303802, 273.2],
  [44.501168, 11.303698, 273],
  [44.501069, 11.303617, 272.6],
  [44.500959, 11.303572, 272.4],
  [44.500841, 11.303557, 272.2],
  [44.500723, 11.303558, 272],
  [44.500605, 11.303573, 271.6],
  [44.500481, 11.303589, 271.2],
  [44.500353, 11.303591, 270.8],
  [44.500235, 11.303546, 270.8],
  [44.500122, 11.30346, 270.8],
  [44.500039, 11.303334, 270.8],
  [44.499964, 11.303194, 270.8],
  [44.499891, 11.303053, 270.8],
  [44.499822, 11.302913, 270.8],
  [44.499749, 11.302768, 270.8],
  [44.499677, 11.302633, 270.8],
  [44.499604, 11.302494, 270.8],
  [44.499532, 11.302357, 271],
  [44.49946, 11.302221, 271],
  [44.49939, 11.302086, 271],
  [44.499317, 11.301948, 271.2],
  [44.499245, 11.301814, 271.2],
  [44.499178, 11.301681, 271.2],
  [44.499106, 11.301548, 271.4],
  [44.499036, 11.301414, 271.4],
  [44.498966, 11.301281, 271.4],
  [44.498894, 11.30115, 271.6],
  [44.498827, 11.301016, 271.6],
  [44.498757, 11.300887, 271.8],
  [44.49869, 11.300755, 271.8],
  [44.49862, 11.300623, 271.8],
  [44.49855, 11.300494, 272],
  [44.498478, 11.300362, 272],
  [44.498411, 11.300234, 272],
  [44.498341, 11.300105, 272],
  [44.498269, 11.299975, 272.2],
  [44.498196, 11.299846, 272.2],
  [44.498129, 11.299719, 272.2],
  [44.498057, 11.29959, 272.2],
  [44.497984, 11.299461, 272.2],
  [44.497912, 11.299333, 272.2],
  [44.497837, 11.299206, 272.2],
  [44.497762, 11.299076, 272.2],
  [44.497689, 11.298952, 272.2],
  [44.497614, 11.298824, 272.2],
  [44.497536, 11.298698, 272],
  [44.497461, 11.298575, 272],
  [44.497375, 11.298451, 271.8],
  [44.497284, 11.298343, 271.8],
  [44.49719, 11.298236, 271.8],
  [44.497102, 11.298128, 271.8],
  [44.497011, 11.298014, 271.8],
  [44.496922, 11.297906, 272],
  [44.496834, 11.297793, 272],
  [44.496748, 11.297684, 272.2],
  [44.496657, 11.297576, 272.2],
  [44.496568, 11.297467, 272.4],
  [44.49648, 11.297361, 272.4],
  [44.496391, 11.297255, 272.6],
  [44.496305, 11.297148, 272.6],
  [44.496219, 11.297041, 272.8],
  [44.496134, 11.296937, 272.8],
  [44.496045, 11.296831, 273],
  [44.495962, 11.296727, 273.2],
  [44.495873, 11.296618, 273.2],
  [44.49579, 11.296516, 273.4],
  [44.495702, 11.29641, 273.4],
  [44.495619, 11.296307, 273.6],
  [44.49553, 11.296202, 273.6],
  [44.495442, 11.296096, 273.8],
  [44.495358, 11.295993, 273.8],
  [44.495275, 11.29589, 274],
  [44.495189, 11.295785, 274],
  [44.495104, 11.295682, 274.2],
  [44.495018, 11.295579, 274.2],
  [44.494932, 11.295478, 274.4],
  [44.494841, 11.295387, 274.4],
  [44.494744, 11.295308, 274.4],
  [44.494634, 11.295335, 274.4],
  [44.494554, 11.295445, 274.4],
  [44.494492, 11.295581, 274.4],
  [44.494436, 11.295721, 274.6],
  [44.494374, 11.295856, 274.6],
  [44.494312, 11.295992, 274.6],
  [44.494253, 11.296129, 274.8],
  [44.494194, 11.296265, 274.8],
  [44.494138, 11.296404, 274.8],
  [44.494082, 11.296543, 275],
  [44.49402, 11.296683, 275],
  [44.493964, 11.296819, 275],
  [44.493902, 11.296955, 275.2],
  [44.493848, 11.2971, 275.2],
  [44.493797, 11.297246, 275.4],
  [44.493757, 11.297388, 275.6],
  [44.493717, 11.297537, 275.8],
  [44.493677, 11.297682, 276],
  [44.493639, 11.29783, 276.2],
  [44.493604, 11.297975, 276.6],
  [44.493569, 11.298119, 276.8],
  [44.493535, 11.298265, 277],
  [44.493505, 11.298407, 277.4],
  [44.493478, 11.298552, 277.8],
  [44.493451, 11.298696, 278],
  [44.493425, 11.298838, 278.4],
  [44.493395, 11.298974, 278.6],
  [44.49336, 11.299113, 278.8],
  [44.493323, 11.299247, 279],
  [44.49328, 11.299381, 279],
  [44.493237, 11.299516, 279.2],
  [44.493197, 11.299652, 279.2],
  [44.493154, 11.299787, 279.2],
  [44.493108, 11.299924, 279.4],
  [44.493065, 11.300059, 279.4],
  [44.49302, 11.3002, 279.6],
  [44.492974, 11.300336, 279.6],
  [44.492934, 11.300476, 279.8],
  [44.492885, 11.300616, 280.2],
  [44.49284, 11.300751, 280.6],
  [44.4928, 11.300886, 281],
  [44.492757, 11.301013, 281.4],
  [44.492714, 11.301146, 281.8],
  [44.492674, 11.301276, 281.8],
  [44.492631, 11.301403, 282],
  [44.492593, 11.301536, 282.2],
  [44.49255, 11.30167, 282.4],
  [44.49251, 11.301802, 282.4],
  [44.492464, 11.301937, 282.6],
  [44.492413, 11.302066, 282.8],
  [44.492357, 11.302186, 282.8],
  [44.492277, 11.302279, 283],
  [44.492172, 11.302319, 283],
  [44.492067, 11.30231, 283],
  [44.49196, 11.302268, 282.8],
  [44.491856, 11.302215, 282.8],
  [44.491754, 11.302158, 282.6],
  [44.491646, 11.302101, 282.4],
  [44.491536, 11.302044, 282.4],
  [44.491429, 11.301989, 282.2],
  [44.491314, 11.301945, 282.2],
  [44.491204, 11.301989, 282.2],
  [44.491147, 11.30213, 282.2],
  [44.491137, 11.302292, 282.6],
  [44.491147, 11.302448, 283.2],
  [44.491166, 11.302601, 283.8],
  [44.491182, 11.302755, 284],
  [44.491193, 11.302903, 284.4],
  [44.491209, 11.30305, 284.6],
  [44.49122, 11.303196, 284.8],
  [44.491231, 11.303346, 285],
  [44.491247, 11.303495, 285],
  [44.491263, 11.303644, 285],
  [44.491273, 11.303792, 285.2],
  [44.491292, 11.303943, 285],
  [44.491308, 11.304093, 285],
  [44.491327, 11.30425, 285],
  [44.491338, 11.304405, 285],
  [44.491349, 11.304564, 285],
  [44.491357, 11.304722, 285],
  [44.491357, 11.304881, 285.2],
  [44.491354, 11.30504, 285.2],
  [44.491349, 11.305194, 285.4],
  [44.491338, 11.305355, 285.6],
  [44.491327, 11.30551, 286],
  [44.491308, 11.305663, 286.2],
  [44.491284, 11.305811, 286.4],
  [44.491257, 11.305958, 286.8],
  [44.491228, 11.306104, 287],
  [44.491193, 11.306243, 287.2],
  [44.491155, 11.306383, 287.4],
  [44.491113, 11.306518, 287.6],
  [44.49107, 11.306658, 288],
  [44.491024, 11.30679, 288.2],
  [44.490984, 11.306922, 288.4],
  [44.490938, 11.307055, 288.6],
  [44.490893, 11.307187, 289],
  [44.49085, 11.307317, 289.2],
  [44.490804, 11.307446, 289.6],
  [44.490758, 11.307574, 290],
  [44.490716, 11.307705, 290.4],
  [44.490678, 11.307831, 290.8],
  [44.490635, 11.307957, 291.2],
  [44.490598, 11.308077, 291.8],
  [44.49056, 11.308195, 292.4],
  [44.490517, 11.308313, 293],
  [44.49048, 11.308425, 293.4],
  [44.490442, 11.308538, 294],
  [44.490407, 11.308647, 294.6],
  [44.490372, 11.308759, 295],
  [44.490337, 11.308868, 295.4],
  [44.490308, 11.308978, 295.8],
  [44.490278, 11.30909, 296.2],
  [44.490252, 11.309203, 296.4],
  [44.490225, 11.309318, 296.6],
  [44.490201, 11.309436, 296.8],
  [44.490182, 11.309562, 296.8],
  [44.490163, 11.309689, 296.8],
  [44.490152, 11.30982, 296.6],
  [44.490144, 11.309959, 296.4],
  [44.490139, 11.310104, 296.4],
  [44.490144, 11.310252, 296.2],
  [44.49016, 11.310398, 296.2],
  [44.490179, 11.310548, 296.4],
  [44.490152, 11.310686, 296.4],
  [44.490056, 11.310647, 297.2],
  [44.490018, 11.310519, 298.6],
  [44.489989, 11.310393, 299.6],
  [44.489956, 11.310279, 300.6],
  [44.489922, 11.31017, 301.6],
  [44.489892, 11.310071, 302.4],
  [44.489857, 11.309978, 303.2],
  [44.489828, 11.30989, 304],
  [44.489793, 11.309807, 304.6],
  [44.489758, 11.309725, 305.4],
  [44.489729, 11.309646, 306],
  [44.489691, 11.309556, 306.6],
  [44.489645, 11.309459, 307.4],
  [44.489597, 11.309357, 308.2],
  [44.489546, 11.309249, 309.2],
  [44.489479, 11.309149, 310.2],
  [44.489396, 11.30908, 311.6],
  [44.489307, 11.309021, 313],
  [44.489222, 11.308963, 314.4],
  [44.489133, 11.308905, 315.6],
  [44.489045, 11.308849, 317],
  [44.488959, 11.30879, 318.4],
  [44.48887, 11.308734, 319.8],
  [44.488787, 11.308677, 321.2],
  [44.488699, 11.308621, 322.4],
  [44.488615, 11.308554, 323.6],
  [44.488548, 11.308452, 325],
  [44.488508, 11.308327, 326.4],
  [44.488473, 11.308201, 327.6],
  [44.488438, 11.308077, 329],
  [44.488401, 11.307949, 330.4],
  [44.488366, 11.307827, 331.8],
  [44.488328, 11.307702, 333.4],
  [44.488291, 11.307579, 334.6],
  [44.488256, 11.307458, 336],
  [44.488218, 11.307338, 337.4],
  [44.488184, 11.307222, 338.6],
  [44.488151, 11.307115, 339.8],
  [44.488122, 11.307008, 341],
  [44.488092, 11.306907, 342],
  [44.488066, 11.306809, 343],
  [44.488039, 11.306714, 344],
  [44.488012, 11.30662, 345],
  [44.487985, 11.306525, 346],
  [44.487958, 11.306434, 347],
  [44.487931, 11.306344, 347.8],
  [44.487907, 11.306253, 348.8],
  [44.48788, 11.306164, 349.6],
  [44.487856, 11.306074, 350.6],
  [44.487832, 11.305986, 351.4],
  [44.487805, 11.305899, 352.4],
  [44.487779, 11.305809, 353.2],
  [44.487757, 11.30572, 354],
  [44.48773, 11.30563, 355],
  [44.487703, 11.305543, 355.8],
  [44.487679, 11.305456, 356.8],
  [44.487652, 11.305366, 357.6],
  [44.487631, 11.30528, 358.4],
  [44.487604, 11.305192, 359.4],
  [44.487577, 11.305101, 360.2],
  [44.487553, 11.305013, 361],
  [44.487526, 11.304922, 361.6],
  [44.4875, 11.304832, 362.4],
  [44.487462, 11.304744, 363.2],
  [44.487416, 11.304662, 363.8],
  [44.487365, 11.304583, 364.4],
  [44.487315, 11.304505, 365.2],
  [44.487261, 11.304426, 365.8],
  [44.487202, 11.304344, 366.6],
  [44.487148, 11.304265, 367.2],
  [44.487092, 11.304182, 368],
  [44.487036, 11.3041, 368.8],
  [44.486982, 11.304017, 369.4],
  [44.486926, 11.303934, 370.2],
  [44.486867, 11.303849, 371],
  [44.48681, 11.303762, 371.6],
  [44.486754, 11.303676, 372.4],
  [44.486695, 11.303592, 373],
  [44.486633, 11.303505, 373.6],
  [44.486577, 11.303416, 374.4],
  [44.486515, 11.30333, 375],
  [44.486459, 11.303241, 376],
  [44.486397, 11.303155, 376.8],
  [44.486341, 11.303069, 377.8],
  [44.486285, 11.302983, 378.6],
  [44.486226, 11.3029, 379.4],
  [44.486169, 11.302818, 380.2],
  [44.486116, 11.302736, 381],
  [44.486059, 11.302654, 381.8],
  [44.486, 11.302573, 382.8],
  [44.485947, 11.302491, 383.6],
  [44.48589, 11.302409, 384.4],
  [44.485834, 11.30233, 385],
  [44.48578, 11.302249, 385.8],
  [44.485724, 11.302168, 386.6],
  [44.485662, 11.302086, 387.2],
  [44.485601, 11.302013, 387.6],
  [44.485512, 11.302004, 387.8],
  [44.485437, 11.302075, 388.4],
  [44.485375, 11.302169, 389.4],
  [44.485322, 11.302267, 390.4],
  [44.485265, 11.302359, 391.6],
  [44.485193, 11.302396, 392.6],
  [44.48515, 11.30231, 393.8],
  [44.485166, 11.302202, 395],
  [44.485158, 11.302105, 396],
  [44.485102, 11.302048, 397],
  [44.485032, 11.302046, 398.2],
  [44.484968, 11.30206, 399.4],
  [44.484906, 11.302079, 400.6],
  [44.484844, 11.302097, 401.6],
  [44.484788, 11.302115, 402.6],
  [44.484732, 11.302131, 403.8],
  [44.484673, 11.302149, 404.8],
  [44.484619, 11.302166, 405.8],
  [44.484568, 11.302182, 406.8],
  [44.484514, 11.3022, 407.8],
  [44.484463, 11.302215, 408.8],
  [44.48441, 11.302231, 409.6],
  [44.484361, 11.302247, 410.6],
  [44.484308, 11.302263, 411.6],
  [44.484257, 11.302279, 412.6],
  [44.484209, 11.302294, 413.6],
  [44.484155, 11.30231, 414.6],
  [44.484104, 11.302325, 415.4],
  [44.484053, 11.302339, 416.4],
  [44.484002, 11.302355, 417.4],
  [44.483954, 11.30237, 418.4],
  [44.4839, 11.302386, 419.2],
  [44.483849, 11.302401, 420.2],
  [44.483795, 11.302416, 421.2],
  [44.483747, 11.302432, 422.2],
  [44.483696, 11.302445, 423.2],
  [44.483648, 11.302461, 424],
  [44.483594, 11.302476, 425],
  [44.483543, 11.302492, 425.8],
  [44.483492, 11.302508, 426.6],
  [44.483433, 11.302524, 427.4],
  [44.48338, 11.302544, 428.2],
  [44.483323, 11.302566, 429],
  [44.483262, 11.302589, 429.8],
  [44.483205, 11.302613, 430.6],
  [44.483144, 11.302637, 431.6],
  [44.483082, 11.302661, 432.6],
  [44.483026, 11.302684, 433.4],
  [44.482964, 11.302707, 434.4],
  [44.482902, 11.30273, 435.2],
  [44.482843, 11.302752, 436.2],
  [44.482784, 11.302774, 437.2],
  [44.482725, 11.302794, 438],
  [44.482666, 11.302811, 439],
  [44.482607, 11.302827, 439.8],
  [44.482546, 11.30283, 440.8],
  [44.482484, 11.30281, 441.8],
  [44.482436, 11.302767, 442.6],
  [44.482385, 11.302716, 443.6],
  [44.482339, 11.302667, 444.6],
  [44.482291, 11.302613, 445.6],
  [44.482242, 11.302562, 446.4],
  [44.482197, 11.302511, 447.4],
  [44.482151, 11.302459, 448.2],
  [44.482106, 11.302408, 449.2],
  [44.48206, 11.302355, 450],
  [44.482014, 11.302303, 451],
  [44.481966, 11.302251, 451.8],
  [44.481921, 11.302197, 452.8],
  [44.48187, 11.302145, 453.6],
  [44.481821, 11.302091, 454.2],
  [44.481768, 11.302035, 455],
  [44.481717, 11.301979, 455.6],
  [44.481668, 11.301906, 456.2],
  [44.481642, 11.301808, 456.4],
  [44.481628, 11.301696, 456.4],
  [44.481607, 11.30158, 456.6],
  [44.48155, 11.301485, 457.2],
  [44.481481, 11.301403, 457.8],
  [44.481406, 11.301323, 458.6],
  [44.481336, 11.301245, 459.2],
  [44.481263, 11.301166, 460],
  [44.481191, 11.301087, 460.6],
  [44.481119, 11.301009, 461.4],
  [44.481041, 11.30093, 462],
  [44.480968, 11.300851, 462.6],
  [44.480896, 11.300772, 463.2],
  [44.480824, 11.300687, 463.8],
  [44.480751, 11.300603, 464.4],
  [44.480679, 11.300515, 465],
  [44.480606, 11.30043, 465.4],
  [44.480531, 11.300344, 466],
  [44.480453, 11.300258, 466.6],
  [44.480378, 11.300171, 467.2],
  [44.480303, 11.300078, 467.6],
  [44.480231, 11.299986, 468.2],
  [44.480156, 11.299886, 469],
  [44.480091, 11.299787, 469.6],
  [44.480032, 11.299679, 470.6],
  [44.479981, 11.299563, 471.6],
  [44.479938, 11.299445, 472.6],
  [44.479906, 11.299329, 473.6],
  [44.479874, 11.299213, 474.8],
  [44.479845, 11.299102, 475.8],
  [44.479812, 11.298996, 476.6],
  [44.479783, 11.298891, 477.6],
  [44.479753, 11.298787, 478.6],
  [44.479727, 11.298682, 479.4],
  [44.479702, 11.298579, 480.2],
  [44.479681, 11.298473, 481.2],
  [44.479668, 11.298368, 482],
  [44.479665, 11.298258, 482.6],
  [44.479673, 11.29815, 483.2],
  [44.479684, 11.298038, 483.8],
  [44.4797, 11.297923, 484.4],
  [44.47971, 11.297806, 485],
  [44.47971, 11.297686, 485.6],
  [44.479694, 11.297562, 486],
  [44.479665, 11.297443, 486.6],
  [44.479627, 11.297329, 487],
  [44.479576, 11.297215, 487.6],
  [44.479528, 11.297102, 488],
  [44.479477, 11.296988, 488.4],
  [44.479437, 11.296868, 489],
  [44.479402, 11.296733, 489.6],
  [44.479378, 11.296599, 490],
  [44.479367, 11.29646, 490.6],
  [44.479359, 11.29632, 491.2],
  [44.479351, 11.296177, 491.6],
  [44.479346, 11.296037, 492.2],
  [44.47933, 11.295895, 492.8],
  [44.479297, 11.295756, 493.2],
  [44.47926, 11.295624, 493.8],
  [44.479204, 11.2955, 494],
  [44.479134, 11.295383, 494],
  [44.479061, 11.295272, 494],
  [44.479002, 11.295179, 494.2],
  [44.478951, 11.295107, 494.2],
  [44.478917, 11.29505, 494],
  [44.478898, 11.295017, 494],
  [44.478887, 11.295001, 494],
  [44.478887, 11.294999, 494],
  [44.478887, 11.294999, 494],
  [44.478887, 11.294999, 494],
  [44.478887, 11.294999, 494],
  [44.478887, 11.294999, 494],
  [44.478882, 11.294994, 494],
  [44.478876, 11.294978, 494],
  [44.478863, 11.294955, 494],
  [44.478852, 11.294942, 494],
  [44.478849, 11.294935, 493.8],
  [44.478844, 11.294927, 493.8],
  [44.478841, 11.294922, 493.8],
  [44.478831, 11.294906, 493.8],
  [44.478604, 11.294678, 493.8],
  [44.478452, 11.294595, 493.8],
];

export default stream;
