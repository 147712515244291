import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.789177, 165.846316, 79.8],
  [-10.789211, 165.846444, 80],
  [-10.789287, 165.84653, 80],
  [-10.789391, 165.846605, 80],
  [-10.789509, 165.846637, 80],
];

export default stream;
