import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.646475, 166.974211, 42.4],
  [-11.646467, 166.974169, 42.4],
  [-11.646458, 166.974134, 42.6],
  [-11.646448, 166.974103, 42.6],
  [-11.646435, 166.974071, 42.6],
  [-11.646419, 166.97404, 42.6],
  [-11.646405, 166.974006, 42.8],
  [-11.646386, 166.973973, 42.8],
  [-11.646362, 166.973944, 42.8],
  [-11.646348, 166.973929, 42.8],
  [-11.646348, 166.973929, 42.8],
  [-11.646348, 166.973929, 42.8],
  [-11.646348, 166.973929, 42.8],
  [-11.646348, 166.973929, 42.8],
  [-11.646348, 166.973929, 42.8],
  [-11.646348, 166.973929, 42.8],
  [-11.646348, 166.973929, 42.8],
  [-11.646343, 166.973924, 42.8],
  [-11.646326, 166.973907, 43],
  [-11.646304, 166.973886, 43],
  [-11.646278, 166.973858, 43],
  [-11.646251, 166.973826, 43],
  [-11.64622, 166.973791, 43],
  [-11.646189, 166.97375, 43],
  [-11.646162, 166.973701, 42.8],
  [-11.646139, 166.973644, 42.4],
  [-11.646119, 166.973578, 42],
  [-11.646099, 166.973504, 41.4],
  [-11.646073, 166.973425, 40.6],
  [-11.646037, 166.973341, 39.6],
  [-11.645958, 166.973289, 39.2],
  [-11.645861, 166.973281, 39.4],
  [-11.645774, 166.973247, 39.6],
  [-11.645686, 166.973249, 40],
  [-11.645607, 166.973221, 40.4],
  [-11.645549, 166.973161, 40.8],
  [-11.645497, 166.973101, 41.2],
  [-11.64545, 166.973041, 41.2],
  [-11.645408, 166.972967, 39],
  [-11.645398, 166.972871, 36],
  [-11.645482, 166.972795, 34.4],
  [-11.645594, 166.972756, 34.2],
  [-11.645697, 166.972771, 34.4],
  [-11.645741, 166.972781, 34.4],
  [-11.645782, 166.972784, 34.4],
  [-11.645828, 166.972791, 34.4],
  [-11.645876, 166.972804, 34.4],
  [-11.645927, 166.972819, 34.4],
  [-11.64598, 166.972833, 34.4],
  [-11.646037, 166.972837, 34.4],
  [-11.646095, 166.972812, 33.6],
  [-11.646127, 166.972747, 32.4],
  [-11.646082, 166.972676, 31.8],
  [-11.646018, 166.972614, 31.4],
  [-11.645976, 166.972533, 30.8],
  [-11.646004, 166.972442, 29],
  [-11.6461, 166.972397, 27.6],
  [-11.646216, 166.972388, 26],
  [-11.646284, 166.97229, 24.6],
  [-11.646275, 166.972165, 25],
  [-11.646206, 166.972064, 25.4],
  [-11.6461, 166.972042, 25.4],
  [-11.645991, 166.972071, 25],
  [-11.64588, 166.972055, 25.2],
  [-11.645778, 166.972019, 25.6],
  [-11.645673, 166.972021, 25.4],
  [-11.645584, 166.972036, 25.2],
  [-11.645511, 166.972048, 25.2],
  [-11.645439, 166.972039, 25],
  [-11.645394, 166.971976, 24],
  [-11.64537, 166.971891, 23.2],
  [-11.645382, 166.971798, 21.8],
  [-11.645398, 166.971701, 19.4],
  [-11.645375, 166.971634, 18],
  [-11.645362, 166.971558, 16.8],
  [-11.645324, 166.971499, 16.4],
  [-11.645256, 166.971475, 16],
  [-11.645189, 166.971445, 16.2],
  [-11.645129, 166.971404, 16.2],
  [-11.645089, 166.97134, 15.6],
  [-11.645079, 166.971259, 15.2],
  [-11.645086, 166.971173, 14.8],
  [-11.645105, 166.971086, 14.4],
  [-11.645138, 166.970999, 14],
  [-11.645188, 166.970918, 13.8],
  [-11.645253, 166.970846, 13.4],
  [-11.645326, 166.97078, 13.2],
  [-11.645401, 166.970717, 13],
  [-11.645477, 166.970652, 12.8],
  [-11.645547, 166.97058, 12.6],
  [-11.645606, 166.970498, 12.4],
  [-11.645642, 166.97042, 12.2],
  [-11.64566, 166.970348, 12.2],
  [-11.645671, 166.970273, 12],
  [-11.645679, 166.970196, 11.8],
  [-11.645689, 166.970116, 11.6],
  [-11.645719, 166.970039, 11.4],
  [-11.645786, 166.969989, 11],
  [-11.645873, 166.969987, 10.6],
  [-11.645961, 166.97001, 10.4],
  [-11.646048, 166.97004, 10.4],
  [-11.646139, 166.97006, 10.2],
  [-11.646227, 166.970088, 10.6],
  [-11.646308, 166.970121, 11.2],
  [-11.646386, 166.970152, 11.8],
  [-11.646459, 166.970181, 12.4],
  [-11.646527, 166.970207, 13],
  [-11.646591, 166.970232, 13.4],
  [-11.646651, 166.970253, 14],
  [-11.646708, 166.970275, 14.2],
  [-11.646764, 166.970298, 14.6],
  [-11.646819, 166.970314, 15],
  [-11.646874, 166.970314, 15.4],
  [-11.646926, 166.970295, 15.4],
  [-11.646975, 166.970262, 15.2],
  [-11.647019, 166.970216, 14.8],
  [-11.647056, 166.970157, 14.6],
  [-11.64706, 166.970083, 14.2],
  [-11.647037, 166.970008, 13.8],
  [-11.647018, 166.969927, 13.6],
  [-11.647033, 166.969843, 13],
  [-11.647074, 166.969762, 12.4],
  [-11.647121, 166.96968, 12],
  [-11.647152, 166.969589, 12.2],
  [-11.647177, 166.969498, 12.6],
  [-11.647223, 166.969422, 12.8],
  [-11.6473, 166.969387, 13.2],
  [-11.647382, 166.969389, 13.4],
  [-11.647462, 166.969405, 13.4],
  [-11.64754, 166.969428, 13.6],
  [-11.647616, 166.969452, 13.6],
  [-11.647693, 166.969478, 13.8],
  [-11.64777, 166.969503, 13.8],
  [-11.647848, 166.969523, 13.8],
  [-11.647927, 166.96954, 13.8],
  [-11.648007, 166.969548, 14],
  [-11.648088, 166.969545, 14],
  [-11.648169, 166.969534, 13.8],
  [-11.648252, 166.96953, 13.8],
  [-11.648335, 166.969538, 13.4],
  [-11.648414, 166.96951, 14.2],
  [-11.648482, 166.969475, 15.2],
  [-11.648545, 166.969446, 15.6],
  [-11.648608, 166.969423, 15.8],
  [-11.648674, 166.969419, 16],
  [-11.648738, 166.969431, 16.4],
  [-11.648797, 166.969452, 16.8],
  [-11.648853, 166.969472, 17.2],
  [-11.648907, 166.96949, 17.6],
  [-11.648959, 166.969503, 18],
  [-11.64901, 166.969506, 18.4],
  [-11.649055, 166.969495, 18.8],
  [-11.649094, 166.969476, 19.2],
  [-11.649127, 166.969452, 19.6],
  [-11.649155, 166.969426, 20],
  [-11.649181, 166.9694, 20.4],
  [-11.649206, 166.969372, 20.8],
  [-11.649229, 166.969344, 21],
  [-11.649253, 166.969316, 21.4],
  [-11.649276, 166.969289, 21.6],
  [-11.6493, 166.969262, 22],
  [-11.649325, 166.969235, 22.2],
  [-11.649351, 166.969207, 22.4],
  [-11.649384, 166.969183, 22.4],
  [-11.649429, 166.969172, 22.4],
  [-11.649476, 166.969174, 22.6],
  [-11.649522, 166.969189, 22.8],
  [-11.649557, 166.969219, 23.4],
  [-11.649578, 166.969254, 24],
  [-11.649583, 166.969288, 24.6],
  [-11.64958, 166.969319, 24.8],
  [-11.649571, 166.969349, 25.2],
  [-11.649558, 166.969379, 25.4],
  [-11.649545, 166.969411, 25.6],
  [-11.649537, 166.969445, 25.8],
  [-11.649534, 166.969478, 26.2],
  [-11.649535, 166.969511, 26.6],
  [-11.64954, 166.969542, 27],
  [-11.649551, 166.96957, 27.2],
  [-11.649569, 166.969595, 27.6],
  [-11.649595, 166.969611, 27.8],
  [-11.649627, 166.969612, 28],
  [-11.649659, 166.969599, 28.2],
  [-11.64968, 166.969576, 28.8],
  [-11.649692, 166.96955, 29],
  [-11.649699, 166.969526, 29.4],
  [-11.649703, 166.969501, 29.8],
  [-11.649707, 166.969476, 30.2],
  [-11.649709, 166.969451, 30.4],
  [-11.649713, 166.969425, 30.8],
  [-11.649719, 166.969398, 31.2],
  [-11.649729, 166.969371, 31.4],
  [-11.64974, 166.969344, 31.6],
  [-11.649763, 166.969323, 31.8],
  [-11.649797, 166.969323, 32.2],
  [-11.649822, 166.969342, 32.4],
  [-11.649841, 166.969369, 32.6],
  [-11.649852, 166.969403, 32.6],
  [-11.649859, 166.969442, 32.8],
  [-11.649865, 166.969484, 32.8],
  [-11.649869, 166.969529, 32.8],
  [-11.649872, 166.969579, 32.8],
  [-11.649872, 166.969632, 32.8],
  [-11.649865, 166.969689, 32.6],
  [-11.649836, 166.969745, 32],
  [-11.649773, 166.969779, 31],
  [-11.649692, 166.969785, 30.2],
  [-11.649608, 166.969766, 29.4],
  [-11.64957, 166.969754, 29],
  [-11.649539, 166.969743, 28.8],
  [-11.649498, 166.96973, 28.2],
  [-11.649445, 166.969739, 27.6],
  [-11.649395, 166.969789, 25.2],
  [-11.649313, 166.969822, 22.6],
  [-11.649227, 166.969861, 21.6],
  [-11.649206, 166.969925, 20.4],
  [-11.649236, 166.969978, 19.8],
  [-11.64928, 166.970027, 19.8],
  [-11.649303, 166.970089, 20],
  [-11.649287, 166.970152, 20.2],
  [-11.649241, 166.970201, 20.2],
  [-11.649182, 166.97023, 20.4],
  [-11.649116, 166.970241, 20.4],
  [-11.649049, 166.970238, 20.4],
  [-11.648985, 166.970216, 20.6],
  [-11.648939, 166.970168, 21],
  [-11.648907, 166.970113, 21.2],
  [-11.648848, 166.970097, 21.2],
  [-11.648788, 166.970123, 21.4],
  [-11.648723, 166.97012, 21.2],
  [-11.648657, 166.970093, 20.6],
  [-11.64858, 166.970082, 19.6],
  [-11.648499, 166.970122, 17.6],
  [-11.648404, 166.970158, 16.4],
  [-11.648305, 166.970198, 16.2],
  [-11.648222, 166.970264, 16.2],
  [-11.648206, 166.97035, 16.6],
  [-11.64825, 166.970398, 16.8],
  [-11.6483, 166.970437, 17],
  [-11.648357, 166.970467, 16.8],
  [-11.648411, 166.970504, 17],
  [-11.648438, 166.970566, 16.8],
  [-11.648475, 166.970627, 16.6],
  [-11.648514, 166.970691, 16.4],
  [-11.648563, 166.97075, 16.4],
  [-11.648633, 166.97078, 16.4],
  [-11.648711, 166.970785, 16.4],
  [-11.648789, 166.970805, 15.6],
  [-11.648875, 166.9708, 15.2],
  [-11.648958, 166.970837, 14.6],
  [-11.649046, 166.970864, 14.2],
  [-11.649132, 166.970901, 13.4],
  [-11.649162, 166.970996, 13.8],
  [-11.649143, 166.971088, 14.8],
  [-11.649113, 166.971171, 15.4],
  [-11.649086, 166.971247, 16.2],
  [-11.649062, 166.97132, 16.4],
  [-11.649045, 166.971392, 16.6],
  [-11.649058, 166.971463, 16.8],
  [-11.649106, 166.971515, 17],
  [-11.649168, 166.971551, 17],
  [-11.649237, 166.971574, 17],
  [-11.649309, 166.971593, 17],
  [-11.649383, 166.971611, 17],
  [-11.649458, 166.971629, 16.8],
  [-11.649531, 166.971658, 16.8],
  [-11.649595, 166.971706, 16.8],
  [-11.64966, 166.971756, 16.8],
  [-11.649726, 166.971803, 16.8],
  [-11.649792, 166.971849, 17.2],
  [-11.649857, 166.971891, 17.6],
  [-11.649929, 166.971902, 17.8],
  [-11.649998, 166.971873, 17.4],
  [-11.650055, 166.971822, 17.4],
  [-11.650096, 166.971774, 17.6],
  [-11.650118, 166.971748, 17.6],
  [-11.65015, 166.97173, 17.8],
  [-11.65018, 166.971704, 17.8],
  [-11.650208, 166.97167, 18],
  [-11.650236, 166.971634, 18],
  [-11.650266, 166.971597, 18.2],
  [-11.650297, 166.971563, 18.6],
  [-11.650323, 166.971527, 19],
  [-11.650352, 166.971496, 19.4],
  [-11.650379, 166.971466, 19.4],
  [-11.650407, 166.971436, 20],
  [-11.650434, 166.971413, 20.4],
  [-11.65046, 166.971397, 20.8],
  [-11.650487, 166.971386, 21.2],
  [-11.650514, 166.971377, 21.4],
  [-11.650541, 166.971371, 21.6],
  [-11.650569, 166.971365, 22],
  [-11.650598, 166.97136, 22.2],
  [-11.65063, 166.971355, 22.2],
  [-11.650666, 166.971351, 22.4],
  [-11.650702, 166.971343, 22.6],
  [-11.650735, 166.971324, 23],
  [-11.650765, 166.971304, 23.4],
  [-11.650792, 166.971284, 23.8],
  [-11.650813, 166.971262, 24.2],
  [-11.650831, 166.971237, 24.4],
  [-11.650853, 166.97121, 24.6],
  [-11.650875, 166.971181, 24.6],
  [-11.650895, 166.971149, 25],
  [-11.650914, 166.971118, 25.6],
  [-11.650931, 166.971091, 25.8],
  [-11.65095, 166.97106, 25.6],
  [-11.650976, 166.971026, 25.6],
  [-11.651018, 166.971002, 25.4],
  [-11.651068, 166.970985, 25.4],
  [-11.651125, 166.970978, 25.2],
  [-11.651179, 166.971003, 25],
  [-11.651203, 166.971062, 25.2],
  [-11.651184, 166.971119, 25.6],
  [-11.651154, 166.97117, 25.8],
  [-11.651133, 166.971223, 26.2],
  [-11.651123, 166.971275, 26.8],
  [-11.651119, 166.971326, 27],
  [-11.65112, 166.971375, 27.4],
  [-11.65113, 166.971421, 27.6],
  [-11.651154, 166.97146, 28],
  [-11.651191, 166.971488, 28],
  [-11.651235, 166.97151, 28],
  [-11.651284, 166.971524, 28],
  [-11.651339, 166.971529, 28.2],
  [-11.65139, 166.971513, 28.8],
  [-11.65143, 166.971486, 29.2],
  [-11.651468, 166.971464, 29.6],
  [-11.651503, 166.97144, 29.8],
  [-11.65154, 166.971413, 29.8],
  [-11.651583, 166.971389, 29.8],
  [-11.651632, 166.971371, 30],
  [-11.651682, 166.971362, 30.4],
  [-11.65173, 166.97136, 30.8],
  [-11.651773, 166.971367, 31.6],
  [-11.651809, 166.971379, 32.2],
  [-11.651838, 166.971392, 32.8],
  [-11.651863, 166.971405, 33.2],
  [-11.651886, 166.971419, 33.8],
  [-11.651908, 166.971433, 34.2],
  [-11.65193, 166.971448, 34.6],
  [-11.651951, 166.971463, 35],
  [-11.651969, 166.97148, 35.4],
  [-11.651986, 166.971499, 35.8],
  [-11.652, 166.97152, 36.2],
  [-11.652012, 166.971545, 36.4],
  [-11.652019, 166.971574, 36.8],
  [-11.652018, 166.971605, 37],
  [-11.652009, 166.971632, 37.6],
  [-11.651998, 166.971654, 38],
  [-11.651985, 166.971672, 38.4],
  [-11.65197, 166.971689, 38.8],
  [-11.651954, 166.971703, 39.2],
  [-11.651936, 166.971714, 39.6],
  [-11.651916, 166.971721, 40],
  [-11.651895, 166.971724, 40.4],
  [-11.651872, 166.971723, 40.6],
  [-11.651848, 166.971718, 41],
  [-11.651823, 166.97171, 41.2],
  [-11.651797, 166.971699, 41.6],
  [-11.651771, 166.971688, 41.8],
  [-11.651744, 166.971676, 42.2],
  [-11.651717, 166.971663, 42.4],
  [-11.651688, 166.971655, 42.8],
  [-11.651658, 166.97165, 43.2],
  [-11.65163, 166.971648, 43.6],
  [-11.651605, 166.97165, 44],
  [-11.651582, 166.971656, 44.4],
  [-11.651559, 166.971667, 44.6],
  [-11.651536, 166.971681, 44.8],
  [-11.651514, 166.971699, 45.2],
  [-11.651493, 166.971721, 45.4],
  [-11.651474, 166.971748, 45.6],
  [-11.651459, 166.971778, 45.8],
  [-11.651454, 166.971812, 46.2],
  [-11.651464, 166.971846, 46.4],
  [-11.651485, 166.971876, 46.6],
  [-11.651512, 166.971903, 46.6],
  [-11.651544, 166.971929, 46.8],
  [-11.65158, 166.971953, 46.8],
  [-11.651619, 166.971977, 47],
  [-11.651662, 166.971994, 47.2],
  [-11.651708, 166.971993, 47.6],
  [-11.65175, 166.971984, 48],
  [-11.65179, 166.971971, 48.2],
  [-11.651826, 166.971955, 48.6],
  [-11.651861, 166.971939, 49],
  [-11.651895, 166.971923, 49.2],
  [-11.651929, 166.97191, 49.6],
  [-11.651964, 166.971904, 50],
  [-11.651998, 166.971903, 50.2],
  [-11.652034, 166.971902, 50.4],
  [-11.652072, 166.971904, 50.6],
  [-11.652109, 166.971919, 50.8],
  [-11.652138, 166.971942, 51.6],
  [-11.652158, 166.971966, 52.2],
  [-11.652173, 166.971989, 52.6],
  [-11.652186, 166.972012, 53],
  [-11.652197, 166.972034, 53.4],
  [-11.652207, 166.972057, 53.8],
  [-11.652217, 166.972082, 54],
  [-11.652226, 166.97211, 54],
  [-11.652234, 166.972143, 54.2],
  [-11.65224, 166.972179, 54.4],
  [-11.652238, 166.972218, 54.6],
  [-11.652226, 166.972256, 54.8],
  [-11.652217, 166.972293, 55.2],
  [-11.652209, 166.972328, 55.6],
  [-11.65221, 166.972361, 56],
  [-11.652223, 166.972391, 56.2],
  [-11.652233, 166.972419, 56.8],
  [-11.652245, 166.972441, 57.2],
  [-11.65226, 166.972458, 57.4],
  [-11.652279, 166.972473, 57.8],
  [-11.6523, 166.972486, 58],
  [-11.652322, 166.972499, 58.2],
  [-11.652345, 166.972513, 58.8],
  [-11.652363, 166.972528, 59.2],
  [-11.652379, 166.972547, 59.2],
  [-11.652391, 166.972575, 59.2],
  [-11.652395, 166.972614, 59.2],
  [-11.65239, 166.972654, 59.6],
  [-11.652378, 166.97269, 60],
  [-11.652358, 166.972719, 60.4],
  [-11.652333, 166.972739, 60.8],
  [-11.652305, 166.972749, 61.2],
  [-11.652277, 166.972755, 61.6],
  [-11.652252, 166.972758, 62],
  [-11.652229, 166.972759, 62.4],
  [-11.652206, 166.972759, 62.8],
  [-11.652182, 166.972756, 63.2],
  [-11.652157, 166.972749, 63.8],
  [-11.652132, 166.972739, 64.2],
  [-11.652109, 166.972726, 64.6],
  [-11.652087, 166.972712, 65],
  [-11.652065, 166.972695, 65.6],
  [-11.652044, 166.972677, 66],
  [-11.652025, 166.972659, 66.4],
  [-11.652008, 166.972639, 66.8],
  [-11.651995, 166.972617, 67],
  [-11.651983, 166.972595, 67.4],
  [-11.651972, 166.972573, 67.6],
  [-11.651959, 166.972552, 68],
  [-11.651944, 166.972534, 68.4],
  [-11.651927, 166.972519, 68.6],
  [-11.651908, 166.972506, 69],
  [-11.651885, 166.972495, 69.2],
  [-11.651858, 166.972488, 69.4],
  [-11.651829, 166.972489, 69.8],
  [-11.651804, 166.972498, 70.4],
  [-11.651783, 166.972509, 70.8],
  [-11.651764, 166.972522, 71],
  [-11.65175, 166.972539, 71.4],
  [-11.651741, 166.972559, 71.8],
  [-11.651737, 166.972581, 72],
  [-11.651738, 166.972607, 72.2],
  [-11.651743, 166.972634, 72.4],
  [-11.651751, 166.972663, 72.6],
  [-11.651763, 166.972693, 72.8],
  [-11.651778, 166.972722, 73],
  [-11.651796, 166.972749, 73.4],
  [-11.651816, 166.972772, 73.6],
  [-11.651838, 166.972792, 74],
  [-11.65186, 166.972807, 74.4],
  [-11.651881, 166.972819, 74.8],
  [-11.651901, 166.972828, 75.4],
  [-11.651919, 166.972837, 75.6],
  [-11.651937, 166.972847, 76],
  [-11.651956, 166.97286, 76.2],
  [-11.651975, 166.972876, 76.4],
  [-11.651995, 166.972895, 76.6],
  [-11.652022, 166.97291, 76.8],
  [-11.652054, 166.972922, 76.8],
  [-11.652091, 166.972932, 76.8],
  [-11.652134, 166.972945, 76.8],
  [-11.652177, 166.972959, 77],
  [-11.652215, 166.972981, 77.4],
  [-11.652243, 166.973012, 77.6],
  [-11.652254, 166.973052, 77.8],
  [-11.652252, 166.973091, 78.2],
  [-11.652229, 166.973118, 78.6],
  [-11.652203, 166.973138, 79],
  [-11.652181, 166.973156, 79.6],
  [-11.652157, 166.973169, 80],
  [-11.652129, 166.973176, 80.2],
  [-11.652096, 166.973177, 80.6],
  [-11.652059, 166.973177, 80.8],
  [-11.65202, 166.973175, 81],
  [-11.651978, 166.973168, 81.2],
  [-11.651935, 166.973158, 81.2],
  [-11.651889, 166.973147, 81.2],
  [-11.651837, 166.97314, 81],
  [-11.651783, 166.973129, 81],
  [-11.651724, 166.973115, 80.6],
  [-11.651659, 166.973101, 80.4],
  [-11.651587, 166.973094, 79.6],
  [-11.651508, 166.973114, 77.8],
  [-11.651441, 166.973184, 75.2],
  [-11.65137, 166.973269, 73.4],
  [-11.651276, 166.9733, 70.4],
  [-11.651217, 166.973256, 68.4],
  [-11.651179, 166.973179, 67.2],
  [-11.651148, 166.973152, 66.8],
  [-11.651124, 166.973139, 66.6],
  [-11.651087, 166.973123, 66.4],
  [-11.651039, 166.973114, 66.4],
  [-11.650989, 166.973126, 66.4],
  [-11.650947, 166.973161, 66.8],
  [-11.650928, 166.97321, 67],
  [-11.650945, 166.973298, 66.8],
  [-11.650946, 166.973323, 66.8],
  [-11.650951, 166.973351, 66.8],
  [-11.650959, 166.973381, 66.6],
  [-11.650969, 166.973415, 66.6],
  [-11.65098, 166.973452, 66.6],
  [-11.650991, 166.973491, 66.4],
  [-11.650999, 166.973534, 66.2],
  [-11.650999, 166.973582, 66.2],
  [-11.65098, 166.973628, 66],
  [-11.650945, 166.973667, 66],
  [-11.650901, 166.973697, 66],
  [-11.650851, 166.97372, 65.8],
  [-11.650796, 166.973736, 65.8],
  [-11.650739, 166.973741, 65.6],
  [-11.65068, 166.973727, 65.4],
];
export default stream;
