import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.761004, 165.846498, 135.8],
  [-10.761151, 165.846595, 135.8],
  [-10.761288, 165.84668, 135.6],
  [-10.761426, 165.846788, 135.2],
  [-10.761564, 165.846884, 134.6],
  [-10.761719, 165.846959, 134],
  [-10.76189, 165.846992, 133.4],
  [-10.762064, 165.846992, 132.8],
  [-10.762242, 165.84697, 132.2],
  [-10.76242, 165.84697, 131.4],
  [-10.762597, 165.846992, 130.8],
  [-10.762777, 165.847002, 130.2],
  [-10.762952, 165.847045, 129.4],
  [-10.763113, 165.847142, 128.6],
  [-10.763268, 165.847238, 127.8],
  [-10.763437, 165.847313, 126.8],
  [-10.763622, 165.847324, 125.8],
  [-10.763805, 165.847281, 124.8],
  [-10.763985, 165.847217, 123.8],
  [-10.76417, 165.847174, 122.8],
  [-10.76436, 165.847185, 122],
  [-10.764544, 165.847238, 121.4],
  [-10.764724, 165.847292, 120.6],
  [-10.764912, 165.847324, 120],
  [-10.765071, 165.847217, 119],
  [-10.765175, 165.847067, 118.4],
  [-10.765243, 165.846884, 117.6],
  [-10.765262, 165.84668, 116.8],
  [-10.765187, 165.846519, 116],
  [-10.765047, 165.846391, 115.4],
  [-10.764884, 165.846283, 114.8],
  [-10.76471, 165.84623, 114.4],
  [-10.764525, 165.846198, 113.6],
  [-10.764342, 165.84623, 112.6],
  [-10.764173, 165.846316, 111.8],
  [-10.764015, 165.846423, 110.8],
  [-10.763856, 165.84653, 110],
  [-10.763689, 165.846627, 109],
  [-10.763504, 165.846627, 107.8],
  [-10.763376, 165.846487, 107],
  [-10.763315, 165.846305, 106.4],
  [-10.763348, 165.846101, 105.6],
  [-10.763473, 165.845962, 104.8],
  [-10.763609, 165.845833, 104],
  [-10.763701, 165.845661, 103.2],
  [-10.763758, 165.845479, 102.4],
  [-10.763862, 165.845307, 102.2],
];

export default stream;
