import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.799632, 165.843011, 66.4],
  [-10.799795, 165.843011, 66.4],
  [-10.799953, 165.843054, 66.4],
  [-10.800117, 165.843086, 66.4],
  [-10.800278, 165.843097, 66.4],
  [-10.800401, 165.843204, 66.2],
  [-10.800397, 165.843376, 66.2],
  [-10.800343, 165.843526, 66.2],
  [-10.800287, 165.843676, 66.2],
  [-10.800236, 165.843848, 66.2],
  [-10.8003, 165.843987, 66],
  [-10.800458, 165.844041, 66],
  [-10.800621, 165.844073, 66],
  [-10.800764, 165.84417, 66],
  [-10.800909, 165.844245, 66],
  [-10.801062, 165.844181, 66],
  [-10.801134, 165.84403, 66],
  [-10.801173, 165.84388, 66],
  [-10.801211, 165.843709, 66],
  [-10.801236, 165.843537, 66],
  [-10.801145, 165.843408, 66],
  [-10.80099, 165.843354, 66],
  [-10.800823, 165.843322, 66],
  [-10.800741, 165.843194, 65.8],
  [-10.800766, 165.843022, 65.8],
  [-10.800811, 165.84285, 65.8],
  [-10.800845, 165.8427, 66],
  [-10.800882, 165.842528, 66],
  [-10.800922, 165.842367, 66.2],
  [-10.800963, 165.842217, 66.4],
  [-10.800997, 165.842046, 66.6],
  [-10.801024, 165.841874, 67],
  [-10.801032, 165.841713, 67.2],
  [-10.801026, 165.841552, 67.4],
  [-10.801006, 165.841391, 67.8],
  [-10.80098, 165.841241, 67.8],
  [-10.801053, 165.841101, 67.6],
  [-10.801206, 165.841037, 67.2],
  [-10.801348, 165.840951, 66.6],
  [-10.801495, 165.840887, 67],
  [-10.801638, 165.840962, 68],
  [-10.801694, 165.841112, 68.4],
  [-10.801641, 165.841252, 68.6],
  [-10.801518, 165.841359, 68.4],
  [-10.801446, 165.841498, 68.4],
  [-10.801527, 165.841638, 68.8],
  [-10.801645, 165.841745, 69.6],
  [-10.8017, 165.841895, 70],
  [-10.801678, 165.842046, 70.2],
  [-10.801631, 165.842196, 70],
  [-10.801589, 165.842346, 70.2],
  [-10.801576, 165.842507, 70.4],
  [-10.801688, 165.842625, 70.4],
  [-10.801844, 165.842636, 70.4],
  [-10.801943, 165.842518, 70.4],
  [-10.801983, 165.842346, 70.4],
  [-10.802017, 165.842196, 70.4],
  [-10.802132, 165.842088, 70.4],
  [-10.802291, 165.842131, 70.4],
  [-10.802447, 165.842185, 70.4],
  [-10.802609, 165.842196, 70.4],
  [-10.802754, 165.842121, 70],
  [-10.802825, 165.84197, 69.6],
  [-10.802872, 165.841799, 69.4],
  [-10.802918, 165.841638, 69],
  [-10.80288, 165.841477, 68.6],
  [-10.80273, 165.841391, 68.2],
  [-10.802595, 165.841295, 68.2],
  [-10.802571, 165.841134, 68.4],
  [-10.802673, 165.840994, 68.2],
  [-10.80284, 165.840994, 68],
  [-10.803005, 165.841037, 67.6],
  [-10.803169, 165.84108, 67],
  [-10.803334, 165.841144, 66.8],
  [-10.803471, 165.841252, 66.6],
  [-10.803534, 165.841423, 66.2],
  [-10.803502, 165.841584, 66.2],
  [-10.803377, 165.841713, 66.2],
  [-10.803232, 165.841799, 66],
  [-10.803088, 165.841895, 66],
  [-10.802986, 165.842035, 66.4],
  [-10.802982, 165.842196, 67],
  [-10.803118, 165.842292, 67],
  [-10.803262, 165.842367, 66.8],
  [-10.803258, 165.842528, 66.6],
  [-10.80321, 165.8427, 66.4],
  [-10.803165, 165.84285, 66.2],
  [-10.80312, 165.843022, 66.2],
  [-10.803079, 165.843194, 66],
  [-10.803045, 165.843354, 66],
  [-10.803048, 165.843526, 65.8],
  [-10.803115, 165.843676, 65.8],
  [-10.80324, 165.843805, 65.6],
];

export default stream;
