import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [53.993559, -1.547048, 108.4],
  [53.993404, -1.547074, 108.6],
  [53.993275, -1.547213, 109.6],
  [53.993163, -1.54737, 111.6],
  [53.993055, -1.547523, 113.8],
  [53.992959, -1.547663, 114.8],
  [53.992857, -1.547793, 115.6],
  [53.992755, -1.547914, 116.2],
  [53.992653, -1.548036, 117],
  [53.992556, -1.548159, 117.8],
  [53.99246, -1.548282, 118.4],
  [53.992363, -1.548404, 119.2],
  [53.992267, -1.548521, 119.8],
  [53.99217, -1.548636, 120.4],
  [53.992068, -1.54875, 121],
  [53.991966, -1.548861, 121.6],
  [53.99187, -1.548965, 122],
  [53.991768, -1.549068, 122.4],
  [53.99166, -1.549168, 122.8],
  [53.991542, -1.549269, 123],
  [53.991435, -1.549367, 123.2],
  [53.991317, -1.549466, 123.4],
  [53.99121, -1.549561, 123.6],
  [53.991092, -1.549658, 123.8],
  [53.990968, -1.549756, 124],
  [53.990845, -1.549852, 124.2],
  [53.990727, -1.549947, 124.4],
  [53.990604, -1.550039, 124.6],
  [53.990475, -1.550125, 125],
  [53.990352, -1.550205, 125.4],
  [53.990223, -1.550278, 125.6],
  [53.990094, -1.55035, 126],
  [53.989965, -1.550417, 126.2],
  [53.989831, -1.550476, 126.4],
  [53.989692, -1.550532, 126.6],
  [53.989563, -1.550589, 126.8],
  [53.989434, -1.550671, 127.2],
  [53.989327, -1.55082, 127.2],
  [53.98923, -1.550991, 127.6],
  [53.989139, -1.551175, 128],
  [53.989069, -1.551373, 128.2],
  [53.989005, -1.551582, 128.8],
  [53.988941, -1.551784, 129.8],
  [53.988882, -1.551984, 130.8],
  [53.988817, -1.552179, 131.6],
  [53.988758, -1.552369, 132.6],
  [53.988705, -1.552554, 133.6],
  [53.988646, -1.55274, 134.4],
  [53.988592, -1.55292, 135.4],
  [53.988538, -1.553098, 136.2],
  [53.988485, -1.553272, 137.2],
  [53.988436, -1.553445, 138],
  [53.988383, -1.553618, 138.8],
  [53.988335, -1.553786, 139.6],
  [53.988281, -1.553955, 140.4],
  [53.988227, -1.554121, 141.2],
  [53.988174, -1.554282, 142],
  [53.98812, -1.554448, 142.8],
  [53.988066, -1.554609, 143.6],
  [53.988018, -1.55477, 144.4],
  [53.987964, -1.554927, 145.2],
  [53.987911, -1.555089, 146],
  [53.987857, -1.555245, 146.6],
  [53.987798, -1.555402, 147.4],
  [53.987739, -1.555554, 148.2],
  [53.98768, -1.555706, 148.8],
  [53.987616, -1.55586, 149.4],
  [53.987551, -1.556008, 150],
  [53.987482, -1.556154, 150.6],
  [53.987412, -1.556298, 151.2],
  [53.987337, -1.556443, 151.6],
  [53.987262, -1.556587, 152.2],
  [53.987187, -1.556727, 152.6],
  [53.987101, -1.556871, 153.2],
  [53.98702, -1.55701, 153.6],
  [53.986934, -1.557151, 154],
  [53.986854, -1.557293, 154.6],
  [53.986768, -1.557435, 155],
  [53.986682, -1.55758, 155.6],
  [53.986596, -1.557729, 156],
  [53.986511, -1.55788, 156.6],
  [53.98643, -1.55803, 157.2],
  [53.98635, -1.558172, 157.8],
  [53.986259, -1.558315, 158.6],
  [53.986173, -1.558459, 159.2],
  [53.986087, -1.558601, 159.8],
  [53.986001, -1.558743, 160.4],
  [53.985921, -1.558891, 161.2],
  [53.985851, -1.559051, 162],
  [53.985862, -1.559245, 162.6],
  [53.985931, -1.559398, 162.8],
  [53.986022, -1.559536, 162.6],
  [53.986124, -1.559674, 162.4],
  [53.986221, -1.559811, 162.2],
  [53.986328, -1.559955, 161.8],
  [53.98643, -1.560109, 161.4],
  [53.986537, -1.56027, 161],
  [53.98665, -1.560433, 160.6],
  [53.986763, -1.560602, 160],
  [53.98687, -1.560772, 159.4],
  [53.986988, -1.560946, 158.6],
  [53.987111, -1.561126, 157.4],
  [53.987235, -1.561311, 156.2],
  [53.987369, -1.561498, 154.8],
  [53.987498, -1.561688, 153.2],
  [53.987632, -1.561882, 151.8],
  [53.987771, -1.562082, 150.8],
  [53.987921, -1.562252, 150],
  [53.988082, -1.562402, 149],
  [53.988243, -1.562544, 147.8],
  [53.98841, -1.562684, 146.6],
  [53.988576, -1.562821, 145.2],
  [53.988753, -1.56296, 143.6],
  [53.988925, -1.563095, 142.2],
  [53.989096, -1.563225, 140.8],
  [53.989284, -1.56334, 139.6],
  [53.989466, -1.563423, 139],
  [53.989654, -1.56349, 138.6],
  [53.989837, -1.563568, 138],
  [53.989998, -1.563736, 137.8],
  [53.990116, -1.563967, 137.6],
  [53.990234, -1.564211, 137],
  [53.990341, -1.56446, 136.2],
  [53.990443, -1.564713, 135],
  [53.99055, -1.564964, 133.8],
  [53.990668, -1.565219, 132.4],
  [53.990775, -1.565474, 131],
  [53.990893, -1.565732, 129.6],
  [53.991011, -1.565989, 128.2],
  [53.991129, -1.566251, 126.8],
  [53.991242, -1.56651, 125.2],
  [53.99136, -1.566774, 123.8],
  [53.991478, -1.567045, 122.4],
  [53.991596, -1.567312, 120.8],
  [53.991714, -1.567583, 119.4],
  [53.991832, -1.567857, 117.8],
  [53.991934, -1.568149, 116.2],
  [53.99195, -1.568484, 114.4],
  [53.991913, -1.568827, 113],
  [53.99187, -1.569157, 111.6],
  [53.991832, -1.569498, 110.4],
  [53.991778, -1.569824, 109.2],
  [53.991725, -1.570149, 107.8],
  [53.99166, -1.570484, 106.4],
  [53.991607, -1.570805, 105],
  [53.991548, -1.571133, 103.8],
  [53.991489, -1.57146, 102.2],
  [53.991446, -1.57179, 100.8],
  [53.991419, -1.572123, 99.4],
  [53.991494, -1.57244, 97.6],
  [53.991677, -1.572539, 97],
  [53.991859, -1.572413, 97.2],
  [53.992009, -1.572208, 97.6],
  [53.992154, -1.572011, 98],
  [53.99232, -1.571906, 98],
  [53.992497, -1.571915, 98.4],
  [53.992669, -1.57195, 99.2],
  [53.992835, -1.572002, 100.2],
  [53.992991, -1.57208, 100.8],
  [53.993125, -1.572211, 101.4],
  [53.993248, -1.572366, 102.4],
  [53.993366, -1.572517, 103.6],
  [53.993484, -1.572663, 104.8],
  [53.993592, -1.572802, 106],
  [53.993699, -1.572941, 107],
  [53.993801, -1.573075, 108.2],
  [53.993903, -1.573207, 109.2],
  [53.993999, -1.573334, 110.2],
  [53.994091, -1.573459, 111.2],
  [53.994182, -1.573582, 112.2],
  [53.994273, -1.573702, 113.2],
  [53.994364, -1.573824, 114.2],
  [53.994445, -1.573941, 115],
  [53.994536, -1.574058, 116],
  [53.994611, -1.574187, 116.8],
  [53.994648, -1.574359, 117.8],
  [53.994648, -1.574538, 118.4],
  [53.994632, -1.57472, 119.2],
  [53.994606, -1.574897, 120],
  [53.994589, -1.575076, 120.8],
  [53.994568, -1.575251, 121.6],
  [53.994541, -1.575428, 122.4],
  [53.99452, -1.5756, 123.2],
  [53.994493, -1.575772, 124.2],
  [53.994471, -1.575947, 125],
  [53.994455, -1.576119, 125.8],
  [53.994434, -1.576295, 126.8],
  [53.994418, -1.576466, 127.6],
  [53.994418, -1.57664, 128.4],
  [53.994418, -1.576814, 129],
  [53.994423, -1.576993, 129.4],
  [53.994439, -1.577171, 129.8],
  [53.994461, -1.577354, 130.2],
  [53.994471, -1.577542, 130.6],
  [53.994488, -1.577732, 131],
  [53.994498, -1.577926, 131.6],
  [53.994509, -1.57812, 132],
  [53.99452, -1.578322, 132.6],
  [53.994525, -1.578519, 133],
  [53.994541, -1.578718, 133.6],
  [53.994552, -1.578923, 134],
  [53.994557, -1.579126, 134.4],
  [53.994568, -1.57933, 134.8],
  [53.994579, -1.579537, 135.2],
  [53.994589, -1.579748, 135.4],
  [53.994606, -1.57996, 135.8],
  [53.994616, -1.580175, 136],
  [53.994632, -1.580391, 136.2],
  [53.994643, -1.580609, 136.6],
  [53.994659, -1.580832, 136.8],
  [53.99467, -1.581051, 137],
  [53.994681, -1.581276, 137.4],
  [53.994697, -1.5815, 137.6],
  [53.994707, -1.581726, 137.8],
  [53.994724, -1.581953, 138],
  [53.994734, -1.582183, 138.4],
  [53.99475, -1.582411, 138.6],
  [53.994761, -1.582642, 138.8],
  [53.994777, -1.582877, 139],
  [53.994788, -1.583107, 139.4],
  [53.994804, -1.583343, 139.6],
  [53.994815, -1.583575, 139.8],
  [53.994831, -1.583806, 140],
  [53.994842, -1.58404, 140.4],
  [53.994858, -1.584276, 140.6],
  [53.994868, -1.584514, 140.8],
  [53.994884, -1.584746, 141],
  [53.994895, -1.584984, 141.4],
  [53.994911, -1.585221, 141.6],
  [53.994927, -1.585454, 141.6],
  [53.994901, -1.585686, 142],
  [53.994815, -1.585879, 142.4],
  [53.994697, -1.586005, 142.8],
  [53.994568, -1.586056, 142.8],
  [53.994423, -1.586073, 142.6],
  [53.994284, -1.58609, 142.2],
  [53.994133, -1.586108, 141.6],
  [53.993983, -1.586126, 141.2],
  [53.993833, -1.586146, 140.6],
  [53.993677, -1.586162, 140],
  [53.993517, -1.586178, 139.6],
  [53.993361, -1.586194, 139],
  [53.993195, -1.58621, 138.4],
  [53.993034, -1.586226, 137.8],
  [53.992867, -1.586242, 137.2],
  [53.992701, -1.586259, 136.8],
  [53.99253, -1.586279, 136.2],
  [53.992369, -1.586299, 135.8],
  [53.992202, -1.58632, 135.4],
  [53.992031, -1.586341, 135],
  [53.991864, -1.586362, 134.6],
  [53.991698, -1.586383, 134.2],
  [53.991526, -1.586404, 134],
  [53.991355, -1.586426, 133.6],
  [53.991188, -1.586448, 133.2],
  [53.991027, -1.586469, 132.8],
  [53.990856, -1.586492, 132.4],
  [53.990684, -1.586514, 132],
  [53.990518, -1.586547, 131.6],
  [53.990352, -1.586615, 131.4],
  [53.990196, -1.586694, 131],
  [53.990035, -1.586795, 130.4],
  [53.989879, -1.586904, 130],
  [53.989729, -1.587048, 129.4],
  [53.98959, -1.587215, 128.4],
  [53.989456, -1.587399, 127.4],
  [53.989322, -1.5876, 126.4],
  [53.989193, -1.587805, 125.2],
  [53.989053, -1.588007, 124.2],
  [53.988914, -1.588197, 123],
  [53.988764, -1.588376, 122],
  [53.988603, -1.588534, 121.2],
  [53.988436, -1.588671, 120.2],
  [53.988265, -1.588789, 119.4],
  [53.988093, -1.588894, 118.6],
  [53.987916, -1.588993, 117.6],
  [53.987744, -1.589084, 116.8],
  [53.987567, -1.589173, 116.2],
  [53.98739, -1.589261, 115.6],
  [53.987208, -1.589347, 115],
  [53.987036, -1.589432, 114.6],
  [53.986865, -1.589523, 114.2],
  [53.986693, -1.58962, 114.2],
  [53.986532, -1.589717, 114.4],
  [53.986371, -1.589812, 115],
  [53.986205, -1.589907, 115.6],
  [53.986055, -1.589997, 116.2],
  [53.985904, -1.590086, 117],
  [53.98576, -1.590173, 117.8],
  [53.98562, -1.590258, 118.4],
  [53.985481, -1.590341, 118.8],
  [53.985336, -1.590423, 119],
  [53.985202, -1.590498, 118.8],
  [53.985057, -1.590568, 118.2],
  [53.984907, -1.59064, 117],
  [53.984756, -1.590726, 116],
  [53.984612, -1.590849, 115],
  [53.984461, -1.59099, 114],
  [53.9843, -1.591069, 113.6],
  [53.984134, -1.591053, 113.8],
  [53.983968, -1.591031, 114.2],
  [53.983818, -1.591117, 114.8],
  [53.9837, -1.591292, 115],
  [53.983587, -1.591482, 115.6],
  [53.983453, -1.591528, 118],
  [53.983367, -1.591338, 120.8],
  [53.983319, -1.591143, 123],
  [53.983276, -1.590958, 125],
  [53.983233, -1.59079, 126.8],
  [53.983169, -1.590667, 128.6],
  [53.983077, -1.59063, 130],
  [53.982986, -1.590668, 130.8],
  [53.982906, -1.590741, 131.6],
  [53.982825, -1.590816, 132.8],
  [53.982745, -1.590885, 134.2],
  [53.98267, -1.590953, 135.2],
  [53.982595, -1.591025, 136.4],
  [53.982525, -1.591097, 137.6],
  [53.982455, -1.591168, 139],
  [53.98238, -1.591228, 140],
  [53.98231, -1.591282, 141.2],
  [53.982246, -1.591331, 142.4],
  [53.982176, -1.591377, 143.6],
  [53.982106, -1.591421, 144.8],
  [53.982042, -1.59146, 146],
  [53.981972, -1.591495, 147],
  [53.981903, -1.591517, 148],
  [53.981833, -1.591528, 148.8],
  [53.981758, -1.591537, 149.6],
  [53.981683, -1.591546, 150.4],
  [53.981602, -1.591555, 151.2],
  [53.981527, -1.591563, 152],
  [53.981441, -1.59157, 152.8],
  [53.981361, -1.591578, 153.6],
  [53.98128, -1.591585, 154.4],
  [53.981189, -1.591593, 155.2],
  [53.981109, -1.591601, 156],
  [53.981017, -1.591609, 156.8],
  [53.980926, -1.591617, 157.6],
  [53.98084, -1.591625, 158.6],
  [53.980749, -1.591633, 159.4],
  [53.980663, -1.59164, 160.2],
  [53.980567, -1.591648, 161],
  [53.980476, -1.591656, 161.6],
  [53.980384, -1.591665, 162.4],
  [53.980288, -1.591676, 163],
  [53.980191, -1.591689, 163.6],
  [53.980095, -1.591703, 164.2],
  [53.979993, -1.591719, 164.6],
  [53.979886, -1.591736, 165],
  [53.979784, -1.591755, 165.4],
  [53.979671, -1.591775, 165.8],
  [53.979558, -1.591796, 166],
  [53.979446, -1.591818, 166.4],
  [53.979328, -1.591841, 166.6],
  [53.97921, -1.591866, 167],
  [53.979092, -1.591891, 167.4],
  [53.978968, -1.591918, 168],
  [53.97885, -1.591945, 168.6],
  [53.978732, -1.591974, 169.2],
  [53.978614, -1.592003, 169.8],
  [53.978496, -1.592033, 170.6],
  [53.978378, -1.592063, 171.2],
  [53.97826, -1.592094, 171.8],
  [53.978137, -1.592124, 172.4],
  [53.978019, -1.592154, 172.8],
  [53.977901, -1.592186, 173.2],
  [53.977783, -1.592217, 173.2],
  [53.977654, -1.592249, 173],
  [53.977525, -1.592282, 172.8],
  [53.977396, -1.592317, 172.2],
  [53.977252, -1.592352, 171.8],
  [53.977112, -1.59239, 171.2],
  [53.976967, -1.592427, 170.6],
  [53.976817, -1.592465, 170.2],
  [53.976667, -1.592505, 169.6],
  [53.976511, -1.592547, 169.2],
  [53.976356, -1.592589, 169],
  [53.9762, -1.592631, 168.6],
  [53.976039, -1.592673, 168.4],
  [53.975878, -1.592716, 168],
  [53.975723, -1.592758, 167.8],
  [53.975562, -1.592799, 167.4],
  [53.975406, -1.592833, 167.2],
  [53.97524, -1.592864, 167],
  [53.975079, -1.592904, 166.8],
  [53.974929, -1.592867, 166.6],
  [53.974918, -1.592603, 165.8],
  [53.974972, -1.592333, 165],
  [53.975036, -1.592073, 164.6],
  [53.975101, -1.591807, 164.2],
  [53.975154, -1.591541, 164.2],
  [53.975208, -1.591272, 164.6],
  [53.975256, -1.591012, 164.8],
  [53.97531, -1.590746, 164.8],
  [53.975358, -1.590492, 165],
  [53.975406, -1.590236, 165],
  [53.975455, -1.58998, 165],
  [53.975503, -1.589724, 165.2],
  [53.975551, -1.589472, 165.2],
  [53.975599, -1.589222, 165.2],
  [53.975642, -1.588972, 165.4],
  [53.975691, -1.58872, 165.4],
  [53.975739, -1.588471, 165.6],
  [53.975787, -1.588222, 165.6],
  [53.97583, -1.587976, 165.6],
  [53.975878, -1.587725, 165.8],
  [53.975927, -1.587481, 165.8],
  [53.975975, -1.587232, 165.8],
  [53.976018, -1.586987, 166],
  [53.976066, -1.586739, 166],
  [53.976109, -1.586497, 166],
  [53.976157, -1.586249, 166],
  [53.976206, -1.586003, 166.2],
  [53.976249, -1.585758, 166.2],
  [53.976297, -1.585511, 166.2],
  [53.976345, -1.58527, 166.4],
  [53.976388, -1.585022, 166.4],
  [53.976436, -1.58478, 166.4],
  [53.976485, -1.584531, 166.4],
  [53.976527, -1.58429, 166.6],
  [53.976576, -1.584041, 166.6],
  [53.976619, -1.583803, 166.6],
  [53.976667, -1.583555, 166.6],
  [53.976715, -1.583308, 166.8],
  [53.976758, -1.583068, 166.8],
  [53.976806, -1.582823, 166.8],
  [53.976855, -1.582573, 166.8],
  [53.976898, -1.582327, 166.8],
  [53.976946, -1.582082, 166.8],
  [53.976989, -1.581834, 166.6],
  [53.977037, -1.58159, 166.6],
  [53.97708, -1.581342, 166.4],
  [53.977123, -1.581086, 166.2],
  [53.977171, -1.580836, 165.8],
  [53.977214, -1.580574, 165.4],
  [53.977262, -1.580323, 165.2],
  [53.977305, -1.580065, 165],
  [53.977359, -1.579807, 164.8],
  [53.977413, -1.579547, 164.8],
  [53.977461, -1.579292, 164.6],
  [53.977509, -1.579037, 164.4],
  [53.977557, -1.578778, 164.2],
  [53.977611, -1.578516, 163.8],
  [53.977665, -1.57826, 163.6],
  [53.977713, -1.577997, 163.2],
  [53.977761, -1.577733, 163],
  [53.97781, -1.577471, 162.6],
  [53.977863, -1.577205, 162.2],
  [53.977911, -1.576943, 162],
  [53.97796, -1.576679, 161.6],
  [53.978008, -1.576409, 161.2],
  [53.978062, -1.576145, 160.8],
  [53.97811, -1.575872, 160.4],
  [53.978158, -1.575606, 160],
  [53.978212, -1.575335, 159.6],
  [53.97826, -1.575066, 159.2],
  [53.978308, -1.574798, 158.6],
  [53.978362, -1.574518, 157.8],
  [53.978416, -1.574243, 157.2],
  [53.978469, -1.573963, 156.2],
  [53.978523, -1.573685, 155.4],
  [53.978577, -1.573396, 154.4],
  [53.97863, -1.573114, 153.4],
  [53.978684, -1.572821, 152.6],
  [53.978738, -1.57253, 151.8],
  [53.978797, -1.572234, 150.4],
  [53.97885, -1.571938, 149.8],
  [53.978904, -1.571648, 150.2],
  [53.978958, -1.571363, 150.8],
  [53.979006, -1.57109, 151.8],
  [53.979054, -1.570824, 153],
  [53.979102, -1.570563, 154],
  [53.979145, -1.570317, 155],
  [53.979194, -1.570083, 156.2],
  [53.979236, -1.569852, 157.2],
  [53.979274, -1.569632, 158.2],
  [53.979312, -1.569411, 159.4],
  [53.979355, -1.569201, 160.4],
  [53.979392, -1.568998, 161.4],
  [53.979435, -1.568797, 162.4],
  [53.979467, -1.568605, 163.4],
  [53.979505, -1.568411, 164.2],
  [53.979537, -1.568225, 165.2],
  [53.979574, -1.568037, 166.2],
  [53.979612, -1.567856, 167],
  [53.979644, -1.567678, 168],
  [53.979682, -1.5675, 169],
  [53.979714, -1.567326, 170],
  [53.979751, -1.567156, 171],
  [53.979784, -1.566988, 172],
  [53.979816, -1.566828, 173],
  [53.979848, -1.566667, 174],
  [53.97988, -1.566507, 175],
  [53.979912, -1.566348, 176],
  [53.97995, -1.566194, 176.8],
  [53.979977, -1.566036, 177.8],
  [53.980014, -1.565881, 178.6],
  [53.980041, -1.565723, 179.2],
  [53.980073, -1.565564, 179.8],
  [53.980106, -1.5654, 180.4],
  [53.980132, -1.565236, 180.8],
  [53.98017, -1.565066, 181.2],
  [53.980197, -1.564892, 181.6],
  [53.980229, -1.564711, 181.8],
  [53.980266, -1.564522, 182.2],
  [53.980299, -1.564335, 182.2],
  [53.980331, -1.564136, 182.4],
  [53.980368, -1.563938, 182.6],
  [53.980401, -1.563735, 182.6],
  [53.980438, -1.563522, 182.6],
  [53.980476, -1.56331, 182.6],
  [53.980513, -1.563094, 182.6],
  [53.980551, -1.562874, 182.6],
  [53.980594, -1.562649, 182.6],
  [53.980637, -1.562419, 182.4],
  [53.980674, -1.562195, 182.4],
  [53.980728, -1.561962, 182.4],
  [53.980776, -1.561732, 182.2],
  [53.980835, -1.561501, 181.4],
  [53.980899, -1.561273, 180.6],
  [53.980975, -1.561036, 179.8],
  [53.981044, -1.560787, 179],
  [53.981109, -1.560541, 178.2],
  [53.981189, -1.560291, 177.2],
  [53.981264, -1.560035, 176.4],
  [53.981339, -1.55977, 175.4],
  [53.981414, -1.55951, 174.6],
  [53.981495, -1.559239, 173.6],
  [53.98157, -1.558974, 172.6],
  [53.981645, -1.558695, 171.8],
  [53.981726, -1.558423, 170.8],
  [53.981801, -1.558151, 169.8],
  [53.981881, -1.557869, 169],
  [53.981962, -1.557591, 168],
  [53.982042, -1.557309, 167],
  [53.982123, -1.557029, 166],
  [53.982203, -1.556749, 165.2],
  [53.982283, -1.556469, 164.2],
  [53.982364, -1.556189, 163.4],
  [53.982444, -1.555906, 162.4],
  [53.982525, -1.55562, 161.6],
  [53.982605, -1.555339, 160.8],
  [53.982691, -1.555052, 160],
  [53.982772, -1.554773, 159],
  [53.982852, -1.554489, 158.2],
  [53.982933, -1.5542, 157.4],
  [53.983013, -1.553922, 156.6],
  [53.983088, -1.553633, 155.8],
  [53.983169, -1.553354, 155],
  [53.983249, -1.55307, 154],
  [53.983335, -1.552782, 153.2],
  [53.983415, -1.552498, 152.4],
  [53.98349, -1.552221, 151.6],
  [53.983576, -1.551934, 150.8],
  [53.983651, -1.551655, 150],
  [53.983732, -1.551373, 149.2],
  [53.983812, -1.551092, 148.4],
  [53.983893, -1.550809, 147.6],
  [53.983973, -1.550528, 147],
  [53.984054, -1.55024, 146.2],
  [53.984134, -1.549966, 145.4],
  [53.984215, -1.54968, 144.8],
  [53.98429, -1.549403, 144.2],
  [53.98437, -1.549127, 143.8],
  [53.984451, -1.548848, 143.4],
  [53.984526, -1.548571, 143],
  [53.984606, -1.548299, 142.4],
  [53.984681, -1.54803, 141.8],
  [53.984756, -1.547758, 141.4],
  [53.984837, -1.547484, 140.8],
  [53.984912, -1.547215, 140.2],
  [53.984993, -1.546943, 139.8],
  [53.985068, -1.546681, 139.2],
  [53.985148, -1.546409, 138.8],
  [53.985223, -1.54614, 138.2],
  [53.985298, -1.545871, 137.8],
  [53.985373, -1.545606, 137.2],
  [53.985454, -1.545334, 136.6],
  [53.985534, -1.545067, 136.2],
  [53.985609, -1.5448, 135.6],
  [53.985685, -1.544536, 135.2],
  [53.98576, -1.544267, 134.6],
  [53.98584, -1.544002, 134.2],
  [53.985915, -1.543733, 133.6],
  [53.985996, -1.543471, 133.2],
  [53.986071, -1.543206, 132.6],
  [53.986151, -1.542939, 132],
  [53.986226, -1.542676, 131.6],
  [53.986301, -1.542407, 131],
  [53.986377, -1.542141, 130.6],
  [53.986457, -1.541876, 130],
  [53.986537, -1.541607, 129.6],
  [53.986613, -1.541344, 129],
  [53.986682, -1.541074, 128.6],
  [53.986757, -1.540811, 128],
  [53.986827, -1.540543, 127.6],
  [53.986908, -1.540273, 127],
  [53.986983, -1.540008, 126.6],
  [53.987074, -1.539758, 126.2],
  [53.987208, -1.539583, 125.8],
  [53.987374, -1.539524, 126],
  [53.987541, -1.539569, 126.2],
  [53.987696, -1.539678, 126.8],
  [53.987846, -1.539795, 126.8],
  [53.98798, -1.539897, 126.8],
  [53.988099, -1.539982, 126.8],
  [53.9882, -1.540057, 126.6],
  [53.988281, -1.540121, 126.6],
  [53.98834, -1.540171, 126.6],
  [53.988383, -1.540209, 126.6],
  [53.98841, -1.540232, 126.6],
  [53.98842, -1.540241, 126.6],
  [53.988426, -1.540251, 126.6],
  [53.988453, -1.540274, 126.6],
  [53.988479, -1.5403, 126.6],
  [53.988517, -1.540326, 126.6],
  [53.98856, -1.540353, 126.6],
  [53.988597, -1.540382, 126.6],
  [53.98864, -1.540413, 126.6],
  [53.988689, -1.540446, 126.6],
  [53.988742, -1.540481, 126.6],
  [53.988796, -1.540519, 126.6],
  [53.98885, -1.540558, 126.6],
  [53.988903, -1.540598, 126.4],
  [53.988962, -1.540641, 126.4],
  [53.989021, -1.540684, 126.4],
  [53.989086, -1.540728, 126.4],
  [53.98915, -1.540774, 126.4],
  [53.989209, -1.540818, 126.4],
  [53.989279, -1.540865, 126.2],
  [53.989348, -1.540911, 126.2],
  [53.989407, -1.540953, 126.2],
  [53.989456, -1.540985, 126.2],
  [53.989488, -1.541007, 126.2],
  [53.989499, -1.541017, 126],
  [53.989509, -1.541026, 126],
  [53.989531, -1.541042, 126],
  [53.989563, -1.541064, 126],
  [53.989595, -1.541086, 126],
  [53.989627, -1.541109, 126],
  [53.98966, -1.541125, 126],
  [53.98967, -1.541132, 126],
  [53.989676, -1.541135, 126],
  [53.989692, -1.541143, 125.8],
  [53.989692, -1.541148, 125.8],
  [53.989692, -1.541151, 125.8],
  [53.989697, -1.541154, 125.8],
  [53.989708, -1.541163, 125.8],
  [53.989735, -1.541176, 125.8],
  [53.989761, -1.541192, 125.8],
  [53.989799, -1.54121, 125.8],
  [53.989853, -1.541235, 125.6],
  [53.989917, -1.541267, 125.6],
  [53.989998, -1.541303, 125.4],
  [53.990083, -1.541344, 125.2],
  [53.99018, -1.54139, 125],
  [53.990282, -1.541439, 124.8],
];
export default stream;
