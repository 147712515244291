import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.686188, 166.925454, 8.4],
  [-11.686125, 166.92524, 8.4],
  [-11.68607, 166.925025, 8.4],
  [-11.686016, 166.924821, 8.2],
  [-11.685964, 166.924628, 7.8],
  [-11.685909, 166.924424, 7.6],
  [-11.68586, 166.924231, 7.4],
  [-11.685815, 166.924016, 7.2],
  [-11.68577, 166.923802, 7.2],
  [-11.685726, 166.923598, 7.4],
  [-11.685683, 166.923405, 7.6],
  [-11.685632, 166.923212, 8.2],
  [-11.685584, 166.923008, 9],
  [-11.68553, 166.922815, 9.8],
  [-11.685481, 166.922622, 10.6],
  [-11.685428, 166.922429, 11.2],
  [-11.685376, 166.922246, 11.6],
  [-11.685323, 166.922064, 11.6],
  [-11.685264, 166.921881, 11.6],
  [-11.685186, 166.921699, 11.6],
  [-11.685115, 166.921517, 11.6],
  [-11.685097, 166.921313, 11.4],
  [-11.685162, 166.92113, 11.8],
  [-11.685276, 166.92098, 13.2],
  [-11.685408, 166.920841, 14.6],
  [-11.685542, 166.920701, 16.4],
  [-11.685666, 166.920562, 18.4],
  [-11.685785, 166.920444, 20.2],
  [-11.685903, 166.920304, 22.2],
  [-11.686016, 166.920186, 24],
  [-11.686127, 166.920068, 26],
  [-11.686228, 166.91994, 27.8],
  [-11.686324, 166.919822, 29.8],
  [-11.686418, 166.919693, 31.8],
  [-11.686503, 166.919564, 33.6],
  [-11.686593, 166.919435, 35.4],
  [-11.68668, 166.919328, 37.4],
  [-11.686779, 166.91921, 39.2],
  [-11.686874, 166.919103, 41],
  [-11.68698, 166.918995, 42.8],
  [-11.687079, 166.918888, 44.4],
  [-11.687173, 166.918781, 46.2],
  [-11.68724, 166.918663, 48.2],
  [-11.687282, 166.918523, 50],
  [-11.687344, 166.918405, 51.4],
  [-11.687419, 166.918277, 52.8],
  [-11.687498, 166.918159, 54.4],
  [-11.687578, 166.91803, 56],
  [-11.687673, 166.917922, 57.6],
  [-11.687777, 166.917815, 59.4],
  [-11.687881, 166.917729, 61],
  [-11.687997, 166.917654, 62.8],
  [-11.688119, 166.917579, 64.8],
  [-11.68823, 166.917504, 66.4],
  [-11.68834, 166.917397, 68.2],
  [-11.688429, 166.917289, 70],
  [-11.688505, 166.917182, 71.6],
  [-11.688571, 166.917053, 73.2],
  [-11.68863, 166.916925, 75],
  [-11.688683, 166.916796, 76.8],
  [-11.688733, 166.916656, 78.4],
  [-11.68878, 166.916528, 80.2],
  [-11.688832, 166.916399, 81.6],
  [-11.688875, 166.91626, 83.6],
  [-11.688903, 166.916131, 85.4],
  [-11.68893, 166.915991, 87],
  [-11.688968, 166.915852, 88.4],
  [-11.689009, 166.915712, 90],
  [-11.688965, 166.915594, 91.2],
  [-11.688862, 166.915669, 92],
  [-11.688832, 166.91582, 93],
  [-11.688796, 166.915959, 94.8],
  [-11.688748, 166.916109, 96.4],
  [-11.688686, 166.916249, 98.2],
  [-11.68862, 166.916367, 100],
  [-11.688545, 166.916496, 102],
  [-11.688455, 166.916614, 103.8],
  [-11.688349, 166.91671, 105.4],
  [-11.688225, 166.916785, 107],
  [-11.688101, 166.91686, 108.4],
  [-11.687981, 166.916925, 109.6],
  [-11.687849, 166.917, 111.2],
  [-11.687712, 166.917043, 112.6],
  [-11.687568, 166.917075, 114.2],
  [-11.687428, 166.917107, 116],
  [-11.687299, 166.917182, 117.8],
  [-11.687172, 166.917236, 119.4],
  [-11.687032, 166.917268, 121.2],
  [-11.686888, 166.917289, 123],
  [-11.686752, 166.9173, 124.8],
  [-11.686641, 166.917397, 126.4],
  [-11.686564, 166.917515, 128],
  [-11.686487, 166.917633, 129.6],
  [-11.686402, 166.917762, 131.6],
  [-11.686314, 166.917869, 133.2],
  [-11.686222, 166.917976, 134.8],
  [-11.686131, 166.918083, 136.2],
  [-11.686037, 166.918191, 137.6],
  [-11.685939, 166.918298, 139.4],
  [-11.685832, 166.918384, 141.6],
  [-11.685724, 166.91848, 143],
  [-11.685618, 166.918566, 144.4],
  [-11.685504, 166.918663, 146.4],
  [-11.685392, 166.918749, 148.4],
  [-11.685285, 166.918845, 149.8],
  [-11.685176, 166.918931, 151.8],
  [-11.685069, 166.919006, 153.6],
  [-11.68496, 166.919103, 154.8],
  [-11.684854, 166.91921, 156.2],
  [-11.684747, 166.919296, 157.6],
  [-11.684643, 166.919392, 159.2],
  [-11.684514, 166.919457, 160.2],
  [-11.684403, 166.91936, 160.6],
  [-11.684456, 166.919221, 161],
  [-11.684584, 166.919113, 161.6],
  [-11.684715, 166.919028, 162.8],
  [-11.684833, 166.91892, 164.2],
  [-11.684951, 166.918813, 165.8],
  [-11.68507, 166.918716, 167.8],
  [-11.685188, 166.918609, 170.2],
  [-11.685278, 166.918491, 172.4],
  [-11.685336, 166.918352, 174.2],
  [-11.685369, 166.918212, 176.2],
  [-11.685284, 166.918105, 177.4],
  [-11.685174, 166.918191, 178.8],
  [-11.685132, 166.918341, 180.8],
  [-11.685099, 166.918459, 182.4],
  [-11.685002, 166.918566, 184.2],
  [-11.684872, 166.918641, 185.8],
  [-11.684742, 166.918706, 187.4],
  [-11.684613, 166.91877, 189],
  [-11.68448, 166.918813, 191],
  [-11.684358, 166.918888, 193],
  [-11.684256, 166.918985, 194.4],
  [-11.684153, 166.919092, 196],
  [-11.684052, 166.919188, 197.4],
  [-11.683951, 166.919296, 199],
  [-11.683843, 166.919392, 200.6],
  [-11.683729, 166.919467, 202],
  [-11.683606, 166.919543, 203],
  [-11.683461, 166.919543, 203.6],
  [-11.683398, 166.919403, 203.6],
  [-11.683484, 166.919274, 204.2],
  [-11.683623, 166.919188, 205.4],
  [-11.683743, 166.919092, 207.2],
  [-11.683847, 166.918952, 209],
  [-11.683944, 166.918824, 210.8],
  [-11.684035, 166.918706, 212.6],
  [-11.684126, 166.918588, 214.6],
  [-11.684243, 166.918502, 216.2],
  [-11.684376, 166.918427, 218],
  [-11.684492, 166.918341, 220],
  [-11.684555, 166.918201, 221.4],
  [-11.684617, 166.918083, 223],
  [-11.684719, 166.917976, 224.8],
  [-11.684829, 166.91788, 226.4],
  [-11.684936, 166.917772, 228],
  [-11.685026, 166.917665, 229.8],
  [-11.685099, 166.917547, 232],
  [-11.685163, 166.917418, 234.2],
  [-11.685221, 166.917289, 236.2],
  [-11.685273, 166.917161, 238],
  [-11.685278, 166.917032, 239.6],
  [-11.685156, 166.917011, 240],
  [-11.685117, 166.91715, 241],
  [-11.685105, 166.917289, 242.2],
  [-11.685038, 166.917418, 244],
  [-11.684942, 166.917547, 245.6],
  [-11.684824, 166.917633, 247],
  [-11.684702, 166.917719, 248.4],
  [-11.684569, 166.917772, 250.2],
  [-11.684445, 166.917869, 252.2],
  [-11.684327, 166.917944, 254.2],
  [-11.684207, 166.918019, 255.2],
  [-11.684078, 166.918083, 256.4],
  [-11.683936, 166.918137, 257.4],
  [-11.683792, 166.918105, 258.4],
  [-11.683717, 166.917987, 259.2],
  [-11.683693, 166.917815, 260.2],
  [-11.683685, 166.917665, 261],
  [-11.68366, 166.917515, 261.8],
  [-11.683647, 166.917343, 262.6],
  [-11.683674, 166.917182, 263.6],
  [-11.683711, 166.917032, 264.6],
  [-11.683752, 166.91686, 265.6],
  [-11.683798, 166.91671, 266.8],
  [-11.683863, 166.916538, 268.4],
  [-11.683917, 166.916388, 270],
  [-11.683939, 166.916238, 271.4],
  [-11.683914, 166.916066, 272],
  [-11.683877, 166.915916, 272.8],
  [-11.683845, 166.915755, 274],
  [-11.683819, 166.915573, 275.4],
  [-11.683825, 166.915423, 276.8],
  [-11.683859, 166.915262, 278.4],
  [-11.683894, 166.915101, 280],
  [-11.683918, 166.914951, 281.6],
  [-11.683922, 166.914779, 283],
  [-11.683884, 166.91465, 284.2],
  [-11.683825, 166.914489, 285.4],
  [-11.683757, 166.914361, 287],
  [-11.683698, 166.9142, 288.8],
  [-11.683661, 166.914049, 290.2],
  [-11.683629, 166.913899, 291.4],
  [-11.683598, 166.913749, 292.8],
  [-11.683571, 166.913599, 294.2],
  [-11.683552, 166.913438, 296.2],
  [-11.68354, 166.913288, 298],
  [-11.683527, 166.913137, 299.8],
  [-11.683501, 166.912998, 301.2],
  [-11.68347, 166.912848, 302.4],
  [-11.68344, 166.912698, 303.8],
  [-11.683407, 166.912558, 305.4],
  [-11.683373, 166.912408, 306.4],
  [-11.683369, 166.912247, 307.2],
  [-11.683487, 166.912161, 307.4],
  [-11.683631, 166.912225, 307.8],
  [-11.68369, 166.912376, 308.8],
  [-11.683696, 166.912537, 310],
  [-11.683697, 166.912708, 310.6],
  [-11.683721, 166.912858, 311.8],
  [-11.683768, 166.91303, 313.2],
  [-11.683818, 166.91318, 314.6],
  [-11.683874, 166.913331, 316.2],
  [-11.683937, 166.913502, 317.4],
  [-11.684028, 166.91362, 318.4],
  [-11.68415, 166.913728, 319.8],
  [-11.684293, 166.913803, 321.6],
  [-11.684431, 166.913888, 323],
  [-11.684577, 166.913942, 324.4],
  [-11.684718, 166.914017, 325.8],
  [-11.684859, 166.914082, 327],
  [-11.685001, 166.914157, 328.4],
  [-11.685131, 166.914232, 329.8],
  [-11.685227, 166.914371, 331],
  [-11.685296, 166.914511, 331.8],
  [-11.68539, 166.914629, 332.6],
  [-11.685474, 166.914521, 333.6],
  [-11.685411, 166.914361, 334.4],
  [-11.685323, 166.914221, 336],
  [-11.685222, 166.914092, 337.2],
  [-11.685108, 166.913985, 338.8],
  [-11.684991, 166.913888, 340.4],
  [-11.684871, 166.913781, 342],
  [-11.684751, 166.913674, 343.4],
  [-11.684633, 166.913567, 344.8],
  [-11.684517, 166.913459, 346],
  [-11.684429, 166.91332, 347.4],
  [-11.68437, 166.91318, 349],
  [-11.684323, 166.91303, 350.4],
  [-11.684279, 166.91288, 352],
  [-11.684254, 166.912719, 353.4],
  [-11.684247, 166.912569, 354.6],
  [-11.684244, 166.912419, 356.2],
  [-11.684236, 166.912268, 357.8],
  [-11.684227, 166.912118, 359.4],
  [-11.684219, 166.911957, 361],
  [-11.684221, 166.911796, 362.6],
  [-11.684235, 166.911657, 364],
  [-11.684266, 166.911507, 365.4],
  [-11.684323, 166.911367, 366.6],
  [-11.684372, 166.911217, 367.6],
  [-11.684374, 166.911056, 368.6],
  [-11.684302, 166.910927, 369.4],
  [-11.684203, 166.910799, 370.6],
  [-11.684085, 166.910691, 372.2],
  [-11.683945, 166.910638, 373.8],
  [-11.68379, 166.910648, 375.8],
  [-11.683646, 166.910691, 377.6],
  [-11.6835, 166.910723, 379],
  [-11.683355, 166.910713, 380.6],
  [-11.683205, 166.910681, 382.2],
  [-11.683055, 166.910659, 383.6],
  [-11.682906, 166.910681, 384.8],
  [-11.682761, 166.910713, 386.4],
  [-11.682616, 166.910756, 388],
  [-11.682467, 166.910788, 390.2],
  [-11.682329, 166.910831, 391.8],
  [-11.682192, 166.910874, 393.2],
  [-11.682048, 166.910927, 394.4],
  [-11.681901, 166.910906, 395.6],
  [-11.681844, 166.910788, 396.8],
  [-11.681954, 166.910691, 398.6],
  [-11.682094, 166.910648, 399.6],
  [-11.682235, 166.910584, 400.8],
  [-11.682365, 166.910498, 403.2],
  [-11.682487, 166.910423, 405],
  [-11.682603, 166.910326, 406.2],
  [-11.682732, 166.910241, 407.4],
  [-11.682856, 166.910166, 409],
  [-11.682985, 166.91008, 410.8],
  [-11.683127, 166.910037, 411.8],
  [-11.683276, 166.910005, 413.2],
  [-11.683415, 166.909962, 414.8],
  [-11.683544, 166.909887, 417],
  [-11.683656, 166.90979, 419.2],
  [-11.683748, 166.909683, 420.8],
  [-11.683845, 166.909575, 422.4],
  [-11.683948, 166.909479, 424],
  [-11.684071, 166.909393, 425.2],
  [-11.684211, 166.909372, 426.2],
  [-11.684358, 166.909372, 427.6],
  [-11.684507, 166.909382, 430],
  [-11.684651, 166.909415, 431.2],
  [-11.684777, 166.90949, 432.8],
  [-11.684871, 166.909597, 434.2],
  [-11.684944, 166.909736, 435.8],
  [-11.685009, 166.909854, 437.4],
  [-11.685136, 166.909887, 438.6],
  [-11.685117, 166.909747, 439.6],
  [-11.685022, 166.909608, 440.4],
  [-11.684923, 166.9095, 441.4],
  [-11.684826, 166.909372, 442.8],
  [-11.684742, 166.909243, 444.6],
  [-11.684624, 166.909157, 446],
  [-11.684487, 166.909082, 447],
  [-11.68435, 166.909018, 448.6],
  [-11.684204, 166.908953, 450.8],
  [-11.684057, 166.908921, 452.4],
  [-11.683913, 166.908942, 453.8],
  [-11.683784, 166.909028, 455],
  [-11.683672, 166.909125, 456.2],
  [-11.68356, 166.909243, 457.4],
  [-11.68345, 166.909339, 458.6],
  [-11.683315, 166.909415, 460.4],
  [-11.683165, 166.909425, 462],
  [-11.68301, 166.909425, 463.8],
  [-11.682863, 166.909425, 465.4],
  [-11.682715, 166.909457, 466.8],
  [-11.682569, 166.90949, 468],
  [-11.682422, 166.909533, 469],
  [-11.682276, 166.909575, 470.4],
  [-11.682137, 166.909629, 471.8],
  [-11.681991, 166.909683, 473],
  [-11.68185, 166.909769, 474.2],
  [-11.681714, 166.909811, 475],
  [-11.681625, 166.909704, 476],
  [-11.681727, 166.909575, 476.4],
  [-11.681881, 166.909522, 477.2],
  [-11.682025, 166.909447, 478],
  [-11.682174, 166.909372, 479.2],
  [-11.682322, 166.909286, 480.6],
  [-11.682473, 166.909232, 482],
  [-11.682619, 166.909168, 483.2],
  [-11.682765, 166.909093, 484.6],
  [-11.682911, 166.909028, 486],
  [-11.683053, 166.908953, 487.4],
  [-11.683186, 166.908867, 488.8],
  [-11.683295, 166.908739, 490.2],
  [-11.683405, 166.908631, 491.8],
  [-11.683511, 166.908513, 493.4],
  [-11.68361, 166.908395, 494.8],
  [-11.683711, 166.908267, 496.2],
  [-11.683823, 166.908181, 497.6],
  [-11.683963, 166.908106, 499.4],
  [-11.684109, 166.908073, 501],
  [-11.68426, 166.908084, 502.2],
  [-11.684412, 166.908116, 503.2],
  [-11.684565, 166.908149, 504.2],
  [-11.684722, 166.908149, 505.2],
  [-11.684883, 166.908149, 506.2],
  [-11.685038, 166.908149, 507.6],
  [-11.685194, 166.908149, 508.8],
  [-11.685343, 166.908202, 510.2],
  [-11.685458, 166.908309, 511.6],
  [-11.685568, 166.908438, 513.2],
  [-11.685683, 166.908524, 515],
  [-11.685838, 166.908556, 516.6],
  [-11.68599, 166.908556, 518.2],
  [-11.686139, 166.908524, 520],
  [-11.686174, 166.908406, 520.4],
  [-11.686029, 166.908374, 522.2],
  [-11.685878, 166.908395, 523.8],
  [-11.685737, 166.908342, 525.6],
  [-11.685639, 166.908224, 527.4],
  [-11.685561, 166.908106, 529.2],
  [-11.685482, 166.907977, 531],
  [-11.685375, 166.907891, 532.2],
  [-11.685242, 166.907805, 533.6],
  [-11.685105, 166.907762, 535.6],
  [-11.684956, 166.907752, 537.6],
  [-11.684824, 166.907687, 538.8],
  [-11.684691, 166.907623, 540],
  [-11.684563, 166.907569, 541.2],
  [-11.684427, 166.907494, 542.6],
  [-11.684279, 166.907462, 544],
  [-11.684132, 166.90743, 545.6],
  [-11.683983, 166.90744, 547.2],
  [-11.683838, 166.907462, 548.6],
  [-11.683694, 166.907505, 550.6],
  [-11.683554, 166.907537, 552.6],
  [-11.683417, 166.90758, 554],
  [-11.683281, 166.907644, 555.4],
  [-11.683155, 166.907719, 557],
  [-11.683033, 166.907794, 558.4],
  [-11.682909, 166.907891, 560],
  [-11.682788, 166.907966, 561.4],
  [-11.682671, 166.908052, 562.8],
  [-11.682563, 166.908149, 564.6],
  [-11.682463, 166.908267, 566.2],
  [-11.682368, 166.908374, 567.4],
  [-11.68223, 166.908417, 568.2],
  [-11.682119, 166.908331, 569],
  [-11.682156, 166.908181, 569.6],
  [-11.68228, 166.908084, 570.2],
  [-11.682424, 166.908009, 571],
  [-11.682563, 166.907912, 572.2],
  [-11.682689, 166.907805, 573.6],
  [-11.682803, 166.907709, 575.4],
  [-11.682919, 166.907601, 577.4],
  [-11.683035, 166.907494, 579.4],
  [-11.68313, 166.907365, 581],
  [-11.683225, 166.907247, 582.6],
  [-11.683318, 166.907119, 584.2],
  [-11.683414, 166.907022, 586],
  [-11.683524, 166.906915, 588],
  [-11.683638, 166.906818, 589.4],
  [-11.683766, 166.906743, 591],
  [-11.6839, 166.9067, 592.4],
  [-11.68405, 166.906679, 594],
  [-11.684196, 166.906668, 595.6],
  [-11.684339, 166.906646, 597.2],
  [-11.684488, 166.906636, 598.8],
  [-11.684631, 166.906625, 600.2],
  [-11.684777, 166.906604, 601.6],
  [-11.684926, 166.906571, 603.8],
  [-11.685057, 166.906528, 606],
  [-11.685194, 166.906486, 607.8],
  [-11.685327, 166.906432, 609.2],
  [-11.685467, 166.906421, 610.6],
  [-11.685615, 166.906432, 612.2],
  [-11.685748, 166.906486, 613.6],
  [-11.685854, 166.906571, 614.8],
  [-11.685934, 166.906711, 616.6],
  [-11.686052, 166.906786, 618.6],
  [-11.686178, 166.90685, 620],
  [-11.686292, 166.906936, 621.4],
  [-11.686375, 166.907065, 622.2],
  [-11.686465, 166.907183, 623],
  [-11.686586, 166.90714, 624.6],
  [-11.686528, 166.907001, 626],
  [-11.686426, 166.906893, 627.2],
  [-11.686319, 166.906786, 628.8],
  [-11.686221, 166.906668, 630.4],
  [-11.686129, 166.906539, 631.8],
  [-11.686039, 166.906421, 633.2],
  [-11.685966, 166.906282, 634.8],
  [-11.685871, 166.906164, 636.6],
  [-11.685752, 166.906089, 638.4],
  [-11.685627, 166.906013, 640],
  [-11.685481, 166.90596, 641.2],
  [-11.685335, 166.905938, 642.6],
  [-11.685186, 166.905938, 644],
  [-11.685038, 166.905938, 645.6],
  [-11.684892, 166.905938, 647],
  [-11.68474, 166.905938, 648.2],
  [-11.68459, 166.905949, 649.6],
  [-11.68444, 166.905949, 651.4],
  [-11.684291, 166.905949, 653.4],
  [-11.684146, 166.905917, 654.6],
  [-11.684002, 166.905885, 655.8],
  [-11.683854, 166.905842, 657.2],
  [-11.683708, 166.90581, 658.8],
  [-11.683559, 166.905831, 660.2],
  [-11.683414, 166.905853, 661.4],
  [-11.683264, 166.905906, 662.4],
  [-11.683118, 166.905938, 663.8],
  [-11.682978, 166.905992, 665],
  [-11.682827, 166.906056, 666.2],
  [-11.682686, 166.906013, 666.6],
  [-11.682639, 166.905853, 667.2],
  [-11.682683, 166.905702, 668.4],
  [-11.682746, 166.905552, 669.4],
  [-11.682786, 166.905402, 670.8],
  [-11.682795, 166.90523, 672.4],
  [-11.682791, 166.90508, 674.2],
  [-11.682786, 166.904908, 676],
  [-11.682791, 166.904758, 677.4],
  [-11.6828, 166.904587, 678.6],
  [-11.682813, 166.904436, 679.8],
  [-11.682833, 166.904286, 681.6],
  [-11.682854, 166.904136, 683.2],
  [-11.68286, 166.903986, 684.8],
  [-11.682872, 166.903825, 686.6],
  [-11.682896, 166.903675, 688.2],
  [-11.682955, 166.903535, 689.4],
  [-11.683053, 166.903417, 690.6],
  [-11.683163, 166.90331, 692.2],
  [-11.683229, 166.90317, 693.4],
  [-11.683285, 166.903031, 694.8],
  [-11.683335, 166.902881, 696.6],
  [-11.683378, 166.902741, 698.4],
  [-11.683428, 166.902612, 700],
  [-11.683468, 166.902452, 701.2],
  [-11.683485, 166.902301, 702.2],
  [-11.68346, 166.902151, 703],
  [-11.683358, 166.902044, 703.8],
  [-11.683208, 166.902012, 705],
  [-11.683068, 166.902087, 706.8],
  [-11.682953, 166.902183, 708.4],
  [-11.682837, 166.902301, 710],
  [-11.682698, 166.902344, 711.4],
  [-11.682546, 166.902333, 712.6],
  [-11.682398, 166.902291, 713.8],
  [-11.682249, 166.902237, 715],
  [-11.682104, 166.902183, 716],
  [-11.681987, 166.902076, 716.6],
  [-11.681896, 166.901937, 717.2],
  [-11.681814, 166.901786, 718],
  [-11.68174, 166.901647, 718.6],
  [-11.68166, 166.901497, 719.6],
  [-11.681565, 166.901357, 721.2],
  [-11.68147, 166.901218, 722.8],
  [-11.681378, 166.9011, 724.4],
  [-11.681294, 166.900939, 726],
  [-11.681228, 166.90081, 727.4],
  [-11.681177, 166.900649, 729],
  [-11.68113, 166.900499, 730.4],
  [-11.681062, 166.900349, 732],
  [-11.680979, 166.900231, 733.6],
  [-11.680892, 166.900102, 735.2],
  [-11.68082, 166.899952, 737],
  [-11.680767, 166.899812, 738.8],
  [-11.680693, 166.899673, 740.6],
  [-11.680603, 166.899565, 742.2],
  [-11.680508, 166.899447, 743.8],
  [-11.680418, 166.89934, 745.2],
  [-11.680334, 166.899222, 746.4],
  [-11.680245, 166.899093, 747.8],
  [-11.680147, 166.898975, 749.2],
  [-11.680049, 166.898857, 750.6],
  [-11.679951, 166.89875, 752],
  [-11.679851, 166.898643, 753.4],
  [-11.679752, 166.898514, 754.8],
  [-11.679658, 166.898396, 756.2],
  [-11.679592, 166.898257, 757],
  [-11.679592, 166.898106, 757.2],
  [-11.67971, 166.89801, 757.4],
  [-11.679831, 166.898106, 758.2],
  [-11.679913, 166.898257, 759],
  [-11.680018, 166.898396, 759],
  [-11.680151, 166.898503, 759.6],
  [-11.680297, 166.898589, 761],
  [-11.680441, 166.898686, 762.4],
  [-11.680582, 166.898782, 763.8],
  [-11.680724, 166.898868, 765.4],
  [-11.680869, 166.898943, 766.8],
  [-11.681011, 166.89904, 768.2],
  [-11.681154, 166.899115, 769.8],
  [-11.681298, 166.899179, 771.2],
  [-11.681443, 166.899254, 772.8],
  [-11.681586, 166.899308, 774],
  [-11.681732, 166.899372, 775.2],
  [-11.681876, 166.899437, 776],
  [-11.682031, 166.89949, 776.8],
  [-11.682179, 166.899555, 777.4],
  [-11.682335, 166.899619, 778.2],
  [-11.682489, 166.899673, 779.6],
  [-11.68265, 166.899651, 780.8],
  [-11.682764, 166.899523, 782],
  [-11.682844, 166.899383, 783.6],
  [-11.682919, 166.899233, 785.4],
  [-11.682992, 166.899093, 787],
  [-11.683087, 166.898965, 788.4],
  [-11.683193, 166.898857, 789.8],
  [-11.683303, 166.898729, 791.2],
  [-11.683242, 166.898621, 792],
  [-11.683104, 166.898696, 793.6],
  [-11.682969, 166.898782, 795.6],
  [-11.682827, 166.898836, 797.4],
  [-11.682677, 166.898857, 798.6],
  [-11.68252, 166.898857, 799.6],
  [-11.682365, 166.898836, 800.8],
  [-11.682222, 166.898793, 802.2],
  [-11.682081, 166.898729, 803.8],
  [-11.681946, 166.898654, 805.6],
  [-11.68181, 166.898578, 807.4],
  [-11.681684, 166.898503, 809.2],
  [-11.681566, 166.898407, 810.6],
  [-11.681451, 166.898321, 811.8],
  [-11.681338, 166.898214, 813],
  [-11.681201, 166.898063, 814.8],
  [-11.68113, 166.897988, 815.8],
  [-11.681026, 166.897881, 817.6],
  [-11.680924, 166.897774, 819.4],
  [-11.680822, 166.897645, 821],
  [-11.680716, 166.897538, 822.2],
  [-11.680609, 166.897452, 823.6],
  [-11.6805, 166.897345, 824.8],
  [-11.680383, 166.897237, 826.6],
  [-11.680261, 166.897162, 828],
  [-11.680134, 166.897066, 829],
  [-11.680094, 166.896926, 830],
  [-11.6802, 166.89684, 830.8],
  [-11.680346, 166.896883, 831.6],
  [-11.68046, 166.896991, 832.4],
  [-11.680583, 166.897098, 833.2],
  [-11.680719, 166.897205, 834],
  [-11.680862, 166.897269, 835.6],
  [-11.68102, 166.897302, 837.2],
  [-11.681183, 166.897302, 838.8],
  [-11.681339, 166.897302, 840.4],
  [-11.681496, 166.897302, 842.2],
  [-11.681649, 166.897269, 844.4],
  [-11.681781, 166.897205, 846],
  [-11.681903, 166.897098, 847.2],
  [-11.682011, 166.897012, 848.6],
  [-11.682117, 166.896883, 850.6],
  [-11.682206, 166.896765, 852.2],
  [-11.682298, 166.896658, 853.8],
  [-11.682394, 166.896529, 855],
  [-11.682493, 166.896411, 856.4],
  [-11.682596, 166.896304, 857.8],
  [-11.682717, 166.896218, 859.4],
  [-11.682837, 166.896122, 861],
  [-11.682954, 166.896046, 862.2],
  [-11.683088, 166.895971, 863.6],
  [-11.683222, 166.895907, 865],
  [-11.683365, 166.895843, 866.4],
  [-11.683503, 166.895789, 867.8],
  [-11.683642, 166.895725, 869.2],
  [-11.683787, 166.895682, 870.8],
  [-11.683924, 166.895617, 872.4],
  [-11.684055, 166.895542, 874],
  [-11.68418, 166.895467, 875.6],
  [-11.684302, 166.89537, 877.2],
  [-11.684425, 166.895295, 879],
  [-11.68455, 166.89521, 880.6],
  [-11.684669, 166.895134, 882],
  [-11.68479, 166.895038, 883.2],
  [-11.684918, 166.894963, 884.8],
  [-11.685058, 166.894898, 886.4],
  [-11.685198, 166.894866, 887.8],
  [-11.685343, 166.894845, 889],
  [-11.68549, 166.894813, 890.2],
  [-11.685642, 166.89478, 891.4],
  [-11.685795, 166.894759, 892.8],
  [-11.685941, 166.894748, 894.4],
  [-11.686094, 166.894737, 896.2],
  [-11.68624, 166.894759, 897.4],
  [-11.686359, 166.894855, 898.4],
  [-11.686429, 166.894995, 899.6],
  [-11.686485, 166.895134, 900.6],
  [-11.686601, 166.89521, 902],
  [-11.686662, 166.89507, 903.6],
  [-11.686658, 166.89492, 905.6],
  [-11.686644, 166.894759, 907.2],
  [-11.68663, 166.894609, 908.8],
  [-11.686621, 166.894459, 910],
  [-11.686587, 166.894308, 911.8],
  [-11.686504, 166.89418, 913.6],
  [-11.686399, 166.894072, 915.2],
  [-11.686287, 166.893986, 916.4],
  [-11.686167, 166.89389, 918],
  [-11.686043, 166.893804, 919.8],
  [-11.685917, 166.893729, 921.4],
  [-11.685788, 166.893665, 923],
  [-11.685656, 166.8936, 924.6],
  [-11.685521, 166.893547, 926.2],
  [-11.685382, 166.893482, 927.8],
  [-11.685241, 166.89345, 929.2],
  [-11.685097, 166.893418, 930.6],
  [-11.684959, 166.893375, 932.2],
  [-11.684817, 166.893343, 933.6],
  [-11.684672, 166.8933, 934.8],
  [-11.684526, 166.893289, 936.2],
  [-11.684381, 166.893268, 937.8],
  [-11.684231, 166.893257, 939.2],
  [-11.684087, 166.893203, 940.6],
  [-11.684011, 166.893085, 941.8],
  [-11.684087, 166.892967, 943],
  [-11.684228, 166.892903, 944.6],
  [-11.684373, 166.89286, 946.2],
  [-11.684507, 166.892796, 947.6],
  [-11.684643, 166.892731, 949],
  [-11.684781, 166.892656, 950.4],
  [-11.684918, 166.892602, 952.2],
  [-11.685057, 166.892538, 953.8],
  [-11.685194, 166.892495, 955],
  [-11.685337, 166.892431, 956.4],
  [-11.685471, 166.892366, 958],
  [-11.685614, 166.892323, 959.4],
  [-11.685765, 166.892281, 961],
  [-11.685902, 166.892248, 962.6],
  [-11.686048, 166.892216, 964.4],
  [-11.686194, 166.892184, 966],
  [-11.686339, 166.892152, 967.4],
  [-11.68648, 166.89212, 968.8],
  [-11.686627, 166.892109, 970],
  [-11.686782, 166.892077, 971.4],
  [-11.686924, 166.892045, 972.8],
  [-11.687074, 166.892034, 974.6],
  [-11.687223, 166.892012, 976.4],
  [-11.687369, 166.892012, 978],
  [-11.687514, 166.892034, 979.8],
  [-11.687656, 166.892066, 981.6],
  [-11.687798, 166.892109, 983.2],
  [-11.687931, 166.892152, 984.6],
  [-11.68806, 166.892227, 985.6],
  [-11.688178, 166.892323, 986.2],
  [-11.688292, 166.892409, 986.8],
  [-11.688422, 166.892495, 988],
  [-11.688406, 166.892356, 989.4],
  [-11.688281, 166.892259, 990.8],
  [-11.688158, 166.892173, 992.4],
  [-11.688054, 166.892066, 993.8],
  [-11.687958, 166.891927, 995.4],
  [-11.687857, 166.891819, 996.8],
  [-11.687741, 166.891712, 998.2],
  [-11.687624, 166.891605, 999.4],
  [-11.687503, 166.891519, 1000.6],
  [-11.687378, 166.891422, 1001.8],
  [-11.687236, 166.891358, 1002.8],
  [-11.687083, 166.891315, 1004.2],
  [-11.68694, 166.891251, 1005.6],
  [-11.686798, 166.891197, 1007.2],
  [-11.686658, 166.891133, 1008.8],
  [-11.686511, 166.891068, 1010.4],
  [-11.686378, 166.891015, 1012],
  [-11.686245, 166.890961, 1013.4],
  [-11.686122, 166.890939, 1014.6],
  [-11.686002, 166.890918, 1015.6],
  [-11.685894, 166.890907, 1016.6],
  [-11.685795, 166.890875, 1017.6],
  [-11.685705, 166.890854, 1018.4],
  [-11.685623, 166.890843, 1019.2],
  [-11.685551, 166.890832, 1019.8],
  [-11.685485, 166.890811, 1020.4],
  [-11.685428, 166.8908, 1021],
  [-11.685376, 166.8908, 1021.6],
  [-11.685333, 166.890779, 1022],
  [-11.685297, 166.890768, 1022.4],
  [-11.685266, 166.890768, 1022.6],
  [-11.685243, 166.890746, 1022.8],
  [-11.685223, 166.890746, 1023],
  [-11.68521, 166.890746, 1023.2],
  [-11.685199, 166.890746, 1023.4],
  [-11.68519, 166.890746, 1023.4],
  [-11.685182, 166.890736, 1023.4],
  [-11.685175, 166.890736, 1023.6],
  [-11.685167, 166.890736, 1023.6],
  [-11.68516, 166.890736, 1023.8],
  [-11.685155, 166.890736, 1023.8],
  [-11.685148, 166.890736, 1023.8],
  [-11.685141, 166.890736, 1024],
  [-11.685133, 166.890736, 1024],
  [-11.685127, 166.890736, 1024],
  [-11.68512, 166.890725, 1024.2],
  [-11.685113, 166.890725, 1024.2],
  [-11.685107, 166.890725, 1024.2],
  [-11.6851, 166.890725, 1024.4],
  [-11.685093, 166.890725, 1024.4],
  [-11.685086, 166.890725, 1024.6],
  [-11.68508, 166.890725, 1024.6],
  [-11.685073, 166.890725, 1024.6],
  [-11.685066, 166.890725, 1024.8],
  [-11.68506, 166.890725, 1024.8],
  [-11.685054, 166.890703, 1024.8],
  [-11.685048, 166.890703, 1025],
  [-11.685041, 166.890703, 1025],
  [-11.685036, 166.890703, 1025],
  [-11.685029, 166.890703, 1025.2],
  [-11.685022, 166.890703, 1025.2],
  [-11.685015, 166.890703, 1025.2],
  [-11.68501, 166.890703, 1025.4],
  [-11.685003, 166.890703, 1025.4],
  [-11.684998, 166.890703, 1025.6],
  [-11.684993, 166.890703, 1025.6],
  [-11.684986, 166.890693, 1025.6],
  [-11.684981, 166.890693, 1025.8],
  [-11.684975, 166.890693, 1025.8],
  [-11.684968, 166.890693, 1025.8],
  [-11.684963, 166.890693, 1026],
  [-11.684956, 166.890693, 1026],
  [-11.684951, 166.890693, 1026],
  [-11.684944, 166.890693, 1026.2],
  [-11.684939, 166.890693, 1026.2],
  [-11.684932, 166.890693, 1026.4],
  [-11.684927, 166.890693, 1026.4],
  [-11.68492, 166.890693, 1026.4],
  [-11.684913, 166.890693, 1026.6],
  [-11.684907, 166.890693, 1026.6],
  [-11.684901, 166.890693, 1026.6],
  [-11.684895, 166.890671, 1026.8],
  [-11.684888, 166.890671, 1026.8],
  [-11.684883, 166.890671, 1026.8],
  [-11.684876, 166.890671, 1027],
  [-11.684869, 166.890671, 1027],
  [-11.684864, 166.890671, 1027],
  [-11.684857, 166.890671, 1027.2],
  [-11.68485, 166.890671, 1027.2],
  [-11.684844, 166.890671, 1027.4],
  [-11.684837, 166.890671, 1027.4],
  [-11.68483, 166.890671, 1027.4],
  [-11.684824, 166.890671, 1027.6],
  [-11.684817, 166.890671, 1027.6],
  [-11.684812, 166.890671, 1027.6],
  [-11.684805, 166.890671, 1027.8],
  [-11.684798, 166.890671, 1027.8],
  [-11.684791, 166.890671, 1027.8],
  [-11.684785, 166.890661, 1028],
  [-11.684778, 166.890661, 1028],
  [-11.684773, 166.890661, 1028],
  [-11.684766, 166.890661, 1028.2],
  [-11.684759, 166.890661, 1028.2],
  [-11.684753, 166.890661, 1028.4],
  [-11.684747, 166.890661, 1028.4],
  [-11.68474, 166.890661, 1028.4],
  [-11.684734, 166.890661, 1028.6],
  [-11.684727, 166.890661, 1028.6],
  [-11.68472, 166.890661, 1028.6],
  [-11.684714, 166.890661, 1028.8],
  [-11.684708, 166.890661, 1028.8],
  [-11.684702, 166.890661, 1028.8],
  [-11.684695, 166.890661, 1029],
  [-11.684688, 166.890661, 1029],
  [-11.684681, 166.890661, 1029],
  [-11.684675, 166.890661, 1029.2],
  [-11.684669, 166.890639, 1029.2],
  [-11.684663, 166.890639, 1029.4],
  [-11.684656, 166.890639, 1029.4],
  [-11.684649, 166.890639, 1029.4],
  [-11.684643, 166.890639, 1029.6],
  [-11.684636, 166.890639, 1029.6],
  [-11.684629, 166.890639, 1029.6],
  [-11.684622, 166.890639, 1029.8],
  [-11.684616, 166.890639, 1029.8],
  [-11.684609, 166.890639, 1029.8],
  [-11.684604, 166.890639, 1030],
  [-11.684597, 166.890639, 1030],
  [-11.68459, 166.890639, 1030],
  [-11.684584, 166.890639, 1030.2],
  [-11.684578, 166.890639, 1030.2],
  [-11.684572, 166.890639, 1030.4],
  [-11.684565, 166.890639, 1030.4],
  [-11.684558, 166.890639, 1030.4],
  [-11.684553, 166.890639, 1030.6],
  [-11.684546, 166.890639, 1030.6],
  [-11.684539, 166.890628, 1030.6],
  [-11.684533, 166.890628, 1030.8],
  [-11.684526, 166.890628, 1030.8],
  [-11.684521, 166.890628, 1030.8],
  [-11.684514, 166.890628, 1031],
  [-11.684507, 166.890628, 1031],
  [-11.6845, 166.890628, 1031.2],
  [-11.684494, 166.890628, 1031.2],
  [-11.684487, 166.890628, 1031.2],
  [-11.684482, 166.890628, 1031.4],
  [-11.684476, 166.890628, 1031.4],
  [-11.68447, 166.890628, 1031.4],
  [-11.684464, 166.890628, 1031.6],
  [-11.684458, 166.890628, 1031.6],
  [-11.684451, 166.890628, 1031.6],
  [-11.684444, 166.890628, 1031.8],
  [-11.684439, 166.890628, 1031.8],
  [-11.684432, 166.890628, 1031.8],
  [-11.684427, 166.890628, 1032],
  [-11.684421, 166.890618, 1032],
  [-11.684415, 166.890618, 1032.2],
  [-11.684408, 166.890618, 1032.2],
  [-11.684403, 166.890618, 1032.2],
  [-11.684397, 166.890618, 1032.4],
  [-11.684392, 166.890618, 1032.4],
  [-11.684385, 166.890618, 1032.4],
  [-11.684378, 166.890618, 1032.6],
  [-11.684373, 166.890618, 1032.6],
  [-11.684368, 166.890618, 1032.6],
  [-11.684362, 166.890618, 1032.8],
  [-11.684356, 166.890618, 1032.8],
  [-11.68435, 166.890618, 1033],
  [-11.684345, 166.890618, 1033],
  [-11.684338, 166.890618, 1033],
  [-11.684333, 166.890618, 1033.2],
  [-11.684326, 166.890618, 1033.2],
  [-11.684321, 166.890618, 1033.2],
  [-11.684314, 166.890618, 1033.4],
  [-11.684309, 166.890618, 1033.4],
  [-11.684302, 166.890596, 1033.4],
  [-11.684297, 166.890596, 1033.6],
  [-11.68429, 166.890596, 1033.6],
  [-11.684285, 166.890596, 1033.8],
  [-11.684278, 166.890596, 1033.8],
  [-11.684271, 166.890596, 1033.8],
  [-11.684266, 166.890596, 1034],
  [-11.684259, 166.890596, 1034],
  [-11.684254, 166.890596, 1034],
  [-11.684247, 166.890596, 1034.2],
  [-11.68424, 166.890596, 1034.2],
  [-11.684234, 166.890596, 1034.2],
  [-11.684227, 166.890596, 1034.4],
  [-11.68422, 166.890596, 1034.4],
  [-11.684213, 166.890596, 1034.4],
  [-11.684207, 166.890596, 1034.6],
  [-11.6842, 166.890596, 1034.6],
  [-11.684193, 166.890596, 1034.8],
  [-11.684187, 166.890596, 1034.8],
  [-11.68418, 166.890596, 1034.8],
  [-11.684173, 166.890596, 1035],
  [-11.684166, 166.890596, 1035],
  [-11.684158, 166.890596, 1035],
  [-11.684152, 166.890596, 1035.2],
  [-11.684145, 166.890596, 1035.2],
  [-11.684137, 166.890596, 1035.2],
  [-11.68413, 166.890596, 1035.4],
  [-11.684124, 166.890596, 1035.4],
  [-11.684116, 166.890596, 1035.4],
  [-11.684107, 166.890585, 1035.6],
  [-11.684101, 166.890585, 1035.6],
  [-11.684093, 166.890585, 1035.6],
  [-11.684086, 166.890585, 1035.8],
  [-11.684078, 166.890585, 1035.8],
  [-11.68407, 166.890585, 1035.8],
  [-11.684062, 166.890585, 1036],
  [-11.684054, 166.890585, 1036],
  [-11.684046, 166.890585, 1036],
  [-11.684038, 166.890585, 1036.2],
  [-11.684028, 166.890585, 1036.2],
  [-11.68402, 166.890585, 1036.2],
  [-11.684011, 166.890585, 1036.4],
  [-11.684002, 166.890585, 1036.4],
  [-11.683991, 166.890585, 1036.4],
  [-11.68398, 166.890585, 1036.6],
  [-11.683969, 166.890585, 1036.6],
  [-11.683959, 166.890585, 1036.6],
  [-11.683948, 166.890564, 1036.8],
  [-11.683936, 166.890564, 1036.8],
  [-11.683926, 166.890564, 1036.8],
  [-11.683916, 166.890564, 1037],
  [-11.683906, 166.890564, 1037],
  [-11.683898, 166.890564, 1037],
  [-11.683889, 166.890564, 1037.2],
  [-11.683879, 166.890564, 1037.2],
  [-11.683871, 166.890564, 1037.4],
  [-11.683863, 166.890553, 1037.4],
  [-11.683855, 166.890553, 1037.4],
  [-11.683847, 166.890553, 1037.6],
  [-11.683841, 166.890553, 1037.6],
  [-11.683831, 166.890553, 1037.6],
  [-11.683825, 166.890553, 1037.8],
  [-11.683815, 166.890553, 1037.8],
  [-11.683807, 166.890553, 1037.8],
  [-11.6838, 166.890553, 1038],
  [-11.683791, 166.890553, 1038],
  [-11.683784, 166.890543, 1038],
  [-11.683776, 166.890543, 1038.2],
  [-11.683768, 166.890543, 1038.2],
  [-11.68376, 166.890543, 1038.2],
  [-11.683751, 166.890543, 1038.4],
  [-11.683744, 166.890543, 1038.4],
  [-11.683736, 166.890543, 1038.6],
  [-11.683728, 166.890543, 1038.6],
  [-11.68372, 166.890543, 1038.6],
  [-11.683712, 166.890543, 1038.8],
  [-11.683704, 166.890543, 1038.8],
  [-11.683696, 166.890543, 1038.8],
  [-11.683689, 166.890521, 1039],
  [-11.683681, 166.890521, 1039],
  [-11.683672, 166.890521, 1039],
  [-11.683665, 166.890521, 1039.2],
  [-11.683657, 166.890521, 1039.2],
  [-11.683649, 166.890521, 1039.2],
  [-11.683641, 166.890521, 1039.4],
  [-11.683633, 166.890521, 1039.4],
  [-11.683626, 166.890521, 1039.4],
  [-11.683618, 166.890521, 1039.6],
  [-11.68361, 166.890521, 1039.6],
  [-11.683603, 166.890521, 1039.6],
  [-11.683594, 166.89051, 1039.8],
  [-11.683587, 166.89051, 1039.8],
  [-11.683579, 166.89051, 1040],
  [-11.683571, 166.89051, 1040],
  [-11.683563, 166.89051, 1040],
  [-11.683556, 166.89051, 1040.2],
  [-11.68355, 166.89051, 1040.2],
  [-11.683542, 166.89051, 1040.2],
  [-11.683533, 166.89051, 1040.4],
  [-11.683527, 166.890489, 1040.4],
  [-11.683519, 166.890489, 1040.4],
  [-11.683512, 166.890489, 1040.6],
  [-11.683504, 166.890489, 1040.6],
  [-11.683497, 166.890489, 1040.6],
  [-11.683489, 166.890489, 1040.8],
  [-11.683483, 166.890489, 1040.8],
  [-11.683474, 166.890489, 1040.8],
  [-11.683468, 166.890489, 1041],
  [-11.683461, 166.890478, 1041],
  [-11.683453, 166.890478, 1041.2],
  [-11.683446, 166.890478, 1041.2],
  [-11.683438, 166.890478, 1041.2],
  [-11.683432, 166.890478, 1041.4],
  [-11.683425, 166.890478, 1041.4],
  [-11.683418, 166.890478, 1041.4],
  [-11.683411, 166.890457, 1041.6],
  [-11.683405, 166.890457, 1041.6],
  [-11.683398, 166.890457, 1041.6],
  [-11.683391, 166.890457, 1041.8],
  [-11.683385, 166.890457, 1041.8],
  [-11.683378, 166.890457, 1041.8],
  [-11.683371, 166.890446, 1042],
  [-11.683365, 166.890446, 1042],
  [-11.683359, 166.890446, 1042.2],
  [-11.683352, 166.890446, 1042.2],
  [-11.683347, 166.890446, 1042.2],
  [-11.683342, 166.890446, 1042.4],
  [-11.683335, 166.890435, 1042.4],
  [-11.68333, 166.890435, 1042.4],
  [-11.683326, 166.890435, 1042.6],
  [-11.683319, 166.890435, 1042.6],
  [-11.683314, 166.890435, 1042.6],
  [-11.68331, 166.890435, 1042.8],
  [-11.683303, 166.890435, 1042.8],
  [-11.683297, 166.890414, 1043],
  [-11.683292, 166.890414, 1043],
  [-11.683287, 166.890414, 1043],
  [-11.683283, 166.890414, 1043.2],
  [-11.683277, 166.890414, 1043.2],
  [-11.683272, 166.890403, 1043.2],
  [-11.683267, 166.890403, 1043.4],
  [-11.683263, 166.890403, 1043.4],
  [-11.683256, 166.890403, 1043.6],
  [-11.683252, 166.890403, 1043.6],
  [-11.683246, 166.890403, 1043.6],
  [-11.683241, 166.890382, 1043.8],
  [-11.683236, 166.890382, 1043.8],
  [-11.683232, 166.890382, 1043.8],
  [-11.683226, 166.890382, 1044],
  [-11.683221, 166.890382, 1044],
  [-11.683217, 166.890382, 1044],
  [-11.683212, 166.890371, 1044.2],
  [-11.683206, 166.890371, 1044.2],
  [-11.683201, 166.890371, 1044.4],
  [-11.683197, 166.890371, 1044.4],
  [-11.683192, 166.890371, 1044.4],
  [-11.683186, 166.890349, 1044.6],
  [-11.683181, 166.890349, 1044.6],
  [-11.683175, 166.890349, 1044.6],
  [-11.68317, 166.890349, 1044.8],
  [-11.683161, 166.890349, 1044.8],
  [-11.683149, 166.890339, 1045],
  [-11.683134, 166.890339, 1045.2],
  [-11.683118, 166.890328, 1045.4],
  [-11.683103, 166.890306, 1045.6],
  [-11.683086, 166.890306, 1045.8],
  [-11.683068, 166.890296, 1046],
  [-11.683049, 166.890296, 1046.2],
  [-11.683031, 166.890274, 1046.4],
  [-11.683009, 166.890274, 1046.4],
  [-11.682985, 166.890264, 1046.6],
  [-11.682961, 166.890253, 1046.8],
  [-11.682934, 166.890253, 1046.8],
  [-11.682905, 166.890231, 1047],
  [-11.682872, 166.890231, 1047],
  [-11.682839, 166.890221, 1047.2],
  [-11.682804, 166.890199, 1047.2],
  [-11.682769, 166.890199, 1047.2],
  [-11.682734, 166.890188, 1047.2],
  [-11.682698, 166.890188, 1047.2],
  [-11.68266, 166.890167, 1047.2],
  [-11.682623, 166.890167, 1047.2],
  [-11.682583, 166.890156, 1047.2],
  [-11.682544, 166.890146, 1047],
  [-11.682502, 166.890146, 1047],
  [-11.682461, 166.890124, 1047],
  [-11.682416, 166.890113, 1047],
  [-11.682373, 166.890113, 1047],
  [-11.682331, 166.890092, 1047],
  [-11.682285, 166.890081, 1047],
  [-11.682242, 166.890081, 1047],
  [-11.682195, 166.89006, 1047],
  [-11.682148, 166.890049, 1047],
  [-11.682103, 166.890049, 1047],
  [-11.682054, 166.890038, 1047],
  [-11.681858, 166.889952, 1046.8],
  [-11.681695, 166.889899, 1046.8],
  [-11.681573, 166.889791, 1046.8],
  [-11.681537, 166.88962, 1046.8],
];

export default stream;
