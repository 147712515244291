import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.659281, 166.976706, 28],
  [-11.659125, 166.976599, 28],
  [-11.658977, 166.976459, 28.6],
  [-11.65884, 166.976341, 29.4],
  [-11.6587, 166.976202, 30.2],
  [-11.658558, 166.976084, 31],
  [-11.658393, 166.975987, 31.4],
  [-11.658213, 166.97603, 32.4],
  [-11.658125, 166.97618, 33],
  [-11.658044, 166.976352, 33.8],
  [-11.657893, 166.976459, 34.4],
  [-11.657721, 166.976502, 35.2],
  [-11.65754, 166.976523, 35.8],
  [-11.657375, 166.976448, 36.2],
  [-11.657332, 166.976277, 36.6],
  [-11.65743, 166.976126, 37.6],
  [-11.657559, 166.975987, 38.6],
  [-11.65752, 166.975815, 37.6],
  [-11.657464, 166.975633, 37.2],
  [-11.657492, 166.975451, 38.4],
  [-11.657465, 166.975268, 39.4],
  [-11.657332, 166.97515, 38.8],
  [-11.657153, 166.975086, 38.6],
  [-11.656962, 166.975086, 39.4],
  [-11.65677, 166.975096, 40.2],
  [-11.656587, 166.975129, 41],
  [-11.656403, 166.97515, 41.8],
  [-11.656223, 166.975096, 41.8],
  [-11.656082, 166.974978, 40.6],
  [-11.655987, 166.974796, 39.8],
  [-11.655968, 166.974614, 40.8],
  [-11.655942, 166.97441, 42],
  [-11.655801, 166.974292, 41.2],
  [-11.655625, 166.974206, 40],
  [-11.655451, 166.974109, 39],
  [-11.655347, 166.973927, 39.4],
  [-11.655309, 166.973745, 41],
  [-11.655239, 166.973562, 41.8],
  [-11.65509, 166.973423, 40.8],
  [-11.654901, 166.973412, 41],
  [-11.654736, 166.973498, 42.4],
  [-11.654673, 166.97368, 41.6],
  [-11.654731, 166.973863, 40.4],
  [-11.654863, 166.974024, 38.6],
  [-11.655, 166.974185, 37.2],
  [-11.655054, 166.974388, 36.6],
  [-11.654989, 166.974581, 37.6],
  [-11.6549, 166.974764, 38.8],
  [-11.654952, 166.974946, 38.8],
  [-11.655133, 166.975043, 37.8],
  [-11.655286, 166.975182, 37.2],
  [-11.655283, 166.975375, 37.8],
  [-11.655183, 166.975547, 39],
  [-11.655046, 166.975697, 38.6],
  [-11.654873, 166.975805, 37.4],
  [-11.654693, 166.97589, 38],
  [-11.654512, 166.975987, 39],
  [-11.654319, 166.975987, 39.2],
  [-11.654132, 166.975912, 38.6],
  [-11.653955, 166.975815, 37.8],
  [-11.653804, 166.975687, 36.8],
  [-11.653742, 166.975483, 36],
  [-11.653777, 166.97529, 35.2],
  [-11.653836, 166.975075, 34.4],
  [-11.653835, 166.974871, 34.2],
  [-11.653691, 166.974721, 35.2],
  [-11.653493, 166.974753, 35.4],
  [-11.653352, 166.974903, 34.6],
  [-11.653247, 166.975075, 34],
  [-11.653145, 166.975257, 33.2],
  [-11.653057, 166.975451, 32.4],
  [-11.652987, 166.975654, 31.4],
  [-11.652971, 166.975869, 30.4],
  [-11.653048, 166.976062, 29.6],
  [-11.653167, 166.976244, 28.6],
  [-11.653285, 166.976427, 28],
  [-11.653339, 166.976641, 27.2],
  [-11.653334, 166.976856, 26.8],
  [-11.653302, 166.977071, 26.4],
  [-11.653255, 166.977285, 26.2],
  [-11.653166, 166.977478, 26],
  [-11.6531, 166.977682, 25.8],
  [-11.653102, 166.977897, 25.4],
  [-11.653131, 166.978111, 25.2],
  [-11.65328, 166.978219, 25.6],
  [-11.653474, 166.978154, 26.6],
  [-11.65366, 166.978058, 27.2],
  [-11.653852, 166.978036, 28],
  [-11.654051, 166.978047, 28.6],
  [-11.65424, 166.978111, 29],
  [-11.654364, 166.97824, 29.6],
  [-11.654476, 166.978401, 30.2],
  [-11.654602, 166.978551, 30.8],
  [-11.654617, 166.978734, 31.2],
  [-11.654555, 166.978916, 31.4],
  [-11.654474, 166.979088, 31.6],
  [-11.654381, 166.979249, 31.8],
  [-11.654269, 166.97942, 31.8],
  [-11.654126, 166.979538, 31.8],
  [-11.653939, 166.97957, 31.8],
  [-11.653749, 166.979527, 31.6],
  [-11.653572, 166.979452, 31.2],
  [-11.653394, 166.979356, 31],
  [-11.653238, 166.979238, 31],
  [-11.653108, 166.979088, 31],
  [-11.652915, 166.979023, 30.8],
  [-11.652722, 166.978991, 30.4],
  [-11.652541, 166.979066, 30.6],
  [-11.652512, 166.97927, 31.2],
  [-11.652539, 166.979452, 32],
  [-11.652575, 166.979645, 32.6],
  [-11.652616, 166.979849, 33.4],
  [-11.652656, 166.980032, 34],
  [-11.652684, 166.980214, 34.6],
  [-11.652677, 166.980407, 35.2],
  [-11.652578, 166.980568, 35.4],
  [-11.652401, 166.980622, 35.2],
  [-11.652209, 166.980654, 35],
  [-11.652019, 166.980686, 34.8],
  [-11.651828, 166.980675, 34.6],
  [-11.65165, 166.98059, 34.6],
  [-11.651509, 166.980461, 34.6],
  [-11.651389, 166.980289, 34.8],
  [-11.651275, 166.980139, 34.8],
  [-11.651146, 166.979989, 35],
  [-11.650994, 166.97986, 35.2],
  [-11.650824, 166.979774, 35.6],
  [-11.65064, 166.979699, 36],
  [-11.650455, 166.979635, 36.6],
  [-11.650271, 166.979592, 37],
  [-11.65008, 166.97956, 37.6],
  [-11.649895, 166.979527, 38.2],
  [-11.649703, 166.979527, 38.8],
  [-11.649518, 166.97956, 39.6],
  [-11.649345, 166.979613, 40.2],
  [-11.64918, 166.97971, 41],
  [-11.649016, 166.979785, 41.8],
  [-11.648844, 166.97986, 42.6],
  [-11.648662, 166.97986, 43.4],
  [-11.648507, 166.979785, 44.2],
  [-11.648393, 166.979635, 44.8],
  [-11.648303, 166.979485, 45.2],
  [-11.648218, 166.979313, 45.8],
  [-11.648111, 166.979173, 46.4],
  [-11.647955, 166.979088, 47.2],
  [-11.64778, 166.979034, 48],
  [-11.647604, 166.979023, 48.8],
  [-11.647426, 166.979012, 49.6],
  [-11.647257, 166.978948, 50.4],
  [-11.647112, 166.978841, 51],
  [-11.646976, 166.978723, 51.6],
  [-11.646834, 166.978616, 52.2],
  [-11.646661, 166.978583, 52.6],
  [-11.646494, 166.978508, 53.2],
  [-11.646412, 166.978347, 53.2],
  [-11.6465, 166.978197, 53.6],
  [-11.646676, 166.978165, 54.2],
  [-11.646861, 166.978154, 54.8],
  [-11.647042, 166.978122, 55.2],
  [-11.647216, 166.978079, 55.8],
  [-11.647383, 166.977983, 56.4],
  [-11.647541, 166.977897, 56.8],
  [-11.647699, 166.9778, 57.4],
  [-11.647867, 166.977725, 58],
  [-11.64804, 166.977682, 58.4],
  [-11.64822, 166.977714, 59],
  [-11.648376, 166.9778, 59.6],
  [-11.648513, 166.977929, 60],
  [-11.64865, 166.978047, 60.4],
  [-11.648819, 166.978122, 61],
  [-11.649004, 166.978122, 61.8],
  [-11.649182, 166.978079, 62.4],
  [-11.649353, 166.978036, 63.2],
  [-11.649511, 166.977929, 63.8],
  [-11.6496, 166.977768, 64.4],
  [-11.649551, 166.977607, 64.6],
  [-11.649409, 166.977478, 64.8],
  [-11.649241, 166.977425, 65.4],
  [-11.649056, 166.977425, 66],
  [-11.648873, 166.977403, 66.8],
  [-11.648702, 166.97735, 67.4],
  [-11.648539, 166.977264, 68],
  [-11.648379, 166.977178, 68.4],
  [-11.648213, 166.977103, 69],
  [-11.648036, 166.97706, 69.6],
  [-11.647852, 166.977081, 70.4],
  [-11.647679, 166.977135, 71.2],
  [-11.647507, 166.977189, 72],
  [-11.647336, 166.977242, 72.6],
  [-11.647156, 166.977253, 73.2],
  [-11.646979, 166.977242, 73.4],
  [-11.646829, 166.977135, 73.8],
  [-11.646798, 166.976953, 74.2],
  [-11.646927, 166.976845, 75],
  [-11.647108, 166.976813, 75.8],
  [-11.64729, 166.976792, 76.4],
  [-11.647467, 166.976792, 77.2],
  [-11.647646, 166.976749, 78],
  [-11.647777, 166.976631, 78.6],
  [-11.647869, 166.97647, 79.4],
  [-11.647993, 166.976352, 80],
  [-11.648171, 166.976341, 80.6],
  [-11.648339, 166.976395, 81],
  [-11.648516, 166.976448, 81.2],
  [-11.648698, 166.976416, 81.4],
  [-11.64887, 166.976341, 81.6],
  [-11.649043, 166.976277, 81.6],
  [-11.649228, 166.976266, 81.8],
  [-11.649409, 166.976309, 82],
  [-11.649583, 166.976384, 82.2],
  [-11.649762, 166.976459, 82.6],
  [-11.649948, 166.976459, 82.4],
  [-11.650127, 166.976384, 81.8],
  [-11.650288, 166.976266, 80.8],
  [-11.650431, 166.976126, 79.8],
  [-11.650557, 166.975955, 78.8],
  [-11.650647, 166.975772, 77.8],
  [-11.650718, 166.975579, 76.8],
  [-11.650796, 166.975375, 75.6],
  [-11.650915, 166.975193, 74.6],
  [-11.651069, 166.975043, 73.4],
  [-11.651165, 166.974839, 72.2],
  [-11.651142, 166.974624, 71],
  [-11.651069, 166.97441, 70],
  [-11.650951, 166.974227, 68.6],
  [-11.650768, 166.974109, 67.4],
  [-11.650624, 166.973927, 66.2],
];

export default stream;
