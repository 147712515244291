import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.762875, 165.845436, 99.8],
  [-10.762965, 165.845371, 100.2],
  [-10.763051, 165.845329, 100.8],
  [-10.763139, 165.845264, 101.2],
  [-10.763228, 165.845221, 101.6],
  [-10.763319, 165.845189, 102],
  [-10.763411, 165.845157, 102.4],
  [-10.763508, 165.845146, 102.4],
  [-10.76361, 165.845125, 102.4],
  [-10.763711, 165.845125, 102.4],
];

export default stream;
