import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.791354, 165.840018, 66],
  [-10.791332, 165.839846, 66],
  [-10.791293, 165.839685, 66],
  [-10.791216, 165.839524, 66],
  [-10.7911, 165.839417, 66.2],
  [-10.790955, 165.839331, 66.6],
  [-10.790811, 165.839256, 66.8],
  [-10.790667, 165.839181, 66.8],
  [-10.790522, 165.839095, 66.8],
  [-10.790378, 165.839009, 66.4],
  [-10.790249, 165.838913, 66],
  [-10.790143, 165.838784, 65.8],
  [-10.790055, 165.838623, 66],
  [-10.789979, 165.838473, 66.2],
  [-10.789909, 165.838333, 66.2],
  [-10.789837, 165.838183, 66.4],
  [-10.789766, 165.838033, 66.2],
  [-10.789697, 165.837861, 66],
  [-10.789626, 165.837711, 66],
  [-10.789543, 165.837572, 66],
  [-10.789459, 165.837421, 66],
  [-10.789372, 165.837271, 66],
];

export default stream;
