import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.692896, 166.966599, 321.2],
  [-11.69288, 166.966546, 321.2],
  [-11.692862, 166.966481, 321.4],
  [-11.692841, 166.966417, 321.4],
  [-11.692814, 166.966363, 321.2],
  [-11.692782, 166.966299, 321],
  [-11.692744, 166.966234, 320.8],
  [-11.6927, 166.96617, 320.4],
  [-11.692649, 166.966095, 320],
  [-11.692595, 166.96602, 319.4],
  [-11.692535, 166.965945, 318.8],
  [-11.692471, 166.96587, 318.2],
  [-11.692404, 166.965795, 317.6],
  [-11.692334, 166.965698, 316.8],
  [-11.692268, 166.965612, 316],
  [-11.692214, 166.965505, 315.4],
  [-11.692176, 166.965387, 315],
  [-11.692162, 166.965258, 314.6],
  [-11.69218, 166.96514, 314.4],
  [-11.692204, 166.965011, 314.2],
  [-11.692228, 166.964893, 314.2],
  [-11.692252, 166.964775, 314.2],
  [-11.692275, 166.964647, 314.2],
  [-11.6923, 166.964529, 314.2],
  [-11.692324, 166.964421, 314.4],
  [-11.692347, 166.964303, 314.4],
  [-11.692371, 166.964196, 314.4],
  [-11.692394, 166.964089, 314.4],
  [-11.692417, 166.96396, 314.4],
  [-11.692436, 166.963853, 314.6],
  [-11.692455, 166.963745, 314.6],
  [-11.692469, 166.963638, 314.6],
  [-11.692442, 166.963531, 314.8],
  [-11.692383, 166.963445, 315],
  [-11.692316, 166.96337, 315.2],
  [-11.692243, 166.963305, 315.4],
  [-11.692168, 166.96323, 315.8],
  [-11.69209, 166.963166, 316],
  [-11.692015, 166.963112, 316.2],
  [-11.691937, 166.963048, 316.4],
  [-11.69186, 166.963005, 316.6],
  [-11.691784, 166.962941, 316.8],
  [-11.691708, 166.962876, 317],
  [-11.691632, 166.962833, 317.4],
  [-11.691557, 166.96279, 317.6],
  [-11.691482, 166.962737, 318],
  [-11.69141, 166.962694, 318.2],
  [-11.691337, 166.962651, 318.6],
  [-11.691265, 166.962608, 319],
  [-11.691197, 166.962554, 319.6],
  [-11.691127, 166.962533, 320],
  [-11.691057, 166.962501, 320.6],
  [-11.690987, 166.962501, 321.2],
  [-11.690922, 166.962501, 321.8],
  [-11.690861, 166.962512, 322.4],
  [-11.690809, 166.962544, 323],
  [-11.690762, 166.962576, 323.4],
  [-11.69072, 166.962608, 323.8],
  [-11.69068, 166.96264, 324.2],
  [-11.690643, 166.962683, 324.6],
  [-11.690608, 166.962715, 325],
  [-11.690573, 166.962758, 325.4],
  [-11.690539, 166.96279, 325.6],
  [-11.690506, 166.962823, 326],
  [-11.690474, 166.962866, 326.4],
  [-11.69044, 166.962898, 326.8],
  [-11.690407, 166.962941, 327],
  [-11.690372, 166.962973, 327.4],
  [-11.690336, 166.963005, 327.8],
  [-11.690298, 166.963037, 328],
  [-11.690258, 166.963059, 328.4],
  [-11.690215, 166.96308, 328.8],
  [-11.690171, 166.963091, 329],
  [-11.690124, 166.963091, 329.4],
  [-11.690077, 166.963091, 329.8],
  [-11.69003, 166.963091, 330.2],
  [-11.689984, 166.96308, 330.4],
  [-11.689941, 166.963059, 330.8],
  [-11.6899, 166.963048, 331.2],
  [-11.689858, 166.963037, 331.4],
  [-11.689819, 166.963005, 331.8],
  [-11.689782, 166.962973, 332],
  [-11.689743, 166.962941, 332.2],
  [-11.689705, 166.962909, 332.4],
  [-11.689668, 166.962876, 332.6],
  [-11.68963, 166.962833, 332.6],
  [-11.689591, 166.96279, 332.6],
  [-11.689552, 166.962737, 332.4],
  [-11.689516, 166.962683, 332],
  [-11.689485, 166.962619, 331.2],
  [-11.689463, 166.962533, 330],
  [-11.689446, 166.962436, 328.6],
  [-11.689432, 166.962318, 326.8],
  [-11.689416, 166.96219, 324.8],
  [-11.689387, 166.962072, 323.2],
  [-11.689328, 166.961954, 322.4],
  [-11.689235, 166.961846, 323],
  [-11.689133, 166.961761, 324.4],
  [-11.689033, 166.961707, 325.8],
  [-11.688941, 166.961643, 327.4],
  [-11.688862, 166.961578, 328.6],
  [-11.688801, 166.961524, 329.4],
  [-11.688764, 166.961449, 329.6],
  [-11.688748, 166.961353, 329],
  [-11.688753, 166.961267, 328],
  [-11.688783, 166.96116, 326.6],
  [-11.68883, 166.961052, 325.4],
  [-11.68889, 166.960956, 324.8],
  [-11.688957, 166.960849, 324.8],
  [-11.689036, 166.960763, 325],
  [-11.689123, 166.960677, 325.4],
  [-11.689209, 166.960602, 325.8],
  [-11.689294, 166.960548, 326.4],
  [-11.689373, 166.960473, 327],
  [-11.689445, 166.960409, 327.6],
  [-11.689508, 166.960334, 328.2],
  [-11.689564, 166.960258, 328.8],
  [-11.68961, 166.960183, 329.4],
  [-11.689641, 166.960098, 330],
  [-11.689661, 166.960022, 330.6],
  [-11.689669, 166.959969, 331.2],
  [-11.68967, 166.959894, 331.8],
  [-11.689668, 166.959808, 332.2],
  [-11.689664, 166.959754, 332.8],
  [-11.689657, 166.95969, 333.2],
  [-11.689649, 166.959625, 333.8],
  [-11.68964, 166.959572, 334.2],
  [-11.689629, 166.959518, 334.6],
  [-11.689618, 166.959465, 335],
  [-11.689606, 166.959411, 335.4],
  [-11.689594, 166.959357, 335.8],
  [-11.689581, 166.959304, 336.2],
  [-11.689568, 166.959261, 336.4],
  [-11.689554, 166.959218, 336.8],
  [-11.689538, 166.959175, 337.2],
  [-11.689523, 166.95911, 337.6],
  [-11.689508, 166.959068, 337.8],
  [-11.689491, 166.959014, 338.2],
  [-11.689473, 166.958971, 338.6],
  [-11.689456, 166.958928, 338.8],
  [-11.689437, 166.958885, 339.2],
  [-11.689417, 166.958853, 339.4],
  [-11.689397, 166.95881, 339.8],
  [-11.689375, 166.958756, 340],
  [-11.689353, 166.958714, 340.4],
  [-11.689327, 166.958671, 340.6],
  [-11.689302, 166.958617, 341],
  [-11.689275, 166.958574, 341.2],
  [-11.689244, 166.958531, 341.4],
  [-11.689212, 166.958488, 341.6],
  [-11.689177, 166.958456, 341.8],
  [-11.689139, 166.958413, 342],
  [-11.689099, 166.958359, 342.2],
  [-11.689056, 166.958317, 342.4],
  [-11.689011, 166.958284, 342.4],
  [-11.688962, 166.958241, 342.6],
  [-11.68891, 166.958199, 342.6],
  [-11.688855, 166.958145, 342.6],
  [-11.688799, 166.958123, 342.8],
  [-11.688737, 166.95807, 342.8],
  [-11.688674, 166.958038, 342.8],
  [-11.688607, 166.957995, 342.8],
  [-11.688538, 166.957963, 342.8],
  [-11.688465, 166.95793, 342.8],
  [-11.68839, 166.957887, 342.8],
  [-11.688312, 166.957855, 342.8],
  [-11.68823, 166.957844, 342.8],
  [-11.688146, 166.957812, 342.8],
  [-11.68806, 166.957802, 342.8],
  [-11.68797, 166.957802, 342.8],
  [-11.68788, 166.957802, 342.8],
  [-11.687789, 166.957812, 342.8],
  [-11.687699, 166.957834, 342.8],
  [-11.68761, 166.957855, 342.8],
  [-11.687521, 166.957877, 342.8],
  [-11.687432, 166.957909, 342.8],
  [-11.68734, 166.95793, 342.6],
  [-11.687244, 166.957963, 342.2],
  [-11.687145, 166.957984, 341.8],
  [-11.687043, 166.957995, 341.4],
  [-11.686935, 166.958016, 340.8],
  [-11.686823, 166.958016, 340],
  [-11.686707, 166.958016, 339.2],
  [-11.686586, 166.958016, 338.2],
  [-11.686461, 166.957995, 337.2],
  [-11.68633, 166.957984, 336.2],
  [-11.686197, 166.957963, 335],
  [-11.686059, 166.957952, 333.6],
  [-11.685917, 166.95793, 332.4],
  [-11.685769, 166.95792, 331],
  [-11.685618, 166.95792, 329.4],
  [-11.685463, 166.95792, 327.8],
  [-11.685305, 166.95793, 326.2],
  [-11.685148, 166.957984, 324.6],
  [-11.685009, 166.95807, 322.8],
  [-11.6849, 166.958199, 321],
  [-11.684817, 166.958349, 319.4],
  [-11.684754, 166.95852, 318],
  [-11.684695, 166.958681, 316.8],
  [-11.684633, 166.958832, 315.6],
  [-11.684549, 166.958992, 314.6],
  [-11.684424, 166.95911, 314.2],
  [-11.684267, 166.959153, 314.2],
  [-11.684107, 166.959175, 314.8],
  [-11.683955, 166.959153, 315.6],
  [-11.683812, 166.959121, 316.6],
  [-11.683678, 166.95911, 317.6],
  [-11.683552, 166.959078, 318.4],
  [-11.683432, 166.959068, 319.4],
  [-11.683319, 166.959068, 320.2],
  [-11.683212, 166.959068, 321],
  [-11.683112, 166.9591, 321.8],
  [-11.683019, 166.959121, 322.4],
  [-11.682931, 166.959143, 323],
  [-11.682848, 166.959175, 323.6],
  [-11.68277, 166.959207, 324.2],
  [-11.682697, 166.959229, 324.8],
  [-11.682628, 166.959282, 325.4],
  [-11.682563, 166.959304, 326],
  [-11.682501, 166.959336, 326.4],
  [-11.682445, 166.959368, 327],
  [-11.68239, 166.9594, 327.4],
  [-11.682339, 166.959443, 327.8],
  [-11.68229, 166.959475, 328.4],
  [-11.682246, 166.959507, 328.8],
  [-11.682203, 166.95955, 329.2],
  [-11.682164, 166.959583, 329.6],
  [-11.682128, 166.959625, 330],
  [-11.682094, 166.959658, 330.4],
  [-11.682065, 166.959701, 330.8],
  [-11.682037, 166.959733, 331.2],
  [-11.682011, 166.959786, 331.6],
  [-11.681987, 166.959829, 332],
  [-11.681966, 166.959862, 332.4],
  [-11.681944, 166.959904, 332.8],
  [-11.681924, 166.959947, 333],
  [-11.681905, 166.95998, 333.4],
  [-11.681888, 166.960022, 333.8],
  [-11.681871, 166.960076, 334],
  [-11.681854, 166.960119, 334.4],
  [-11.68184, 166.960162, 334.8],
  [-11.681824, 166.960194, 335],
  [-11.68181, 166.960237, 335.4],
  [-11.681797, 166.960291, 335.8],
  [-11.681783, 166.960334, 336],
  [-11.68177, 166.960377, 336.4],
  [-11.681758, 166.960419, 336.8],
  [-11.681744, 166.960452, 337],
  [-11.681732, 166.960495, 337.4],
  [-11.681722, 166.960548, 337.8],
  [-11.681708, 166.960591, 338],
  [-11.681698, 166.960634, 338.4],
  [-11.681687, 166.960677, 338.8],
  [-11.681676, 166.960731, 339],
  [-11.681668, 166.960773, 339.4],
  [-11.681664, 166.960816, 339.8],
  [-11.681661, 166.96087, 340.2],
  [-11.681664, 166.960913, 340.4],
  [-11.681668, 166.960945, 340.8],
  [-11.681673, 166.960988, 341],
  [-11.68168, 166.961031, 341.4],
  [-11.681688, 166.961074, 341.6],
  [-11.681695, 166.961128, 342],
  [-11.681703, 166.96117, 342.4],
  [-11.681708, 166.961213, 342.6],
  [-11.681708, 166.961278, 343],
  [-11.681703, 166.961321, 343.2],
  [-11.681692, 166.961364, 343.6],
  [-11.681675, 166.961417, 344],
  [-11.681653, 166.961449, 344.2],
  [-11.681629, 166.961492, 344.6],
  [-11.681602, 166.961524, 345],
  [-11.681571, 166.961567, 345.2],
  [-11.681539, 166.9616, 345.4],
  [-11.681506, 166.961632, 345.8],
  [-11.68147, 166.961675, 346],
  [-11.681431, 166.961707, 346.4],
  [-11.68139, 166.961739, 346.6],
  [-11.681349, 166.96175, 347],
  [-11.681306, 166.961782, 347.2],
  [-11.681262, 166.961814, 347.6],
  [-11.681216, 166.961825, 348],
  [-11.681169, 166.961846, 348.2],
  [-11.681121, 166.961857, 348.6],
  [-11.681073, 166.961868, 348.8],
  [-11.681023, 166.961889, 349.2],
  [-11.680975, 166.961889, 349.6],
  [-11.680925, 166.961889, 349.8],
  [-11.680875, 166.961889, 350.2],
  [-11.680826, 166.961889, 350.6],
  [-11.680776, 166.961889, 350.8],
  [-11.680728, 166.961868, 351.2],
  [-11.680678, 166.961868, 351.6],
  [-11.680629, 166.961868, 351.8],
  [-11.68058, 166.961857, 352.2],
  [-11.680532, 166.961857, 352.4],
  [-11.680482, 166.961846, 352.8],
  [-11.680434, 166.961846, 353.2],
  [-11.680385, 166.961846, 353.4],
  [-11.680335, 166.961825, 353.8],
  [-11.680285, 166.961825, 354],
  [-11.680236, 166.961825, 354.4],
  [-11.680186, 166.961825, 354.8],
  [-11.680136, 166.961825, 355],
  [-11.680087, 166.961825, 355.4],
  [-11.680037, 166.961846, 355.6],
  [-11.679989, 166.961846, 356],
  [-11.679942, 166.961857, 356.4],
  [-11.679894, 166.961868, 356.6],
  [-11.679845, 166.961889, 357],
  [-11.679799, 166.9619, 357.2],
  [-11.67975, 166.961921, 357.4],
  [-11.679702, 166.961932, 357.8],
  [-11.679655, 166.961964, 358],
  [-11.679605, 166.961975, 358.4],
  [-11.679557, 166.961997, 358.6],
  [-11.679509, 166.962007, 359],
  [-11.679459, 166.962029, 359.2],
  [-11.679408, 166.96205, 359.6],
  [-11.679357, 166.962072, 359.8],
  [-11.679306, 166.962072, 360.2],
  [-11.679254, 166.962082, 360.4],
  [-11.679202, 166.962104, 360.8],
  [-11.679149, 166.962104, 361.2],
  [-11.679097, 166.962104, 361.4],
  [-11.679046, 166.962104, 361.8],
  [-11.678994, 166.962104, 362.2],
  [-11.678944, 166.962082, 362.4],
  [-11.678895, 166.962072, 362.8],
  [-11.678848, 166.96205, 363.2],
  [-11.678803, 166.962029, 363.4],
  [-11.678758, 166.961997, 363.8],
  [-11.678716, 166.961975, 364.2],
  [-11.678675, 166.961954, 364.6],
  [-11.678636, 166.961921, 364.8],
  [-11.678597, 166.961889, 365.2],
  [-11.678558, 166.961857, 365.4],
  [-11.678522, 166.961825, 365.8],
  [-11.678486, 166.961782, 366.2],
  [-11.678449, 166.96175, 366.4],
  [-11.678414, 166.961718, 366.8],
  [-11.67838, 166.961685, 367],
  [-11.678346, 166.961643, 367.4],
  [-11.678311, 166.96161, 367.6],
  [-11.678278, 166.961567, 368],
  [-11.678244, 166.961535, 368.2],
  [-11.678211, 166.961492, 368.6],
  [-11.678177, 166.96146, 368.8],
  [-11.678142, 166.961417, 369.2],
  [-11.678109, 166.961385, 369.4],
  [-11.678075, 166.961342, 369.8],
  [-11.67804, 166.961288, 370],
  [-11.678005, 166.961267, 370.2],
  [-11.677971, 166.961213, 370.4],
  [-11.677934, 166.96117, 370.8],
  [-11.677898, 166.961128, 371],
  [-11.677862, 166.961074, 371.2],
  [-11.677823, 166.961031, 371.4],
  [-11.677786, 166.960988, 371.6],
  [-11.677745, 166.960945, 371.8],
  [-11.677705, 166.960891, 372],
  [-11.677666, 166.960849, 372],
  [-11.677626, 166.960806, 372.2],
  [-11.677586, 166.960741, 372.4],
  [-11.677545, 166.960677, 372.4],
  [-11.677504, 166.960634, 372.4],
  [-11.677464, 166.96058, 372.6],
  [-11.677423, 166.960516, 372.6],
  [-11.677382, 166.960441, 372.6],
  [-11.677342, 166.960377, 372.8],
  [-11.677301, 166.960312, 372.8],
  [-11.677261, 166.960237, 372.8],
  [-11.677221, 166.960162, 372.8],
  [-11.677182, 166.960098, 373],
  [-11.677142, 166.960022, 373],
  [-11.677104, 166.959947, 373],
  [-11.677067, 166.959862, 373],
  [-11.67703, 166.959786, 373],
  [-11.676994, 166.959701, 373],
  [-11.676958, 166.959615, 373],
  [-11.676922, 166.95954, 373],
  [-11.676887, 166.959443, 373],
  [-11.676852, 166.959368, 373],
  [-11.676817, 166.959282, 373],
  [-11.676782, 166.959186, 373],
  [-11.676748, 166.9591, 373],
  [-11.676714, 166.959003, 373],
  [-11.67668, 166.958917, 373],
  [-11.676646, 166.958821, 373],
  [-11.676612, 166.958724, 373],
  [-11.676579, 166.958638, 373],
  [-11.676548, 166.958542, 373],
  [-11.676548, 166.958435, 373],
];

export default stream;
