import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.718704, 166.966846, 13.6],
  [-11.71878, 166.966943, 13.6],
  [-11.718873, 166.966985, 13.6],
  [-11.718982, 166.967028, 13.6],
  [-11.719092, 166.967061, 13.6],
  [-11.719203, 166.967093, 13.6],
  [-11.719311, 166.967125, 13.6],
  [-11.719417, 166.967157, 13.6],
  [-11.719525, 166.967179, 13.6],
  [-11.719632, 166.967211, 13.6],
  [-11.719741, 166.967254, 13.6],
  [-11.719847, 166.967286, 13.6],
  [-11.719955, 166.967318, 13.4],
  [-11.720068, 166.96735, 13.4],
  [-11.72017, 166.967382, 13.2],
  [-11.720268, 166.967458, 13],
  [-11.720336, 166.967543, 13],
  [-11.720368, 166.967651, 12.8],
  [-11.720374, 166.967779, 12.8],
  [-11.720354, 166.967887, 12.8],
  [-11.720304, 166.968005, 12.8],
  [-11.720229, 166.96808, 12.8],
  [-11.720146, 166.968176, 12.8],
  [-11.720062, 166.968251, 12.6],
  [-11.719973, 166.968337, 12.6],
  [-11.719892, 166.968423, 12.6],
  [-11.719806, 166.968498, 12.6],
  [-11.719723, 166.968573, 12.6],
  [-11.719636, 166.968659, 12.6],
  [-11.719553, 166.968734, 12.6],
  [-11.719467, 166.96882, 12.6],
  [-11.719381, 166.968895, 12.6],
  [-11.719295, 166.96897, 12.6],
  [-11.719212, 166.969045, 12.6],
  [-11.719129, 166.969121, 12.6],
  [-11.719046, 166.969196, 12.6],
  [-11.718959, 166.969292, 12.4],
  [-11.718878, 166.969367, 12.4],
  [-11.718783, 166.969421, 12.4],
  [-11.718672, 166.969442, 12.4],
  [-11.718571, 166.969399, 12.4],
  [-11.718508, 166.969303, 12.4],
  [-11.718485, 166.969196, 12.4],
  [-11.718483, 166.969078, 12.4],
  [-11.718485, 166.968949, 12.4],
  [-11.718487, 166.968842, 12.4],
  [-11.718487, 166.968724, 12.4],
  [-11.718487, 166.968616, 12.4],
  [-11.718487, 166.968498, 12.4],
  [-11.718464, 166.968391, 12.4],
  [-11.718374, 166.968316, 12.4],
  [-11.718269, 166.968316, 12.2],
  [-11.718153, 166.968316, 11.8],
  [-11.718032, 166.968316, 11.4],
  [-11.717911, 166.968316, 11],
  [-11.717788, 166.968316, 10.6],
  [-11.717662, 166.968316, 10.2],
  [-11.717535, 166.968316, 9.8],
  [-11.717415, 166.968316, 9.4],
  [-11.717285, 166.968316, 9],
  [-11.717152, 166.968316, 8.6],
  [-11.717024, 166.968327, 8.2],
  [-11.716931, 166.968402, 8.2],
  [-11.716903, 166.968541, 8.4],
  [-11.716904, 166.968659, 8.8],
  [-11.716907, 166.968799, 9],
  [-11.716908, 166.968917, 9.4],
  [-11.716908, 166.969024, 9.6],
  [-11.716908, 166.969153, 10],
  [-11.716908, 166.969271, 10.2],
  [-11.716908, 166.969378, 10.4],
  [-11.716908, 166.969485, 10.8],
  [-11.716908, 166.969593, 11],
  [-11.716908, 166.9697, 11.2],
  [-11.716908, 166.969807, 11.6],
  [-11.716908, 166.969914, 11.8],
  [-11.716908, 166.970022, 12],
  [-11.716908, 166.970129, 12.2],
  [-11.716899, 166.970215, 12.4],
  [-11.716857, 166.970311, 12.4],
  [-11.716782, 166.970387, 12.2],
  [-11.716692, 166.970429, 12.2],
  [-11.716585, 166.970429, 12.6],
  [-11.716486, 166.970429, 12.8],
  [-11.716387, 166.970429, 13],
  [-11.716285, 166.970429, 13.4],
  [-11.716184, 166.970429, 13.6],
  [-11.716085, 166.970429, 14],
  [-11.716002, 166.970462, 14],
  [-11.715952, 166.970558, 13.8],
  [-11.715944, 166.970665, 13.8],
  [-11.715943, 166.970751, 13.8],
  [-11.715941, 166.970859, 13.8],
  [-11.715937, 166.970966, 13.8],
  [-11.715935, 166.971073, 13.8],
  [-11.715931, 166.97118, 13.8],
  [-11.715927, 166.971288, 13.8],
  [-11.715923, 166.971395, 13.8],
  [-11.715918, 166.971502, 13.8],
  [-11.715914, 166.97161, 13.8],
  [-11.71591, 166.971717, 13.8],
  [-11.715906, 166.971824, 13.8],
  [-11.715901, 166.971942, 13.8],
  [-11.715898, 166.972049, 13.8],
  [-11.715894, 166.972157, 13.8],
  [-11.715889, 166.972264, 13.8],
  [-11.715885, 166.972371, 13.8],
  [-11.715881, 166.972489, 13.8],
  [-11.715876, 166.972597, 13.8],
  [-11.715872, 166.972704, 13.8],
  [-11.715868, 166.972811, 13.8],
  [-11.715862, 166.97294, 13.8],
  [-11.715858, 166.973037, 13.8],
  [-11.715862, 166.973144, 13.8],
  [-11.71592, 166.973251, 14],
  [-11.716007, 166.973315, 14],
  [-11.716116, 166.973337, 13.6],
  [-11.716231, 166.973337, 13.2],
  [-11.716349, 166.973315, 13],
  [-11.716464, 166.973305, 12.6],
  [-11.716585, 166.973305, 12.2],
  [-11.716694, 166.973348, 12],
  [-11.716774, 166.973433, 11.8],
  [-11.716853, 166.97353, 11.8],
  [-11.716967, 166.973562, 11.8],
  [-11.717087, 166.973594, 11.8],
  [-11.71717, 166.97368, 12],
  [-11.717223, 166.973788, 12],
  [-11.71725, 166.973916, 12],
  [-11.717261, 166.974024, 12],
  [-11.717257, 166.974142, 12],
  [-11.717237, 166.97426, 12.2],
  [-11.717201, 166.974367, 12.2],
  [-11.717158, 166.974474, 12.2],
  [-11.717113, 166.974581, 12.2],
  [-11.717062, 166.974689, 12.2],
  [-11.716994, 166.974785, 12.2],
  [-11.716906, 166.97486, 12.2],
  [-11.716801, 166.974903, 12.4],
  [-11.716706, 166.974946, 12.6],
  [-11.716665, 166.975054, 12.8],
  [-11.716667, 166.975161, 13],
  [-11.716683, 166.97529, 13],
  [-11.716707, 166.975397, 13.2],
  [-11.716734, 166.975483, 13.4],
  [-11.716761, 166.97559, 13.4],
  [-11.71679, 166.975697, 13.6],
  [-11.71682, 166.975805, 13.6],
  [-11.716844, 166.975912, 13.6],
  [-11.716863, 166.976019, 13.6],
  [-11.716875, 166.976126, 13.6],
  [-11.716876, 166.976234, 13.6],
  [-11.716865, 166.976341, 13.4],
  [-11.716852, 166.976448, 13.2],
  [-11.716833, 166.976566, 12.8],
  [-11.716809, 166.976674, 12.4],
  [-11.716779, 166.976792, 12],
  [-11.716741, 166.976899, 11.6],
  [-11.716698, 166.977028, 11.2],
  [-11.716641, 166.977146, 11],
  [-11.716582, 166.977242, 11],
  [-11.716499, 166.97735, 10.6],
  [-11.716407, 166.977425, 10.2],
  [-11.716305, 166.9775, 9.6],
  [-11.716193, 166.977553, 9.2],
  [-11.716082, 166.977618, 9.2],
  [-11.715964, 166.977682, 9.2],
  [-11.715858, 166.977757, 9.2],
  [-11.715748, 166.977822, 9.2],
  [-11.715653, 166.977897, 9.2],
  [-11.715551, 166.977972, 9.2],
  [-11.71546, 166.978047, 9.2],
  [-11.715366, 166.978122, 9.2],
  [-11.715277, 166.978219, 9],
  [-11.715196, 166.978304, 8.8],
  [-11.715122, 166.978401, 8.6],
  [-11.715049, 166.978508, 8.4],
  [-11.714981, 166.978616, 8],
  [-11.714906, 166.978723, 7.8],
  [-11.714832, 166.97883, 7.4],
  [-11.714762, 166.978916, 7.2],
  [-11.71467, 166.979012, 6.8],
  [-11.714557, 166.979066, 6.6],
  [-11.714433, 166.979098, 6.6],
  [-11.714304, 166.979088, 6.8],
  [-11.714187, 166.979066, 6.8],
  [-11.714072, 166.979023, 7],
  [-11.71395, 166.978991, 7.2],
  [-11.713838, 166.978948, 7.2],
  [-11.71373, 166.978905, 7.4],
  [-11.713621, 166.978852, 7.6],
  [-11.713517, 166.97883, 7.8],
  [-11.7134, 166.978776, 8],
  [-11.713294, 166.978766, 8],
  [-11.713184, 166.978744, 8.2],
  [-11.713074, 166.978734, 8.2],
  [-11.712967, 166.978744, 8.2],
  [-11.712853, 166.978776, 8.4],
  [-11.712752, 166.978809, 8.4],
  [-11.712646, 166.978841, 8.4],
  [-11.712543, 166.978905, 8.4],
  [-11.71245, 166.978948, 8.4],
  [-11.712348, 166.978991, 8.4],
  [-11.712257, 166.979034, 8.6],
  [-11.712161, 166.979098, 8.4],
  [-11.712056, 166.979163, 8.4],
  [-11.71196, 166.979206, 8.4],
  [-11.711858, 166.979249, 8.4],
  [-11.711752, 166.979281, 8.2],
  [-11.711647, 166.979313, 8.2],
  [-11.71153, 166.979345, 8],
  [-11.71142, 166.979356, 7.8],
  [-11.711306, 166.979356, 7.6],
  [-11.711187, 166.979356, 7.4],
  [-11.711065, 166.979356, 7.2],
  [-11.710942, 166.979345, 6.8],
  [-11.710829, 166.979324, 6.6],
  [-11.7107, 166.979313, 6.2],
  [-11.710581, 166.979302, 6],
  [-11.710462, 166.979302, 5.6],
  [-11.71033, 166.979281, 5.4],
  [-11.710212, 166.979281, 5],
  [-11.710079, 166.979281, 4.8],
  [-11.70996, 166.979302, 4.4],
  [-11.709831, 166.979313, 4.2],
  [-11.709708, 166.979356, 4],
  [-11.709597, 166.97942, 3.8],
  [-11.709487, 166.979495, 3.8],
  [-11.709402, 166.97957, 3.8],
  [-11.709335, 166.979678, 4],
  [-11.709291, 166.979806, 4.2],
  [-11.709264, 166.979924, 4.4],
  [-11.709253, 166.980032, 4.6],
  [-11.709249, 166.98015, 5],
  [-11.709252, 166.980278, 5.2],
  [-11.709259, 166.980386, 5.6],
  [-11.709265, 166.980493, 5.8],
  [-11.709273, 166.980611, 6.2],
  [-11.709281, 166.980718, 6.4],
  [-11.709285, 166.980826, 6.6],
  [-11.709285, 166.980933, 6.8],
  [-11.709281, 166.981019, 7],
  [-11.709269, 166.981126, 7.4],
  [-11.709251, 166.981233, 7.4],
  [-11.709222, 166.98133, 7.6],
  [-11.709181, 166.981416, 7.6],
  [-11.709131, 166.981512, 7.8],
  [-11.709075, 166.981598, 7.8],
  [-11.709021, 166.981695, 7.8],
  [-11.708959, 166.98177, 7.8],
  [-11.708894, 166.981866, 7.8],
  [-11.708828, 166.981952, 7.8],
  [-11.708762, 166.982027, 7.8],
  [-11.708695, 166.982102, 7.6],
  [-11.708626, 166.982199, 7.6],
  [-11.708556, 166.982274, 7.6],
  [-11.708483, 166.982349, 7.6],
  [-11.708407, 166.982446, 7.6],
  [-11.708335, 166.982521, 7.4],
  [-11.708253, 166.982596, 7.4],
  [-11.708176, 166.982682, 7.4],
  [-11.7081, 166.982757, 7.2],
  [-11.708022, 166.982843, 7.2],
  [-11.707942, 166.982918, 7],
  [-11.70786, 166.983004, 7],
  [-11.707785, 166.983079, 7],
  [-11.707702, 166.983154, 6.8],
  [-11.707617, 166.98325, 6.8],
  [-11.707539, 166.983325, 6.6],
  [-11.707451, 166.983401, 6.6],
  [-11.707374, 166.983497, 6.6],
  [-11.707294, 166.983572, 6.4],
  [-11.707219, 166.983658, 6.4],
  [-11.707144, 166.983733, 6.4],
  [-11.707063, 166.98384, 6.2],
  [-11.706995, 166.983937, 6.2],
  [-11.706934, 166.984023, 6.2],
  [-11.706877, 166.98413, 6.4],
  [-11.706846, 166.984237, 6.4],
  [-11.706835, 166.984345, 6.6],
  [-11.706837, 166.984473, 6.6],
  [-11.706847, 166.984581, 6.8],
  [-11.706865, 166.984699, 6.8],
  [-11.706887, 166.984806, 6.8],
  [-11.706916, 166.984913, 6.8],
  [-11.706948, 166.985021, 6.6],
  [-11.706985, 166.985139, 6.6],
  [-11.707023, 166.985235, 6.6],
  [-11.707069, 166.985353, 6.6],
  [-11.707114, 166.98545, 6.6],
  [-11.707164, 166.985557, 6.4],
  [-11.70722, 166.985664, 6.4],
  [-11.707275, 166.98575, 6.4],
  [-11.707335, 166.985857, 6.4],
  [-11.70738, 166.985965, 6.4],
  [-11.707406, 166.986072, 6.4],
  [-11.707415, 166.986179, 6.4],
  [-11.707402, 166.986297, 6.2],
  [-11.707358, 166.986405, 6.2],
  [-11.707288, 166.986501, 6],
  [-11.707196, 166.986576, 6],
  [-11.707086, 166.986619, 5.8],
  [-11.70698, 166.986641, 5.6],
  [-11.706862, 166.986651, 5.6],
  [-11.706744, 166.986651, 5.4],
  [-11.706623, 166.986641, 5.4],
  [-11.706511, 166.986609, 5.2],
  [-11.706405, 166.986566, 5.2],
  [-11.706301, 166.986512, 5.2],
  [-11.706198, 166.986469, 5.4],
  [-11.706095, 166.986405, 5.4],
  [-11.705994, 166.98633, 5.4],
  [-11.705902, 166.986276, 5.4],
  [-11.705816, 166.98619, 5.4],
  [-11.705748, 166.986115, 5.6],
  [-11.70568, 166.986029, 5.6],
  [-11.705634, 166.985922, 5.6],
  [-11.705611, 166.985815, 5.6],
  [-11.705619, 166.985697, 5.6],
  [-11.705671, 166.985589, 5.8],
  [-11.70576, 166.985525, 6],
  [-11.705859, 166.985482, 6.4],
  [-11.705954, 166.98545, 6.8],
  [-11.706053, 166.985396, 7.2],
  [-11.706149, 166.985353, 7.6],
  [-11.706222, 166.9853, 8.2],
  [-11.70626, 166.985203, 8.6],
  [-11.706253, 166.985106, 8.8],
  [-11.706218, 166.985021, 8.8],
  [-11.706181, 166.984924, 8.8],
  [-11.706171, 166.984838, 8.8],
  [-11.706193, 166.984731, 9],
  [-11.706232, 166.984634, 9.2],
  [-11.706287, 166.984559, 9.6],
  [-11.706343, 166.984473, 9.8],
  [-11.706399, 166.984398, 10],
  [-11.70645, 166.984334, 10.4],
  [-11.706505, 166.984259, 10.6],
  [-11.706552, 166.984184, 11],
  [-11.706591, 166.984087, 11.2],
  [-11.706613, 166.984001, 11.4],
  [-11.706613, 166.983905, 11.8],
  [-11.706586, 166.983819, 12],
  [-11.706536, 166.983733, 12],
  [-11.706477, 166.98368, 12.2],
  [-11.706399, 166.983615, 12.2],
  [-11.706323, 166.983572, 12.2],
  [-11.706226, 166.98354, 12.2],
  [-11.706132, 166.983551, 12.2],
  [-11.706051, 166.983583, 12.2],
  [-11.705973, 166.983658, 12.4],
  [-11.705918, 166.983733, 12.4],
  [-11.705863, 166.98383, 12.6],
  [-11.705819, 166.983905, 12.6],
  [-11.705777, 166.984012, 12.6],
  [-11.705735, 166.984109, 12.8],
  [-11.705698, 166.984195, 12.8],
  [-11.705666, 166.984291, 12.8],
  [-11.70563, 166.984398, 13],
  [-11.705595, 166.984484, 13],
  [-11.705564, 166.984581, 13],
  [-11.705529, 166.984688, 13],
  [-11.705493, 166.984795, 13.2],
  [-11.705462, 166.984881, 13.2],
  [-11.705423, 166.984978, 13.2],
  [-11.705383, 166.985085, 13.4],
  [-11.705345, 166.985171, 13.4],
  [-11.705294, 166.985278, 13.4],
  [-11.705246, 166.985353, 13.4],
  [-11.705188, 166.98545, 13.6],
  [-11.705132, 166.985536, 13.6],
  [-11.705077, 166.985632, 13.6],
  [-11.705029, 166.985718, 13.6],
  [-11.704975, 166.985815, 13.6],
  [-11.704921, 166.985922, 13.6],
  [-11.704869, 166.986008, 13.6],
  [-11.70482, 166.986104, 13.6],
  [-11.704767, 166.986212, 13.6],
  [-11.70472, 166.986297, 13.6],
  [-11.704671, 166.986405, 13.6],
  [-11.704624, 166.986501, 13.6],
  [-11.704582, 166.986609, 13.6],
  [-11.704549, 166.986716, 13.6],
  [-11.704518, 166.986823, 13.6],
  [-11.704487, 166.98693, 13.6],
  [-11.70446, 166.987038, 13.6],
  [-11.704427, 166.987145, 13.6],
  [-11.704386, 166.987252, 13.6],
  [-11.704338, 166.987338, 13.6],
  [-11.704279, 166.987445, 13.6],
  [-11.704203, 166.98752, 13.6],
  [-11.704115, 166.987596, 13.6],
  [-11.704012, 166.987649, 13.6],
  [-11.703905, 166.987671, 13.6],
  [-11.703796, 166.987692, 13.6],
  [-11.703686, 166.987692, 13.6],
  [-11.703578, 166.987671, 13.6],
  [-11.703456, 166.987671, 13.6],
  [-11.703356, 166.987724, 13.6],
  [-11.703272, 166.987799, 13.6],
  [-11.703222, 166.987885, 13.4],
  [-11.703195, 166.987993, 13.4],
  [-11.703189, 166.988121, 13.4],
  [-11.703191, 166.988229, 13.2],
  [-11.703186, 166.988347, 13],
  [-11.703167, 166.988465, 12.8],
  [-11.703138, 166.988572, 12.6],
  [-11.703096, 166.988679, 12.6],
  [-11.703029, 166.988786, 12.6],
  [-11.702945, 166.988862, 12.6],
  [-11.702859, 166.988937, 12.6],
  [-11.702756, 166.98899, 12.8],
  [-11.702647, 166.989044, 13],
  [-11.702542, 166.989076, 13.2],
  [-11.702438, 166.98913, 13.4],
  [-11.702332, 166.989151, 13.6],
  [-11.702234, 166.989183, 13.6],
  [-11.702123, 166.989205, 13.6],
  [-11.702014, 166.989216, 13.6],
  [-11.701903, 166.989216, 13.6],
  [-11.701793, 166.989183, 13.6],
  [-11.7017, 166.989141, 13.6],
  [-11.701608, 166.989065, 13.6],
  [-11.701531, 166.98899, 13.6],
  [-11.701451, 166.988915, 13.6],
  [-11.70138, 166.988829, 13.6],
  [-11.701294, 166.988744, 13.6],
  [-11.701199, 166.988701, 13.6],
  [-11.701097, 166.988668, 13.6],
  [-11.700981, 166.988701, 13.4],
  [-11.700897, 166.988776, 13.2],
  [-11.700835, 166.988862, 13],
  [-11.700785, 166.988958, 12.8],
  [-11.700734, 166.989076, 12.6],
  [-11.700677, 166.989173, 12.4],
  [-11.700598, 166.989259, 12.4],
  [-11.700494, 166.989323, 12.4],
  [-11.700379, 166.989355, 12.4],
  [-11.700264, 166.989355, 12.6],
  [-11.700148, 166.989334, 12.6],
  [-11.70004, 166.989323, 12.8],
  [-11.699931, 166.989312, 13],
  [-11.699823, 166.989291, 13.2],
  [-11.699715, 166.989323, 13.4],
  [-11.699621, 166.989387, 13.6],
  [-11.699568, 166.989473, 13.6],
  [-11.699549, 166.98958, 13.6],
  [-11.699548, 166.989709, 13.8],
  [-11.69955, 166.989816, 13.8],
  [-11.699554, 166.989913, 13.8],
  [-11.699544, 166.99002, 13.6],
  [-11.699502, 166.990128, 13.6],
  [-11.699422, 166.990203, 13.6],
  [-11.699316, 166.990256, 13.4],
  [-11.69921, 166.990288, 13.2],
  [-11.699096, 166.990299, 13],
  [-11.698984, 166.99031, 12.8],
  [-11.698869, 166.99031, 12.6],
  [-11.69875, 166.99031, 12.4],
  [-11.69863, 166.990299, 12.2],
  [-11.698511, 166.990299, 12],
  [-11.698392, 166.990288, 12],
  [-11.698274, 166.990288, 11.8],
  [-11.698152, 166.990299, 11.6],
  [-11.698034, 166.990299, 11.4],
  [-11.697914, 166.990331, 11.4],
  [-11.697802, 166.990374, 11.2],
  [-11.697696, 166.990439, 11.2],
  [-11.697616, 166.990525, 11.4],
  [-11.69756, 166.990632, 11.6],
  [-11.697529, 166.990739, 11.8],
  [-11.697513, 166.990868, 12],
  [-11.697505, 166.990975, 12.4],
  [-11.697502, 166.991082, 12.6],
  [-11.6975, 166.99119, 13],
  [-11.697496, 166.991286, 13.2],
  [-11.697484, 166.991394, 13.4],
  [-11.697462, 166.991522, 13.6],
  [-11.697429, 166.991608, 13.6],
  [-11.697388, 166.991715, 13.6],
  [-11.69735, 166.991812, 13.6],
  [-11.697329, 166.991919, 13.6],
  [-11.697343, 166.992027, 13.6],
  [-11.697376, 166.992134, 13.6],
  [-11.697427, 166.99222, 13.6],
  [-11.697484, 166.992316, 13.6],
  [-11.697551, 166.992402, 13.6],
  [-11.69762, 166.992477, 13.6],
  [-11.697696, 166.992574, 13.6],
  [-11.697779, 166.992638, 13.4],
  [-11.697879, 166.992681, 13.2],
  [-11.697983, 166.992724, 12.8],
  [-11.69809, 166.992767, 12.6],
  [-11.69818, 166.992853, 12.4],
  [-11.698224, 166.992949, 12.4],
  [-11.69822, 166.993078, 12.4],
  [-11.698181, 166.993185, 12.4],
  [-11.698115, 166.993293, 12.6],
  [-11.698038, 166.993368, 12.6],
  [-11.69795, 166.993443, 12.8],
  [-11.697854, 166.993507, 13],
  [-11.697759, 166.993561, 13.2],
  [-11.697659, 166.993614, 13.2],
  [-11.697561, 166.993668, 13.4],
  [-11.697465, 166.993722, 13.4],
  [-11.697372, 166.993765, 13.6],
  [-11.697273, 166.993808, 13.6],
  [-11.697164, 166.993829, 13.6],
  [-11.697055, 166.99384, 13.4],
  [-11.696942, 166.99384, 13.4],
  [-11.696835, 166.99385, 13.2],
  [-11.696729, 166.993904, 13.2],
  [-11.696639, 166.993958, 12.8],
  [-11.696557, 166.994054, 12.6],
  [-11.696489, 166.994129, 12.2],
  [-11.696422, 166.994237, 12],
  [-11.696356, 166.994344, 11.8],
  [-11.696298, 166.994451, 12],
  [-11.696254, 166.994559, 12.2],
  [-11.696216, 166.994666, 12.6],
  [-11.696173, 166.994773, 13],
  [-11.69612, 166.99488, 13.2],
  [-11.696051, 166.994956, 13.4],
  [-11.695967, 166.995031, 13.6],
  [-11.69588, 166.995074, 13.6],
  [-11.695778, 166.995116, 13.6],
  [-11.695673, 166.995149, 13.6],
  [-11.695569, 166.995149, 13.6],
  [-11.69546, 166.995116, 13.6],
  [-11.695363, 166.995074, 13.4],
  [-11.695276, 166.995009, 13.4],
  [-11.695192, 166.994934, 13.4],
  [-11.695113, 166.994859, 13.4],
  [-11.695032, 166.994773, 13.2],
  [-11.694958, 166.994698, 13.2],
  [-11.694889, 166.994601, 13],
  [-11.694818, 166.994516, 13],
  [-11.694746, 166.994419, 12.8],
  [-11.69467, 166.994323, 12.6],
  [-11.694599, 166.994237, 12.6],
  [-11.694524, 166.99414, 12.4],
  [-11.694441, 166.994065, 12.2],
  [-11.694352, 166.993979, 12],
  [-11.694261, 166.993904, 12],
  [-11.694155, 166.99384, 11.8],
  [-11.69405, 166.993808, 11.6],
  [-11.693926, 166.993797, 11.4],
  [-11.693809, 166.993808, 11.4],
  [-11.693704, 166.993872, 11.4],
  [-11.693644, 166.993958, 11.6],
  [-11.693635, 166.994087, 11.8],
  [-11.693647, 166.994205, 12.2],
  [-11.693666, 166.994312, 12.4],
  [-11.693684, 166.994419, 12.6],
  [-11.693688, 166.994526, 12.8],
  [-11.69367, 166.994644, 12.8],
  [-11.693616, 166.994741, 12.8],
  [-11.69353, 166.994805, 12.6],
  [-11.693427, 166.994848, 12.2],
  [-11.693307, 166.994859, 11.6],
  [-11.693188, 166.994848, 11.2],
  [-11.693074, 166.994827, 10.6],
  [-11.692959, 166.994784, 10.2],
  [-11.692854, 166.994709, 9.8],
  [-11.692756, 166.994634, 9.4],
  [-11.69266, 166.994537, 9],
  [-11.69256, 166.994451, 8.6],
  [-11.692453, 166.994387, 8],
  [-11.692322, 166.994344, 7.6],
  [-11.692193, 166.994376, 7.2],
  [-11.69207, 166.994419, 6.8],
  [-11.69195, 166.994483, 6.6],
  [-11.691823, 166.994526, 6.2],
  [-11.69169, 166.994516, 5.8],
  [-11.69158, 166.994419, 5.2],
  [-11.691482, 166.994323, 4.8],
  [-11.691353, 166.99428, 4.4],
  [-11.691237, 166.994226, 4],
  [-11.691156, 166.994097, 3.6],
  [-11.691136, 166.993958, 3.2],
  [-11.691139, 166.993829, 3],
  [-11.691083, 166.99369, 3],
  [-11.690983, 166.993614, 2.8],
  [-11.690859, 166.993561, 2.6],
  [-11.690726, 166.993582, 2.4],
  [-11.690598, 166.993625, 2.2],
  [-11.690482, 166.993668, 2.2],
  [-11.690361, 166.993732, 2],
  [-11.690258, 166.993808, 1.8],
  [-11.690167, 166.993904, 1.8],
  [-11.690046, 166.993958, 1.8],
  [-11.689921, 166.993979, 1.6],
  [-11.689796, 166.993958, 1.6],
  [-11.689668, 166.993947, 1.6],
  [-11.689558, 166.993883, 1.6],
  [-11.689456, 166.993829, 1.6],
  [-11.689366, 166.993732, 1.8],
  [-11.68928, 166.993636, 1.8],
  [-11.689197, 166.99355, 1.8],
  [-11.689112, 166.993475, 1.8],
  [-11.689024, 166.993378, 1.8],
  [-11.68893, 166.993303, 1.8],
  [-11.688828, 166.99325, 1.8],
  [-11.688721, 166.993196, 1.8],
  [-11.688611, 166.993164, 1.8],
  [-11.688496, 166.993164, 1.8],
  [-11.688376, 166.993164, 1.8],
  [-11.688262, 166.993164, 1.8],
  [-11.688147, 166.993142, 2],
  [-11.688041, 166.99311, 2.2],
  [-11.687927, 166.993078, 2.4],
  [-11.687821, 166.993057, 2.6],
  [-11.687719, 166.993089, 3],
  [-11.687647, 166.993164, 3.6],
  [-11.68759, 166.99326, 4.2],
  [-11.687525, 166.993335, 4.6],
  [-11.687439, 166.993378, 5],
  [-11.687342, 166.9934, 5.2],
  [-11.687251, 166.993411, 5.2],
  [-11.687188, 166.993486, 5.6],
  [-11.687181, 166.993582, 6],
  [-11.687195, 166.993668, 6.4],
  [-11.687213, 166.993765, 6.6],
  [-11.687226, 166.99385, 7],
  [-11.687215, 166.993947, 7.2],
  [-11.687167, 166.994022, 7.6],
  [-11.68709, 166.994065, 7.8],
  [-11.687006, 166.994087, 8],
  [-11.686918, 166.994087, 8.2],
  [-11.68683, 166.994065, 8.4],
  [-11.686744, 166.994054, 8.6],
  [-11.686662, 166.994011, 8.8],
  [-11.68659, 166.993947, 9],
  [-11.686531, 166.993883, 9.2],
  [-11.686488, 166.993808, 9.4],
  [-11.686458, 166.993722, 9.8],
  [-11.686436, 166.993625, 10],
  [-11.686421, 166.99355, 10.4],
  [-11.686409, 166.993454, 10.6],
  [-11.686398, 166.993368, 11],
  [-11.686386, 166.993271, 11.2],
  [-11.686369, 166.993196, 11.6],
  [-11.686339, 166.993121, 11.8],
  [-11.686289, 166.993046, 12.2],
  [-11.686225, 166.992981, 12.4],
  [-11.686143, 166.992949, 12.6],
  [-11.686064, 166.992949, 12.8],
  [-11.685976, 166.992971, 13],
  [-11.685897, 166.993003, 13.2],
  [-11.68582, 166.993035, 13.2],
  [-11.685741, 166.993078, 13.4],
  [-11.685663, 166.993142, 13.4],
  [-11.685588, 166.993185, 13.4],
  [-11.68551, 166.993228, 13.4],
  [-11.68543, 166.993271, 13.4],
  [-11.685345, 166.993325, 13.6],
  [-11.685253, 166.993346, 13.6],
  [-11.685159, 166.993368, 13.6],
  [-11.68506, 166.993368, 13.6],
  [-11.684966, 166.993335, 13.6],
  [-11.684887, 166.99326, 13.6],
  [-11.684832, 166.993185, 13.6],
  [-11.684791, 166.993078, 13.6],
  [-11.684757, 166.992981, 13.4],
  [-11.684727, 166.992874, 13.4],
  [-11.684691, 166.992788, 13.4],
  [-11.684639, 166.992681, 13.2],
  [-11.684566, 166.992606, 13.2],
  [-11.684468, 166.992542, 12.8],
  [-11.684364, 166.992509, 12.6],
  [-11.684259, 166.992477, 12.4],
  [-11.68414, 166.992456, 12],
  [-11.684022, 166.992434, 11.6],
  [-11.683906, 166.992434, 11.4],
  [-11.683787, 166.992434, 11],
  [-11.683661, 166.992424, 10.6],
  [-11.683536, 166.992424, 10.4],
  [-11.683413, 166.992402, 10],
  [-11.683292, 166.992424, 9.8],
  [-11.683166, 166.992456, 9.4],
  [-11.683039, 166.992466, 9],
  [-11.682913, 166.992466, 8.8],
  [-11.682786, 166.992434, 8.4],
  [-11.682668, 166.992391, 8],
  [-11.682563, 166.992295, 7.6],
  [-11.682473, 166.992209, 7.2],
  [-11.682377, 166.992112, 6.8],
  [-11.682273, 166.992027, 6.6],
  [-11.682156, 166.991962, 6.2],
  [-11.682041, 166.991898, 6],
  [-11.681919, 166.991844, 5.8],
  [-11.681837, 166.991748, 5.6],
  [-11.681781, 166.991608, 5],
  [-11.68174, 166.99149, 4.6],
  [-11.681681, 166.991372, 4.4],
  [-11.681596, 166.991265, 4.2],
  [-11.681494, 166.99119, 4],
  [-11.681374, 166.991104, 4],
  [-11.68127, 166.991029, 4],
  [-11.681188, 166.990943, 4.2],
  [-11.681126, 166.990814, 4.4],
  [-11.681079, 166.990707, 4.4],
  [-11.681039, 166.990589, 4.6],
  [-11.68101, 166.990471, 4.8],
  [-11.680981, 166.990342, 5.2],
  [-11.680957, 166.990235, 5.4],
  [-11.680933, 166.990128, 5.6],
  [-11.680909, 166.99001, 5.8],
  [-11.680881, 166.989902, 6.2],
  [-11.680849, 166.989795, 6.4],
  [-11.680811, 166.989709, 6.8],
  [-11.680761, 166.989602, 7.2],
  [-11.680705, 166.989516, 7.6],
  [-11.680641, 166.989441, 7.8],
  [-11.680558, 166.989387, 8.2],
  [-11.680472, 166.989334, 8.6],
  [-11.680381, 166.989323, 9],
  [-11.680285, 166.989323, 9.2],
  [-11.680194, 166.989355, 9.6],
  [-11.680112, 166.989387, 9.8],
  [-11.680035, 166.989441, 10.2],
  [-11.679962, 166.989495, 10.4],
  [-11.679891, 166.989548, 10.6],
  [-11.679823, 166.989613, 10.8],
  [-11.679756, 166.989677, 11],
  [-11.679687, 166.989731, 11.2],
  [-11.679619, 166.989795, 11.4],
  [-11.679549, 166.989859, 11.6],
  [-11.679478, 166.989902, 11.8],
  [-11.6794, 166.989967, 12],
  [-11.679318, 166.99001, 12.2],
  [-11.679235, 166.990042, 12.2],
  [-11.679145, 166.990052, 12.4],
  [-11.679052, 166.990074, 12.6],
  [-11.678956, 166.990052, 12.8],
  [-11.678865, 166.990052, 12.8],
  [-11.678773, 166.99002, 13],
  [-11.678683, 166.99001, 13.2],
  [-11.67859, 166.989977, 13.2],
  [-11.678506, 166.989945, 13.4],
  [-11.678416, 166.989902, 13.4],
  [-11.678329, 166.989859, 13.6],
  [-11.678244, 166.989816, 13.6],
  [-11.678161, 166.989763, 13.6],
  [-11.678075, 166.989709, 13.6],
  [-11.677997, 166.989645, 13.6],
  [-11.67792, 166.98958, 13.6],
  [-11.677839, 166.989505, 13.6],
  [-11.677769, 166.98943, 13.6],
  [-11.677697, 166.989355, 13.4],
  [-11.677627, 166.98928, 13.2],
  [-11.677566, 166.989183, 13.2],
  [-11.677505, 166.989098, 12.8],
  [-11.677456, 166.98899, 12.6],
  [-11.677418, 166.988883, 12.2],
  [-11.677385, 166.988776, 11.8],
  [-11.677362, 166.988647, 11.4],
  [-11.677344, 166.988529, 10.8],
  [-11.67733, 166.988411, 10.4],
  [-11.67732, 166.988282, 9.8],
  [-11.677312, 166.988143, 9.4],
  [-11.677304, 166.988025, 8.8],
  [-11.677296, 166.987885, 8.4],
  [-11.677284, 166.987756, 8],
  [-11.677265, 166.987617, 7.8],
  [-11.677241, 166.987478, 7.6],
  [-11.677216, 166.98736, 7.4],
  [-11.677181, 166.98722, 7.2],
  [-11.677142, 166.987091, 7],
  [-11.677099, 166.986973, 7],
  [-11.677044, 166.986866, 6.8],
  [-11.676977, 166.986748, 6.8],
  [-11.6769, 166.986641, 6.8],
  [-11.676812, 166.986544, 6.6],
  [-11.67671, 166.98648, 6.6],
  [-11.676591, 166.986426, 6.6],
  [-11.676477, 166.986394, 6.6],
  [-11.676351, 166.986383, 6.6],
  [-11.676234, 166.986383, 6.6],
  [-11.67611, 166.986383, 6.8],
  [-11.675987, 166.986383, 6.8],
  [-11.675877, 166.986405, 6.8],
  [-11.675756, 166.986437, 7],
  [-11.675652, 166.986469, 7.2],
  [-11.675543, 166.986512, 7.2],
  [-11.67544, 166.986576, 7.4],
  [-11.675346, 166.986619, 7.6],
  [-11.675248, 166.986684, 7.8],
  [-11.675158, 166.986748, 7.8],
  [-11.675063, 166.986823, 8],
  [-11.674977, 166.986877, 8.2],
  [-11.674891, 166.986941, 8.4],
  [-11.674799, 166.987005, 8.4],
  [-11.674713, 166.98707, 8.6],
  [-11.674621, 166.987123, 8.8],
  [-11.674524, 166.987166, 8.8],
  [-11.67443, 166.98722, 8.8],
  [-11.674331, 166.987263, 9],
  [-11.674224, 166.987295, 9],
  [-11.674119, 166.987306, 9],
  [-11.674016, 166.987306, 9],
  [-11.673902, 166.987295, 8.8],
  [-11.673805, 166.987263, 8.8],
  [-11.673696, 166.98722, 8.4],
  [-11.673603, 166.987156, 8.2],
  [-11.673502, 166.987113, 8],
  [-11.673401, 166.987048, 7.8],
  [-11.673297, 166.986984, 7.4],
  [-11.673187, 166.986941, 7.2],
  [-11.673072, 166.986909, 7],
  [-11.672947, 166.986909, 7],
  [-11.672833, 166.98693, 7],
  [-11.672717, 166.986963, 7.2],
  [-11.672601, 166.986984, 7.2],
  [-11.672488, 166.986984, 7.4],
  [-11.672376, 166.986973, 7.2],
  [-11.672272, 166.986909, 7],
  [-11.672181, 166.986834, 6.8],
  [-11.672097, 166.986748, 6.6],
  [-11.672024, 166.986651, 6.4],
  [-11.671952, 166.986566, 6.2],
  [-11.671877, 166.986469, 5.8],
  [-11.671785, 166.986383, 5.6],
  [-11.671689, 166.986297, 5.2],
  [-11.671578, 166.986222, 5],
  [-11.671468, 166.986179, 4.6],
  [-11.671343, 166.986147, 4.4],
  [-11.671215, 166.986136, 4.2],
  [-11.671088, 166.986136, 4],
  [-11.670962, 166.986136, 4],
  [-11.670835, 166.986136, 4],
  [-11.670718, 166.986136, 4],
  [-11.670596, 166.986115, 4],
  [-11.670478, 166.986072, 4.2],
  [-11.670377, 166.985997, 4.2],
  [-11.670293, 166.985922, 4.4],
  [-11.670214, 166.985825, 4.6],
  [-11.670148, 166.985739, 4.8],
  [-11.670088, 166.985632, 4.8],
  [-11.670037, 166.985525, 5],
  [-11.669992, 166.985428, 5],
  [-11.669949, 166.985321, 5],
  [-11.669909, 166.985203, 5],
  [-11.669874, 166.985106, 5],
  [-11.669837, 166.984988, 4.8],
  [-11.669798, 166.984881, 4.6],
  [-11.669748, 166.984795, 4.4],
  [-11.669685, 166.984688, 4.2],
  [-11.66961, 166.984591, 4],
  [-11.669508, 166.984527, 3.8],
  [-11.669394, 166.984506, 3.6],
  [-11.669276, 166.984527, 3.8],
  [-11.669177, 166.984613, 4],
  [-11.669095, 166.984688, 4.2],
  [-11.669024, 166.984774, 4.4],
  [-11.668956, 166.98487, 4.6],
  [-11.66889, 166.984956, 4.8],
  [-11.668822, 166.985053, 5],
  [-11.668755, 166.985128, 5],
  [-11.668678, 166.985214, 5],
  [-11.668607, 166.9853, 4.8],
  [-11.668527, 166.985385, 4.8],
  [-11.668445, 166.985461, 4.6],
  [-11.668359, 166.985525, 4.6],
  [-11.668268, 166.985589, 4.6],
  [-11.668168, 166.985643, 4.6],
  [-11.668056, 166.985675, 4.8],
  [-11.667943, 166.985697, 5],
  [-11.667836, 166.985664, 5.2],
  [-11.667734, 166.985632, 5.6],
  [-11.667636, 166.9856, 5.8],
  [-11.667533, 166.985568, 6.2],
  [-11.667438, 166.985536, 6.4],
  [-11.667337, 166.985503, 6.8],
  [-11.667242, 166.985493, 7],
  [-11.667144, 166.985482, 7.4],
  [-11.667047, 166.985482, 7.6],
  [-11.666951, 166.985493, 8],
  [-11.666857, 166.985503, 8.2],
  [-11.666768, 166.985557, 8.6],
  [-11.666685, 166.985589, 8.8],
  [-11.666609, 166.985643, 9],
  [-11.666532, 166.985697, 9.4],
  [-11.666456, 166.98575, 9.6],
  [-11.666383, 166.985815, 9.8],
  [-11.666312, 166.985857, 10],
  [-11.666241, 166.985922, 10.2],
  [-11.666169, 166.985986, 10.4],
  [-11.666094, 166.986029, 10.8],
  [-11.666017, 166.986083, 11],
  [-11.665941, 166.986115, 11.2],
  [-11.665859, 166.986169, 11.4],
  [-11.665773, 166.98619, 11.6],
  [-11.66569, 166.986222, 11.8],
  [-11.665598, 166.986254, 12.2],
  [-11.665512, 166.986276, 12.4],
  [-11.665426, 166.986287, 12.6],
  [-11.665337, 166.986319, 12.8],
  [-11.66525, 166.986319, 13],
  [-11.665156, 166.98633, 13.2],
  [-11.665066, 166.98633, 13.4],
  [-11.664978, 166.98633, 13.4],
  [-11.664885, 166.98633, 13.6],
  [-11.664794, 166.986319, 13.6],
  [-11.664703, 166.986287, 13.4],
  [-11.664613, 166.986254, 13.4],
  [-11.664525, 166.986212, 13.2],
  [-11.664436, 166.986147, 13.2],
  [-11.664357, 166.986083, 13.2],
  [-11.664279, 166.986008, 13.2],
  [-11.664201, 166.985933, 13.2],
  [-11.664126, 166.985879, 13.2],
  [-11.664053, 166.985782, 13.2],
  [-11.663984, 166.985718, 13.2],
  [-11.663912, 166.985632, 13.2],
  [-11.663838, 166.985557, 13.2],
  [-11.66377, 166.985482, 13.2],
  [-11.663695, 166.985385, 13.2],
  [-11.663625, 166.98531, 13.2],
];
export default stream;
