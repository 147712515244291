import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.64659, 166.950463, 10],
  [-11.646787, 166.950431, 9.2],
  [-11.64697, 166.950506, 9],
  [-11.647097, 166.950656, 9.4],
  [-11.647182, 166.950839, 10.2],
  [-11.647249, 166.951021, 10.8],
  [-11.647306, 166.951203, 11.4],
  [-11.647363, 166.951386, 12.2],
  [-11.647418, 166.951557, 13],
  [-11.647471, 166.95174, 13.6],
];

export default stream;
