import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.756191, 165.852227, 165.8],
  [-10.756203, 165.852056, 165.4],
  [-10.756236, 165.851884, 164.6],
  [-10.756329, 165.851734, 163.4],
  [-10.756439, 165.851583, 161.8],
  [-10.756555, 165.851433, 160.2],
  [-10.756692, 165.851294, 158.6],
  [-10.756863, 165.851187, 157.2],
  [-10.757038, 165.851079, 155.8],
  [-10.757182, 165.85094, 154.4],
  [-10.757225, 165.850747, 152.4],
  [-10.757195, 165.850532, 150.8],
  [-10.757149, 165.850317, 149],
  [-10.757159, 165.850103, 147],
  [-10.757318, 165.849953, 144.4],
  [-10.75753, 165.849888, 142.4],
  [-10.757729, 165.849792, 140.2],
  [-10.757862, 165.849609, 138.4],
  [-10.757957, 165.849395, 137],
];

export default stream;
