import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.748461, 165.842164, 93.6],
  [-10.748396, 165.842303, 93.6],
  [-10.748328, 165.842453, 93.6],
  [-10.748262, 165.842614, 94],
  [-10.748201, 165.842743, 94.6],
  [-10.748141, 165.842882, 95.4],
  [-10.748084, 165.843033, 96.2],
  [-10.748023, 165.843172, 96.8],
  [-10.747962, 165.843312, 97],
  [-10.74788, 165.84343, 97.2],
  [-10.747736, 165.843419, 97.4],
  [-10.747597, 165.843354, 97.4],
  [-10.74746, 165.843279, 97.4],
  [-10.747321, 165.843215, 97.4],
  [-10.74718, 165.843161, 97.4],
  [-10.747032, 165.843097, 97.2],
  [-10.74689, 165.84314, 97.2],
  [-10.746813, 165.843279, 97.2],
  [-10.746747, 165.84343, 96.8],
  [-10.746679, 165.84359, 96.2],
  [-10.746614, 165.843741, 95.6],
  [-10.74655, 165.843891, 95.2],
  [-10.746484, 165.844063, 94.6],
  [-10.746418, 165.844213, 94],
  [-10.746352, 165.844384, 93.6],
  [-10.746284, 165.844535, 93.6],
];

export default stream;
