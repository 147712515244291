import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-21.655952, 166.205069, 1543],
  [-21.656042, 166.204951, 1543],
  [-21.656162, 166.204879, 1543],
  [-21.656286, 166.204796, 1543],
];

export default stream;
