import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.796162, 165.818367, 3.2],
  [-10.796131, 165.818238, 3.2],
  [-10.796073, 165.818131, 3.2],
  [-10.795984, 165.818056, 3.4],
  [-10.795888, 165.818002, 4],
  [-10.795804, 165.817916, 4.6],
  [-10.795774, 165.817809, 5],
  [-10.795795, 165.817713, 5],
  [-10.795838, 165.817605, 5],
  [-10.795883, 165.817498, 4.8],
  [-10.79591, 165.817391, 4.8],
  [-10.79593, 165.817262, 4.8],
  [-10.795948, 165.817155, 4.8],
  [-10.795962, 165.817037, 4.8],
  [-10.795971, 165.816929, 4.8],
];

export default stream;
