import { LatLngAlt } from "../../../types.js";

export const edgeNeokyoD1: LatLngAlt[] = [
  [-10.799045, 165.841391, 65],
  [-10.798991, 165.841262, 65],
  [-10.798915, 165.841155, 65],
  [-10.798818, 165.841069, 65],
  [-10.798701, 165.841026, 65],
];
export const edgeNeokyoD2: LatLngAlt[] = [
  [-10.798718, 165.841037, 65],
  [-10.798824, 165.841037, 65],
  [-10.79893, 165.841037, 65],
  [-10.799036, 165.841026, 65],
  [-10.799138, 165.840994, 65],
];
export const edgeNeokyoD3: LatLngAlt[] = [
  [-10.799224, 165.840951, 65],
  [-10.799145, 165.841058, 65],
  [-10.799079, 165.841177, 65],
  [-10.799038, 165.841295, 65],
  [-10.799027, 165.841423, 65],
];
