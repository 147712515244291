import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.809119, 165.816468, 4.2],
  [-10.809223, 165.816532, 4.2],
  [-10.8093, 165.816629, 4.2],
  [-10.809343, 165.816725, 4.2],
  [-10.809363, 165.816854, 4.2],
  [-10.809367, 165.816972, 4.2],
  [-10.809359, 165.817101, 4.2],
  [-10.809318, 165.817208, 4.2],
  [-10.809216, 165.817262, 4.2],
  [-10.809103, 165.817294, 4],
  [-10.809011, 165.817391, 3.8],
  [-10.808985, 165.817498, 3.4],
  [-10.809041, 165.817616, 3.2],
  [-10.809146, 165.81768, 3],
  [-10.809265, 165.817723, 2.8],
  [-10.809387, 165.817766, 2.6],
  [-10.80951, 165.817798, 2.6],
  [-10.809632, 165.817831, 2.6],
  [-10.80975, 165.817873, 2.6],
  [-10.809855, 165.817949, 2.6],
  [-10.809917, 165.818056, 2.6],
  [-10.80991, 165.818185, 2.8],
  [-10.809831, 165.818292, 2.8],
  [-10.809717, 165.818313, 2.8],
  [-10.809591, 165.818335, 2.8],
  [-10.809469, 165.818335, 2.6],
  [-10.809345, 165.818335, 2.6],
  [-10.809221, 165.818335, 2.6],
  [-10.809098, 165.818346, 2.6],
  [-10.808981, 165.818399, 2.6],
  [-10.808893, 165.818485, 2.4],
  [-10.808842, 165.818592, 2.4],
  [-10.808797, 165.81871, 2.6],
  [-10.808706, 165.818785, 2.4],
  [-10.80859, 165.818775, 2.6],
  [-10.808522, 165.818667, 3],
  [-10.808478, 165.81856, 3.4],
  [-10.808428, 165.818453, 3.8],
  [-10.808353, 165.818367, 4.2],
  [-10.808249, 165.818313, 4.4],
  [-10.808143, 165.818346, 4.6],
  [-10.808069, 165.818442, 4.8],
  [-10.808023, 165.818528, 5],
  [-10.807985, 165.818635, 5.2],
  [-10.807934, 165.818743, 5.2],
  [-10.807873, 165.818839, 5.2],
  [-10.807813, 165.818925, 5.2],
  [-10.807755, 165.819021, 5.2],
  [-10.807696, 165.819129, 5.2],
  [-10.807637, 165.819215, 5],
  [-10.807572, 165.819322, 5],
  [-10.807502, 165.819418, 5],
  [-10.807427, 165.819504, 5],
  [-10.807335, 165.819569, 5],
  [-10.807228, 165.819612, 5],
  [-10.80712, 165.819665, 5.2],
  [-10.807034, 165.81974, 5.2],
  [-10.806995, 165.819848, 5.2],
  [-10.807, 165.819966, 5.2],
  [-10.807018, 165.820084, 5.2],
  [-10.806998, 165.820191, 5.2],
];

export default stream;
