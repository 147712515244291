import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [55.640414, -5.239623, 53.6],
  [55.640361, -5.239461, 53.6],
  [55.640334, -5.23928, 53.6],
  [55.640312, -5.239098, 53.6],
  [55.640291, -5.238916, 53.6],
  [55.640264, -5.238736, 53.6],
  [55.640237, -5.238556, 53.6],
  [55.640216, -5.238377, 53.6],
  [55.640189, -5.238198, 53.6],
  [55.640162, -5.23802, 53.8],
  [55.640135, -5.237841, 53.8],
  [55.640114, -5.237663, 53.8],
  [55.640092, -5.237486, 53.8],
  [55.64006, -5.237311, 53.8],
  [55.640044, -5.237134, 54],
  [55.640044, -5.236955, 54],
  [55.640082, -5.236785, 53.8],
  [55.640135, -5.23663, 53.4],
  [55.640211, -5.236495, 53.2],
  [55.640296, -5.236392, 53.2],
  [55.640393, -5.236321, 53.8],
  [55.640489, -5.236269, 54.6],
  [55.640581, -5.23623, 55.6],
  [55.640666, -5.236198, 56.4],
  [55.640747, -5.236172, 57.2],
  [55.640822, -5.23615, 58],
  [55.640886, -5.236129, 58.8],
  [55.640951, -5.236109, 59.4],
  [55.64101, -5.236089, 60],
  [55.641069, -5.236069, 60.4],
  [55.641122, -5.236051, 61],
  [55.641176, -5.236037, 61.4],
  [55.64123, -5.236027, 61.8],
  [55.641283, -5.236018, 62.2],
  [55.641337, -5.236009, 62.6],
  [55.641385, -5.235999, 63],
  [55.641434, -5.235984, 63.4],
  [55.641487, -5.235965, 63.6],
  [55.64153, -5.235941, 64],
  [55.641578, -5.235912, 64.4],
  [55.641621, -5.235877, 64.8],
  [55.641664, -5.235836, 65.2],
  [55.641702, -5.235789, 65.6],
  [55.641739, -5.235737, 66],
  [55.641777, -5.23568, 66.4],
  [55.641804, -5.235618, 66.8],
  [55.641831, -5.235552, 67.2],
  [55.641852, -5.235481, 67.4],
  [55.641879, -5.235406, 67.8],
  [55.641895, -5.235328, 68.2],
  [55.641906, -5.235246, 68.4],
  [55.641906, -5.23516, 68.8],
  [55.641906, -5.235073, 69],
  [55.641895, -5.234984, 69.2],
  [55.641879, -5.234894, 69.4],
  [55.641857, -5.234803, 69.6],
  [55.641831, -5.234713, 69.6],
  [55.641804, -5.234621, 69.8],
  [55.641772, -5.234527, 70],
  [55.641734, -5.23443, 70],
  [55.641702, -5.234328, 70.2],
  [55.64167, -5.234222, 70.4],
  [55.641643, -5.234112, 70.6],
  [55.641616, -5.233997, 70.6],
  [55.641605, -5.233875, 70.8],
  [55.641605, -5.233749, 71.2],
  [55.641643, -5.233639, 71.4],
  [55.641691, -5.23355, 71.6],
  [55.64175, -5.233474, 71.8],
  [55.641814, -5.233409, 71.8],
  [55.641879, -5.23335, 72],
  [55.641949, -5.233296, 72.2],
  [55.642018, -5.233246, 72.2],
  [55.642093, -5.233197, 72.4],
  [55.642163, -5.233149, 72.4],
  [55.642238, -5.2331, 72.6],
  [55.642313, -5.233049, 72.6],
  [55.642383, -5.232992, 72.8],
  [55.642458, -5.232925, 73],
  [55.642523, -5.232844, 73.4],
  [55.642582, -5.232742, 73.6],
  [55.642614, -5.232619, 74],
  [55.642624, -5.232486, 74.6],
  [55.642608, -5.232358, 75],
  [55.642571, -5.232252, 75.4],
  [55.642517, -5.232186, 75.8],
  [55.642447, -5.232155, 76],
  [55.642378, -5.232149, 76.2],
  [55.642308, -5.232155, 76.4],
  [55.642238, -5.232169, 76.4],
  [55.642163, -5.232187, 76.4],
  [55.642093, -5.232208, 76.6],
  [55.642013, -5.23223, 76.6],
  [55.641932, -5.232243, 76.6],
  [55.641852, -5.232234, 76.8],
  [55.641777, -5.232191, 77],
  [55.641718, -5.232099, 77],
  [55.641686, -5.231966, 77],
  [55.64168, -5.231818, 77],
  [55.641696, -5.231669, 77],
  [55.641723, -5.231523, 77],
  [55.641761, -5.231385, 77.2],
  [55.64182, -5.231273, 77.6],
  [55.641895, -5.2312, 78],
  [55.641975, -5.231155, 78.4],
  [55.642051, -5.231125, 78.8],
  [55.642126, -5.231102, 79.2],
  [55.642201, -5.231079, 79.6],
  [55.642276, -5.231052, 80],
  [55.642346, -5.23101, 80.4],
  [55.64241, -5.230947, 81],
  [55.642453, -5.23086, 81.4],
  [55.64248, -5.230758, 82],
  [55.64249, -5.23065, 82.6],
  [55.64249, -5.230546, 83],
  [55.642464, -5.230453, 83.6],
  [55.642437, -5.230376, 84],
  [55.642394, -5.230313, 84.4],
  [55.642351, -5.23026, 84.8],
  [55.642308, -5.230211, 85.2],
  [55.642265, -5.230163, 85.6],
  [55.642222, -5.230115, 86],
  [55.642179, -5.230065, 86.2],
  [55.642142, -5.230002, 86.6],
  [55.642115, -5.229922, 86.8],
  [55.642104, -5.229831, 87],
  [55.642093, -5.229732, 87],
  [55.642088, -5.229626, 87],
  [55.642083, -5.229515, 87],
  [55.642067, -5.229402, 87],
  [55.642024, -5.229306, 87],
  [55.641959, -5.229251, 87],
  [55.641879, -5.229251, 86.8],
  [55.641804, -5.229278, 86.6],
  [55.641723, -5.229289, 86.4],
  [55.641637, -5.229257, 86.4],
  [55.641573, -5.229154, 86.4],
  [55.641552, -5.229001, 86.4],
  [55.641552, -5.22884, 86.4],
  [55.641573, -5.228682, 86.4],
  [55.641605, -5.228528, 86.2],
  [55.641654, -5.228386, 86],
  [55.641734, -5.228302, 85.6],
  [55.641836, -5.228292, 85.2],
  [55.641938, -5.228311, 84.6],
  [55.642051, -5.228336, 84],
  [55.642158, -5.228366, 83],
  [55.64227, -5.228425, 82.2],
  [55.642388, -5.228498, 81.2],
  [55.642506, -5.228574, 80.4],
  [55.642608, -5.228708, 79.6],
  [55.642689, -5.22888, 79.2],
  [55.642764, -5.229068, 78.8],
  [55.642791, -5.229288, 78.2],
  [55.642732, -5.229488, 77.6],
  [55.642624, -5.229619, 77.2],
  [55.642501, -5.229659, 76.8],
  [55.642394, -5.22953, 76.4],
  [55.642367, -5.229308, 75.4],
  [55.642415, -5.229092, 74.6],
  [55.642506, -5.228916, 73.8],
  [55.642619, -5.228776, 73.2],
  [55.642743, -5.228684, 72.4],
  [55.642877, -5.228688, 71.4],
  [55.643005, -5.228801, 70.4],
  [55.643123, -5.22894, 69.6],
  [55.643225, -5.229104, 68.8],
  [55.643365, -5.229104, 67.8],
  [55.643451, -5.2289, 67.4],
  [55.643456, -5.228655, 67.2],
  [55.643392, -5.228444, 66.6],
  [55.643268, -5.228311, 65.2],
  [55.643134, -5.228219, 63.8],
  [55.642995, -5.228153, 62.4],
  [55.642844, -5.228097, 61],
  [55.6427, -5.228041, 59.6],
  [55.642555, -5.227953, 58.2],
  [55.64241, -5.227834, 57],
  [55.642276, -5.227694, 55.8],
  [55.642158, -5.227511, 54.8],
  [55.642083, -5.227272, 54.2],
  [55.642099, -5.227008, 54.2],
  [55.642222, -5.226869, 53.6],
  [55.642367, -5.226896, 52.6],
  [55.642501, -5.22701, 51.6],
  [55.642624, -5.227154, 50.8],
  [55.642748, -5.227306, 50.6],
  [55.642844, -5.227494, 51.2],
  [55.642925, -5.227691, 51.8],
  [55.643016, -5.227855, 52.2],
  [55.643134, -5.22795, 51.8],
  [55.643257, -5.227981, 50.6],
  [55.643392, -5.227954, 48.6],
  [55.643526, -5.22787, 46.8],
  [55.643654, -5.227747, 46],
  [55.643767, -5.227589, 45.6],
  [55.643853, -5.227389, 45.4],
  [55.643896, -5.227153, 45.4],
  [55.643869, -5.226917, 45.4],
  [55.643789, -5.226731, 45.4],
  [55.643676, -5.22663, 45.6],
  [55.643547, -5.226625, 46],
  [55.643435, -5.226701, 46.4],
  [55.643343, -5.226824, 46.8],
  [55.643241, -5.226921, 46.8],
  [55.643129, -5.226928, 46.4],
  [55.643021, -5.226866, 45.8],
  [55.64292, -5.22677, 45],
  [55.642812, -5.226659, 44.2],
  [55.64271, -5.22655, 43.4],
  [55.642587, -5.226463, 42.6],
  [55.642469, -5.226384, 41.8],
  [55.642346, -5.2263, 40.8],
  [55.642222, -5.226193, 39.8],
  [55.642115, -5.226052, 38.8],
  [55.642024, -5.225861, 37.8],
  [55.641997, -5.225621, 37],
  [55.642051, -5.225396, 37.2],
  [55.642147, -5.225219, 37.4],
  [55.642249, -5.225081, 37.4],
  [55.642372, -5.224996, 37],
  [55.642501, -5.225002, 36.4],
  [55.64263, -5.225061, 35.8],
  [55.642753, -5.22513, 35.4],
  [55.642882, -5.225188, 35.2],
  [55.643005, -5.225255, 35.4],
  [55.643118, -5.225353, 35],
  [55.643225, -5.22547, 34.6],
  [55.643306, -5.22564, 34],
  [55.643327, -5.225861, 33.6],
  [55.643268, -5.226059, 32.8],
  [55.64315, -5.226148, 32.2],
  [55.643021, -5.226104, 31.4],
  [55.642914, -5.225966, 30.8],
  [55.642882, -5.225743, 30.2],
  [55.64292, -5.225517, 29.8],
  [55.642989, -5.225311, 29.4],
  [55.643064, -5.225116, 28.8],
  [55.643134, -5.224922, 28.4],
  [55.643204, -5.224718, 28.2],
  [55.643257, -5.224506, 27.8],
  [55.643295, -5.224285, 27.8],
  [55.643306, -5.224061, 27.8],
  [55.643311, -5.223836, 27.8],
  [55.643322, -5.223618, 27.8],
  [55.643333, -5.223405, 27.8],
  [55.643343, -5.223189, 27.8],
];

export default stream;
