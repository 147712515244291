import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.791183, 165.843816, 66],
  [-10.791047, 165.843827, 66],
  [-10.790928, 165.843773, 66],
  [-10.790844, 165.843666, 66],
  [-10.790831, 165.843537, 66.4],
  [-10.790859, 165.843408, 67],
  [-10.7909, 165.843279, 67.6],
  [-10.790936, 165.843172, 68.2],
  [-10.790965, 165.843054, 68.6],
  [-10.790994, 165.842925, 69.2],
  [-10.791015, 165.842807, 69.8],
  [-10.791016, 165.8427, 70.4],
  [-10.791004, 165.842593, 70.8],
  [-10.790979, 165.842475, 71.4],
  [-10.790939, 165.842378, 72],
  [-10.790877, 165.842292, 72.4],
  [-10.790794, 165.842228, 73],
  [-10.790701, 165.842164, 73.6],
  [-10.790615, 165.84211, 74],
  [-10.790555, 165.842035, 74.6],
  [-10.790537, 165.841928, 75],
  [-10.79055, 165.841831, 75.4],
  [-10.7906, 165.841745, 76],
  [-10.790678, 165.841681, 76.6],
  [-10.790758, 165.841616, 77.2],
  [-10.790835, 165.841552, 77.6],
  [-10.790909, 165.841477, 78],
  [-10.790977, 165.841423, 78.2],
  [-10.791034, 165.841327, 78.4],
  [-10.791087, 165.841241, 78.2],
];

export default stream;
