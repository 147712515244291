import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [51.499245, -0.110978, 20.6],
  [51.499084, -0.111049, 19.4],
  [51.498912, -0.111046, 18.6],
  [51.49874, -0.111055, 17.6],
  [51.498579, -0.111059, 16.8],
  [51.498413, -0.111018, 16.4],
  [51.498258, -0.110955, 15.8],
  [51.498107, -0.110881, 14.8],
  [51.497952, -0.1108, 13.8],
  [51.497802, -0.11071, 12.6],
  [51.497657, -0.110612, 11.4],
  [51.497506, -0.110514, 10.2],
  [51.497356, -0.110418, 9.8],
  [51.497211, -0.110324, 9.8],
  [51.497067, -0.110235, 10],
  [51.496933, -0.110159, 9.6],
  [51.496788, -0.110112, 9],
  [51.496643, -0.110095, 8.6],
  [51.496498, -0.11009, 8.2],
  [51.496353, -0.110091, 7.6],
  [51.496208, -0.110091, 6.6],
  [51.496074, -0.110157, 5.6],
  [51.495967, -0.110312, 4.8],
];

export default stream;
