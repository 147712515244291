import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.76423, 165.844985, 102.4],
  [-10.764332, 165.844942, 102.4],
  [-10.764433, 165.844878, 102.4],
  [-10.764535, 165.844835, 102.2],
  [-10.76464, 165.844792, 102],
  [-10.764746, 165.844749, 101.6],
  [-10.764859, 165.844696, 101.2],
  [-10.764979, 165.844674, 100.8],
  [-10.765104, 165.844674, 100.2],
  [-10.765234, 165.844653, 99.4],
  [-10.765365, 165.844642, 98.8],
  [-10.765481, 165.844567, 98.4],
  [-10.765564, 165.84446, 98],
  [-10.765625, 165.844331, 97.8],
  [-10.765684, 165.844213, 97.6],
  [-10.765765, 165.844095, 97.2],
  [-10.765897, 165.844063, 96.6],
  [-10.766036, 165.844073, 95.8],
  [-10.76617, 165.844116, 95.2],
  [-10.766288, 165.844213, 94],
  [-10.766321, 165.844352, 92.2],
  [-10.76626, 165.844502, 90.4],
  [-10.766179, 165.844642, 88.6],
  [-10.766121, 165.844792, 87.2],
  [-10.766168, 165.844964, 86.4],
  [-10.76628, 165.845082, 86.8],
  [-10.76641, 165.845157, 87.4],
  [-10.766552, 165.8452, 87.6],
  [-10.766693, 165.845189, 87],
  [-10.766823, 165.845125, 86.4],
  [-10.766945, 165.84505, 85.6],
  [-10.76706, 165.844964, 84.8],
];

export default stream;
