import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [53.990507, -1.541537, 124.4],
  [53.990631, -1.541587, 124],
  [53.990754, -1.541639, 123.8],
  [53.990883, -1.541691, 123.4],
  [53.991017, -1.541746, 123],
  [53.991156, -1.541802, 122.8],
  [53.991301, -1.541859, 122.4],
  [53.991446, -1.541919, 122],
  [53.991591, -1.541978, 121.6],
  [53.991736, -1.542039, 121.2],
  [53.991886, -1.542099, 120.8],
  [53.992036, -1.542157, 120.4],
  [53.992192, -1.542215, 120],
  [53.992347, -1.542274, 119.6],
  [53.992503, -1.542333, 119.2],
  [53.992664, -1.542393, 118.8],
  [53.992819, -1.542456, 118.4],
  [53.99298, -1.542526, 118.2],
  [53.99313, -1.542611, 118],
  [53.993286, -1.542722, 117.8],
  [53.993431, -1.542841, 117.8],
  [53.993576, -1.542965, 117.6],
  [53.99372, -1.543095, 117],
  [53.993865, -1.543227, 115.8],
  [53.994015, -1.543359, 114.2],
  [53.994171, -1.543496, 112.2],
  [53.994332, -1.54364, 110.2],
  [53.994493, -1.543801, 108.4],
  [53.994648, -1.543989, 106.8],
  [53.994702, -1.544282, 105.8],
  [53.994616, -1.544567, 105.6],
  [53.994525, -1.544845, 105.8],
  [53.994439, -1.545116, 106],
  [53.994353, -1.545387, 106.2],
  [53.994273, -1.545648, 106.4],
  [53.994192, -1.545898, 106.6],
  [53.994101, -1.546149, 107],
  [53.993999, -1.546367, 107.6],
  [53.993881, -1.546554, 108.2],
  [53.993796, -1.546775, 108.4],
];
export default stream;
