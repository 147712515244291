import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [40.777605, -73.966919, 33.2],
  [40.777551, -73.966935, 33.2],
  [40.777498, -73.966962, 33.2],
  [40.777441, -73.966983, 33],
  [40.777382, -73.966999, 33],
  [40.777326, -73.96701, 32.8],
  [40.777275, -73.967037, 32.8],
  [40.777229, -73.967053, 32.6],
  [40.777189, -73.967063, 32.6],
  [40.777154, -73.96708, 32.4],
  [40.777119, -73.967096, 32.4],
  [40.77709, -73.967112, 32.2],
  [40.777066, -73.967117, 32.2],
  [40.777044, -73.967128, 32],
  [40.777017, -73.967133, 32],
  [40.776991, -73.967149, 31.8],
  [40.776958, -73.967155, 31.8],
  [40.776926, -73.967165, 31.6],
  [40.776886, -73.967181, 31.4],
  [40.776854, -73.967187, 31.2],
  [40.776819, -73.967198, 31.2],
  [40.776832, -73.967198, 31.2],
  [40.776803, -73.967203, 31],
  [40.776768, -73.967219, 30.8],
  [40.776728, -73.967224, 30.6],
  [40.776655, -73.967251, 30.2],
  [40.776556, -73.967273, 29.8],
  [40.776441, -73.967305, 29],
  [40.776304, -73.967326, 28.2],
  [40.776159, -73.967316, 27],
  [40.775998, -73.967289, 25.4],
  [40.775827, -73.967262, 23.8],
  [40.775639, -73.967305, 22.4],
  [40.775481, -73.967434, 21.6],
  [40.775357, -73.967621, 20.8],
  [40.775255, -73.967841, 20],
  [40.775151, -73.968072, 19],
  [40.775043, -73.968303, 18.2],
  [40.774912, -73.968506, 17.6],
  [40.774762, -73.968689, 17.2],
  [40.774595, -73.968861, 17.2],
  [40.774424, -73.969005, 17.2],
  [40.774244, -73.969134, 17.8],
  [40.774048, -73.969204, 19.4],
  [40.773858, -73.969215, 20.8],
];

export default stream;
