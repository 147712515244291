export const Z_INDEX = {
  previewRoute: 505,
  route: 506,
  segments: 507,
  places: 508,
  routeEnd: 509,
  routeStart: 509,
};

export const POLYLINE_WIDTH = 5;
export const POLYLINE_WIDTH_HIGHLIGHTED = 8;
