import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.791508, 165.843408, 66],
  [-10.791474, 165.843322, 66],
  [-10.791452, 165.843215, 66],
  [-10.791442, 165.843129, 65.8],
  [-10.791436, 165.843022, 65.6],
  [-10.791433, 165.842915, 65.4],
  [-10.79143, 165.842807, 65],
  [-10.791423, 165.8427, 64.8],
  [-10.79141, 165.842582, 64.8],
  [-10.791395, 165.842475, 65],
  [-10.791382, 165.842346, 65],
  [-10.791375, 165.842239, 65],
  [-10.791372, 165.842121, 65],
  [-10.791369, 165.842013, 65.2],
  [-10.791366, 165.841895, 65.2],
  [-10.79136, 165.841788, 65.2],
  [-10.79135, 165.841659, 65.4],
  [-10.791362, 165.841552, 65.6],
  [-10.791415, 165.841445, 65.8],
  [-10.791458, 165.841348, 66],
  [-10.791464, 165.841241, 66.2],
  [-10.791456, 165.841134, 66.2],
  [-10.791449, 165.841005, 66.2],
  [-10.791442, 165.840898, 66],
];

export default stream;
