import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.789726, 165.846637, 80],
  [-10.789863, 165.846637, 80],
  [-10.789998, 165.846627, 80],
  [-10.790132, 165.846627, 80],
  [-10.790265, 165.846605, 80],
  [-10.790397, 165.846595, 80],
  [-10.790527, 165.846595, 79.8],
  [-10.790658, 165.846573, 79.6],
  [-10.790792, 165.846562, 79.2],
  [-10.790918, 165.84653, 79],
  [-10.791033, 165.846455, 78.8],
  [-10.791128, 165.846359, 78.4],
  [-10.791198, 165.846241, 78.2],
  [-10.791248, 165.846123, 77.8],
  [-10.791275, 165.845983, 77.4],
  [-10.791288, 165.845844, 76.6],
  [-10.791289, 165.845704, 75.8],
  [-10.791282, 165.845554, 75.6],
  [-10.791273, 165.845414, 76.4],
  [-10.791264, 165.845275, 77],
  [-10.791257, 165.845146, 77.6],
  [-10.79125, 165.845017, 78],
  [-10.791244, 165.844899, 77.8],
  [-10.791239, 165.84476, 77.4],
  [-10.791234, 165.844642, 77.2],
  [-10.791225, 165.844502, 77.2],
  [-10.791216, 165.844384, 77.2],
  [-10.791205, 165.844245, 77.2],
  [-10.791197, 165.844116, 77.2],
  [-10.791193, 165.843987, 77.2],
  [-10.791214, 165.843859, 77.2],
  [-10.791252, 165.843741, 77],
  [-10.791301, 165.843612, 77],
  [-10.791358, 165.843505, 76.8],
  [-10.791417, 165.843408, 76.6],
  [-10.791478, 165.843301, 76.6],
  [-10.791537, 165.843172, 76.6],
  [-10.791589, 165.843065, 76.4],
  [-10.791635, 165.842957, 76.4],
  [-10.791669, 165.84285, 76.4],
  [-10.791686, 165.842732, 76.6],
  [-10.791686, 165.842625, 76.8],
  [-10.791674, 165.842507, 77.2],
  [-10.791661, 165.8424, 77.6],
  [-10.791652, 165.842303, 77.8],
  [-10.791653, 165.842196, 78],
  [-10.791657, 165.84211, 78.2],
  [-10.791663, 165.842003, 78.2],
  [-10.791667, 165.841906, 78.2],
  [-10.791668, 165.841799, 78.2],
  [-10.791667, 165.841713, 78.2],
  [-10.791666, 165.841616, 78.2],
  [-10.791661, 165.841509, 78.2],
  [-10.791649, 165.841423, 78.2],
  [-10.791628, 165.841327, 78.2],
  [-10.791603, 165.841241, 78.2],
];

export default stream;
