import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.717545, 166.966631, 13.8],
  [-11.717646, 166.966546, 13.8],
  [-11.717752, 166.966438, 13.8],
  [-11.717883, 166.966385, 13.8],
  [-11.718021, 166.966406, 13.8],
  [-11.718142, 166.96647, 13.8],
  [-11.718236, 166.966556, 13.8],
  [-11.71832, 166.966664, 13.8],
];

export default stream;
