import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.667094, 166.94394, 98.2],
  [-11.667124, 166.943908, 98.2],
  [-11.667152, 166.943865, 98.2],
  [-11.66718, 166.943822, 98.2],
  [-11.66721, 166.943779, 98.2],
  [-11.667239, 166.943725, 98.2],
  [-11.667269, 166.943682, 98.2],
  [-11.667299, 166.94364, 98.2],
  [-11.667332, 166.943597, 98.2],
  [-11.667361, 166.943543, 98.2],
  [-11.667393, 166.943489, 98.2],
  [-11.667425, 166.943436, 98.2],
  [-11.667459, 166.943393, 98],
  [-11.667495, 166.94335, 98],
  [-11.66753, 166.943307, 98],
  [-11.667568, 166.943243, 98],
  [-11.667605, 166.9432, 98],
  [-11.667645, 166.943146, 98.2],
  [-11.667687, 166.943103, 98.2],
  [-11.667731, 166.943071, 98.2],
  [-11.667777, 166.943028, 98.2],
  [-11.667822, 166.942996, 98.2],
  [-11.667869, 166.942953, 98.4],
  [-11.667915, 166.942921, 98.6],
  [-11.667961, 166.942878, 98.6],
  [-11.668006, 166.942846, 98.8],
  [-11.668052, 166.942813, 99],
  [-11.668097, 166.942803, 99.2],
  [-11.66814, 166.94277, 99.4],
  [-11.668183, 166.942749, 99.4],
  [-11.668226, 166.942728, 99.6],
  [-11.668269, 166.942706, 99.8],
  [-11.668312, 166.942695, 99.8],
  [-11.668355, 166.942674, 100],
  [-11.668395, 166.942663, 100],
  [-11.668437, 166.942642, 100],
  [-11.668481, 166.942642, 100],
  [-11.668524, 166.942631, 100.2],
  [-11.668569, 166.94262, 100.2],
  [-11.668615, 166.942599, 100.2],
  [-11.668662, 166.942599, 100.2],
  [-11.66871, 166.942588, 100],
  [-11.668761, 166.942567, 100],
  [-11.668814, 166.942567, 100],
  [-11.668866, 166.942556, 99.8],
  [-11.668925, 166.942534, 99.8],
  [-11.668981, 166.942534, 99.6],
  [-11.669042, 166.942524, 99.4],
  [-11.669105, 166.942513, 99.2],
  [-11.669168, 166.942492, 99],
  [-11.669235, 166.942481, 98.8],
  [-11.669304, 166.942459, 98.6],
  [-11.669375, 166.942459, 98.4],
  [-11.669449, 166.942449, 98.2],
  [-11.669524, 166.942438, 98],
  [-11.669602, 166.942416, 97.6],
  [-11.669681, 166.942406, 97.4],
  [-11.669764, 166.942406, 97.2],
  [-11.669848, 166.942384, 96.8],
  [-11.669933, 166.942374, 96.6],
  [-11.670021, 166.942374, 96.2],
  [-11.670109, 166.942352, 95.8],
  [-11.670202, 166.942352, 95.6],
  [-11.670294, 166.942341, 95.2],
  [-11.670388, 166.942341, 94.8],
  [-11.670483, 166.942341, 94.6],
  [-11.670581, 166.942341, 94.2],
  [-11.670679, 166.942352, 93.8],
  [-11.670778, 166.942352, 93.4],
  [-11.670876, 166.942374, 93.2],
  [-11.670979, 166.942384, 92.8],
  [-11.671079, 166.942406, 92.4],
  [-11.671179, 166.942416, 92.2],
  [-11.671278, 166.942449, 91.8],
  [-11.671379, 166.942481, 91.6],
  [-11.671477, 166.942513, 91.2],
  [-11.671575, 166.942534, 90.8],
  [-11.671674, 166.942588, 90.6],
  [-11.671771, 166.94262, 90.2],
  [-11.671869, 166.942663, 90],
  [-11.671965, 166.942706, 89.6],
  [-11.672062, 166.942749, 89.4],
  [-11.67216, 166.942781, 89],
  [-11.672256, 166.942824, 88.6],
  [-11.672353, 166.942878, 88.4],
  [-11.672448, 166.942921, 88],
  [-11.672544, 166.942964, 87.8],
  [-11.672641, 166.943017, 87.4],
  [-11.672739, 166.94306, 87.2],
  [-11.672834, 166.943103, 86.8],
  [-11.672932, 166.943167, 86.4],
  [-11.67303, 166.94321, 86.2],
  [-11.673128, 166.943243, 85.8],
  [-11.673227, 166.943285, 85.4],
  [-11.673328, 166.943328, 85.2],
  [-11.673431, 166.943361, 84.8],
  [-11.673534, 166.943393, 84.4],
  [-11.673642, 166.943425, 84],
  [-11.67375, 166.943457, 83.6],
  [-11.673861, 166.943468, 83],
  [-11.673971, 166.943468, 82.6],
  [-11.674084, 166.943468, 82],
  [-11.674198, 166.943457, 81.4],
  [-11.674312, 166.943414, 80.6],
  [-11.674422, 166.943382, 80],
  [-11.674537, 166.94335, 79.4],
  [-11.674655, 166.943318, 78.8],
  [-11.674767, 166.943275, 78.2],
  [-11.674874, 166.94321, 77.4],
  [-11.67498, 166.943146, 76.8],
  [-11.67509, 166.943092, 76.2],
  [-11.675204, 166.943028, 75.6],
  [-11.675325, 166.942996, 75.2],
  [-11.675448, 166.942964, 74.8],
  [-11.67557, 166.942953, 74.4],
  [-11.675693, 166.942931, 74],
  [-11.675814, 166.942931, 73.8],
  [-11.675933, 166.942921, 73.4],
  [-11.676057, 166.942921, 73.2],
  [-11.676172, 166.942921, 72.8],
  [-11.67629, 166.942953, 72.6],
  [-11.676406, 166.942964, 72.2],
  [-11.676522, 166.942985, 71.8],
  [-11.676638, 166.942985, 71.4],
  [-11.676739, 166.942921, 71],
  [-11.676811, 166.942824, 70.4],
  [-11.676843, 166.942706, 69.8],
  [-11.67682, 166.942599, 69.4],
  [-11.676773, 166.942481, 69],
  [-11.676748, 166.942374, 68.8],
  [-11.676782, 166.942245, 68.4],
  [-11.676859, 166.942159, 68],
  [-11.676961, 166.942116, 67.8],
  [-11.677073, 166.942116, 67.4],
  [-11.677181, 166.942159, 67.2],
  [-11.677276, 166.942223, 67],
  [-11.677374, 166.942277, 66.8],
  [-11.677478, 166.942309, 66.6],
  [-11.677591, 166.942309, 66.2],
  [-11.677701, 166.942298, 66],
  [-11.677807, 166.942266, 65.6],
  [-11.677913, 166.942234, 65.4],
  [-11.678016, 166.942202, 65.2],
  [-11.678118, 166.942159, 64.8],
  [-11.678216, 166.942116, 64.6],
  [-11.678311, 166.942052, 64.4],
  [-11.67839, 166.941987, 63.8],
  [-11.678414, 166.94188, 63.4],
  [-11.678381, 166.941773, 63],
  [-11.678314, 166.941687, 62.8],
  [-11.678227, 166.941622, 62.4],
  [-11.678119, 166.94159, 62.2],
  [-11.678011, 166.94159, 62],
  [-11.677902, 166.94159, 61.8],
  [-11.677795, 166.941612, 61.8],
  [-11.677689, 166.941612, 61.6],
  [-11.677586, 166.941612, 61.4],
  [-11.677485, 166.94158, 61.2],
  [-11.677403, 166.941504, 61],
  [-11.677347, 166.941429, 60.6],
  [-11.677312, 166.941322, 60.2],
  [-11.677304, 166.941215, 60],
  [-11.677326, 166.941108, 59.6],
  [-11.677383, 166.941011, 59.2],
  [-11.677466, 166.940957, 58.8],
  [-11.677555, 166.940893, 58.6],
  [-11.677647, 166.94085, 58.4],
  [-11.67774, 166.940786, 58.2],
  [-11.677834, 166.940743, 58],
  [-11.677924, 166.940678, 57.8],
  [-11.678007, 166.940614, 57.6],
  [-11.67809, 166.94056, 57.6],
  [-11.678169, 166.940496, 57.6],
  [-11.678241, 166.940421, 57.6],
  [-11.678311, 166.940356, 57.6],
  [-11.678376, 166.940281, 57.6],
  [-11.678436, 166.940206, 57.8],
  [-11.678496, 166.940142, 57.8],
  [-11.678549, 166.940067, 57.8],
  [-11.678598, 166.939992, 57.8],
  [-11.678644, 166.939917, 57.8],
  [-11.678687, 166.939842, 57.8],
  [-11.678727, 166.939766, 57.8],
  [-11.678763, 166.939691, 57.8],
  [-11.678801, 166.939605, 57.8],
  [-11.678836, 166.93953, 57.8],
  [-11.678869, 166.939455, 57.8],
  [-11.678901, 166.93938, 57.8],
  [-11.678924, 166.939305, 57.8],
];

export default stream;
