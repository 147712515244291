import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.681168, 166.9276, 21.4],
  [-11.681, 166.927664, 21.6],
  [-11.680878, 166.927782, 22],
  [-11.680787, 166.927933, 22.6],
  [-11.680697, 166.928072, 23.2],
  [-11.680572, 166.928201, 23.8],
  [-11.680421, 166.928276, 24.4],
  [-11.680264, 166.928319, 25],
  [-11.680104, 166.928383, 25.6],
  [-11.679954, 166.928437, 26.2],
  [-11.679815, 166.928533, 26.8],
  [-11.679717, 166.928651, 27.4],
  [-11.679654, 166.928802, 28],
  [-11.679608, 166.928962, 28.4],
  [-11.679561, 166.929113, 29],
  [-11.679486, 166.929263, 29.4],
  [-11.679403, 166.929402, 30],
  [-11.679308, 166.929542, 30.6],
  [-11.679202, 166.92966, 31],
  [-11.679084, 166.929767, 31.6],
  [-11.678951, 166.929864, 32.4],
  [-11.678813, 166.929939, 33],
  [-11.678684, 166.930025, 33.6],
  [-11.678573, 166.930153, 34.4],
  [-11.678492, 166.930282, 35],
  [-11.678436, 166.930443, 35.6],
  [-11.678393, 166.930593, 36.2],
  [-11.67836, 166.930743, 36.6],
  [-11.678331, 166.930894, 37.2],
  [-11.678321, 166.931065, 37.8],
  [-11.678339, 166.931216, 38.4],
  [-11.678358, 166.931366, 39],
  [-11.678345, 166.931537, 39.8],
  [-11.678279, 166.931677, 40.4],
  [-11.678149, 166.931752, 41.2],
  [-11.677999, 166.931816, 41.6],
  [-11.677849, 166.931859, 42.2],
  [-11.677706, 166.931924, 42.8],
  [-11.677575, 166.931999, 43.2],
  [-11.677481, 166.932138, 43.8],
  [-11.677433, 166.932288, 44.2],
  [-11.677417, 166.932439, 44.6],
  [-11.677449, 166.932589, 45],
  [-11.677567, 166.932696, 45.6],
  [-11.677713, 166.93276, 46.2],
  [-11.677853, 166.932846, 46.8],
  [-11.677893, 166.932986, 47.6],
  [-11.677836, 166.933136, 48.2],
  [-11.677753, 166.933275, 48.8],
  [-11.67767, 166.933404, 49.4],
  [-11.67765, 166.933554, 50.2],
  [-11.677755, 166.933672, 50.6],
  [-11.677902, 166.933715, 51],
  [-11.678043, 166.933662, 51.6],
  [-11.678174, 166.933565, 52.2],
  [-11.678325, 166.933565, 52.8],
  [-11.678389, 166.933705, 53.4],
  [-11.678331, 166.933855, 54],
  [-11.678254, 166.933994, 54.6],
  [-11.678252, 166.934144, 54.6],
  [-11.678208, 166.934295, 54.6],
  [-11.678094, 166.934423, 54.6],
  [-11.677957, 166.934509, 54.8],
  [-11.677818, 166.934606, 55.2],
  [-11.677688, 166.934713, 55.4],
  [-11.677588, 166.934831, 55.4],
  [-11.677516, 166.935003, 55],
  [-11.677499, 166.935174, 55],
  [-11.677598, 166.935303, 55.6],
  [-11.677733, 166.93541, 56.2],
  [-11.677838, 166.935539, 56.4],
  [-11.677934, 166.935689, 56.6],
  [-11.678091, 166.935732, 57],
  [-11.678255, 166.935689, 57.2],
  [-11.678406, 166.935625, 57.6],
  [-11.678558, 166.935539, 58],
  [-11.678707, 166.935464, 58.2],
  [-11.678862, 166.9354, 58.6],
  [-11.679031, 166.935378, 59],
  [-11.679191, 166.935443, 59.4],
  [-11.679329, 166.935539, 59.6],
  [-11.679435, 166.935668, 59.8],
  [-11.679446, 166.93584, 59.4],
  [-11.679392, 166.93599, 58.6],
  [-11.679379, 166.936172, 57.8],
  [-11.67945, 166.936344, 57.4],
  [-11.679538, 166.936494, 57.2],
  [-11.679579, 166.936677, 56.8],
  [-11.67955, 166.936859, 56.8],
  [-11.679486, 166.937041, 56.6],
  [-11.679399, 166.937202, 56.8],
  [-11.679285, 166.937352, 56.8],
  [-11.679143, 166.93746, 57],
  [-11.678987, 166.937546, 57.4],
  [-11.678842, 166.937653, 57.6],
  [-11.678758, 166.937814, 57.8],
  [-11.678762, 166.937996, 57.8],
  [-11.678809, 166.938157, 57.8],
];

export default stream;
