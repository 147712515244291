import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.758004, 165.849191, 136.4],
  [-10.757932, 165.849127, 136.6],
  [-10.757872, 165.849051, 136.6],
  [-10.757812, 165.848955, 136.6],
  [-10.757754, 165.84888, 136.6],
  [-10.757693, 165.848794, 136.6],
  [-10.757634, 165.848708, 136.6],
  [-10.757575, 165.848633, 136.6],
  [-10.757517, 165.848547, 136.6],
  [-10.757457, 165.848472, 136.6],
  [-10.757398, 165.848376, 136.6],
  [-10.757337, 165.8483, 136.6],
  [-10.757276, 165.848225, 136.4],
  [-10.757212, 165.848129, 136.2],
  [-10.757146, 165.848054, 136.2],
  [-10.757078, 165.847979, 136.2],
];

export default stream;
