import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [53.99364, -1.54735, 108.4],
  [53.993661, -1.54761, 109.8],
  [53.993656, -1.547857, 111.2],
  [53.993613, -1.548089, 112.4],
  [53.993549, -1.54829, 113.4],
  [53.993468, -1.548475, 114.2],
  [53.993388, -1.548648, 115],
  [53.993313, -1.548823, 115.8],
  [53.993232, -1.548994, 116.6],
  [53.993157, -1.549162, 117.4],
  [53.993082, -1.549326, 118.2],
  [53.993007, -1.549485, 119],
  [53.992932, -1.549649, 119.8],
  [53.992862, -1.549808, 120.4],
  [53.992787, -1.549965, 121.2],
  [53.992717, -1.550125, 122],
  [53.992648, -1.550282, 122.6],
  [53.992572, -1.550444, 123.4],
  [53.992513, -1.550614, 123.6],
  [53.992465, -1.550797, 124],
  [53.992411, -1.550983, 124.2],
  [53.992358, -1.551174, 124.4],
  [53.99231, -1.551366, 124.6],
  [53.992261, -1.551565, 124.8],
  [53.992208, -1.551766, 125],
  [53.992159, -1.551966, 125.2],
  [53.992106, -1.552171, 125.6],
  [53.992047, -1.552377, 125.8],
  [53.991993, -1.552582, 126.2],
  [53.991939, -1.552787, 126.4],
  [53.991886, -1.552993, 126.8],
  [53.991827, -1.5532, 127],
  [53.991773, -1.55341, 127.4],
  [53.991714, -1.553616, 127.6],
  [53.99166, -1.553824, 128],
  [53.991607, -1.554033, 128.2],
  [53.991548, -1.554244, 128.6],
  [53.991489, -1.554453, 129],
  [53.991435, -1.554663, 129.2],
  [53.991376, -1.554874, 129.6],
  [53.991317, -1.555085, 129.8],
  [53.991264, -1.555297, 130.2],
  [53.99121, -1.55551, 130.6],
  [53.991156, -1.555726, 130.8],
  [53.991108, -1.555938, 131.2],
  [53.99106, -1.556158, 131.4],
  [53.991011, -1.556377, 131.8],
  [53.99099, -1.556608, 132],
  [53.99106, -1.556795, 132.2],
  [53.991183, -1.55689, 132],
  [53.991317, -1.556966, 131.8],
  [53.991462, -1.557036, 131.4],
  [53.991601, -1.557097, 131],
  [53.991746, -1.557178, 130.8],
  [53.99188, -1.557293, 130.6],
  [53.99195, -1.557503, 130.8],
  [53.99195, -1.557762, 131.2],
  [53.99195, -1.558013, 131.4],
  [53.991982, -1.558256, 131.8],
  [53.992025, -1.558451, 132],
  [53.992079, -1.558604, 132.2],
  [53.992122, -1.558713, 132.4],
  [53.992154, -1.558792, 132.6],
  [53.992175, -1.558842, 132.6],
  [53.992186, -1.558865, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992186, -1.558866, 132.6],
  [53.992192, -1.558872, 132.6],
  [53.992202, -1.558897, 132.8],
  [53.992218, -1.55893, 132.8],
  [53.99224, -1.558968, 132.8],
  [53.992267, -1.559008, 132.8],
  [53.992293, -1.55905, 133],
  [53.99232, -1.559092, 133],
  [53.992358, -1.55913, 133],
  [53.992401, -1.559164, 133],
  [53.992449, -1.559195, 133],
  [53.992497, -1.559228, 132.8],
  [53.992546, -1.559263, 132.8],
  [53.992599, -1.559296, 132.8],
  [53.992637, -1.559321, 132.8],
  [53.992653, -1.559335, 132.6],
  [53.992658, -1.559337, 132.6],
  [53.992664, -1.559343, 132.6],
  [53.992674, -1.559351, 132.6],
  [53.99268, -1.559357, 132.6],
  [53.99268, -1.559362, 132.6],
  [53.992701, -1.55938, 132.6],
  [53.992739, -1.5594, 132.6],
  [53.992771, -1.559423, 132.6],
  [53.992814, -1.559448, 132.6],
  [53.992867, -1.55948, 132.4],
  [53.992943, -1.559521, 132.2],
  [53.993023, -1.559566, 132],
  [53.993109, -1.559619, 131.8],
  [53.993205, -1.559676, 131.4],
  [53.993313, -1.559739, 130.8],
  [53.993431, -1.559806, 130],
  [53.993554, -1.559878, 129.2],
  [53.993683, -1.559953, 128.4],
  [53.993817, -1.560032, 127.4],
  [53.993956, -1.560114, 126.6],
  [53.994101, -1.560207, 125.8],
  [53.994246, -1.560305, 125],
  [53.994402, -1.560305, 124.2],
  [53.994498, -1.560088, 124.2],
  [53.994552, -1.559829, 124.4],
  [53.994606, -1.559571, 124.6],
  [53.99467, -1.559322, 124.8],
  [53.994724, -1.559084, 125],
  [53.994788, -1.558837, 125.2],
  [53.994847, -1.558593, 125.2],
  [53.994911, -1.55836, 125.2],
  [53.994986, -1.558124, 125.2],
  [53.995056, -1.557894, 124.8],
  [53.995137, -1.557665, 124.4],
  [53.995217, -1.557438, 123.8],
  [53.995308, -1.557209, 123.2],
  [53.995405, -1.556988, 122.6],
  [53.995507, -1.556765, 122],
  [53.995614, -1.556553, 121.6],
  [53.995727, -1.556341, 121.2],
  [53.995839, -1.556139, 120.8],
  [53.995957, -1.555931, 120.2],
  [53.996075, -1.555725, 119.8],
  [53.996193, -1.555526, 119.4],
  [53.996311, -1.55532, 119],
  [53.996429, -1.555121, 118.6],
  [53.996553, -1.554916, 118.2],
  [53.996671, -1.554714, 117.8],
  [53.996794, -1.554508, 117.4],
  [53.996907, -1.554305, 116.8],
  [53.997025, -1.554096, 116.4],
  [53.997143, -1.553885, 115.8],
  [53.997266, -1.553673, 115.4],
  [53.997384, -1.553461, 114.8],
  [53.997497, -1.553254, 114],
  [53.997615, -1.553027, 113],
  [53.997717, -1.552784, 112.2],
  [53.997813, -1.552534, 111.4],
  [53.997915, -1.552285, 110.6],
  [53.998012, -1.552037, 110],
  [53.998114, -1.551784, 109.8],
  [53.998194, -1.551523, 109.6],
  [53.998264, -1.551245, 109.6],
  [53.998328, -1.550976, 109.8],
  [53.998387, -1.550707, 110],
  [53.998452, -1.550446, 110],
  [53.998564, -1.550248, 109.4],
  [53.998709, -1.550105, 108.4],
  [53.99886, -1.549968, 107],
  [53.999015, -1.54982, 105.6],
  [53.99916, -1.54965, 104.6],
  [53.999305, -1.549466, 103.6],
  [53.999439, -1.549256, 103],
  [53.999519, -1.548994, 103.2],
  [53.999562, -1.548703, 103.8],
  [53.999594, -1.548414, 104.2],
  [53.999621, -1.548136, 104.6],
  [53.999648, -1.547867, 105],
  [53.999675, -1.54759, 105.6],
  [53.999691, -1.547331, 106],
  [53.999621, -1.54711, 106.6],
  [53.999476, -1.547034, 107.4],
  [53.999332, -1.546994, 108.6],
  [53.999192, -1.546957, 109.6],
  [53.999058, -1.546914, 110.4],
  [53.998924, -1.546868, 111.4],
  [53.998795, -1.546824, 112.2],
  [53.998672, -1.546781, 113],
  [53.998543, -1.546738, 113.8],
  [53.99842, -1.546696, 114.4],
  [53.998302, -1.546654, 114.8],
  [53.998178, -1.546611, 115.2],
  [53.998049, -1.546565, 115],
  [53.997921, -1.546515, 114.8],
  [53.997792, -1.54646, 114.4],
  [53.997658, -1.5464, 114.2],
  [53.997518, -1.546337, 113.8],
  [53.997379, -1.546253, 113.4],
  [53.997245, -1.54615, 113],
  [53.997105, -1.546039, 112.4],
  [53.996971, -1.545927, 112],
  [53.996832, -1.545811, 111.4],
  [53.996687, -1.545691, 111],
  [53.996542, -1.545573, 110.4],
  [53.996397, -1.545458, 109.8],
  [53.996236, -1.545435, 109.4],
  [53.996113, -1.545613, 109.6],
  [53.996011, -1.545826, 109.6],
  [53.995909, -1.54604, 109.8],
  [53.995807, -1.546249, 109.8],
  [53.9957, -1.546443, 109.8],
  [53.995577, -1.546615, 109.6],
  [53.995448, -1.546782, 109.4],
  [53.995319, -1.546938, 109.2],
  [53.995185, -1.547084, 108.8],
  [53.995051, -1.547219, 108.6],
  [53.994895, -1.547302, 108.6],
  [53.994734, -1.547292, 108.6],
  [53.994579, -1.547258, 108.6],
  [53.994423, -1.547217, 108.4],
  [53.994262, -1.54716, 108.4],
  [53.994107, -1.5471, 108.4],
];
export default stream;
