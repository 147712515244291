import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [51.506926, -0.127501, 25.8],
  [51.507012, -0.127525, 25.8],
  [51.507103, -0.127519, 25.8],
  [51.507189, -0.127467, 25.4],
  [51.50727, -0.127385, 25],
  [51.507339, -0.12728, 24.8],
  [51.507393, -0.127145, 24.4],
  [51.507365, -0.12693, 23.8],
  [51.507357, -0.126852, 23.4],
];

export default stream;
