import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.795242, 165.812595, 4.8],
  [-10.795319, 165.812659, 4.8],
  [-10.795398, 165.812734, 4.8],
  [-10.795481, 165.812799, 4.8],
  [-10.795571, 165.812842, 4.8],
  [-10.79567, 165.812874, 4.8],
  [-10.795769, 165.812852, 4.8],
  [-10.79586, 165.812809, 4.8],
  [-10.795938, 165.812745, 4.8],
  [-10.796012, 165.81267, 4.8],
  [-10.796102, 165.812627, 4.8],
  [-10.796197, 165.812627, 5],
  [-10.796263, 165.81267, 5.2],
  [-10.796304, 165.812702, 5.4],
  [-10.79633, 165.812734, 5.6],
  [-10.796339, 165.812745, 5.6],
  [-10.796346, 165.812745, 5.6],
  [-10.796353, 165.812745, 5.8],
  [-10.796359, 165.812767, 5.8],
  [-10.796355, 165.812767, 5.8],
  [-10.796364, 165.812767, 5.8],
  [-10.796385, 165.812777, 5.8],
  [-10.796412, 165.812799, 6],
  [-10.796445, 165.812809, 6.2],
  [-10.796485, 165.81282, 6.2],
  [-10.796529, 165.81282, 6.4],
  [-10.796577, 165.81282, 6.4],
  [-10.796628, 165.81282, 6.6],
  [-10.796679, 165.812809, 6.6],
  [-10.796729, 165.812777, 6.6],
  [-10.796777, 165.812745, 6.6],
  [-10.796818, 165.812691, 6.8],
  [-10.796852, 165.812638, 6.8],
  [-10.796878, 165.812584, 7],
  [-10.796898, 165.812509, 7],
  [-10.796913, 165.812445, 7.2],
  [-10.796922, 165.81237, 7.2],
  [-10.796929, 165.812294, 7.4],
  [-10.796935, 165.812219, 7.6],
  [-10.796942, 165.812155, 7.8],
  [-10.796949, 165.81208, 8],
  [-10.796964, 165.812005, 8.2],
  [-10.796992, 165.811919, 8.4],
  [-10.79704, 165.811876, 8.8],
  [-10.797108, 165.811865, 9.2],
  [-10.797177, 165.811865, 9.4],
  [-10.797244, 165.811876, 9.6],
  [-10.79731, 165.811898, 9.8],
  [-10.797374, 165.811919, 10],
  [-10.797436, 165.811951, 10.2],
  [-10.797495, 165.811983, 10.4],
  [-10.797554, 165.812026, 10.6],
  [-10.797609, 165.81208, 10.8],
  [-10.797662, 165.812123, 10.8],
  [-10.797714, 165.812166, 11],
  [-10.797763, 165.81223, 11],
  [-10.797809, 165.812294, 11.2],
  [-10.797852, 165.812348, 11.2],
  [-10.797891, 165.812413, 11.2],
  [-10.797925, 165.812488, 11.2],
  [-10.797954, 165.812563, 11.2],
  [-10.797975, 165.812638, 11.2],
  [-10.797985, 165.812734, 11.2],
  [-10.797983, 165.812809, 11],
  [-10.797965, 165.812906, 10.8],
  [-10.797924, 165.812981, 10.4],
  [-10.797861, 165.813056, 10],
  [-10.79778, 165.81311, 9.6],
  [-10.797689, 165.813164, 9.2],
  [-10.79759, 165.813196, 8.8],
  [-10.797489, 165.813217, 8.2],
  [-10.797383, 165.813249, 7.8],
  [-10.797274, 165.813271, 7.4],
  [-10.797163, 165.813292, 7],
  [-10.797053, 165.813324, 6.4],
  [-10.796943, 165.813378, 6],
  [-10.796836, 165.813421, 5.6],
  [-10.796738, 165.813485, 5.2],
  [-10.796666, 165.813593, 4.8],
  [-10.79666, 165.813711, 4.6],
  [-10.7967, 165.813818, 4.6],
  [-10.79675, 165.813925, 4.8],
  [-10.796795, 165.814033, 4.8],
  [-10.796811, 165.81414, 5],
  [-10.796805, 165.814247, 5],
  [-10.796777, 165.814333, 5],
  [-10.796721, 165.81443, 5],
  [-10.796654, 165.814505, 5],
  [-10.796588, 165.814569, 5],
  [-10.796522, 165.814644, 5],
  [-10.796455, 165.814698, 5],
  [-10.796385, 165.814762, 5],
  [-10.796312, 165.814826, 5],
  [-10.796237, 165.814869, 5],
  [-10.796158, 165.814912, 5],
  [-10.796076, 165.814945, 5],
  [-10.795991, 165.814966, 5],
  [-10.795905, 165.814945, 5],
  [-10.795826, 165.814912, 5],
  [-10.795747, 165.81488, 5],
  [-10.795671, 165.814859, 5],
  [-10.795593, 165.814826, 5],
  [-10.79551, 165.814805, 5],
  [-10.795429, 165.814805, 5],
  [-10.795354, 165.814837, 5],
  [-10.795284, 165.81488, 5],
  [-10.795221, 165.814945, 5],
  [-10.795164, 165.815009, 5],
  [-10.795114, 165.815063, 5],
  [-10.795069, 165.815127, 5],
  [-10.79503, 165.815202, 5],
  [-10.794997, 165.815277, 5],
  [-10.794967, 165.815352, 5],
  [-10.794943, 165.815438, 5],
  [-10.794924, 165.815513, 5],
  [-10.794912, 165.815588, 5],
  [-10.794914, 165.815663, 5],
  [-10.794932, 165.815738, 5],
  [-10.794961, 165.815814, 5],
  [-10.794999, 165.815878, 5],
  [-10.795043, 165.815953, 5],
  [-10.795092, 165.816017, 5],
  [-10.795142, 165.816071, 5],
  [-10.795193, 165.816135, 5],
  [-10.795246, 165.8162, 5],
  [-10.795302, 165.816243, 5],
  [-10.795363, 165.816307, 5],
  [-10.795427, 165.81635, 5],
  [-10.795495, 165.816393, 5],
  [-10.795565, 165.816457, 5],
  [-10.795638, 165.8165, 5],
  [-10.795714, 165.816543, 5],
];

export default stream;
