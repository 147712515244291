import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [40.793926, -73.954983, 28.4],
  [40.793835, -73.955004, 28.4],
  [40.793749, -73.955026, 28.4],
  [40.793655, -73.955042, 28.4],
  [40.793564, -73.955058, 28.4],
  [40.79347, -73.955074, 28.4],
  [40.793374, -73.955079, 28.4],
  [40.793274, -73.955074, 28.2],
  [40.793175, -73.955063, 27.8],
  [40.793076, -73.955058, 27.6],
  [40.792974, -73.955042, 27.4],
  [40.792869, -73.955026, 27.2],
  [40.792765, -73.95502, 27.2],
  [40.792657, -73.95501, 27],
  [40.79255, -73.955004, 27.2],
  [40.792448, -73.95501, 27.2],
  [40.792349, -73.955058, 27.2],
  [40.792252, -73.955117, 27.2],
  [40.792164, -73.955192, 27.2],
  [40.792083, -73.955278, 27.2],
  [40.792006, -73.955364, 27],
  [40.79192, -73.955455, 27],
  [40.791839, -73.955546, 27],
  [40.791762, -73.955637, 26.8],
  [40.791684, -73.955728, 26.6],
  [40.791603, -73.955825, 26.4],
  [40.791523, -73.955927, 26.2],
  [40.791448, -73.956023, 26],
  [40.791367, -73.956125, 25.8],
  [40.791287, -73.956222, 25.8],
  [40.791206, -73.956324, 25.6],
  [40.791121, -73.95642, 25.6],
  [40.791035, -73.956512, 25.4],
  [40.790949, -73.956608, 25.4],
  [40.79086, -73.956689, 25.6],
  [40.790769, -73.956764, 25.8],
  [40.790681, -73.956844, 26],
  [40.790592, -73.956919, 26.4],
  [40.790506, -73.956978, 26.8],
  [40.790418, -73.957048, 27.2],
  [40.790335, -73.957107, 27.6],
  [40.790254, -73.957161, 27.8],
  [40.790171, -73.957214, 28.2],
  [40.790091, -73.957268, 28.6],
  [40.79001, -73.957316, 29],
  [40.78993, -73.957359, 29.2],
  [40.789852, -73.957397, 29.4],
  [40.789769, -73.95744, 29.6],
  [40.789686, -73.957461, 29.6],
  [40.789597, -73.957477, 29.6],
  [40.789509, -73.957466, 29.6],
  [40.789423, -73.957445, 29.6],
  [40.789334, -73.957413, 29.6],
  [40.789251, -73.95737, 29.8],
  [40.789171, -73.957316, 29.8],
  [40.78909, -73.957252, 30],
  [40.78901, -73.957193, 30],
  [40.788935, -73.957134, 30.2],
  [40.788854, -73.95707, 30.4],
  [40.788771, -73.957027, 30.6],
  [40.788685, -73.956989, 31],
  [40.788602, -73.956951, 31.4],
  [40.788521, -73.956925, 31.8],
  [40.788438, -73.956909, 32.2],
  [40.788358, -73.956903, 32.8],
  [40.788283, -73.956919, 33.4],
  [40.78821, -73.956925, 33.8],
  [40.788135, -73.956941, 34.2],
  [40.788063, -73.956957, 34.2],
  [40.78799, -73.956962, 34.4],
  [40.787915, -73.956978, 34.4],
  [40.787843, -73.956989, 34.6],
  [40.787762, -73.956994, 34.8],
  [40.787687, -73.957005, 35.2],
  [40.78761, -73.957011, 35.6],
  [40.787537, -73.957011, 36],
  [40.787465, -73.957011, 36.2],
  [40.787392, -73.957011, 36.6],
  [40.78732, -73.957011, 37],
  [40.787253, -73.957016, 37.4],
  [40.787183, -73.957027, 37.6],
  [40.787113, -73.957032, 38],
  [40.787049, -73.957048, 38.2],
  [40.786982, -73.95707, 38.4],
  [40.786915, -73.957096, 38.6],
  [40.786848, -73.957123, 38.8],
  [40.786783, -73.957161, 39],
  [40.786714, -73.957198, 39],
  [40.786644, -73.957241, 39.2],
  [40.78658, -73.957284, 39.2],
  [40.786507, -73.957332, 39.4],
  [40.78644, -73.957375, 39.4],
  [40.786368, -73.957429, 39.4],
  [40.786295, -73.957477, 39.6],
  [40.786223, -73.957531, 39.6],
  [40.78615, -73.957585, 39.8],
  [40.786073, -73.957638, 39.8],
  [40.786, -73.957681, 40],
  [40.785925, -73.957735, 40.2],
  [40.785853, -73.957788, 40.4],
  [40.785775, -73.957842, 40.6],
  [40.785702, -73.957896, 40.8],
  [40.78563, -73.957955, 41],
  [40.785558, -73.958008, 41.2],
  [40.785483, -73.958062, 41.2],
  [40.78541, -73.958126, 41.4],
  [40.785332, -73.95818, 41.4],
  [40.785257, -73.958228, 41.4],
  [40.785179, -73.958293, 41.4],
  [40.785099, -73.958346, 41.4],
  [40.785021, -73.958405, 41.4],
  [40.784941, -73.958459, 41.4],
  [40.78486, -73.958523, 41.4],
  [40.784777, -73.958582, 41.4],
  [40.784691, -73.958641, 41.4],
  [40.784608, -73.958706, 41.4],
  [40.784525, -73.958765, 41.4],
  [40.784439, -73.958824, 41.4],
  [40.784356, -73.958888, 41.4],
  [40.784267, -73.958958, 41.2],
  [40.784182, -73.959017, 41.2],
  [40.784093, -73.959076, 41.2],
  [40.784005, -73.959146, 41.2],
  [40.783913, -73.959215, 41.2],
  [40.783825, -73.959274, 41.2],
  [40.783739, -73.959339, 41.2],
  [40.783648, -73.959408, 41.4],
  [40.783559, -73.959473, 41.4],
  [40.783471, -73.959537, 41.2],
  [40.78338, -73.959602, 41.2],
  [40.783291, -73.959671, 41.2],
  [40.7832, -73.959736, 41.2],
  [40.783109, -73.9598, 41],
  [40.783018, -73.959864, 41],
  [40.782926, -73.959934, 40.8],
  [40.782835, -73.959998, 40.8],
  [40.782741, -73.960068, 40.8],
  [40.78265, -73.960138, 40.6],
  [40.782556, -73.960208, 40.6],
  [40.782465, -73.960277, 40.4],
  [40.782368, -73.960342, 40.4],
  [40.782277, -73.960412, 40.2],
  [40.782181, -73.960481, 40.2],
  [40.78209, -73.960551, 40],
  [40.781996, -73.960621, 39.8],
  [40.781899, -73.960696, 39.8],
  [40.781808, -73.960782, 39.6],
  [40.781727, -73.960873, 39.2],
  [40.781671, -73.961002, 38.8],
  [40.781628, -73.961136, 38.4],
  [40.781599, -73.961281, 38.2],
  [40.781583, -73.961436, 38],
  [40.781583, -73.961586, 37.6],
  [40.781599, -73.961747, 37.4],
  [40.781626, -73.961892, 37.2],
  [40.781652, -73.962048, 37],
  [40.781679, -73.962198, 36.6],
  [40.781698, -73.962348, 36.6],
  [40.781706, -73.962504, 36.4],
  [40.781698, -73.962665, 36.4],
  [40.781671, -73.962809, 36.6],
  [40.781639, -73.962954, 36.8],
  [40.781601, -73.963088, 37],
  [40.781556, -73.963223, 37.2],
  [40.78151, -73.963351, 37.4],
  [40.781465, -73.963475, 37.6],
  [40.781411, -73.963593, 37.8],
  [40.781357, -73.963711, 38],
  [40.78129, -73.963802, 38],
  [40.78121, -73.963882, 38],
  [40.781129, -73.963968, 38],
  [40.781046, -73.964043, 37.8],
  [40.780958, -73.964118, 37.6],
  [40.780869, -73.964188, 37.4],
  [40.780778, -73.964263, 37.2],
  [40.780687, -73.964333, 37],
  [40.780596, -73.964408, 36.8],
  [40.780499, -73.964489, 36.6],
  [40.780405, -73.964558, 36.4],
  [40.780309, -73.964633, 36.2],
  [40.780215, -73.964708, 36],
  [40.780118, -73.964784, 35.8],
  [40.780022, -73.964859, 35.4],
  [40.779922, -73.964939, 35],
  [40.779823, -73.96502, 34.8],
  [40.779724, -73.965105, 34.6],
  [40.77963, -73.965191, 34.4],
  [40.779531, -73.965272, 34.2],
  [40.779431, -73.965363, 34.2],
  [40.779335, -73.965449, 34.2],
  [40.779241, -73.965535, 34],
  [40.779144, -73.965615, 34],
  [40.779051, -73.965701, 34],
  [40.778959, -73.965781, 33.8],
  [40.778866, -73.965867, 33.8],
  [40.778772, -73.965958, 33.8],
  [40.77868, -73.966044, 33.8],
  [40.778592, -73.966125, 33.8],
  [40.778503, -73.966221, 33.8],
  [40.778418, -73.966302, 33.8],
  [40.778326, -73.966388, 33.8],
  [40.778238, -73.966468, 33.6],
  [40.778147, -73.966554, 33.6],
  [40.778058, -73.966629, 33.6],
  [40.777986, -73.966683, 33.6],
  [40.777929, -73.966731, 33.6],
  [40.777889, -73.966763, 33.6],
  [40.777862, -73.966784, 33.6],
  [40.777852, -73.96679, 33.6],
  [40.777849, -73.96679, 33.4],
  [40.777841, -73.966801, 33.4],
  [40.77783, -73.966806, 33.4],
  [40.777814, -73.966822, 33.4],
  [40.777798, -73.966827, 33.4],
  [40.777785, -73.966838, 33.4],
  [40.77776, -73.966854, 33.4],
  [40.777742, -73.96686, 33.4],
  [40.777715, -73.966876, 33.4],
  [40.777691, -73.966892, 33.4],
];

export default stream;
