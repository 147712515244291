import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.703744, 166.930776, 1.2],
  [-11.703853, 166.930776, 1.2],
  [-11.703969, 166.930776, 1.2],
  [-11.70409, 166.930776, 1.2],
  [-11.704196, 166.930786, 1.4],
  [-11.704305, 166.930819, 1.6],
  [-11.70441, 166.930851, 2],
  [-11.704516, 166.930894, 2.2],
  [-11.704616, 166.930915, 2.6],
  [-11.704722, 166.930926, 2.8],
  [-11.704824, 166.930926, 2.8],
  [-11.704927, 166.930894, 3],
  [-11.70503, 166.930883, 3],
  [-11.705137, 166.930894, 3],
  [-11.705238, 166.930926, 3.2],
  [-11.705334, 166.930958, 3.4],
  [-11.705427, 166.931001, 3.8],
  [-11.705514, 166.931055, 4.2],
  [-11.705597, 166.931108, 4.4],
  [-11.705664, 166.931183, 4.6],
  [-11.705721, 166.931258, 4.6],
  [-11.705761, 166.931355, 4.8],
  [-11.705789, 166.931462, 5],
  [-11.705812, 166.931548, 5.2],
  [-11.705833, 166.931645, 5.6],
  [-11.705862, 166.931741, 6],
  [-11.705902, 166.931827, 6.2],
  [-11.705954, 166.931902, 6.6],
  [-11.706012, 166.931977, 6.8],
  [-11.706069, 166.932052, 7.2],
  [-11.706131, 166.932117, 7.4],
  [-11.706195, 166.932181, 7.8],
  [-11.706257, 166.932235, 8],
  [-11.706327, 166.932299, 8.4],
  [-11.706394, 166.932364, 8.8],
  [-11.706469, 166.932406, 9],
  [-11.706548, 166.932439, 9.4],
  [-11.706625, 166.932471, 9.6],
  [-11.706709, 166.932503, 9.8],
  [-11.706792, 166.932514, 10],
  [-11.706873, 166.932546, 10.4],
  [-11.706945, 166.932589, 10.6],
  [-11.707008, 166.932653, 10.8],
  [-11.707063, 166.932718, 11],
  [-11.707103, 166.932793, 11.4],
  [-11.707137, 166.932878, 11.6],
  [-11.70716, 166.932954, 11.8],
  [-11.707181, 166.93305, 12],
  [-11.7072, 166.933125, 12.2],
  [-11.70722, 166.933211, 12.4],
  [-11.707238, 166.933308, 12.4],
  [-11.707254, 166.933383, 12.6],
  [-11.707266, 166.933479, 12.6],
  [-11.707275, 166.933565, 12.8],
  [-11.707286, 166.933662, 12.8],
  [-11.707295, 166.933769, 13],
  [-11.707311, 166.933844, 13],
  [-11.707331, 166.93393, 13.2],
  [-11.707361, 166.934037, 13.2],
  [-11.707402, 166.934112, 13.2],
  [-11.707459, 166.934209, 13.2],
  [-11.707518, 166.934284, 13.2],
  [-11.707582, 166.934359, 13.2],
  [-11.707649, 166.934434, 13.2],
  [-11.707715, 166.934509, 13.2],
  [-11.707786, 166.934584, 13.2],
  [-11.70785, 166.934681, 13.2],
  [-11.707916, 166.934756, 13.2],
  [-11.707989, 166.934831, 13.2],
  [-11.708054, 166.934906, 13.2],
  [-11.708121, 166.935003, 13.2],
  [-11.708194, 166.935078, 13],
  [-11.708262, 166.935153, 13],
  [-11.708329, 166.93525, 13],
  [-11.708402, 166.935325, 13],
  [-11.708473, 166.93541, 13],
  [-11.708544, 166.935486, 13],
  [-11.708612, 166.935582, 13],
  [-11.708682, 166.935657, 13],
  [-11.708754, 166.935754, 13],
  [-11.708824, 166.935829, 13],
  [-11.7089, 166.935915, 13],
  [-11.708973, 166.93599, 13],
  [-11.709041, 166.936086, 13],
  [-11.709112, 166.936162, 13],
  [-11.709187, 166.936258, 13],
  [-11.70926, 166.936333, 13],
  [-11.709328, 166.936419, 12.8],
  [-11.709403, 166.936516, 12.8],
  [-11.709473, 166.936591, 12.8],
  [-11.709547, 166.936677, 12.8],
  [-11.709619, 166.936752, 12.8],
  [-11.709689, 166.936848, 12.8],
  [-11.709739, 166.936945, 13],
  [-11.709782, 166.937041, 13.4],
  [-11.70982, 166.937149, 13.6],
  [-11.709861, 166.937245, 13.8],
  [-11.70991, 166.937352, 14],
  [-11.709979, 166.937428, 14.2],
  [-11.710061, 166.937492, 14],
  [-11.710156, 166.937535, 13.8],
  [-11.710259, 166.937567, 13.6],
  [-11.710369, 166.937599, 13.2],
  [-11.710475, 166.93761, 12.8],
  [-11.710593, 166.937642, 12.4],
  [-11.710707, 166.937674, 12],
  [-11.710812, 166.937717, 11.8],
  [-11.710907, 166.937792, 11.4],
  [-11.710979, 166.937889, 11.4],
  [-11.711025, 166.938007, 11.6],
  [-11.71106, 166.938114, 11.6],
  [-11.711097, 166.938232, 11.6],
  [-11.711136, 166.938339, 11.6],
  [-11.711178, 166.938447, 11.6],
  [-11.711218, 166.938576, 11.6],
  [-11.711259, 166.938683, 11.6],
  [-11.7113, 166.93879, 11.6],
  [-11.711339, 166.938897, 11.6],
  [-11.711376, 166.939005, 11.6],
  [-11.711415, 166.939123, 11.6],
  [-11.711455, 166.93923, 11.6],
  [-11.711491, 166.939337, 11.6],
  [-11.71153, 166.939445, 11.6],
  [-11.711568, 166.939552, 11.6],
  [-11.711608, 166.939659, 11.6],
  [-11.711646, 166.939766, 11.6],
  [-11.711683, 166.939874, 11.6],
  [-11.711724, 166.939981, 11.6],
  [-11.711762, 166.940078, 11.6],
  [-11.711785, 166.940206, 11.6],
  [-11.711778, 166.940314, 11.4],
  [-11.711746, 166.940432, 11.4],
  [-11.711703, 166.940528, 11.2],
  [-11.71165, 166.940635, 11],
  [-11.711603, 166.940743, 10.8],
  [-11.711573, 166.94085, 10.6],
  [-11.711595, 166.940968, 10.6],
  [-11.711669, 166.941065, 10.4],
  [-11.711778, 166.941086, 10.4],
  [-11.711901, 166.941086, 10.6],
  [-11.712013, 166.941075, 10.6],
  [-11.712124, 166.941108, 10.6],
  [-11.712228, 166.94115, 10.6],
  [-11.712326, 166.941215, 10.6],
  [-11.712432, 166.941258, 10.8],
  [-11.712544, 166.94129, 10.8],
  [-11.712656, 166.941301, 10.8],
  [-11.712759, 166.941322, 10.8],
  [-11.712871, 166.941322, 10.8],
  [-11.712987, 166.941333, 11],
  [-11.713098, 166.941333, 11],
  [-11.713203, 166.941333, 11],
  [-11.713314, 166.941365, 11],
  [-11.713419, 166.941397, 11],
  [-11.713502, 166.941472, 11],
  [-11.713565, 166.941558, 11],
  [-11.713593, 166.941665, 11],
  [-11.713594, 166.941794, 10.8],
  [-11.713572, 166.941901, 10.8],
  [-11.713529, 166.942009, 11],
  [-11.713471, 166.942095, 11.2],
  [-11.713386, 166.94217, 11.2],
  [-11.713281, 166.942191, 11.4],
  [-11.71318, 166.94217, 11.2],
  [-11.713073, 166.942137, 11.2],
  [-11.712964, 166.942127, 11.2],
  [-11.712861, 166.942159, 11],
  [-11.712779, 166.942234, 11],
  [-11.712701, 166.942309, 11],
  [-11.712623, 166.942406, 11],
  [-11.712544, 166.942481, 11],
  [-11.712469, 166.942556, 11],
  [-11.712399, 166.942642, 11],
  [-11.712346, 166.942738, 11],
  [-11.712316, 166.942846, 11],
  [-11.712314, 166.942964, 11],
  [-11.712308, 166.943071, 11],
  [-11.712252, 166.943167, 11],
  [-11.71217, 166.943243, 11],
  [-11.71206, 166.943285, 11],
  [-11.711953, 166.943275, 11],
  [-11.71186, 166.94321, 11],
  [-11.711769, 166.943146, 11],
  [-11.711686, 166.943071, 11],
  [-11.711597, 166.942996, 11],
  [-11.711514, 166.942931, 11],
  [-11.711424, 166.942856, 11],
  [-11.711339, 166.942803, 11.2],
  [-11.711241, 166.942738, 11.2],
  [-11.711136, 166.942738, 11.4],
  [-11.711036, 166.942781, 11.2],
  [-11.710958, 166.942856, 11.2],
  [-11.710889, 166.942953, 11],
  [-11.710838, 166.94306, 10.8],
  [-11.71085, 166.943167, 10.8],
  [-11.710932, 166.943243, 10.8],
  [-11.711015, 166.943318, 11],
  [-11.711081, 166.943414, 11],
  [-11.711112, 166.943511, 11],
  [-11.711121, 166.943618, 11],
  [-11.711103, 166.943747, 11.2],
  [-11.71105, 166.943833, 11.4],
  [-11.710977, 166.943908, 11.8],
  [-11.710911, 166.943994, 12],
  [-11.71088, 166.944101, 12],
  [-11.71088, 166.944197, 12.2],
  [-11.710893, 166.944305, 12.2],
  [-11.710913, 166.944412, 12.2],
  [-11.710934, 166.944519, 12.2],
  [-11.710951, 166.944627, 12.2],
  [-11.710968, 166.944723, 12.2],
  [-11.710986, 166.94483, 12.2],
  [-11.711002, 166.944938, 12.2],
  [-11.711022, 166.945056, 12],
  [-11.71104, 166.945163, 12],
  [-11.711056, 166.94527, 12],
  [-11.711073, 166.945378, 12],
  [-11.711092, 166.945485, 12],
  [-11.711111, 166.945592, 12],
  [-11.711128, 166.94571, 12],
  [-11.711145, 166.945817, 12],
  [-11.711164, 166.945925, 12],
  [-11.711183, 166.946032, 12],
  [-11.7112, 166.946139, 12],
  [-11.711219, 166.946257, 11.8],
  [-11.711238, 166.946365, 11.8],
  [-11.711251, 166.946472, 11.8],
  [-11.711221, 166.946579, 11.8],
  [-11.71115, 166.946676, 12],
  [-11.711066, 166.94674, 12],
  [-11.710975, 166.946815, 12],
  [-11.710904, 166.946901, 12],
  [-11.710864, 166.947008, 11.8],
  [-11.710842, 166.947116, 11.6],
  [-11.710826, 166.947244, 11.2],
  [-11.710816, 166.947341, 11],
  [-11.710812, 166.94747, 10.6],
  [-11.710808, 166.947588, 10.4],
  [-11.710808, 166.947716, 10],
  [-11.710808, 166.947834, 9.8],
  [-11.710808, 166.947953, 9.4],
  [-11.710808, 166.948092, 9],
  [-11.710801, 166.948221, 8.8],
  [-11.710787, 166.948339, 8.4],
  [-11.710767, 166.948478, 8.2],
  [-11.710735, 166.948596, 7.8],
  [-11.710692, 166.948714, 7.6],
  [-11.710635, 166.948843, 7.4],
  [-11.710563, 166.94895, 7.4],
  [-11.710475, 166.949036, 7.2],
  [-11.710378, 166.949111, 7.2],
  [-11.71026, 166.949176, 7.4],
  [-11.710148, 166.949208, 7.4],
  [-11.710032, 166.949251, 7.6],
  [-11.709908, 166.949272, 7.8],
  [-11.709794, 166.949283, 7.8],
  [-11.709677, 166.949304, 8],
  [-11.70956, 166.949326, 8.2],
  [-11.709445, 166.949347, 8.4],
  [-11.709338, 166.949358, 8.6],
  [-11.70923, 166.949379, 8.8],
  [-11.709123, 166.949401, 9],
  [-11.709021, 166.949455, 9.2],
  [-11.708929, 166.949497, 9.4],
  [-11.708851, 166.949573, 9.6],
  [-11.708815, 166.949669, 9.8],
  [-11.708807, 166.949776, 10],
  [-11.708819, 166.949884, 10.2],
  [-11.708845, 166.949991, 10.4],
  [-11.708906, 166.950066, 10.4],
  [-11.708996, 166.95012, 10.4],
  [-11.709092, 166.950152, 10.4],
  [-11.709193, 166.950184, 10.4],
  [-11.709303, 166.950195, 10.4],
  [-11.709407, 166.950216, 10.4],
  [-11.709515, 166.950227, 10.2],
  [-11.709619, 166.950227, 10.2],
  [-11.709732, 166.950248, 10.2],
  [-11.709841, 166.950248, 10.2],
  [-11.709952, 166.950248, 10.2],
  [-11.710062, 166.950259, 10.2],
  [-11.710168, 166.950281, 10.2],
  [-11.710276, 166.950302, 10.2],
  [-11.710382, 166.950334, 10.2],
  [-11.710484, 166.950377, 10.4],
  [-11.710582, 166.950431, 10.4],
  [-11.710661, 166.950506, 10.6],
  [-11.710735, 166.950581, 10.8],
  [-11.710799, 166.950678, 11],
  [-11.710854, 166.950763, 11.2],
  [-11.710907, 166.95086, 11.4],
  [-11.710956, 166.950946, 11.6],
  [-11.711007, 166.951042, 11.8],
  [-11.71106, 166.951128, 12],
  [-11.711119, 166.951225, 12.2],
  [-11.711184, 166.9513, 12.4],
  [-11.711258, 166.951364, 12.6],
  [-11.711345, 166.951407, 12.8],
  [-11.711441, 166.951439, 13],
  [-11.71154, 166.951439, 13.2],
  [-11.711619, 166.951386, 13.6],
  [-11.711666, 166.9513, 13.8],
  [-11.711699, 166.951203, 14],
  [-11.711726, 166.951118, 14.2],
  [-11.711761, 166.951021, 14.4],
  [-11.711815, 166.950946, 14.4],
  [-11.711897, 166.950881, 14.6],
  [-11.71198, 166.950839, 14.6],
  [-11.712051, 166.950763, 14.8],
  [-11.712107, 166.950688, 14.8],
  [-11.712151, 166.950592, 15],
  [-11.712181, 166.950506, 15],
  [-11.712205, 166.950409, 15],
  [-11.712226, 166.950302, 15.2],
  [-11.712257, 166.950216, 15.2],
  [-11.71231, 166.95012, 15.2],
  [-11.712385, 166.950066, 15.2],
  [-11.71248, 166.950012, 15.4],
  [-11.71258, 166.950002, 15.4],
  [-11.712684, 166.950012, 15.4],
  [-11.712782, 166.950045, 15.4],
  [-11.712881, 166.950077, 15.4],
  [-11.712973, 166.95012, 15.4],
  [-11.713073, 166.950173, 15.4],
  [-11.713164, 166.950216, 15.4],
  [-11.71326, 166.950259, 15.4],
  [-11.713368, 166.950291, 15.4],
  [-11.713467, 166.950302, 15.4],
  [-11.713578, 166.950302, 15.4],
  [-11.713683, 166.950302, 15.4],
  [-11.713789, 166.950291, 15.4],
  [-11.713897, 166.950302, 15.4],
  [-11.714003, 166.950334, 15.4],
  [-11.714099, 166.950377, 15.2],
  [-11.714184, 166.950463, 15],
  [-11.714264, 166.950538, 14.8],
  [-11.71434, 166.950624, 14.6],
  [-11.714408, 166.950699, 14.4],
  [-11.714479, 166.950806, 14.2],
  [-11.714548, 166.950903, 13.8],
  [-11.714624, 166.950989, 13.6],
  [-11.714705, 166.951085, 13.6],
  [-11.714799, 166.95116, 13.4],
  [-11.714902, 166.951225, 13.4],
  [-11.715007, 166.951278, 13.4],
  [-11.715102, 166.951364, 13.4],
  [-11.715181, 166.951439, 13.6],
  [-11.715232, 166.951547, 13.6],
  [-11.715255, 166.951665, 13.6],
  [-11.715259, 166.951783, 13.4],
  [-11.715252, 166.95189, 13.4],
  [-11.715241, 166.952019, 13.4],
  [-11.715235, 166.952126, 13.4],
  [-11.715245, 166.952244, 13.4],
  [-11.715277, 166.952362, 13.6],
  [-11.715324, 166.952459, 13.6],
  [-11.715383, 166.952566, 13.8],
  [-11.715444, 166.952652, 14],
  [-11.715507, 166.952748, 14.2],
  [-11.715574, 166.952834, 14.4],
  [-11.715638, 166.952931, 14.6],
  [-11.715704, 166.953027, 14.8],
  [-11.715766, 166.953102, 15],
  [-11.715822, 166.953188, 15],
  [-11.715872, 166.953295, 15.2],
  [-11.715914, 166.953392, 15.2],
  [-11.715944, 166.953499, 15.4],
  [-11.715963, 166.953607, 15.4],
  [-11.715968, 166.953692, 15.2],
  [-11.715964, 166.95381, 15],
  [-11.715945, 166.953918, 14.8],
  [-11.715912, 166.954025, 14.4],
  [-11.715857, 166.954132, 14],
  [-11.715782, 166.954207, 13.6],
  [-11.715682, 166.954293, 13.4],
  [-11.715582, 166.954347, 13.2],
  [-11.715465, 166.954379, 13],
  [-11.71535, 166.954411, 13],
  [-11.715228, 166.954422, 13.2],
  [-11.71511, 166.954443, 13.2],
  [-11.714993, 166.954454, 13.4],
  [-11.714878, 166.954486, 13.6],
  [-11.714769, 166.954497, 14],
  [-11.714661, 166.954551, 14.2],
  [-11.714557, 166.954583, 14.4],
  [-11.714467, 166.954637, 14.8],
  [-11.714382, 166.954701, 15],
  [-11.714315, 166.954798, 15.2],
  [-11.71427, 166.954883, 15.4],
  [-11.714254, 166.95498, 15.4],
  [-11.714268, 166.955087, 15.6],
  [-11.714304, 166.955184, 15.6],
  [-11.714353, 166.95528, 15.6],
  [-11.714408, 166.955377, 15.6],
  [-11.714467, 166.955463, 15.4],
  [-11.714528, 166.955538, 15.4],
  [-11.714584, 166.955634, 15.4],
  [-11.714638, 166.955742, 15.4],
  [-11.714679, 166.955827, 15.4],
  [-11.714705, 166.955935, 15.4],
  [-11.71471, 166.956042, 15.4],
  [-11.714702, 166.956149, 15.4],
  [-11.714681, 166.956257, 15.4],
  [-11.714654, 166.956364, 15.4],
  [-11.71462, 166.956482, 15.4],
  [-11.714587, 166.956578, 15.4],
  [-11.714555, 166.956686, 15.4],
  [-11.714524, 166.956804, 15.4],
  [-11.714508, 166.956911, 15.4],
  [-11.714505, 166.957018, 15.4],
  [-11.714525, 166.957126, 15.4],
  [-11.714571, 166.957233, 15.4],
  [-11.714647, 166.957308, 15.4],
  [-11.714746, 166.957351, 15.4],
  [-11.714855, 166.957383, 15.4],
  [-11.714966, 166.957383, 15.4],
  [-11.715079, 166.957383, 15.4],
  [-11.71519, 166.957383, 15.4],
  [-11.715303, 166.957372, 15.4],
  [-11.71541, 166.957372, 15.4],
  [-11.715524, 166.957383, 15.4],
  [-11.715631, 166.957415, 15.4],
  [-11.715727, 166.95748, 15.4],
  [-11.715809, 166.957555, 15.4],
  [-11.715874, 166.957641, 15.2],
  [-11.715925, 166.957748, 15.2],
  [-11.715963, 166.957855, 15.2],
  [-11.715998, 166.957963, 15.2],
  [-11.716026, 166.95807, 15.2],
  [-11.716055, 166.958199, 15.2],
  [-11.71609, 166.958306, 15],
  [-11.716134, 166.958413, 15],
  [-11.716189, 166.958499, 15],
  [-11.716247, 166.958606, 15.2],
  [-11.716311, 166.958703, 15.2],
  [-11.716373, 166.958789, 15.2],
  [-11.716436, 166.958885, 15.2],
  [-11.716496, 166.958971, 15.2],
  [-11.716558, 166.959078, 15.2],
  [-11.716613, 166.959175, 15.2],
  [-11.716661, 166.959282, 15.4],
  [-11.716704, 166.959389, 15.4],
  [-11.716739, 166.959497, 15.4],
  [-11.716763, 166.959604, 15.4],
  [-11.716777, 166.959722, 15.4],
  [-11.716785, 166.959829, 15.4],
  [-11.716788, 166.959947, 15.4],
  [-11.716786, 166.960055, 15.4],
  [-11.716785, 166.960162, 15.4],
  [-11.716783, 166.960291, 15.4],
  [-11.716783, 166.960387, 15.4],
  [-11.716788, 166.960495, 15.4],
  [-11.7168, 166.960623, 15.4],
  [-11.71682, 166.960741, 15.4],
  [-11.716851, 166.960849, 15.4],
  [-11.716902, 166.960945, 15.4],
  [-11.716974, 166.961031, 15.4],
  [-11.71705, 166.961106, 15.2],
  [-11.717134, 166.961181, 15.2],
  [-11.717218, 166.961278, 15.2],
  [-11.71729, 166.961353, 15.2],
  [-11.717348, 166.96146, 15.2],
  [-11.717383, 166.961567, 15.2],
  [-11.717403, 166.961675, 15.2],
  [-11.71741, 166.961793, 15],
  [-11.71741, 166.961921, 14.8],
  [-11.717407, 166.962029, 14.6],
  [-11.717406, 166.962147, 14.4],
  [-11.717416, 166.962265, 14.2],
  [-11.717443, 166.962372, 14],
  [-11.717496, 166.962479, 14],
  [-11.717565, 166.962576, 13.8],
  [-11.717649, 166.962662, 13.6],
  [-11.717733, 166.962758, 13.4],
  [-11.717824, 166.962833, 13.4],
  [-11.717925, 166.962909, 13.2],
  [-11.718023, 166.962984, 13],
  [-11.718123, 166.963048, 12.8],
  [-11.718232, 166.963112, 12.8],
  [-11.718338, 166.963166, 12.6],
  [-11.718448, 166.96323, 12.6],
  [-11.718555, 166.963273, 12.4],
  [-11.718668, 166.963338, 12.4],
  [-11.718778, 166.963381, 12.4],
  [-11.71889, 166.963413, 12.4],
  [-11.71901, 166.963423, 12.6],
  [-11.719129, 166.963423, 12.6],
  [-11.719247, 166.963423, 12.8],
  [-11.719358, 166.963413, 12.8],
  [-11.719474, 166.963413, 13],
  [-11.719589, 166.963402, 13.2],
  [-11.719698, 166.963402, 13.4],
  [-11.719813, 166.963413, 13.6],
  [-11.719915, 166.963445, 13.6],
  [-11.720017, 166.963509, 13.8],
  [-11.720093, 166.963563, 13.8],
  [-11.720156, 166.96366, 13.8],
  [-11.720203, 166.963767, 13.8],
  [-11.720234, 166.963874, 13.8],
  [-11.720252, 166.963981, 13.8],
  [-11.720257, 166.964099, 13.8],
  [-11.720254, 166.964207, 13.8],
  [-11.720244, 166.964314, 13.6],
  [-11.720229, 166.964421, 13.6],
  [-11.72021, 166.964539, 13.6],
  [-11.720191, 166.964647, 13.6],
  [-11.720171, 166.964754, 13.6],
  [-11.72015, 166.964883, 13.6],
  [-11.720135, 166.96499, 13.6],
  [-11.720124, 166.965108, 13.6],
  [-11.72012, 166.965215, 13.6],
  [-11.720127, 166.965322, 13.6],
  [-11.72016, 166.965441, 13.6],
  [-11.72021, 166.965537, 13.6],
  [-11.720262, 166.965644, 13.6],
  [-11.720289, 166.965752, 13.6],
  [-11.720272, 166.96587, 13.6],
  [-11.720223, 166.965966, 13.6],
  [-11.72015, 166.966052, 13.6],
  [-11.72006, 166.966127, 13.6],
  [-11.719961, 166.96617, 13.6],
  [-11.719849, 166.966224, 13.6],
  [-11.719746, 166.966234, 13.6],
  [-11.719637, 166.966267, 13.6],
  [-11.71953, 166.966299, 13.6],
  [-11.719415, 166.966342, 13.6],
  [-11.719313, 166.966374, 13.6],
  [-11.719203, 166.966406, 13.6],
  [-11.719094, 166.966438, 13.6],
  [-11.718983, 166.96647, 13.6],
  [-11.71888, 166.966492, 13.6],
  [-11.718778, 166.966546, 13.6],
  [-11.718704, 166.966631, 13.6],
  [-11.718678, 166.966739, 13.6],
];
export default stream;
