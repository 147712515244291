import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.642468, 166.93952, 127.8],
  [-11.642537, 166.939734, 125.4],
  [-11.642649, 166.939927, 122.6],
  [-11.642822, 166.940099, 120.2],
  [-11.643044, 166.940185, 118.8],
  [-11.643282, 166.940238, 117.6],
  [-11.643523, 166.940238, 116.4],
  [-11.64376, 166.940185, 115.2],
  [-11.643976, 166.940078, 113.8],
  [-11.644163, 166.939927, 112.6],
  [-11.644309, 166.939745, 111.6],
  [-11.644412, 166.93952, 110.6],
  [-11.644486, 166.939294, 110],
  [-11.644534, 166.939058, 109.4],
  [-11.644537, 166.938812, 108.8],
  [-11.644456, 166.938586, 108.6],
  [-11.644329, 166.938404, 108.4],
  [-11.644203, 166.938211, 107.6],
  [-11.644073, 166.938007, 106.4],
  [-11.643898, 166.937857, 105.2],
  [-11.643685, 166.937749, 104],
  [-11.643457, 166.937706, 103],
  [-11.643223, 166.937685, 101.8],
  [-11.642992, 166.937749, 101],
  [-11.642773, 166.937824, 100],
  [-11.642563, 166.937932, 99],
  [-11.642355, 166.93805, 97.8],
  [-11.642167, 166.938189, 96.6],
  [-11.642016, 166.938372, 95.6],
  [-11.641899, 166.938586, 94.6],
  [-11.6418, 166.938801, 93.6],
  [-11.641714, 166.939026, 92.6],
  [-11.641641, 166.939262, 91.8],
  [-11.641603, 166.939487, 91.2],
  [-11.641603, 166.939734, 90.6],
  [-11.641645, 166.93996, 89.8],
  [-11.641743, 166.940174, 89.2],
  [-11.641902, 166.940346, 89.4],
  [-11.642075, 166.940496, 89],
  [-11.642233, 166.940668, 87.6],
  [-11.642417, 166.940818, 86],
  [-11.642624, 166.940925, 84.6],
  [-11.642843, 166.941, 83.2],
  [-11.643078, 166.941043, 81.8],
  [-11.643315, 166.941065, 80.6],
  [-11.643552, 166.941065, 79.4],
  [-11.64379, 166.941032, 78.2],
  [-11.644022, 166.940968, 77],
  [-11.644243, 166.940871, 75.6],
  [-11.644451, 166.940753, 74.2],
  [-11.644651, 166.940614, 72.8],
  [-11.644835, 166.940464, 71.4],
  [-11.644973, 166.940271, 70.2],
  [-11.64506, 166.940035, 69],
  [-11.64517, 166.939809, 67.6],
  [-11.645316, 166.939627, 66.4],
  [-11.645434, 166.939412, 65.2],
  [-11.645472, 166.939166, 64],
  [-11.645465, 166.938919, 63],
  [-11.645438, 166.938683, 62.2],
  [-11.645378, 166.938447, 61.4],
  [-11.645245, 166.938254, 60.4],
  [-11.645018, 166.938189, 59.4],
  [-11.644782, 166.938221, 58.8],
  [-11.644553, 166.938264, 58.4],
  [-11.644332, 166.938339, 57.8],
  [-11.644159, 166.9385, 56.8],
  [-11.644088, 166.938726, 56],
  [-11.643959, 166.938908, 55.4],
  [-11.643732, 166.938919, 55.2],
  [-11.643514, 166.938844, 55.2],
  [-11.643295, 166.93879, 55.2],
  [-11.643079, 166.938704, 55.2],
  [-11.642863, 166.938651, 55.2],
  [-11.64265, 166.938586, 55.2],
  [-11.642437, 166.938522, 55.2],
  [-11.642227, 166.938447, 55.2],
  [-11.642064, 166.938318, 55.2],
  [-11.641995, 166.938103, 55.2],
  [-11.641888, 166.937921, 55.2],
  [-11.641675, 166.937889, 55.4],
  [-11.64146, 166.937921, 55.4],
  [-11.641275, 166.938039, 54.4],
  [-11.641136, 166.938211, 52.8],
  [-11.641025, 166.938404, 51.8],
  [-11.640949, 166.938618, 51.6],
  [-11.640898, 166.938844, 52.2],
  [-11.64086, 166.939058, 53.8],
  [-11.640829, 166.939273, 55.2],
  [-11.640782, 166.939487, 55.6],
  [-11.640707, 166.939691, 55.4],
  [-11.640614, 166.939874, 54.6],
  [-11.640534, 166.940067, 53.8],
  [-11.640512, 166.940292, 52.8],
  [-11.640613, 166.940496, 51.8],
  [-11.640772, 166.940646, 51],
  [-11.640941, 166.940796, 50.2],
  [-11.641074, 166.940979, 49.2],
  [-11.641164, 166.941193, 47.8],
  [-11.641301, 166.941376, 47.2],
  [-11.64149, 166.941504, 47.6],
  [-11.641694, 166.941612, 48.4],
  [-11.641906, 166.941665, 48.4],
  [-11.64212, 166.94173, 47.6],
  [-11.642341, 166.941762, 46.6],
  [-11.642565, 166.941794, 45.6],
  [-11.642792, 166.941805, 44.4],
  [-11.643021, 166.941837, 43.2],
  [-11.643249, 166.941869, 42.2],
  [-11.643477, 166.941912, 41.2],
  [-11.643708, 166.941934, 40.2],
  [-11.64394, 166.941901, 39],
  [-11.644169, 166.941848, 37.8],
  [-11.644397, 166.941794, 36.6],
  [-11.644625, 166.94173, 35.4],
  [-11.644849, 166.941655, 34.2],
  [-11.645068, 166.941558, 33],
  [-11.645276, 166.94144, 31.6],
  [-11.645458, 166.94129, 30.4],
  [-11.645591, 166.941086, 29],
  [-11.645689, 166.940861, 28],
  [-11.645765, 166.940635, 26.8],
  [-11.64584, 166.940399, 25.8],
  [-11.645942, 166.940185, 24.6],
  [-11.646082, 166.939992, 23.4],
  [-11.646236, 166.939809, 22.2],
  [-11.646355, 166.939595, 20.8],
  [-11.646413, 166.939369, 19.4],
  [-11.646444, 166.939123, 18.2],
  [-11.646451, 166.938876, 16.8],
  [-11.646428, 166.938629, 15.6],
  [-11.646353, 166.938404, 14.2],
  [-11.646216, 166.938211, 12.8],
  [-11.646051, 166.938018, 11.8],
  [-11.645882, 166.937857, 11.6],
  [-11.645701, 166.937706, 12.8],
  [-11.645517, 166.937546, 14],
  [-11.645372, 166.937385, 14.4],
  [-11.645287, 166.937181, 13.8],
  [-11.645225, 166.936966, 12.8],
  [-11.645111, 166.936773, 11.6],
  [-11.644939, 166.936623, 10.8],
  [-11.644745, 166.936494, 10],
  [-11.644542, 166.936387, 9.4],
  [-11.644334, 166.936301, 9.2],
  [-11.64412, 166.936226, 9.2],
  [-11.643905, 166.936151, 9.2],
];

export default stream;
