import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.635885, 166.953369, 5],
  [-11.635933, 166.953309, 5],
  [-11.635975, 166.953248, 5],
  [-11.636019, 166.953185, 5],
  [-11.636062, 166.953119, 5],
  [-11.636108, 166.953055, 5],
  [-11.63615, 166.95299, 5],
  [-11.636193, 166.952929, 5],
  [-11.63624, 166.952866, 5],
  [-11.636284, 166.952805, 5],
  [-11.636329, 166.952741, 5],
  [-11.636373, 166.952676, 5],
  [-11.636419, 166.952605, 5],
  [-11.636467, 166.952541, 5],
  [-11.636522, 166.952479, 5],
  [-11.636583, 166.952426, 5],
  [-11.636656, 166.952385, 5],
  [-11.636736, 166.95237, 5],
  [-11.636819, 166.952379, 5],
  [-11.636896, 166.952406, 5],
  [-11.636972, 166.952445, 5.2],
  [-11.637039, 166.952486, 5.2],
  [-11.637103, 166.952535, 5.2],
  [-11.637168, 166.952586, 5.2],
  [-11.637228, 166.95264, 5.2],
  [-11.637289, 166.9527, 5.2],
  [-11.637347, 166.952758, 5],
  [-11.637405, 166.952821, 5],
  [-11.637459, 166.952889, 4.8],
  [-11.637507, 166.95296, 4.6],
  [-11.637557, 166.953033, 4.4],
  [-11.637609, 166.953112, 4],
  [-11.637656, 166.953194, 3.6],
  [-11.6377, 166.953285, 3.4],
  [-11.637736, 166.953376, 3],
  [-11.637765, 166.953477, 2.6],
  [-11.637778, 166.953577, 2.4],
  [-11.637774, 166.953683, 2.2],
  [-11.637749, 166.953786, 2],
  [-11.637708, 166.953888, 2],
  [-11.637661, 166.953981, 1.8],
  [-11.637609, 166.954073, 1.8],
  [-11.637549, 166.954162, 1.8],
  [-11.637488, 166.954245, 1.8],
];

export default stream;
