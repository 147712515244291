import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.703191, 166.930861, 2],
  [-11.703295, 166.93084, 2],
  [-11.703407, 166.930819, 1.8],
  [-11.703516, 166.930808, 1.6],
  [-11.703627, 166.930786, 1.4],
];
export default stream;
