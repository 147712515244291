import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.788998, 165.846713, 80],
  [-10.789085, 165.846713, 80],
  [-10.78917, 165.846734, 80],
  [-10.789253, 165.846745, 80],
  [-10.789327, 165.846788, 80],
  [-10.789368, 165.846863, 80],
  [-10.789378, 165.846927, 80],
  [-10.78938, 165.84697, 80],
];

export default stream;
