import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [47.253914, 11.41354, 297.2],
  [47.253807, 11.413646, 297.6],
  [47.253726, 11.413794, 297.4],
  [47.253608, 11.413892, 297.4],
  [47.253566, 11.414024, 297.4],
];

export default stream;
