import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [51.495988, -0.072644, 26.6],
  [51.495945, -0.07254, 26.8],
  [51.495903, -0.072432, 26.8],
  [51.49586, -0.072322, 27],
  [51.495822, -0.07221, 27],
  [51.49579, -0.072094, 27.2],
  [51.495763, -0.071972, 27.2],
  [51.495742, -0.071846, 27.4],
  [51.495726, -0.071717, 27.4],
  [51.495715, -0.071586, 27.4],
  [51.495715, -0.071453, 27.6],
  [51.495715, -0.071319, 27.6],
  [51.49572, -0.071186, 27.6],
  [51.495731, -0.071052, 27.8],
  [51.495736, -0.070917, 27.8],
  [51.495742, -0.070781, 27.8],
  [51.495758, -0.070645, 28],
  [51.495763, -0.070509, 28],
  [51.495774, -0.070372, 28.2],
  [51.495785, -0.070236, 28.2],
  [51.49579, -0.070099, 28.2],
  [51.495795, -0.069961, 28.4],
  [51.495806, -0.069825, 28.6],
  [51.495811, -0.069687, 28.6],
  [51.495822, -0.06955, 28.6],
  [51.495833, -0.069413, 28.6],
  [51.495844, -0.069275, 28.8],
  [51.495854, -0.069136, 28.8],
  [51.49586, -0.068996, 29],
  [51.49586, -0.068857, 29.2],
  [51.495849, -0.068722, 29.4],
  [51.495838, -0.06859, 29.8],
  [51.495811, -0.068464, 30.2],
  [51.495785, -0.068346, 30.6],
  [51.495758, -0.068233, 31.2],
  [51.495731, -0.068126, 31.6],
  [51.495699, -0.068025, 32],
  [51.495667, -0.067928, 32.6],
  [51.49564, -0.067835, 33],
  [51.495613, -0.067746, 33.4],
  [51.495586, -0.067662, 33.8],
  [51.495554, -0.067581, 34.2],
  [51.495527, -0.067504, 34.6],
  [51.4955, -0.06743, 35],
  [51.495473, -0.067359, 35.4],
  [51.495447, -0.067291, 35.8],
  [51.495414, -0.067225, 36.2],
  [51.495388, -0.067163, 36.6],
  [51.495361, -0.067103, 37],
  [51.495334, -0.067045, 37.4],
  [51.495307, -0.06699, 37.6],
  [51.49528, -0.066937, 38],
  [51.495253, -0.066886, 38.4],
  [51.495221, -0.066838, 38.8],
  [51.495189, -0.066793, 39],
  [51.495162, -0.066751, 39.4],
  [51.495125, -0.066713, 39.8],
  [51.495098, -0.06668, 40],
  [51.495055, -0.066652, 40.4],
  [51.495023, -0.066632, 40.6],
  [51.494985, -0.066618, 41],
  [51.494942, -0.066612, 41.4],
  [51.494905, -0.066613, 41.6],
  [51.494867, -0.066621, 41.8],
  [51.494824, -0.066635, 42.2],
  [51.494787, -0.066654, 42.4],
  [51.494749, -0.066678, 42.6],
  [51.494712, -0.066707, 43],
  [51.494674, -0.06674, 43.2],
  [51.494631, -0.066777, 43.4],
  [51.494594, -0.066816, 43.6],
  [51.494556, -0.066859, 43.8],
  [51.494513, -0.066903, 44.2],
  [51.494476, -0.06695, 44.4],
  [51.494438, -0.066998, 44.6],
  [51.494395, -0.067047, 44.8],
  [51.494352, -0.067097, 45],
  [51.494309, -0.067148, 45.2],
  [51.494266, -0.067198, 45.6],
  [51.494223, -0.067249, 45.8],
  [51.494186, -0.067299, 46],
  [51.494138, -0.067346, 46.4],
  [51.494095, -0.067392, 46.6],
  [51.494046, -0.067437, 46.8],
  [51.493998, -0.067482, 47.2],
  [51.493955, -0.067527, 47.4],
  [51.493907, -0.067574, 47.6],
  [51.493864, -0.067622, 47.8],
  [51.493816, -0.067671, 48.2],
  [51.493773, -0.067722, 48.4],
  [51.493725, -0.067772, 48.6],
  [51.493682, -0.067824, 48.8],
  [51.493633, -0.067876, 49],
  [51.49359, -0.067929, 49.4],
  [51.493537, -0.067983, 49.6],
  [51.493494, -0.068036, 49.8],
  [51.493446, -0.06809, 50],
  [51.493397, -0.068143, 50.4],
  [51.493354, -0.068196, 50.6],
  [51.493301, -0.068249, 50.8],
  [51.493258, -0.068301, 51.2],
  [51.49321, -0.068351, 51.4],
  [51.493161, -0.068399, 51.6],
  [51.493113, -0.068444, 52],
  [51.493065, -0.068487, 52.4],
  [51.493011, -0.068525, 52.6],
  [51.492957, -0.068558, 53],
  [51.492909, -0.068585, 53.4],
  [51.492861, -0.068604, 53.8],
  [51.492813, -0.068617, 54.2],
  [51.492764, -0.068625, 54.6],
  [51.492721, -0.068629, 55],
  [51.492673, -0.068627, 55.4],
  [51.49263, -0.06862, 55.8],
  [51.492587, -0.068608, 56.2],
  [51.492555, -0.068592, 56.6],
  [51.492523, -0.068572, 57],
  [51.492485, -0.068548, 57.4],
  [51.492453, -0.068522, 57.8],
  [51.492426, -0.068493, 58.2],
  [51.492405, -0.068462, 58.6],
  [51.492378, -0.068429, 58.8],
  [51.492351, -0.068394, 59.2],
  [51.49233, -0.068359, 59.6],
  [51.492308, -0.068322, 59.8],
  [51.492287, -0.068284, 60.2],
  [51.492271, -0.068245, 60.6],
  [51.492249, -0.068205, 60.8],
  [51.492233, -0.068163, 61.2],
  [51.492212, -0.068121, 61.4],
  [51.49219, -0.068078, 61.8],
  [51.49218, -0.068034, 62.2],
  [51.492164, -0.067988, 62.4],
  [51.492147, -0.067941, 62.8],
  [51.492137, -0.067894, 63],
  [51.492121, -0.067845, 63.4],
  [51.49211, -0.067796, 63.8],
  [51.492099, -0.067746, 64],
  [51.492083, -0.067695, 64.4],
  [51.492078, -0.067644, 64.6],
  [51.492067, -0.067592, 65],
  [51.492056, -0.06754, 65.2],
  [51.492046, -0.067488, 65.6],
  [51.492035, -0.067435, 66],
  [51.492029, -0.067382, 66.2],
  [51.492019, -0.067329, 66.6],
  [51.492008, -0.067275, 66.8],
  [51.492003, -0.067221, 67.2],
  [51.491992, -0.067166, 67.4],
  [51.491981, -0.067111, 67.8],
  [51.491976, -0.067056, 68],
  [51.491965, -0.067, 68.4],
  [51.49196, -0.066944, 68.6],
  [51.491954, -0.066888, 69],
  [51.491938, -0.066831, 69.2],
  [51.491933, -0.066774, 69.6],
  [51.491928, -0.066717, 70],
  [51.491911, -0.066659, 70.2],
  [51.491906, -0.066601, 70.6],
  [51.491901, -0.066543, 70.8],
  [51.49189, -0.066484, 71.2],
  [51.491879, -0.066425, 71.4],
  [51.491868, -0.066365, 71.8],
  [51.491863, -0.066305, 72],
  [51.491847, -0.066244, 72.2],
  [51.491842, -0.066182, 72.6],
  [51.491831, -0.066121, 72.8],
  [51.49182, -0.066059, 73.2],
  [51.491804, -0.065997, 73.4],
  [51.491793, -0.065933, 73.8],
  [51.491777, -0.065869, 74],
  [51.491761, -0.065806, 74.2],
  [51.49174, -0.065741, 74.4],
  [51.491718, -0.065676, 74.6],
  [51.491697, -0.065612, 74.8],
  [51.49167, -0.065546, 75],
  [51.491638, -0.065479, 75],
  [51.491606, -0.065411, 75.2],
  [51.491568, -0.065342, 75.2],
  [51.491525, -0.065273, 75],
  [51.491472, -0.065204, 74.8],
  [51.491413, -0.065136, 74.6],
  [51.491348, -0.065074, 74],
  [51.491273, -0.065018, 73.6],
  [51.491187, -0.064975, 73],
  [51.491096, -0.064948, 72.4],
  [51.490999, -0.064948, 71.8],
  [51.490898, -0.064997, 71.2],
  [51.490806, -0.065089, 70.6],
  [51.490721, -0.065205, 70],
  [51.490635, -0.065329, 69.2],
  [51.490549, -0.065456, 68.8],
  [51.490452, -0.065571, 68.4],
  [51.49035, -0.06567, 68],
  [51.490248, -0.065763, 67.8],
  [51.490141, -0.065857, 67.6],
  [51.490034, -0.06595, 67.4],
  [51.489932, -0.066041, 67.4],
  [51.48983, -0.066131, 67.2],
  [51.489723, -0.066215, 67.2],
  [51.489615, -0.066293, 67.2],
  [51.489508, -0.066365, 67.2],
  [51.489406, -0.066427, 67.2],
  [51.489299, -0.066479, 67.2],
  [51.489186, -0.066518, 67.2],
  [51.489079, -0.066546, 67.2],
  [51.488972, -0.066565, 67.2],
  [51.488864, -0.066579, 67.2],
  [51.488757, -0.066587, 67.2],
  [51.48865, -0.066591, 67.4],
  [51.488543, -0.066589, 67.4],
  [51.488441, -0.066583, 67.4],
  [51.488333, -0.066573, 67.6],
  [51.488231, -0.066557, 67.6],
  [51.488135, -0.066536, 67.8],
  [51.488033, -0.06651, 68],
  [51.487936, -0.066477, 68.2],
  [51.487845, -0.066437, 68.4],
  [51.487754, -0.066389, 68.8],
  [51.487668, -0.066332, 69.2],
  [51.487593, -0.066263, 69.6],
  [51.487518, -0.066188, 70],
  [51.487454, -0.066106, 70.6],
  [51.487395, -0.066021, 71],
  [51.487341, -0.065935, 71.6],
  [51.487293, -0.065849, 72.2],
  [51.48725, -0.065764, 72.6],
  [51.487212, -0.06568, 73.2],
  [51.487175, -0.065598, 73.6],
  [51.487148, -0.065518, 74.2],
  [51.487116, -0.065441, 74.6],
  [51.487094, -0.065365, 75],
  [51.487067, -0.065293, 75.4],
  [51.487046, -0.065223, 76],
  [51.48703, -0.065154, 76.4],
  [51.487008, -0.065088, 76.8],
  [51.486992, -0.065023, 77.2],
  [51.486976, -0.064961, 77.4],
  [51.486965, -0.064898, 77.8],
  [51.486955, -0.064837, 78.2],
  [51.486949, -0.064777, 78.6],
  [51.486944, -0.064718, 79],
  [51.486939, -0.06466, 79.4],
  [51.486939, -0.064603, 79.6],
  [51.486939, -0.064547, 80],
  [51.486939, -0.064491, 80.4],
  [51.486944, -0.064436, 80.6],
  [51.486944, -0.064381, 81],
  [51.486949, -0.064326, 81.4],
  [51.486949, -0.064271, 81.6],
  [51.486955, -0.064216, 82],
  [51.48696, -0.064162, 82.4],
  [51.486965, -0.064107, 82.6],
  [51.486971, -0.064052, 83],
  [51.486976, -0.063998, 83.2],
  [51.486987, -0.063945, 83.6],
  [51.486992, -0.063891, 84],
  [51.487003, -0.063837, 84.2],
  [51.487008, -0.063784, 84.6],
  [51.487014, -0.063729, 84.8],
  [51.487014, -0.063674, 85.2],
  [51.487024, -0.063619, 85.6],
  [51.48703, -0.063565, 85.8],
  [51.48703, -0.06351, 86.2],
  [51.48703, -0.063455, 86.4],
  [51.48703, -0.0634, 86.8],
  [51.48703, -0.063345, 87.2],
  [51.48703, -0.06329, 87.4],
  [51.487019, -0.063235, 87.8],
  [51.487014, -0.063181, 88.2],
  [51.487008, -0.063128, 88.4],
  [51.486998, -0.063074, 88.8],
  [51.486987, -0.063021, 89],
  [51.486976, -0.062969, 89.4],
  [51.48696, -0.062917, 89.6],
  [51.486939, -0.062866, 89.8],
  [51.486917, -0.062816, 90.2],
  [51.486896, -0.062769, 90.4],
  [51.486869, -0.062724, 90.8],
  [51.486842, -0.06268, 91.2],
  [51.486815, -0.062639, 91.6],
  [51.486788, -0.062598, 91.8],
  [51.486762, -0.06256, 92.2],
  [51.48674, -0.062523, 92.6],
  [51.486719, -0.062488, 93],
  [51.486697, -0.062452, 93.4],
  [51.486676, -0.062415, 93.8],
  [51.48666, -0.062378, 94.2],
  [51.486644, -0.062341, 94.4],
  [51.486622, -0.062303, 94.8],
  [51.486606, -0.062265, 95],
  [51.48659, -0.062228, 95.4],
  [51.486568, -0.062191, 95.8],
  [51.486552, -0.062153, 96],
  [51.486531, -0.062117, 96.4],
  [51.486515, -0.062082, 96.8],
  [51.486493, -0.062048, 97],
  [51.486472, -0.062016, 97.4],
  [51.48645, -0.061985, 97.8],
  [51.486434, -0.061955, 98.2],
  [51.486408, -0.061928, 98.4],
  [51.486386, -0.061903, 98.8],
  [51.486365, -0.06188, 99.2],
  [51.486343, -0.061859, 99.6],
  [51.486316, -0.061841, 99.8],
  [51.48629, -0.061826, 100.2],
  [51.486263, -0.061813, 100.6],
  [51.486236, -0.061802, 100.8],
  [51.486214, -0.061794, 101.2],
  [51.486188, -0.061789, 101.6],
  [51.486161, -0.061786, 101.8],
  [51.486134, -0.061785, 102.2],
  [51.486107, -0.061787, 102.6],
  [51.48608, -0.061791, 102.8],
  [51.486053, -0.061797, 103.2],
  [51.486027, -0.061805, 103.4],
  [51.486, -0.061815, 103.8],
  [51.485973, -0.061827, 104.2],
  [51.485946, -0.061841, 104.4],
  [51.485919, -0.061857, 104.8],
  [51.485893, -0.061875, 105],
  [51.485866, -0.061894, 105.4],
  [51.485839, -0.061914, 105.6],
  [51.485807, -0.061934, 106],
  [51.48578, -0.061956, 106.4],
  [51.485753, -0.061979, 106.6],
  [51.485726, -0.062002, 107],
  [51.485694, -0.062026, 107.2],
  [51.485667, -0.06205, 107.6],
  [51.48564, -0.062076, 107.8],
  [51.485614, -0.062101, 108.2],
  [51.485587, -0.062128, 108.6],
  [51.485555, -0.062154, 108.8],
  [51.485528, -0.062181, 109.2],
  [51.485496, -0.062209, 109.4],
  [51.485469, -0.062237, 109.8],
  [51.485442, -0.062265, 110],
  [51.485415, -0.062294, 110.4],
  [51.485383, -0.062323, 110.8],
  [51.485356, -0.062352, 111],
  [51.485324, -0.062381, 111.4],
  [51.485297, -0.06241, 111.6],
  [51.48527, -0.06244, 112],
  [51.485238, -0.06247, 112.4],
  [51.485206, -0.062499, 112.6],
  [51.485179, -0.062529, 113],
  [51.485152, -0.062559, 113.2],
  [51.48512, -0.062589, 113.6],
  [51.485088, -0.062619, 113.8],
  [51.485061, -0.062649, 114.2],
  [51.485034, -0.062679, 114.6],
  [51.485002, -0.062709, 114.8],
  [51.48497, -0.062738, 115.2],
  [51.484943, -0.062768, 115.4],
  [51.484911, -0.062797, 115.8],
  [51.484879, -0.062826, 116.2],
  [51.484852, -0.062854, 116.4],
  [51.48482, -0.062882, 116.8],
  [51.484787, -0.062908, 117],
  [51.484755, -0.062935, 117.4],
  [51.484728, -0.062961, 117.8],
  [51.484691, -0.062987, 118],
  [51.484664, -0.063011, 118.4],
  [51.484632, -0.063036, 118.6],
  [51.4846, -0.063061, 119],
  [51.484568, -0.063085, 119.4],
  [51.484535, -0.063111, 119.6],
  [51.484503, -0.063137, 120],
  [51.484471, -0.063165, 120.2],
  [51.484439, -0.063193, 120.6],
  [51.484407, -0.063222, 120.8],
  [51.484374, -0.063252, 121],
  [51.484337, -0.063282, 121.4],
  [51.484305, -0.063312, 121.6],
  [51.484267, -0.063343, 122],
  [51.484235, -0.063374, 122.2],
  [51.484203, -0.063405, 122.6],
  [51.484165, -0.063436, 122.8],
  [51.484133, -0.063468, 123.2],
  [51.484095, -0.063499, 123.4],
  [51.484058, -0.063531, 123.8],
  [51.484026, -0.063561, 124],
  [51.483988, -0.06359, 124.4],
  [51.483951, -0.063619, 124.8],
  [51.483913, -0.063646, 125],
  [51.483876, -0.063673, 125.4],
  [51.483843, -0.063699, 125.6],
  [51.483806, -0.063723, 126],
  [51.483768, -0.063745, 126.4],
  [51.483731, -0.063766, 126.6],
  [51.483693, -0.063784, 127],
  [51.483661, -0.063801, 127.4],
  [51.483623, -0.063814, 127.8],
  [51.483586, -0.063826, 128.2],
  [51.483554, -0.063834, 128.4],
  [51.483516, -0.063839, 128.8],
  [51.483489, -0.063842, 129.2],
  [51.483452, -0.063842, 129.6],
  [51.483425, -0.063838, 130],
  [51.483398, -0.063832, 130.4],
  [51.483366, -0.063823, 130.8],
  [51.483344, -0.063811, 131.2],
  [51.483318, -0.063796, 131.4],
  [51.483291, -0.063779, 131.8],
  [51.483269, -0.063761, 132.2],
  [51.483253, -0.063741, 132.6],
  [51.483226, -0.063719, 132.8],
  [51.48321, -0.063696, 133.2],
  [51.483189, -0.063672, 133.6],
  [51.483173, -0.063646, 133.8],
  [51.483151, -0.06362, 134.2],
  [51.483135, -0.063592, 134.6],
  [51.483119, -0.063564, 134.8],
  [51.483098, -0.063534, 135.2],
  [51.483082, -0.063504, 135.6],
  [51.483065, -0.063473, 135.8],
  [51.483044, -0.063441, 136.2],
  [51.483028, -0.063409, 136.4],
  [51.483012, -0.063375, 136.8],
  [51.482996, -0.063342, 137.2],
  [51.482974, -0.063308, 137.4],
  [51.482964, -0.063274, 137.8],
  [51.482942, -0.063239, 138.2],
  [51.482926, -0.063205, 138.4],
  [51.48291, -0.06317, 138.8],
  [51.482888, -0.063134, 139],
  [51.482872, -0.063099, 139.4],
  [51.482856, -0.063064, 139.8],
  [51.48284, -0.063028, 140],
  [51.482819, -0.062993, 140.4],
  [51.482803, -0.062958, 140.8],
  [51.482781, -0.062923, 141],
  [51.482765, -0.062889, 141.4],
  [51.482744, -0.062855, 141.8],
  [51.482722, -0.062822, 142],
  [51.482701, -0.062789, 142.4],
  [51.482679, -0.062757, 142.6],
  [51.482658, -0.062726, 143],
  [51.482636, -0.062697, 143.4],
  [51.482615, -0.062668, 143.6],
  [51.482588, -0.06264, 144],
  [51.482567, -0.062615, 144.4],
  [51.48254, -0.062591, 144.6],
  [51.482513, -0.062568, 145],
  [51.482486, -0.062547, 145.2],
  [51.482454, -0.062526, 145.6],
  [51.482427, -0.062505, 145.8],
  [51.482395, -0.062484, 146.2],
  [51.482368, -0.062462, 146.4],
  [51.482341, -0.062439, 146.8],
  [51.482304, -0.062416, 147],
  [51.482277, -0.062392, 147.2],
  [51.482239, -0.062367, 147.6],
  [51.482207, -0.062342, 147.8],
  [51.48217, -0.062316, 148.2],
  [51.482137, -0.062289, 148.4],
  [51.4821, -0.062263, 148.6],
  [51.482062, -0.062237, 149],
  [51.482025, -0.062212, 149.2],
  [51.481982, -0.062188, 149.4],
  [51.481944, -0.062165, 149.8],
  [51.481907, -0.062143, 150],
  [51.481864, -0.062123, 150.4],
  [51.481821, -0.062104, 150.6],
  [51.481778, -0.062087, 151],
  [51.481735, -0.062073, 151.2],
  [51.481692, -0.062062, 151.6],
  [51.481649, -0.062053, 151.8],
  [51.481606, -0.062049, 152.2],
  [51.481563, -0.062048, 152.6],
  [51.481521, -0.062051, 153],
  [51.481483, -0.062058, 153.2],
  [51.48144, -0.06207, 153.6],
  [51.481408, -0.062086, 154],
  [51.48137, -0.062106, 154.4],
  [51.481338, -0.06213, 154.8],
  [51.481306, -0.062157, 155],
  [51.481274, -0.062186, 155.4],
  [51.481247, -0.062218, 155.8],
  [51.48122, -0.062252, 156.2],
  [51.481199, -0.062288, 156.6],
  [51.481177, -0.062326, 156.8],
  [51.481156, -0.062366, 157.2],
  [51.48114, -0.062407, 157.6],
  [51.481118, -0.062448, 157.8],
  [51.481097, -0.062491, 158.2],
  [51.481086, -0.062535, 158.4],
  [51.48107, -0.062579, 158.8],
  [51.481054, -0.062624, 159.2],
  [51.481038, -0.06267, 159.4],
  [51.481022, -0.062717, 159.8],
  [51.481006, -0.062764, 160],
  [51.480995, -0.062811, 160.4],
  [51.480979, -0.062859, 160.8],
  [51.480968, -0.062909, 161],
  [51.480952, -0.062957, 161.4],
  [51.480941, -0.063007, 161.6],
  [51.480925, -0.063058, 162],
  [51.480914, -0.063109, 162.2],
  [51.480904, -0.06316, 162.6],
  [51.480888, -0.063212, 163],
  [51.480877, -0.063264, 163.2],
  [51.480866, -0.063316, 163.6],
  [51.480855, -0.063368, 163.8],
  [51.480845, -0.063422, 164.2],
  [51.480834, -0.063475, 164.4],
  [51.480823, -0.063529, 164.8],
  [51.480807, -0.063583, 165],
  [51.480796, -0.063638, 165.4],
  [51.480786, -0.063693, 165.8],
  [51.480775, -0.063748, 166],
  [51.480764, -0.063803, 166.4],
  [51.480753, -0.063858, 166.6],
  [51.480743, -0.063914, 167],
  [51.480732, -0.06397, 167.2],
  [51.480721, -0.064027, 167.6],
  [51.480711, -0.064083, 168],
  [51.4807, -0.064139, 168.2],
  [51.480689, -0.064196, 168.6],
  [51.480678, -0.064254, 168.8],
  [51.480668, -0.064312, 169.2],
  [51.480657, -0.064369, 169.4],
  [51.480646, -0.064427, 169.8],
  [51.480635, -0.064486, 170],
  [51.480625, -0.064543, 170.4],
  [51.480614, -0.064602, 170.8],
  [51.480603, -0.06466, 171],
  [51.480592, -0.064719, 171.4],
  [51.480582, -0.064778, 171.6],
  [51.480571, -0.064837, 172],
  [51.48056, -0.064896, 172.2],
  [51.48055, -0.064956, 172.6],
  [51.480539, -0.065016, 172.8],
  [51.480528, -0.065076, 173.2],
  [51.480517, -0.065136, 173.4],
  [51.480507, -0.065197, 173.8],
  [51.480491, -0.065257, 174],
  [51.48048, -0.065318, 174.4],
  [51.480469, -0.065378, 174.6],
  [51.480453, -0.065439, 175],
  [51.480442, -0.0655, 175.2],
  [51.480426, -0.06556, 175.6],
  [51.48041, -0.065623, 175.8],
  [51.480399, -0.065687, 176],
  [51.480383, -0.065753, 176.2],
  [51.480367, -0.065821, 176.4],
  [51.480356, -0.065892, 176.6],
  [51.48034, -0.065968, 176.8],
  [51.480324, -0.066047, 176.8],
  [51.480308, -0.066131, 176.8],
  [51.480292, -0.066221, 176.8],
  [51.480276, -0.066317, 176.8],
  [51.48026, -0.066416, 177],
  [51.480238, -0.066519, 177],
  [51.480222, -0.066623, 177.4],
  [51.480201, -0.066723, 177.8],
  [51.480185, -0.066819, 178.2],
  [51.480163, -0.066911, 178.8],
  [51.480147, -0.066998, 179.2],
  [51.480131, -0.06708, 179.8],
  [51.480115, -0.067157, 180.2],
  [51.480099, -0.06723, 180.8],
  [51.480083, -0.067299, 181.2],
  [51.480067, -0.067364, 181.6],
  [51.480056, -0.067426, 182],
  [51.480045, -0.067485, 182.4],
  [51.480035, -0.067541, 182.8],
  [51.480024, -0.067595, 183.2],
  [51.480013, -0.067648, 183.6],
  [51.480002, -0.067699, 184],
  [51.479992, -0.067748, 184.2],
  [51.479986, -0.067797, 184.6],
  [51.479976, -0.067845, 185],
  [51.479965, -0.067893, 185.2],
  [51.479954, -0.06794, 185.6],
  [51.479943, -0.067987, 186],
  [51.479938, -0.068034, 186.4],
  [51.479927, -0.06808, 186.6],
  [51.479917, -0.068127, 187],
  [51.479911, -0.068174, 187.2],
  [51.479895, -0.06822, 187.6],
  [51.47989, -0.068266, 188],
  [51.479879, -0.068313, 188.2],
  [51.479874, -0.068359, 188.6],
  [51.479858, -0.068406, 189],
  [51.479852, -0.068453, 189.2],
  [51.479841, -0.068501, 189.6],
  [51.479831, -0.068549, 189.8],
  [51.47982, -0.068597, 190.2],
  [51.479809, -0.068645, 190.4],
  [51.479799, -0.068695, 190.8],
  [51.479788, -0.068743, 191.2],
  [51.479777, -0.068792, 191.4],
  [51.479766, -0.068842, 191.8],
  [51.479761, -0.06889, 192.2],
  [51.47975, -0.068938, 192.6],
  [51.479745, -0.068985, 192.8],
  [51.479734, -0.069031, 193.2],
  [51.479723, -0.069077, 193.6],
  [51.479713, -0.069124, 193.8],
  [51.479702, -0.069171, 194.2],
  [51.479691, -0.069218, 194.4],
  [51.479681, -0.069267, 194.6],
  [51.479664, -0.069316, 195],
  [51.479654, -0.069367, 195.2],
  [51.479638, -0.069418, 195.6],
  [51.479622, -0.06947, 195.8],
  [51.479605, -0.069523, 196.2],
  [51.479595, -0.069577, 196.4],
  [51.479579, -0.069631, 196.8],
  [51.479557, -0.069685, 197],
  [51.479541, -0.06974, 197.4],
  [51.47953, -0.069796, 197.6],
  [51.479509, -0.069852, 198],
  [51.479493, -0.069908, 198.2],
  [51.479477, -0.069965, 198.6],
  [51.479461, -0.070021, 198.8],
  [51.479439, -0.070079, 199.2],
  [51.479423, -0.070136, 199.4],
  [51.479407, -0.070193, 199.8],
  [51.479385, -0.070251, 200],
  [51.479369, -0.070308, 200.4],
  [51.479353, -0.070366, 200.6],
  [51.479332, -0.070425, 201],
  [51.479316, -0.070482, 201.2],
  [51.479294, -0.070541, 201.6],
  [51.479278, -0.070599, 201.8],
  [51.479257, -0.070658, 202.2],
  [51.479235, -0.070717, 202.4],
  [51.479219, -0.070775, 202.8],
  [51.479198, -0.070834, 203],
  [51.479187, -0.070893, 203.4],
  [51.479166, -0.070951, 203.6],
  [51.479144, -0.071011, 204],
  [51.479128, -0.07107, 204.2],
  [51.479107, -0.071129, 204.6],
  [51.47909, -0.071188, 205],
  [51.479064, -0.071247, 205.2],
  [51.479048, -0.071306, 205.6],
  [51.479026, -0.071366, 205.8],
  [51.47901, -0.071425, 206.2],
  [51.478989, -0.071484, 206.4],
  [51.478972, -0.071544, 206.8],
  [51.478951, -0.071603, 207],
  [51.478935, -0.071663, 207.4],
  [51.478913, -0.071722, 207.6],
  [51.478892, -0.071781, 208],
  [51.478876, -0.071841, 208.2],
  [51.478854, -0.0719, 208.6],
  [51.478838, -0.07196, 208.8],
  [51.478817, -0.07202, 209.2],
  [51.478801, -0.072079, 209.4],
  [51.478779, -0.07214, 209.8],
  [51.478758, -0.072199, 210.2],
  [51.478736, -0.072259, 210.4],
  [51.47872, -0.072319, 210.8],
  [51.478699, -0.072379, 211],
  [51.478683, -0.072438, 211.4],
  [51.478661, -0.072498, 211.6],
  [51.478645, -0.072558, 212],
  [51.478624, -0.072618, 212.2],
  [51.478602, -0.072678, 212.6],
  [51.478586, -0.072738, 212.8],
  [51.478565, -0.072798, 213.2],
  [51.478543, -0.072859, 213.4],
  [51.478522, -0.072918, 213.8],
  [51.478506, -0.072978, 214],
  [51.478484, -0.073038, 214.4],
  [51.478468, -0.073098, 214.6],
  [51.478447, -0.073158, 215],
  [51.478431, -0.073218, 215.4],
  [51.478409, -0.073279, 215.6],
  [51.478393, -0.073339, 216],
  [51.478372, -0.073399, 216.2],
  [51.47835, -0.07346, 216.6],
  [51.478329, -0.07352, 216.8],
  [51.478313, -0.073581, 217.2],
  [51.478291, -0.073641, 217.4],
  [51.478275, -0.073702, 217.8],
  [51.478254, -0.073763, 218],
  [51.478232, -0.073824, 218.4],
  [51.478216, -0.073884, 218.6],
  [51.478195, -0.073945, 219],
  [51.478179, -0.074006, 219.2],
  [51.478157, -0.074067, 219.6],
  [51.478141, -0.074129, 219.8],
  [51.478119, -0.074189, 220.2],
  [51.478103, -0.07425, 220.4],
  [51.478087, -0.074312, 220.8],
  [51.478066, -0.074373, 221.2],
  [51.47805, -0.074434, 221.4],
  [51.478028, -0.074495, 221.8],
  [51.478007, -0.074557, 222],
  [51.477991, -0.074618, 222.4],
  [51.477975, -0.07468, 222.6],
  [51.477959, -0.074742, 223],
  [51.477937, -0.074804, 223.2],
  [51.477916, -0.074866, 223.6],
  [51.477905, -0.074928, 223.8],
  [51.477883, -0.074991, 224.2],
  [51.477867, -0.075054, 224.4],
  [51.477851, -0.075117, 224.8],
  [51.477835, -0.075181, 225],
  [51.477814, -0.075244, 225.4],
  [51.477798, -0.075308, 225.6],
  [51.477782, -0.075371, 226],
  [51.477765, -0.075436, 226.2],
  [51.477749, -0.0755, 226.6],
  [51.477733, -0.075565, 226.8],
  [51.477717, -0.07563, 227.2],
  [51.477701, -0.075695, 227.4],
  [51.477685, -0.075762, 227.8],
  [51.477669, -0.075828, 228],
  [51.477658, -0.075894, 228.4],
  [51.477642, -0.075962, 228.6],
  [51.477626, -0.076029, 229],
  [51.477615, -0.076097, 229.2],
  [51.477599, -0.076165, 229.4],
  [51.477588, -0.076235, 229.8],
  [51.477572, -0.076304, 230],
  [51.477562, -0.076374, 230.4],
  [51.477551, -0.076444, 230.6],
  [51.47754, -0.076516, 231],
  [51.477529, -0.076588, 231.2],
  [51.477519, -0.076659, 231.4],
  [51.477508, -0.076731, 231.8],
  [51.477492, -0.076804, 232],
  [51.477486, -0.076876, 232.4],
  [51.477476, -0.076949, 232.6],
  [51.477465, -0.077022, 233],
  [51.477454, -0.077095, 233.2],
  [51.477444, -0.077168, 233.6],
  [51.477433, -0.07724, 233.8],
  [51.477422, -0.077312, 234.2],
  [51.477411, -0.077383, 234.4],
  [51.477401, -0.077454, 234.8],
  [51.47739, -0.077526, 235],
  [51.477379, -0.077597, 235.4],
  [51.477374, -0.07767, 235.6],
  [51.477363, -0.077742, 236],
  [51.477358, -0.077814, 236.2],
  [51.477347, -0.077886, 236.6],
  [51.477342, -0.077958, 236.8],
  [51.477336, -0.07803, 237.2],
  [51.477331, -0.078102, 237.4],
  [51.47732, -0.078174, 237.8],
  [51.47732, -0.078247, 238],
  [51.477315, -0.07832, 238.4],
  [51.477309, -0.078392, 238.6],
  [51.477309, -0.078465, 239],
  [51.477304, -0.078538, 239.2],
  [51.477299, -0.07861, 239.6],
  [51.477299, -0.078683, 239.8],
  [51.477293, -0.078756, 240.2],
  [51.477288, -0.078829, 240.4],
  [51.477288, -0.078902, 240.8],
  [51.477288, -0.078975, 241.2],
  [51.477293, -0.079047, 241.4],
  [51.477299, -0.079117, 241.8],
  [51.477304, -0.079186, 242.2],
  [51.477315, -0.079254, 242.4],
  [51.47732, -0.079324, 242.6],
  [51.477326, -0.079397, 242.8],
  [51.477331, -0.079474, 243],
  [51.477331, -0.079555, 243],
  [51.477331, -0.07964, 243.2],
  [51.477336, -0.07973, 243.2],
  [51.477336, -0.079823, 243.2],
  [51.477336, -0.079922, 243.2],
  [51.477336, -0.080025, 243.2],
  [51.477336, -0.080131, 243.4],
  [51.477336, -0.080241, 243.4],
  [51.477336, -0.080355, 243.4],
  [51.477336, -0.080472, 243.4],
  [51.477331, -0.080592, 243.4],
  [51.47732, -0.080714, 243.4],
  [51.477309, -0.080837, 243.6],
  [51.477299, -0.080959, 243.6],
  [51.477272, -0.081081, 243.8],
  [51.477245, -0.081201, 244],
  [51.477218, -0.081317, 244.2],
  [51.477181, -0.08143, 244.4],
  [51.477149, -0.08154, 244.6],
  [51.477111, -0.08165, 244.8],
  [51.477073, -0.081758, 245],
  [51.477031, -0.081864, 245.2],
  [51.476998, -0.081972, 245.2],
  [51.476961, -0.082081, 245.4],
  [51.476929, -0.08219, 245.6],
  [51.476891, -0.082301, 245.6],
  [51.476859, -0.082417, 245.8],
  [51.476832, -0.082533, 245.8],
  [51.4768, -0.082652, 245.8],
  [51.476773, -0.082773, 246],
  [51.476741, -0.082895, 246],
  [51.476714, -0.08302, 246],
  [51.476687, -0.083147, 246],
  [51.47666, -0.083277, 245.8],
  [51.476634, -0.083409, 245.8],
  [51.476601, -0.083543, 245.8],
  [51.476575, -0.083678, 245.8],
  [51.476553, -0.083815, 245.8],
  [51.476526, -0.083953, 245.8],
  [51.476505, -0.084093, 245.8],
  [51.476478, -0.084234, 246],
  [51.476457, -0.084377, 246],
  [51.476435, -0.08452, 246],
  [51.476414, -0.084664, 246],
  [51.476392, -0.084809, 246],
  [51.476371, -0.084954, 246],
  [51.476355, -0.085101, 246],
  [51.476333, -0.085246, 246],
  [51.476317, -0.085393, 246.2],
  [51.476301, -0.08554, 246.2],
  [51.476279, -0.085685, 246.4],
  [51.476263, -0.085831, 246.4],
  [51.476247, -0.085974, 246.6],
  [51.476231, -0.086117, 246.8],
  [51.476215, -0.086258, 247],
  [51.476199, -0.086397, 247.2],
  [51.476188, -0.086535, 247.6],
  [51.476172, -0.086669, 247.8],
  [51.476161, -0.086804, 248],
  [51.476145, -0.086936, 248.2],
  [51.476129, -0.087068, 248.4],
  [51.476108, -0.087198, 248.4],
  [51.476092, -0.087328, 248.6],
  [51.47607, -0.087456, 248.6],
  [51.476043, -0.087584, 248.8],
  [51.476017, -0.087711, 248.8],
  [51.47599, -0.087837, 249],
  [51.475963, -0.087965, 249.2],
  [51.475936, -0.088092, 249.2],
  [51.475904, -0.088218, 249.2],
  [51.475877, -0.088346, 249.4],
  [51.475845, -0.088475, 249.4],
  [51.475818, -0.088604, 249.4],
  [51.475791, -0.088736, 249.4],
  [51.475765, -0.088871, 249.4],
  [51.475743, -0.089009, 249.2],
  [51.475727, -0.089154, 249],
  [51.475727, -0.089306, 248.4],
  [51.475754, -0.08946, 247.6],
  [51.475791, -0.089617, 246.2],
  [51.475829, -0.089792, 244.8],
  [51.475856, -0.089986, 243.6],
  [51.475872, -0.09019, 242.4],
  [51.475893, -0.090401, 241],
  [51.475909, -0.090622, 239.6],
  [51.475931, -0.090849, 238.2],
  [51.475952, -0.091081, 236.8],
  [51.475974, -0.091322, 235.2],
  [51.47599, -0.091569, 233.6],
  [51.476001, -0.09182, 232],
  [51.476001, -0.092078, 230.2],
  [51.476006, -0.092345, 228.2],
  [51.476001, -0.092613, 226.2],
  [51.47599, -0.092887, 224.2],
  [51.475963, -0.093164, 222.2],
  [51.475899, -0.093421, 220.8],
  [51.475759, -0.093601, 219.6],
  [51.475604, -0.093725, 218.8],
  [51.475448, -0.093822, 218],
  [51.475287, -0.093915, 217.4],
  [51.475132, -0.094011, 216.6],
  [51.474987, -0.094138, 215.6],
  [51.474858, -0.094302, 214],
  [51.474751, -0.094507, 211.8],
  [51.474665, -0.094743, 209.6],
  [51.474574, -0.094987, 207.4],
  [51.474499, -0.095243, 205.2],
  [51.474418, -0.095508, 202.8],
  [51.474354, -0.095781, 200.4],
  [51.474311, -0.096071, 198.2],
  [51.474343, -0.096368, 196],
  [51.474413, -0.096652, 193.8],
  [51.474445, -0.096955, 191.6],
  [51.47444, -0.097258, 189.6],
  [51.474413, -0.097564, 187.4],
  [51.474386, -0.097866, 185.2],
  [51.474359, -0.098174, 183],
  [51.474348, -0.098482, 181],
  [51.474359, -0.098791, 179],
  [51.474391, -0.099096, 177.2],
  [51.474434, -0.099395, 175.2],
  [51.474482, -0.099693, 173.6],
  [51.474531, -0.099989, 171.8],
  [51.474574, -0.100283, 170.2],
  [51.474595, -0.100582, 169],
  [51.474584, -0.100878, 168.2],
  [51.474563, -0.101166, 167.2],
  [51.474547, -0.101452, 165.4],
  [51.474525, -0.10174, 163.4],
  [51.474509, -0.102031, 161.4],
  [51.474488, -0.102323, 159.4],
  [51.474477, -0.102617, 157.4],
  [51.474472, -0.102914, 155.6],
  [51.474472, -0.10321, 154],
  [51.474477, -0.103506, 152.6],
  [51.474482, -0.103798, 151],
  [51.474499, -0.104092, 149.6],
  [51.474515, -0.104381, 148],
  [51.474536, -0.104671, 146.4],
  [51.474563, -0.10496, 144.6],
  [51.47459, -0.105247, 142.8],
  [51.474617, -0.105534, 141],
  [51.474654, -0.105825, 139.2],
  [51.474686, -0.106112, 137.6],
  [51.474724, -0.1064, 136],
  [51.474756, -0.106687, 134.6],
  [51.474788, -0.106973, 133.2],
  [51.474826, -0.107255, 131.8],
  [51.474879, -0.107531, 130.6],
  [51.474933, -0.107799, 129.4],
  [51.475008, -0.108057, 128.2],
  [51.475089, -0.108304, 127.2],
  [51.475174, -0.108547, 126],
  [51.47526, -0.108782, 125],
  [51.475357, -0.109008, 124.2],
  [51.475459, -0.109229, 123.4],
  [51.475582, -0.10939, 123.2],
  [51.475732, -0.109498, 122.8],
  [51.475883, -0.109583, 122.6],
  [51.476027, -0.109656, 122.2],
  [51.476172, -0.109723, 122],
  [51.476317, -0.109788, 121.6],
  [51.476457, -0.109852, 121.2],
  [51.476596, -0.109915, 121],
  [51.476735, -0.109981, 120.6],
  [51.47687, -0.110061, 120.2],
  [51.476988, -0.110173, 119.4],
  [51.477073, -0.110353, 118.6],
  [51.4771, -0.110575, 118],
  [51.477106, -0.110801, 117.6],
  [51.477111, -0.111028, 117.4],
  [51.477111, -0.111249, 117],
  [51.477106, -0.11147, 116.8],
  [51.4771, -0.111687, 116.6],
  [51.4771, -0.111904, 116.2],
  [51.4771, -0.112117, 116],
  [51.477095, -0.112328, 115.8],
  [51.4771, -0.112537, 115.6],
  [51.4771, -0.112742, 115.6],
  [51.477106, -0.112947, 115.4],
  [51.477111, -0.113149, 115.4],
  [51.477116, -0.113346, 115.2],
  [51.477127, -0.113542, 115.2],
  [51.477138, -0.113735, 115.2],
  [51.477149, -0.113927, 115.2],
  [51.47717, -0.11411, 115.2],
  [51.477208, -0.114288, 115],
  [51.477283, -0.114427, 114.8],
  [51.477401, -0.11445, 114.6],
  [51.477508, -0.114394, 114.4],
  [51.477615, -0.114321, 114],
  [51.477717, -0.114221, 113.6],
  [51.477814, -0.114104, 113.2],
  [51.477905, -0.11398, 112.8],
  [51.477996, -0.113856, 112.4],
  [51.478093, -0.113735, 111.8],
  [51.4782, -0.113629, 111],
  [51.478313, -0.113543, 110.2],
  [51.478436, -0.113471, 109],
  [51.478565, -0.11341, 107.8],
  [51.478699, -0.113356, 106.4],
  [51.478844, -0.113306, 105.2],
  [51.478983, -0.113256, 104],
  [51.479128, -0.113206, 103.2],
  [51.479273, -0.113154, 102.6],
  [51.479412, -0.113105, 102.2],
  [51.479557, -0.113059, 101.8],
  [51.479702, -0.113016, 101.6],
  [51.479836, -0.112973, 101.2],
  [51.479976, -0.112931, 101.2],
  [51.48011, -0.112889, 101],
  [51.480238, -0.112846, 100.8],
  [51.480373, -0.1128, 100.6],
  [51.480496, -0.112749, 100.6],
  [51.480625, -0.112692, 100.4],
  [51.480743, -0.112628, 100.4],
  [51.480861, -0.112559, 100.4],
  [51.480979, -0.112486, 100.4],
  [51.481086, -0.112412, 100.4],
  [51.481199, -0.112338, 100.4],
  [51.481311, -0.112267, 100.4],
  [51.481419, -0.112197, 100.2],
  [51.481526, -0.112133, 100],
  [51.481639, -0.112078, 99.8],
  [51.481751, -0.112033, 99.4],
  [51.481869, -0.111992, 98.8],
  [51.481982, -0.111952, 98.4],
  [51.482105, -0.111911, 97.8],
  [51.482223, -0.111864, 97.2],
  [51.482341, -0.111804, 96.8],
  [51.482459, -0.111723, 96.4],
  [51.482567, -0.11162, 96.2],
  [51.482669, -0.111502, 96.2],
  [51.482765, -0.111371, 96],
  [51.482846, -0.111234, 96.2],
  [51.482921, -0.111089, 96.2],
  [51.483001, -0.110941, 96.2],
  [51.483065, -0.110791, 96],
  [51.483135, -0.110639, 96],
  [51.4832, -0.110484, 95.6],
  [51.483253, -0.110323, 95.4],
  [51.483307, -0.110155, 95],
  [51.483355, -0.109986, 94.8],
  [51.483403, -0.109814, 94.4],
  [51.483452, -0.109639, 94],
  [51.4835, -0.109464, 93.6],
  [51.483554, -0.109288, 93.2],
  [51.483607, -0.109114, 92.8],
  [51.483677, -0.108958, 92.4],
  [51.483768, -0.108824, 92],
  [51.483881, -0.108729, 91.4],
  [51.483994, -0.108649, 91],
  [51.484112, -0.108588, 90.2],
  [51.48424, -0.108615, 90.4],
  [51.484353, -0.108696, 90.8],
  [51.484455, -0.108792, 91.2],
  [51.484551, -0.108884, 91.6],
  [51.484653, -0.10896, 92.2],
  [51.484755, -0.108998, 92.6],
  [51.484852, -0.108945, 93.2],
  [51.484905, -0.108815, 93.4],
  [51.484938, -0.108668, 93.6],
  [51.484954, -0.108521, 93.8],
  [51.48497, -0.108372, 94],
  [51.484981, -0.108226, 94.2],
  [51.484986, -0.108079, 94.4],
  [51.484997, -0.107933, 94.4],
  [51.484997, -0.107788, 94.6],
  [51.485002, -0.107644, 94.6],
  [51.485013, -0.107499, 94.8],
  [51.485023, -0.107359, 95],
  [51.485034, -0.107217, 95],
  [51.485045, -0.107073, 95],
  [51.485045, -0.106926, 94.8],
  [51.485045, -0.106779, 94.8],
  [51.485045, -0.106629, 94.6],
  [51.48505, -0.106476, 94.4],
  [51.485061, -0.106322, 94.4],
  [51.485066, -0.106164, 94.2],
  [51.485072, -0.106007, 94],
  [51.485088, -0.105846, 93.8],
  [51.485093, -0.105685, 93.8],
  [51.485099, -0.10552, 93.6],
  [51.485099, -0.105355, 93.2],
  [51.485099, -0.105186, 93],
  [51.485088, -0.105016, 92.6],
  [51.485061, -0.104846, 92.2],
  [51.485029, -0.104679, 91.8],
  [51.48497, -0.104522, 91.2],
  [51.4849, -0.104378, 90.4],
  [51.484809, -0.104245, 89.8],
  [51.484718, -0.104119, 88.8],
  [51.484616, -0.103996, 88],
  [51.484514, -0.103872, 87],
  [51.484407, -0.103742, 86.2],
  [51.48431, -0.103596, 85.2],
  [51.48423, -0.103417, 84],
  [51.484176, -0.103204, 83],
  [51.48416, -0.102977, 82.2],
  [51.48416, -0.102748, 81.4],
  [51.484181, -0.102518, 80.6],
  [51.484208, -0.102291, 80],
  [51.484251, -0.102068, 79.2],
  [51.484294, -0.101849, 78.6],
  [51.484348, -0.101633, 78.2],
  [51.484407, -0.101419, 77.8],
  [51.484466, -0.101214, 77.4],
  [51.484525, -0.101014, 77.4],
  [51.4846, -0.100827, 77.2],
  [51.48468, -0.100655, 77],
  [51.484777, -0.100509, 76.8],
  [51.484889, -0.1004, 76.6],
  [51.485013, -0.100332, 76.2],
  [51.485142, -0.100298, 75.8],
  [51.48527, -0.100282, 75.2],
  [51.485404, -0.100276, 74.8],
  [51.485538, -0.100281, 74.4],
  [51.485667, -0.100294, 74],
  [51.485796, -0.100316, 73.6],
  [51.485925, -0.100347, 73.2],
  [51.486053, -0.100387, 72.8],
  [51.486182, -0.100438, 72.2],
  [51.486306, -0.100502, 71.8],
  [51.486429, -0.100581, 71.4],
  [51.486542, -0.10068, 71],
  [51.486649, -0.100801, 70.6],
  [51.48674, -0.100951, 70.2],
  [51.486815, -0.101116, 70],
  [51.486885, -0.101295, 69.8],
  [51.486944, -0.101478, 69.8],
  [51.486987, -0.101663, 69.6],
  [51.48703, -0.101852, 69.4],
  [51.487067, -0.102043, 69.4],
  [51.4871, -0.102231, 69.2],
  [51.487126, -0.102421, 69.2],
  [51.487153, -0.10261, 69],
  [51.487175, -0.1028, 68.8],
  [51.487185, -0.102988, 68.6],
  [51.487201, -0.103179, 68.4],
  [51.487212, -0.10337, 68],
  [51.487228, -0.10356, 67.6],
  [51.487228, -0.103754, 67],
  [51.487218, -0.103951, 66.6],
  [51.487201, -0.10415, 66],
  [51.487185, -0.10435, 65.2],
  [51.487159, -0.104551, 64.6],
  [51.487132, -0.104754, 63.8],
  [51.487094, -0.104959, 63.2],
  [51.487062, -0.105164, 62.4],
  [51.487024, -0.105373, 61.6],
  [51.486987, -0.105584, 60.8],
  [51.486949, -0.105794, 60.2],
  [51.486917, -0.10601, 59.4],
  [51.486885, -0.106228, 58.8],
  [51.486858, -0.106448, 58.2],
  [51.486826, -0.106665, 57.8],
  [51.486788, -0.106878, 57.4],
  [51.486751, -0.107092, 57],
  [51.486724, -0.107305, 56.6],
  [51.486708, -0.107519, 56.4],
  [51.486735, -0.107729, 56],
  [51.48681, -0.107903, 56],
  [51.486917, -0.108019, 56],
  [51.487041, -0.108061, 56.2],
  [51.487164, -0.108016, 56],
  [51.487266, -0.107899, 55.6],
  [51.487341, -0.10774, 55.2],
  [51.4874, -0.107561, 54.6],
  [51.487448, -0.107373, 54],
  [51.487491, -0.107181, 53.6],
  [51.487534, -0.106984, 53],
  [51.487577, -0.106787, 52.4],
  [51.487615, -0.106587, 51.8],
  [51.487657, -0.106385, 51.2],
  [51.4877, -0.106183, 50.6],
  [51.487743, -0.10598, 50],
  [51.487781, -0.105776, 49.4],
  [51.487834, -0.105574, 48.8],
  [51.487883, -0.105371, 48],
  [51.487942, -0.105174, 47.2],
  [51.488033, -0.105, 46.2],
  [51.488162, -0.104919, 45],
  [51.488296, -0.104973, 44],
  [51.488419, -0.105105, 43],
  [51.488521, -0.105273, 42.2],
  [51.488607, -0.105468, 41.2],
  [51.488661, -0.105691, 39.8],
  [51.48865, -0.105934, 38.2],
  [51.488602, -0.106171, 37],
  [51.488537, -0.106405, 35.6],
  [51.488473, -0.106638, 34.4],
  [51.488414, -0.106877, 33],
  [51.48836, -0.107119, 31.8],
  [51.488296, -0.10736, 30.4],
  [51.488231, -0.107601, 29.2],
  [51.488162, -0.107843, 27.8],
  [51.488108, -0.108091, 26.4],
  [51.488054, -0.108343, 25],
  [51.488001, -0.108599, 23.4],
  [51.487947, -0.108857, 21.8],
  [51.487899, -0.109116, 20.2],
  [51.487845, -0.109381, 18.4],
  [51.487797, -0.109649, 16.8],
  [51.487754, -0.10992, 15.4],
  [51.487722, -0.110196, 14.2],
  [51.48769, -0.110469, 13.2],
  [51.487668, -0.11074, 12.4],
  [51.487657, -0.111008, 11.8],
  [51.487663, -0.111274, 11.4],
  [51.48769, -0.111526, 11.2],
  [51.487738, -0.111767, 11.4],
  [51.487797, -0.111989, 11.8],
  [51.487872, -0.112186, 12.2],
  [51.487963, -0.112353, 12.8],
  [51.48807, -0.112488, 13.2],
  [51.488178, -0.112594, 13.4],
  [51.48829, -0.112677, 13.4],
  [51.488403, -0.112748, 13.6],
  [51.488521, -0.112809, 13.6],
  [51.488634, -0.112862, 13.6],
  [51.488746, -0.112909, 13.6],
  [51.488864, -0.112949, 13.6],
  [51.488972, -0.112981, 13.6],
  [51.489084, -0.113006, 13.6],
  [51.489197, -0.113021, 13.6],
  [51.489304, -0.113024, 13.6],
  [51.489417, -0.11301, 13.6],
  [51.489524, -0.112965, 13.8],
  [51.489615, -0.112886, 13.8],
  [51.489696, -0.112784, 14],
  [51.489776, -0.112668, 14.2],
  [51.489841, -0.112545, 14.4],
  [51.489905, -0.112419, 14.4],
  [51.489964, -0.112292, 14.6],
  [51.490023, -0.11217, 14.6],
  [51.490098, -0.112058, 14.6],
  [51.490173, -0.111967, 14.6],
  [51.490265, -0.111896, 14.4],
  [51.490361, -0.111838, 14],
  [51.490463, -0.111794, 13.4],
  [51.490565, -0.111765, 12.8],
  [51.490678, -0.111746, 12.2],
  [51.49079, -0.111732, 11.8],
  [51.490908, -0.11172, 11.4],
  [51.491026, -0.111708, 11.2],
  [51.491139, -0.111703, 10.8],
  [51.491257, -0.111704, 10.6],
  [51.491375, -0.111706, 10.4],
  [51.491493, -0.11171, 10.2],
  [51.491611, -0.111714, 10.2],
  [51.491729, -0.111719, 10],
  [51.491842, -0.111723, 9.8],
  [51.49196, -0.111728, 9.6],
  [51.492072, -0.111731, 9.4],
  [51.49219, -0.111731, 9.2],
  [51.492308, -0.111728, 9],
  [51.492421, -0.111721, 8.8],
  [51.492534, -0.11171, 8.8],
  [51.492652, -0.111697, 8.6],
  [51.49277, -0.111682, 8.4],
  [51.492877, -0.111667, 8.2],
  [51.492995, -0.11165, 8.2],
  [51.493108, -0.111633, 8],
  [51.49322, -0.111615, 7.8],
  [51.493338, -0.111597, 7.6],
  [51.493446, -0.111578, 7.6],
  [51.493564, -0.111559, 7.4],
  [51.493671, -0.111534, 7.2],
  [51.493789, -0.111512, 7.2],
  [51.493896, -0.111491, 7.2],
  [51.494009, -0.111472, 7],
  [51.494122, -0.111451, 6.6],
  [51.494234, -0.11143, 6.2],
];

export default stream;
