import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.755317, 165.84829, 135.6],
  [-10.755156, 165.848333, 136.2],
  [-10.755, 165.848376, 136.6],
  [-10.754847, 165.84844, 137.2],
  [-10.754701, 165.848504, 137.6],
  [-10.754558, 165.848558, 138.2],
  [-10.75442, 165.848633, 138.6],
  [-10.754285, 165.848708, 139],
  [-10.754153, 165.848805, 139.4],
  [-10.754026, 165.84888, 139.8],
  [-10.753903, 165.848976, 140],
  [-10.753799, 165.849051, 140.2],
  [-10.753715, 165.849105, 140],
  [-10.753654, 165.849169, 139.6],
  [-10.75361, 165.849234, 139.2],
  [-10.75358, 165.849277, 139],
  [-10.753562, 165.84932, 138.8],
  [-10.753553, 165.849341, 138.8],
  [-10.753549, 165.849341, 138.8],
  [-10.753536, 165.849373, 138.8],
  [-10.753514, 165.849427, 138.6],
  [-10.75349, 165.849502, 138.2],
  [-10.753456, 165.849588, 138],
  [-10.7534, 165.849674, 137.6],
  [-10.75332, 165.84977, 137.2],
  [-10.753227, 165.849845, 136.8],
  [-10.753119, 165.849921, 136.2],
  [-10.753004, 165.849996, 135.8],
  [-10.752883, 165.850071, 135.2],
  [-10.752751, 165.850135, 134.8],
  [-10.752599, 165.850146, 134.4],
  [-10.752444, 165.850114, 133.8],
  [-10.752286, 165.850114, 133.4],
  [-10.752131, 165.850167, 133.2],
  [-10.751989, 165.850242, 133.2],
  [-10.751882, 165.85036, 133.2],
  [-10.751824, 165.850532, 133],
  [-10.751773, 165.850682, 133],
  [-10.751687, 165.850822, 133],
  [-10.751587, 165.850961, 132.8],
  [-10.751475, 165.851079, 132.8],
  [-10.751345, 165.851187, 132.8],
  [-10.751208, 165.851272, 132.8],
  [-10.751086, 165.85138, 132.8],
  [-10.750984, 165.851519, 132.8],
  [-10.750899, 165.851659, 132.8],
  [-10.750847, 165.85183, 132.8],
  [-10.750829, 165.85198, 132.8],
];

export default stream;
