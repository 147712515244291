import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.659694, 166.967533, 6.4],
  [-11.659505, 166.967468, 6.4],
  [-11.659317, 166.967415, 6.4],
  [-11.659131, 166.96735, 6.4],
  [-11.658943, 166.967286, 6.4],
  [-11.658758, 166.967232, 6.4],
  [-11.658574, 166.967168, 6.4],
  [-11.65839, 166.967125, 6.4],
  [-11.658205, 166.967061, 6.4],
  [-11.65802, 166.966996, 6.4],
  [-11.657838, 166.966943, 6.8],
  [-11.657657, 166.966878, 7],
  [-11.65748, 166.966814, 7.4],
  [-11.657303, 166.96676, 7.2],
  [-11.657123, 166.966696, 6.6],
  [-11.656939, 166.966621, 5.4],
  [-11.656754, 166.966556, 4],
  [-11.656563, 166.966492, 2.6],
  [-11.656365, 166.966438, 1.2],
  [-11.656164, 166.966363, -0.2],
  [-11.655966, 166.966299, -1.8],
  [-11.655761, 166.966234, -3.2],
  [-11.655557, 166.966159, -4.8],
  [-11.65535, 166.966095, -6],
  [-11.655146, 166.96602, -6.4],
  [-11.654941, 166.965945, -6],
  [-11.654745, 166.96587, -5.6],
  [-11.65455, 166.965805, -5.4],
  [-11.65436, 166.96573, -5.4],
  [-11.654166, 166.965677, -5.4],
  [-11.653977, 166.965591, -5.4],
  [-11.653789, 166.965537, -5.4],
  [-11.653605, 166.965462, -5.4],
  [-11.653416, 166.965398, -5.4],
  [-11.65323, 166.965333, -5.4],
  [-11.65305, 166.965258, -5.4],
  [-11.652866, 166.965194, -5.4],
  [-11.652684, 166.965119, -5.4],
  [-11.652499, 166.965065, -5.4],
  [-11.652321, 166.96499, -5.4],
  [-11.652141, 166.964904, -5.4],
  [-11.651964, 166.96485, -5.4],
  [-11.651784, 166.964775, -5.4],
  [-11.651608, 166.9647, -5.4],
  [-11.651427, 166.964614, -5.4],
  [-11.651252, 166.964539, -5.4],
  [-11.651081, 166.964464, -5.4],
  [-11.650918, 166.964357, -5.4],
  [-11.650788, 166.964217, -5.4],
  [-11.650754, 166.964035, -5.4],
  [-11.650801, 166.963853, -5.4],
  [-11.650874, 166.96367, -5.4],
  [-11.65095, 166.963488, -5.4],
  [-11.651024, 166.963327, -5.4],
  [-11.651096, 166.963134, -5.4],
  [-11.651175, 166.962951, -5.4],
  [-11.651254, 166.96279, -5.4],
  [-11.651327, 166.962608, -5.4],
];

export default stream;
