import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.659624, 166.976674, 28],
  [-11.659744, 166.976491, 28],
  [-11.659753, 166.976287, 27.8],
  [-11.659662, 166.976094, 27],
  [-11.659626, 166.97588, 26.4],
  [-11.659467, 166.97574, 25.4],
  [-11.659247, 166.975708, 24.6],
  [-11.659037, 166.975654, 24],
  [-11.658973, 166.975451, 23.2],
  [-11.65912, 166.9753, 22.6],
  [-11.659336, 166.975365, 21.8],
  [-11.659557, 166.975365, 21.2],
  [-11.659705, 166.975204, 20.8],
  [-11.659639, 166.975, 20.2],
  [-11.659445, 166.974871, 19.6],
  [-11.659278, 166.974721, 19],
  [-11.659093, 166.974581, 18.6],
  [-11.658877, 166.974517, 18],
  [-11.658656, 166.974474, 17.4],
  [-11.658437, 166.974399, 17],
  [-11.658246, 166.974281, 16.4],
  [-11.658144, 166.974077, 15.8],
  [-11.658126, 166.973852, 15.4],
  [-11.658134, 166.973627, 15],
  [-11.658124, 166.973391, 14.4],
  [-11.658077, 166.973165, 14],
  [-11.658019, 166.972951, 13.6],
  [-11.657979, 166.972725, 13.2],
  [-11.657975, 166.972489, 12.8],
  [-11.658008, 166.972275, 12.4],
  [-11.658067, 166.972049, 12],
  [-11.658138, 166.971835, 11.8],
  [-11.658219, 166.97162, 11.4],
  [-11.658306, 166.971406, 11.2],
  [-11.6584, 166.971213, 10.8],
  [-11.658504, 166.971009, 10.4],
  [-11.658632, 166.970826, 10],
  [-11.658793, 166.970665, 9.4],
  [-11.658971, 166.970526, 8.8],
  [-11.659137, 166.970376, 8.2],
  [-11.659293, 166.970204, 7.6],
  [-11.65945, 166.970054, 7.2],
  [-11.659588, 166.969872, 6.6],
  [-11.659662, 166.969657, 6.4],
  [-11.65965, 166.969421, 6.4],
  [-11.65962, 166.969196, 6.4],
  [-11.659603, 166.968981, 6.4],
  [-11.659577, 166.968756, 6.4],
  [-11.659552, 166.968541, 6.4],
  [-11.659546, 166.968316, 6.6],
  [-11.659573, 166.968101, 6.4],
  [-11.659626, 166.967887, 6.4],
];

export default stream;
