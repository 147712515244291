import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.635211, 166.973712, 13],
  [-11.635145, 166.973927, 13],
  [-11.635077, 166.97412, 13],
  [-11.634996, 166.974335, 13],
  [-11.634949, 166.974549, 13],
  [-11.634953, 166.974764, 13],
  [-11.635004, 166.974978, 13.2],
  [-11.635075, 166.975182, 13.2],
  [-11.635149, 166.975375, 13.2],
  [-11.635208, 166.97559, 13.4],
  [-11.635236, 166.975805, 13.4],
  [-11.635205, 166.976019, 13.6],
  [-11.635118, 166.976212, 14],
  [-11.635004, 166.976395, 14.4],
  [-11.63488, 166.976566, 14.8],
  [-11.634755, 166.976738, 15],
  [-11.634649, 166.97692, 15.4],
  [-11.634626, 166.977135, 15.8],
  [-11.634712, 166.977317, 16.2],
  [-11.634877, 166.977457, 16.2],
  [-11.635081, 166.977478, 16.4],
  [-11.635286, 166.977435, 16.4],
  [-11.635492, 166.977392, 16.2],
  [-11.63569, 166.977468, 16.2],
  [-11.635782, 166.97765, 16],
  [-11.635805, 166.977865, 15.8],
  [-11.635812, 166.978079, 15.6],
  [-11.635814, 166.978304, 15.4],
  [-11.635841, 166.978519, 15.2],
  [-11.63594, 166.978701, 15.2],
  [-11.636121, 166.97883, 15.4],
  [-11.636335, 166.978841, 15.6],
  [-11.636547, 166.978809, 15.8],
  [-11.63675, 166.978744, 15.8],
  [-11.636948, 166.978669, 16],
  [-11.637138, 166.978562, 16],
  [-11.637323, 166.978455, 16],
  [-11.637497, 166.978337, 16.2],
  [-11.637634, 166.978165, 16],
  [-11.637661, 166.97795, 16],
  [-11.637634, 166.977746, 15.6],
  [-11.637703, 166.977543, 15.6],
  [-11.637906, 166.977478, 15.6],
  [-11.638117, 166.977457, 15.8],
  [-11.638282, 166.977317, 15.8],
];

export default stream;
