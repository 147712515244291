import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-21.737903, 166.177032, 13],
  [-21.737773, 166.176914, 13],
  [-21.737646, 166.176796, 13],
  [-21.73752, 166.176667, 13],
  [-21.737393, 166.176549, 13.2],
  [-21.737266, 166.176431, 13.4],
  [-21.73714, 166.176302, 13.6],
  [-21.737012, 166.176184, 14],
  [-21.736887, 166.176056, 14.2],
  [-21.736767, 166.175938, 14.8],
  [-21.736688, 166.175787, 15.2],
  [-21.736665, 166.175605, 15.6],
  [-21.736655, 166.175433, 16],
  [-21.736643, 166.175262, 16.4],
  [-21.736629, 166.175079, 16.8],
  [-21.736615, 166.174918, 17.2],
  [-21.736602, 166.174736, 17.4],
  [-21.736588, 166.174564, 17.8],
  [-21.736576, 166.174393, 18.2],
  [-21.736563, 166.174232, 18.6],
  [-21.736549, 166.17406, 19],
  [-21.736531, 166.173878, 19.4],
  [-21.736443, 166.173738, 19.8],
  [-21.736291, 166.17376, 20],
  [-21.736156, 166.173867, 20],
  [-21.736014, 166.173921, 20.2],
  [-21.735911, 166.173803, 20.6],
  [-21.735884, 166.173631, 21],
  [-21.735869, 166.17347, 21.4],
  [-21.735802, 166.173309, 21.8],
  [-21.735651, 166.173266, 22.2],
  [-21.735506, 166.173191, 22.6],
  [-21.735463, 166.173041, 23],
  [-21.735487, 166.172869, 23.2],
  [-21.735513, 166.172708, 23.6],
  [-21.735522, 166.172537, 23.8],
  [-21.735411, 166.172429, 24.4],
  [-21.735259, 166.172472, 24.6],
  [-21.735124, 166.172569, 24.2],
  [-21.73499, 166.172655, 23.8],
  [-21.734856, 166.172762, 23.2],
  [-21.734723, 166.172891, 22.4],
  [-21.734565, 166.172966, 21.8],
  [-21.734408, 166.172891, 21.2],
  [-21.734308, 166.172719, 20.6],
  [-21.734231, 166.172547, 19.8],
  [-21.73411, 166.172408, 19],
  [-21.733987, 166.172537, 18.4],
  [-21.733886, 166.172687, 17.8],
  [-21.733714, 166.172644, 17.4],
  [-21.733634, 166.172472, 17.2],
  [-21.733516, 166.172333, 16.4],
  [-21.733331, 166.172354, 15.6],
  [-21.73315, 166.172354, 15],
  [-21.733056, 166.172172, 14.4],
  [-21.73302, 166.171968, 14],
  [-21.732997, 166.171775, 13.6],
  [-21.732975, 166.171571, 13.4],
  [-21.732857, 166.171432, 13.8],
  [-21.732714, 166.171528, 14.4],
  [-21.73265, 166.17171, 15],
  [-21.732489, 166.171743, 15.4],
  [-21.732397, 166.171592, 15.8],
  [-21.732341, 166.171421, 16.2],
  [-21.732181, 166.17141, 16.6],
  [-21.732102, 166.17156, 17.2],
  [-21.732004, 166.1717, 17.8],
  [-21.73186, 166.171635, 18.4],
  [-21.731743, 166.171517, 18.8],
  [-21.731683, 166.171346, 19.2],
  [-21.731565, 166.171249, 19.4],
  [-21.731461, 166.171356, 19.8],
  [-21.731499, 166.171528, 20],
  [-21.731475, 166.171678, 20.6],
  [-21.73132, 166.171721, 21],
  [-21.731162, 166.171721, 21.8],
  [-21.731033, 166.171818, 22],
  [-21.730984, 166.171968, 22.6],
  [-21.730961, 166.17214, 23.2],
  [-21.730933, 166.172311, 23.8],
  [-21.730855, 166.17244, 24.2],
  [-21.730708, 166.172462, 24.6],
  [-21.730595, 166.172333, 25.2],
  [-21.730472, 166.172247, 25],
  [-21.730323, 166.172183, 24.6],
  [-21.730189, 166.172258, 24],
  [-21.730126, 166.172429, 23.4],
  [-21.730034, 166.17258, 22.8],
  [-21.729922, 166.172719, 22.2],
  [-21.729809, 166.172869, 21.4],
  [-21.729727, 166.173041, 20.8],
  [-21.729609, 166.173191, 20.2],
  [-21.729434, 166.173191, 19.6],
  [-21.729283, 166.173084, 19.2],
  [-21.729175, 166.172934, 19.2],
  [-21.729031, 166.172805, 19],
  [-21.72887, 166.172719, 18.6],
  [-21.728707, 166.172622, 18.2],
  [-21.728554, 166.172687, 17.4],
  [-21.728583, 166.172869, 17.2],
  [-21.728644, 166.173052, 17],
  [-21.728615, 166.173255, 16.6],
  [-21.728459, 166.173341, 15.8],
  [-21.728273, 166.173373, 15.2],
  [-21.728095, 166.173331, 14.6],
  [-21.727934, 166.173416, 13.8],
  [-21.727953, 166.173599, 13.2],
  [-21.728045, 166.173781, 13],
  [-21.728001, 166.173974, 13],
  [-21.727824, 166.173996, 12.6],
  [-21.727639, 166.173974, 12.4],
  [-21.727474, 166.17406, 12.8],
  [-21.727454, 166.174242, 13.2],
  [-21.727572, 166.174382, 13.8],
  [-21.727581, 166.174564, 14.4],
  [-21.72745, 166.174672, 15],
  [-21.727282, 166.174639, 15],
  [-21.72713, 166.174554, 14.6],
  [-21.726962, 166.174532, 14.8],
  [-21.726845, 166.174661, 15.2],
  [-21.726918, 166.174811, 15.8],
  [-21.727071, 166.174886, 16.2],
  [-21.727167, 166.175026, 16.6],
  [-21.727128, 166.175187, 17.4],
  [-21.727007, 166.175294, 18],
  [-21.726867, 166.17539, 18.4],
  [-21.72671, 166.175433, 18.6],
  [-21.726561, 166.175498, 19.2],
  [-21.726487, 166.175637, 19.8],
  [-21.72653, 166.175798, 20.2],
  [-21.726672, 166.175863, 20.4],
  [-21.726828, 166.17582, 20.2],
  [-21.726985, 166.175766, 20],
  [-21.727147, 166.175745, 19.8],
  [-21.727293, 166.17582, 19.8],
  [-21.727345, 166.175981, 20.2],
  [-21.727336, 166.176152, 19.8],
  [-21.727303, 166.176335, 19.4],
  [-21.727343, 166.176506, 18.8],
  [-21.727501, 166.17656, 18.6],
  [-21.727663, 166.176485, 18.8],
  [-21.72783, 166.176474, 18.8],
  [-21.727946, 166.176614, 18.4],
  [-21.72798, 166.176796, 17.8],
  [-21.728015, 166.176978, 17.4],
  [-21.728047, 166.177161, 16.8],
  [-21.727933, 166.177311, 16.6],
  [-21.727765, 166.177354, 16.4],
  [-21.727587, 166.177386, 16.6],
  [-21.727408, 166.177386, 16.6],
  [-21.727256, 166.177461, 16.4],
  [-21.727273, 166.177644, 16],
  [-21.727419, 166.177751, 15.6],
  [-21.727547, 166.17789, 15.2],
  [-21.727535, 166.178073, 14.8],
  [-21.727406, 166.178212, 14],
  [-21.727264, 166.17833, 13.2],
  [-21.72713, 166.17847, 13],
  [-21.727072, 166.178652, 13],
  [-21.727121, 166.178834, 12.8],
  [-21.727171, 166.179038, 13],
  [-21.727171, 166.179221, 13.6],
  [-21.727167, 166.179414, 14],
  [-21.727117, 166.179585, 14.6],
  [-21.727013, 166.179736, 15.4],
  [-21.726885, 166.179864, 15.6],
  [-21.72675, 166.179961, 15.8],
  [-21.72662, 166.180068, 16.4],
  [-21.726502, 166.180197, 17.2],
  [-21.726374, 166.180304, 17.8],
  [-21.726234, 166.18039, 18.2],
  [-21.726081, 166.180454, 18.2],
  [-21.725923, 166.180487, 18],
  [-21.725758, 166.180519, 17.8],
  [-21.725593, 166.18053, 17.4],
  [-21.725422, 166.180551, 17.2],
  [-21.725251, 166.180562, 16.8],
  [-21.725079, 166.180573, 16.8],
  [-21.724909, 166.180584, 17.2],
  [-21.724738, 166.180594, 17.8],
  [-21.724572, 166.180562, 18.2],
  [-21.72441, 166.180519, 17.8],
  [-21.724253, 166.180444, 17.2],
  [-21.724097, 166.180358, 16.4],
  [-21.723942, 166.180272, 15.8],
  [-21.723796, 166.180165, 15.8],
  [-21.723659, 166.180047, 16.2],
  [-21.723533, 166.179907, 17],
  [-21.723416, 166.179779, 17.8],
  [-21.723303, 166.17965, 18.4],
  [-21.723196, 166.17951, 18.4],
  [-21.723099, 166.17936, 17.8],
  [-21.723008, 166.179199, 17],
  [-21.722914, 166.179038, 16.8],
  [-21.722817, 166.178888, 17.2],
  [-21.722717, 166.178749, 18],
  [-21.722622, 166.178588, 18.8],
  [-21.722535, 166.178437, 19.2],
  [-21.722457, 166.178287, 19.4],
  [-21.722377, 166.178137, 18.8],
  [-21.722299, 166.177965, 18],
  [-21.722229, 166.177804, 17.2],
  [-21.722186, 166.177611, 16.8],
  [-21.722184, 166.177418, 16.8],
  [-21.722218, 166.177236, 17.4],
  [-21.722272, 166.177064, 18],
  [-21.722324, 166.176882, 18.6],
  [-21.722369, 166.176721, 18.8],
  [-21.722401, 166.176528, 18.6],
  [-21.722436, 166.176345, 17.8],
  [-21.722471, 166.176163, 17],
  [-21.722485, 166.175981, 16.4],
  [-21.722472, 166.175787, 16.2],
  [-21.722432, 166.175605, 16.6],
  [-21.722374, 166.175423, 17.4],
  [-21.722308, 166.175251, 18.2],
  [-21.722233, 166.175101, 18.8],
  [-21.72215, 166.174951, 19.4],
  [-21.722056, 166.17479, 19.6],
  [-21.721945, 166.174661, 19.2],
  [-21.721821, 166.174532, 18.8],
  [-21.721682, 166.174425, 18.2],
  [-21.721532, 166.174339, 18.2],
  [-21.721375, 166.174264, 18.6],
  [-21.721213, 166.1742, 19.2],
  [-21.721053, 166.174157, 20.2],
  [-21.720892, 166.174103, 20.8],
  [-21.720734, 166.17406, 21.4],
  [-21.720576, 166.174028, 21.8],
  [-21.720416, 166.173996, 21.6],
  [-21.720255, 166.173974, 21],
  [-21.720087, 166.173942, 20.4],
  [-21.719916, 166.173942, 19.8],
  [-21.719744, 166.173974, 19.4],
  [-21.719578, 166.174028, 19.4],
  [-21.719418, 166.174103, 19.6],
  [-21.719264, 166.174178, 19.8],
  [-21.719111, 166.174275, 20],
  [-21.718964, 166.174371, 20],
  [-21.718813, 166.174457, 19.8],
  [-21.718664, 166.174554, 19.2],
  [-21.71852, 166.174661, 18.6],
  [-21.718376, 166.174779, 17.8],
  [-21.718238, 166.174897, 17.4],
  [-21.718108, 166.175036, 17.4],
  [-21.717986, 166.175176, 17.6],
  [-21.717872, 166.175326, 17.4],
  [-21.717755, 166.175476, 16.8],
  [-21.717629, 166.175616, 16],
  [-21.717516, 166.175766, 15.2],
  [-21.717443, 166.175948, 14.8],
  [-21.717419, 166.176152, 15],
  [-21.717432, 166.176335, 15.4],
  [-21.717461, 166.176528, 16],
  [-21.717495, 166.176721, 16.2],
  [-21.71753, 166.176903, 16],
  [-21.717566, 166.177086, 15.4],
  [-21.717614, 166.177268, 14.8],
  [-21.717675, 166.17745, 14.4],
  [-21.71775, 166.177611, 14.4],
  [-21.717845, 166.177783, 15],
  [-21.717951, 166.177933, 15.8],
  [-21.718064, 166.178073, 16.6],
  [-21.718182, 166.178201, 17.2],
  [-21.718308, 166.178319, 17],
  [-21.718446, 166.178427, 16.4],
  [-21.718592, 166.178513, 15.8],
  [-21.718741, 166.17862, 15.6],
  [-21.718884, 166.178727, 15.8],
  [-21.719029, 166.178834, 16.6],
  [-21.719171, 166.178931, 17.2],
  [-21.71931, 166.179038, 18],
  [-21.719437, 166.179146, 18.4],
  [-21.719554, 166.179274, 18.4],
  [-21.719658, 166.179414, 18],
  [-21.71976, 166.179553, 17.2],
  [-21.719851, 166.179703, 16.6],
  [-21.719939, 166.179875, 16],
  [-21.720015, 166.180047, 15.6],
  [-21.720079, 166.180229, 16],
  [-21.720129, 166.180412, 16.6],
  [-21.720164, 166.180573, 17.2],
  [-21.720177, 166.180755, 17.8],
  [-21.720162, 166.180948, 17.8],
  [-21.720106, 166.181109, 17.4],
  [-21.720023, 166.181281, 17],
  [-21.71992, 166.181431, 17],
  [-21.719799, 166.181549, 17.6],
  [-21.719668, 166.181678, 18.4],
  [-21.719536, 166.181785, 19],
  [-21.71941, 166.181903, 19.2],
  [-21.719288, 166.18201, 19],
  [-21.719161, 166.182128, 18.2],
  [-21.71904, 166.182268, 17.6],
  [-21.718953, 166.182418, 17.4],
  [-21.718898, 166.182611, 17.8],
  [-21.718876, 166.182793, 18.4],
  [-21.718891, 166.182976, 18.6],
  [-21.718935, 166.183137, 18.4],
  [-21.718989, 166.183319, 17.6],
  [-21.719053, 166.183491, 16.8],
  [-21.719138, 166.183662, 16],
  [-21.719251, 166.183813, 15.4],
  [-21.719403, 166.18392, 15],
  [-21.719582, 166.183952, 15.2],
  [-21.719761, 166.183931, 15.8],
  [-21.719937, 166.18392, 16.2],
  [-21.720109, 166.183888, 16.8],
  [-21.720278, 166.183856, 17.2],
  [-21.720448, 166.183845, 17.6],
  [-21.720618, 166.183856, 17.4],
  [-21.720787, 166.183877, 17.2],
  [-21.720959, 166.183898, 16.4],
  [-21.721131, 166.183952, 15.8],
  [-21.721298, 166.184006, 15],
  [-21.721451, 166.184113, 14.8],
  [-21.721572, 166.184253, 15.2],
  [-21.72166, 166.184424, 16],
  [-21.721728, 166.184585, 17],
  [-21.72178, 166.184757, 17.8],
  [-21.72182, 166.184928, 18.2],
  [-21.72184, 166.185111, 18.2],
  [-21.721835, 166.185293, 17.6],
  [-21.721807, 166.185454, 17],
  [-21.72176, 166.185647, 16.4],
  [-21.721699, 166.18583, 15.8],
  [-21.721619, 166.185991, 15],
  [-21.721524, 166.186162, 14.4],
  [-21.721424, 166.186312, 14.2],
  [-21.721328, 166.186484, 14.4],
  [-21.721234, 166.186645, 15],
  [-21.721117, 166.186785, 15.4],
  [-21.720974, 166.186892, 15.2],
  [-21.720814, 166.186967, 14.4],
  [-21.720641, 166.187021, 13.8],
  [-21.720463, 166.187042, 13.6],
  [-21.720285, 166.187063, 14.2],
  [-21.720109, 166.187063, 14.8],
  [-21.719937, 166.187031, 15.2],
  [-21.71977, 166.186988, 15.2],
  [-21.719609, 166.186924, 14.6],
  [-21.719449, 166.186827, 14],
  [-21.719291, 166.186752, 13.6],
  [-21.719134, 166.186666, 13.8],
  [-21.71899, 166.186538, 14.4],
  [-21.718852, 166.186452, 15.2],
  [-21.718697, 166.186377, 15.6],
  [-21.718532, 166.186345, 15.4],
  [-21.71836, 166.186334, 14.6],
  [-21.718186, 166.186355, 14],
  [-21.718018, 166.186409, 13.6],
  [-21.717869, 166.186516, 13.8],
  [-21.717746, 166.186645, 14.6],
  [-21.717639, 166.186785, 15.4],
  [-21.717539, 166.186935, 16],
  [-21.717445, 166.187074, 16.2],
  [-21.717358, 166.187224, 15.6],
  [-21.717282, 166.187396, 14.8],
  [-21.717213, 166.187568, 14.2],
  [-21.717145, 166.18775, 14.2],
  [-21.71707, 166.187911, 14.8],
  [-21.716997, 166.188083, 15.4],
  [-21.716948, 166.188254, 16],
  [-21.716925, 166.188437, 16.4],
  [-21.71692, 166.188619, 16.4],
  [-21.716921, 166.188802, 16.4],
  [-21.71693, 166.188973, 16.2],
  [-21.716956, 166.189156, 16],
  [-21.716996, 166.189338, 15.8],
  [-21.717059, 166.189499, 15.6],
  [-21.717154, 166.18966, 15.2],
  [-21.717272, 166.189789, 14.6],
  [-21.717407, 166.189928, 14.2],
  [-21.717547, 166.190035, 13.6],
  [-21.717694, 166.190164, 13.2],
  [-21.717845, 166.190261, 13],
  [-21.718003, 166.190357, 13.4],
  [-21.718171, 166.190422, 14.2],
  [-21.718337, 166.190464, 15],
  [-21.718487, 166.190561, 15.2],
  [-21.718618, 166.190679, 14.8],
  [-21.718732, 166.190819, 14],
  [-21.718841, 166.190969, 13.2],
  [-21.718958, 166.191108, 12.8],
  [-21.719074, 166.191258, 13],
  [-21.719146, 166.19143, 13],
  [-21.719175, 166.191612, 13],
  [-21.719197, 166.191806, 13],
];

export default stream;
