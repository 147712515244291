import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.665029, 166.950334, 77],
  [-11.66502, 166.950302, 77],
  [-11.66501, 166.950281, 77.2],
  [-11.665001, 166.950248, 77.4],
  [-11.664989, 166.950216, 77.4],
  [-11.664975, 166.950184, 77.6],
  [-11.664961, 166.950152, 77.6],
  [-11.664946, 166.95012, 77.8],
  [-11.664931, 166.950088, 78],
  [-11.664915, 166.950066, 78],
  [-11.6649, 166.950034, 78.2],
  [-11.664884, 166.950012, 78.2],
  [-11.664869, 166.949991, 78.4],
  [-11.664853, 166.949959, 78.4],
  [-11.664838, 166.949937, 78.6],
  [-11.664822, 166.949905, 78.6],
  [-11.664809, 166.949884, 78.8],
  [-11.664794, 166.949852, 78.8],
  [-11.664779, 166.949819, 78.8],
  [-11.664766, 166.949798, 79],
  [-11.664753, 166.949776, 79],
  [-11.664742, 166.949744, 79.2],
  [-11.66473, 166.949712, 79.2],
  [-11.664719, 166.94968, 79.4],
  [-11.664708, 166.949648, 79.4],
  [-11.6647, 166.949615, 79.6],
  [-11.664691, 166.949594, 79.6],
  [-11.664684, 166.949573, 79.8],
  [-11.664678, 166.94953, 79.8],
  [-11.664672, 166.949497, 79.8],
  [-11.66467, 166.949465, 80],
  [-11.664667, 166.949433, 80],
  [-11.664664, 166.949401, 80.2],
  [-11.664664, 166.949379, 80.2],
  [-11.664664, 166.949347, 80.2],
  [-11.664665, 166.949315, 80.4],
  [-11.664667, 166.949272, 80.4],
  [-11.66467, 166.94924, 80.4],
  [-11.664672, 166.949208, 80.6],
  [-11.664675, 166.949165, 80.6],
  [-11.664679, 166.949133, 80.8],
  [-11.664682, 166.9491, 80.8],
  [-11.664686, 166.949068, 80.8],
  [-11.66469, 166.949025, 81],
  [-11.664694, 166.948993, 81],
  [-11.664696, 166.94895, 81.2],
  [-11.6647, 166.948918, 81.2],
  [-11.664704, 166.948886, 81.2],
  [-11.664707, 166.948843, 81.4],
  [-11.66471, 166.948811, 81.4],
  [-11.66471, 166.948768, 81.6],
  [-11.664711, 166.948736, 81.6],
  [-11.664711, 166.948704, 81.8],
  [-11.66471, 166.948661, 81.8],
  [-11.664707, 166.948628, 81.8],
  [-11.664704, 166.948586, 82],
  [-11.6647, 166.948553, 82.2],
  [-11.664694, 166.948521, 82.2],
  [-11.664687, 166.948489, 82.4],
  [-11.664679, 166.948446, 82.4],
  [-11.664668, 166.948414, 82.6],
  [-11.664657, 166.948382, 82.6],
  [-11.664645, 166.948349, 82.8],
  [-11.664632, 166.948317, 82.8],
  [-11.664619, 166.948296, 83],
  [-11.664604, 166.948264, 83],
  [-11.66459, 166.948231, 83.2],
  [-11.664576, 166.948221, 83.2],
  [-11.664561, 166.948189, 83.4],
  [-11.664545, 166.948156, 83.4],
  [-11.66453, 166.948124, 83.6],
  [-11.664515, 166.948113, 83.8],
  [-11.664501, 166.948081, 83.8],
  [-11.664484, 166.948049, 84],
  [-11.66447, 166.948028, 84],
  [-11.664455, 166.948006, 84.2],
  [-11.66444, 166.947974, 84.2],
  [-11.664425, 166.947953, 84.4],
  [-11.664409, 166.947931, 84.4],
  [-11.664396, 166.947899, 84.6],
  [-11.664381, 166.947877, 84.6],
  [-11.664368, 166.947845, 84.8],
  [-11.664354, 166.947824, 84.8],
  [-11.664342, 166.947802, 85],
  [-11.66433, 166.94777, 85],
  [-11.664318, 166.947738, 85],
  [-11.664307, 166.947716, 85.2],
  [-11.664298, 166.947695, 85.2],
  [-11.664287, 166.947663, 85.4],
  [-11.664279, 166.947631, 85.4],
  [-11.664271, 166.947609, 85.6],
  [-11.664265, 166.947577, 85.6],
  [-11.664258, 166.947545, 85.8],
  [-11.664252, 166.947534, 85.8],
  [-11.664248, 166.947502, 86],
  [-11.664246, 166.94747, 86],
  [-11.664243, 166.947438, 86.2],
  [-11.66424, 166.947405, 86.2],
  [-11.664238, 166.947373, 86.2],
  [-11.664236, 166.947341, 86.4],
  [-11.664235, 166.94732, 86.4],
  [-11.664234, 166.947287, 86.6],
  [-11.664234, 166.947244, 86.6],
  [-11.664232, 166.947212, 86.6],
  [-11.664232, 166.94718, 86.8],
  [-11.664234, 166.947148, 86.8],
  [-11.664235, 166.947116, 87],
  [-11.664236, 166.947083, 87],
  [-11.664239, 166.947041, 87],
  [-11.664242, 166.947008, 87.2],
  [-11.664246, 166.946976, 87.2],
  [-11.66425, 166.946944, 87.2],
  [-11.664255, 166.946901, 87.4],
  [-11.66426, 166.946869, 87.4],
  [-11.664267, 166.946847, 87.6],
  [-11.664274, 166.946815, 87.6],
  [-11.664282, 166.946762, 87.6],
  [-11.664291, 166.94674, 87.8],
  [-11.664299, 166.946708, 87.8],
  [-11.66431, 166.946654, 88],
  [-11.664321, 166.946633, 88],
  [-11.66433, 166.946601, 88.2],
  [-11.664344, 166.946568, 88.2],
  [-11.664356, 166.946536, 88.4],
  [-11.664368, 166.946493, 88.4],
  [-11.664383, 166.946461, 88.6],
  [-11.664396, 166.946429, 88.6],
  [-11.664409, 166.946397, 88.8],
  [-11.664425, 166.946365, 88.8],
  [-11.66444, 166.946343, 88.8],
  [-11.664456, 166.946311, 89],
  [-11.664472, 166.946279, 89.2],
  [-11.664488, 166.946247, 89.2],
  [-11.664505, 166.946214, 89.4],
  [-11.664521, 166.946182, 89.4],
  [-11.664538, 166.946161, 89.6],
  [-11.664556, 166.946139, 89.6],
  [-11.664572, 166.946107, 89.8],
  [-11.664589, 166.946075, 89.8],
  [-11.664606, 166.946054, 89.8],
  [-11.664624, 166.946021, 90],
  [-11.664641, 166.946, 90],
  [-11.664659, 166.945968, 90.2],
  [-11.664678, 166.945946, 90.2],
  [-11.664694, 166.945914, 90.4],
  [-11.664712, 166.945893, 90.4],
  [-11.66473, 166.945871, 90.6],
  [-11.664749, 166.945839, 90.6],
  [-11.664766, 166.945817, 90.8],
  [-11.664785, 166.945785, 90.8],
  [-11.664802, 166.945764, 91],
  [-11.664821, 166.945742, 91],
  [-11.66484, 166.94571, 91.2],
  [-11.664859, 166.945699, 91.2],
  [-11.664877, 166.945667, 91.4],
  [-11.664895, 166.945635, 91.4],
  [-11.664915, 166.945624, 91.6],
  [-11.664934, 166.945592, 91.6],
  [-11.664952, 166.945571, 91.8],
  [-11.664971, 166.945549, 91.8],
  [-11.66499, 166.945528, 92],
  [-11.66501, 166.945496, 92],
  [-11.665029, 166.945485, 92.2],
  [-11.665048, 166.945453, 92.2],
  [-11.665068, 166.945421, 92.4],
  [-11.665087, 166.94541, 92.4],
  [-11.665107, 166.945378, 92.6],
  [-11.665127, 166.945367, 92.6],
  [-11.665146, 166.945335, 92.6],
  [-11.665167, 166.945313, 92.8],
  [-11.665186, 166.945281, 92.8],
  [-11.665206, 166.94527, 93],
  [-11.665226, 166.945238, 93],
  [-11.665248, 166.945227, 93.2],
  [-11.665268, 166.945195, 93.2],
  [-11.665288, 166.945184, 93.4],
  [-11.665309, 166.945163, 93.4],
  [-11.665331, 166.945131, 93.6],
  [-11.665351, 166.94512, 93.6],
  [-11.665374, 166.945088, 93.8],
  [-11.665394, 166.945077, 93.8],
  [-11.665415, 166.945045, 94],
  [-11.665437, 166.945024, 94],
  [-11.665459, 166.945013, 94.2],
  [-11.665481, 166.944981, 94.2],
  [-11.665502, 166.94497, 94.2],
  [-11.665525, 166.944938, 94.4],
  [-11.665548, 166.944916, 94.4],
  [-11.665572, 166.944906, 94.6],
  [-11.665595, 166.944873, 94.6],
  [-11.665618, 166.944863, 94.8],
  [-11.665642, 166.944841, 94.8],
  [-11.665667, 166.94483, 95],
  [-11.665691, 166.944798, 95],
  [-11.665717, 166.944788, 95.2],
  [-11.665744, 166.944766, 95.2],
  [-11.665771, 166.944755, 95.4],
  [-11.665799, 166.944734, 95.4],
  [-11.665826, 166.944723, 95.6],
  [-11.665852, 166.944702, 95.6],
  [-11.665882, 166.944702, 95.6],
  [-11.665909, 166.944691, 95.8],
  [-11.665938, 166.94468, 95.8],
  [-11.665966, 166.944659, 96],
  [-11.665996, 166.944659, 96],
  [-11.666025, 166.944648, 96.2],
  [-11.666054, 166.944648, 96.2],
  [-11.666084, 166.944627, 96.4],
  [-11.666114, 166.944627, 96.4],
  [-11.666143, 166.944616, 96.6],
  [-11.666174, 166.944594, 96.6],
  [-11.666204, 166.944594, 96.8],
  [-11.666235, 166.944584, 96.8],
  [-11.666265, 166.944584, 96.8],
  [-11.666295, 166.944573, 97],
  [-11.666326, 166.944573, 97],
  [-11.666358, 166.944551, 97.2],
  [-11.666387, 166.944551, 97.2],
  [-11.66642, 166.944541, 97.2],
  [-11.666451, 166.944519, 97.4],
  [-11.666483, 166.944519, 97.4],
  [-11.666512, 166.944509, 97.6],
  [-11.666544, 166.944498, 97.6],
  [-11.666575, 166.944476, 97.6],
  [-11.666606, 166.944466, 97.8],
  [-11.666638, 166.944444, 97.8],
  [-11.666668, 166.944412, 97.8],
  [-11.666697, 166.944401, 97.8],
  [-11.666728, 166.944391, 98],
  [-11.666756, 166.944358, 98],
  [-11.666787, 166.944326, 98],
  [-11.666814, 166.944294, 98],
  [-11.666843, 166.944262, 98.2],
  [-11.666872, 166.94423, 98.2],
  [-11.666898, 166.944197, 98.2],
  [-11.666927, 166.944176, 98.2],
  [-11.666955, 166.944144, 98.2],
  [-11.666982, 166.944112, 98.2],
  [-11.667011, 166.944069, 98.2],
  [-11.667038, 166.944036, 98.2],
  [-11.667066, 166.943994, 98.2],
];
export default stream;
