import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [51.507865, -0.127183, 24.2],
  [51.507699, -0.127299, 24.4],
  [51.507602, -0.127577, 24.4],
  [51.507527, -0.127869, 24.6],
  [51.50735, -0.128018, 24.8],
  [51.507157, -0.128062, 24.8],
];

export default stream;
