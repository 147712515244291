import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [51.500961, -0.126052, 16],
  [51.500849, -0.126059, 15.8],
  [51.500736, -0.126067, 15.6],
  [51.500618, -0.126074, 15.2],
  [51.500505, -0.126082, 15],
  [51.500387, -0.12609, 14.8],
  [51.500275, -0.126097, 14.8],
  [51.500151, -0.126104, 14.8],
  [51.500039, -0.12611, 14.8],
  [51.499931, -0.126116, 14.8],
  [51.499813, -0.126121, 15],
  [51.499706, -0.126126, 15.2],
  [51.499599, -0.126129, 15.4],
  [51.499491, -0.126123, 15.4],
  [51.499389, -0.126103, 15.4],
  [51.499282, -0.126072, 15.4],
  [51.499186, -0.126035, 15.2],
  [51.499078, -0.125995, 15.2],
  [51.498976, -0.125957, 15],
  [51.498874, -0.125915, 14.8],
  [51.498767, -0.125868, 14.6],
  [51.498665, -0.125822, 14.4],
  [51.498558, -0.125779, 14.2],
  [51.498451, -0.125745, 14.2],
  [51.498343, -0.125721, 14],
  [51.498236, -0.125707, 14],
  [51.498129, -0.125698, 14.2],
  [51.498021, -0.125692, 14.2],
  [51.497914, -0.125688, 14.2],
  [51.497812, -0.125684, 14.4],
  [51.497705, -0.12568, 14.4],
  [51.497603, -0.125677, 14.2],
  [51.497496, -0.125676, 14],
  [51.497394, -0.125663, 14],
  [51.497287, -0.125643, 14.2],
  [51.497185, -0.125622, 14.2],
  [51.497083, -0.125601, 14.4],
  [51.496986, -0.12558, 14.6],
  [51.496884, -0.125559, 14.6],
  [51.496788, -0.125538, 14.8],
  [51.496686, -0.125517, 15],
  [51.496589, -0.125496, 15],
  [51.496498, -0.125475, 15.2],
  [51.496401, -0.125455, 15.4],
  [51.49631, -0.125434, 15.4],
  [51.496214, -0.125414, 15.6],
  [51.496122, -0.125393, 15.6],
  [51.496031, -0.125372, 15.6],
  [51.495935, -0.125349, 15.6],
  [51.495838, -0.125325, 15.6],
  [51.495747, -0.125301, 15.6],
  [51.49565, -0.125277, 15.6],
  [51.495559, -0.125253, 15.6],
  [51.495463, -0.125229, 15.4],
  [51.495361, -0.12521, 15.4],
  [51.49527, -0.125203, 15.4],
  [51.495168, -0.125202, 15.4],
  [51.495071, -0.125201, 15.4],
  [51.494974, -0.125188, 15.4],
  [51.494873, -0.125163, 15.4],
];

export default stream;
