import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [51.50772, -0.126971, 24.2],
  [51.507635, -0.127241, 24.4],
  [51.507597, -0.127548, 24.6],
  [51.507533, -0.127835, 24.6],
  [51.507404, -0.128066, 24.6],
  [51.507334, -0.128341, 24.4],
];

export default stream;
