import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [55.642812, -5.222238, 27.8],
  [55.642705, -5.222221, 27.8],
  [55.642592, -5.222203, 27.8],
  [55.642485, -5.222184, 27.8],
  [55.642372, -5.222184, 27.8],
  [55.642265, -5.22223, 27.6],
  [55.642163, -5.222289, 27.8],
  [55.642061, -5.222355, 28],
  [55.641959, -5.222423, 28],
  [55.641857, -5.22248, 28],
  [55.641755, -5.222527, 28.2],
  [55.641659, -5.222569, 28.4],
  [55.641552, -5.222607, 28.8],
  [55.641455, -5.222641, 29],
  [55.641353, -5.222673, 29.2],
  [55.641257, -5.222703, 29.4],
  [55.64116, -5.222731, 29.8],
  [55.641063, -5.222757, 30.2],
  [55.640972, -5.222782, 30.4],
  [55.640881, -5.222805, 30.8],
  [55.64079, -5.222826, 31.2],
  [55.640704, -5.222846, 31.4],
  [55.640613, -5.222866, 31.8],
  [55.640527, -5.222884, 32.2],
  [55.640441, -5.222902, 32.4],
  [55.640361, -5.22292, 32.8],
  [55.640275, -5.222938, 33.2],
  [55.640194, -5.222956, 33.4],
  [55.640114, -5.222973, 33.8],
  [55.640033, -5.222991, 34],
  [55.639953, -5.223008, 34.2],
  [55.639873, -5.223027, 34.6],
  [55.639797, -5.223046, 34.8],
  [55.639717, -5.223065, 35],
  [55.639637, -5.223084, 35.2],
  [55.639556, -5.223102, 35.6],
  [55.639476, -5.223117, 35.6],
  [55.6394, -5.223128, 35.8],
  [55.63932, -5.223137, 35.8],
  [55.639234, -5.223144, 35.8],
  [55.639148, -5.223147, 35.8],
  [55.639057, -5.223145, 35.8],
  [55.638966, -5.223138, 35.8],
  [55.63888, -5.223134, 35.8],
  [55.638789, -5.223135, 35.8],
  [55.638692, -5.22314, 35.8],
  [55.638596, -5.223145, 35.8],
  [55.638499, -5.22315, 35.8],
];

export default stream;
