import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [51.507323, -0.126864, 23.4],
  [51.507329, -0.126907, 23.6],
  [51.507339, -0.126956, 23.8],
  [51.50735, -0.127011, 24],
  [51.50735, -0.127073, 24.2],
  [51.507339, -0.127133, 24.4],
  [51.507323, -0.127186, 24.6],
  [51.507297, -0.127237, 24.8],
  [51.507264, -0.127287, 25],
  [51.507232, -0.127337, 25.2],
  [51.507195, -0.127395, 25.4],
  [51.507173, -0.127461, 25.6],
  [51.507152, -0.127538, 25.6],
  [51.507141, -0.127628, 25.6],
  [51.507141, -0.127718, 25.4],
  [51.507146, -0.127811, 25.4],
  [51.507168, -0.127905, 25.2],
  [51.507195, -0.127999, 24.8],
  [51.507227, -0.128094, 24.6],
  [51.507264, -0.128192, 24.4],
  [51.507297, -0.128292, 24.4],
];

export default stream;
