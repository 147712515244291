import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [48.867515, 2.314494, 48.2],
  [48.867445, 2.314708, 48.2],
  [48.867375, 2.314921, 48.2],
  [48.867311, 2.315135, 48],
  [48.867241, 2.315349, 48],
  [48.867171, 2.315563, 48],
  [48.867107, 2.315777, 48],
  [48.867037, 2.315991, 48],
  [48.866973, 2.316206, 47.8],
  [48.866903, 2.316421, 47.8],
  [48.866833, 2.316636, 47.6],
  [48.866764, 2.316852, 47.4],
  [48.866694, 2.317068, 47.4],
  [48.86663, 2.317281, 47.2],
  [48.86656, 2.317497, 47.2],
  [48.866485, 2.317714, 47.2],
  [48.86642, 2.317929, 47.4],
  [48.866351, 2.318143, 47.6],
  [48.866281, 2.318356, 47.6],
  [48.866211, 2.318569, 47.4],
  [48.866147, 2.318783, 47.4],
  [48.866077, 2.318999, 47.2],
  [48.866007, 2.319215, 47],
  [48.865943, 2.319432, 46.8],
  [48.865879, 2.31965, 46.8],
  [48.865809, 2.319868, 46.6],
  [48.865739, 2.320087, 46.4],
  [48.865664, 2.320305, 46.2],
  [48.865573, 2.320499, 45.8],
  [48.865428, 2.320609, 45.6],
  [48.865272, 2.32062, 45.8],
  [48.865111, 2.320601, 46],
  [48.864956, 2.320569, 46.4],
  [48.8648, 2.320525, 46.6],
  [48.86465, 2.320479, 47],
  [48.8645, 2.320441, 47],
  [48.864344, 2.320406, 47],
  [48.864194, 2.320366, 47.2],
  [48.864044, 2.320361, 47.4],
  [48.863921, 2.320489, 47.4],
  [48.863835, 2.320684, 47.4],
  [48.863765, 2.320889, 47.6],
  [48.863701, 2.321096, 47.6],
  [48.863631, 2.321304, 47.8],
  [48.863567, 2.321513, 47.6],
  [48.863502, 2.321727, 47.6],
  [48.863438, 2.321939, 47.6],
  [48.863368, 2.322149, 47.6],
  [48.863304, 2.322359, 47.6],
  [48.863234, 2.322569, 47.6],
  [48.863164, 2.322779, 47.8],
  [48.8631, 2.322989, 47.8],
  [48.86303, 2.3232, 47.8],
  [48.86296, 2.323411, 47.8],
  [48.862896, 2.323622, 47.8],
  [48.862826, 2.323832, 47.8],
  [48.862762, 2.324039, 48],
  [48.862697, 2.32425, 48],
  [48.862628, 2.324459, 48],
  [48.862553, 2.324668, 48.2],
  [48.862483, 2.324876, 48.2],
  [48.862419, 2.325084, 48.2],
  [48.862349, 2.325294, 48.2],
  [48.862284, 2.325505, 48.2],
  [48.862215, 2.325716, 48.2],
  [48.86215, 2.325926, 48.2],
  [48.862081, 2.326137, 48.4],
  [48.862016, 2.326348, 48.4],
  [48.861946, 2.32656, 48.4],
  [48.861882, 2.326771, 48.4],
  [48.861812, 2.326983, 48.4],
  [48.861743, 2.327194, 48.4],
  [48.861678, 2.327405, 48.4],
  [48.861614, 2.327617, 48.4],
  [48.861544, 2.327828, 48.4],
  [48.86148, 2.32804, 48.4],
  [48.86141, 2.328252, 48.4],
  [48.861346, 2.32846, 48.4],
  [48.861276, 2.328672, 48.4],
  [48.861212, 2.328885, 48.2],
  [48.861142, 2.3291, 48],
  [48.861072, 2.329315, 47.8],
  [48.861008, 2.329531, 47.8],
  [48.860938, 2.329747, 47.8],
  [48.860895, 2.329974, 47.6],
  [48.860986, 2.330157, 48],
  [48.861126, 2.330272, 48.2],
  [48.86126, 2.330376, 48.4],
  [48.861399, 2.330474, 47.6],
  [48.861544, 2.330575, 46.6],
  [48.861694, 2.330677, 45.2],
  [48.86185, 2.330783, 43.8],
  [48.862011, 2.330891, 42.4],
  [48.862172, 2.331002, 41.6],
  [48.862327, 2.331118, 41.4],
  [48.862483, 2.331238, 41.4],
  [48.862644, 2.331357, 41.8],
  [48.862789, 2.331473, 42.2],
  [48.862939, 2.331587, 42.8],
  [48.863084, 2.331699, 43.2],
  [48.863223, 2.331807, 44],
  [48.863357, 2.331914, 45],
  [48.863502, 2.331992, 46],
  [48.863647, 2.331991, 46.2],
  [48.863776, 2.331894, 46.2],
  [48.863872, 2.331721, 46.2],
  [48.863937, 2.331519, 46.4],
  [48.864001, 2.331314, 46.4],
  [48.86406, 2.331109, 46.6],
  [48.864119, 2.330903, 46.6],
  [48.864189, 2.330697, 46.8],
  [48.864248, 2.330491, 47],
  [48.864312, 2.330286, 47],
  [48.864377, 2.330081, 47],
  [48.864441, 2.329875, 47],
  [48.864511, 2.32967, 47.2],
  [48.864575, 2.329464, 47.2],
  [48.864645, 2.329257, 47.2],
  [48.864709, 2.32905, 47.2],
  [48.864779, 2.328843, 47.2],
  [48.864843, 2.328636, 47.4],
  [48.864913, 2.328432, 47.4],
  [48.864977, 2.328223, 47.4],
  [48.865047, 2.328015, 47.4],
  [48.865111, 2.327806, 47.4],
  [48.865176, 2.327596, 47.4],
  [48.865246, 2.327385, 47.4],
  [48.86531, 2.327175, 47.4],
  [48.865374, 2.326963, 47.4],
  [48.865444, 2.326751, 47.4],
  [48.865508, 2.326539, 47.2],
  [48.865578, 2.326327, 47.2],
  [48.865643, 2.326114, 47.2],
  [48.865712, 2.325901, 47.2],
  [48.865777, 2.325689, 47.2],
  [48.865846, 2.325476, 47.2],
  [48.865916, 2.325264, 47.4],
  [48.865986, 2.325052, 47.4],
  [48.86605, 2.32484, 47.4],
  [48.866115, 2.324628, 47.4],
  [48.866184, 2.324417, 47.4],
  [48.866254, 2.324204, 47.4],
  [48.866313, 2.323989, 47.4],
  [48.866367, 2.32377, 47.4],
  [48.866372, 2.323535, 47.4],
  [48.866372, 2.323299, 47.4],
  [48.866404, 2.323068, 47.2],
  [48.866436, 2.322835, 47],
  [48.866458, 2.3226, 46.8],
  [48.866485, 2.322363, 46.8],
  [48.866495, 2.322124, 46.6],
  [48.866436, 2.321899, 46.4],
  [48.866335, 2.321719, 46.2],
  [48.866217, 2.321553, 46.2],
  [48.866099, 2.321395, 46],
  [48.86597, 2.321241, 45.8],
  [48.865862, 2.321068, 45.6],
  [48.865777, 2.320862, 45.6],
  [48.865755, 2.320625, 45.8],
  [48.865798, 2.320396, 46.2],
  [48.865862, 2.320179, 46.4],
  [48.865932, 2.319967, 46.6],
  [48.866002, 2.319756, 46.8],
  [48.866066, 2.319546, 46.8],
  [48.866131, 2.31934, 47],
  [48.8662, 2.319131, 47.2],
  [48.866265, 2.318922, 47.4],
  [48.866335, 2.318714, 47.4],
  [48.866399, 2.318506, 47.6],
  [48.866463, 2.318298, 47.6],
  [48.866533, 2.31809, 47.4],
  [48.866597, 2.317881, 47.2],
  [48.866667, 2.31767, 47.2],
  [48.866737, 2.317461, 47.2],
  [48.866796, 2.317275, 47.2],
  [48.866844, 2.317121, 47.4],
  [48.866887, 2.316997, 47.4],
  [48.866919, 2.316902, 47.4],
  [48.866946, 2.316835, 47.6],
  [48.866962, 2.316795, 47.6],
  [48.866968, 2.31678, 47.6],
  [48.866968, 2.316776, 47.6],
  [48.866973, 2.31676, 47.6],
  [48.866989, 2.316735, 47.6],
  [48.867, 2.316705, 47.6],
  [48.86701, 2.316669, 47.6],
  [48.867021, 2.31663, 47.6],
  [48.867037, 2.316587, 47.6],
  [48.867048, 2.316541, 47.8],
  [48.867064, 2.316492, 47.8],
  [48.86708, 2.31644, 47.8],
  [48.867096, 2.316387, 47.8],
  [48.867112, 2.316332, 47.8],
  [48.867182, 2.316128, 48],
  [48.867188, 2.316109, 48],
  [48.867198, 2.316091, 48],
  [48.867209, 2.316055, 48],
  [48.86722, 2.316006, 48],
  [48.867236, 2.315949, 48],
  [48.867257, 2.315883, 48],
  [48.867279, 2.315811, 48],
  [48.8673, 2.315734, 48],
  [48.867327, 2.315653, 48],
  [48.867354, 2.315567, 48],
  [48.867381, 2.315477, 48],
  [48.867407, 2.315384, 48],
  [48.867434, 2.315287, 48],
  [48.867472, 2.315187, 48.2],
  [48.867504, 2.315085, 48.2],
  [48.867536, 2.31498, 48.2],
  [48.867568, 2.314872, 48.2],
  [48.867601, 2.314762, 48.2],
  [48.867638, 2.314651, 48.2],
  [48.867676, 2.314538, 48.2],
  [48.867713, 2.314424, 48.2],
  [48.867745, 2.314308, 48.2],
  [48.867783, 2.31419, 48.2],
  [48.867821, 2.314071, 48.2],
  [48.867858, 2.313951, 48.2],
  [48.867901, 2.313829, 48.2],
  [48.867939, 2.313709, 48.2],
  [48.867976, 2.313585, 48],
  [48.868019, 2.313459, 48],
  [48.868062, 2.313332, 48],
  [48.868099, 2.313204, 47.8],
  [48.868142, 2.313073, 47.8],
  [48.868185, 2.312942, 47.6],
  [48.868228, 2.312808, 47.6],
  [48.868266, 2.312674, 47.4],
  [48.868314, 2.312538, 47.4],
  [48.868357, 2.312402, 47.2],
  [48.8684, 2.312264, 47.2],
  [48.868443, 2.312126, 47.2],
  [48.868486, 2.311987, 47],
  [48.868529, 2.311847, 47],
  [48.868572, 2.311708, 47.2],
  [48.868609, 2.31157, 47.2],
  [48.868657, 2.311434, 47.4],
  [48.8687, 2.311299, 47.4],
  [48.868743, 2.311167, 47.6],
  [48.868786, 2.311033, 47.6],
  [48.868834, 2.310893, 47.8],
  [48.868883, 2.310748, 47.8],
  [48.868931, 2.310593, 48],
  [48.868985, 2.310432, 48],
  [48.869033, 2.310265, 48.2],
  [48.869087, 2.310093, 48.4],
  [48.86914, 2.309918, 48.6],
  [48.869194, 2.30974, 48.8],
  [48.869253, 2.30956, 49],
  [48.869312, 2.309378, 49.2],
  [48.869365, 2.309195, 49.6],
  [48.869424, 2.309011, 49.8],
  [48.869483, 2.308826, 50.2],
  [48.869542, 2.308641, 50.6],
  [48.869601, 2.308456, 51.2],
  [48.86966, 2.308273, 51.8],
  [48.86972, 2.30809, 52.2],
  [48.869779, 2.307907, 52.6],
  [48.869838, 2.307724, 53],
  [48.869891, 2.307541, 53.4],
  [48.869956, 2.307357, 53.6],
  [48.870009, 2.307171, 53.8],
  [48.870074, 2.306987, 54.2],
  [48.870133, 2.3068, 54.4],
  [48.870192, 2.306611, 54.8],
  [48.870251, 2.306423, 55.2],
  [48.870315, 2.306235, 55.6],
  [48.870369, 2.306047, 56],
  [48.870433, 2.30586, 56.6],
  [48.870492, 2.305673, 57],
  [48.870551, 2.305487, 57.4],
  [48.87061, 2.3053, 57.8],
  [48.870669, 2.305114, 58.2],
  [48.870723, 2.304927, 58.6],
  [48.870787, 2.304741, 59.2],
  [48.870841, 2.304555, 59.6],
  [48.8709, 2.304368, 60],
  [48.870964, 2.304182, 60.2],
  [48.871018, 2.303997, 60.6],
  [48.871077, 2.303808, 60.8],
  [48.871136, 2.30362, 61.2],
  [48.8712, 2.303432, 61.4],
  [48.871259, 2.303243, 61.6],
  [48.871323, 2.303053, 61.8],
  [48.871382, 2.302864, 62],
  [48.871447, 2.302672, 62],
  [48.871511, 2.302478, 62.2],
  [48.87157, 2.302285, 62.4],
  [48.871635, 2.30209, 62.8],
  [48.871699, 2.301895, 63],
  [48.871763, 2.301701, 63.4],
  [48.871828, 2.301507, 63.8],
  [48.871887, 2.301315, 64.4],
  [48.871951, 2.301125, 65],
  [48.87201, 2.300936, 65.6],
  [48.872069, 2.300749, 66],
  [48.872133, 2.300562, 66.6],
  [48.872193, 2.300375, 67],
  [48.872252, 2.30019, 67.4],
  [48.872311, 2.300004, 68],
  [48.87237, 2.29982, 68.4],
  [48.872429, 2.299635, 68.8],
  [48.872488, 2.29945, 69],
  [48.872547, 2.299264, 69.4],
  [48.872611, 2.299077, 69.6],
  [48.87267, 2.29889, 69.6],
  [48.872734, 2.298703, 69.8],
  [48.872799, 2.298512, 69.8],
  [48.872863, 2.298319, 69.8],
  [48.872927, 2.298124, 70],
  [48.872992, 2.297929, 70.2],
  [48.873056, 2.297733, 70.4],
  [48.873121, 2.297537, 70.6],
  [48.873185, 2.29734, 70.8],
  [48.873249, 2.297142, 70.8],
  [48.873319, 2.296944, 70.8],
  [48.873383, 2.296745, 70.6],
  [48.873453, 2.296544, 70.6],
  [48.873523, 2.296341, 70.8],
  [48.873587, 2.296137, 71],
  [48.873678, 2.295959, 71],
  [48.873813, 2.295857, 71.2],
  [48.873957, 2.29579, 71.2],
  [48.874086, 2.295681, 71.4],
  [48.874199, 2.295529, 71.4],
  [48.874279, 2.295338, 71.4],
  [48.874328, 2.295119, 71.2],
  [48.874328, 2.294888, 71.2],
  [48.87429, 2.294669, 71],
  [48.874204, 2.29447, 71],
  [48.874092, 2.294319, 71],
  [48.873947, 2.294228, 71],
  [48.873791, 2.294207, 71],
  [48.873641, 2.294252, 71],
  [48.873507, 2.294364, 71],
  [48.873394, 2.294529, 71],
  [48.873324, 2.294734, 71],
  [48.873287, 2.294962, 71.2],
  [48.873298, 2.295195, 71.2],
  [48.873351, 2.295417, 71],
  [48.873416, 2.295629, 71],
  [48.873464, 2.295853, 71],
  [48.873442, 2.296085, 70.8],
  [48.873378, 2.2963, 70.8],
  [48.873298, 2.296511, 70.6],
  [48.873233, 2.296723, 70.6],
  [48.873158, 2.296936, 70.8],
  [48.873088, 2.297148, 70.8],
  [48.873019, 2.29736, 70.6],
  [48.872949, 2.297573, 70.6],
  [48.872874, 2.297783, 70.2],
  [48.872809, 2.297997, 70],
  [48.872734, 2.298213, 69.8],
  [48.872665, 2.298429, 69.8],
  [48.872589, 2.298645, 69.6],
  [48.87252, 2.298861, 69.6],
  [48.872445, 2.299079, 69.4],
  [48.872375, 2.299296, 69.2],
  [48.872305, 2.299515, 68.8],
  [48.87223, 2.299736, 68.4],
  [48.87216, 2.299959, 67.8],
  [48.872085, 2.300185, 67.2],
  [48.872015, 2.300412, 66.6],
  [48.87194, 2.300642, 66],
  [48.871865, 2.300873, 65.4],
  [48.87179, 2.301107, 64.8],
  [48.871715, 2.301342, 64],
  [48.87164, 2.301579, 63.4],
  [48.871565, 2.301816, 63],
  [48.87149, 2.302052, 62.6],
  [48.871409, 2.302289, 62.4],
  [48.87134, 2.30252, 62.2],
  [48.871264, 2.302754, 61.8],
  [48.871189, 2.302987, 61.6],
  [48.871114, 2.303218, 61.4],
  [48.871039, 2.303449, 61.2],
  [48.870964, 2.30368, 60.8],
  [48.870894, 2.303911, 60.6],
  [48.870819, 2.304142, 60],
  [48.870744, 2.304374, 59.6],
  [48.870669, 2.304608, 59.2],
  [48.870599, 2.304842, 58.6],
  [48.870524, 2.305077, 58],
  [48.870454, 2.305313, 57.6],
  [48.870379, 2.305549, 57],
  [48.870299, 2.305785, 56.4],
  [48.870224, 2.306021, 56],
  [48.870149, 2.306258, 55.4],
  [48.870074, 2.306495, 54.8],
  [48.869998, 2.306733, 54.4],
  [48.869918, 2.30697, 54],
  [48.869843, 2.307207, 53.8],
  [48.869773, 2.30744, 53.4],
  [48.869698, 2.307676, 53],
  [48.869623, 2.307912, 52.4],
  [48.869548, 2.308148, 51.8],
  [48.869467, 2.308385, 51.2],
  [48.869392, 2.308623, 50.4],
  [48.869312, 2.308862, 49.8],
  [48.869237, 2.3091, 49.6],
  [48.869162, 2.309338, 49.2],
  [48.869087, 2.309576, 48.8],
  [48.869006, 2.309812, 48.6],
  [48.868931, 2.310048, 48.4],
  [48.868861, 2.310282, 48.2],
  [48.868781, 2.310516, 48],
  [48.868711, 2.310749, 47.8],
  [48.868636, 2.310981, 47.6],
  [48.868561, 2.311208, 47.4],
  [48.868486, 2.311435, 47.2],
  [48.868411, 2.311663, 47.2],
  [48.868341, 2.311891, 47],
  [48.868271, 2.312118, 47.2],
  [48.868201, 2.312343, 47.2],
  [48.868132, 2.312562, 47.4],
  [48.868062, 2.312781, 47.6],
  [48.867992, 2.312999, 47.8],
  [48.867922, 2.313216, 48],
  [48.867858, 2.31343, 48],
  [48.867788, 2.313644, 48.2],
  [48.867719, 2.313856, 48.2],
  [48.867649, 2.314069, 48.2],
  [48.867579, 2.314281, 48.2],
];

export default stream;
