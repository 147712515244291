import { LatLngAlt } from "../../../types.js";

export const edgeSouthJunctionE1: LatLngAlt[] = [
  [40.768319, -73.979504, 23.6],
  [40.768284, -73.979251, 23],
  [40.768346, -73.978989, 21.4],
];
export const edgeSouthJunctionE2: LatLngAlt[] = [
  [40.767895, -73.978913, 22.2],
  [40.768051, -73.979021, 22.6],
  [40.768231, -73.979005, 22],
  [40.768389, -73.978892, 21],
];
export const edgeSouthJunctionE3: LatLngAlt[] = [
  [40.768335, -73.9796, 23.6],
  [40.768263, -73.979504, 23.6],
  [40.76819, -73.979396, 23.2],
  [40.768113, -73.979289, 23],
  [40.76804, -73.979182, 22.8],
  [40.767965, -73.979074, 22.6],
  [40.767885, -73.978967, 22.2],
];
