import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [40.770712, -73.97541, 41],
  [40.770529, -73.975459, 42],
  [40.770341, -73.975496, 44],
  [40.77017, -73.975534, 46.8],
  [40.770006, -73.975571, 49.6],
  [40.769845, -73.975555, 51.8],
  [40.7697, -73.97548, 53.2],
  [40.769572, -73.975373, 54.2],
  [40.769446, -73.975266, 55],
  [40.769314, -73.975148, 55.6],
  [40.769188, -73.975024, 56.4],
  [40.769059, -73.974906, 57.4],
  [40.768917, -73.974826, 58.6],
  [40.768764, -73.974804, 60.4],
  [40.768614, -73.97481, 62.8],
  [40.768472, -73.974804, 64.6],
  [40.768343, -73.974724, 65.8],
];

export default stream;
