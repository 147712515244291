import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.798448, 165.841005, 65],
  [-10.79832, 165.841005, 65],
  [-10.798195, 165.841026, 64.6],
  [-10.798067, 165.841037, 64.4],
  [-10.797936, 165.841058, 64],
  [-10.797805, 165.841069, 63.8],
  [-10.797674, 165.841101, 63.8],
  [-10.797546, 165.841112, 63.8],
  [-10.797418, 165.841144, 63.8],
  [-10.797289, 165.841134, 63.8],
  [-10.797198, 165.841037, 63.8],
  [-10.797159, 165.840919, 63.8],
  [-10.797141, 165.84079, 63.8],
  [-10.797124, 165.840672, 63.8],
  [-10.797089, 165.840533, 63.8],
  [-10.797012, 165.840447, 63.8],
  [-10.796898, 165.840383, 63.8],
  [-10.796778, 165.84034, 64],
  [-10.796655, 165.840307, 64.2],
  [-10.796533, 165.840286, 64.6],
  [-10.796418, 165.840265, 64.8],
  [-10.796304, 165.840211, 64.8],
  [-10.796188, 165.840168, 64.8],
  [-10.79607, 165.840157, 65],
  [-10.79595, 165.840157, 65.4],
  [-10.795832, 165.840168, 65.6],
  [-10.795716, 165.840157, 65.6],
];

export default stream;
