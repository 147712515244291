import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-21.719721, 166.19216, 13],
  [-21.719819, 166.19217, 13],
  [-21.719912, 166.192203, 13],
  [-21.720004, 166.192245, 12.8],
  [-21.720095, 166.192299, 12.8],
  [-21.720189, 166.192321, 12.6],
  [-21.720285, 166.192353, 12.6],
  [-21.720384, 166.192385, 12.6],
  [-21.720484, 166.192385, 12.6],
  [-21.720584, 166.192385, 12.8],
  [-21.72068, 166.192353, 13],
  [-21.720773, 166.192321, 13.2],
  [-21.720856, 166.192278, 13.4],
  [-21.720935, 166.192224, 13.6],
  [-21.721007, 166.19216, 13.8],
  [-21.721077, 166.192095, 14.2],
  [-21.721143, 166.192031, 14.4],
  [-21.721207, 166.191977, 14.6],
  [-21.72127, 166.191913, 15],
  [-21.721331, 166.191849, 15.2],
  [-21.721391, 166.191795, 15.4],
  [-21.72145, 166.19173, 15.8],
  [-21.721509, 166.191666, 16],
  [-21.721565, 166.191612, 16.2],
  [-21.721623, 166.191548, 16.6],
  [-21.721681, 166.191505, 16.8],
  [-21.721737, 166.191441, 17],
  [-21.721795, 166.191398, 17.2],
  [-21.721854, 166.191344, 17.6],
  [-21.721913, 166.191301, 17.8],
  [-21.721972, 166.191258, 18],
  [-21.722033, 166.191216, 18.2],
  [-21.722096, 166.191183, 18.6],
  [-21.722161, 166.191151, 18.8],
  [-21.722228, 166.191119, 19],
  [-21.722296, 166.191108, 19.2],
  [-21.722363, 166.191087, 19.6],
  [-21.722432, 166.191087, 19.8],
  [-21.7225, 166.191087, 20],
  [-21.722567, 166.191087, 20.2],
  [-21.722635, 166.191087, 20.4],
  [-21.722703, 166.191108, 20.6],
  [-21.72277, 166.191119, 20.8],
  [-21.722837, 166.19114, 21],
  [-21.722901, 166.191151, 21.2],
  [-21.722965, 166.191183, 21.4],
  [-21.723031, 166.191216, 21.6],
  [-21.723094, 166.191226, 21.8],
  [-21.723154, 166.191258, 22],
  [-21.723218, 166.191291, 22.2],
  [-21.723279, 166.191334, 22.4],
  [-21.72334, 166.191366, 22.6],
  [-21.723399, 166.191398, 22.8],
  [-21.723458, 166.191441, 23],
  [-21.723515, 166.191473, 23.4],
  [-21.723573, 166.191516, 23.6],
  [-21.723629, 166.191548, 23.8],
  [-21.723683, 166.191591, 24],
  [-21.723737, 166.191634, 24.2],
  [-21.72379, 166.191688, 24.4],
  [-21.72384, 166.19173, 24.8],
  [-21.723889, 166.191773, 25],
  [-21.723936, 166.191827, 25.2],
  [-21.723985, 166.19187, 25.4],
  [-21.72403, 166.191934, 25.6],
  [-21.724076, 166.191977, 25.8],
  [-21.72412, 166.19202, 26],
  [-21.724162, 166.192085, 26.2],
  [-21.724203, 166.192127, 26.6],
  [-21.724243, 166.192192, 26.8],
  [-21.724284, 166.192245, 27],
  [-21.72432, 166.19231, 27.2],
  [-21.724356, 166.192353, 27.4],
  [-21.724391, 166.192417, 27.6],
  [-21.724423, 166.192482, 28],
  [-21.724451, 166.192535, 28.2],
  [-21.724478, 166.1926, 28.4],
  [-21.724501, 166.192664, 28.8],
  [-21.72452, 166.192739, 29],
  [-21.724536, 166.192803, 29.2],
  [-21.724545, 166.192857, 29.6],
  [-21.724553, 166.192921, 29.8],
  [-21.724559, 166.192997, 30],
  [-21.724559, 166.193061, 30.2],
  [-21.724556, 166.193115, 30.6],
  [-21.724553, 166.1932, 30.8],
  [-21.724547, 166.193254, 31],
  [-21.724537, 166.193318, 31.2],
  [-21.724526, 166.193383, 31.6],
  [-21.724514, 166.193436, 31.8],
  [-21.7245, 166.193501, 32],
  [-21.724484, 166.193565, 32.2],
  [-21.724467, 166.19364, 32.6],
  [-21.724447, 166.193683, 32.8],
  [-21.724429, 166.193748, 33],
  [-21.724407, 166.193801, 33.4],
  [-21.724387, 166.193866, 33.6],
  [-21.724361, 166.19393, 34],
  [-21.724335, 166.193973, 34.2],
  [-21.724305, 166.194037, 34.4],
  [-21.724273, 166.19408, 34.8],
  [-21.724239, 166.194123, 35],
  [-21.724207, 166.194177, 35.4],
  [-21.724171, 166.19422, 35.6],
  [-21.724138, 166.194263, 35.8],
  [-21.724101, 166.194305, 36.2],
  [-21.724062, 166.194359, 36.4],
  [-21.724026, 166.194402, 36.6],
  [-21.723989, 166.194445, 37],
  [-21.72395, 166.194488, 37.2],
  [-21.723912, 166.19452, 37.4],
  [-21.723873, 166.194574, 37.8],
  [-21.723837, 166.194617, 38],
  [-21.7238, 166.194659, 38.4],
  [-21.723761, 166.194692, 38.6],
  [-21.723727, 166.194735, 38.8],
  [-21.723692, 166.194777, 39.2],
  [-21.723659, 166.194831, 39.4],
  [-21.723625, 166.194874, 39.8],
  [-21.723592, 166.194917, 40],
  [-21.723562, 166.194971, 40.4],
  [-21.723543, 166.195014, 40.4],
  [-21.723546, 166.195067, 40.6],
  [-21.72358, 166.195121, 40.6],
  [-21.723629, 166.195153, 40.8],
  [-21.723683, 166.195174, 40.8],
  [-21.723742, 166.195207, 40.8],
  [-21.723804, 166.195228, 40.8],
  [-21.723868, 166.19526, 40.8],
  [-21.723932, 166.195282, 41],
  [-21.723999, 166.195303, 41],
  [-21.724066, 166.195335, 41.2],
  [-21.724133, 166.195368, 41.4],
  [-21.724199, 166.195389, 41.6],
  [-21.724266, 166.195421, 41.8],
  [-21.724332, 166.195443, 42],
  [-21.724398, 166.195464, 42.4],
  [-21.724463, 166.195496, 42.6],
  [-21.72453, 166.195529, 42.8],
  [-21.724595, 166.195561, 43],
  [-21.724659, 166.195571, 43.2],
  [-21.724725, 166.195604, 43.4],
  [-21.724789, 166.195636, 43.6],
  [-21.724854, 166.195668, 43.8],
  [-21.724917, 166.1957, 44],
  [-21.724976, 166.195732, 44],
  [-21.725024, 166.195786, 44.2],
  [-21.725039, 166.195861, 44.2],
  [-21.72502, 166.195947, 44.2],
  [-21.724969, 166.196001, 44.4],
  [-21.724902, 166.196033, 44.6],
  [-21.724831, 166.196054, 45.2],
  [-21.724762, 166.196065, 45.6],
  [-21.724694, 166.196076, 46],
  [-21.724628, 166.196097, 46.4],
  [-21.724568, 166.196108, 46.8],
  [-21.724506, 166.196129, 47.2],
  [-21.724447, 166.196129, 47.6],
  [-21.724392, 166.19614, 48],
  [-21.724339, 166.196151, 48.4],
  [-21.724288, 166.196172, 48.8],
  [-21.724235, 166.196172, 49.2],
  [-21.724188, 166.196183, 49.6],
  [-21.72414, 166.196183, 49.8],
  [-21.724093, 166.196204, 50.2],
  [-21.724049, 166.196215, 50.6],
  [-21.724003, 166.196215, 51],
  [-21.723962, 166.196237, 51.2],
  [-21.723918, 166.196237, 51.6],
  [-21.723876, 166.196247, 51.8],
  [-21.723833, 166.196247, 52.2],
  [-21.723792, 166.196258, 52.4],
  [-21.72375, 166.19628, 52.8],
  [-21.723708, 166.19628, 53],
  [-21.723668, 166.19629, 53.4],
  [-21.723625, 166.196312, 53.6],
  [-21.723584, 166.196312, 54],
  [-21.723543, 166.196322, 54.2],
  [-21.723502, 166.196344, 54.6],
  [-21.72346, 166.196355, 54.8],
  [-21.723417, 166.196355, 55],
  [-21.723376, 166.196365, 55.4],
  [-21.723334, 166.196387, 55.6],
  [-21.723291, 166.196398, 56],
  [-21.72325, 166.196419, 56.2],
  [-21.723205, 166.196419, 56.4],
  [-21.723164, 166.19643, 56.8],
  [-21.72312, 166.19644, 57],
  [-21.723077, 166.19644, 57.4],
  [-21.723034, 166.196462, 57.6],
  [-21.722991, 166.196473, 57.8],
  [-21.722947, 166.196473, 58.2],
  [-21.722902, 166.196494, 58.4],
  [-21.722857, 166.196494, 58.8],
  [-21.722814, 166.196494, 59],
  [-21.72277, 166.196505, 59.4],
  [-21.722724, 166.196505, 59.6],
  [-21.72268, 166.196526, 59.8],
  [-21.722635, 166.196526, 60.2],
  [-21.72259, 166.196526, 60.4],
  [-21.722546, 166.196537, 60.8],
  [-21.7225, 166.196537, 61],
  [-21.722457, 166.196537, 61.4],
  [-21.722413, 166.196548, 61.6],
  [-21.722369, 166.196548, 62],
  [-21.722324, 166.196569, 62.2],
  [-21.722283, 166.19658, 62.6],
  [-21.722243, 166.196601, 63],
  [-21.722206, 166.196612, 63.2],
  [-21.722174, 166.196644, 63.6],
  [-21.722146, 166.196676, 63.8],
  [-21.722123, 166.196709, 64],
  [-21.722103, 166.196752, 64.2],
  [-21.722094, 166.196795, 64.4],
  [-21.722094, 166.196859, 64.6],
  [-21.722103, 166.196902, 64.8],
  [-21.722127, 166.196945, 65],
  [-21.722159, 166.196998, 65.2],
  [-21.722197, 166.197031, 65.2],
  [-21.722243, 166.197052, 65.4],
  [-21.722291, 166.197106, 65.4],
  [-21.722342, 166.197127, 65.6],
  [-21.722395, 166.197159, 65.8],
  [-21.72245, 166.197191, 66],
  [-21.722509, 166.197213, 66],
  [-21.722568, 166.197234, 66.2],
  [-21.722629, 166.197267, 66.4],
  [-21.722688, 166.197288, 66.6],
  [-21.722748, 166.19732, 66.8],
  [-21.722807, 166.197342, 66.8],
  [-21.722865, 166.197395, 67],
  [-21.722924, 166.197428, 67.2],
  [-21.722981, 166.19747, 67.4],
  [-21.72304, 166.197503, 67.6],
  [-21.723097, 166.197546, 67.6],
  [-21.723157, 166.197588, 67.8],
  [-21.723216, 166.197621, 68],
  [-21.723275, 166.197664, 68],
  [-21.723336, 166.197717, 68.2],
  [-21.723395, 166.19776, 68.4],
  [-21.723456, 166.197792, 68.6],
  [-21.723515, 166.197835, 68.8],
  [-21.723576, 166.197878, 68.8],
  [-21.723637, 166.197921, 69],
  [-21.723698, 166.197975, 69.2],
  [-21.723759, 166.198018, 69.4],
  [-21.72382, 166.198061, 69.6],
  [-21.723881, 166.198093, 69.8],
  [-21.723942, 166.198136, 70],
  [-21.724003, 166.198189, 70.4],
  [-21.724062, 166.198211, 70.6],
  [-21.724124, 166.198264, 70.8],
  [-21.724183, 166.198307, 71.2],
  [-21.724238, 166.198339, 71.4],
  [-21.724296, 166.198382, 71.8],
  [-21.724352, 166.198415, 72],
  [-21.724406, 166.198457, 72.2],
  [-21.72445, 166.1985, 72.4],
  [-21.724481, 166.198565, 72.6],
  [-21.724488, 166.19864, 72.8],
  [-21.724467, 166.198704, 73],
  [-21.724419, 166.198747, 73.2],
  [-21.724359, 166.198801, 73.4],
  [-21.724296, 166.198812, 73.6],
  [-21.72423, 166.198844, 73.8],
  [-21.724163, 166.198854, 74],
  [-21.724099, 166.198876, 74.4],
  [-21.724036, 166.198887, 74.8],
  [-21.723975, 166.198919, 75],
  [-21.723918, 166.19893, 75.6],
  [-21.723863, 166.198962, 76],
  [-21.723806, 166.198983, 76.4],
  [-21.723755, 166.198994, 76.8],
  [-21.723706, 166.199026, 77.2],
  [-21.723659, 166.199037, 77.6],
  [-21.723612, 166.199058, 77.8],
  [-21.723566, 166.199069, 78.2],
  [-21.723523, 166.199101, 78.6],
  [-21.723479, 166.199112, 79],
  [-21.723437, 166.199133, 79.2],
  [-21.723395, 166.199144, 79.6],
  [-21.723353, 166.199166, 79.8],
  [-21.723311, 166.199176, 80.2],
  [-21.723268, 166.199187, 80.4],
  [-21.72323, 166.199208, 80.8],
  [-21.723187, 166.199219, 81],
  [-21.723146, 166.199241, 81.4],
  [-21.723105, 166.199251, 81.6],
  [-21.723063, 166.199251, 82],
  [-21.723022, 166.199273, 82.2],
  [-21.722981, 166.199284, 82.6],
  [-21.722939, 166.199294, 82.8],
  [-21.722897, 166.199316, 83.2],
  [-21.722855, 166.199327, 83.4],
  [-21.722814, 166.199348, 83.6],
  [-21.722771, 166.199359, 84],
  [-21.722729, 166.19938, 84.2],
  [-21.722689, 166.19938, 84.6],
  [-21.722648, 166.199391, 84.8],
  [-21.722605, 166.199402, 85.2],
  [-21.722562, 166.199423, 85.4],
  [-21.722519, 166.199434, 85.6],
  [-21.722477, 166.199455, 86],
  [-21.722436, 166.199466, 86.2],
  [-21.722394, 166.199487, 86.6],
  [-21.722351, 166.199487, 86.8],
  [-21.722308, 166.199498, 87.2],
  [-21.722265, 166.199509, 87.4],
  [-21.722224, 166.19953, 87.6],
  [-21.72218, 166.199541, 88],
  [-21.722138, 166.199563, 88.2],
  [-21.722094, 166.199573, 88.6],
  [-21.722052, 166.199584, 88.8],
  [-21.722008, 166.199584, 89],
  [-21.721965, 166.199605, 89.2],
  [-21.721921, 166.199616, 89.4],
  [-21.721876, 166.199648, 89.6],
  [-21.721836, 166.199681, 90],
  [-21.72182, 166.199723, 90.2],
  [-21.721827, 166.199788, 90.4],
  [-21.721858, 166.19982, 90.8],
  [-21.721903, 166.199852, 91],
  [-21.721952, 166.199863, 91.4],
  [-21.721998, 166.199863, 91.6],
  [-21.722048, 166.199874, 91.8],
  [-21.722096, 166.199895, 92.2],
  [-21.722146, 166.199895, 92.4],
  [-21.722196, 166.199895, 92.6],
  [-21.722247, 166.199906, 92.8],
  [-21.722299, 166.199906, 93],
  [-21.722351, 166.199927, 93.2],
  [-21.722405, 166.199927, 93.4],
  [-21.722458, 166.199938, 93.6],
  [-21.722513, 166.19996, 93.8],
  [-21.722568, 166.19996, 94],
  [-21.722625, 166.19997, 94.2],
  [-21.72268, 166.19997, 94.4],
  [-21.722737, 166.199981, 94.8],
  [-21.722796, 166.199981, 95],
  [-21.722851, 166.200002, 95.2],
  [-21.72291, 166.200002, 95.4],
  [-21.722969, 166.200013, 95.6],
  [-21.723028, 166.200013, 95.8],
  [-21.723087, 166.200013, 96],
  [-21.723146, 166.200035, 96.2],
  [-21.723205, 166.200035, 96.6],
  [-21.723267, 166.200035, 96.8],
  [-21.723326, 166.200045, 97],
  [-21.723388, 166.200045, 97.2],
  [-21.723448, 166.200045, 97.4],
  [-21.723507, 166.200045, 97.6],
  [-21.723569, 166.200067, 97.8],
  [-21.723632, 166.200067, 98],
  [-21.723692, 166.200067, 98.2],
  [-21.723747, 166.200067, 98.4],
  [-21.723783, 166.200067, 98.4],
  [-21.723797, 166.200067, 98.6],
  [-21.7238, 166.200067, 98.6],
  [-21.7238, 166.200045, 98.6],
  [-21.723805, 166.200045, 98.6],
  [-21.723824, 166.200045, 98.6],
  [-21.723855, 166.200045, 98.6],
  [-21.723889, 166.200067, 98.8],
  [-21.723927, 166.200078, 98.8],
  [-21.723967, 166.200088, 99],
  [-21.724009, 166.20011, 99],
  [-21.724048, 166.200142, 99],
  [-21.72408, 166.200185, 99.2],
  [-21.724107, 166.200228, 99.2],
  [-21.724121, 166.200292, 99.2],
  [-21.724124, 166.200367, 99.2],
  [-21.724111, 166.200432, 99.4],
  [-21.724074, 166.200485, 99.4],
  [-21.724022, 166.200517, 99.6],
  [-21.723966, 166.20056, 99.6],
  [-21.723904, 166.200593, 100],
  [-21.723845, 166.200625, 100.2],
  [-21.723786, 166.200657, 100.8],
  [-21.723728, 166.200668, 101.2],
  [-21.723675, 166.2007, 101.6],
  [-21.723625, 166.200721, 102],
  [-21.723578, 166.200753, 102.4],
  [-21.72353, 166.200775, 102.6],
  [-21.723483, 166.200807, 102.8],
  [-21.723435, 166.200839, 103.2],
  [-21.723388, 166.200871, 103.4],
  [-21.72334, 166.200882, 103.6],
  [-21.723291, 166.200914, 103.8],
  [-21.723246, 166.200947, 104.2],
  [-21.723199, 166.200979, 104.4],
  [-21.72315, 166.201011, 104.6],
  [-21.723104, 166.201043, 105],
  [-21.723054, 166.201065, 105.2],
  [-21.723008, 166.201097, 105.4],
  [-21.72296, 166.201118, 105.8],
  [-21.722912, 166.20115, 106],
  [-21.722865, 166.201172, 106.4],
  [-21.722817, 166.201193, 106.8],
  [-21.72277, 166.201226, 107.2],
  [-21.722724, 166.201236, 107.6],
  [-21.722681, 166.201247, 108],
  [-21.722642, 166.201279, 108.2],
  [-21.722602, 166.201301, 108.4],
  [-21.722566, 166.201333, 108.6],
  [-21.722536, 166.201376, 108.8],
  [-21.722535, 166.20144, 109],
  [-21.722562, 166.201483, 109.2],
  [-21.722609, 166.201494, 109.6],
  [-21.722658, 166.201494, 110],
  [-21.722708, 166.201494, 110.4],
  [-21.722753, 166.201494, 110.8],
  [-21.722798, 166.201483, 111.2],
  [-21.722842, 166.201483, 111.4],
  [-21.722884, 166.201483, 111.8],
  [-21.722925, 166.201462, 112],
  [-21.722968, 166.201451, 112.4],
  [-21.72301, 166.201451, 112.6],
  [-21.723051, 166.20144, 112.8],
  [-21.723094, 166.20144, 113.2],
  [-21.723136, 166.201419, 113.4],
  [-21.72318, 166.201419, 113.8],
  [-21.723223, 166.201408, 114],
  [-21.723266, 166.201386, 114.2],
  [-21.723309, 166.201386, 114.6],
  [-21.723353, 166.201376, 114.8],
  [-21.723395, 166.201376, 115.2],
  [-21.723439, 166.201354, 115.4],
  [-21.723484, 166.201354, 115.6],
  [-21.723529, 166.201376, 115.8],
  [-21.723565, 166.201419, 116],
  [-21.723588, 166.201462, 116],
  [-21.723589, 166.201526, 116],
  [-21.723574, 166.201569, 116],
  [-21.723543, 166.201633, 116.2],
  [-21.723506, 166.201676, 116.4],
  [-21.723462, 166.20173, 116.6],
  [-21.723416, 166.201773, 117.2],
  [-21.723372, 166.201816, 117.6],
  [-21.723329, 166.201848, 118],
  [-21.723289, 166.20188, 118.4],
  [-21.723252, 166.201923, 118.8],
  [-21.723218, 166.201955, 119.4],
  [-21.723195, 166.201998, 119.8],
  [-21.723183, 166.20203, 120.2],
  [-21.723185, 166.202073, 120.6],
  [-21.723199, 166.202127, 120.8],
  [-21.723227, 166.202148, 120.8],
  [-21.723262, 166.20218, 120.8],
  [-21.723299, 166.202234, 120.8],
  [-21.72334, 166.202277, 120.8],
  [-21.72338, 166.20232, 120.8],
  [-21.723424, 166.202363, 120.8],
  [-21.723467, 166.202416, 120.8],
  [-21.723513, 166.20247, 120.8],
  [-21.723562, 166.202524, 120.8],
];

export default stream;
