import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.756907, 165.847785, 136.2],
  [-10.756751, 165.847732, 136.2],
  [-10.756608, 165.847839, 136.2],
  [-10.756474, 165.847946, 135.6],
  [-10.756327, 165.848043, 135.2],
  [-10.756165, 165.848118, 134.6],
  [-10.755998, 165.848182, 134.2],
  [-10.755828, 165.848225, 134.2],
  [-10.755655, 165.848236, 134.6],
];

export default stream;
