import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.639117, 166.952888, 39],
  [-11.639119, 166.953049, 39.4],
  [-11.639075, 166.95322, 39.4],
  [-11.639061, 166.953392, 39],
  [-11.639062, 166.953574, 38.6],
  [-11.639081, 166.953757, 38],
  [-11.639148, 166.953939, 37.6],
  [-11.639254, 166.954089, 37.2],
  [-11.639374, 166.95424, 36.8],
  [-11.639466, 166.954411, 36.4],
  [-11.639454, 166.954594, 36.2],
  [-11.639341, 166.954744, 36.2],
];

export default stream;
