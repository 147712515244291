import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.781133, 165.835437, 65.6],
  [-10.781153, 165.835544, 65.6],
  [-10.781173, 165.83564, 65.6],
  [-10.781194, 165.835748, 65.6],
  [-10.781218, 165.835834, 65.6],
  [-10.781264, 165.83593, 65.6],
  [-10.781328, 165.836005, 65.6],
  [-10.78138, 165.836091, 65.6],
  [-10.781434, 165.836188, 65.6],
  [-10.78149, 165.836263, 65.6],
  [-10.781538, 165.836349, 65.6],
  [-10.781564, 165.836456, 65.6],
  [-10.781574, 165.836552, 65.6],
  [-10.781574, 165.83666, 65.6],
  [-10.781566, 165.836767, 65.6],
  [-10.781556, 165.836853, 65.6],
  [-10.781546, 165.83696, 65.6],
  [-10.781538, 165.837067, 65.6],
  [-10.781538, 165.837164, 65.6],
  [-10.781549, 165.837271, 65.6],
  [-10.781572, 165.837379, 65.6],
  [-10.781612, 165.837464, 65.6],
  [-10.781665, 165.837561, 65.6],
  [-10.781728, 165.837636, 65.6],
  [-10.7818, 165.837711, 65.6],
  [-10.781875, 165.837775, 65.6],
  [-10.781955, 165.837829, 65.6],
  [-10.782037, 165.837893, 65.6],
  [-10.78212, 165.837958, 65.6],
  [-10.782205, 165.838012, 65.6],
  [-10.78229, 165.838065, 65.6],
  [-10.782376, 165.838119, 65.6],
  [-10.782461, 165.838172, 65.6],
  [-10.782547, 165.838226, 65.8],
  [-10.782637, 165.83828, 65.8],
  [-10.782731, 165.838312, 66],
  [-10.78283, 165.838323, 66],
  [-10.782927, 165.838323, 65.8],
  [-10.78303, 165.838323, 65.8],
  [-10.78313, 165.838323, 65.8],
  [-10.78323, 165.838312, 65.8],
  [-10.783331, 165.83829, 65.8],
  [-10.78343, 165.83829, 65.8],
  [-10.783532, 165.83828, 65.8],
];

export default stream;
