import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [40.782808, -73.967686, 63.6],
  [40.782991, -73.967621, 63.8],
  [40.78317, -73.967546, 63.4],
  [40.783345, -73.967455, 63],
  [40.783522, -73.967353, 62],
  [40.783707, -73.967257, 60.8],
  [40.783892, -73.967149, 58.6],
  [40.784082, -73.967042, 56.2],
  [40.784273, -73.966929, 54.2],
  [40.784466, -73.96679, 52.6],
  [40.784654, -73.966656, 51.2],
  [40.78485, -73.966511, 50],
  [40.78504, -73.966366, 49],
  [40.785225, -73.966221, 48.4],
  [40.785413, -73.966076, 48],
  [40.785601, -73.965932, 47.8],
  [40.78578, -73.965792, 47.8],
  [40.785957, -73.965647, 47.8],
  [40.786134, -73.965518, 47.8],
  [40.786309, -73.965379, 48],
  [40.786486, -73.965245, 48.2],
  [40.786657, -73.965105, 48.4],
  [40.786824, -73.964977, 48.4],
  [40.786995, -73.964848, 48.6],
  [40.787159, -73.964708, 48.8],
  [40.787328, -73.96458, 48.8],
  [40.787492, -73.964451, 49],
  [40.78766, -73.964311, 49.2],
  [40.787819, -73.964183, 49.4],
  [40.787985, -73.964054, 49.6],
  [40.788143, -73.963931, 49.8],
  [40.788307, -73.963802, 50.2],
  [40.788465, -73.963678, 50.4],
  [40.788629, -73.96355, 50.6],
  [40.78879, -73.963421, 50.6],
  [40.788948, -73.963303, 50.8],
  [40.789109, -73.963185, 51],
  [40.789272, -73.963051, 51.4],
  [40.789431, -73.962922, 52],
  [40.789584, -73.962788, 53],
  [40.789723, -73.962643, 54.2],
  [40.789868, -73.962504, 55.4],
  [40.790005, -73.962364, 56.8],
  [40.790139, -73.962235, 58],
  [40.790276, -73.962112, 59],
  [40.790415, -73.961994, 59.8],
  [40.790555, -73.961876, 60.6],
  [40.790697, -73.961763, 61.4],
  [40.790836, -73.961656, 62.2],
  [40.790981, -73.96156, 62.6],
  [40.791139, -73.961474, 62.6],
  [40.791306, -73.961425, 62.6],
];

export default stream;
