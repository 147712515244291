import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [47.259005, 11.410957, 282.6],
  [47.258871, 11.41117, 282.4],
  [47.258785, 11.41143, 282.2],
  [47.258602, 11.411541, 282],
  [47.258399, 11.41152, 282.2],
];

export default stream;
