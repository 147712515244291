import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.717285, 166.966771, 13.8],
  [-11.717175, 166.966771, 13.8],
  [-11.717065, 166.966771, 13.8],
  [-11.716955, 166.966771, 13.8],
  [-11.716845, 166.966771, 13.6],
  [-11.716737, 166.966771, 13.6],
  [-11.716624, 166.966771, 13.8],
  [-11.716514, 166.966771, 13.8],
  [-11.716407, 166.966771, 14.2],
  [-11.716299, 166.966771, 14.6],
  [-11.716196, 166.966771, 15.2],
  [-11.716097, 166.966771, 15.6],
  [-11.716, 166.966771, 16.2],
  [-11.715906, 166.966771, 16.8],
  [-11.715815, 166.966771, 17.4],
  [-11.715728, 166.966771, 17.8],
  [-11.715644, 166.966771, 18.4],
  [-11.715563, 166.966771, 19],
  [-11.715483, 166.966771, 19.4],
  [-11.715406, 166.966771, 20],
  [-11.715331, 166.966771, 20.6],
  [-11.715259, 166.966771, 21],
  [-11.715189, 166.966771, 21.6],
  [-11.715119, 166.966771, 22],
  [-11.715052, 166.966771, 22.6],
  [-11.714986, 166.966771, 23],
  [-11.714922, 166.966771, 23.6],
  [-11.714859, 166.966771, 24],
  [-11.714797, 166.966771, 24.4],
  [-11.714737, 166.966771, 25],
  [-11.714677, 166.966771, 25.4],
  [-11.714619, 166.966771, 25.8],
  [-11.714561, 166.966771, 26.4],
  [-11.714504, 166.966771, 26.8],
  [-11.714447, 166.966771, 27.2],
  [-11.714391, 166.966771, 27.8],
  [-11.714337, 166.966771, 28.2],
  [-11.714281, 166.966771, 28.6],
  [-11.714227, 166.966771, 29],
  [-11.714175, 166.966771, 29.6],
  [-11.714123, 166.966771, 30],
  [-11.714069, 166.966771, 30.4],
  [-11.714017, 166.966771, 30.8],
  [-11.713967, 166.966771, 31.4],
  [-11.713916, 166.966771, 31.8],
  [-11.713865, 166.966771, 32.2],
  [-11.713816, 166.966771, 32.8],
  [-11.713767, 166.966771, 33.2],
  [-11.713719, 166.966771, 33.6],
  [-11.713669, 166.966771, 34.2],
  [-11.713623, 166.966771, 34.6],
  [-11.713574, 166.966771, 35],
  [-11.713529, 166.966771, 35.4],
  [-11.71348, 166.966771, 36],
  [-11.713435, 166.966771, 36.4],
  [-11.713389, 166.966771, 36.8],
  [-11.713344, 166.966771, 37.4],
  [-11.713298, 166.966771, 37.8],
  [-11.713254, 166.966771, 38.2],
  [-11.713208, 166.966771, 38.8],
  [-11.713165, 166.966771, 39.2],
  [-11.713121, 166.966771, 39.6],
  [-11.713075, 166.966771, 40],
  [-11.713032, 166.966771, 40.6],
  [-11.71299, 166.966771, 41],
  [-11.712947, 166.966771, 41.4],
  [-11.712904, 166.966771, 42],
  [-11.712861, 166.966771, 42.4],
  [-11.712818, 166.966771, 42.8],
  [-11.712775, 166.966771, 43.4],
  [-11.712733, 166.966771, 43.8],
  [-11.712692, 166.966771, 44.2],
  [-11.712649, 166.966771, 44.6],
  [-11.712607, 166.966771, 45.2],
  [-11.712566, 166.966771, 45.6],
  [-11.712524, 166.966771, 46],
  [-11.712481, 166.966771, 46.6],
  [-11.712441, 166.966771, 47],
  [-11.712399, 166.966771, 47.4],
  [-11.712358, 166.966771, 47.8],
  [-11.712316, 166.966771, 48.4],
  [-11.712275, 166.966771, 48.8],
  [-11.712234, 166.966771, 49.2],
  [-11.712193, 166.966771, 49.8],
  [-11.712151, 166.966771, 50.2],
  [-11.712111, 166.966771, 50.6],
  [-11.71207, 166.966771, 51],
  [-11.712029, 166.966771, 51.6],
  [-11.711988, 166.966771, 52],
  [-11.711946, 166.966771, 52.4],
  [-11.711906, 166.966771, 53],
  [-11.711864, 166.966771, 53.4],
  [-11.711824, 166.966771, 53.8],
  [-11.711783, 166.966771, 54.2],
  [-11.711742, 166.966771, 54.8],
  [-11.711702, 166.966771, 55.2],
  [-11.71166, 166.966771, 55.6],
  [-11.71162, 166.966771, 56],
  [-11.711579, 166.966771, 56.6],
  [-11.711537, 166.966771, 57],
  [-11.711497, 166.966771, 57.4],
  [-11.711455, 166.966771, 58],
  [-11.711415, 166.966771, 58.4],
  [-11.711375, 166.966771, 58.8],
  [-11.711333, 166.966771, 59.2],
  [-11.711293, 166.966771, 59.8],
  [-11.711251, 166.966771, 60.2],
  [-11.711211, 166.966771, 60.6],
  [-11.71117, 166.966771, 61],
  [-11.711128, 166.966771, 61.6],
  [-11.711088, 166.966771, 62],
  [-11.711048, 166.966771, 62.4],
  [-11.711006, 166.966771, 63],
  [-11.710966, 166.966771, 63.4],
  [-11.710923, 166.966771, 63.8],
  [-11.710883, 166.966771, 64.2],
  [-11.710842, 166.966771, 64.8],
  [-11.710801, 166.966771, 65.2],
  [-11.710761, 166.966771, 65.6],
  [-11.710719, 166.966771, 66.2],
  [-11.710679, 166.966771, 66.6],
  [-11.710637, 166.966771, 67],
  [-11.710597, 166.966771, 67.4],
  [-11.710555, 166.966771, 68],
  [-11.710514, 166.966771, 68.4],
  [-11.710475, 166.966771, 68.8],
  [-11.710433, 166.966771, 69.2],
  [-11.710392, 166.966771, 69.8],
  [-11.710352, 166.966771, 70.2],
  [-11.71031, 166.966771, 70.6],
  [-11.71027, 166.966771, 71.2],
  [-11.710228, 166.966771, 71.6],
  [-11.710187, 166.966771, 72],
  [-11.710148, 166.966771, 72.4],
  [-11.710105, 166.966771, 73],
  [-11.710065, 166.966771, 73.4],
  [-11.710024, 166.966771, 73.8],
  [-11.709983, 166.966771, 74.2],
  [-11.709943, 166.966771, 74.8],
  [-11.709901, 166.966771, 75.2],
  [-11.709861, 166.966771, 75.6],
  [-11.709819, 166.966771, 76.2],
  [-11.709778, 166.966771, 76.6],
  [-11.709739, 166.966771, 77],
  [-11.709696, 166.966771, 77.4],
  [-11.709656, 166.966771, 78],
  [-11.709615, 166.966771, 78.4],
  [-11.709574, 166.966771, 78.8],
  [-11.709533, 166.966771, 79.2],
  [-11.709492, 166.966771, 79.8],
  [-11.709452, 166.966771, 80.2],
  [-11.70941, 166.966771, 80.6],
  [-11.70937, 166.966771, 81.2],
  [-11.709328, 166.966771, 81.6],
  [-11.709289, 166.966771, 82],
  [-11.709249, 166.966771, 82.6],
  [-11.709209, 166.966771, 83],
  [-11.709169, 166.966771, 83.4],
  [-11.709128, 166.966771, 84],
  [-11.709088, 166.966771, 84.4],
  [-11.709049, 166.966771, 84.8],
  [-11.709009, 166.966771, 85.2],
  [-11.708969, 166.966771, 85.8],
  [-11.70893, 166.966771, 86.2],
  [-11.70889, 166.966771, 86.6],
  [-11.708851, 166.966771, 87.2],
  [-11.708811, 166.966771, 87.6],
  [-11.70877, 166.966771, 88],
  [-11.708732, 166.966771, 88.6],
  [-11.708691, 166.966771, 89],
  [-11.708652, 166.966771, 89.4],
  [-11.708612, 166.966771, 89.8],
  [-11.708573, 166.966771, 90.4],
  [-11.708533, 166.966771, 90.8],
  [-11.708494, 166.966771, 91.2],
  [-11.708454, 166.966771, 91.8],
  [-11.708415, 166.966771, 92.2],
  [-11.708375, 166.966771, 92.6],
  [-11.708336, 166.966771, 93],
  [-11.708296, 166.966771, 93.6],
  [-11.708255, 166.966771, 94],
  [-11.708217, 166.966771, 94.4],
  [-11.708178, 166.966771, 95],
  [-11.708137, 166.966771, 95.4],
  [-11.708099, 166.966771, 95.8],
  [-11.708058, 166.966771, 96.2],
  [-11.708018, 166.966771, 96.8],
  [-11.707979, 166.966771, 97.2],
  [-11.707939, 166.966771, 97.6],
  [-11.7079, 166.966771, 98.2],
  [-11.707861, 166.966771, 98.6],
  [-11.707821, 166.966771, 99],
  [-11.707782, 166.966771, 99.4],
  [-11.707742, 166.966771, 100],
  [-11.707703, 166.966771, 100.4],
  [-11.707663, 166.966771, 100.8],
  [-11.707624, 166.966771, 101.4],
  [-11.707585, 166.966771, 101.8],
  [-11.707545, 166.966771, 102.2],
  [-11.707506, 166.966771, 102.6],
  [-11.707465, 166.966771, 103.2],
  [-11.707427, 166.966771, 103.6],
  [-11.707386, 166.966771, 104],
  [-11.707347, 166.966771, 104.6],
  [-11.707307, 166.966771, 105],
  [-11.707268, 166.966771, 105.4],
  [-11.707228, 166.966771, 105.8],
  [-11.707188, 166.966771, 106.4],
  [-11.707149, 166.966771, 106.8],
  [-11.707109, 166.966771, 107.2],
  [-11.70707, 166.966771, 107.8],
  [-11.70703, 166.966771, 108.2],
  [-11.706989, 166.966771, 108.6],
  [-11.706951, 166.966771, 109],
  [-11.706912, 166.966771, 109.6],
  [-11.706871, 166.966771, 110],
  [-11.706832, 166.966771, 110.4],
  [-11.706792, 166.966771, 111],
  [-11.706753, 166.966771, 111.4],
  [-11.706714, 166.966771, 111.8],
  [-11.706674, 166.966771, 112.2],
  [-11.706634, 166.966771, 112.8],
  [-11.706595, 166.966771, 113.2],
  [-11.706555, 166.966771, 113.6],
  [-11.706516, 166.966771, 114.2],
  [-11.706477, 166.966771, 114.6],
  [-11.706437, 166.966771, 115],
  [-11.706397, 166.966771, 115.4],
  [-11.706358, 166.966771, 116],
  [-11.706318, 166.966771, 116.4],
  [-11.706279, 166.966771, 116.8],
  [-11.706238, 166.966771, 117.4],
  [-11.706199, 166.966771, 117.8],
  [-11.706159, 166.966771, 118.2],
  [-11.70612, 166.966771, 118.6],
  [-11.70608, 166.966771, 119.2],
  [-11.706041, 166.966771, 119.6],
  [-11.706001, 166.966771, 120],
  [-11.705962, 166.966771, 120.6],
  [-11.705922, 166.966771, 121],
  [-11.705883, 166.966771, 121.4],
  [-11.705843, 166.966771, 121.8],
  [-11.705804, 166.966771, 122.4],
  [-11.705764, 166.966771, 122.8],
  [-11.705723, 166.966771, 123.2],
  [-11.705685, 166.966771, 123.8],
  [-11.705644, 166.966771, 124.2],
  [-11.705605, 166.966771, 124.6],
  [-11.705565, 166.966771, 125],
  [-11.705526, 166.966771, 125.6],
  [-11.705486, 166.966771, 126],
  [-11.705447, 166.966771, 126.4],
  [-11.705408, 166.966771, 127],
  [-11.705368, 166.966771, 127.4],
  [-11.705328, 166.966771, 127.8],
  [-11.705289, 166.966771, 128.2],
  [-11.70525, 166.966771, 128.8],
  [-11.705208, 166.966771, 129.2],
  [-11.70517, 166.966771, 129.6],
  [-11.705131, 166.966771, 130.2],
  [-11.705092, 166.966771, 130.6],
  [-11.705052, 166.966771, 131],
  [-11.705011, 166.966771, 131.4],
  [-11.704972, 166.966771, 132],
  [-11.704933, 166.966771, 132.4],
  [-11.704893, 166.966771, 132.8],
  [-11.704853, 166.966771, 133.2],
  [-11.704811, 166.966771, 133.6],
  [-11.704769, 166.966771, 134],
  [-11.704723, 166.966771, 134.2],
  [-11.704675, 166.966771, 134.4],
  [-11.704624, 166.966771, 134.6],
  [-11.704569, 166.966771, 134.8],
  [-11.704511, 166.966771, 134.8],
  [-11.704449, 166.966771, 135],
  [-11.704382, 166.966771, 134.8],
  [-11.704311, 166.966771, 134.8],
];

export default stream;
