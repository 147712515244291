import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.748354, 165.842078, 93.6],
  [-10.748204, 165.842003, 93.6],
  [-10.748055, 165.841938, 93.6],
  [-10.747904, 165.841863, 93.6],
  [-10.747752, 165.841799, 93.6],
  [-10.747596, 165.841756, 93.6],
  [-10.747474, 165.841863, 93.6],
  [-10.747404, 165.842013, 93.6],
  [-10.747339, 165.842164, 93.6],
  [-10.747273, 165.842324, 93.6],
  [-10.747199, 165.842453, 93.6],
  [-10.747046, 165.842485, 93.6],
  [-10.746894, 165.84241, 93.6],
  [-10.746747, 165.842346, 93.6],
  [-10.746595, 165.842271, 93.6],
  [-10.746443, 165.842217, 93.6],
  [-10.746284, 165.842217, 93.6],
  [-10.74618, 165.842346, 93.6],
  [-10.746115, 165.842507, 93.6],
  [-10.746047, 165.842657, 93.6],
  [-10.745975, 165.842797, 93.6],
  [-10.745907, 165.842947, 93.6],
  [-10.745841, 165.843097, 93.6],
  [-10.745774, 165.843247, 93.6],
  [-10.745708, 165.843408, 93.6],
  [-10.745642, 165.843558, 93.6],
  [-10.745575, 165.843709, 93.6],
  [-10.745507, 165.843859, 93.6],
  [-10.745441, 165.844009, 93.6],
  [-10.745426, 165.844181, 93.6],
  [-10.745547, 165.844288, 93.6],
  [-10.745696, 165.844363, 93.6],
  [-10.745851, 165.844406, 93.6],
  [-10.746006, 165.84447, 93.6],
  [-10.74616, 165.844535, 93.6],
];

export default stream;
