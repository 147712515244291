import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [40.792132, -73.961479, 62.6],
  [40.792314, -73.961511, 62.6],
  [40.792502, -73.961543, 62.6],
  [40.792684, -73.961581, 62.6],
  [40.792869, -73.961619, 62.6],
  [40.793054, -73.961651, 62.2],
  [40.793248, -73.961678, 61.4],
  [40.793438, -73.961704, 60.8],
  [40.793636, -73.961726, 60],
  [40.79384, -73.961731, 59.2],
  [40.794041, -73.961726, 58.2],
  [40.79424, -73.961688, 57.2],
  [40.794441, -73.961619, 56],
  [40.79464, -73.961517, 54.8],
  [40.79483, -73.961404, 53.6],
  [40.795026, -73.961281, 52.2],
  [40.795222, -73.961163, 51],
  [40.795415, -73.961045, 49.6],
  [40.795613, -73.960927, 48.2],
  [40.795812, -73.960787, 46.8],
  [40.796008, -73.960658, 45.4],
  [40.796203, -73.960519, 44],
  [40.796407, -73.96039, 42.6],
  [40.7966, -73.960251, 41.2],
  [40.796804, -73.960117, 39.8],
  [40.797003, -73.959977, 38.6],
  [40.797196, -73.959843, 37.2],
  [40.7974, -73.959698, 35.8],
  [40.797593, -73.959559, 34.6],
  [40.797797, -73.959414, 33.2],
  [40.79799, -73.959269, 32.2],
];

export default stream;
