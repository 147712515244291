import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.693138, 166.966599, 318.2],
  [-11.693147, 166.966513, 318.2],
  [-11.693187, 166.966438, 318.2],
  [-11.693255, 166.966374, 318],
  [-11.693345, 166.966342, 318],
  [-11.69344, 166.966342, 318],
  [-11.693534, 166.966363, 318],
  [-11.69362, 166.966406, 318],
  [-11.693692, 166.96647, 318],
  [-11.693755, 166.966546, 318],
  [-11.693817, 166.966621, 318],
  [-11.693885, 166.966696, 318],
];

export default stream;
