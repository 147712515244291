import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.661551, 166.984259, 12.8],
  [-11.661353, 166.984302, 12.6],
  [-11.661153, 166.984323, 12.8],
  [-11.660951, 166.984302, 12.8],
  [-11.660751, 166.98427, 12.8],
  [-11.660551, 166.984291, 13],
  [-11.660421, 166.984441, 13.4],
  [-11.660397, 166.984634, 13.6],
  [-11.660394, 166.984838, 14],
  [-11.660347, 166.985031, 14.2],
  [-11.660217, 166.985192, 14.2],
  [-11.66006, 166.98531, 14],
  [-11.659891, 166.985418, 13.8],
  [-11.659705, 166.985493, 13.6],
  [-11.659504, 166.985503, 13.6],
  [-11.65932, 166.985428, 13.4],
  [-11.659182, 166.985278, 13.6],
  [-11.659078, 166.985106, 13.6],
  [-11.65899, 166.984924, 13.8],
  [-11.658909, 166.984731, 14],
  [-11.658832, 166.984549, 14],
  [-11.658758, 166.984366, 14.2],
  [-11.658684, 166.984162, 14.4],
  [-11.65861, 166.98398, 14.4],
  [-11.658537, 166.983787, 14.6],
  [-11.658458, 166.983604, 14.8],
  [-11.658369, 166.983422, 15],
  [-11.658252, 166.983261, 15],
  [-11.658082, 166.983154, 15.2],
  [-11.657897, 166.983218, 15.2],
  [-11.657753, 166.983358, 15.2],
  [-11.657641, 166.983529, 15],
  [-11.657598, 166.983722, 14.8],
  [-11.657578, 166.983937, 14.6],
  [-11.657539, 166.98413, 14.2],
  [-11.657426, 166.984302, 13.8],
  [-11.657272, 166.984441, 13.6],
  [-11.657104, 166.984559, 13.4],
  [-11.656929, 166.984667, 13.2],
  [-11.65674, 166.984763, 13.2],
  [-11.65654, 166.984795, 13.4],
  [-11.656333, 166.984806, 13.6],
  [-11.656133, 166.984838, 13.8],
  [-11.655942, 166.984903, 13.8],
  [-11.655777, 166.985021, 13.4],
  [-11.655589, 166.985096, 13.2],
  [-11.655386, 166.985085, 13.2],
  [-11.655187, 166.985031, 13.2],
  [-11.654983, 166.985021, 13.2],
  [-11.65479, 166.985096, 13.2],
  [-11.654609, 166.985192, 13.2],
  [-11.654457, 166.985321, 13.2],
  [-11.654351, 166.985493, 13.2],
  [-11.654284, 166.985697, 13.2],
  [-11.654236, 166.98589, 13.2],
  [-11.654195, 166.986083, 13.2],
  [-11.654163, 166.986297, 13.2],
  [-11.654136, 166.986501, 13.2],
  [-11.654116, 166.986694, 13.2],
  [-11.654104, 166.986909, 13.2],
  [-11.654103, 166.987113, 13.2],
  [-11.654116, 166.987327, 13.2],
  [-11.654167, 166.98752, 13.2],
  [-11.654295, 166.987671, 13.2],
  [-11.654462, 166.987799, 13.2],
  [-11.654625, 166.987917, 13.2],
  [-11.654743, 166.988068, 13.2],
  [-11.65481, 166.988271, 13.2],
  [-11.654837, 166.988486, 13.2],
  [-11.654845, 166.988679, 13.2],
  [-11.654843, 166.988883, 13.2],
  [-11.654838, 166.989098, 13.2],
  [-11.654828, 166.989291, 13.2],
  [-11.654816, 166.989505, 13.2],
  [-11.654802, 166.989709, 13.2],
  [-11.654784, 166.989902, 13.2],
  [-11.654765, 166.990117, 13.2],
  [-11.654744, 166.99031, 13.2],
  [-11.654723, 166.990525, 13.2],
  [-11.654698, 166.990728, 13.2],
  [-11.654672, 166.990943, 13.2],
  [-11.654645, 166.991136, 13.2],
  [-11.654613, 166.99134, 13.2],
  [-11.654575, 166.991533, 13.2],
  [-11.654529, 166.991737, 13.2],
  [-11.654462, 166.99193, 13.2],
  [-11.654358, 166.992102, 13.2],
  [-11.6542, 166.992241, 13.2],
  [-11.654008, 166.992295, 13.2],
  [-11.653805, 166.992284, 13.2],
  [-11.653611, 166.99222, 13.2],
  [-11.65342, 166.992145, 13.2],
  [-11.653231, 166.99208, 13.2],
  [-11.653038, 166.992027, 13.2],
  [-11.652852, 166.991951, 13.2],
  [-11.652661, 166.991876, 13.2],
  [-11.652475, 166.991791, 13.2],
  [-11.652287, 166.991705, 13.2],
  [-11.652099, 166.99164, 13.2],
  [-11.651908, 166.991565, 13.2],
  [-11.65171, 166.991522, 13.2],
  [-11.651509, 166.991501, 13.2],
  [-11.651313, 166.991565, 13.2],
  [-11.651131, 166.99164, 13.2],
  [-11.650935, 166.991705, 13.2],
  [-11.650734, 166.991715, 13.2],
  [-11.650532, 166.991705, 13.4],
  [-11.650337, 166.991748, 13.4],
  [-11.650258, 166.99193, 13.6],
  [-11.650233, 166.992134, 13.6],
  [-11.650139, 166.992316, 13.6],
  [-11.650005, 166.992466, 13.6],
  [-11.649861, 166.992606, 13.4],
  [-11.649715, 166.992756, 13.4],
  [-11.64957, 166.992896, 13.4],
  [-11.649432, 166.993046, 13.2],
  [-11.64931, 166.993217, 13.2],
  [-11.649212, 166.993378, 13.2],
  [-11.649172, 166.993582, 13.2],
  [-11.649188, 166.993797, 13.2],
  [-11.649211, 166.99399, 13.2],
  [-11.649232, 166.994205, 13.2],
  [-11.649258, 166.994408, 13.2],
  [-11.64929, 166.994601, 13.2],
  [-11.649322, 166.994816, 13.2],
  [-11.649354, 166.995009, 13.2],
  [-11.649389, 166.995213, 13.2],
  [-11.649422, 166.995406, 13.2],
  [-11.649455, 166.995621, 13.2],
  [-11.649488, 166.995825, 13.2],
  [-11.64952, 166.996018, 13.2],
  [-11.649549, 166.996232, 13.2],
  [-11.649575, 166.996436, 13.2],
  [-11.649598, 166.996629, 13.2],
  [-11.649614, 166.996844, 13.2],
  [-11.649621, 166.997048, 13.2],
  [-11.649621, 166.997262, 13.2],
  [-11.649617, 166.997455, 13.2],
  [-11.649608, 166.997659, 13.2],
  [-11.649598, 166.997863, 13.2],
  [-11.649589, 166.998067, 13.2],
  [-11.649583, 166.998281, 13.2],
  [-11.649589, 166.998475, 13.2],
  [-11.649612, 166.998689, 13.2],
  [-11.649672, 166.998872, 13.2],
  [-11.649794, 166.999043, 13.4],
  [-11.649966, 166.999151, 13.4],
  [-11.650155, 166.999226, 13.6],
  [-11.650347, 166.99929, 13.6],
  [-11.650499, 166.999408, 13.8],
  [-11.650577, 166.99959, 13.8],
  [-11.650575, 166.999805, 13.8],
  [-11.650495, 166.999987, 13.8],
  [-11.650344, 167.000116, 13.8],
  [-11.650161, 167.000202, 13.8],
  [-11.649979, 167.000299, 13.8],
  [-11.649815, 167.000417, 13.8],
  [-11.649726, 167.000599, 13.8],
  [-11.649722, 167.000803, 13.8],
  [-11.649748, 167.000996, 13.8],
  [-11.649778, 167.00121, 13.8],
  [-11.649767, 167.001404, 13.8],
  [-11.649696, 167.001586, 13.8],
  [-11.649571, 167.001758, 13.6],
  [-11.649409, 167.001876, 13.8],
  [-11.649231, 167.001972, 14],
  [-11.649039, 167.002047, 14],
  [-11.648842, 167.002047, 14.2],
  [-11.648689, 167.001908, 14.2],
  [-11.648599, 167.001747, 14],
  [-11.648524, 167.001543, 13.8],
  [-11.64846, 167.00135, 13.8],
  [-11.648415, 167.001146, 13.6],
  [-11.648342, 167.000964, 13.4],
  [-11.648203, 167.000813, 13.4],
  [-11.648047, 167.000674, 13.2],
  [-11.647852, 167.000631, 13.2],
  [-11.647666, 167.000706, 13.4],
  [-11.647511, 167.000846, 13.8],
  [-11.647385, 167.000996, 14],
  [-11.647271, 167.001168, 14.4],
  [-11.647159, 167.001328, 14.8],
  [-11.647053, 167.0015, 15],
  [-11.646947, 167.001672, 15.4],
  [-11.64684, 167.001833, 15.8],
  [-11.646732, 167.002004, 16],
  [-11.646622, 167.002176, 16.4],
  [-11.646507, 167.002337, 16.8],
  [-11.646385, 167.002487, 17],
  [-11.646249, 167.002627, 17.4],
  [-11.646087, 167.002734, 17.6],
  [-11.645898, 167.002777, 17.6],
  [-11.645704, 167.002755, 17.6],
  [-11.645524, 167.00267, 17.4],
  [-11.645362, 167.002552, 17.2],
  [-11.645219, 167.002412, 17],
  [-11.645114, 167.002251, 16.8],
  [-11.645095, 167.002037, 16.4],
  [-11.645183, 167.001854, 16],
  [-11.645309, 167.001693, 15.6],
  [-11.645445, 167.001532, 15.2],
  [-11.645584, 167.001382, 14.8],
  [-11.645718, 167.001221, 14.6],
  [-11.645838, 167.001039, 14.2],
  [-11.645911, 167.000846, 13.8],
  [-11.645932, 167.000642, 13.6],
  [-11.645913, 167.000427, 13.4],
  [-11.645858, 167.000234, 13.4],
  [-11.645775, 167.00003, 13.4],
  [-11.645655, 166.999869, 13.2],
  [-11.645488, 166.999741, 13.2],
  [-11.645299, 166.999655, 13.2],
  [-11.645101, 166.99959, 13.2],
  [-11.644898, 166.99959, 13.2],
  [-11.644692, 166.999612, 13.2],
  [-11.64449, 166.999612, 13.2],
  [-11.644309, 166.999515, 13.2],
  [-11.644181, 166.999344, 13.2],
  [-11.644086, 166.999161, 13.2],
  [-11.643999, 166.998979, 13.2],
  [-11.643903, 166.998796, 13.2],
  [-11.64376, 166.998657, 13.2],
  [-11.643579, 166.998571, 13.2],
  [-11.643378, 166.998539, 13.2],
  [-11.643182, 166.998603, 13.2],
  [-11.643008, 166.998689, 13.2],
  [-11.642828, 166.998796, 13.2],
  [-11.642628, 166.998829, 13.2],
  [-11.642427, 166.998796, 13.2],
  [-11.642231, 166.998743, 13.2],
  [-11.642042, 166.998657, 13.2],
  [-11.641867, 166.998571, 13.2],
  [-11.641743, 166.998399, 13.2],
  [-11.641688, 166.998206, 13.2],
  [-11.641649, 166.998003, 13.2],
  [-11.641576, 166.997809, 13.2],
  [-11.64149, 166.997627, 13.2],
  [-11.641399, 166.997445, 13.2],
  [-11.641302, 166.997262, 13.2],
  [-11.641202, 166.997091, 13.2],
  [-11.641096, 166.996908, 13.2],
  [-11.640988, 166.996737, 13.2],
  [-11.640878, 166.996554, 13.2],
  [-11.640771, 166.996382, 13.2],
  [-11.640664, 166.996222, 13.2],
  [-11.640565, 166.996039, 13.2],
  [-11.640471, 166.995857, 13.2],
  [-11.640374, 166.995674, 13.2],
  [-11.640273, 166.995503, 13.2],
  [-11.640186, 166.99531, 13.2],
  [-11.640131, 166.995106, 13.2],
  [-11.640125, 166.994913, 13.2],
  [-11.640162, 166.994709, 13.2],
  [-11.640228, 166.994516, 13.2],
  [-11.640319, 166.994323, 13.2],
  [-11.64043, 166.994162, 13.2],
  [-11.640562, 166.994011, 13.2],
  [-11.640711, 166.993872, 13.2],
  [-11.640882, 166.993765, 13.2],
  [-11.641067, 166.993668, 13.2],
  [-11.641258, 166.993593, 13.2],
  [-11.641448, 166.993539, 13.2],
  [-11.641639, 166.993454, 13.2],
  [-11.641826, 166.993378, 13.2],
  [-11.642001, 166.993271, 13.2],
  [-11.64214, 166.993142, 13.2],
  [-11.642231, 166.992949, 13.2],
  [-11.642274, 166.992756, 13.2],
  [-11.642282, 166.992542, 13.2],
  [-11.642269, 166.992348, 13.2],
  [-11.642244, 166.992134, 13.2],
  [-11.642207, 166.99193, 13.2],
  [-11.642146, 166.991737, 13.2],
  [-11.642068, 166.991554, 13.2],
  [-11.641982, 166.991372, 13.2],
  [-11.641892, 166.99119, 13.2],
  [-11.641802, 166.990997, 13.2],
  [-11.641715, 166.990803, 13.2],
  [-11.641627, 166.990621, 13.2],
  [-11.641533, 166.990439, 13.2],
  [-11.641436, 166.990267, 13.2],
  [-11.641341, 166.990085, 13.2],
  [-11.641246, 166.989902, 13.2],
  [-11.641152, 166.98972, 13.2],
  [-11.641061, 166.989537, 13.2],
  [-11.640968, 166.989355, 13.2],
  [-11.640872, 166.989173, 13.2],
  [-11.640775, 166.98899, 13.2],
  [-11.64068, 166.988808, 13.2],
  [-11.640589, 166.988626, 13.2],
  [-11.640503, 166.988443, 13.2],
  [-11.640424, 166.98825, 13.2],
  [-11.640367, 166.988057, 13.2],
  [-11.640377, 166.987853, 13.2],
  [-11.640453, 166.98766, 13.2],
  [-11.640476, 166.987456, 13.2],
  [-11.640361, 166.987295, 13.2],
  [-11.640204, 166.987166, 13.2],
  [-11.640041, 166.987048, 13.2],
  [-11.639898, 166.986898, 13.2],
  [-11.639838, 166.986716, 13.2],
  [-11.639914, 166.986512, 13.2],
  [-11.640047, 166.986362, 13.2],
  [-11.640196, 166.986222, 13.2],
  [-11.640347, 166.986083, 13.2],
  [-11.640501, 166.985965, 13.2],
  [-11.640645, 166.985815, 13.2],
  [-11.64076, 166.985643, 13.2],
  [-11.640846, 166.985461, 13.2],
  [-11.640892, 166.985267, 13.2],
  [-11.640911, 166.985064, 13.2],
  [-11.640904, 166.984849, 13.4],
  [-11.640868, 166.984656, 13.6],
  [-11.640801, 166.984452, 13.8],
  [-11.640695, 166.984291, 13.8],
  [-11.640546, 166.984152, 13.8],
  [-11.640375, 166.984044, 13.8],
  [-11.640188, 166.983969, 14],
  [-11.639997, 166.983926, 14],
  [-11.639808, 166.98384, 14.2],
  [-11.639638, 166.983733, 14.2],
  [-11.639505, 166.983583, 14.4],
  [-11.639403, 166.983422, 14.6],
  [-11.639325, 166.98324, 14.8],
  [-11.639261, 166.983036, 14.8],
  [-11.639205, 166.982843, 14.8],
  [-11.639159, 166.98265, 14.6],
  [-11.639138, 166.982446, 14.6],
  [-11.639163, 166.982242, 14.4],
  [-11.639273, 166.98207, 14.2],
  [-11.639435, 166.981952, 14.2],
  [-11.639611, 166.981845, 14.2],
  [-11.639777, 166.981727, 14],
  [-11.639927, 166.981587, 14],
  [-11.640079, 166.981448, 13.8],
  [-11.640231, 166.981308, 13.8],
  [-11.640369, 166.981158, 13.6],
  [-11.640492, 166.980987, 13.6],
  [-11.640598, 166.980826, 13.4],
  [-11.640696, 166.980643, 13.4],
  [-11.640786, 166.980461, 13.4],
  [-11.640869, 166.980257, 13.4],
  [-11.640943, 166.980075, 13.2],
  [-11.641007, 166.979882, 13.2],
  [-11.641051, 166.979667, 13.2],
  [-11.641071, 166.979463, 13.2],
  [-11.641063, 166.979249, 13.2],
  [-11.641022, 166.979055, 13.2],
  [-11.640947, 166.978873, 13.2],
  [-11.640823, 166.978701, 13.2],
  [-11.64066, 166.978583, 13.2],
  [-11.640485, 166.978476, 13.2],
];

export default stream;
