import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [55.643751, -5.222352, 27.8],
  [55.643692, -5.222172, 27.8],
  [55.643638, -5.221989, 27.8],
  [55.643547, -5.221866, 27.8],
  [55.64344, -5.22181, 27.8],
  [55.643322, -5.221814, 27.8],
  [55.64322, -5.221896, 27.8],
  [55.643145, -5.222039, 27.8],
  [55.64307, -5.222184, 27.8],
  [55.642962, -5.222242, 27.8],
];

export default stream;
