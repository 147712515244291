import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [55.65463, -5.234512, 26.8],
  [55.654544, -5.234375, 26.8],
  [55.654464, -5.234229, 26.8],
  [55.654389, -5.234082, 26.8],
  [55.654314, -5.233937, 26.8],
  [55.654238, -5.233792, 26.8],
  [55.654163, -5.233643, 26.8],
  [55.654099, -5.233486, 26.8],
  [55.654056, -5.233306, 26.8],
  [55.654061, -5.233112, 26.8],
  [55.654104, -5.232933, 26.8],
  [55.654153, -5.232759, 27],
  [55.654169, -5.232571, 27],
  [55.654131, -5.232395, 27.4],
  [55.654067, -5.232251, 27.8],
  [55.654002, -5.232114, 28.2],
  [55.653965, -5.23195, 28.2],
  [55.653976, -5.231773, 28],
  [55.654008, -5.231597, 27.4],
  [55.654051, -5.231421, 26.8],
  [55.654094, -5.231239, 26],
  [55.654158, -5.231064, 25.4],
  [55.654228, -5.23089, 24.6],
  [55.654255, -5.230678, 23.8],
  [55.654196, -5.230483, 23.6],
  [55.654099, -5.230341, 23.6],
  [55.654002, -5.230199, 23.8],
  [55.653965, -5.229999, 23.8],
  [55.653981, -5.229789, 23.8],
  [55.65404, -5.229605, 23.8],
  [55.65411, -5.229435, 23.8],
  [55.654174, -5.229266, 23.8],
  [55.654244, -5.229099, 23.8],
  [55.654308, -5.228933, 23.8],
  [55.654362, -5.228749, 23.8],
];

export default stream;
