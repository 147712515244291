import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [51.506981, -0.127472, 25.8],
  [51.507118, -0.127488, 25.4],
  [51.507232, -0.127695, 25.2],
  [51.507218, -0.127917, 25.0],
  [51.507172, -0.128043, 24.6],
];

export default stream;
