import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.638462, 166.97736, 15.6],
  [-11.638565, 166.977543, 15.6],
  [-11.63869, 166.977693, 15.2],
  [-11.638847, 166.977832, 15],
  [-11.639017, 166.97794, 14.8],
  [-11.639198, 166.978036, 14.4],
  [-11.639391, 166.978111, 14.2],
  [-11.639595, 166.978143, 13.8],
  [-11.6398, 166.978154, 13.6],
  [-11.640001, 166.978197, 13.2],
  [-11.64019, 166.978294, 13.2],
  [-11.64037, 166.978379, 13.2],
];

export default stream;
