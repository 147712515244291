import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.637319, 166.954443, 2],
  [-11.637186, 166.954583, 2.2],
  [-11.637033, 166.954701, 2.4],
  [-11.636879, 166.954808, 2.6],
  [-11.636722, 166.954916, 3],
  [-11.636589, 166.955055, 3.2],
  [-11.636547, 166.955237, 3.8],
  [-11.636657, 166.955377, 4.2],
  [-11.636832, 166.955431, 4.4],
  [-11.637017, 166.955463, 4.6],
];

export default stream;
