import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.686786, 166.925712, 6.6],
  [-11.686972, 166.925647, 6.2],
  [-11.687168, 166.925615, 6],
  [-11.687362, 166.925615, 5.6],
  [-11.687558, 166.925637, 5],
  [-11.687749, 166.925658, 4.2],
  [-11.687936, 166.925722, 3.2],
  [-11.688101, 166.92583, 2.4],
  [-11.688211, 166.926001, 1.6],
  [-11.688311, 166.926184, 1],
  [-11.688477, 166.92627, 0.4],
  [-11.688673, 166.926259, 0.4],
  [-11.688856, 166.926184, 0.4],
  [-11.689027, 166.926087, 0.4],
  [-11.68913, 166.925937, 0.6],
  [-11.689112, 166.925744, 0.6],
  [-11.689127, 166.925561, 0.6],
  [-11.689259, 166.925422, 0.6],
  [-11.689429, 166.925347, 0.6],
  [-11.689602, 166.925272, 0.6],
  [-11.689768, 166.925175, 0.6],
  [-11.689932, 166.9251, 0.6],
  [-11.690112, 166.925036, 0.6],
  [-11.690294, 166.925057, 0.6],
  [-11.690468, 166.925111, 0.6],
  [-11.690632, 166.925207, 0.6],
  [-11.690779, 166.925315, 0.6],
  [-11.690889, 166.925465, 0.4],
  [-11.690951, 166.925637, 0.4],
  [-11.691003, 166.925819, 0.4],
  [-11.691087, 166.92598, 0.4],
  [-11.691182, 166.926141, 0.4],
  [-11.69126, 166.926302, 0.4],
  [-11.691272, 166.926484, 0.6],
  [-11.691207, 166.926666, 0.8],
  [-11.691107, 166.926827, 1.2],
  [-11.690987, 166.926945, 1.6],
  [-11.690859, 166.927085, 1.8],
  [-11.69071, 166.927171, 2.2],
  [-11.690553, 166.927246, 2.4],
  [-11.690392, 166.927332, 2.6],
  [-11.690238, 166.927418, 2.8],
  [-11.690113, 166.927536, 3],
  [-11.690043, 166.927707, 3],
  [-11.690014, 166.927879, 3.2],
  [-11.690007, 166.928061, 3.2],
  [-11.690018, 166.928222, 3.2],
  [-11.690043, 166.928405, 3.2],
  [-11.690083, 166.928576, 3.4],
  [-11.69014, 166.928759, 3.4],
  [-11.690216, 166.928909, 3.4],
  [-11.690307, 166.92907, 3.4],
  [-11.690409, 166.92922, 3.4],
  [-11.690523, 166.929359, 3.4],
  [-11.690652, 166.929477, 3.2],
  [-11.690796, 166.929585, 3.2],
  [-11.690963, 166.929649, 2.8],
  [-11.691142, 166.929681, 2.6],
  [-11.691324, 166.929692, 2.4],
  [-11.691506, 166.929703, 2],
  [-11.691682, 166.929756, 1.8],
  [-11.691852, 166.929832, 1.4],
  [-11.692015, 166.929917, 1],
  [-11.692172, 166.930025, 0.8],
  [-11.692307, 166.930153, 0.6],
  [-11.692405, 166.930314, 0.4],
  [-11.69245, 166.930497, 0.4],
];

export default stream;
