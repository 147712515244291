import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.751219, 165.857077, 151.8],
  [-10.751298, 165.856862, 151.8],
  [-10.751369, 165.856658, 152.8],
  [-10.751446, 165.856476, 153.2],
  [-10.751544, 165.856293, 153.2],
  [-10.751627, 165.856111, 153.2],
  [-10.751657, 165.855918, 153.6],
  [-10.751692, 165.855736, 153.8],
  [-10.751768, 165.855553, 153.6],
  [-10.75188, 165.855381, 153.4],
  [-10.752019, 165.855263, 153.4],
  [-10.75217, 165.855135, 153.6],
  [-10.752326, 165.855027, 153.8],
  [-10.75248, 165.85492, 153.8],
  [-10.752639, 165.854834, 153.8],
  [-10.752796, 165.854727, 153.8],
  [-10.7529, 165.854577, 153.2],
  [-10.752966, 165.854394, 152.6],
  [-10.753031, 165.854201, 151.8],
  [-10.753064, 165.854019, 151.2],
  [-10.753053, 165.853826, 150.6],
  [-10.753094, 165.853622, 150.2],
  [-10.753164, 165.85344, 150.4],
  [-10.753241, 165.853257, 150.6],
  [-10.753343, 165.853107, 150.8],
  [-10.753483, 165.852968, 151],
  [-10.753661, 165.852914, 150.6],
  [-10.753848, 165.852882, 150.4],
  [-10.754032, 165.852849, 150.4],
  [-10.7542, 165.852774, 150.8],
  [-10.754325, 165.852635, 151.6],
  [-10.754424, 165.852463, 152.8],
  [-10.754517, 165.852334, 154],
  [-10.754646, 165.852206, 155.2],
  [-10.754808, 165.852163, 156.6],
  [-10.754974, 165.852163, 157.8],
  [-10.755124, 165.852227, 159],
  [-10.755251, 165.852334, 160],
  [-10.755372, 165.852431, 161],
  [-10.755506, 165.852528, 162],
  [-10.755653, 165.852485, 163.4],
  [-10.755791, 165.85241, 164.4],
  [-10.755942, 165.852377, 165.2],
  [-10.756094, 165.852345, 166],
];

export default stream;
