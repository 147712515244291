import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [55.640731, -5.218463, 42],
  [55.640731, -5.218302, 42.2],
  [55.640731, -5.218144, 42.8],
  [55.640731, -5.217993, 43.4],
  [55.640731, -5.217852, 44.2],
  [55.640731, -5.21772, 45],
  [55.640731, -5.217597, 45.8],
  [55.640731, -5.217483, 46.4],
  [55.640736, -5.217377, 47],
  [55.640736, -5.217277, 47.6],
  [55.640736, -5.217184, 48.2],
  [55.640736, -5.217095, 48.6],
  [55.640736, -5.21701, 49.2],
  [55.640742, -5.216925, 49.4],
  [55.640742, -5.216841, 49.8],
  [55.640742, -5.216754, 50],
  [55.640742, -5.216665, 50.2],
  [55.640736, -5.216571, 50.4],
  [55.640736, -5.216473, 50.4],
  [55.640736, -5.21637, 50.6],
  [55.640736, -5.216261, 50.6],
  [55.640736, -5.216147, 50.6],
  [55.640736, -5.216027, 50.6],
  [55.640736, -5.215902, 50.6],
  [55.640736, -5.215772, 50.6],
  [55.640731, -5.215637, 50.6],
  [55.640731, -5.215498, 50.6],
  [55.640709, -5.215361, 50.4],
  [55.640666, -5.215242, 50.4],
  [55.640591, -5.21516, 50.4],
  [55.640506, -5.215136, 50],
  [55.640414, -5.21513, 49.4],
  [55.640312, -5.215129, 49],
  [55.640211, -5.21513, 48.4],
  [55.640103, -5.215132, 47.8],
  [55.639991, -5.215134, 47.4],
  [55.639883, -5.215136, 46.8],
  [55.639765, -5.215138, 46],
  [55.639647, -5.215139, 45.4],
  [55.639518, -5.21514, 44.8],
  [55.639395, -5.21514, 44],
  [55.639266, -5.215141, 43.4],
  [55.639138, -5.215141, 42.6],
  [55.639004, -5.215141, 42],
  [55.63888, -5.215086, 42.2],
  [55.638821, -5.21489, 43],
  [55.638816, -5.214675, 43.8],
  [55.638821, -5.21447, 44.6],
  [55.638826, -5.214277, 45.2],
  [55.638826, -5.214093, 46],
  [55.638821, -5.213919, 46.6],
  [55.638778, -5.213767, 46.8],
  [55.638714, -5.213652, 46.8],
  [55.638623, -5.2136, 46.6],
  [55.638526, -5.213591, 46.2],
  [55.638419, -5.213588, 45.6],
  [55.638317, -5.213587, 45.2],
  [55.63821, -5.213588, 45],
  [55.638097, -5.21359, 45.4],
  [55.63799, -5.213591, 45.8],
  [55.637888, -5.213591, 45.8],
  [55.637786, -5.213591, 45.4],
  [55.637679, -5.21359, 45],
  [55.637566, -5.213589, 44.4],
  [55.637453, -5.213589, 43.8],
  [55.637341, -5.21359, 43.4],
  [55.637217, -5.213592, 43.2],
  [55.637099, -5.213593, 43.2],
  [55.636981, -5.213594, 43.4],
  [55.636874, -5.213594, 43.8],
  [55.636761, -5.21359, 44.4],
  [55.636659, -5.213585, 45],
  [55.636557, -5.21358, 45.6],
  [55.636461, -5.21358, 46],
  [55.636364, -5.213586, 46.2],
  [55.636268, -5.213594, 46.4],
  [55.636176, -5.2136, 46.6],
  [55.636085, -5.21361, 46.6],
  [55.635994, -5.21364, 46.4],
  [55.635903, -5.213699, 46.2],
  [55.635828, -5.213803, 45.8],
  [55.635774, -5.213958, 45.2],
  [55.635758, -5.214146, 44.4],
  [55.635758, -5.214346, 43.6],
  [55.635758, -5.214555, 42.6],
  [55.635758, -5.214772, 41.8],
  [55.635763, -5.214997, 40.8],
  [55.635763, -5.215228, 39.8],
  [55.635763, -5.215466, 38.8],
  [55.635763, -5.215709, 37.8],
  [55.635763, -5.215958, 36.8],
  [55.635806, -5.216191, 35.8],
  [55.635935, -5.216293, 35.6],
  [55.636075, -5.216301, 35.6],
  [55.636214, -5.2163, 35.4],
  [55.636348, -5.216299, 35.4],
  [55.636482, -5.216298, 35.4],
  [55.636611, -5.216297, 35.4],
  [55.636745, -5.216295, 35.6],
  [55.636868, -5.216294, 35.6],
  [55.636992, -5.216292, 35.8],
  [55.637115, -5.216291, 35.8],
  [55.637233, -5.21629, 35.8],
  [55.637351, -5.216288, 36.2],
  [55.637469, -5.216286, 36.6],
  [55.637577, -5.216284, 37],
  [55.637684, -5.216291, 37.4],
  [55.637775, -5.216394, 37.4],
  [55.637839, -5.216533, 37.4],
  [55.637936, -5.216612, 37.6],
  [55.638038, -5.216619, 37.6],
  [55.638134, -5.216553, 37.6],
  [55.63821, -5.216427, 37.4],
  [55.638285, -5.216299, 37.6],
  [55.638387, -5.216265, 38.2],
  [55.638483, -5.216267, 39],
  [55.638574, -5.21627, 39.8],
  [55.63866, -5.216272, 40.4],
  [55.638741, -5.216271, 41],
  [55.638821, -5.216269, 41.4],
  [55.638902, -5.216267, 41.6],
  [55.638977, -5.216266, 41.6],
  [55.639063, -5.216266, 41.6],
  [55.639138, -5.216304, 41.6],
  [55.639186, -5.216421, 41.8],
  [55.639207, -5.216567, 42],
  [55.639207, -5.216716, 42],
  [55.639207, -5.216868, 42],
  [55.639207, -5.217023, 42],
  [55.639202, -5.21718, 42],
  [55.639202, -5.217339, 42],
  [55.639202, -5.217501, 42],
  [55.639207, -5.217664, 42],
  [55.639207, -5.21783, 42],
  [55.639207, -5.217997, 41.8],
  [55.639207, -5.218166, 41.8],
  [55.639207, -5.218336, 41.8],
];

export default stream;
