import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.781144, 165.835179, 65.6],
  [-10.781253, 165.835061, 65.6],
  [-10.781371, 165.834932, 65.6],
  [-10.781449, 165.834793, 65.6],
  [-10.781488, 165.834632, 65.6],
  [-10.781491, 165.83446, 65.6],
  [-10.781439, 165.83431, 65.6],
  [-10.781338, 165.834171, 65.6],
  [-10.781206, 165.834085, 65.6],
  [-10.781053, 165.83402, 65.6],
  [-10.780888, 165.833999, 65.6],
  [-10.780756, 165.833892, 65.6],
];

export default stream;
