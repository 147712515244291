import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.650458, 166.948811, 11],
  [-11.650322, 166.94895, 11],
  [-11.650191, 166.9491, 11],
  [-11.650062, 166.94924, 11.2],
  [-11.649932, 166.94939, 11.2],
  [-11.649794, 166.94953, 11.2],
  [-11.649645, 166.949648, 11.2],
  [-11.649468, 166.949723, 11.2],
  [-11.64929, 166.949669, 11],
  [-11.649168, 166.949508, 11],
  [-11.649082, 166.949347, 10.8],
  [-11.649009, 166.949165, 10.6],
  [-11.64894, 166.948982, 10.4],
  [-11.648871, 166.9488, 10.2],
  [-11.6488, 166.948618, 10],
  [-11.64871, 166.948425, 10],
  [-11.648563, 166.948317, 9.8],
  [-11.648395, 166.948403, 10],
  [-11.648273, 166.948564, 10],
  [-11.64817, 166.948714, 10.2],
  [-11.648072, 166.948907, 10.4],
  [-11.647975, 166.949068, 10.6],
  [-11.647876, 166.949219, 10.8],
  [-11.647757, 166.949379, 10.8],
  [-11.647595, 166.949465, 11],
  [-11.647418, 166.949401, 10.6],
  [-11.647271, 166.949283, 10.4],
];

export default stream;
