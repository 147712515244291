import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [40.787762, -73.958673, 56.2],
  [40.787591, -73.958802, 56.2],
  [40.787419, -73.958926, 56.2],
  [40.787247, -73.959049, 56],
  [40.787076, -73.959167, 55.6],
  [40.786901, -73.959301, 55.4],
  [40.78673, -73.959419, 55],
  [40.786558, -73.959543, 54.8],
  [40.786381, -73.959655, 54.6],
  [40.786201, -73.959762, 54.4],
  [40.786024, -73.95987, 54.4],
  [40.785845, -73.959972, 54.6],
  [40.78566, -73.960068, 54.6],
  [40.785483, -73.960154, 54.8],
  [40.785297, -73.96024, 55],
  [40.78512, -73.960315, 55.2],
  [40.784935, -73.96039, 55.4],
  [40.784753, -73.960465, 55.6],
  [40.784573, -73.960535, 56],
  [40.784394, -73.96061, 56.2],
  [40.784219, -73.96068, 56.4],
  [40.784039, -73.960755, 56.6],
  [40.78386, -73.960825, 56.8],
  [40.78368, -73.9609, 57.2],
  [40.783508, -73.960975, 57.4],
  [40.783329, -73.961061, 57.6],
  [40.783154, -73.961136, 57.8],
  [40.782983, -73.961222, 58],
  [40.782811, -73.961307, 58.4],
  [40.782639, -73.961415, 58.8],
  [40.782478, -73.961511, 59.2],
  [40.782315, -73.961624, 59.6],
  [40.78217, -73.961763, 60.2],
  [40.782044, -73.96194, 61],
  [40.78195, -73.962128, 62],
  [40.781888, -73.962343, 62.8],
  [40.78187, -73.962573, 63],
  [40.78188, -73.962809, 62.2],
  [40.781915, -73.963035, 61.4],
  [40.781955, -73.963282, 60.4],
  [40.782004, -73.963518, 59.6],
  [40.782049, -73.963764, 59.4],
  [40.782108, -73.964006, 60.4],
  [40.782226, -73.964199, 61.8],
  [40.782379, -73.964317, 62.8],
  [40.782559, -73.964344, 62],
  [40.782747, -73.964295, 60.6],
  [40.782934, -73.964242, 58.8],
  [40.783133, -73.964183, 57],
  [40.783334, -73.964183, 55],
  [40.783506, -73.964333, 53.4],
  [40.783624, -73.964569, 52.6],
  [40.783704, -73.964821, 51.8],
  [40.783723, -73.965105, 50.8],
  [40.783643, -73.965358, 50.2],
  [40.783479, -73.965535, 49.8],
  [40.783288, -73.965647, 49.8],
  [40.783106, -73.96576, 50],
  [40.782934, -73.965905, 50.6],
  [40.782782, -73.966082, 51.8],
  [40.782642, -73.966259, 53.2],
  [40.782519, -73.96643, 54.8],
  [40.782398, -73.966618, 56.6],
  [40.782304, -73.966806, 58.8],
  [40.78225, -73.96701, 61],
  [40.782253, -73.967224, 63],
];

export default stream;
