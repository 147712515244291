import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.718355, 166.966803, 13.8],
  [-11.718282, 166.966878, 13.8],
  [-11.718217, 166.966964, 13.8],
  [-11.718137, 166.96705, 13.8],
  [-11.718039, 166.967093, 13.8],
  [-11.717931, 166.967103, 13.8],
  [-11.717823, 166.967093, 13.8],
  [-11.717724, 166.967061, 13.8],
  [-11.717638, 166.966985, 13.8],
  [-11.717569, 166.966889, 13.8],
];

export default stream;
