import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [40.774628, -73.975292, 24.4],
  [40.774579, -73.975384, 24.8],
  [40.774528, -73.975475, 25.4],
  [40.774477, -73.975555, 25.8],
  [40.774426, -73.975636, 26.2],
  [40.77437, -73.9757, 26.6],
  [40.774311, -73.975765, 27],
  [40.774252, -73.975818, 27.6],
  [40.77419, -73.975861, 28],
  [40.774134, -73.975909, 28.6],
  [40.774075, -73.975947, 29],
  [40.774019, -73.975979, 29.4],
  [40.773965, -73.976017, 29.8],
  [40.773909, -73.976054, 30],
  [40.773855, -73.976081, 30.4],
  [40.773796, -73.976113, 30.6],
  [40.77374, -73.976151, 30.6],
  [40.773683, -73.976183, 30.8],
  [40.773622, -73.976215, 30.8],
  [40.773565, -73.976237, 31],
  [40.773523, -73.976258, 31],
  [40.773485, -73.976279, 31],
  [40.773453, -73.976296, 31],
  [40.773431, -73.976312, 31.2],
  [40.773413, -73.976322, 31.2],
  [40.773394, -73.976333, 31.2],
  [40.773378, -73.976344, 31.2],
  [40.773362, -73.976349, 31.2],
  [40.773351, -73.97636, 31.4],
  [40.773335, -73.976365, 31.4],
  [40.773324, -73.976376, 31.4],
  [40.773313, -73.976381, 31.4],
  [40.773305, -73.976387, 31.4],
  [40.773295, -73.976387, 31.4],
  [40.773281, -73.976398, 31.4],
  [40.77327, -73.976403, 31.4],
  [40.77326, -73.976414, 31.4],
  [40.773249, -73.976414, 31.4],
  [40.773233, -73.976419, 31.4],
  [40.773222, -73.976424, 31.4],
  [40.773206, -73.976435, 31.4],
  [40.773187, -73.97644, 31.4],
  [40.773169, -73.976451, 31.4],
  [40.77315, -73.976467, 31.4],
  [40.773128, -73.976473, 31.2],
  [40.773104, -73.976478, 31.2],
  [40.77308, -73.976494, 31.2],
  [40.77305, -73.97651, 31],
  [40.773018, -73.976526, 30.8],
  [40.772983, -73.976542, 30.8],
  [40.772946, -73.976558, 30.6],
  [40.772906, -73.976575, 30.4],
  [40.772863, -73.976585, 30.2],
  [40.772817, -73.976612, 29.8],
  [40.772763, -73.976623, 29.6],
  [40.77271, -73.97665, 29.4],
  [40.772656, -73.976671, 29],
  [40.772595, -73.976693, 28.6],
  [40.772536, -73.976725, 28.2],
  [40.772476, -73.976746, 28],
  [40.77242, -73.976778, 27.6],
  [40.772367, -73.976811, 27.4],
  [40.772313, -73.976837, 27],
  [40.772265, -73.976864, 26.8],
  [40.772219, -73.976891, 26.4],
  [40.772176, -73.976923, 26.2],
  [40.772133, -73.976955, 26],
  [40.772096, -73.976982, 25.8],
  [40.772061, -73.977014, 25.6],
  [40.772026, -73.977047, 25.4],
  [40.771996, -73.977068, 25.2],
  [40.77197, -73.9771, 25],
  [40.771943, -73.977122, 24.8],
  [40.771916, -73.977143, 24.6],
  [40.771894, -73.977175, 24.6],
  [40.77187, -73.977191, 24.4],
  [40.771852, -73.977213, 24.2],
  [40.771833, -73.977234, 24.2],
  [40.771817, -73.97725, 24],
  [40.771798, -73.977272, 23.8],
  [40.771787, -73.977288, 23.8],
  [40.771771, -73.977299, 23.8],
  [40.771763, -73.977309, 23.6],
  [40.771752, -73.977326, 23.6],
  [40.771744, -73.977336, 23.6],
  [40.771736, -73.977342, 23.4],
  [40.771734, -73.977352, 23.4],
  [40.771731, -73.977352, 23.4],
  [40.771725, -73.977358, 23.4],
  [40.771717, -73.977358, 23.4],
  [40.771709, -73.977374, 23.4],
  [40.771699, -73.97739, 23.2],
  [40.771691, -73.97739, 23.2],
  [40.771683, -73.977406, 23.2],
  [40.771672, -73.977411, 23],
  [40.77165, -73.977444, 23],
  [40.771624, -73.97747, 22.8],
  [40.771589, -73.977508, 22.6],
  [40.771551, -73.977551, 22.4],
  [40.771506, -73.977605, 22.2],
  [40.77146, -73.977653, 22],
  [40.771409, -73.977717, 21.8],
  [40.771355, -73.977776, 21.6],
  [40.771302, -73.977841, 21.4],
  [40.771245, -73.977905, 21.4],
  [40.771192, -73.977975, 21.4],
  [40.771135, -73.97805, 21.4],
  [40.771076, -73.97813, 21.4],
  [40.77102, -73.978205, 21.4],
  [40.770964, -73.97828, 21.6],
  [40.770905, -73.978366, 21.8],
  [40.770848, -73.978441, 21.8],
  [40.770792, -73.978527, 22],
  [40.770733, -73.978602, 22.2],
  [40.770677, -73.978677, 22.4],
  [40.77062, -73.978763, 22.6],
  [40.770559, -73.978838, 22.8],
  [40.770497, -73.978908, 23],
  [40.770435, -73.978983, 23],
  [40.770371, -73.979053, 23.2],
  [40.770307, -73.979123, 23.4],
  [40.770242, -73.979187, 23.4],
  [40.770172, -73.979257, 23.6],
  [40.770105, -73.979326, 23.8],
  [40.770038, -73.979385, 24],
  [40.769966, -73.979455, 24],
  [40.769899, -73.979514, 24.2],
  [40.769826, -73.979579, 24.4],
  [40.769757, -73.979638, 24.4],
  [40.769682, -73.979702, 24.6],
  [40.769609, -73.979761, 24.6],
  [40.769531, -73.979815, 24.8],
  [40.769456, -73.979868, 24.8],
  [40.769376, -73.979922, 24.8],
  [40.769295, -73.979965, 24.6],
  [40.769207, -73.980008, 24.6],
  [40.769113, -73.980035, 24.4],
  [40.769016, -73.980051, 24.4],
  [40.768923, -73.980051, 24.6],
  [40.768831, -73.980018, 24.8],
  [40.768737, -73.979965, 25],
  [40.768654, -73.979911, 25],
  [40.768571, -73.979852, 24.8],
  [40.768491, -73.979782, 24.2],
];

export default stream;
