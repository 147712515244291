import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.665544, 166.951407, 79],
  [-11.665622, 166.951557, 79.2],
  [-11.665698, 166.951708, 79.4],
  [-11.665797, 166.951858, 79.4],
  [-11.66591, 166.951997, 79.4],
  [-11.666039, 166.952126, 79.4],
  [-11.666188, 166.952233, 79.4],
  [-11.66635, 166.952308, 79.6],
  [-11.666511, 166.952384, 79.8],
  [-11.666662, 166.952491, 80],
  [-11.666779, 166.95263, 80.2],
  [-11.666835, 166.952791, 80.4],
  [-11.66687, 166.952974, 80.6],
  [-11.666921, 166.953156, 80.8],
  [-11.667027, 166.953295, 81],
  [-11.667176, 166.953424, 81.4],
  [-11.667334, 166.953499, 81.8],
  [-11.667498, 166.953574, 82],
  [-11.667662, 166.95366, 82.4],
  [-11.667809, 166.953768, 82.6],
  [-11.667926, 166.953907, 82.8],
  [-11.667999, 166.954079, 83],
  [-11.668046, 166.95424, 83],
  [-11.668085, 166.954422, 83],
  [-11.66814, 166.954594, 83],
  [-11.668236, 166.954744, 83],
  [-11.668351, 166.954883, 83],
  [-11.668477, 166.955023, 83.2],
  [-11.668583, 166.955162, 83.2],
  [-11.668651, 166.955323, 83],
  [-11.668698, 166.955506, 83],
  [-11.668734, 166.955688, 82.8],
  [-11.668784, 166.95586, 82.4],
  [-11.668824, 166.956042, 82.2],
  [-11.668823, 166.956246, 82.4],
  [-11.66881, 166.956428, 82.6],
  [-11.668816, 166.956611, 83],
  [-11.668842, 166.956804, 83.2],
  [-11.668836, 166.956986, 83.2],
  [-11.6688, 166.957169, 82.8],
  [-11.668764, 166.957351, 82.4],
  [-11.668757, 166.957555, 82.6],
  [-11.668791, 166.957737, 83],
  [-11.668873, 166.957909, 83],
  [-11.668983, 166.958059, 83.2],
  [-11.669119, 166.958199, 83.4],
  [-11.669294, 166.958252, 83.6],
  [-11.66948, 166.958252, 84],
  [-11.669661, 166.958284, 84.4],
  [-11.66983, 166.958359, 84.6],
  [-11.669947, 166.958499, 85.2],
  [-11.670074, 166.958638, 85.4],
  [-11.670212, 166.958756, 85.2],
  [-11.670293, 166.958928, 85.6],
  [-11.670329, 166.95911, 86],
  [-11.670332, 166.959293, 85.8],
  [-11.670271, 166.959475, 85.4],
  [-11.670137, 166.959604, 86],
  [-11.669964, 166.959658, 86.2],
  [-11.669798, 166.959733, 86.4],
  [-11.669696, 166.959894, 87],
  [-11.669617, 166.960055, 87.2],
  [-11.669536, 166.960226, 87.4],
  [-11.669489, 166.960387, 87.2],
  [-11.669515, 166.960591, 87],
  [-11.669578, 166.960763, 86.8],
  [-11.669675, 166.960924, 87.4],
  [-11.669821, 166.961031, 87.8],
  [-11.66998, 166.961128, 88],
  [-11.670113, 166.961267, 88],
  [-11.670249, 166.961385, 87.8],
  [-11.670399, 166.961492, 87.6],
  [-11.670569, 166.961578, 88],
  [-11.670717, 166.961675, 88.6],
  [-11.670772, 166.961857, 88.8],
  [-11.670742, 166.962039, 89],
  [-11.670663, 166.962211, 89],
  [-11.670609, 166.962394, 89.2],
  [-11.670586, 166.962576, 89.8],
  [-11.670576, 166.962758, 90.2],
  [-11.670588, 166.962941, 90.8],
  [-11.670632, 166.963123, 91],
  [-11.6707, 166.963295, 91],
  [-11.670788, 166.963445, 91],
  [-11.670915, 166.963584, 90.6],
  [-11.671022, 166.963735, 90.2],
  [-11.671048, 166.963917, 90.4],
  [-11.670991, 166.964099, 90.6],
  [-11.670871, 166.964239, 91],
  [-11.670829, 166.964421, 91.4],
  [-11.670936, 166.964561, 91.6],
  [-11.671115, 166.964571, 91.8],
  [-11.67125, 166.964453, 91.8],
  [-11.671402, 166.964357, 92],
  [-11.671549, 166.964464, 92.4],
  [-11.671659, 166.964614, 93],
  [-11.671814, 166.964711, 93.2],
  [-11.671993, 166.964754, 93.2],
  [-11.672161, 166.964818, 93.4],
  [-11.672263, 166.964968, 93.4],
  [-11.67228, 166.965151, 93.8],
  [-11.672211, 166.965322, 94.4],
  [-11.672091, 166.965462, 94.8],
  [-11.671949, 166.965569, 95.2],
  [-11.67191, 166.965752, 95.2],
  [-11.671958, 166.965934, 95],
  [-11.67207, 166.966074, 94.8],
  [-11.672205, 166.966202, 94.4],
  [-11.672299, 166.966363, 94.4],
  [-11.672347, 166.966546, 94.8],
  [-11.672432, 166.966707, 95.2],
  [-11.672581, 166.966814, 95.6],
  [-11.672762, 166.966846, 96],
  [-11.672939, 166.966878, 96.4],
  [-11.673101, 166.966964, 96.8],
  [-11.6732, 166.967125, 96.6],
  [-11.673235, 166.967307, 96.4],
  [-11.6732, 166.96749, 97],
  [-11.673169, 166.967651, 97.8],
  [-11.673226, 166.967844, 98.2],
  [-11.673306, 166.968005, 98.6],
  [-11.673348, 166.968176, 99],
  [-11.673371, 166.968359, 99.6],
  [-11.673396, 166.96853, 100],
  [-11.673444, 166.968713, 100.4],
  [-11.673511, 166.968874, 100.4],
  [-11.673577, 166.969045, 100],
  [-11.673613, 166.969228, 99],
];

export default stream;
