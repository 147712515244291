import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [55.638226, -5.22226, 35.8],
  [55.638226, -5.222074, 35.6],
  [55.638226, -5.221887, 35.6],
  [55.638226, -5.2217, 35.6],
  [55.638226, -5.221512, 35.6],
  [55.638226, -5.221326, 35.8],
  [55.638226, -5.221142, 36],
  [55.63822, -5.220961, 36.2],
  [55.63822, -5.220783, 36.6],
  [55.638226, -5.220611, 37],
  [55.638252, -5.220448, 37.2],
  [55.638306, -5.220315, 37.4],
  [55.638392, -5.220238, 37.4],
  [55.638489, -5.220225, 37.4],
  [55.63858, -5.220228, 37.4],
  [55.638671, -5.220231, 37.6],
  [55.638773, -5.220233, 37.6],
  [55.638869, -5.220232, 37.6],
  [55.638961, -5.220231, 37.6],
  [55.639057, -5.220231, 37.6],
  [55.639159, -5.220234, 37.4],
  [55.639256, -5.220246, 37.2],
  [55.639358, -5.220265, 37],
  [55.639459, -5.220292, 37.2],
  [55.639556, -5.220325, 37.2],
  [55.639658, -5.22036, 37.4],
  [55.639755, -5.220397, 37.6],
  [55.639851, -5.220435, 37.8],
  [55.639948, -5.220472, 38],
  [55.640044, -5.220509, 38.2],
  [55.640135, -5.220545, 38.4],
  [55.640227, -5.22059, 38.6],
  [55.640318, -5.220641, 38.8],
  [55.640404, -5.22068, 39],
  [55.640495, -5.220683, 39.2],
  [55.640586, -5.220633, 39.4],
  [55.64065, -5.220532, 39.6],
  [55.640704, -5.220401, 39.8],
  [55.640731, -5.220253, 40.2],
  [55.640747, -5.2201, 40.6],
  [55.640747, -5.219951, 41.2],
  [55.640736, -5.219808, 41.6],
  [55.640731, -5.219668, 41.8],
  [55.640731, -5.219527, 42],
  [55.640731, -5.219383, 42],
  [55.640731, -5.219236, 42],
  [55.640731, -5.219087, 42],
  [55.640731, -5.218934, 42],
  [55.640731, -5.218779, 42],
];

export default stream;
