import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.635511, 166.952212, 1.6],
  [-11.635566, 166.952416, 1.6],
  [-11.63566, 166.952577, 1.6],
  [-11.635785, 166.952716, 1.6],
  [-11.635926, 166.952856, 1.6],
  [-11.636073, 166.952974, 1.6],
  [-11.636225, 166.953102, 1.6],
  [-11.636379, 166.95321, 1.6],
  [-11.636539, 166.953328, 1.6],
  [-11.636695, 166.953435, 1.6],
  [-11.636851, 166.953542, 1.6],
  [-11.637007, 166.95366, 1.6],
  [-11.637155, 166.953789, 1.6],
  [-11.637294, 166.953918, 1.6],
  [-11.637394, 166.954079, 1.6],
];

export default stream;
