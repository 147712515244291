import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.777791, 165.830812, 66.8],
  [-10.777819, 165.830855, 66.8],
  [-10.777842, 165.830909, 66.8],
  [-10.777861, 165.830963, 66.8],
  [-10.777877, 165.831016, 67],
  [-10.77789, 165.83107, 67],
  [-10.777903, 165.831124, 67.2],
  [-10.777914, 165.831167, 67.4],
  [-10.777927, 165.831209, 67.4],
  [-10.77794, 165.831252, 67.4],
  [-10.777955, 165.831306, 67.4],
  [-10.777971, 165.831349, 67.6],
  [-10.777987, 165.831392, 67.6],
  [-10.778002, 165.831445, 67.6],
  [-10.778018, 165.831467, 67.6],
  [-10.778035, 165.831521, 67.4],
  [-10.778053, 165.831563, 67.4],
  [-10.778075, 165.831606, 67.4],
  [-10.778098, 165.831649, 67.4],
  [-10.778124, 165.831681, 67.4],
  [-10.778151, 165.831735, 67.4],
  [-10.778179, 165.831778, 67.4],
  [-10.778206, 165.83181, 67.4],
  [-10.778236, 165.831853, 67.6],
  [-10.778265, 165.831885, 67.6],
  [-10.778296, 165.831928, 67.6],
  [-10.778328, 165.83196, 67.6],
  [-10.77836, 165.831993, 67.6],
  [-10.778395, 165.832036, 67.6],
  [-10.778431, 165.832068, 67.4],
  [-10.77847, 165.8321, 67.4],
  [-10.778509, 165.832132, 67.4],
  [-10.778553, 165.832154, 67.4],
  [-10.7786, 165.832175, 67.2],
  [-10.778649, 165.832207, 67.2],
  [-10.778701, 165.832218, 67],
  [-10.778755, 165.832229, 66.8],
  [-10.778812, 165.83225, 66.8],
  [-10.77887, 165.832261, 66.6],
  [-10.778929, 165.832282, 66.4],
  [-10.778991, 165.832282, 66.4],
  [-10.779055, 165.832282, 66.2],
  [-10.779121, 165.832293, 66],
  [-10.779186, 165.832293, 65.8],
  [-10.779252, 165.832314, 65.8],
  [-10.779316, 165.832336, 65.8],
  [-10.77938, 165.832357, 65.6],
  [-10.779442, 165.83239, 65.6],
  [-10.779504, 165.832422, 65.6],
  [-10.779563, 165.832443, 65.6],
  [-10.779622, 165.832475, 65.6],
  [-10.779677, 165.832508, 65.6],
  [-10.779729, 165.832551, 65.6],
  [-10.77978, 165.832583, 65.6],
  [-10.779828, 165.832626, 65.6],
  [-10.779878, 165.832679, 65.6],
  [-10.779929, 165.832722, 65.6],
  [-10.77998, 165.832754, 65.6],
  [-10.780031, 165.832787, 65.6],
  [-10.780076, 165.832829, 65.4],
  [-10.780113, 165.832894, 65.4],
  [-10.780146, 165.832937, 65.4],
  [-10.78018, 165.83298, 65.4],
  [-10.780214, 165.833044, 65.6],
  [-10.780248, 165.833087, 65.6],
  [-10.780281, 165.83313, 65.6],
  [-10.780312, 165.833194, 65.6],
  [-10.780341, 165.833237, 65.6],
  [-10.780366, 165.833291, 65.6],
  [-10.780393, 165.833344, 65.6],
  [-10.780419, 165.833398, 65.6],
  [-10.780445, 165.833452, 65.6],
  [-10.780476, 165.833505, 65.6],
  [-10.78051, 165.833548, 65.6],
  [-10.780548, 165.833591, 65.6],
  [-10.780585, 165.833634, 65.6],
  [-10.780618, 165.833688, 65.6],
  [-10.780644, 165.833731, 65.6],
  [-10.780664, 165.833795, 65.6],
];

export default stream;
