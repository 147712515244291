import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.784206, 165.838151, 65.8],
  [-10.784307, 165.838097, 65.8],
  [-10.784407, 165.838033, 65.8],
  [-10.784506, 165.837958, 65.8],
  [-10.784603, 165.837893, 65.8],
  [-10.7847, 165.837818, 65.8],
  [-10.784804, 165.837754, 65.6],
  [-10.784925, 165.837743, 65.4],
  [-10.785042, 165.837775, 65.2],
  [-10.785154, 165.837818, 65.2],
  [-10.78526, 165.837893, 65.2],
  [-10.785367, 165.837936, 65.2],
  [-10.785484, 165.83799, 65.2],
  [-10.785604, 165.838012, 65.4],
  [-10.785723, 165.838033, 65.4],
  [-10.785845, 165.838033, 65.6],
  [-10.785964, 165.838012, 65.8],
  [-10.786082, 165.838012, 65.8],
  [-10.7862, 165.838001, 66],
  [-10.786316, 165.83799, 66],
  [-10.786434, 165.837969, 66.2],
  [-10.78655, 165.837958, 66.2],
  [-10.786668, 165.837936, 66],
  [-10.786787, 165.837926, 65.8],
  [-10.786907, 165.837915, 65.6],
  [-10.787031, 165.837915, 65.4],
  [-10.787153, 165.837915, 65.2],
  [-10.787277, 165.837893, 65],
  [-10.787401, 165.837883, 65],
  [-10.787523, 165.837861, 65.2],
  [-10.787645, 165.837851, 65.2],
  [-10.787765, 165.837829, 65.6],
  [-10.787886, 165.837818, 66],
  [-10.788, 165.837808, 66.6],
  [-10.788113, 165.837808, 66.8],
  [-10.788226, 165.837786, 66.8],
  [-10.788331, 165.837743, 66.6],
  [-10.788427, 165.837679, 66.4],
  [-10.788515, 165.837593, 66],
  [-10.788603, 165.837518, 66],
  [-10.788688, 165.837421, 66],
  [-10.788775, 165.837346, 66],
  [-10.788861, 165.83725, 66],
];

export default stream;
