import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [40.798303, -73.958003, 32.8],
  [40.798253, -73.957821, 34.4],
  [40.798207, -73.957627, 35.8],
  [40.798153, -73.95744, 37],
  [40.79807, -73.957279, 38.4],
  [40.79796, -73.957155, 39.8],
  [40.797823, -73.95707, 40.2],
  [40.79767, -73.957027, 39.4],
  [40.797504, -73.956994, 38.2],
  [40.797333, -73.956962, 37.4],
  [40.797158, -73.956935, 36.6],
  [40.796976, -73.956898, 35.8],
  [40.796788, -73.95685, 35],
  [40.796598, -73.956796, 34.2],
  [40.796407, -73.956828, 32.8],
  [40.796217, -73.956935, 31],
  [40.79604, -73.95707, 29.2],
  [40.795868, -73.957241, 27.8],
  [40.795731, -73.957445, 27],
  [40.795616, -73.957681, 26.2],
  [40.795525, -73.957939, 24.8],
  [40.79545, -73.958201, 23.6],
  [40.795366, -73.95847, 22.4],
  [40.79527, -73.958727, 21.4],
  [40.795128, -73.958942, 20.2],
  [40.794945, -73.959092, 19.6],
  [40.794744, -73.959199, 20],
];

export default stream;
