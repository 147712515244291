import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-21.655875, 166.205216, 1543],
  [-21.655852, 166.205264, 1543],
  [-21.655832, 166.205313, 1543],
  [-21.655816, 166.205365, 1543],
  [-21.655799, 166.205415, 1543],
  [-21.655779, 166.205464, 1543],
  [-21.655753, 166.205509, 1543],
  [-21.655721, 166.205548, 1543],
  [-21.655688, 166.20558, 1543],
  [-21.65566, 166.205605, 1543],
  [-21.655635, 166.205625, 1543],
  [-21.655617, 166.205638, 1543],
  [-21.655603, 166.205648, 1543],
  [-21.655595, 166.205654, 1543],
  [-21.655593, 166.205656, 1543],
  [-21.655591, 166.205657, 1543],
  [-21.655587, 166.20566, 1543],
  [-21.655579, 166.205665, 1543],
  [-21.655566, 166.205673, 1543],
  [-21.655549, 166.205682, 1543],
  [-21.65553, 166.205691, 1543],
  [-21.655509, 166.2057, 1543],
  [-21.655487, 166.205707, 1543],
  [-21.655468, 166.205712, 1543],
  [-21.655456, 166.205715, 1543],
  [-21.655445, 166.205718, 1543],
  [-21.655432, 166.205721, 1543],
  [-21.655418, 166.205725, 1543],
  [-21.6554, 166.205728, 1543],
  [-21.655375, 166.205732, 1543],
  [-21.655345, 166.205735, 1543],
  [-21.65531, 166.205737, 1543],
  [-21.65527, 166.205738, 1543],
  [-21.65523, 166.205737, 1543],
  [-21.655189, 166.205732, 1543],
  [-21.655152, 166.205728, 1543],
  [-21.655123, 166.205725, 1543],
  [-21.6551, 166.205722, 1543],
  [-21.655084, 166.20572, 1543],
  [-21.655075, 166.205718, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655072, 166.205717, 1543],
  [-21.655071, 166.205717, 1543],
  [-21.655069, 166.205717, 1543],
  [-21.655067, 166.205716, 1543],
  [-21.655067, 166.205716, 1543],
  [-21.655067, 166.205716, 1543],
  [-21.655067, 166.205716, 1543],
  [-21.655067, 166.205716, 1543],
  [-21.655067, 166.205716, 1543],
  [-21.655067, 166.205717, 1543],
  [-21.655067, 166.205716, 1543],
  [-21.655065, 166.205716, 1543],
  [-21.655063, 166.205715, 1543],
  [-21.655059, 166.205715, 1543],
  [-21.655054, 166.205713, 1543],
  [-21.655048, 166.205712, 1543],
  [-21.655041, 166.20571, 1543],
  [-21.655032, 166.205706, 1543],
  [-21.655027, 166.205705, 1543],
  [-21.655027, 166.205705, 1543],
  [-21.655027, 166.205705, 1543],
  [-21.655027, 166.205705, 1543],
  [-21.655027, 166.205705, 1543],
  [-21.655026, 166.205704, 1543],
  [-21.655024, 166.205704, 1543],
  [-21.655021, 166.205702, 1543],
  [-21.655016, 166.2057, 1543],
  [-21.65501, 166.205698, 1543],
  [-21.655003, 166.205694, 1543],
  [-21.654995, 166.205691, 1543],
  [-21.654986, 166.205686, 1543],
  [-21.654976, 166.20568, 1543],
  [-21.654965, 166.205674, 1543],
  [-21.654953, 166.205667, 1543],
  [-21.654941, 166.205659, 1543],
  [-21.654929, 166.205651, 1543],
  [-21.654917, 166.205642, 1543],
  [-21.654903, 166.205633, 1543],
  [-21.654889, 166.205623, 1543],
  [-21.654875, 166.205613, 1543],
  [-21.654859, 166.205601, 1543],
  [-21.654841, 166.205588, 1543],
  [-21.654822, 166.205574, 1543],
  [-21.654803, 166.205559, 1543],
  [-21.654784, 166.205545, 1543],
  [-21.654762, 166.20553, 1543],
  [-21.65474, 166.205515, 1543],
  [-21.654716, 166.205501, 1543],
  [-21.654691, 166.205489, 1543],
  [-21.654665, 166.20548, 1543],
  [-21.654636, 166.205472, 1543],
  [-21.654607, 166.205466, 1543],
  [-21.654577, 166.205463, 1543],
  [-21.654547, 166.205461, 1543],
  [-21.654518, 166.205461, 1543],
  [-21.654488, 166.205461, 1543],
  [-21.654457, 166.205462, 1543],
  [-21.654426, 166.205462, 1543],
  [-21.654394, 166.205462, 1543],
  [-21.654363, 166.205459, 1543],
  [-21.65433, 166.205454, 1543],
  [-21.654298, 166.205448, 1543],
  [-21.654266, 166.205441, 1543],
  [-21.654234, 166.205434, 1543],
  [-21.6542, 166.205426, 1543],
  [-21.654166, 166.205418, 1543],
  [-21.654131, 166.205408, 1543],
  [-21.654096, 166.205398, 1543],
  [-21.65406, 166.205386, 1543],
  [-21.654023, 166.205372, 1543],
  [-21.653985, 166.205355, 1543],
  [-21.653948, 166.205336, 1543],
  [-21.65391, 166.205313, 1543],
  [-21.653872, 166.205289, 1543],
  [-21.653833, 166.205261, 1543],
  [-21.653794, 166.205232, 1543],
  [-21.653755, 166.2052, 1543],
  [-21.653717, 166.205165, 1543],
  [-21.65368, 166.205127, 1543],
  [-21.653645, 166.205086, 1543],
  [-21.653612, 166.205042, 1543],
  [-21.653584, 166.204994, 1543],
  [-21.653558, 166.204943, 1543],
  [-21.653536, 166.204891, 1543],
  [-21.653518, 166.204838, 1543],
  [-21.653502, 166.204783, 1543],
  [-21.653489, 166.204728, 1543],
  [-21.653479, 166.204672, 1543],
  [-21.653473, 166.204614, 1543],
  [-21.65347, 166.204557, 1543],
  [-21.653471, 166.204499, 1543],
  [-21.653476, 166.204441, 1543],
  [-21.653484, 166.204383, 1543],
  [-21.653494, 166.204326, 1543],
  [-21.653507, 166.204269, 1543],
  [-21.653523, 166.204213, 1543],
  [-21.653541, 166.204157, 1543],
  [-21.653561, 166.204101, 1543],
  [-21.653583, 166.204048, 1543],
  [-21.653608, 166.203994, 1543],
  [-21.653636, 166.203943, 1543],
  [-21.653668, 166.203895, 1543],
  [-21.653704, 166.203848, 1543],
  [-21.653745, 166.203802, 1543],
  [-21.653786, 166.203759, 1543],
  [-21.653831, 166.203714, 1543],
  [-21.653876, 166.203669, 1543],
  [-21.653921, 166.203621, 1543],
  [-21.653961, 166.203572, 1543],
  [-21.653999, 166.203518, 1543],
  [-21.654034, 166.203462, 1543],
  [-21.654066, 166.203403, 1543],
  [-21.654097, 166.203344, 1543],
  [-21.654129, 166.203285, 1543],
  [-21.654161, 166.203226, 1543],
  [-21.654195, 166.203169, 1543],
  [-21.654234, 166.203115, 1543],
  [-21.654277, 166.203064, 1543],
  [-21.654326, 166.20302, 1543],
  [-21.65438, 166.202984, 1543],
  [-21.654439, 166.202954, 1543],
  [-21.654499, 166.202931, 1543],
  [-21.654562, 166.202912, 1543],
  [-21.654625, 166.202897, 1543],
  [-21.654689, 166.202886, 1543],
  [-21.654754, 166.202877, 1543],
  [-21.654817, 166.202871, 1543],
  [-21.654883, 166.202866, 1543],
  [-21.654948, 166.202862, 1543],
  [-21.655012, 166.202861, 1543],
  [-21.655078, 166.202864, 1543],
  [-21.655142, 166.202871, 1543],
  [-21.655207, 166.202884, 1543],
  [-21.655269, 166.202899, 1543],
  [-21.655331, 166.202918, 1543],
  [-21.655395, 166.20294, 1543],
  [-21.655457, 166.202966, 1543],
  [-21.655521, 166.202999, 1543],
  [-21.65558, 166.203036, 1543],
  [-21.655639, 166.203077, 1543],
  [-21.655698, 166.203121, 1543],
  [-21.655759, 166.203167, 1543],
  [-21.655821, 166.203211, 1543],
  [-21.655883, 166.203251, 1543],
  [-21.655949, 166.203293, 1543],
  [-21.656014, 166.203335, 1543],
  [-21.656084, 166.203378, 1543],
  [-21.656155, 166.203418, 1543],
  [-21.656231, 166.203453, 1543],
  [-21.656309, 166.203486, 1543],
  [-21.656389, 166.203515, 1543],
  [-21.656465, 166.203554, 1543],
  [-21.656531, 166.203612, 1543],
  [-21.656585, 166.203685, 1543],
  [-21.656628, 166.203762, 1543],
  [-21.656664, 166.203842, 1543],
  [-21.656686, 166.20392, 1543],
  [-21.656702, 166.203998, 1543],
  [-21.656707, 166.204075, 1543],
  [-21.656705, 166.204143, 1543],
  [-21.656699, 166.2042, 1543],
  [-21.656695, 166.20425, 1543],
  [-21.656685, 166.204296, 1543],
  [-21.65667, 166.204345, 1543],
  [-21.656652, 166.204395, 1543],
  [-21.656629, 166.204446, 1543],
  [-21.6566, 166.2045, 1543],
  [-21.656562, 166.204555, 1543],
  [-21.65652, 166.204605, 1543],
  [-21.656473, 166.204652, 1543],
  [-21.656427, 166.204704, 1543],
  [-21.656389, 166.204764, 1543],
];

export default stream;
