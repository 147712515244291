import { LatLngAlt } from "../../../types.js";

export const edgeSouthJunctionB1: LatLngAlt[] = [
  [40.775132, -73.974236, 20.6],
  [40.774998, -73.974418, 21.2],
  [40.774904, -73.974649, 22],
  [40.774813, -73.974869, 22.8],
  [40.774716, -73.975094, 23.6],
];
export const edgeSouthJunctionB2: LatLngAlt[] = [
  [40.775076, -73.974236, 20.6],
  [40.774971, -73.974262, 20.6],
  [40.774861, -73.974262, 20.8],
  [40.774759, -73.97422, 21.2],
  [40.774668, -73.974171, 21.4],
];
export const edgeSouthJunctionB3: LatLngAlt[] = [
  [40.774595, -73.974118, 21.6],
  [40.774695, -73.974338, 21.4],
  [40.77474, -73.974563, 21.8],
  [40.774727, -73.974788, 22.6],
  [40.774727, -73.974992, 23.2],
];
