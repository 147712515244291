import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [47.258216, 11.411583, 282.4],
  [47.258034, 11.411704, 282.6],
  [47.257862, 11.411834, 282.6],
  [47.257685, 11.41196, 282.8],
  [47.257508, 11.412079, 283],
  [47.257326, 11.412201, 283.2],
  [47.257154, 11.412315, 283.4],
  [47.256972, 11.412417, 284],
  [47.256789, 11.412496, 284.6],
  [47.256607, 11.412566, 285.2],
  [47.256424, 11.412636, 285.8],
  [47.256237, 11.412708, 286.4],
  [47.256065, 11.412781, 287],
  [47.255883, 11.41285, 287.6],
  [47.255706, 11.412919, 288.2],
  [47.255529, 11.412982, 288.8],
  [47.255352, 11.413046, 289.4],
  [47.255175, 11.413105, 290.2],
  [47.254992, 11.413165, 290.8],
  [47.25482, 11.413222, 291.6],
  [47.254643, 11.413279, 292.2],
  [47.254466, 11.413336, 293],
  [47.254295, 11.413393, 294],
];

export default stream;
