import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.744859, 165.851852, 109],
  [-10.744815, 165.852013, 109],
  [-10.744776, 165.852174, 109],
  [-10.744794, 165.852334, 109.4],
  [-10.744847, 165.852485, 109.8],
  [-10.744915, 165.852635, 110.4],
  [-10.744984, 165.852774, 111],
  [-10.745026, 165.852925, 111.4],
  [-10.745035, 165.853075, 111.6],
  [-10.745012, 165.853225, 111.4],
  [-10.744942, 165.853364, 111.4],
  [-10.74482, 165.853472, 111.6],
  [-10.744675, 165.853536, 112],
  [-10.74453, 165.853579, 112.4],
  [-10.744391, 165.853654, 112.8],
  [-10.744247, 165.853719, 113.2],
  [-10.744113, 165.853794, 113.6],
  [-10.744009, 165.853901, 114.2],
  [-10.743942, 165.85404, 114.6],
  [-10.743907, 165.854191, 115.2],
  [-10.743888, 165.854341, 115.6],
  [-10.743879, 165.854491, 116],
  [-10.743881, 165.854652, 116.4],
  [-10.743891, 165.854791, 116.8],
  [-10.743903, 165.854942, 117],
  [-10.743913, 165.855092, 117.4],
  [-10.743919, 165.855242, 117.8],
  [-10.743917, 165.855392, 118.2],
  [-10.743897, 165.855553, 118.6],
  [-10.743855, 165.855682, 119],
  [-10.743802, 165.855821, 119.4],
  [-10.743748, 165.855961, 119.8],
  [-10.743705, 165.856111, 120.4],
  [-10.7437, 165.856251, 121],
  [-10.743764, 165.85639, 121.4],
  [-10.743877, 165.856476, 122],
  [-10.744007, 165.856551, 122.4],
  [-10.744139, 165.856605, 122.8],
  [-10.744276, 165.856658, 123.2],
  [-10.744413, 165.856723, 123.6],
  [-10.744538, 165.856798, 123.8],
  [-10.744644, 165.856894, 124.2],
  [-10.744732, 165.857012, 124.4],
  [-10.744824, 165.857141, 124.6],
  [-10.744946, 165.857227, 124.6],
  [-10.74509, 165.857259, 124.8],
  [-10.745241, 165.85727, 125],
  [-10.745392, 165.857291, 125.2],
  [-10.745545, 165.857291, 125.4],
  [-10.745694, 165.857323, 125.8],
  [-10.745841, 165.857366, 126.2],
  [-10.745987, 165.857399, 126.4],
  [-10.746136, 165.857431, 126.8],
  [-10.746287, 165.857399, 127.2],
  [-10.746415, 165.857334, 127.6],
  [-10.746517, 165.857216, 127.8],
  [-10.746604, 165.857087, 128],
  [-10.746689, 165.856969, 128.2],
  [-10.746788, 165.856851, 128.4],
  [-10.746905, 165.856755, 128.6],
  [-10.747027, 165.856658, 129],
  [-10.747153, 165.856572, 129.2],
  [-10.74728, 165.856476, 129.4],
  [-10.74741, 165.856401, 129.6],
  [-10.747546, 165.856326, 129.8],
  [-10.747695, 165.856293, 130.2],
  [-10.747833, 165.85624, 130.2],
  [-10.747904, 165.8561, 130.4],
  [-10.747931, 165.85595, 130.4],
  [-10.747938, 165.855778, 130.4],
  [-10.747933, 165.855628, 130.6],
  [-10.74791, 165.855457, 130.6],
  [-10.747874, 165.855306, 130.6],
  [-10.747836, 165.855156, 130.6],
  [-10.747803, 165.854995, 130.6],
];

export default stream;
