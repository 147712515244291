import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.651022, 166.962426, -5.4],
  [-11.650843, 166.962361, -5.4],
  [-11.650668, 166.962286, -5.4],
  [-11.650491, 166.962222, -5.4],
  [-11.650313, 166.962147, -5.4],
  [-11.650131, 166.962082, -5.4],
  [-11.649954, 166.962029, -5.4],
  [-11.649775, 166.961964, -5.4],
  [-11.649595, 166.961889, -5.4],
  [-11.649417, 166.961825, -5.4],
  [-11.649233, 166.961782, -5.4],
  [-11.649051, 166.961718, -5.4],
  [-11.648867, 166.961675, -5.4],
  [-11.648681, 166.961643, -5.4],
  [-11.648494, 166.9616, -5.4],
  [-11.648308, 166.961567, -5.4],
  [-11.648123, 166.961524, -5.4],
  [-11.647934, 166.961492, -5.4],
  [-11.647743, 166.961471, -5.4],
  [-11.647556, 166.961492, -5.4],
  [-11.647368, 166.961524, -5.4],
  [-11.64719, 166.961578, -5.4],
  [-11.647027, 166.961685, -5.4],
  [-11.646885, 166.961814, -5.4],
  [-11.646752, 166.961954, -5.4],
  [-11.64663, 166.962104, -5.4],
  [-11.646515, 166.962254, -5.4],
  [-11.6464, 166.962404, -5.4],
  [-11.646286, 166.962554, -5.4],
  [-11.646172, 166.962715, -5.4],
  [-11.646056, 166.962866, -5.4],
  [-11.645933, 166.963005, -5.4],
  [-11.645797, 166.963134, -5.4],
  [-11.645642, 166.963263, -5.4],
  [-11.64548, 166.963348, -5.4],
  [-11.645309, 166.963445, -5.4],
  [-11.645132, 166.963509, -5.4],
  [-11.644943, 166.963531, -5.4],
  [-11.644754, 166.963552, -5.4],
  [-11.644566, 166.963531, -5.4],
  [-11.644379, 166.963509, -5.4],
  [-11.644198, 166.963456, -5.4],
  [-11.644022, 166.963381, -5.4],
  [-11.643853, 166.963295, -5.4],
  [-11.643696, 166.963187, -5.4],
  [-11.643546, 166.963059, -5.4],
  [-11.643409, 166.96293, -5.4],
  [-11.643287, 166.96279, -5.4],
  [-11.643188, 166.962619, -5.4],
  [-11.643103, 166.962447, -5.4],
  [-11.643024, 166.962265, -5.4],
  [-11.642948, 166.962104, -5.4],
  [-11.642869, 166.961921, -5.4],
  [-11.642781, 166.96175, -5.4],
  [-11.642682, 166.961578, -5.4],
  [-11.642559, 166.961449, -5.4],
  [-11.642413, 166.961321, -5.6],
  [-11.642249, 166.961213, -5.6],
  [-11.642072, 166.961138, -5.8],
  [-11.64189, 166.961095, -5.8],
  [-11.641707, 166.961031, -6],
  [-11.641519, 166.960999, -5.8],
  [-11.641333, 166.960956, -5.6],
  [-11.641148, 166.960913, -5.2],
  [-11.640964, 166.960881, -4.4],
  [-11.64078, 166.960849, -3.8],
  [-11.640602, 166.960816, -3.2],
  [-11.640424, 166.960773, -2.4],
  [-11.640248, 166.960741, -1.8],
  [-11.640071, 166.960709, -1.2],
  [-11.639897, 166.960677, -0.6],
  [-11.639726, 166.960655, 0],
  [-11.639555, 166.960623, 0.6],
  [-11.639382, 166.96058, 1],
  [-11.639211, 166.960527, 1],
  [-11.639044, 166.960484, 0.8],
  [-11.638869, 166.960419, 0.6],
  [-11.638698, 166.960377, 0.6],
  [-11.638525, 166.960301, 0.6],
  [-11.638354, 166.960237, 0.6],
  [-11.638188, 166.960162, 0.6],
  [-11.638023, 166.960076, 0.6],
  [-11.637862, 166.95998, 0.6],
  [-11.637707, 166.959872, 0.6],
  [-11.637558, 166.959754, 0.6],
  [-11.637422, 166.959625, 0.6],
  [-11.6373, 166.959475, 0.6],
  [-11.637188, 166.959325, 0.6],
  [-11.637084, 166.959175, 0.6],
];

export default stream;
