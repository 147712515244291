import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.799347, 165.840865, 65],
  [-10.799437, 165.84079, 65],
  [-10.799536, 165.840737, 65],
  [-10.799638, 165.840672, 65],
  [-10.799746, 165.840608, 65],
  [-10.79986, 165.840576, 65],
  [-10.799978, 165.840554, 64.8],
  [-10.800095, 165.840533, 64.8],
  [-10.800213, 165.840501, 65],
  [-10.800328, 165.840468, 65.4],
  [-10.800422, 165.840415, 65.8],
  [-10.800482, 165.840307, 66],
  [-10.800521, 165.8402, 66.4],
  [-10.800551, 165.840093, 66.8],
  [-10.800576, 165.839986, 67.2],
  [-10.800607, 165.839878, 67.4],
  [-10.800656, 165.839782, 67.8],
  [-10.800726, 165.839707, 68.2],
  [-10.80082, 165.839664, 68.4],
  [-10.800924, 165.839653, 68.6],
  [-10.801028, 165.839664, 68.8],
  [-10.801129, 165.839696, 68.8],
  [-10.801226, 165.839728, 68.8],
  [-10.801324, 165.839771, 68.8],
  [-10.801419, 165.839835, 68.8],
  [-10.801519, 165.839878, 68.8],
  [-10.801619, 165.839921, 68.8],
  [-10.801744, 165.839986, 68.8],
  [-10.801823, 165.840018, 68.8],
  [-10.801927, 165.840061, 69],
  [-10.802033, 165.840104, 69],
  [-10.802141, 165.840136, 69],
  [-10.80225, 165.840168, 68.8],
  [-10.802361, 165.8402, 68.6],
  [-10.802476, 165.840232, 68.4],
  [-10.802594, 165.840243, 68.2],
  [-10.802715, 165.840243, 67.8],
  [-10.802838, 165.840232, 67.6],
  [-10.802957, 165.8402, 67.4],
  [-10.80307, 165.840157, 67.2],
  [-10.803179, 165.840071, 67],
  [-10.803281, 165.840018, 67],
  [-10.80338, 165.839921, 67],
  [-10.803471, 165.839846, 67.2],
  [-10.803554, 165.83976, 67.4],
  [-10.803629, 165.839653, 67.6],
  [-10.803697, 165.839556, 67.8],
  [-10.803755, 165.839449, 68],
  [-10.803804, 165.839342, 68.4],
  [-10.803845, 165.839224, 68.6],
  [-10.803882, 165.839117, 68.8],
  [-10.803917, 165.839009, 68.8],
  [-10.803953, 165.838902, 68.8],
  [-10.803988, 165.838784, 68.8],
  [-10.804018, 165.838677, 68.4],
  [-10.804047, 165.838548, 68],
  [-10.804082, 165.83843, 67.8],
  [-10.804123, 165.838323, 67.6],
  [-10.804165, 165.838205, 67.4],
  [-10.804208, 165.838076, 67.2],
  [-10.80425, 165.837958, 67],
  [-10.80429, 165.837829, 67],
  [-10.804325, 165.837711, 67],
  [-10.804362, 165.837604, 67.2],
  [-10.804409, 165.837486, 67.2],
  [-10.804472, 165.837379, 67.2],
  [-10.804547, 165.837271, 67],
  [-10.804624, 165.837175, 66.8],
  [-10.80471, 165.837089, 66.6],
  [-10.804802, 165.836992, 66.4],
  [-10.804897, 165.836906, 66.2],
];

export default stream;
