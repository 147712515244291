import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.636993, 166.959003, 0.6],
  [-11.636919, 166.958832, 0.4],
  [-11.63686, 166.958649, 0.4],
  [-11.636815, 166.958456, 0.4],
  [-11.63678, 166.958274, 0.4],
  [-11.636764, 166.958091, 0.8],
  [-11.636766, 166.957887, 1],
  [-11.63678, 166.957705, 1.4],
  [-11.636801, 166.957523, 2],
  [-11.636836, 166.95734, 2.4],
  [-11.636878, 166.957158, 3],
  [-11.636918, 166.956975, 3.6],
  [-11.636962, 166.956793, 4],
  [-11.637, 166.956621, 4.2],
  [-11.637031, 166.956439, 4.4],
  [-11.637063, 166.956257, 4.4],
  [-11.637095, 166.956074, 4.6],
  [-11.637127, 166.955892, 4.6],
  [-11.637161, 166.955709, 4.6],
];

export default stream;
