import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [37.547139, -77.448914, 53],
  [37.547222, -77.44907, 53.6],
  [37.547308, -77.44922, 54],
  [37.547394, -77.449359, 54.2],
  [37.547482, -77.449493, 54.6],
  [37.547571, -77.449633, 54.8],
  [37.547654, -77.449772, 55.2],
  [37.547737, -77.449906, 55.6],
  [37.547823, -77.450046, 56],
  [37.547903, -77.450191, 56.2],
  [37.547981, -77.45032, 56.4],
  [37.548062, -77.450459, 56.6],
  [37.548147, -77.450593, 56.8],
  [37.548225, -77.450722, 57],
  [37.548311, -77.450867, 57.2],
  [37.548392, -77.451001, 57.2],
  [37.548475, -77.451135, 57.4],
  [37.548555, -77.451274, 57.6],
  [37.548638, -77.451409, 57.6],
  [37.548719, -77.451548, 57.8],
  [37.548802, -77.451693, 57.8],
  [37.548882, -77.451822, 58],
  [37.548966, -77.451966, 58.2],
  [37.549051, -77.452106, 58.2],
  [37.549134, -77.45224, 58.4],
  [37.549218, -77.452385, 58.4],
  [37.549298, -77.452519, 58.6],
  [37.549381, -77.452664, 58.4],
  [37.549467, -77.452809, 58.2],
  [37.54955, -77.452943, 58],
  [37.549639, -77.453088, 57.8],
  [37.549725, -77.453243, 57.6],
  [37.549813, -77.453377, 57.6],
  [37.549904, -77.453533, 57.6],
  [37.549993, -77.453678, 57.4],
  [37.550084, -77.453812, 57.4],
  [37.550181, -77.453957, 57.4],
  [37.550266, -77.454112, 57.4],
  [37.550352, -77.454257, 57.6],
  [37.550438, -77.454402, 57.6],
  [37.550529, -77.454547, 57.8],
  [37.550615, -77.454692, 58],
  [37.550698, -77.454842, 58.2],
  [37.550779, -77.454987, 58.6],
  [37.550854, -77.455131, 59],
  [37.550934, -77.455276, 59.4],
  [37.551015, -77.455421, 59.6],
  [37.551095, -77.455561, 59.8],
  [37.551178, -77.455689, 59.8],
  [37.551267, -77.455834, 60],
  [37.551355, -77.455963, 60],
  [37.551441, -77.456102, 59.8],
  [37.551527, -77.456242, 60],
  [37.55161, -77.456387, 60],
  [37.551693, -77.456532, 60.2],
  [37.551776, -77.456666, 60.4],
  [37.551862, -77.456805, 60.6],
  [37.551945, -77.45695, 60.8],
  [37.552029, -77.457095, 61],
  [37.552114, -77.457224, 61],
  [37.5522, -77.457363, 61.2],
  [37.552297, -77.457492, 61.6],
  [37.552399, -77.457599, 61.8],
  [37.552514, -77.457696, 62],
  [37.552613, -77.457819, 62.2],
  [37.552694, -77.457958, 62.4],
  [37.552774, -77.458093, 62.6],
  [37.552855, -77.458232, 62.8],
  [37.552933, -77.458366, 62.8],
  [37.553018, -77.458511, 63],
  [37.553099, -77.45865, 63.2],
  [37.553182, -77.45879, 63.2],
  [37.55327, -77.458924, 63.4],
  [37.553354, -77.459053, 63.6],
  [37.553442, -77.459192, 63.6],
  [37.553525, -77.459332, 63.8],
  [37.553606, -77.459466, 63.8],
  [37.5537, -77.459595, 63.8],
  [37.553812, -77.459702, 63.8],
  [37.553933, -77.459793, 63.8],
  [37.554048, -77.459895, 64],
  [37.554129, -77.46004, 64],
  [37.554169, -77.460206, 64],
  [37.554204, -77.460383, 64],
  [37.554255, -77.460549, 64],
  [37.55433, -77.460694, 64],
  [37.554421, -77.460839, 63.8],
  [37.55451, -77.460984, 63.8],
  [37.554598, -77.461123, 63.6],
  [37.554689, -77.461268, 63.6],
  [37.554775, -77.461413, 63.4],
  [37.554866, -77.461563, 63.2],
  [37.554955, -77.461708, 63.2],
  [37.555043, -77.461853, 63],
  [37.555135, -77.462009, 63],
  [37.555223, -77.462153, 63],
  [37.555314, -77.462304, 63],
  [37.555403, -77.462448, 63],
  [37.555489, -77.462593, 63.2],
  [37.555577, -77.462749, 63.2],
  [37.555666, -77.462894, 63.2],
  [37.555752, -77.463039, 63.2],
  [37.55584, -77.463189, 63.2],
  [37.555923, -77.463334, 63.2],
  [37.556012, -77.463478, 63.4],
  [37.556095, -77.463623, 63.4],
  [37.556183, -77.463779, 63.4],
  [37.556266, -77.463924, 63.4],
  [37.556355, -77.464069, 63.6],
  [37.556444, -77.464213, 63.6],
  [37.556527, -77.464358, 63.6],
  [37.556615, -77.464508, 63.8],
  [37.556704, -77.464648, 63.8],
  [37.556787, -77.464793, 64],
  [37.556875, -77.464938, 64],
  [37.556958, -77.465082, 64.2],
  [37.557042, -77.465227, 64.2],
  [37.55713, -77.465372, 64.2],
  [37.557219, -77.465517, 64.4],
  [37.557302, -77.465656, 64.4],
  [37.55739, -77.465801, 64.4],
  [37.557476, -77.465935, 64.6],
  [37.557565, -77.46608, 64.6],
  [37.557653, -77.466225, 64.6],
  [37.557744, -77.466365, 64.6],
  [37.557828, -77.466499, 64.8],
  [37.557919, -77.466649, 64.8],
  [37.558007, -77.466794, 64.8],
  [37.55809, -77.466928, 64.8],
  [37.558182, -77.467073, 64.8],
  [37.55827, -77.467217, 64.8],
  [37.558348, -77.467373, 64.8],
  [37.558375, -77.467545, 64.8],
  [37.558262, -77.467647, 64.8],
  [37.558133, -77.467577, 64.8],
  [37.558042, -77.467437, 64.8],
  [37.557964, -77.467287, 64.8],
  [37.557884, -77.467137, 64.8],
  [37.557798, -77.466992, 64.8],
  [37.55771, -77.466842, 64.8],
  [37.557621, -77.466697, 64.8],
  [37.557535, -77.466552, 64.8],
  [37.557447, -77.466407, 64.6],
  [37.557364, -77.466263, 64.6],
  [37.557283, -77.466134, 64.6],
  [37.557211, -77.46601, 64.6],
  [37.557141, -77.465898, 64.6],
  [37.557079, -77.465801, 64.4],
  [37.557023, -77.46571, 64.4],
  [37.556975, -77.465624, 64.4],
  [37.556924, -77.465538, 64.4],
  [37.556881, -77.465469, 64.4],
  [37.556843, -77.46541, 64.2],
  [37.556806, -77.465351, 64.2],
  [37.556771, -77.465286, 64.2],
  [37.556733, -77.465227, 64.2],
  [37.556696, -77.465168, 64.2],
  [37.556661, -77.465099, 64.2],
  [37.556623, -77.465034, 64],
  [37.556583, -77.464975, 64],
  [37.556545, -77.464905, 64],
  [37.556508, -77.464846, 64],
  [37.55647, -77.464777, 64],
  [37.556435, -77.464723, 64],
  [37.556406, -77.464669, 63.8],
  [37.556382, -77.464626, 63.8],
  [37.556355, -77.464589, 63.8],
  [37.556336, -77.464557, 63.8],
  [37.55632, -77.464535, 63.8],
  [37.556309, -77.464519, 63.8],
  [37.556301, -77.464503, 63.8],
  [37.556299, -77.464492, 63.8],
  [37.556293, -77.464487, 63.8],
  [37.556283, -77.464471, 63.8],
  [37.556266, -77.464449, 63.8],
  [37.556256, -77.464428, 63.8],
  [37.556237, -77.464396, 63.8],
  [37.556221, -77.464374, 63.8],
  [37.556202, -77.464337, 63.6],
  [37.556173, -77.464288, 63.6],
  [37.556175, -77.464288, 63.6],
  [37.556143, -77.464219, 63.6],
  [37.5561, -77.464144, 63.6],
  [37.556049, -77.464058, 63.6],
  [37.555996, -77.463961, 63.6],
  [37.555939, -77.46387, 63.4],
  [37.555878, -77.463763, 63.4],
  [37.555813, -77.463655, 63.4],
  [37.555746, -77.463543, 63.4],
  [37.555676, -77.463425, 63.4],
  [37.555604, -77.463307, 63.4],
  [37.555529, -77.463183, 63.4],
  [37.555456, -77.46306, 63.2],
  [37.555379, -77.462921, 63.2],
  [37.555304, -77.462792, 63.2],
  [37.555226, -77.462663, 63.2],
  [37.555145, -77.462529, 63.2],
  [37.555065, -77.462389, 63.2],
  [37.554987, -77.46225, 63.2],
  [37.554901, -77.462116, 63.2],
  [37.554818, -77.461976, 63.2],
  [37.55473, -77.461842, 63.2],
  [37.554646, -77.461703, 63.2],
  [37.554558, -77.461563, 63.4],
  [37.554475, -77.461429, 63.4],
  [37.554386, -77.46129, 63.6],
  [37.554303, -77.461156, 63.8],
  [37.554215, -77.461016, 63.8],
  [37.554129, -77.460871, 64],
  [37.554035, -77.460743, 64],
  [37.55393, -77.460625, 64.2],
  [37.553815, -77.460528, 64.2],
  [37.553692, -77.460453, 64.2],
  [37.55359, -77.46033, 64.2],
  [37.55352, -77.460174, 64.2],
  [37.553493, -77.460002, 64],
  [37.553453, -77.459825, 63.8],
  [37.553399, -77.459659, 63.8],
  [37.553319, -77.459503, 63.8],
  [37.55323, -77.459359, 63.6],
  [37.553139, -77.459214, 63.4],
  [37.553053, -77.459064, 63.4],
  [37.552965, -77.458919, 63.2],
  [37.552876, -77.458774, 63],
  [37.552788, -77.458613, 63],
  [37.55271, -77.458457, 62.8],
  [37.552621, -77.458307, 62.6],
  [37.55253, -77.458162, 62.4],
  [37.552431, -77.458017, 62.4],
  [37.552291, -77.457948, 62.2],
  [37.552155, -77.458017, 62.2],
  [37.552029, -77.458114, 62.4],
  [37.551919, -77.458232, 62.6],
  [37.551801, -77.458345, 63],
  [37.551683, -77.458452, 63.2],
  [37.551575, -77.458575, 63.2],
  [37.551465, -77.458688, 63.4],
  [37.551347, -77.458795, 63.4],
  [37.551221, -77.458881, 63.4],
  [37.551087, -77.458956, 63.4],
  [37.550958, -77.459031, 63.4],
  [37.550827, -77.459101, 63.4],
  [37.550696, -77.459176, 63.4],
  [37.550561, -77.459246, 63.4],
  [37.55043, -77.459321, 63.4],
  [37.550293, -77.459391, 63.4],
  [37.550159, -77.459466, 63.2],
  [37.550022, -77.459536, 63.2],
  [37.549894, -77.459605, 63.2],
  [37.549757, -77.45968, 63.2],
  [37.549623, -77.45975, 63.2],
  [37.549489, -77.459825, 63],
  [37.549354, -77.459895, 63],
  [37.549218, -77.45997, 62.8],
  [37.549081, -77.460045, 62.6],
  [37.548944, -77.460126, 62.4],
  [37.54881, -77.460206, 62.2],
  [37.548676, -77.460281, 62.2],
  [37.548539, -77.460367, 62],
  [37.548405, -77.460442, 62],
  [37.548271, -77.460533, 61.8],
  [37.548139, -77.460625, 61.8],
  [37.548008, -77.460716, 61.8],
  [37.547877, -77.460802, 61.6],
  [37.547737, -77.460877, 61.6],
  [37.547598, -77.460941, 61.4],
  [37.547447, -77.460957, 61.4],
  [37.547327, -77.460861, 61.2],
  [37.547257, -77.460689, 61],
  [37.547227, -77.460507, 60.8],
  [37.547201, -77.460319, 60.8],
  [37.547155, -77.460136, 60.6],
  [37.547091, -77.459965, 60.4],
  [37.547024, -77.459793, 60.4],
  [37.546965, -77.459621, 60.4],
  [37.546903, -77.45945, 60.4],
  [37.546839, -77.459278, 60.4],
  [37.546777, -77.459101, 60.4],
  [37.546721, -77.458935, 60.4],
  [37.546653, -77.458763, 60.4],
  [37.546594, -77.458591, 60.4],
  [37.546533, -77.45842, 60.4],
  [37.546471, -77.458254, 60.2],
  [37.546415, -77.458076, 60],
  [37.546353, -77.45791, 59.8],
  [37.546291, -77.457733, 59.6],
  [37.546232, -77.457561, 59.4],
  [37.546165, -77.45739, 59],
  [37.546106, -77.457218, 58.8],
  [37.546039, -77.457041, 58.6],
  [37.545975, -77.456864, 58.4],
  [37.545919, -77.456692, 58.4],
  [37.545868, -77.456505, 58.2],
  [37.545857, -77.456317, 58.2],
  [37.545854, -77.456124, 58.2],
  [37.545854, -77.455936, 58.4],
  [37.545854, -77.455743, 58.4],
  [37.545849, -77.455561, 58.2],
  [37.545843, -77.455367, 58.2],
  [37.545838, -77.455185, 58],
  [37.545843, -77.454997, 57.8],
  [37.545843, -77.454804, 57.6],
  [37.545843, -77.454616, 57.4],
  [37.545843, -77.454423, 57],
  [37.545843, -77.454236, 56.8],
  [37.545843, -77.454042, 56.8],
  [37.545843, -77.453849, 57.2],
  [37.545838, -77.453662, 57.6],
  [37.545835, -77.453479, 57.8],
  [37.545835, -77.453297, 58.2],
  [37.545827, -77.453109, 58.4],
  [37.545822, -77.452937, 58.4],
  [37.545819, -77.452755, 58.4],
  [37.545819, -77.452573, 58.4],
  [37.545819, -77.452385, 58.4],
  [37.545822, -77.452213, 58.4],
  [37.545822, -77.452031, 58.4],
  [37.545822, -77.451843, 58.2],
  [37.545822, -77.451661, 58.2],
  [37.545822, -77.451478, 58],
  [37.545822, -77.451296, 57.6],
  [37.545822, -77.451103, 57.2],
  [37.545822, -77.450915, 56.8],
  [37.545822, -77.450722, 56.4],
  [37.545822, -77.450534, 56],
  [37.545822, -77.450336, 55.4],
  [37.545819, -77.450143, 55],
  [37.545817, -77.449939, 54.4],
  [37.545809, -77.44974, 53.8],
  [37.545801, -77.449542, 53.2],
  [37.545792, -77.449332, 52.6],
  [37.545784, -77.449139, 52.2],
  [37.545811, -77.448941, 51.8],
  [37.545956, -77.44886, 51.8],
  [37.546117, -77.448855, 52],
  [37.546273, -77.448855, 52.2],
  [37.546431, -77.448855, 52.4],
  [37.546586, -77.448839, 52.4],
  [37.546739, -77.448817, 52.4],
  [37.546887, -77.448769, 52.4],
  [37.547032, -77.448785, 52.6],
];

export default stream;
