import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [37.540669, -77.433593, 51.2],
  [37.540605, -77.433491, 51],
  [37.540541, -77.433384, 50.8],
  [37.540474, -77.433277, 50.8],
  [37.540409, -77.433159, 50.6],
  [37.540337, -77.433046, 50.4],
  [37.540264, -77.432917, 50.2],
  [37.540184, -77.432778, 50],
  [37.540101, -77.432633, 49.8],
  [37.540012, -77.432483, 49.6],
  [37.539918, -77.432327, 49.4],
  [37.539819, -77.432161, 49.2],
  [37.53972, -77.431995, 48.8],
  [37.539615, -77.431818, 48.4],
  [37.539511, -77.431641, 47.8],
  [37.539393, -77.431469, 47.2],
  [37.539243, -77.431346, 46],
  [37.539063, -77.431437, 44.2],
  [37.538899, -77.431582, 42.4],
  [37.538736, -77.431732, 40.8],
  [37.538548, -77.431839, 39.2],
  [37.538341, -77.431877, 38.2],
  [37.538132, -77.431925, 36.6],
  [37.537931, -77.432016, 35],
  [37.537724, -77.432118, 33.6],
  [37.537534, -77.432247, 32.6],
  [37.537338, -77.432376, 31.4],
  [37.537134, -77.432472, 29.6],
  [37.53693, -77.43259, 27.6],
  [37.536764, -77.432773, 26],
  [37.536566, -77.432901, 24.2],
  [37.536343, -77.432976, 22.4],
  [37.536153, -77.432832, 21],
  [37.53601, -77.432612, 19.6],
  [37.535879, -77.432376, 18.2],
  [37.535737, -77.432156, 16.8],
  [37.5356, -77.431925, 15.4],
  [37.535458, -77.431705, 14],
  [37.535313, -77.43148, 12.6],
  [37.535166, -77.43127, 11.6],
  [37.535021, -77.431045, 10.8],
  [37.534881, -77.430825, 10.2],
  [37.534744, -77.430605, 9.8],
  [37.534616, -77.430391, 9.8],
  [37.53449, -77.430171, 8.8],
  [37.534361, -77.429956, 8],
  [37.534219, -77.429752, 7.8],
  [37.534082, -77.429554, 7.8],
  [37.53394, -77.429345, 7.6],
  [37.533806, -77.429157, 7.6],
  [37.533674, -77.428958, 7.6],
  [37.533551, -77.428749, 7.8],
  [37.533436, -77.42854, 8],
  [37.533312, -77.428347, 8.2],
  [37.533186, -77.428148, 8.6],
  [37.533073, -77.42795, 8.8],
  [37.532974, -77.427735, 9.2],
  [37.53302, -77.427515, 9.6],
  [37.533165, -77.42736, 9.6],
  [37.533326, -77.427231, 9.8],
  [37.533478, -77.427086, 10],
  [37.533629, -77.426936, 10.2],
  [37.533779, -77.426797, 10.4],
  [37.533932, -77.426652, 10.6],
  [37.534082, -77.426518, 11],
  [37.534229, -77.426378, 11.2],
  [37.53438, -77.426233, 11.6],
  [37.534527, -77.426094, 12],
  [37.534677, -77.425949, 12.2],
  [37.534822, -77.425804, 12.4],
  [37.53497, -77.42567, 12.4],
  [37.53512, -77.425525, 12.6],
  [37.535257, -77.425364, 12.8],
  [37.535383, -77.425198, 12.8],
  [37.535367, -77.424967, 13.6],
  [37.535273, -77.424774, 14.2],
  [37.535158, -77.424592, 14.8],
  [37.535042, -77.42442, 15.4],
  [37.53493, -77.424243, 16],
  [37.534825, -77.424061, 17],
  [37.534718, -77.423884, 18.4],
  [37.534616, -77.423707, 19.8],
  [37.534514, -77.42354, 21.2],
  [37.534409, -77.423374, 22.4],
  [37.53431, -77.423213, 23.6],
  [37.534211, -77.423052, 24.8],
  [37.534117, -77.422891, 25.8],
  [37.53402, -77.42273, 26.8],
  [37.533921, -77.422569, 28.2],
  [37.533827, -77.422419, 29.6],
  [37.533731, -77.422264, 31.4],
  [37.533639, -77.422113, 33.2],
  [37.533548, -77.421969, 35],
  [37.53346, -77.421824, 36.8],
  [37.533371, -77.421684, 38.6],
  [37.533285, -77.42155, 40.4],
  [37.533199, -77.421411, 41.8],
  [37.533119, -77.421277, 42],
  [37.533036, -77.421121, 42.6],
  [37.532955, -77.42096, 43.2],
  [37.532872, -77.42081, 44],
  [37.532773, -77.420665, 44.6],
  [37.532631, -77.420644, 45],
  [37.532502, -77.420756, 44.8],
  [37.532373, -77.42088, 45.2],
  [37.532242, -77.421008, 46],
  [37.532116, -77.421142, 46.4],
  [37.53199, -77.421282, 46.2],
  [37.531861, -77.421421, 45.2],
  [37.531719, -77.421566, 43.4],
  [37.531574, -77.421716, 41.2],
  [37.531424, -77.421877, 38.8],
  [37.531266, -77.422044, 36.2],
  [37.531102, -77.42221, 33.4],
  [37.530933, -77.422398, 30.6],
  [37.530759, -77.422569, 27.4],
  [37.530571, -77.422746, 24.4],
  [37.530389, -77.422929, 21.4],
  [37.530179, -77.423058, 19],
  [37.52997, -77.422923, 19],
  [37.529828, -77.422704, 20],
  [37.529702, -77.422462, 21],
  [37.529581, -77.422242, 21.8],
  [37.529455, -77.422038, 22],
  [37.529332, -77.421824, 22],
  [37.529222, -77.421609, 22],
  [37.529114, -77.421389, 21.6],
  [37.529165, -77.421153, 23],
  [37.529316, -77.421008, 25],
  [37.529469, -77.42088, 26.8],
  [37.529621, -77.420745, 28.2],
  [37.529766, -77.420627, 29.6],
  [37.529857, -77.42045, 32],
  [37.529793, -77.420268, 35],
  [37.529691, -77.420123, 37.2],
  [37.529603, -77.419968, 39.4],
  [37.529522, -77.419817, 41.2],
  [37.529436, -77.419678, 42.6],
  [37.529342, -77.419544, 43.6],
  [37.529257, -77.419399, 44.2],
  [37.529168, -77.419254, 44.8],
  [37.529072, -77.419099, 45.2],
  [37.52898, -77.418948, 45.4],
  [37.528886, -77.418787, 45.8],
  [37.528787, -77.418627, 46.2],
  [37.528683, -77.418466, 46.4],
  [37.528581, -77.418299, 46.6],
  [37.528479, -77.418133, 46.8],
  [37.528372, -77.417961, 46.8],
  [37.528264, -77.417795, 47],
  [37.528157, -77.417618, 47.2],
  [37.52805, -77.417446, 47.6],
  [37.527956, -77.417259, 47.4],
  [37.527878, -77.417055, 46.8],
  [37.52776, -77.416878, 46],
  [37.527586, -77.416942, 45.8],
  [37.527441, -77.417087, 46],
  [37.527304, -77.417248, 46.4],
  [37.527165, -77.417414, 46.6],
  [37.527028, -77.417575, 46.6],
  [37.527012, -77.417795, 46.8],
  [37.527165, -77.417924, 46.8],
  [37.527344, -77.417886, 46.6],
  [37.527481, -77.41772, 46.6],
  [37.52765, -77.417602, 45.4],
  [37.527806, -77.417725, 43.6],
  [37.527913, -77.41794, 42.8],
  [37.528093, -77.418053, 41.4],
  [37.528221, -77.41823, 39.4],
  [37.528087, -77.418396, 38],
  [37.527883, -77.418342, 36],
  [37.527666, -77.418342, 34.2],
  [37.527516, -77.418503, 32.6],
  [37.527669, -77.418702, 30.4],
  [37.527666, -77.418938, 28.2],
  [37.527441, -77.418965, 26.6],
  [37.527218, -77.418895, 25.4],
  [37.526998, -77.418846, 25.4],
  [37.526776, -77.418793, 24],
  [37.526558, -77.418739, 22],
  [37.526338, -77.41868, 20],
  [37.526116, -77.418621, 18],
  [37.525896, -77.418535, 16],
  [37.525665, -77.418514, 14],
  [37.525434, -77.41853, 12.4],
  [37.525201, -77.418551, 10.8],
  [37.524973, -77.418584, 9.4],
  [37.524742, -77.418594, 8.4],
  [37.524514, -77.418557, 7.6],
  [37.524297, -77.418482, 6.8],
  [37.524091, -77.418369, 6.6],
  [37.523892, -77.418251, 6.2],
  [37.523704, -77.418106, 5.8],
  [37.523541, -77.41794, 5.8],
  [37.523385, -77.417758, 5.8],
  [37.52323, -77.41758, 5.8],
  [37.523071, -77.417403, 5.8],
  [37.522919, -77.417237, 5.8],
  [37.522763, -77.417071, 5.8],
  [37.52261, -77.41691, 5.8],
  [37.522436, -77.416781, 7],
  [37.522256, -77.41669, 7.6],
  [37.522079, -77.416588, 8],
  [37.521905, -77.416497, 8.4],
  [37.52173, -77.416406, 8.6],
  [37.521553, -77.416314, 8.8],
  [37.521379, -77.416223, 8.4],
  [37.521202, -77.416132, 8],
  [37.521025, -77.416041, 7],
  [37.520832, -77.415982, 6.8],
  [37.520641, -77.415918, 6.8],
  [37.520475, -77.415993, 6.6],
  [37.520548, -77.416207, 6],
  [37.520714, -77.416336, 5.8],
  [37.520899, -77.416427, 5.4],
  [37.521081, -77.416524, 5],
  [37.521269, -77.416604, 5],
  [37.521454, -77.416679, 5.2],
  [37.521642, -77.41676, 5.2],
  [37.52183, -77.41684, 5.2],
  [37.522015, -77.41691, 5],
  [37.522205, -77.416964, 4.8],
  [37.522393, -77.417039, 4.4],
  [37.522562, -77.417167, 4.2],
  [37.522715, -77.417323, 4.2],
  [37.522865, -77.417484, 4.2],
  [37.523015, -77.417634, 5],
  [37.52316, -77.417795, 5],
  [37.523305, -77.417956, 4.8],
  [37.52345, -77.418117, 4.8],
  [37.523603, -77.418267, 4.8],
  [37.523772, -77.418385, 5],
  [37.523935, -77.418514, 5],
  [37.524093, -77.418643, 5],
  [37.524254, -77.418771, 5],
  [37.524415, -77.4189, 5],
  [37.524576, -77.41904, 5],
  [37.524734, -77.419163, 5],
  [37.524895, -77.419292, 5],
  [37.525059, -77.41942, 5],
  [37.525223, -77.419549, 5],
  [37.525384, -77.419678, 5],
  [37.525547, -77.419807, 4.8],
  [37.525711, -77.41993, 4.8],
  [37.525877, -77.420059, 4.8],
  [37.526041, -77.420193, 4.8],
  [37.526199, -77.420322, 4.8],
  [37.52636, -77.42045, 4.6],
  [37.526518, -77.420595, 4.6],
  [37.526655, -77.420772, 4.6],
  [37.526792, -77.420939, 4.6],
  [37.526926, -77.421116, 4.6],
  [37.527055, -77.421287, 4.6],
  [37.527183, -77.421475, 4.6],
  [37.527317, -77.421647, 4.8],
  [37.527449, -77.421824, 6],
  [37.527567, -77.422001, 7.4],
  [37.527677, -77.422183, 9],
  [37.527787, -77.422349, 9.6],
  [37.527905, -77.422516, 9.8],
  [37.528023, -77.422687, 9.8],
  [37.528141, -77.422854, 9.8],
  [37.528259, -77.42302, 10],
  [37.528374, -77.423197, 10],
  [37.528492, -77.423379, 10],
  [37.528608, -77.423556, 9.8],
  [37.528734, -77.423733, 8.8],
  [37.528862, -77.423916, 8],
  [37.528988, -77.424098, 7.6],
  [37.529106, -77.424286, 7.6],
  [37.529222, -77.424484, 7],
  [37.529297, -77.424721, 5.4],
  [37.529375, -77.424951, 5.4],
  [37.529495, -77.42515, 5.4],
  [37.529621, -77.425348, 5.4],
  [37.529739, -77.425547, 5.4],
  [37.529855, -77.42575, 5.2],
  [37.529975, -77.425944, 5.2],
  [37.530096, -77.426142, 5.2],
  [37.530217, -77.42633, 5.2],
  [37.53034, -77.426523, 5.2],
  [37.530458, -77.426716, 5.2],
  [37.530579, -77.426904, 5.2],
  [37.530705, -77.427097, 5.4],
  [37.530834, -77.427269, 5.6],
  [37.53096, -77.427456, 5.8],
  [37.531086, -77.427639, 6],
  [37.531209, -77.427821, 6.2],
  [37.53133, -77.428004, 6.2],
  [37.531456, -77.428186, 6.2],
  [37.531582, -77.428363, 6.2],
  [37.531708, -77.428545, 6.4],
  [37.531805, -77.428749, 6.4],
  [37.531915, -77.428948, 6.4],
  [37.532014, -77.429157, 6.4],
  [37.532121, -77.429355, 6.4],
  [37.532234, -77.429543, 6.4],
  [37.53233, -77.429752, 6.4],
  [37.532392, -77.429978, 6.4],
  [37.532422, -77.430214, 6.4],
  [37.532395, -77.43045, 6.4],
  [37.532355, -77.43068, 6.4],
  [37.532357, -77.430927, 6.4],
  [37.532419, -77.431147, 6.6],
  [37.532521, -77.431351, 6.6],
  [37.532636, -77.431544, 6.8],
  [37.532744, -77.431743, 6.8],
  [37.532862, -77.431925, 7],
  [37.533041, -77.431903, 7.2],
  [37.533189, -77.431759, 7.4],
  [37.533331, -77.431598, 7.6],
  [37.53347, -77.431442, 7.8],
  [37.533621, -77.431297, 7.8],
  [37.533765, -77.431147, 7.8],
  [37.53391, -77.430992, 7.8],
  [37.534055, -77.430841, 7.6],
  [37.5342, -77.43068, 7.6],
  [37.534353, -77.430536, 8],
  [37.534527, -77.430493, 10],
  [37.534672, -77.430627, 9.8],
  [37.53479, -77.430804, 10],
  [37.534908, -77.430981, 10.4],
  [37.535029, -77.431163, 11],
  [37.535139, -77.431329, 11.6],
  [37.535251, -77.431507, 12.6],
  [37.535367, -77.431678, 13.6],
  [37.535474, -77.431855, 14.8],
  [37.535581, -77.432021, 15.8],
  [37.535683, -77.432193, 16.8],
  [37.535788, -77.432359, 18],
  [37.53589, -77.432526, 19],
  [37.535989, -77.432697, 19.8],
  [37.536088, -77.432864, 20.6],
  [37.53619, -77.433025, 21.4],
  [37.536297, -77.433186, 22.4],
  [37.536407, -77.433336, 23.4],
  [37.536515, -77.433497, 24.2],
  [37.536619, -77.433658, 25],
  [37.536719, -77.433819, 26],
  [37.536818, -77.433985, 26.8],
  [37.536912, -77.434146, 27.4],
  [37.537014, -77.434307, 28.4],
  [37.537121, -77.434468, 28.8],
  [37.53722, -77.434634, 28.8],
  [37.537319, -77.4348, 28.8],
  [37.537424, -77.434977, 28.6],
  [37.537526, -77.435154, 28.6],
  [37.537633, -77.435337, 29],
  [37.53774, -77.435508, 29.4],
  [37.53785, -77.435685, 29.8],
  [37.537958, -77.435862, 30.4],
  [37.538068, -77.436045, 31],
  [37.538175, -77.436222, 31.4],
  [37.538285, -77.436393, 32],
  [37.538392, -77.436571, 32.6],
  [37.5385, -77.436737, 33.4],
  [37.538607, -77.436914, 34],
  [37.538709, -77.437091, 34.8],
  [37.538816, -77.437268, 35.6],
  [37.538918, -77.43744, 36.4],
  [37.539017, -77.437617, 37.2],
  [37.539122, -77.437783, 38.2],
  [37.539221, -77.43796, 39],
  [37.53932, -77.438121, 39.8],
  [37.539411, -77.438282, 40.6],
  [37.539503, -77.438432, 41.4],
  [37.539586, -77.438571, 42.2],
  [37.539666, -77.438706, 43],
  [37.539747, -77.43884, 43.6],
  [37.539819, -77.438958, 44.2],
  [37.539892, -77.439081, 45],
  [37.539959, -77.439188, 45.6],
  [37.54002, -77.439296, 46.2],
  [37.540085, -77.439392, 46.8],
  [37.540146, -77.439494, 47.6],
  [37.540208, -77.439591, 48.2],
  [37.54027, -77.439682, 49],
  [37.540326, -77.439784, 49.8],
  [37.540388, -77.43988, 50.6],
  [37.540447, -77.439982, 51.2],
  [37.540509, -77.440073, 52],
  [37.54057, -77.44017, 52.6],
  [37.540632, -77.440272, 53.2],
  [37.540694, -77.440369, 53.6],
  [37.540753, -77.440476, 54],
  [37.540814, -77.440583, 54.2],
  [37.540876, -77.44069, 54.4],
  [37.54094, -77.440803, 54.6],
  [37.541005, -77.440926, 54.6],
  [37.541074, -77.44105, 54.4],
  [37.541142, -77.441173, 54.4],
  [37.541214, -77.441302, 54.2],
  [37.541292, -77.441431, 54.2],
  [37.541367, -77.44157, 54.2],
  [37.541439, -77.441704, 54.2],
  [37.541509, -77.441849, 54.2],
  [37.541576, -77.441989, 54.2],
  [37.541643, -77.442117, 54],
  [37.541707, -77.442225, 54],
  [37.541764, -77.442294, 54],
  [37.54182, -77.442353, 54],
  [37.54189, -77.442391, 54],
  [37.541968, -77.442402, 54.2],
  [37.542043, -77.442386, 54.2],
  [37.542115, -77.442348, 54.4],
  [37.542185, -77.4423, 54.6],
  [37.542252, -77.442257, 54.6],
  [37.542316, -77.442203, 54.8],
  [37.542383, -77.44215, 55],
  [37.54245, -77.442085, 55],
  [37.542515, -77.442032, 55],
  [37.542587, -77.441978, 55],
  [37.542657, -77.44193, 55],
  [37.542727, -77.441865, 55],
  [37.542791, -77.441795, 55],
  [37.542858, -77.441731, 55],
  [37.542928, -77.441651, 54.8],
  [37.542998, -77.441586, 54.8],
  [37.54307, -77.441506, 54.8],
  [37.543142, -77.441436, 54.8],
  [37.543218, -77.441372, 54.8],
  [37.543293, -77.441302, 54.8],
  [37.54337, -77.441232, 54.8],
  [37.543451, -77.441162, 54.8],
  [37.543526, -77.441093, 54.8],
  [37.543598, -77.441018, 54.6],
  [37.543676, -77.440943, 54.6],
  [37.543749, -77.440857, 54.6],
  [37.543824, -77.440782, 54.6],
  [37.543896, -77.440701, 54.4],
  [37.543974, -77.440621, 54.4],
  [37.544049, -77.44054, 54.2],
  [37.544127, -77.44046, 54],
  [37.544205, -77.440379, 54],
  [37.544285, -77.440293, 53.8],
  [37.544374, -77.440224, 53.6],
];

export default stream;
