import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [40.767734, -73.974375, 65.6],
  [40.767587, -73.974499, 65.6],
  [40.76745, -73.974649, 65.6],
  [40.767343, -73.974842, 65.6],
  [40.767313, -73.975078, 65.6],
  [40.767353, -73.975319, 65.2],
  [40.767434, -73.975534, 66.2],
  [40.767533, -73.975738, 67.4],
  [40.767643, -73.975931, 68.8],
  [40.76775, -73.976113, 70.2],
  [40.767858, -73.976296, 71.6],
  [40.76796, -73.976478, 73.2],
  [40.768059, -73.976666, 74.6],
  [40.768147, -73.976848, 76],
  [40.768228, -73.977036, 77.4],
  [40.76829, -73.977229, 78.8],
  [40.768319, -73.977444, 80.4],
  [40.768308, -73.977647, 81.8],
  [40.768265, -73.977846, 83.2],
  [40.768212, -73.978044, 84.6],
  [40.768155, -73.978227, 85.8],
  [40.768104, -73.978425, 87.2],
  [40.768104, -73.978634, 88.6],
  [40.768193, -73.97879, 89.8],
  [40.768327, -73.978897, 90.6],
  [40.768472, -73.978983, 91.2],
  [40.768606, -73.979096, 91.8],
  [40.768716, -73.979257, 92.2],
  [40.768805, -73.979439, 92.6],
  [40.768888, -73.979638, 92.6],
  [40.768971, -73.979831, 92.6],
  [40.769049, -73.980045, 92.8],
  [40.769108, -73.980265, 93],
  [40.769148, -73.980496, 93.4],
  [40.769132, -73.980732, 94.4],
  [40.769051, -73.980936, 96],
  [40.768936, -73.981102, 98],
  [40.768805, -73.981242, 100],
  [40.768665, -73.981365, 101.8],
  [40.768528, -73.981462, 103.8],
  [40.768391, -73.981563, 105.6],
  [40.768255, -73.981655, 107.2],
  [40.768113, -73.981746, 108.6],
  [40.767976, -73.981826, 110],
  [40.767834, -73.981907, 110.6],
  [40.767686, -73.981971, 111],
  [40.767533, -73.982025, 111.2],
  [40.76737, -73.982068, 111.2],
  [40.767198, -73.982068, 111.8],
  [40.767034, -73.982025, 113.2],
  [40.766881, -73.981934, 115.4],
  [40.766753, -73.981816, 118],
  [40.766637, -73.981681, 120.8],
  [40.766533, -73.981547, 123.4],
  [40.766439, -73.981403, 125.6],
  [40.766348, -73.981263, 127.2],
  [40.766259, -73.981129, 128.6],
  [40.766176, -73.980973, 129.8],
  [40.766098, -73.980812, 130.8],
  [40.766026, -73.980646, 131.4],
  [40.765959, -73.980469, 131.8],
  [40.765886, -73.980297, 132.2],
  [40.765814, -73.980104, 132.4],
  [40.765736, -73.979911, 132.6],
  [40.765661, -73.979713, 132.6],
  [40.765589, -73.979509, 132.8],
  [40.765511, -73.979305, 132.8],
  [40.765436, -73.97909, 132.6],
  [40.765358, -73.978876, 132.6],
  [40.76528, -73.978656, 132.2],
  [40.7652, -73.978436, 131.8],
  [40.765119, -73.978211, 131.2],
  [40.765031, -73.977985, 130.2],
  [40.764939, -73.977739, 128.2],
  [40.764843, -73.977503, 124.8],
  [40.764741, -73.977245, 121],
  [40.764626, -73.976982, 118.4],
  [40.764513, -73.97673, 116.8],
  [40.764379, -73.976478, 115.6],
  [40.764245, -73.976237, 114.8],
  [40.764108, -73.976001, 113.8],
  [40.763971, -73.975754, 113],
  [40.763845, -73.975518, 112.2],
  [40.763727, -73.975266, 111.6],
  [40.763623, -73.975003, 110.8],
  [40.76355, -73.974724, 110],
  [40.763558, -73.974445, 109.4],
  [40.763663, -73.974182, 109.2],
  [40.763813, -73.973984, 109.2],
  [40.763985, -73.973817, 109.2],
  [40.76417, -73.973672, 109.2],
  [40.764355, -73.973544, 109.2],
  [40.764543, -73.97342, 109],
  [40.76473, -73.973297, 108.2],
  [40.764913, -73.973168, 106.4],
  [40.765111, -73.973061, 103.4],
  [40.765331, -73.973002, 99.4],
  [40.76557, -73.972991, 95],
  [40.765787, -73.973125, 91.6],
  [40.76594, -73.973377, 89.6],
  [40.766077, -73.973629, 87.6],
  [40.766302, -73.973635, 84],
  [40.766474, -73.973399, 81],
  [40.766621, -73.973141, 78.6],
  [40.766793, -73.972889, 75.8],
  [40.767021, -73.972798, 72.2],
  [40.767268, -73.972889, 68.2],
  [40.767485, -73.973077, 66],
  [40.76764, -73.973324, 65.6],
  [40.767775, -73.973603, 65.6],
  [40.767893, -73.973871, 65.6],
];

export default stream;
