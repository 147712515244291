import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.647369, 166.939337, 2.4],
  [-11.647491, 166.93952, 2.4],
  [-11.647595, 166.939702, 2.4],
  [-11.647682, 166.939895, 2.2],
  [-11.647756, 166.94011, 2.2],
  [-11.647821, 166.940314, 2],
  [-11.647875, 166.940528, 1.8],
  [-11.647916, 166.940743, 1.4],
  [-11.647947, 166.940968, 1.2],
  [-11.647966, 166.941183, 1.2],
  [-11.647975, 166.941397, 1],
  [-11.647974, 166.941622, 1],
  [-11.647958, 166.941837, 1],
  [-11.647934, 166.942062, 1],
  [-11.647906, 166.942277, 1],
  [-11.647874, 166.942492, 1],
  [-11.647841, 166.942706, 1.2],
  [-11.647809, 166.942921, 1.2],
  [-11.647781, 166.943135, 1.2],
  [-11.647758, 166.943361, 1.2],
  [-11.647747, 166.943575, 1.2],
  [-11.647749, 166.94379, 1.4],
  [-11.64776, 166.944004, 1.4],
  [-11.647781, 166.94423, 1.4],
  [-11.64781, 166.944444, 1.4],
  [-11.647848, 166.944659, 1.4],
  [-11.647894, 166.944873, 1.4],
  [-11.647941, 166.945088, 1.4],
  [-11.64799, 166.945281, 1.4],
  [-11.648043, 166.945496, 1.4],
  [-11.648102, 166.94571, 1.4],
  [-11.64817, 166.945914, 1.4],
  [-11.648252, 166.946129, 1.4],
  [-11.648352, 166.946311, 1.4],
  [-11.648484, 166.946472, 1.2],
  [-11.648643, 166.946633, 1.2],
  [-11.648816, 166.946751, 1.2],
  [-11.648996, 166.946869, 1.2],
  [-11.649173, 166.946998, 1.2],
  [-11.649327, 166.947148, 1.4],
  [-11.649449, 166.94733, 1.8],
  [-11.649579, 166.947502, 2],
  [-11.649759, 166.94762, 2],
  [-11.649954, 166.947695, 1.8],
  [-11.650159, 166.947759, 2],
  [-11.650371, 166.94777, 2.6],
  [-11.65057, 166.947727, 3.2],
  [-11.650739, 166.947588, 4],
  [-11.650934, 166.947534, 4.6],
  [-11.651139, 166.947545, 5.4],
  [-11.651275, 166.947684, 6],
  [-11.651224, 166.947877, 6.4],
  [-11.651047, 166.947974, 6.8],
  [-11.650902, 166.948092, 7.4],
  [-11.651048, 166.948221, 8],
  [-11.651249, 166.948242, 8.6],
  [-11.651449, 166.948221, 9.2],
  [-11.651642, 166.948242, 9.8],
  [-11.651753, 166.948414, 10.2],
  [-11.651706, 166.948596, 10.8],
  [-11.651536, 166.948704, 11],
  [-11.651335, 166.948671, 10.8],
];

export default stream;
