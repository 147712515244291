import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.768908, 165.841552, 69.6],
  [-10.768736, 165.841552, 69.4],
  [-10.768569, 165.841606, 69],
  [-10.768403, 165.841649, 68.6],
  [-10.768241, 165.841691, 68.2],
  [-10.768077, 165.841756, 67.6],
  [-10.767912, 165.841799, 67],
  [-10.767744, 165.841831, 66.6],
  [-10.767575, 165.841863, 66.2],
  [-10.767406, 165.841863, 65.8],
  [-10.767239, 165.84182, 65.6],
  [-10.767082, 165.841767, 65.6],
  [-10.766945, 165.841681, 65.4],
  [-10.766838, 165.841541, 65.4],
  [-10.766747, 165.841423, 65.4],
  [-10.766662, 165.841262, 65.4],
  [-10.766582, 165.841134, 65.4],
  [-10.766504, 165.840994, 65.4],
  [-10.766427, 165.840855, 65.6],
  [-10.766364, 165.840704, 65.6],
  [-10.766325, 165.840554, 65.4],
  [-10.766296, 165.840393, 64.8],
  [-10.766286, 165.840232, 64.4],
  [-10.766316, 165.840071, 64.4],
  [-10.766382, 165.839921, 64.6],
  [-10.766475, 165.839803, 64.4],
  [-10.766569, 165.839664, 64.4],
  [-10.76664, 165.839524, 64.2],
  [-10.766666, 165.839374, 64.2],
  [-10.766619, 165.839224, 64.2],
  [-10.766544, 165.839084, 64.2],
  [-10.766465, 165.838945, 64.4],
  [-10.766413, 165.838805, 64.4],
  [-10.766387, 165.838655, 64.6],
  [-10.766386, 165.838505, 64.8],
  [-10.766411, 165.838355, 65],
  [-10.766466, 165.838215, 65],
  [-10.766539, 165.838076, 65.2],
  [-10.766614, 165.837958, 65.2],
  [-10.766681, 165.837818, 65.4],
  [-10.766721, 165.837668, 65.6],
  [-10.766699, 165.837529, 65.6],
  [-10.766632, 165.837389, 65.6],
  [-10.766537, 165.837282, 65.6],
  [-10.766416, 165.837196, 65.6],
  [-10.766276, 165.837142, 65.8],
  [-10.766129, 165.837175, 65.8],
  [-10.765993, 165.837228, 66],
  [-10.765861, 165.837282, 66.2],
  [-10.76573, 165.837357, 66.4],
  [-10.765598, 165.837421, 66.4],
  [-10.765458, 165.837464, 66.6],
  [-10.765314, 165.837497, 66.6],
  [-10.765169, 165.837497, 66.6],
  [-10.765022, 165.837518, 66.6],
  [-10.764877, 165.837529, 66.8],
  [-10.764735, 165.837572, 66.8],
  [-10.764599, 165.837636, 66.8],
  [-10.764467, 165.8377, 66.8],
  [-10.764338, 165.837775, 66.8],
  [-10.764213, 165.837851, 66.8],
  [-10.764096, 165.837936, 66.8],
  [-10.76399, 165.838044, 67],
  [-10.763881, 165.838151, 67],
  [-10.763768, 165.838248, 67.2],
  [-10.763654, 165.838333, 67.4],
  [-10.76354, 165.83843, 67.8],
  [-10.763416, 165.838505, 68],
  [-10.763283, 165.838569, 68.2],
  [-10.763143, 165.838602, 68.4],
  [-10.763001, 165.838612, 68.6],
  [-10.762856, 165.838623, 68.8],
  [-10.762714, 165.838623, 69],
  [-10.762571, 165.838612, 69.2],
  [-10.762431, 165.83858, 69.4],
  [-10.762297, 165.838537, 69.8],
  [-10.762172, 165.838473, 70],
  [-10.762051, 165.838398, 70.4],
  [-10.76192, 165.838333, 70.4],
  [-10.761785, 165.838312, 70.4],
  [-10.761643, 165.83828, 70.2],
  [-10.761499, 165.83828, 70],
  [-10.761354, 165.83829, 70],
  [-10.76121, 165.838323, 70],
  [-10.761067, 165.838355, 70],
  [-10.760923, 165.838398, 70],
  [-10.76078, 165.83843, 69.8],
  [-10.760635, 165.838462, 69.4],
  [-10.760489, 165.838505, 69.2],
  [-10.760341, 165.838548, 68.8],
  [-10.760191, 165.83858, 68.4],
  [-10.760037, 165.838612, 68.2],
  [-10.759881, 165.838612, 68.2],
  [-10.759728, 165.838612, 68.2],
  [-10.759574, 165.838602, 68.2],
];

export default stream;
