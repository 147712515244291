import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.653714, 166.955785, 1],
  [-11.653737, 166.955903, 1],
  [-11.653749, 166.956031, 0.8],
  [-11.653757, 166.956139, 0.8],
  [-11.653764, 166.956257, 0.8],
  [-11.653769, 166.956375, 0.8],
  [-11.653768, 166.956482, 0.8],
  [-11.653766, 166.956611, 0.8],
  [-11.653765, 166.956729, 0.8],
  [-11.653764, 166.956857, 0.8],
  [-11.653764, 166.956975, 0.8],
  [-11.653764, 166.957093, 0.8],
  [-11.653764, 166.957222, 0.8],
  [-11.653764, 166.95734, 0.8],
  [-11.653764, 166.957458, 0.8],
  [-11.653764, 166.957587, 0.8],
  [-11.653765, 166.957705, 0.8],
  [-11.653786, 166.957834, 0.8],
  [-11.653828, 166.957952, 0.8],
  [-11.653886, 166.958059, 0.8],
  [-11.653954, 166.958145, 0.8],
  [-11.654018, 166.958252, 0.8],
  [-11.654073, 166.958359, 0.8],
  [-11.654116, 166.958467, 0.8],
  [-11.654154, 166.958574, 0.8],
  [-11.654193, 166.958681, 0.8],
  [-11.654229, 166.95881, 0.8],
  [-11.654267, 166.958917, 0.8],
  [-11.654304, 166.959014, 0.6],
  [-11.654344, 166.959121, 0.6],
  [-11.654381, 166.959229, 0.6],
  [-11.654421, 166.959357, 0.6],
  [-11.65446, 166.959465, 0.6],
  [-11.6545, 166.959572, 0.6],
  [-11.654539, 166.95969, 0.6],
  [-11.654574, 166.959797, 0.6],
  [-11.654605, 166.959915, 0.6],
  [-11.654631, 166.960044, 0.6],
  [-11.654654, 166.960162, 0.6],
  [-11.654674, 166.960291, 0.6],
  [-11.65469, 166.960409, 0.6],
  [-11.654704, 166.960548, 0.6],
  [-11.654713, 166.960666, 1],
  [-11.654717, 166.960784, 1],
  [-11.654721, 166.960913, 0.8],
  [-11.654723, 166.961052, 0.4],
  [-11.654724, 166.96117, 0],
  [-11.654725, 166.96131, -0.4],
  [-11.654727, 166.961449, -1],
  [-11.654728, 166.961578, -1.4],
  [-11.654729, 166.961718, -2],
  [-11.654731, 166.961857, -2.4],
  [-11.654732, 166.961997, -3],
  [-11.654733, 166.962147, -3.6],
  [-11.654736, 166.962297, -4],
  [-11.654737, 166.962447, -4.6],
  [-11.65474, 166.962587, -5],
  [-11.654741, 166.962737, -5.6],
  [-11.654741, 166.962898, -6],
  [-11.654741, 166.963048, -6],
  [-11.654737, 166.963198, -6.2],
  [-11.654712, 166.963338, -6],
  [-11.654641, 166.963477, -6],
  [-11.654532, 166.963563, -5.8],
  [-11.654397, 166.963617, -5.6],
  [-11.65426, 166.963617, -5.6],
  [-11.65412, 166.963595, -5.6],
  [-11.653988, 166.963563, -5.4],
  [-11.653859, 166.96352, -5.4],
  [-11.65373, 166.963477, -5.4],
  [-11.653605, 166.963423, -5.4],
  [-11.653479, 166.96337, -5.4],
  [-11.653357, 166.963327, -5.4],
  [-11.653233, 166.963273, -5.4],
  [-11.653109, 166.96322, -5.4],
  [-11.652987, 166.963166, -5.4],
  [-11.652861, 166.963123, -5.4],
  [-11.652738, 166.96308, -5.4],
  [-11.652612, 166.963037, -5.4],
  [-11.65249, 166.962984, -5.4],
  [-11.652368, 166.962941, -5.4],
  [-11.652241, 166.962898, -5.4],
  [-11.652119, 166.962833, -5.4],
  [-11.651995, 166.96279, -5.4],
  [-11.651873, 166.962737, -5.4],
  [-11.651749, 166.962694, -5.4],
  [-11.651627, 166.962651, -5.4],
  [-11.651505, 166.962608, -5.4],
];

export default stream;
