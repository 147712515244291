import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.758075, 165.849212, 136.2],
  [-10.758214, 165.849169, 136.4],
  [-10.758344, 165.849084, 136.6],
  [-10.758472, 165.848987, 136.6],
  [-10.7586, 165.848912, 136.6],
  [-10.758725, 165.848805, 136.6],
  [-10.758851, 165.848708, 136.6],
  [-10.758974, 165.848622, 136.6],
  [-10.759101, 165.848526, 136.6],
  [-10.759238, 165.84859, 136.6],
  [-10.759341, 165.848708, 136.6],
  [-10.759458, 165.848815, 136.6],
  [-10.759606, 165.848762, 136.6],
  [-10.759737, 165.848665, 136.6],
  [-10.759868, 165.848579, 136.6],
  [-10.759995, 165.848472, 136.6],
  [-10.760123, 165.848365, 136.6],
  [-10.76025, 165.848268, 136.6],
  [-10.760379, 165.848161, 136.6],
  [-10.760507, 165.848054, 136.6],
  [-10.76064, 165.847968, 136.6],
  [-10.760771, 165.847871, 136.6],
  [-10.760808, 165.847721, 136.6],
  [-10.760726, 165.847571, 136.6],
  [-10.760635, 165.847431, 136.6],
  [-10.760544, 165.847292, 136.6],
  [-10.760451, 165.847152, 136.6],
  [-10.760386, 165.847024, 136.6],
  [-10.760483, 165.846884, 136.6],
  [-10.760615, 165.846788, 136.6],
  [-10.760733, 165.84667, 136.4],
  [-10.76085, 165.846552, 136],
];

export default stream;
