import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [40.773423, -73.969654, 21.8],
  [40.773423, -73.969821, 21.4],
  [40.773423, -73.969987, 21.2],
  [40.773434, -73.970153, 21.2],
  [40.773477, -73.970309, 21.6],
  [40.773525, -73.970454, 22.4],
  [40.773576, -73.970582, 22.6],
  [40.773619, -73.970727, 22.8],
  [40.773651, -73.970867, 22.8],
  [40.773686, -73.971001, 22.8],
  [40.773724, -73.97114, 22.8],
  [40.773759, -73.971274, 22.8],
  [40.773791, -73.971414, 22.6],
  [40.77382, -73.971553, 22.4],
  [40.773842, -73.971698, 22],
  [40.773855, -73.971848, 21.6],
  [40.773855, -73.972004, 21.2],
  [40.773839, -73.972149, 21.2],
  [40.773815, -73.972299, 21],
  [40.773785, -73.972444, 21],
  [40.773767, -73.972589, 21],
  [40.773767, -73.972744, 21],
  [40.773791, -73.972895, 20.8],
  [40.773828, -73.973034, 20.6],
  [40.773877, -73.973163, 20.4],
  [40.773936, -73.973297, 20.4],
  [40.774, -73.97342, 20.2],
  [40.77408, -73.973528, 20.4],
  [40.774172, -73.973613, 20.6],
  [40.774257, -73.973694, 20.8],
  [40.774346, -73.973785, 21],
  [40.774426, -73.973876, 21.4],
  [40.774507, -73.973973, 21.6],
];

export default stream;
