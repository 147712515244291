import { LatLngAlt } from "../../../types.js";

export const edgeNorthJunctionA1: LatLngAlt[] = [
  [40.798349, -73.958834, 29.4],
  [40.798411, -73.95855, 29],
  [40.798368, -73.95826, 30.6],
];
export const edgeNorthJunctionA2: LatLngAlt[] = [
  [40.799001, -73.958572, 25.2],
  [40.798947, -73.958614, 25.6],
  [40.798899, -73.958641, 25.8],
  [40.798848, -73.958679, 26.2],
  [40.7988, -73.958711, 26.6],
  [40.798749, -73.958743, 27],
  [40.798703, -73.958781, 27.2],
  [40.798658, -73.958813, 27.6],
  [40.798612, -73.958851, 28],
  [40.798569, -73.958877, 28.4],
  [40.798523, -73.958915, 28.6],
  [40.798483, -73.958947, 29],
  [40.79844, -73.958985, 29.4],
  [40.798395, -73.959017, 29.6],
  [40.798352, -73.959054, 30],
  [40.798312, -73.959087, 30.2],
  [40.798269, -73.959113, 30.6],
];
export const edgeNorthJunctionA3: LatLngAlt[] = [
  [40.798397, -73.95818, 31.2],
  [40.798548, -73.958384, 28.4],
  [40.798754, -73.958475, 26.4],
  [40.798971, -73.95848, 25],
];
