import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-21.724005, 166.20261, 119.4],
  [-21.723981, 166.20262, 119.6],
  [-21.723954, 166.20262, 119.8],
  [-21.723927, 166.20262, 119.8],
  [-21.723899, 166.20262, 120],
  [-21.723869, 166.20262, 120.2],
  [-21.723837, 166.202642, 120.4],
  [-21.723805, 166.202642, 120.4],
  [-21.72377, 166.202642, 120.6],
  [-21.723733, 166.20262, 120.6],
  [-21.723695, 166.20262, 120.8],
  [-21.723655, 166.20261, 120.8],
];

export default stream;
