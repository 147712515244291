import { SurfaceType, WorldConfig } from "../../types";
import watopiaMap from "../../maps/watopia.png";

export const WORLD_CONFIG_WATOPIA: WorldConfig = {
  initialBounds: [
    [-11.635444, 166.93555],
    [-11.673613, 166.972511],
  ],
  image: watopiaMap,
  backgroundColor: "#0884e2",
  surfaces: [
    {
      // epic KOM castle
      type: SurfaceType.Cobbles,
      polygon: [
        [-11.678957, 166.972306],
        [-11.681067, 166.972329],
        [-11.681081, 166.972807],
        [-11.678979, 166.972913],
        [-11.678957, 166.972306],
      ],
    },
    {
      // radio tower
      type: SurfaceType.Snow,
      polygon: [
        [-11.673845, 166.948143],
        [-11.679016, 166.951603],
        [-11.677798, 166.956701],
        [-11.677025, 166.958537],
        [-11.676743, 166.958537],
        [-11.676549, 166.958112],
        [-11.676163, 166.956276],
        [-11.676401, 166.955123],
        [-11.674588, 166.951436],
        [-11.672716, 166.950252],
        [-11.673845, 166.948143],
      ],
    },
    {
      // jungle loop
      type: SurfaceType.Dirt,
      polygon: [
        [-11.683264, 166.93731],
        [-11.683453, 166.937653],
        [-11.677916, 166.943393],
        [-11.6753, 166.938114],
        [-11.677191, 166.928587],
        [-11.68588, 166.924982],
        [-11.689967, 166.924049],
        [-11.691764, 166.92584],
        [-11.692582, 166.9303],
        [-11.692656, 166.930831],
        [-11.692583, 166.931355],
        [-11.692173, 166.931087],
        [-11.691018, 166.93157],
        [-11.691039, 166.932085],
        [-11.691575, 166.93261],
        [-11.691575, 166.935732],
        [-11.686574, 166.93776],
        [-11.685565, 166.936666],
        [-11.685428, 166.936344],
        [-11.682854, 166.934166],
        [-11.683957, 166.93054],
        [-11.683611, 166.930228],
        [-11.683327, 166.929638],
        [-11.683332, 166.928388],
        [-11.683054, 166.927793],
        [-11.68193, 166.928297],
        [-11.681431, 166.929264],
        [-11.681858, 166.929465],
        [-11.680942, 166.937191],
        [-11.683264, 166.93731],
      ],
    },
    {
      // jungle cave east
      type: SurfaceType.Wood,
      polygon: [
        [-11.691039, 166.932085],
        [-11.691018, 166.93157],
        [-11.692173, 166.931087],
        [-11.692583, 166.931355],
        [-11.691575, 166.93261],
        [-11.691039, 166.932085],
      ],
    },
    {
      // jungle middle road
      type: SurfaceType.Brick,
      polygon: [
        [-11.681431, 166.929264],
        [-11.68193, 166.928297],
        [-11.683054, 166.927793],
        [-11.683322, 166.928383],
        [-11.682754, 166.928523],
        [-11.681858, 166.929465],
        [-11.681431, 166.929264],
      ],
    },
    {
      // jungle middle road
      type: SurfaceType.Wood,
      polygon: [
        [-11.682746, 166.928536],
        [-11.683314, 166.928392],
        [-11.683462, 166.928946],
        [-11.682612, 166.92909],
        [-11.682337, 166.928904],
        [-11.682746, 166.928536],
      ],
    },
    {
      // jungle middle road
      type: SurfaceType.Brick,
      polygon: [
        [-11.682344, 166.9289],
        [-11.682619, 166.92909],
        [-11.682916, 166.930437],
        [-11.682638, 166.930493],
        [-11.682181, 166.929173],
        [-11.682344, 166.9289],
      ],
    },
    {
      // jungle middle road
      type: SurfaceType.Brick,
      polygon: [
        [-11.683622, 166.930247],
        [-11.68363, 166.930952],
        [-11.683228, 166.930626],
        [-11.68305, 166.929799],
        [-11.683355, 166.929648],
        [-11.683622, 166.930247],
      ],
    },
    {
      // jungle middle road
      type: SurfaceType.Wood,
      polygon: [
        [-11.682986, 166.931146],
        [-11.683243, 166.93062],
        [-11.683621, 166.930942],
        [-11.683348, 166.931414],
        [-11.682986, 166.931146],
      ],
    },
    {
      // jungle middle road
      type: SurfaceType.Brick,
      polygon: [
        [-11.683362, 166.9314],
        [-11.683132, 166.932606],
        [-11.682634, 166.932462],
        [-11.682649, 166.931703],
        [-11.682998, 166.931142],
        [-11.683362, 166.9314],
      ],
    },
    {
      // jungle middle road
      type: SurfaceType.Dirt,
      polygon: [
        [-11.682638, 166.930493],
        [-11.681906, 166.93148],
        [-11.681958, 166.932333],
        [-11.682434, 166.933266],
        [-11.682872, 166.93327],
        [-11.683132, 166.932618],
        [-11.682649, 166.932466],
        [-11.682642, 166.931688],
        [-11.683017, 166.930619],
        [-11.68292, 166.930433],
        [-11.682638, 166.930493],
      ],
    },
    {
      // repack ridge
      type: SurfaceType.Grass,
      polygon: [
        [-11.646436, 166.9744],
        [-11.645166, 166.973232],
        [-11.644519, 166.971069],
        [-11.646154, 166.968695],
        [-11.650746, 166.968649],
        [-11.653488, 166.972867],
        [-11.650805, 166.973983],
        [-11.650627, 166.97355],
        [-11.65027, 166.97245],
        [-11.648413, 166.971836],
        [-11.646659, 166.974043],
        [-11.646436, 166.9744],
      ],
    },
    {
      // bridge to start of east epic kom
      type: SurfaceType.Wood,
      polygon: [
        [-11.659528, 166.967671],
        [-11.659922, 166.9678],
        [-11.659907, 166.969552],
        [-11.659513, 166.969545],
        [-11.65935, 166.968331],
        [-11.659528, 166.967671],
      ],
    },
    {
      // bridge to start of east epic kom
      type: SurfaceType.Dirt,
      polygon: [
        [-11.659523, 166.967677],
        [-11.659555, 166.967554],
        [-11.66003, 166.967704],
        [-11.659902, 166.967804],
        [-11.659523, 166.967677],
      ],
    },
    {
      // bridge east of stadium
      type: SurfaceType.Dirt,
      polygon: [
        [-11.636376, 166.959447],
        [-11.636866, 166.958908],
        [-11.637148, 166.959424],
        [-11.636695, 166.959705],
        [-11.636376, 166.959447],
      ],
    },
    {
      // bridge east of stadium
      type: SurfaceType.Wood,
      polygon: [
        [-11.636383, 166.959447],
        [-11.636695, 166.959709],
        [-11.636855, 166.961731],
        [-11.636524, 166.961951],
        [-11.6359, 166.960577],
        [-11.63616, 166.959644],
        [-11.636383, 166.959447],
      ],
    },
    {
      // italian village
      type: SurfaceType.Dirt,
      polygon: [
        [-11.649193, 166.947544],
        [-11.650203, 166.946755],
        [-11.652106, 166.94765],
        [-11.652091, 166.949258],
        [-11.651058, 166.94894],
        [-11.650813, 166.948492],
        [-11.649193, 166.947544],
      ],
    },
    {
      // italian village
      type: SurfaceType.Cobbles,
      polygon: [
        [-11.65107, 166.94895],
        [-11.65107, 166.94895],
        [-11.650891, 166.949519],
        [-11.650891, 166.949519],
        [-11.650067, 166.949573],
        [-11.650067, 166.949573],
        [-11.649767, 166.949272],
        [-11.649767, 166.949272],
        [-11.650492, 166.948317],
        [-11.650492, 166.948317],
        [-11.650823, 166.948489],
        [-11.650823, 166.948489],
        [-11.65107, 166.94895],
      ],
    },
    {
      // JB
      type: SurfaceType.Wood,
      polygon: [
        [-11.64569, 166.947938],
        [-11.64719, 166.948974],
        [-11.646979, 166.949293],
        [-11.645489, 166.948234],
        [-11.64569, 166.947938],
      ],
    },
    {
      // pier start
      type: SurfaceType.Wood,
      polygon: [
        [-11.635387, 166.952164],
        [-11.635291, 166.952604],
        [-11.635625, 166.953105],
        [-11.634459, 166.953841],
        [-11.633745, 166.954683],
        [-11.63515, 166.955897],
        [-11.635789, 166.955092],
        [-11.636019, 166.953788],
        [-11.636264, 166.953264],
        [-11.635967, 166.953059],
        [-11.635692, 166.952756],
        [-11.635573, 166.952551],
        [-11.635387, 166.952164],
      ],
    },
    {
      // pier start
      type: SurfaceType.Wood,
      polygon: [
        [-11.636078, 166.952839],
        [-11.636487, 166.95205],
        [-11.63749, 166.952384],
        [-11.638248, 166.953552],
        [-11.637832, 166.954357],
        [-11.637423, 166.954577],
        [-11.637564, 166.954174],
        [-11.63749, 166.953924],
        [-11.636963, 166.953393],
        [-11.636078, 166.952839],
      ],
    },
    {
      // islands before west epic kom start
      type: SurfaceType.Wood,
      polygon: [
        [-11.650203, 166.957748],
        [-11.651073, 166.955623],
        [-11.651422, 166.955775],
        [-11.65056, 166.958195],
        [-11.650203, 166.957748],
      ],
    },
    {
      // islands before west epic kom start
      type: SurfaceType.Wood,
      polygon: [
        [-11.651994, 166.955168],
        [-11.653109, 166.955225],
        [-11.653105, 166.955623],
        [-11.651964, 166.955574],
        [-11.651994, 166.955168],
      ],
    },
    {
      // islands before west epic kom start
      type: SurfaceType.Wood,
      polygon: [
        [-11.654569, 166.955119],
        [-11.657343, 166.95439],
        [-11.657522, 166.954776],
        [-11.654685, 166.955538],
        [-11.654569, 166.955119],
      ],
    },
    {
      // islands before west epic kom start
      type: SurfaceType.Wood,
      polygon: [
        [-11.653525, 166.955965],
        [-11.653948, 166.955927],
        [-11.654008, 166.957816],
        [-11.653614, 166.957892],
        [-11.653525, 166.955965],
      ],
    },
    {
      // islands before west epic kom start
      type: SurfaceType.Wood,
      polygon: [
        [-11.654517, 166.958987],
        [-11.654811, 166.959926],
        [-11.654438, 166.960033],
        [-11.654123, 166.959121],
        [-11.654517, 166.958987],
      ],
    },
    {
      // islands before west epic kom start
      type: SurfaceType.Dirt,
      polygon: [
        [-11.653109, 166.955232],
        [-11.654574, 166.955125],
        [-11.654695, 166.955538],
        [-11.653949, 166.955956],
        [-11.653539, 166.955978],
        [-11.653098, 166.955618],
        [-11.653109, 166.955232],
      ],
    },
    {
      // islands before west epic kom start
      type: SurfaceType.Dirt,
      polygon: [
        [-11.65108, 166.955631],
        [-11.651259, 166.955123],
        [-11.652002, 166.955176],
        [-11.651979, 166.955586],
        [-11.651415, 166.95579],
        [-11.65108, 166.955631],
      ],
    },
    {
      // road between epic koms
      type: SurfaceType.Dirt,
      polygon: [
        [-11.666475, 166.952824],
        [-11.667032, 166.952574],
        [-11.667508, 166.953408],
        [-11.667062, 166.953765],
        [-11.666475, 166.952824],
      ],
    },
    {
      // volcano kom
      type: SurfaceType.Wood,
      polygon: [
        [-11.642228, 166.937953],
        [-11.643278, 166.937465],
        [-11.64424, 166.937824],
        [-11.644024, 166.93813],
        [-11.64332, 166.937975],
        [-11.642475, 166.938157],
        [-11.642228, 166.937953],
      ],
    },
    {
      // volcano kom
      // earlier than actual to avoid overlaps
      type: SurfaceType.Wood,
      polygon: [
        [-11.641492, 166.939895],
        [-11.64176, 166.939809],
        [-11.641996, 166.94026],
        [-11.641755, 166.94041],
        [-11.641492, 166.939895],
      ],
    },

    {
      // southern coast
      // west intersection
      type: SurfaceType.Wood,
      polygon: [
        [-11.692582, 166.9303],
        [-11.692656, 166.930831],
        [-11.693488, 166.930785],
        [-11.693488, 166.93033],
        [-11.692582, 166.9303],
      ],
    },
    {
      // southern coast
      // west town - west
      type: SurfaceType.Brick,
      polygon: [
        [-11.707081, 166.934359],
        [-11.707544, 166.933866],
        [-11.713784, 166.937106],
        [-11.712409, 166.940982],
        [-11.712156, 166.941384],
        [-11.710069, 166.94127],
        [-11.707231, 166.937757],
        [-11.707081, 166.934359],
      ],
    },
    {
      // southern coast
      // west town - small bridge
      type: SurfaceType.Wood,
      polygon: [
        [-11.712409, 166.940982],
        [-11.71281, 166.941141],
        [-11.712818, 166.941528],
        [-11.712156, 166.941384],
        [-11.712409, 166.940982],
      ],
    },
    {
      // southern coast
      // west town - east
      type: SurfaceType.Brick,
      polygon: [
        [-11.71281, 166.941141],
        [-11.714816, 166.94152],
        [-11.712, 166.947043],
        [-11.71125, 166.94674],
        [-11.710983, 166.94658],
        [-11.709467, 166.945222],
        [-11.709958, 166.941998],
        [-11.712818, 166.941528],
        [-11.71281, 166.941141],
      ],
    },
    {
      // southern coast
      // bridge
      type: SurfaceType.Wood,
      polygon: [
        [-11.712528, 166.950207],
        [-11.712446, 166.949881],
        [-11.713553, 166.94963],
        [-11.713716, 166.950116],
        [-11.713694, 166.95045],
        [-11.712996, 166.950958],
        [-11.712528, 166.950207],
      ],
    },
    {
      // southern coast
      // bridge
      type: SurfaceType.Wood,
      polygon: [
        [-11.714073, 166.954948],
        [-11.714437, 166.954963],
        [-11.715046, 166.955752],
        [-11.714898, 166.95617],
        [-11.714459, 166.956117],
        [-11.714073, 166.954948],
      ],
    },
    {
      // southern coast
      // bridge around corner
      type: SurfaceType.Wood,
      polygon: [
        [-11.707172, 166.985871],
        [-11.707514, 166.985817],
        [-11.707818, 166.986151],
        [-11.707536, 166.986447],
        [-11.707239, 166.98628],
        [-11.707172, 166.985871],
      ],
    },
    {
      // southern coast
      // bridge around corner
      type: SurfaceType.Wood,
      polygon: [
        [-11.70689, 166.986455],
        [-11.706912, 166.986766],
        [-11.706533, 166.986773],
        [-11.706511, 166.986326],
        [-11.70689, 166.986455],
      ],
    },
    {
      // southern coast
      // mini bridge
      type: SurfaceType.Wood,
      polygon: [
        [-11.706139, 166.98449],
        [-11.706414, 166.984687],
        [-11.706578, 166.984444],
        [-11.706355, 166.984224],
        [-11.706139, 166.98449],
      ],
    },
    {
      // southern coast
      // mini bridge
      type: SurfaceType.Wood,
      polygon: [
        [-11.706069, 166.983724],
        [-11.705902, 166.983435],
        [-11.705656, 166.983746],
        [-11.705861, 166.984148],
        [-11.706069, 166.983724],
      ],
    },
    {
      // southern coast
      // beach
      type: SurfaceType.Dirt,
      polygon: [
        [-11.691606, 166.994065],
        [-11.691364, 166.994473],
        [-11.689305, 166.995438],
        [-11.687383, 166.993529],
        [-11.687277, 166.993207],
        [-11.690303, 166.991318],
        [-11.691606, 166.994065],
      ],
    },
  ],
};
