import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-21.7237, 166.203114, 123],
  [-21.723675, 166.20321, 123.6],
  [-21.723651, 166.203285, 124.2],
  [-21.723628, 166.203371, 124.8],
  [-21.723602, 166.203446, 125.4],
  [-21.723578, 166.203543, 125.8],
  [-21.723553, 166.203618, 126.4],
  [-21.723526, 166.203704, 127],
  [-21.723502, 166.2038, 127.6],
  [-21.723476, 166.203876, 128.2],
  [-21.723452, 166.203972, 128.6],
  [-21.723425, 166.204058, 129.2],
  [-21.723401, 166.204133, 129.8],
  [-21.723376, 166.20423, 130.4],
  [-21.723352, 166.204305, 131],
  [-21.723329, 166.204391, 131.8],
  [-21.723305, 166.204487, 132.4],
  [-21.723281, 166.204562, 133],
  [-21.723258, 166.204637, 133.8],
  [-21.723235, 166.204734, 134.4],
  [-21.723213, 166.204809, 135],
  [-21.723191, 166.204884, 135.8],
  [-21.723168, 166.204981, 136.4],
  [-21.723146, 166.205056, 137.2],
  [-21.723124, 166.205131, 137.8],
  [-21.723104, 166.205206, 138.4],
  [-21.723081, 166.205281, 139],
  [-21.723059, 166.205356, 139.8],
  [-21.723038, 166.205431, 140.4],
  [-21.723018, 166.205506, 141],
  [-21.722998, 166.205581, 141.6],
  [-21.722979, 166.205667, 142.2],
  [-21.72296, 166.205753, 142.6],
  [-21.722941, 166.205828, 143.2],
  [-21.722922, 166.205903, 143.6],
  [-21.722906, 166.206, 144.2],
  [-21.722889, 166.206075, 144.6],
  [-21.722876, 166.206161, 145],
  [-21.722861, 166.206257, 145.4],
  [-21.722846, 166.206332, 145.8],
  [-21.722833, 166.206429, 146.2],
  [-21.722817, 166.206536, 146.6],
  [-21.722803, 166.206622, 147],
  [-21.722787, 166.206719, 147.4],
  [-21.722767, 166.206805, 147.8],
  [-21.722744, 166.206912, 148.2],
  [-21.722721, 166.207008, 148.6],
  [-21.722699, 166.207094, 149],
  [-21.722678, 166.207201, 149.4],
  [-21.722658, 166.207298, 150],
  [-21.72264, 166.207405, 150.4],
  [-21.722618, 166.207491, 150.8],
  [-21.722594, 166.207588, 151.4],
  [-21.722566, 166.207695, 151.8],
  [-21.722535, 166.207781, 152.2],
  [-21.722499, 166.207877, 152.6],
  [-21.722454, 166.207963, 153],
  [-21.722407, 166.20806, 153.4],
  [-21.72235, 166.208146, 153.8],
  [-21.722285, 166.208221, 154.2],
  [-21.722213, 166.208307, 154.6],
  [-21.722137, 166.20836, 154.8],
  [-21.722056, 166.208425, 155.2],
  [-21.721974, 166.208489, 155.4],
  [-21.72189, 166.208543, 155.6],
  [-21.721804, 166.208618, 156],
  [-21.721717, 166.208682, 156.2],
  [-21.721632, 166.208746, 156.6],
  [-21.721549, 166.208822, 157],
  [-21.721473, 166.208886, 157.4],
  [-21.721402, 166.208972, 158],
  [-21.721337, 166.209047, 158.4],
  [-21.721278, 166.209143, 159],
  [-21.721222, 166.209229, 159.6],
  [-21.721171, 166.209326, 160.2],
  [-21.721125, 166.209412, 161],
  [-21.721081, 166.209508, 161.6],
  [-21.721037, 166.209594, 162.2],
  [-21.720995, 166.209691, 163],
  [-21.720955, 166.209787, 163.6],
  [-21.720915, 166.209873, 164.2],
  [-21.720872, 166.209948, 165],
  [-21.720829, 166.210045, 165.6],
  [-21.720786, 166.21012, 166.4],
  [-21.720746, 166.210195, 167.2],
  [-21.72071, 166.21027, 168],
  [-21.720673, 166.210366, 168.8],
  [-21.720637, 166.210442, 169.4],
  [-21.720602, 166.210495, 170.2],
  [-21.720569, 166.21057, 171],
  [-21.720533, 166.210656, 171.6],
  [-21.720494, 166.21071, 172.2],
  [-21.720454, 166.210785, 172.8],
  [-21.720412, 166.210849, 173.4],
  [-21.720366, 166.210914, 174],
  [-21.720318, 166.210989, 174.4],
  [-21.720267, 166.211053, 174.8],
  [-21.720212, 166.211107, 175.2],
  [-21.720153, 166.211171, 175.6],
  [-21.720091, 166.211236, 175.8],
  [-21.720028, 166.211289, 176.2],
  [-21.719961, 166.211364, 176.4],
  [-21.719893, 166.211429, 176.6],
  [-21.71982, 166.211493, 176.8],
  [-21.719748, 166.211568, 177.2],
  [-21.719674, 166.211632, 177.4],
  [-21.719599, 166.211708, 177.8],
  [-21.719523, 166.211761, 178],
  [-21.719446, 166.211847, 178.6],
  [-21.719374, 166.211922, 179],
  [-21.7193, 166.211976, 179.6],
  [-21.719229, 166.212051, 180.2],
  [-21.719155, 166.212115, 180.8],
  [-21.719084, 166.21219, 181.6],
  [-21.719013, 166.212255, 182.2],
  [-21.718943, 166.212319, 182.8],
  [-21.718875, 166.212373, 183.4],
  [-21.718805, 166.212448, 184.2],
  [-21.718741, 166.212512, 184.8],
  [-21.718682, 166.212577, 185.4],
  [-21.718654, 166.212662, 186.2],
  [-21.71865, 166.212759, 187],
  [-21.718654, 166.212845, 187.8],
  [-21.718659, 166.21292, 188.6],
  [-21.718668, 166.213017, 189.4],
  [-21.718678, 166.213092, 190.2],
  [-21.718687, 166.213188, 190.8],
  [-21.718699, 166.213263, 191.6],
  [-21.718709, 166.213338, 192.4],
  [-21.718717, 166.213413, 193],
  [-21.718726, 166.213489, 193.8],
  [-21.718733, 166.213564, 194.4],
  [-21.718742, 166.213639, 195.2],
  [-21.71875, 166.213714, 195.8],
  [-21.71876, 166.2138, 196.4],
  [-21.718768, 166.213853, 197],
  [-21.718777, 166.213928, 197.6],
  [-21.718787, 166.214004, 198.2],
  [-21.718796, 166.2141, 198.8],
  [-21.718805, 166.214175, 199.4],
  [-21.718816, 166.21425, 199.8],
  [-21.718825, 166.214325, 200.4],
  [-21.718836, 166.214401, 201],
  [-21.718846, 166.214486, 201.6],
  [-21.718858, 166.214572, 202.2],
  [-21.718867, 166.214647, 202.8],
  [-21.718878, 166.214744, 203.2],
  [-21.71889, 166.214819, 203.8],
  [-21.718899, 166.214894, 204.4],
  [-21.718911, 166.21498, 205],
  [-21.718921, 166.215066, 205.6],
  [-21.718931, 166.215152, 206.2],
  [-21.718941, 166.215227, 206.8],
  [-21.718951, 166.215323, 207.2],
  [-21.718961, 166.215398, 207.8],
  [-21.71897, 166.215484, 208.4],
  [-21.718977, 166.21557, 209],
  [-21.718988, 166.215656, 209.6],
  [-21.718994, 166.215752, 210.2],
  [-21.719002, 166.215827, 210.8],
  [-21.719012, 166.215913, 211.4],
  [-21.71902, 166.215988, 212],
  [-21.719027, 166.216085, 212.4],
  [-21.719033, 166.216171, 213],
  [-21.719037, 166.216257, 213.6],
  [-21.71904, 166.216342, 214.2],
  [-21.71904, 166.216439, 214.8],
  [-21.719037, 166.216525, 215.2],
  [-21.719025, 166.2166, 215.6],
  [-21.719002, 166.216697, 216],
  [-21.718968, 166.216782, 216.4],
  [-21.718925, 166.216857, 216.6],
  [-21.718878, 166.216943, 217],
  [-21.718846, 166.217029, 217.2],
  [-21.718839, 166.217136, 217.8],
  [-21.718844, 166.217233, 218.2],
  [-21.718858, 166.21734, 218.6],
  [-21.718872, 166.217426, 219.2],
  [-21.718884, 166.217533, 219.6],
  [-21.718886, 166.217641, 220],
  [-21.71885, 166.217726, 220.2],
  [-21.718785, 166.217812, 220.6],
  [-21.718709, 166.217866, 221],
  [-21.718632, 166.21793, 221.4],
  [-21.718556, 166.218005, 221.8],
  [-21.718479, 166.21807, 222.4],
  [-21.718406, 166.218145, 222.8],
  [-21.718333, 166.218209, 223],
  [-21.718261, 166.218284, 223.2],
  [-21.718194, 166.21837, 223.2],
  [-21.718131, 166.218467, 222.6],
  [-21.718073, 166.218574, 221.8],
  [-21.718018, 166.218681, 220.6],
  [-21.717963, 166.21881, 219.4],
  [-21.717901, 166.218939, 219.2],
  [-21.717837, 166.219057, 220],
  [-21.717775, 166.219196, 221.4],
  [-21.71773, 166.219304, 222.4],
  [-21.717702, 166.219422, 223.8],
  [-21.717688, 166.21955, 224.8],
  [-21.717689, 166.219658, 225.4],
  [-21.7177, 166.219765, 226.2],
  [-21.717711, 166.219883, 226.8],
  [-21.717724, 166.21999, 227.2],
  [-21.717739, 166.220087, 227.8],
  [-21.717755, 166.220183, 228.2],
  [-21.71777, 166.220291, 228.8],
  [-21.717783, 166.220398, 229.4],
  [-21.717797, 166.220505, 230],
  [-21.71781, 166.220602, 230.4],
  [-21.717824, 166.220709, 230.8],
  [-21.717837, 166.220816, 231.2],
  [-21.717848, 166.220924, 231.6],
  [-21.717858, 166.221009, 231.8],
  [-21.717868, 166.221117, 232.2],
  [-21.717879, 166.221224, 232.4],
  [-21.717896, 166.221331, 232.8],
  [-21.717915, 166.22146, 233],
  [-21.717944, 166.221557, 233.2],
  [-21.717983, 166.221653, 233.4],
  [-21.718041, 166.221761, 233.4],
  [-21.718112, 166.221846, 233.4],
  [-21.71819, 166.221932, 233.2],
  [-21.718276, 166.222018, 233],
  [-21.718363, 166.222093, 233],
  [-21.718447, 166.22219, 232.8],
  [-21.718541, 166.222275, 232.8],
  [-21.718647, 166.22234, 232.8],
  [-21.718764, 166.222383, 232.8],
  [-21.718882, 166.222436, 233],
  [-21.719002, 166.222458, 233.4],
  [-21.719119, 166.222512, 233.8],
  [-21.719234, 166.222533, 234.4],
  [-21.719347, 166.222587, 234.8],
  [-21.71946, 166.222619, 235.2],
  [-21.719571, 166.22264, 235.8],
  [-21.719681, 166.222672, 236.2],
  [-21.719794, 166.222694, 236.8],
  [-21.719901, 166.222726, 237.2],
  [-21.719956, 166.222812, 237.8],
  [-21.719892, 166.222908, 238.6],
  [-21.719788, 166.222919, 239.4],
  [-21.719685, 166.222919, 240],
  [-21.719586, 166.222919, 240.8],
  [-21.719489, 166.222919, 241.6],
  [-21.719395, 166.22293, 242.4],
  [-21.719304, 166.222951, 243.4],
  [-21.719214, 166.222951, 244.2],
  [-21.719128, 166.222962, 245],
  [-21.719047, 166.222962, 245.8],
  [-21.718964, 166.222984, 246.8],
  [-21.718886, 166.222984, 247.6],
  [-21.718809, 166.222984, 248.4],
  [-21.718737, 166.222984, 249.2],
  [-21.718664, 166.222984, 250],
  [-21.718595, 166.222984, 250.8],
  [-21.718524, 166.222984, 251.6],
  [-21.718457, 166.222984, 252.4],
  [-21.71839, 166.222984, 253],
  [-21.718324, 166.222984, 253.8],
  [-21.718258, 166.222984, 254.6],
  [-21.718194, 166.222984, 255.2],
  [-21.718131, 166.222984, 256],
  [-21.718066, 166.222984, 256.8],
  [-21.718003, 166.222984, 257.4],
  [-21.71794, 166.222984, 258.2],
  [-21.717877, 166.222962, 258.8],
  [-21.717816, 166.222962, 259.6],
  [-21.717753, 166.222962, 260.2],
  [-21.717689, 166.222962, 261],
  [-21.717629, 166.222962, 261.6],
  [-21.717566, 166.222962, 262.4],
  [-21.717504, 166.222962, 263],
  [-21.717444, 166.222962, 263.8],
  [-21.717381, 166.222951, 264.4],
  [-21.717321, 166.222951, 265.2],
  [-21.717259, 166.22293, 265.8],
  [-21.717197, 166.22293, 266.6],
  [-21.717138, 166.222919, 267.2],
  [-21.717078, 166.222908, 268],
  [-21.717019, 166.222887, 268.6],
  [-21.71696, 166.222876, 269.4],
  [-21.716901, 166.222855, 270.2],
  [-21.716842, 166.222855, 270.8],
  [-21.716782, 166.222855, 271.6],
  [-21.716725, 166.222876, 272.2],
  [-21.716677, 166.222919, 273],
  [-21.716646, 166.222962, 273.6],
  [-21.716627, 166.223027, 274.2],
  [-21.716622, 166.223102, 274.8],
  [-21.716621, 166.223166, 275.6],
  [-21.716621, 166.22322, 276.2],
  [-21.716618, 166.223305, 277],
  [-21.716618, 166.223359, 277.6],
  [-21.716618, 166.223423, 278.2],
  [-21.716618, 166.223499, 279],
  [-21.716617, 166.223563, 279.6],
  [-21.716617, 166.223617, 280.4],
  [-21.716617, 166.223702, 281],
  [-21.716617, 166.223756, 281.8],
  [-21.716617, 166.22382, 282.4],
  [-21.716614, 166.223896, 283.2],
  [-21.716609, 166.22396, 283.8],
  [-21.716603, 166.224014, 284.6],
  [-21.716595, 166.224078, 285.4],
  [-21.716587, 166.224153, 286],
  [-21.716576, 166.224217, 286.8],
  [-21.716546, 166.22426, 287.4],
  [-21.7165, 166.224303, 287.8],
  [-21.716442, 166.224335, 288.2],
  [-21.716378, 166.224368, 288.8],
  [-21.716309, 166.224389, 289.4],
  [-21.716242, 166.2244, 290.2],
  [-21.716175, 166.2244, 290.8],
  [-21.716108, 166.224411, 291.6],
  [-21.716043, 166.224411, 292.2],
  [-21.715976, 166.224411, 292.8],
  [-21.715908, 166.224432, 293.6],
  [-21.715841, 166.224432, 294.2],
  [-21.715773, 166.224432, 294.8],
  [-21.715706, 166.224432, 295.4],
  [-21.715638, 166.224432, 296.2],
  [-21.715571, 166.224432, 296.8],
  [-21.715502, 166.224443, 297.4],
  [-21.715435, 166.224443, 298.2],
  [-21.715369, 166.224443, 298.8],
  [-21.715302, 166.224443, 299.6],
  [-21.715235, 166.224443, 300.2],
  [-21.715171, 166.224443, 301],
  [-21.715104, 166.224443, 301.6],
  [-21.715039, 166.224443, 302.4],
  [-21.714974, 166.224464, 303.2],
  [-21.714911, 166.224464, 303.8],
  [-21.714846, 166.224464, 304.6],
  [-21.714783, 166.224464, 305.2],
  [-21.71472, 166.224464, 306],
  [-21.714657, 166.224464, 306.6],
  [-21.714594, 166.224464, 307.4],
  [-21.714531, 166.224464, 308],
  [-21.714471, 166.224464, 308.8],
  [-21.714408, 166.224464, 309.4],
  [-21.714346, 166.224464, 310.2],
  [-21.714283, 166.224464, 311],
  [-21.714223, 166.224464, 311.6],
  [-21.714161, 166.224464, 312.4],
  [-21.714101, 166.224464, 313],
  [-21.714039, 166.224464, 313.8],
  [-21.71398, 166.224464, 314.4],
  [-21.71392, 166.224464, 315.2],
  [-21.713858, 166.224464, 315.8],
  [-21.713798, 166.224443, 316.6],
  [-21.713739, 166.224443, 317.2],
  [-21.713677, 166.224443, 318],
  [-21.713618, 166.224443, 318.6],
  [-21.713556, 166.224443, 319.4],
  [-21.713497, 166.224443, 320],
  [-21.713437, 166.224432, 320.8],
  [-21.713378, 166.224432, 321.6],
  [-21.713319, 166.224432, 322.2],
  [-21.71326, 166.224432, 323],
  [-21.713201, 166.224411, 323.6],
  [-21.713143, 166.224411, 324.4],
  [-21.713084, 166.224411, 325.2],
  [-21.713026, 166.2244, 325.8],
  [-21.712969, 166.2244, 326.6],
  [-21.712914, 166.2244, 327.4],
  [-21.712857, 166.224389, 328.2],
  [-21.712801, 166.224389, 329],
  [-21.712746, 166.224368, 329.6],
  [-21.712693, 166.224368, 330.4],
  [-21.712639, 166.224368, 331.2],
  [-21.712588, 166.224357, 332],
  [-21.712534, 166.224357, 332.8],
  [-21.712483, 166.224335, 333.6],
  [-21.712434, 166.224335, 334.2],
  [-21.712381, 166.224335, 335],
  [-21.712332, 166.224325, 335.8],
  [-21.712282, 166.224325, 336.6],
  [-21.712235, 166.224303, 337.4],
  [-21.712186, 166.224293, 338],
  [-21.712136, 166.224293, 338.8],
  [-21.712088, 166.224282, 339.6],
  [-21.712041, 166.22426, 340.2],
  [-21.711991, 166.22426, 341],
  [-21.711943, 166.22425, 341.6],
  [-21.711896, 166.224217, 342.4],
  [-21.71185, 166.224207, 343],
  [-21.711807, 166.224174, 343.8],
  [-21.711766, 166.224121, 344.4],
  [-21.711724, 166.224099, 345],
  [-21.711681, 166.224067, 345.8],
  [-21.711636, 166.224035, 346.4],
  [-21.711585, 166.224003, 347.2],
  [-21.711531, 166.223992, 347.8],
  [-21.711476, 166.223992, 348.4],
  [-21.711418, 166.223971, 349],
  [-21.711361, 166.223971, 349.6],
  [-21.711302, 166.22396, 350.4],
  [-21.711241, 166.22396, 351],
  [-21.711182, 166.223938, 351.6],
  [-21.711119, 166.223938, 352.2],
  [-21.711058, 166.223928, 352.8],
  [-21.710995, 166.223928, 353.6],
  [-21.710934, 166.223906, 354.2],
  [-21.710873, 166.223896, 354.8],
  [-21.710814, 166.223863, 355.6],
  [-21.710757, 166.223831, 356.4],
  [-21.710701, 166.22381, 357],
  [-21.710649, 166.223778, 357.8],
  [-21.710596, 166.223745, 358.4],
  [-21.710545, 166.223713, 359.2],
  [-21.710493, 166.22367, 360],
  [-21.710443, 166.223638, 360.6],
  [-21.710393, 166.223606, 361.4],
  [-21.710342, 166.223574, 362],
  [-21.710289, 166.223541, 362.8],
  [-21.710238, 166.223499, 363.4],
  [-21.710189, 166.223466, 364.2],
  [-21.710139, 166.223434, 364.8],
  [-21.710087, 166.223413, 365.6],
  [-21.710037, 166.223359, 366.2],
  [-21.709982, 166.223348, 367],
  [-21.709923, 166.223327, 367.6],
  [-21.709864, 166.223327, 368.4],
  [-21.709806, 166.223359, 369.2],
  [-21.709756, 166.223391, 369.8],
  [-21.709707, 166.223423, 370.6],
  [-21.70966, 166.223456, 371.2],
  [-21.709605, 166.223488, 372],
  [-21.709548, 166.223488, 372.6],
  [-21.709489, 166.223488, 373.4],
  [-21.709431, 166.223466, 374],
  [-21.70938, 166.223434, 374.8],
  [-21.709334, 166.223391, 375.4],
  [-21.709298, 166.223348, 376],
  [-21.709269, 166.223284, 376.6],
  [-21.709242, 166.223241, 377.2],
  [-21.709218, 166.223177, 378],
  [-21.70919, 166.223123, 378.8],
  [-21.70916, 166.223059, 379.6],
  [-21.709129, 166.222994, 380.4],
  [-21.7091, 166.222951, 381.2],
  [-21.709065, 166.222908, 382],
  [-21.709023, 166.222855, 382.8],
  [-21.708971, 166.222844, 383.4],
  [-21.708916, 166.222833, 384],
  [-21.708861, 166.222833, 384.6],
  [-21.708802, 166.222833, 385.4],
  [-21.708744, 166.222844, 386],
  [-21.708685, 166.222855, 386.6],
  [-21.708625, 166.222876, 387],
  [-21.708563, 166.222887, 387.6],
  [-21.7085, 166.222908, 388.4],
  [-21.708437, 166.222919, 389],
  [-21.708373, 166.22293, 389.6],
  [-21.70831, 166.222951, 390.2],
  [-21.708245, 166.222962, 390.8],
  [-21.708178, 166.222984, 391.4],
  [-21.708113, 166.222994, 392],
  [-21.708047, 166.223016, 392.6],
  [-21.70798, 166.223027, 393.4],
  [-21.707913, 166.223037, 394],
  [-21.707846, 166.223059, 394.6],
  [-21.70778, 166.223069, 395.2],
  [-21.707713, 166.223091, 395.8],
  [-21.707645, 166.223102, 396.6],
  [-21.707578, 166.223123, 397.2],
  [-21.707511, 166.223134, 397.8],
  [-21.707442, 166.223145, 398.4],
  [-21.707375, 166.223166, 399.2],
  [-21.707307, 166.223177, 399.8],
  [-21.70724, 166.223198, 400.4],
  [-21.707173, 166.223209, 401.2],
  [-21.707104, 166.22322, 401.8],
  [-21.707037, 166.223252, 402.4],
  [-21.706969, 166.223273, 403.2],
  [-21.706904, 166.223284, 403.8],
  [-21.706836, 166.223305, 404.4],
  [-21.706769, 166.223316, 405],
  [-21.706702, 166.223327, 405.8],
  [-21.706635, 166.223348, 406.4],
  [-21.706566, 166.223359, 407],
  [-21.706499, 166.223381, 407.8],
  [-21.706432, 166.223391, 408.4],
  [-21.706367, 166.223413, 409],
  [-21.706299, 166.223423, 409.8],
  [-21.706232, 166.223434, 410.4],
  [-21.706165, 166.223456, 411],
  [-21.7061, 166.223466, 411.8],
  [-21.706033, 166.223488, 412.4],
  [-21.705966, 166.223499, 413],
  [-21.705898, 166.22352, 413.8],
  [-21.705831, 166.223531, 414.4],
  [-21.705766, 166.223541, 415],
  [-21.705699, 166.223563, 415.8],
  [-21.705632, 166.223574, 416.4],
  [-21.705565, 166.223595, 417],
  [-21.705499, 166.223606, 417.6],
  [-21.705432, 166.223617, 418.4],
  [-21.705366, 166.223638, 419],
  [-21.7053, 166.223649, 419.8],
  [-21.705233, 166.223649, 420.6],
  [-21.70517, 166.22367, 421.4],
  [-21.705109, 166.223681, 422.2],
  [-21.705054, 166.223713, 422.8],
  [-21.705008, 166.223756, 423.6],
  [-21.704964, 166.22381, 424.2],
  [-21.704926, 166.223863, 425],
  [-21.70489, 166.223906, 425.8],
  [-21.704855, 166.223971, 426.4],
  [-21.704822, 166.224014, 427.2],
  [-21.704785, 166.224078, 428],
  [-21.704749, 166.224121, 428.8],
  [-21.704713, 166.224174, 429.4],
  [-21.704678, 166.224217, 430.2],
  [-21.704646, 166.224282, 431],
  [-21.704611, 166.224325, 431.8],
  [-21.704579, 166.224368, 432.4],
  [-21.704544, 166.224411, 433.2],
  [-21.704512, 166.224464, 433.8],
  [-21.704478, 166.224518, 434.6],
  [-21.704443, 166.224571, 435.4],
  [-21.704409, 166.224614, 436],
  [-21.704372, 166.224657, 436.8],
  [-21.704336, 166.2247, 437.4],
  [-21.704299, 166.224754, 438.2],
  [-21.704261, 166.224786, 439],
  [-21.704221, 166.224829, 439.6],
  [-21.704181, 166.224872, 440.4],
  [-21.704139, 166.224904, 441],
  [-21.704096, 166.224947, 441.8],
  [-21.704053, 166.22499, 442.4],
  [-21.704009, 166.225022, 443.2],
  [-21.703965, 166.225054, 444],
  [-21.70392, 166.225097, 444.6],
  [-21.703875, 166.225129, 445.4],
  [-21.703829, 166.225162, 446],
  [-21.703785, 166.225204, 446.8],
  [-21.703739, 166.225237, 447.4],
  [-21.703694, 166.225269, 448.2],
  [-21.703648, 166.225301, 448.8],
  [-21.703603, 166.225333, 449.6],
  [-21.703554, 166.225376, 450.2],
  [-21.703509, 166.225408, 451],
  [-21.703463, 166.225441, 451.6],
  [-21.703416, 166.225473, 452.4],
  [-21.703369, 166.225516, 453],
  [-21.703322, 166.225548, 453.8],
  [-21.703274, 166.22558, 454.4],
  [-21.703224, 166.225601, 455],
  [-21.703175, 166.225655, 455.8],
  [-21.703124, 166.225677, 456.4],
  [-21.703073, 166.225709, 457],
  [-21.70302, 166.225741, 457.8],
  [-21.70297, 166.225773, 458.4],
  [-21.702917, 166.225805, 459.2],
  [-21.702864, 166.225848, 459.8],
  [-21.702813, 166.22588, 460.6],
  [-21.702766, 166.225913, 461.4],
  [-21.702721, 166.225955, 462.2],
  [-21.702683, 166.225998, 463],
  [-21.702654, 166.226052, 463.8],
  [-21.702637, 166.226106, 464.4],
  [-21.702628, 166.22617, 465.2],
  [-21.702622, 166.226234, 465.6],
  [-21.702622, 166.226288, 466.2],
  [-21.702626, 166.226352, 466.8],
  [-21.702628, 166.226417, 467.4],
  [-21.702632, 166.226492, 468],
  [-21.702636, 166.226556, 468.6],
  [-21.70264, 166.226631, 469.2],
  [-21.702644, 166.226685, 470],
  [-21.702646, 166.22676, 470.6],
  [-21.70265, 166.226825, 471.2],
  [-21.702654, 166.2269, 471.8],
  [-21.702658, 166.226964, 472.6],
  [-21.70266, 166.227039, 473.2],
  [-21.702664, 166.227114, 474],
  [-21.702666, 166.227179, 474.8],
  [-21.702662, 166.227254, 475.6],
  [-21.702653, 166.227318, 476.2],
  [-21.702636, 166.227372, 477],
  [-21.702614, 166.227436, 477.8],
  [-21.702591, 166.2275, 478.4],
  [-21.702565, 166.227554, 479.2],
  [-21.70254, 166.227618, 479.8],
  [-21.702514, 166.227683, 480.4],
  [-21.702483, 166.227736, 481],
  [-21.702448, 166.22779, 481.6],
  [-21.702414, 166.227844, 482.4],
  [-21.702383, 166.227908, 483],
  [-21.702353, 166.227973, 483.8],
  [-21.702323, 166.228026, 484.4],
  [-21.702292, 166.22808, 485.2],
  [-21.702256, 166.228133, 485.8],
  [-21.70222, 166.228198, 486.6],
  [-21.702177, 166.228241, 487.2],
  [-21.702135, 166.228294, 488],
  [-21.702091, 166.228337, 488.8],
  [-21.702043, 166.22838, 489.4],
  [-21.701991, 166.228412, 490.2],
  [-21.70194, 166.228445, 491],
  [-21.701886, 166.228477, 491.6],
  [-21.701832, 166.228509, 492.4],
  [-21.701779, 166.22852, 493],
  [-21.701725, 166.228552, 493.8],
  [-21.701674, 166.228584, 494.6],
  [-21.701624, 166.228616, 495.4],
  [-21.701576, 166.228638, 496],
  [-21.701531, 166.228691, 496.8],
  [-21.701489, 166.228713, 497.6],
  [-21.701447, 166.228766, 498.4],
  [-21.701411, 166.228809, 499.2],
  [-21.701376, 166.228852, 500],
  [-21.701345, 166.228906, 500.6],
  [-21.701316, 166.228949, 501.4],
  [-21.701289, 166.228992, 502.2],
  [-21.701262, 166.229035, 502.8],
  [-21.701237, 166.229088, 503.6],
  [-21.701213, 166.229142, 504.2],
  [-21.70119, 166.229196, 505],
  [-21.701167, 166.229249, 505.8],
  [-21.701144, 166.229303, 506.4],
  [-21.701123, 166.229346, 507.2],
  [-21.7011, 166.229399, 507.8],
  [-21.701079, 166.229453, 508.6],
  [-21.701058, 166.229507, 509.2],
  [-21.701037, 166.22956, 510],
  [-21.701018, 166.229614, 510.8],
  [-21.700997, 166.229668, 511.4],
  [-21.700978, 166.229721, 512.2],
  [-21.700957, 166.229775, 512.8],
  [-21.700938, 166.229829, 513.6],
  [-21.700916, 166.229882, 514.4],
  [-21.700898, 166.229936, 515],
  [-21.700879, 166.22999, 515.8],
  [-21.700857, 166.230043, 516.4],
  [-21.700839, 166.230086, 517.2],
  [-21.70082, 166.23015, 518],
  [-21.700798, 166.230215, 518.6],
  [-21.700779, 166.230258, 519.4],
  [-21.700758, 166.230322, 520],
  [-21.700738, 166.230365, 520.8],
  [-21.700718, 166.230429, 521.6],
  [-21.700699, 166.230472, 522.2],
  [-21.700682, 166.230537, 523],
  [-21.70067, 166.23058, 523.8],
  [-21.700656, 166.230644, 524.4],
  [-21.700643, 166.230698, 525.2],
  [-21.700631, 166.230751, 525.8],
  [-21.700617, 166.230805, 526.6],
  [-21.7006, 166.230859, 527.4],
  [-21.700581, 166.230912, 528],
  [-21.700554, 166.230966, 528.8],
  [-21.700523, 166.231009, 529.4],
  [-21.700487, 166.231052, 530.2],
  [-21.700446, 166.231095, 530.8],
  [-21.700401, 166.231127, 531.4],
  [-21.700354, 166.231159, 532.2],
  [-21.700302, 166.231191, 532.8],
  [-21.70025, 166.231223, 533.4],
  [-21.700196, 166.231234, 534],
  [-21.700138, 166.231266, 534.6],
  [-21.700081, 166.231277, 535.2],
  [-21.700024, 166.231309, 535.8],
  [-21.699963, 166.231331, 536.6],
  [-21.699904, 166.231363, 537.2],
  [-21.699845, 166.231374, 537.8],
  [-21.699784, 166.231384, 538.6],
  [-21.699725, 166.231416, 539.2],
  [-21.699665, 166.231449, 539.8],
  [-21.699601, 166.231438, 540.6],
  [-21.69954, 166.231416, 541.2],
  [-21.699483, 166.231406, 542],
  [-21.699425, 166.231374, 543],
  [-21.699366, 166.231374, 543.6],
  [-21.699307, 166.231374, 544.4],
  [-21.699248, 166.231384, 545],
  [-21.699193, 166.231416, 545.6],
  [-21.699137, 166.231438, 546.4],
  [-21.69908, 166.231459, 547],
  [-21.699025, 166.231492, 547.6],
  [-21.698968, 166.231524, 548.2],
  [-21.698909, 166.231545, 549],
  [-21.698851, 166.231567, 549.6],
  [-21.698792, 166.231599, 550.2],
  [-21.698733, 166.231631, 550.8],
  [-21.698674, 166.231652, 551.6],
  [-21.698615, 166.231674, 552.2],
  [-21.698556, 166.231706, 552.8],
  [-21.698497, 166.231738, 553.6],
  [-21.698438, 166.231771, 554.2],
  [-21.698378, 166.231781, 554.8],
  [-21.698319, 166.231813, 555.6],
  [-21.69826, 166.231846, 556.2],
  [-21.698199, 166.231878, 556.8],
  [-21.69814, 166.231889, 557.6],
  [-21.698079, 166.231921, 558.2],
  [-21.69802, 166.231953, 559],
  [-21.697959, 166.231964, 559.6],
  [-21.697897, 166.231996, 560.4],
  [-21.697837, 166.232017, 561],
  [-21.697777, 166.232028, 561.8],
  [-21.697716, 166.23206, 562.6],
  [-21.697655, 166.232071, 563.2],
  [-21.697596, 166.232092, 564],
  [-21.697537, 166.232103, 564.8],
  [-21.697478, 166.232125, 565.4],
  [-21.69742, 166.232135, 566.2],
  [-21.697361, 166.232167, 566.8],
  [-21.697305, 166.232178, 567.6],
  [-21.697247, 166.2322, 568.2],
  [-21.697189, 166.232232, 568.8],
  [-21.697133, 166.232243, 569.4],
  [-21.697075, 166.232275, 570.2],
  [-21.697018, 166.232307, 570.8],
  [-21.696959, 166.232339, 571.4],
  [-21.6969, 166.232361, 572],
  [-21.696841, 166.232382, 572.6],
  [-21.69678, 166.232414, 573.2],
  [-21.696719, 166.232457, 573.8],
  [-21.696658, 166.232489, 574.2],
  [-21.696597, 166.232522, 574.8],
  [-21.696536, 166.232543, 575.4],
  [-21.696473, 166.232597, 576],
  [-21.696412, 166.232629, 576.6],
  [-21.696348, 166.232672, 577.2],
  [-21.696288, 166.232704, 577.8],
  [-21.696226, 166.232747, 578.6],
  [-21.696166, 166.23279, 579.2],
  [-21.696104, 166.232822, 579.8],
  [-21.696045, 166.232865, 580.4],
  [-21.695985, 166.232919, 581],
  [-21.695925, 166.232961, 581.6],
  [-21.695866, 166.233004, 582.2],
  [-21.695807, 166.233047, 582.8],
  [-21.695748, 166.233101, 583.4],
  [-21.695689, 166.233144, 584],
  [-21.69563, 166.233208, 584.6],
  [-21.695572, 166.233251, 585.2],
  [-21.695513, 166.233294, 585.8],
  [-21.695454, 166.233337, 586.4],
  [-21.695396, 166.233401, 587.2],
  [-21.695337, 166.233444, 587.8],
  [-21.695281, 166.233498, 588.4],
  [-21.695223, 166.233552, 589],
  [-21.695168, 166.233605, 589.6],
  [-21.695113, 166.233659, 590.4],
  [-21.695057, 166.233712, 591],
  [-21.695002, 166.233766, 591.6],
  [-21.694948, 166.23382, 592.4],
  [-21.694897, 166.233873, 593],
  [-21.694845, 166.233916, 593.8],
  [-21.694794, 166.233981, 594.6],
  [-21.694747, 166.234024, 595.4],
  [-21.694703, 166.234088, 596],
  [-21.694664, 166.234152, 596.8],
  [-21.694628, 166.234195, 597.6],
  [-21.694596, 166.23426, 598.2],
  [-21.694568, 166.234335, 599],
  [-21.694542, 166.234399, 599.6],
  [-21.694519, 166.234453, 600.2],
  [-21.694499, 166.234528, 600.8],
  [-21.694483, 166.234592, 601.4],
  [-21.694468, 166.234667, 602],
  [-21.694456, 166.234742, 602.6],
  [-21.694445, 166.234818, 603],
  [-21.694433, 166.234893, 603.6],
  [-21.694424, 166.234978, 604.2],
  [-21.694416, 166.235054, 604.8],
  [-21.694407, 166.235129, 605.2],
  [-21.6944, 166.235204, 605.8],
  [-21.69439, 166.235279, 606.4],
  [-21.694382, 166.235375, 607],
  [-21.694373, 166.235451, 607.6],
  [-21.694361, 166.235526, 608.2],
  [-21.694348, 166.235601, 608.8],
  [-21.694333, 166.235687, 609.4],
  [-21.694318, 166.235772, 610.2],
  [-21.694302, 166.235847, 610.8],
  [-21.694287, 166.235923, 611.6],
  [-21.694271, 166.235998, 612.2],
  [-21.694256, 166.236073, 612.8],
  [-21.694242, 166.236148, 613.6],
  [-21.694224, 166.236223, 614.2],
  [-21.694205, 166.236298, 614.8],
  [-21.694184, 166.236373, 615.6],
  [-21.694161, 166.236459, 616.2],
  [-21.694133, 166.236513, 616.8],
  [-21.694099, 166.236588, 617.4],
  [-21.694062, 166.236652, 618],
  [-21.694008, 166.236717, 618.4],
  [-21.693945, 166.236759, 619],
  [-21.693881, 166.236802, 619.4],
  [-21.693815, 166.236856, 620],
  [-21.693753, 166.236899, 620.6],
  [-21.693692, 166.236942, 621.4],
  [-21.693629, 166.236985, 622],
  [-21.693568, 166.237038, 622.6],
  [-21.693507, 166.237092, 623.2],
  [-21.693444, 166.237146, 623.8],
  [-21.693381, 166.237189, 624.4],
  [-21.693318, 166.237231, 625],
  [-21.693255, 166.237274, 625.6],
  [-21.69319, 166.237328, 626.2],
  [-21.693127, 166.237371, 626.8],
  [-21.693063, 166.237414, 627.4],
  [-21.692997, 166.237457, 628.2],
  [-21.692933, 166.23751, 628.8],
  [-21.692868, 166.237553, 629.4],
  [-21.692801, 166.237586, 630],
  [-21.69273, 166.237618, 630.6],
  [-21.692658, 166.237639, 631.2],
  [-21.69258, 166.237628, 631.8],
  [-21.692508, 166.237618, 632.2],
  [-21.692432, 166.237586, 632.8],
  [-21.692356, 166.237586, 633.6],
  [-21.692288, 166.237618, 634.4],
  [-21.692229, 166.237671, 635],
  [-21.692175, 166.237725, 635.8],
  [-21.692124, 166.237779, 636.4],
  [-21.692073, 166.237843, 637.2],
  [-21.692023, 166.237886, 637.8],
  [-21.691974, 166.23795, 638.4],
  [-21.691926, 166.238015, 639],
  [-21.691877, 166.238068, 639.8],
  [-21.69183, 166.238122, 640.4],
  [-21.691782, 166.238176, 641],
  [-21.691735, 166.23824, 641.6],
  [-21.691687, 166.238304, 642.2],
  [-21.69164, 166.238358, 643],
  [-21.691592, 166.238422, 643.6],
  [-21.691546, 166.238487, 644],
  [-21.691504, 166.23854, 644.4],
  [-21.691452, 166.238605, 644.8],
  [-21.691389, 166.238648, 645.6],
  [-21.691325, 166.238701, 646.2],
  [-21.691258, 166.238744, 647],
  [-21.691189, 166.238776, 647.6],
  [-21.691122, 166.238809, 648.2],
  [-21.691054, 166.238852, 649],
  [-21.690989, 166.238884, 649.6],
  [-21.690922, 166.238916, 650.4],
  [-21.690855, 166.238937, 651],
  [-21.690791, 166.23897, 651.8],
  [-21.690725, 166.239002, 652.4],
  [-21.690661, 166.239045, 653],
  [-21.690601, 166.239077, 653.8],
  [-21.690539, 166.239109, 654.6],
  [-21.690478, 166.239152, 655.2],
  [-21.690421, 166.239206, 656],
  [-21.690366, 166.239227, 656.6],
  [-21.690316, 166.239291, 657.4],
  [-21.690268, 166.239334, 658.2],
  [-21.690226, 166.239388, 659],
  [-21.690187, 166.239442, 659.8],
  [-21.690154, 166.239506, 660.6],
  [-21.690122, 166.239549, 661.2],
  [-21.690092, 166.239613, 662],
  [-21.690064, 166.239678, 662.8],
  [-21.690037, 166.239731, 663.4],
  [-21.69001, 166.239796, 664.2],
  [-21.689986, 166.239839, 665],
  [-21.689961, 166.239903, 665.6],
  [-21.689933, 166.239967, 666.4],
  [-21.6899, 166.24001, 667],
  [-21.689866, 166.240075, 667.6],
  [-21.689828, 166.240118, 668.2],
  [-21.689785, 166.24016, 668.8],
  [-21.689734, 166.240203, 669.4],
  [-21.689676, 166.240236, 670],
  [-21.689612, 166.240257, 670.8],
  [-21.689549, 166.240257, 671.4],
  [-21.689486, 166.240236, 672.2],
  [-21.689426, 166.240203, 673],
  [-21.689368, 166.240193, 673.6],
  [-21.68931, 166.24016, 674.2],
  [-21.689255, 166.240128, 675],
  [-21.689207, 166.240085, 675.6],
  [-21.689164, 166.240042, 676.4],
  [-21.689124, 166.239978, 677],
  [-21.689085, 166.239935, 677.8],
  [-21.689048, 166.239871, 678.6],
  [-21.689007, 166.239828, 679.2],
  [-21.68897, 166.239785, 680],
  [-21.688931, 166.239731, 680.8],
  [-21.688891, 166.239688, 681.6],
  [-21.68885, 166.239645, 682.4],
  [-21.688809, 166.239603, 683.2],
  [-21.688765, 166.239549, 683.8],
  [-21.688723, 166.239517, 684.6],
  [-21.688679, 166.239495, 685.4],
  [-21.688637, 166.239442, 686.2],
  [-21.688597, 166.239409, 686.8],
  [-21.688561, 166.239367, 687.4],
  [-21.688529, 166.239324, 688],
  [-21.688496, 166.239259, 688.6],
  [-21.688465, 166.239216, 689.4],
  [-21.688429, 166.239173, 690.2],
  [-21.688384, 166.23912, 691],
  [-21.688329, 166.23912, 691.6],
  [-21.688271, 166.23912, 692.2],
  [-21.68822, 166.239152, 692.8],
  [-21.688193, 166.239216, 693.4],
  [-21.688195, 166.239281, 694.2],
  [-21.688217, 166.239334, 694.8],
  [-21.688244, 166.239399, 695.4],
  [-21.688271, 166.239463, 696],
  [-21.68829, 166.239517, 696.8],
  [-21.688294, 166.239603, 697.4],
  [-21.688276, 166.239656, 698],
  [-21.688239, 166.239699, 698.8],
  [-21.688187, 166.239753, 699.4],
  [-21.688126, 166.239763, 699.8],
  [-21.688062, 166.239785, 700.2],
  [-21.687995, 166.239796, 700.8],
  [-21.687946, 166.239806, 701.2],
  [-21.687924, 166.239806, 701.2],
  [-21.687922, 166.239806, 701.2],
  [-21.687901, 166.239828, 701.4],
  [-21.687867, 166.239828, 701.8],
  [-21.68782, 166.239839, 702],
  [-21.687769, 166.239839, 702.4],
  [-21.687716, 166.23986, 703],
  [-21.687661, 166.239871, 703.6],
  [-21.687606, 166.239903, 704.2],
  [-21.687552, 166.239935, 704.8],
  [-21.687503, 166.239967, 705.6],
  [-21.687457, 166.24001, 706.2],
  [-21.687413, 166.240053, 707],
  [-21.687371, 166.240096, 707.8],
  [-21.687331, 166.24015, 708.4],
  [-21.687291, 166.240193, 709.2],
  [-21.68725, 166.240236, 710],
  [-21.68721, 166.240268, 710.6],
  [-21.68717, 166.240311, 711.4],
  [-21.687131, 166.240364, 712],
  [-21.687087, 166.240407, 712.8],
  [-21.687043, 166.240439, 713.4],
  [-21.686996, 166.240482, 714],
  [-21.686943, 166.240515, 714.8],
  [-21.686888, 166.240547, 715.4],
  [-21.686831, 166.240557, 715.8],
  [-21.68677, 166.240579, 716.4],
  [-21.686707, 166.24059, 717],
  [-21.686642, 166.2406, 717.6],
  [-21.686576, 166.240622, 718],
  [-21.686509, 166.240622, 718.6],
  [-21.68644, 166.240633, 719.4],
  [-21.686372, 166.240654, 720],
  [-21.686305, 166.240665, 720.6],
  [-21.686237, 166.240686, 721.4],
  [-21.686167, 166.240697, 722],
  [-21.686102, 166.240708, 722.8],
  [-21.686039, 166.240729, 723.6],
  [-21.68598, 166.240772, 724.2],
  [-21.685931, 166.240815, 725],
  [-21.685891, 166.240869, 725.8],
  [-21.685862, 166.240922, 726.6],
  [-21.685841, 166.240987, 727.2],
  [-21.685824, 166.241051, 728],
  [-21.685803, 166.241105, 728.6],
  [-21.685771, 166.241169, 729.2],
  [-21.685722, 166.241212, 730],
  [-21.68566, 166.241233, 730.8],
  [-21.6856, 166.241233, 731.6],
  [-21.685538, 166.241233, 732.2],
  [-21.685479, 166.241233, 733],
  [-21.68542, 166.241212, 733.6],
  [-21.685361, 166.241212, 734.4],
  [-21.685302, 166.241201, 735.2],
  [-21.685243, 166.241201, 736],
  [-21.685182, 166.241201, 736.6],
  [-21.685126, 166.24118, 737.4],
  [-21.685071, 166.24118, 738.2],
  [-21.685013, 166.241169, 739],
  [-21.68496, 166.241169, 739.8],
  [-21.684905, 166.241158, 740.4],
  [-21.684851, 166.241158, 741.2],
  [-21.684798, 166.241137, 742],
  [-21.684743, 166.241137, 742.6],
  [-21.684689, 166.241126, 743.4],
  [-21.684635, 166.241126, 744.2],
  [-21.68458, 166.241126, 744.8],
  [-21.684527, 166.241126, 745.4],
  [-21.684472, 166.241105, 746.2],
  [-21.684417, 166.241126, 746.8],
  [-21.684363, 166.241137, 747.6],
  [-21.684309, 166.241158, 748.2],
  [-21.68426, 166.24118, 749],
  [-21.68421, 166.241212, 749.8],
  [-21.684163, 166.241233, 750.4],
  [-21.684115, 166.241266, 751.2],
  [-21.684067, 166.241308, 751.8],
  [-21.68402, 166.241341, 752.6],
  [-21.683971, 166.241373, 753.2],
  [-21.683924, 166.241394, 754],
  [-21.683876, 166.241426, 754.8],
  [-21.683829, 166.241459, 755.4],
  [-21.683781, 166.241491, 756.2],
  [-21.683731, 166.241523, 756.8],
  [-21.683682, 166.241534, 757.6],
  [-21.683631, 166.241566, 758.2],
  [-21.683578, 166.241598, 759],
  [-21.683528, 166.241609, 759.6],
  [-21.683477, 166.241641, 760.4],
  [-21.683423, 166.241663, 761],
  [-21.683369, 166.241684, 761.8],
  [-21.683314, 166.241705, 762.4],
  [-21.683261, 166.241716, 763.2],
  [-21.683206, 166.241738, 763.8],
  [-21.683149, 166.241759, 764.6],
  [-21.683094, 166.241781, 765.2],
  [-21.683041, 166.241791, 766],
  [-21.682986, 166.241813, 766.6],
  [-21.682931, 166.241823, 767.4],
  [-21.682873, 166.241845, 768],
  [-21.682818, 166.241856, 768.8],
  [-21.682763, 166.241888, 769.4],
  [-21.682707, 166.241899, 770.2],
  [-21.68265, 166.24192, 770.8],
  [-21.682597, 166.241931, 771.6],
  [-21.682539, 166.241952, 772.2],
  [-21.682484, 166.241963, 773],
  [-21.682429, 166.241974, 773.6],
  [-21.682371, 166.241995, 774.4],
  [-21.682317, 166.242006, 775],
  [-21.682262, 166.242027, 775.8],
  [-21.682205, 166.242038, 776.6],
  [-21.68215, 166.24207, 777.2],
  [-21.682095, 166.242081, 778],
  [-21.682042, 166.242102, 778.6],
  [-21.681987, 166.242135, 779.4],
  [-21.681934, 166.242145, 780.2],
  [-21.681882, 166.242156, 781],
  [-21.68183, 166.242188, 781.6],
  [-21.681779, 166.24221, 782.4],
  [-21.681726, 166.242242, 783],
  [-21.681675, 166.242253, 783.8],
  [-21.681625, 166.242285, 784.6],
  [-21.681572, 166.242296, 785.2],
  [-21.681521, 166.242328, 786],
  [-21.681472, 166.242349, 786.8],
  [-21.681422, 166.24236, 787.4],
  [-21.68137, 166.242392, 788.2],
  [-21.681319, 166.242403, 789],
  [-21.681269, 166.242424, 789.6],
  [-21.681218, 166.242446, 790.4],
  [-21.681166, 166.242467, 791],
  [-21.681115, 166.242499, 791.6],
  [-21.681061, 166.24251, 792.4],
  [-21.68101, 166.242532, 793],
  [-21.680957, 166.242553, 793.8],
  [-21.680903, 166.242574, 794.4],
  [-21.680849, 166.242607, 795.2],
  [-21.680796, 166.242617, 796],
  [-21.680743, 166.242639, 796.6],
  [-21.68069, 166.24266, 797.2],
  [-21.680633, 166.242682, 798],
  [-21.680578, 166.242692, 798.6],
  [-21.680522, 166.242714, 799.2],
  [-21.680464, 166.242714, 800],
  [-21.680405, 166.242725, 800.6],
  [-21.680348, 166.242746, 801.2],
  [-21.680289, 166.242746, 802],
  [-21.68023, 166.242757, 802.6],
  [-21.680168, 166.242768, 803.2],
  [-21.680109, 166.242768, 804],
  [-21.680049, 166.242789, 804.6],
  [-21.67999, 166.2428, 805.4],
  [-21.679928, 166.242821, 806],
  [-21.679868, 166.242821, 806.8],
  [-21.679809, 166.242832, 807.4],
  [-21.679747, 166.242843, 808],
  [-21.679688, 166.242864, 808.8],
  [-21.679629, 166.242875, 809.4],
  [-21.67957, 166.242896, 810.2],
  [-21.679511, 166.242929, 810.8],
  [-21.679452, 166.242939, 811.6],
  [-21.679393, 166.24295, 812.2],
  [-21.679334, 166.242971, 813],
  [-21.679276, 166.243004, 813.6],
  [-21.679217, 166.243014, 814.4],
  [-21.67916, 166.243036, 815],
  [-21.679102, 166.243057, 815.8],
  [-21.679043, 166.243079, 816.4],
  [-21.678987, 166.243089, 817.2],
  [-21.678928, 166.243122, 817.8],
  [-21.67887, 166.243132, 818.6],
  [-21.678812, 166.243165, 819.2],
  [-21.678753, 166.243186, 819.8],
  [-21.678697, 166.243197, 820.6],
  [-21.678639, 166.243229, 821.2],
  [-21.67858, 166.24324, 822],
  [-21.678523, 166.243272, 822.6],
  [-21.678465, 166.243293, 823.4],
  [-21.678406, 166.243304, 824],
  [-21.678347, 166.243325, 824.8],
  [-21.678291, 166.243347, 825.4],
  [-21.67823, 166.243368, 826.2],
  [-21.678174, 166.243379, 826.8],
  [-21.678116, 166.243411, 827.6],
  [-21.678057, 166.243433, 828.2],
  [-21.677999, 166.243454, 828.8],
  [-21.67794, 166.243476, 829.4],
  [-21.677881, 166.243486, 830.2],
  [-21.677821, 166.243519, 830.8],
  [-21.677762, 166.243529, 831.4],
  [-21.677703, 166.243562, 832],
  [-21.677643, 166.243594, 832.8],
  [-21.677588, 166.243626, 833.6],
  [-21.677533, 166.243658, 834.2],
  [-21.677482, 166.243701, 835],
  [-21.677432, 166.243733, 835.8],
  [-21.677384, 166.243776, 836.4],
  [-21.677336, 166.243819, 837.2],
  [-21.67729, 166.243851, 837.8],
  [-21.677247, 166.243905, 838.6],
  [-21.6772, 166.243948, 839.4],
  [-21.677157, 166.243991, 840],
  [-21.677112, 166.244034, 840.8],
  [-21.677067, 166.244066, 841.4],
  [-21.677022, 166.244119, 842],
  [-21.676978, 166.244162, 842.6],
  [-21.676933, 166.244205, 843.4],
  [-21.67689, 166.244248, 844],
  [-21.676845, 166.244302, 844.6],
  [-21.676801, 166.244355, 845.4],
  [-21.676756, 166.244409, 846],
  [-21.676712, 166.244452, 846.8],
  [-21.67667, 166.244495, 847.6],
  [-21.676629, 166.244538, 848.4],
  [-21.676589, 166.244591, 849],
  [-21.676551, 166.244634, 849.8],
  [-21.676512, 166.244677, 850.6],
  [-21.676475, 166.24472, 851.4],
  [-21.676438, 166.244774, 852],
  [-21.6764, 166.244817, 852.8],
  [-21.676362, 166.24486, 853.6],
  [-21.676322, 166.244903, 854.2],
  [-21.676282, 166.244956, 855],
  [-21.676241, 166.244999, 855.6],
  [-21.6762, 166.245031, 856.4],
  [-21.676155, 166.245074, 857],
  [-21.676111, 166.245117, 857.8],
  [-21.676066, 166.245149, 858.4],
  [-21.676016, 166.245182, 859],
  [-21.675966, 166.245214, 859.8],
  [-21.675915, 166.245246, 860.4],
  [-21.675864, 166.245278, 861],
  [-21.675811, 166.2453, 861.8],
  [-21.675754, 166.245332, 862.4],
  [-21.675698, 166.245353, 863],
  [-21.67564, 166.245385, 863.6],
  [-21.675583, 166.245396, 864.4],
  [-21.675524, 166.245428, 865],
  [-21.675465, 166.245439, 865.6],
  [-21.675404, 166.245471, 866.2],
  [-21.675345, 166.245493, 867],
  [-21.675284, 166.245503, 867.6],
  [-21.675221, 166.245536, 868.2],
  [-21.67516, 166.245546, 868.8],
  [-21.675097, 166.245568, 869.4],
  [-21.675034, 166.245589, 870.2],
  [-21.674971, 166.245611, 870.8],
  [-21.674907, 166.245621, 871.4],
  [-21.674841, 166.245654, 872],
  [-21.674777, 166.245675, 872.6],
  [-21.674711, 166.245686, 873.2],
  [-21.674645, 166.245718, 873.8],
  [-21.674577, 166.245729, 874.2],
  [-21.674507, 166.24575, 874.8],
  [-21.674435, 166.245761, 875],
  [-21.674358, 166.245782, 875.4],
  [-21.674279, 166.245793, 875.6],
  [-21.674196, 166.245804, 875.8],
  [-21.674114, 166.245836, 876.4],
  [-21.674038, 166.245879, 877],
  [-21.673969, 166.245933, 877.8],
  [-21.673919, 166.245986, 878.8],
  [-21.673877, 166.246072, 879.6],
  [-21.673841, 166.246147, 880.2],
  [-21.673806, 166.246222, 880.8],
  [-21.673774, 166.246297, 881.4],
  [-21.673744, 166.246372, 882],
  [-21.673716, 166.246448, 882.6],
  [-21.673688, 166.246523, 883.2],
  [-21.673658, 166.246598, 883.8],
  [-21.67363, 166.246673, 884.4],
  [-21.673602, 166.246759, 885],
  [-21.673573, 166.246834, 885.6],
  [-21.673543, 166.24692, 886.2],
  [-21.673509, 166.246995, 886.8],
  [-21.673477, 166.247059, 887.4],
  [-21.673444, 166.247134, 888.2],
  [-21.673409, 166.247209, 889],
  [-21.673375, 166.247284, 889.6],
  [-21.673345, 166.24736, 890.4],
  [-21.673315, 166.247424, 891.2],
  [-21.673287, 166.247499, 892],
  [-21.673259, 166.247563, 892.8],
  [-21.673227, 166.247628, 893.4],
  [-21.673193, 166.247703, 894.2],
  [-21.673157, 166.247746, 895],
  [-21.673119, 166.24781, 895.6],
  [-21.673079, 166.247864, 896.4],
  [-21.673035, 166.247917, 897],
  [-21.672988, 166.247971, 897.6],
  [-21.672938, 166.248025, 898.2],
  [-21.672885, 166.248046, 898.8],
  [-21.672827, 166.2481, 899.4],
  [-21.672768, 166.248132, 900],
  [-21.672705, 166.248175, 900.4],
  [-21.67264, 166.248207, 901],
  [-21.672573, 166.248239, 901.4],
  [-21.672505, 166.248261, 902],
  [-21.672433, 166.248293, 902.4],
  [-21.67236, 166.248325, 902.8],
  [-21.672284, 166.248336, 903.4],
  [-21.672207, 166.248368, 904],
  [-21.672129, 166.248389, 904.4],
  [-21.67205, 166.248422, 905],
  [-21.671971, 166.248432, 905.6],
  [-21.671891, 166.248443, 906.2],
  [-21.671813, 166.248465, 906.8],
  [-21.671733, 166.248475, 907.4],
  [-21.671655, 166.248497, 908.2],
  [-21.671574, 166.248507, 908.8],
  [-21.671496, 166.248507, 909.4],
  [-21.671417, 166.248507, 910],
  [-21.67134, 166.248529, 910.8],
  [-21.671263, 166.248529, 911.4],
  [-21.671185, 166.248529, 912],
  [-21.671109, 166.24854, 912.6],
  [-21.671032, 166.24854, 913.2],
  [-21.670956, 166.24854, 914],
  [-21.670882, 166.24854, 914.6],
  [-21.670807, 166.24854, 915.2],
  [-21.670758, 166.24854, 915.6],
  [-21.670733, 166.24855, 915.8],
  [-21.670729, 166.24855, 915.8],
  [-21.670725, 166.24855, 916],
  [-21.670699, 166.24855, 916.2],
  [-21.670658, 166.24855, 916.4],
  [-21.670613, 166.24855, 916.8],
  [-21.670562, 166.24855, 917.4],
  [-21.670505, 166.24855, 917.8],
  [-21.670449, 166.24855, 918.2],
  [-21.670387, 166.248572, 918.8],
  [-21.670324, 166.248572, 919.4],
  [-21.67026, 166.248572, 920],
  [-21.670196, 166.248583, 920.6],
  [-21.670129, 166.248583, 921],
  [-21.670061, 166.248604, 921.6],
  [-21.669994, 166.248604, 922.2],
  [-21.669929, 166.248615, 923],
  [-21.669859, 166.248626, 923.6],
  [-21.669793, 166.248658, 924.2],
  [-21.669726, 166.248679, 924.8],
  [-21.669661, 166.248711, 925.4],
  [-21.669596, 166.248722, 926.2],
  [-21.669532, 166.248754, 926.8],
  [-21.669469, 166.248786, 927.4],
  [-21.669406, 166.248819, 928],
  [-21.669343, 166.24884, 928.8],
  [-21.66928, 166.248872, 929.4],
  [-21.669217, 166.248926, 930],
  [-21.669154, 166.248947, 930.6],
  [-21.669089, 166.24898, 931.4],
  [-21.669023, 166.249001, 932],
  [-21.668959, 166.249022, 932.6],
  [-21.668895, 166.249055, 933.2],
  [-21.668829, 166.249087, 933.8],
  [-21.668765, 166.24913, 934.4],
  [-21.668702, 166.249162, 935],
  [-21.668641, 166.249216, 935.6],
  [-21.668581, 166.249259, 936.4],
  [-21.668529, 166.249301, 937.2],
  [-21.66848, 166.249344, 938],
  [-21.668444, 166.249409, 938.6],
  [-21.668413, 166.249484, 939.4],
  [-21.668385, 166.249548, 940.2],
  [-21.668353, 166.249613, 941],
  [-21.668313, 166.249655, 941.6],
  [-21.668268, 166.249709, 942.4],
  [-21.668222, 166.249763, 943],
  [-21.668173, 166.249795, 943.8],
  [-21.668124, 166.249838, 944.4],
  [-21.668073, 166.249881, 945],
  [-21.668023, 166.249924, 945.8],
  [-21.667971, 166.249956, 946.4],
  [-21.66792, 166.249999, 947.2],
  [-21.667869, 166.250052, 947.8],
  [-21.667816, 166.250085, 948.4],
  [-21.667763, 166.250128, 949.2],
  [-21.667712, 166.25017, 949.8],
  [-21.667658, 166.250203, 950.6],
  [-21.667607, 166.250246, 951.2],
  [-21.667554, 166.250278, 951.8],
  [-21.667499, 166.250321, 952.6],
  [-21.667445, 166.250353, 953.2],
  [-21.667391, 166.250396, 953.8],
  [-21.667336, 166.250428, 954.6],
  [-21.667283, 166.25046, 955.2],
  [-21.667225, 166.250503, 955.8],
  [-21.66717, 166.250535, 956.6],
  [-21.667112, 166.250567, 957.2],
  [-21.667053, 166.2506, 957.8],
  [-21.666994, 166.250632, 958.4],
  [-21.666935, 166.250664, 959],
  [-21.666875, 166.250685, 959.8],
  [-21.666813, 166.250718, 960.4],
  [-21.666753, 166.25075, 961],
  [-21.666691, 166.250782, 961.6],
  [-21.666628, 166.250814, 962.4],
  [-21.666565, 166.250825, 963],
  [-21.666502, 166.250857, 963.8],
  [-21.666442, 166.250889, 964.4],
  [-21.666379, 166.2509, 965.2],
  [-21.666317, 166.250932, 966],
  [-21.666257, 166.250954, 966.8],
  [-21.666195, 166.250964, 967.6],
  [-21.666136, 166.250986, 968.4],
  [-21.666077, 166.250997, 969.2],
  [-21.666019, 166.250997, 970],
  [-21.665964, 166.251007, 970.8],
  [-21.665909, 166.251029, 971.6],
  [-21.665854, 166.251029, 972.2],
  [-21.665798, 166.25104, 973],
  [-21.665743, 166.251061, 973.6],
  [-21.665688, 166.251072, 974.4],
  [-21.66563, 166.251072, 975],
  [-21.665574, 166.251082, 975.6],
  [-21.665515, 166.251082, 976.2],
  [-21.665455, 166.251104, 976.8],
  [-21.665396, 166.251104, 977.4],
  [-21.665335, 166.251115, 978.2],
  [-21.665272, 166.251115, 978.8],
  [-21.665209, 166.251136, 979.4],
  [-21.665146, 166.251136, 980],
  [-21.665082, 166.251136, 980.6],
  [-21.665016, 166.251147, 981.2],
  [-21.664952, 166.251147, 981.8],
  [-21.664885, 166.251168, 982.4],
  [-21.664818, 166.251168, 983],
  [-21.664752, 166.251179, 983.8],
  [-21.664685, 166.251179, 984.4],
  [-21.664618, 166.25119, 985],
  [-21.66455, 166.25119, 985.6],
  [-21.664483, 166.251211, 986.2],
  [-21.664414, 166.251211, 987],
  [-21.664346, 166.251211, 987.6],
  [-21.664277, 166.251222, 988.2],
  [-21.66421, 166.251222, 989],
  [-21.664142, 166.251243, 989.6],
  [-21.664072, 166.251243, 990.2],
  [-21.664006, 166.251254, 991],
  [-21.663937, 166.251254, 991.6],
  [-21.663871, 166.251276, 992.4],
  [-21.663804, 166.251276, 993],
  [-21.663737, 166.251286, 993.8],
  [-21.663672, 166.251286, 994.4],
  [-21.663605, 166.251297, 995.2],
  [-21.663541, 166.251318, 995.8],
  [-21.663475, 166.251318, 996.6],
  [-21.663411, 166.251329, 997.2],
  [-21.663345, 166.251329, 998],
  [-21.663284, 166.251351, 998.6],
  [-21.663218, 166.251351, 999.4],
  [-21.663155, 166.251361, 1000],
  [-21.663092, 166.251372, 1000.8],
  [-21.663027, 166.251372, 1001.4],
  [-21.662964, 166.251394, 1002.2],
  [-21.662901, 166.251394, 1002.8],
  [-21.662838, 166.251394, 1003.6],
  [-21.662775, 166.251394, 1004.2],
  [-21.662712, 166.251404, 1005],
  [-21.662649, 166.251404, 1005.6],
  [-21.662589, 166.251404, 1006.4],
  [-21.662527, 166.251394, 1007],
  [-21.662471, 166.251361, 1007.8],
  [-21.662417, 166.251329, 1008.4],
  [-21.662368, 166.251286, 1009.2],
  [-21.662319, 166.251254, 1009.8],
  [-21.662274, 166.251211, 1010.4],
  [-21.662229, 166.251147, 1011],
  [-21.662187, 166.251104, 1011.8],
  [-21.662144, 166.251061, 1012.4],
  [-21.662101, 166.251007, 1013],
  [-21.662055, 166.250954, 1013.6],
  [-21.662007, 166.2509, 1014.4],
  [-21.661953, 166.250879, 1015],
  [-21.661894, 166.250846, 1015.8],
  [-21.661831, 166.250825, 1016.4],
  [-21.661768, 166.250825, 1017],
  [-21.661704, 166.250814, 1017.6],
  [-21.661646, 166.250782, 1018.4],
  [-21.661596, 166.250718, 1019],
  [-21.661556, 166.250675, 1019.6],
  [-21.661523, 166.25061, 1020.2],
  [-21.661492, 166.250557, 1021],
  [-21.661464, 166.250482, 1021.6],
  [-21.661434, 166.250417, 1022.2],
  [-21.661407, 166.250353, 1022.8],
  [-21.661382, 166.250278, 1023.4],
  [-21.661355, 166.250213, 1024],
  [-21.66133, 166.250138, 1024.8],
  [-21.661301, 166.250085, 1025.4],
  [-21.661275, 166.25002, 1026],
  [-21.661246, 166.249945, 1026.6],
  [-21.66122, 166.249881, 1027.2],
  [-21.661198, 166.249806, 1028],
  [-21.661183, 166.249731, 1028.6],
  [-21.661171, 166.249655, 1029.2],
  [-21.661163, 166.24958, 1029.8],
  [-21.661158, 166.249516, 1030.6],
  [-21.661147, 166.249441, 1031.4],
  [-21.661127, 166.249366, 1032.2],
  [-21.661103, 166.249301, 1033.4],
  [-21.661071, 166.249259, 1034.2],
  [-21.661035, 166.249216, 1035],
  [-21.660994, 166.249162, 1035.6],
  [-21.66095, 166.249119, 1036.4],
  [-21.660906, 166.249076, 1037],
  [-21.660859, 166.249044, 1037.6],
  [-21.660811, 166.249001, 1038.2],
  [-21.660764, 166.248947, 1038.8],
  [-21.660715, 166.248904, 1039.4],
  [-21.660666, 166.248872, 1040],
  [-21.660619, 166.248829, 1040.8],
  [-21.660569, 166.248786, 1041.4],
  [-21.66052, 166.248733, 1042],
  [-21.66047, 166.24869, 1042.6],
  [-21.66042, 166.248647, 1043.4],
  [-21.660371, 166.248604, 1044],
  [-21.660318, 166.248572, 1044.6],
  [-21.660267, 166.248529, 1045.4],
  [-21.660216, 166.248475, 1046],
  [-21.660163, 166.248432, 1046.6],
  [-21.660109, 166.2484, 1047.4],
  [-21.660054, 166.248357, 1048],
  [-21.659997, 166.248325, 1048.8],
  [-21.659938, 166.248293, 1049.4],
  [-21.659878, 166.248261, 1050.2],
  [-21.659819, 166.24825, 1050.8],
  [-21.659762, 166.248218, 1051.6],
  [-21.659703, 166.248186, 1052.4],
  [-21.659644, 166.248153, 1053],
  [-21.659586, 166.248143, 1053.6],
  [-21.659539, 166.2481, 1054.2],
  [-21.659512, 166.248025, 1054.4],
  [-21.659522, 166.24796, 1054.6],
  [-21.659569, 166.247896, 1054.8],
  [-21.659642, 166.247853, 1055.2],
  [-21.659724, 166.247842, 1055.6],
  [-21.659806, 166.247842, 1056],
  [-21.659889, 166.247821, 1056.6],
  [-21.659974, 166.24781, 1057],
  [-21.660058, 166.247789, 1057.6],
  [-21.660141, 166.247778, 1058],
  [-21.660222, 166.247746, 1058.6],
  [-21.660296, 166.247703, 1059.2],
  [-21.660355, 166.247628, 1059.6],
  [-21.660381, 166.247553, 1060],
  [-21.660391, 166.247445, 1060.2],
  [-21.660397, 166.247349, 1060.6],
  [-21.660403, 166.247241, 1060.8],
  [-21.660408, 166.247156, 1061],
  [-21.660415, 166.247048, 1061.4],
  [-21.660416, 166.246941, 1061.6],
  [-21.660418, 166.246834, 1061.8],
  [-21.660418, 166.246727, 1062],
  [-21.660418, 166.246619, 1062.4],
  [-21.66042, 166.246512, 1063],
  [-21.660368, 166.246415, 1063.6],
  [-21.660289, 166.246362, 1064.2],
  [-21.6602, 166.246297, 1064.8],
  [-21.660117, 166.246254, 1065.6],
  [-21.660034, 166.246201, 1066.4],
  [-21.659956, 166.246147, 1067.4],
  [-21.659883, 166.246094, 1068.2],
  [-21.659807, 166.246051, 1069],
  [-21.659735, 166.246008, 1070],
  [-21.659665, 166.245975, 1070.8],
  [-21.659593, 166.245943, 1071.6],
  [-21.659518, 166.245933, 1072.6],
  [-21.659447, 166.245933, 1073.6],
  [-21.659378, 166.245943, 1074.4],
  [-21.659314, 166.245943, 1075.2],
  [-21.659251, 166.245965, 1076.2],
  [-21.659188, 166.245965, 1077],
  [-21.659127, 166.245975, 1077.6],
  [-21.659064, 166.245965, 1078.4],
  [-21.659008, 166.245965, 1079.2],
  [-21.658952, 166.245943, 1080],
  [-21.658894, 166.245933, 1080.6],
  [-21.658839, 166.2459, 1081.4],
  [-21.658785, 166.245879, 1082.2],
  [-21.658731, 166.245868, 1082.8],
  [-21.658677, 166.245836, 1083.6],
  [-21.658623, 166.245825, 1084.2],
  [-21.658568, 166.245804, 1085],
  [-21.658515, 166.245782, 1085.6],
  [-21.658461, 166.245761, 1086.4],
  [-21.658407, 166.245729, 1087],
  [-21.658352, 166.245718, 1087.8],
  [-21.658297, 166.245686, 1088.4],
  [-21.658244, 166.245675, 1089.2],
  [-21.658189, 166.245654, 1090],
  [-21.658136, 166.245621, 1090.6],
  [-21.658084, 166.245611, 1091.4],
  [-21.65803, 166.245589, 1092],
  [-21.657977, 166.245568, 1092.8],
  [-21.657922, 166.245546, 1093.4],
  [-21.657864, 166.245536, 1094],
  [-21.657806, 166.245503, 1094.6],
  [-21.657746, 166.245493, 1095.2],
  [-21.657687, 166.245471, 1095.6],
  [-21.657624, 166.245461, 1096.2],
  [-21.657558, 166.245439, 1096.8],
  [-21.657494, 166.245428, 1097.4],
  [-21.657426, 166.245407, 1097.8],
  [-21.657357, 166.245396, 1098.4],
  [-21.657287, 166.245385, 1099],
  [-21.657218, 166.245364, 1099.4],
  [-21.657145, 166.245332, 1100],
  [-21.657073, 166.245321, 1100.6],
  [-21.657, 166.2453, 1101.2],
  [-21.656925, 166.245289, 1101.6],
  [-21.656852, 166.245278, 1102.2],
  [-21.656777, 166.245257, 1102.8],
  [-21.6567, 166.245224, 1103.4],
  [-21.656625, 166.245214, 1103.8],
  [-21.656549, 166.245192, 1104.4],
  [-21.656472, 166.245171, 1105],
  [-21.656393, 166.245149, 1105.6],
  [-21.656318, 166.245117, 1106],
  [-21.656239, 166.245106, 1106.6],
  [-21.656161, 166.245074, 1107],
  [-21.656085, 166.245042, 1107.6],
  [-21.656007, 166.24501, 1108],
  [-21.655926, 166.244988, 1108.6],
  [-21.655846, 166.244956, 1109],
  [-21.655767, 166.244924, 1109.6],
  [-21.655684, 166.244892, 1110],
  [-21.655602, 166.244881, 1110.6],
  [-21.65552, 166.244849, 1111],
  [-21.655437, 166.244817, 1111.6],
  [-21.655352, 166.244806, 1112.2],
  [-21.655268, 166.244785, 1112.8],
  [-21.655185, 166.244774, 1113.4],
  [-21.655099, 166.244752, 1114],
  [-21.655014, 166.244752, 1114.6],
  [-21.654931, 166.244752, 1115.4],
  [-21.654847, 166.244752, 1116],
  [-21.654765, 166.244752, 1116.6],
  [-21.654683, 166.244752, 1117.2],
  [-21.654603, 166.244752, 1118],
  [-21.654522, 166.244752, 1118.6],
  [-21.654443, 166.244774, 1119.2],
  [-21.654364, 166.244774, 1119.8],
  [-21.654286, 166.244752, 1120.6],
  [-21.654195, 166.244742, 1121.2],
  [-21.654133, 166.24472, 1121.6],
  [-21.654066, 166.244677, 1122],
  [-21.654019, 166.244602, 1122],
  [-21.654014, 166.244506, 1121.8],
  [-21.65405, 166.24442, 1122],
  [-21.65411, 166.244345, 1122.4],
  [-21.654183, 166.24427, 1123],
  [-21.654261, 166.244216, 1123.4],
  [-21.654341, 166.244162, 1124],
  [-21.654422, 166.244098, 1124.6],
  [-21.654503, 166.244055, 1125],
  [-21.654585, 166.244012, 1125.6],
  [-21.654667, 166.243958, 1126],
  [-21.65475, 166.243905, 1126.6],
  [-21.654832, 166.243851, 1127.2],
  [-21.654914, 166.243808, 1127.8],
  [-21.654994, 166.243765, 1128.4],
  [-21.655073, 166.243722, 1129],
  [-21.655154, 166.243669, 1129.6],
  [-21.65523, 166.243626, 1130.4],
  [-21.655307, 166.243583, 1131.2],
  [-21.65538, 166.243529, 1132],
  [-21.655453, 166.243508, 1132.8],
  [-21.655525, 166.243454, 1133.6],
  [-21.655596, 166.243411, 1134.2],
  [-21.655665, 166.243379, 1135],
  [-21.655732, 166.243347, 1135.8],
  [-21.6558, 166.243304, 1136.6],
  [-21.655866, 166.243272, 1137.2],
  [-21.655932, 166.24324, 1137.8],
  [-21.655997, 166.243197, 1138.4],
  [-21.656063, 166.243165, 1139],
  [-21.65613, 166.243132, 1139.6],
  [-21.656199, 166.243111, 1140.2],
  [-21.656268, 166.243057, 1140.6],
  [-21.656338, 166.243036, 1141.2],
  [-21.65641, 166.243004, 1141.6],
  [-21.656483, 166.24295, 1142],
  [-21.656558, 166.242929, 1142.6],
  [-21.656632, 166.242896, 1143],
  [-21.656707, 166.242843, 1143.6],
  [-21.656781, 166.242821, 1144],
  [-21.656857, 166.242768, 1144.6],
  [-21.656933, 166.242746, 1145],
  [-21.657006, 166.242692, 1145.8],
  [-21.657064, 166.242617, 1146.6],
  [-21.657104, 166.242553, 1147.4],
  [-21.657127, 166.242467, 1148.4],
  [-21.65714, 166.242392, 1149.2],
  [-21.657143, 166.242317, 1150],
  [-21.65714, 166.242242, 1150.6],
  [-21.657127, 166.242156, 1151.4],
  [-21.657106, 166.242081, 1152],
  [-21.657084, 166.242006, 1152.6],
  [-21.657057, 166.241952, 1153.2],
  [-21.657027, 166.241866, 1153.8],
  [-21.656991, 166.241813, 1154.2],
  [-21.656944, 166.241748, 1154.8],
  [-21.656889, 166.241684, 1155.4],
  [-21.656829, 166.241641, 1156],
  [-21.656762, 166.241598, 1156.4],
  [-21.656693, 166.241566, 1157],
  [-21.656625, 166.241534, 1157.6],
  [-21.656554, 166.241491, 1158.2],
  [-21.656483, 166.241459, 1159],
  [-21.656417, 166.241416, 1159.8],
  [-21.65635, 166.241384, 1160.6],
  [-21.656284, 166.241351, 1161.4],
  [-21.65622, 166.241308, 1162],
  [-21.656156, 166.241287, 1162.8],
  [-21.656083, 166.241266, 1163.6],
  [-21.65602, 166.241244, 1164],
  [-21.655952, 166.241233, 1164.6],
  [-21.655878, 166.241233, 1165],
  [-21.655804, 166.241233, 1165.4],
  [-21.655726, 166.241233, 1165.8],
  [-21.655647, 166.241244, 1166.2],
  [-21.655566, 166.241244, 1166.6],
  [-21.655482, 166.241266, 1167],
  [-21.655398, 166.241266, 1167.4],
  [-21.655313, 166.241244, 1168],
  [-21.65523, 166.241233, 1168.6],
  [-21.655148, 166.241212, 1169.4],
  [-21.655069, 166.241201, 1170.2],
  [-21.654994, 166.241169, 1171],
  [-21.654919, 166.241137, 1171.8],
  [-21.654847, 166.241126, 1172.6],
  [-21.654774, 166.241094, 1173.2],
  [-21.654703, 166.241072, 1174],
  [-21.654635, 166.241051, 1174.6],
  [-21.654565, 166.24103, 1175.2],
  [-21.654494, 166.240997, 1175.8],
  [-21.654423, 166.240987, 1176.4],
  [-21.65435, 166.240954, 1176.8],
  [-21.654278, 166.240944, 1177.4],
  [-21.654204, 166.240911, 1177.8],
  [-21.654128, 166.240879, 1178.4],
  [-21.654051, 166.240869, 1179],
  [-21.653975, 166.240847, 1179.6],
  [-21.653897, 166.240836, 1180.2],
  [-21.653821, 166.240815, 1181],
  [-21.653743, 166.240804, 1181.6],
  [-21.653666, 166.240804, 1182.2],
  [-21.65359, 166.240783, 1183],
  [-21.653518, 166.240761, 1183.6],
  [-21.653459, 166.240708, 1184.2],
  [-21.653424, 166.240633, 1184.8],
  [-21.653432, 166.240557, 1185],
  [-21.653479, 166.240482, 1185.2],
  [-21.653548, 166.240439, 1185.4],
  [-21.65363, 166.240407, 1185.4],
  [-21.653717, 166.240375, 1185.6],
  [-21.653799, 166.240311, 1185.8],
  [-21.653868, 166.240257, 1186.2],
  [-21.653928, 166.24016, 1186.6],
  [-21.653984, 166.240085, 1187.2],
  [-21.654042, 166.24, 1187.6],
  [-21.654097, 166.239914, 1188.2],
  [-21.654152, 166.239828, 1188.6],
  [-21.654208, 166.239731, 1189],
  [-21.654263, 166.239656, 1189.6],
  [-21.654317, 166.23957, 1190],
  [-21.654372, 166.239495, 1190.6],
  [-21.65443, 166.239399, 1191],
  [-21.654482, 166.239324, 1191.6],
  [-21.654538, 166.239227, 1192],
  [-21.654588, 166.239152, 1192.6],
  [-21.654635, 166.239066, 1193.2],
  [-21.654675, 166.23897, 1194],
  [-21.654707, 166.238884, 1194.8],
  [-21.654726, 166.238787, 1195.4],
  [-21.654734, 166.238701, 1195.8],
  [-21.654713, 166.238605, 1196.2],
  [-21.654666, 166.23853, 1196.6],
  [-21.654601, 166.238455, 1197.2],
  [-21.654529, 166.23839, 1197.6],
  [-21.65445, 166.238347, 1198.2],
  [-21.654367, 166.238304, 1198.6],
  [-21.654278, 166.238283, 1199.2],
  [-21.654188, 166.238251, 1199.6],
  [-21.654101, 166.238229, 1200.2],
  [-21.654014, 166.238197, 1200.6],
  [-21.653925, 166.238165, 1201.2],
  [-21.653839, 166.238143, 1201.8],
  [-21.653752, 166.238122, 1202.2],
  [-21.653664, 166.23809, 1202.8],
  [-21.653577, 166.238058, 1203.2],
  [-21.653488, 166.238025, 1203.8],
  [-21.653409, 166.238015, 1204.2],
  [-21.65335, 166.237993, 1204.6],
  [-21.653314, 166.237983, 1204.8],
  [-21.653298, 166.237983, 1204.8],
  [-21.653291, 166.237983, 1205],
  [-21.65326, 166.237961, 1205],
  [-21.65322, 166.237961, 1205.4],
  [-21.653172, 166.23795, 1205.6],
  [-21.653119, 166.237918, 1206],
  [-21.653059, 166.237907, 1206.2],
  [-21.652996, 166.237875, 1206.6],
  [-21.652932, 166.237854, 1207],
  [-21.652862, 166.237843, 1207.4],
  [-21.652791, 166.237811, 1207.8],
  [-21.652716, 166.2378, 1208.4],
  [-21.652642, 166.237768, 1208.8],
  [-21.652564, 166.237746, 1209.2],
  [-21.652485, 166.237736, 1209.6],
  [-21.652406, 166.237725, 1210.2],
  [-21.652325, 166.237693, 1210.6],
  [-21.652245, 166.237661, 1211.2],
  [-21.652171, 166.237628, 1211.6],
  [-21.652109, 166.237564, 1212.2],
  [-21.652087, 166.237478, 1212.4],
  [-21.652104, 166.237382, 1212.6],
  [-21.652146, 166.237296, 1213.2],
  [-21.652199, 166.237221, 1213.8],
  [-21.652254, 166.237146, 1214.4],
  [-21.652313, 166.237081, 1215.2],
  [-21.652372, 166.237006, 1215.8],
  [-21.652434, 166.236942, 1216.6],
  [-21.652497, 166.236877, 1217.2],
  [-21.652557, 166.236824, 1217.8],
  [-21.65262, 166.236759, 1218.4],
  [-21.652683, 166.236717, 1219.2],
  [-21.652749, 166.236652, 1219.8],
  [-21.652818, 166.236609, 1220.4],
  [-21.652886, 166.236566, 1221],
  [-21.652957, 166.236534, 1221.6],
  [-21.653027, 166.23648, 1222.4],
  [-21.653098, 166.236438, 1223],
  [-21.653166, 166.236405, 1223.6],
  [-21.653233, 166.236362, 1224.2],
  [-21.653302, 166.23632, 1224.8],
  [-21.65337, 166.236266, 1225.4],
  [-21.653439, 166.236244, 1226],
  [-21.653506, 166.236191, 1226.6],
  [-21.653565, 166.236137, 1227.4],
  [-21.653614, 166.236073, 1228],
  [-21.653658, 166.236008, 1228.8],
  [-21.653696, 166.235933, 1229.6],
  [-21.653731, 166.23588, 1230.2],
  [-21.653763, 166.235794, 1230.8],
  [-21.653794, 166.23574, 1231.6],
  [-21.653815, 166.235676, 1232],
  [-21.653825, 166.235665, 1232.2],
  [-21.653825, 166.235644, 1232.4],
  [-21.653831, 166.235633, 1232.4],
  [-21.653844, 166.235601, 1232.8],
  [-21.653861, 166.235558, 1233.2],
  [-21.653881, 166.235504, 1233.6],
  [-21.653903, 166.235451, 1234.2],
  [-21.653921, 166.235397, 1234.6],
  [-21.653939, 166.235343, 1235.2],
  [-21.653956, 166.235279, 1235.8],
  [-21.653978, 166.235204, 1236.4],
  [-21.654, 166.235139, 1237],
  [-21.654023, 166.235086, 1237.6],
  [-21.654046, 166.235021, 1238.2],
  [-21.654063, 166.234946, 1238.6],
  [-21.654076, 166.234871, 1239.2],
  [-21.654088, 166.234807, 1239.6],
  [-21.654097, 166.234732, 1240.2],
  [-21.6541, 166.234635, 1240.6],
  [-21.654089, 166.23456, 1241.4],
  [-21.654076, 166.234485, 1242],
  [-21.654057, 166.23441, 1242.8],
  [-21.654037, 166.234345, 1243.6],
  [-21.654011, 166.23427, 1244.4],
  [-21.653984, 166.234206, 1245.2],
  [-21.653953, 166.234152, 1245.8],
  [-21.653917, 166.234088, 1246.2],
  [-21.653876, 166.234024, 1246.6],
  [-21.653829, 166.23397, 1246.8],
  [-21.653775, 166.233895, 1247],
  [-21.653716, 166.23383, 1247],
  [-21.653653, 166.233766, 1247.2],
  [-21.65359, 166.233712, 1247.6],
  [-21.653523, 166.233627, 1248],
  [-21.653459, 166.233573, 1248.6],
  [-21.653393, 166.233498, 1249.2],
  [-21.653329, 166.233433, 1249.8],
  [-21.653264, 166.233358, 1250.4],
  [-21.653198, 166.233294, 1251],
  [-21.653134, 166.23323, 1251.6],
  [-21.653071, 166.233176, 1252.2],
  [-21.653007, 166.233112, 1252.8],
  [-21.652941, 166.233047, 1253.4],
  [-21.652873, 166.232994, 1254.2],
  [-21.652801, 166.23294, 1255],
  [-21.652729, 166.232897, 1255.6],
  [-21.652659, 166.232865, 1256.2],
  [-21.652594, 166.232811, 1256.6],
  [-21.652548, 166.232736, 1257],
  [-21.652524, 166.23265, 1257.6],
  [-21.652541, 166.232564, 1258],
  [-21.6526, 166.232489, 1258],
  [-21.652677, 166.232446, 1258.2],
  [-21.652759, 166.232393, 1258.8],
  [-21.65284, 166.23235, 1259.4],
  [-21.652922, 166.232307, 1260],
  [-21.653004, 166.232253, 1260.6],
  [-21.653084, 166.232232, 1261.2],
  [-21.653165, 166.232178, 1261.8],
  [-21.653244, 166.232135, 1262.4],
  [-21.653325, 166.232092, 1263],
  [-21.653405, 166.23206, 1263.6],
  [-21.653483, 166.232017, 1264.2],
  [-21.653561, 166.231964, 1264.6],
  [-21.653641, 166.231942, 1265.2],
  [-21.653717, 166.231889, 1265.8],
  [-21.653794, 166.231846, 1266.4],
  [-21.653866, 166.231803, 1267],
  [-21.653939, 166.23176, 1267.6],
  [-21.654007, 166.231695, 1268.2],
  [-21.65407, 166.231631, 1269],
  [-21.654128, 166.231567, 1269.6],
  [-21.654177, 166.231492, 1270.2],
  [-21.654222, 166.231438, 1271],
  [-21.654261, 166.231363, 1271.6],
  [-21.654299, 166.231277, 1272.2],
  [-21.654336, 166.231202, 1272.8],
  [-21.654369, 166.231127, 1273.6],
  [-21.654407, 166.231052, 1274.2],
  [-21.65444, 166.230977, 1274.8],
  [-21.654475, 166.230901, 1275.4],
  [-21.654509, 166.230826, 1276],
  [-21.654544, 166.230751, 1276.6],
  [-21.654576, 166.230676, 1277.2],
  [-21.654608, 166.23059, 1278],
  [-21.65464, 166.230515, 1278.6],
  [-21.654671, 166.23044, 1279.2],
  [-21.654702, 166.230365, 1279.8],
  [-21.65473, 166.23029, 1280.4],
  [-21.654757, 166.230215, 1281],
  [-21.654782, 166.23014, 1281.6],
  [-21.654805, 166.230065, 1282.2],
  [-21.654824, 166.229968, 1282.8],
  [-21.654843, 166.229893, 1283.4],
  [-21.654861, 166.229818, 1284],
  [-21.654882, 166.229721, 1284.6],
  [-21.6549, 166.229646, 1285.2],
  [-21.654919, 166.229571, 1285.8],
  [-21.654938, 166.229485, 1286.4],
  [-21.654959, 166.229399, 1287],
  [-21.654978, 166.229324, 1287.6],
  [-21.654997, 166.229239, 1288.2],
  [-21.655013, 166.229163, 1288.8],
  [-21.655028, 166.229088, 1289.4],
  [-21.65504, 166.228992, 1290],
  [-21.655051, 166.228917, 1290.6],
  [-21.65506, 166.22882, 1291.2],
  [-21.655069, 166.228745, 1291.8],
  [-21.655077, 166.228659, 1292.4],
  [-21.655085, 166.228584, 1293],
  [-21.655093, 166.228487, 1293.6],
  [-21.6551, 166.228412, 1294.2],
  [-21.655106, 166.228327, 1294.8],
  [-21.655112, 166.228241, 1295.4],
  [-21.655112, 166.228155, 1296],
  [-21.655106, 166.22808, 1296.6],
  [-21.655093, 166.228005, 1297.4],
  [-21.655072, 166.227908, 1298],
  [-21.655046, 166.227833, 1298.6],
  [-21.65502, 166.227758, 1299.4],
  [-21.654994, 166.227683, 1300],
  [-21.654965, 166.227618, 1300.8],
  [-21.654934, 166.227543, 1301.4],
  [-21.654902, 166.227468, 1302],
  [-21.654869, 166.227404, 1302.8],
  [-21.654832, 166.227329, 1303.4],
  [-21.654786, 166.227264, 1304.2],
  [-21.65473, 166.227221, 1305],
  [-21.654667, 166.227189, 1305.6],
  [-21.654597, 166.227189, 1306.2],
  [-21.654529, 166.227189, 1306.8],
  [-21.654458, 166.227211, 1307.6],
  [-21.654389, 166.227221, 1308.2],
  [-21.654321, 166.227243, 1308.8],
  [-21.654251, 166.227254, 1309.4],
  [-21.654183, 166.227264, 1310],
  [-21.654114, 166.227286, 1310.6],
  [-21.654043, 166.227318, 1311.2],
  [-21.653975, 166.227329, 1312],
  [-21.653904, 166.22734, 1312.6],
  [-21.653835, 166.227361, 1313.2],
  [-21.653766, 166.227372, 1313.8],
  [-21.653695, 166.227393, 1314.4],
  [-21.653624, 166.227404, 1315.2],
  [-21.653554, 166.227425, 1315.8],
  [-21.653483, 166.227436, 1316.4],
  [-21.653414, 166.227447, 1317.2],
  [-21.653343, 166.227468, 1317.8],
  [-21.653274, 166.227479, 1318.4],
  [-21.653205, 166.227479, 1319.2],
  [-21.653134, 166.2275, 1319.8],
  [-21.653063, 166.2275, 1320.4],
  [-21.652995, 166.2275, 1321],
  [-21.652924, 166.227479, 1321.2],
  [-21.652858, 166.227447, 1321.4],
  [-21.652799, 166.227393, 1321.4],
  [-21.652746, 166.227318, 1321.6],
  [-21.652702, 166.227221, 1322],
  [-21.652663, 166.227146, 1322.4],
  [-21.652628, 166.22705, 1322.8],
  [-21.652591, 166.226964, 1323],
  [-21.652547, 166.226867, 1323.2],
  [-21.65251, 166.226782, 1323.6],
  [-21.652488, 166.226674, 1324.2],
  [-21.652478, 166.226567, 1324.8],
  [-21.652481, 166.22646, 1325.6],
  [-21.652492, 166.226363, 1326.2],
  [-21.652505, 166.226277, 1327],
  [-21.65252, 166.226181, 1327.6],
  [-21.652537, 166.226095, 1328.4],
  [-21.652555, 166.225998, 1329.2],
  [-21.652571, 166.225913, 1329.8],
  [-21.652588, 166.225837, 1330.6],
  [-21.652606, 166.225741, 1331.2],
  [-21.652623, 166.225666, 1332],
  [-21.652638, 166.22558, 1332.6],
  [-21.652651, 166.225494, 1333.4],
  [-21.652665, 166.225408, 1334],
  [-21.652678, 166.225333, 1334.8],
  [-21.652692, 166.225258, 1335.4],
  [-21.652705, 166.225162, 1336],
  [-21.652716, 166.225086, 1336.8],
  [-21.652729, 166.225011, 1337.4],
  [-21.652742, 166.224936, 1338],
  [-21.652756, 166.224861, 1338.8],
  [-21.652769, 166.224786, 1339.4],
  [-21.652783, 166.2247, 1340],
  [-21.652796, 166.224625, 1340.6],
  [-21.652812, 166.22455, 1341.2],
  [-21.652827, 166.224464, 1341.8],
  [-21.652842, 166.224389, 1342.4],
  [-21.652858, 166.224303, 1343],
  [-21.652874, 166.224228, 1343.6],
  [-21.65289, 166.224153, 1344.2],
  [-21.652909, 166.224078, 1344.8],
  [-21.652926, 166.224003, 1345.4],
  [-21.652944, 166.223928, 1346],
  [-21.652962, 166.223831, 1346.4],
  [-21.652981, 166.223756, 1347],
  [-21.653003, 166.223681, 1347.6],
  [-21.653021, 166.223595, 1348.2],
  [-21.653043, 166.22352, 1348.6],
  [-21.653063, 166.223434, 1349.2],
  [-21.653086, 166.223348, 1349.8],
  [-21.653107, 166.223273, 1350.4],
  [-21.653131, 166.223177, 1350.8],
  [-21.653161, 166.223102, 1351.4],
  [-21.653192, 166.223016, 1351.8],
  [-21.653221, 166.22293, 1352.4],
  [-21.653253, 166.222855, 1352.8],
  [-21.653283, 166.222769, 1353.4],
  [-21.653307, 166.222672, 1354],
  [-21.653325, 166.222597, 1354.6],
  [-21.653329, 166.222512, 1355.4],
  [-21.65332, 166.222415, 1356.2],
  [-21.6533, 166.22234, 1357],
  [-21.653274, 166.222265, 1357.8],
  [-21.653241, 166.22219, 1358.6],
  [-21.653206, 166.222125, 1359.4],
  [-21.653176, 166.22205, 1360.2],
  [-21.653147, 166.221986, 1361],
  [-21.653122, 166.221911, 1361.8],
  [-21.653095, 166.221846, 1362.4],
  [-21.65307, 166.221771, 1363.2],
  [-21.65304, 166.221718, 1364],
  [-21.653012, 166.221653, 1364.6],
  [-21.652984, 166.221589, 1365.4],
  [-21.652954, 166.221535, 1366],
  [-21.652928, 166.221471, 1366.6],
  [-21.652903, 166.221396, 1367.4],
  [-21.652877, 166.221331, 1368],
  [-21.652854, 166.221267, 1368.6],
  [-21.652828, 166.221213, 1369.4],
  [-21.652804, 166.221138, 1370],
  [-21.652779, 166.221074, 1370.6],
  [-21.652756, 166.221009, 1371.2],
  [-21.652732, 166.220934, 1372],
  [-21.652706, 166.22087, 1372.6],
  [-21.652683, 166.220795, 1373.2],
  [-21.652659, 166.220741, 1373.8],
  [-21.652634, 166.220677, 1374.6],
  [-21.65261, 166.220602, 1375.2],
  [-21.652587, 166.220537, 1375.8],
  [-21.652561, 166.220462, 1376.4],
  [-21.652537, 166.220398, 1377.2],
  [-21.652514, 166.220323, 1377.8],
  [-21.652497, 166.220269, 1378.2],
  [-21.652489, 166.220183, 1378.8],
  [-21.652488, 166.220108, 1379.2],
  [-21.652497, 166.220033, 1379.6],
  [-21.652514, 166.219947, 1380],
  [-21.652543, 166.219872, 1380.4],
  [-21.652579, 166.219786, 1380.8],
  [-21.65263, 166.219711, 1381.2],
  [-21.652696, 166.219668, 1381.8],
  [-21.652768, 166.219625, 1382.4],
  [-21.652844, 166.219593, 1382.8],
  [-21.652922, 166.21955, 1383.4],
  [-21.653, 166.219518, 1383.8],
  [-21.65308, 166.219486, 1384.4],
  [-21.653161, 166.219454, 1385],
  [-21.653239, 166.219422, 1385.4],
  [-21.653319, 166.2194, 1386],
  [-21.6534, 166.219368, 1386.6],
  [-21.653479, 166.219314, 1387],
  [-21.653559, 166.219293, 1387.6],
  [-21.65364, 166.219261, 1388.2],
  [-21.65372, 166.219229, 1388.8],
  [-21.653795, 166.219186, 1389.4],
  [-21.65387, 166.219132, 1390],
  [-21.653939, 166.219078, 1390.8],
  [-21.653994, 166.219014, 1391.6],
  [-21.654033, 166.218939, 1392.4],
  [-21.654063, 166.218864, 1393.2],
  [-21.654092, 166.218789, 1394],
  [-21.654116, 166.218714, 1394.6],
  [-21.654133, 166.218628, 1395.4],
  [-21.654147, 166.218553, 1396.2],
  [-21.654151, 166.218477, 1396.8],
  [-21.654149, 166.218402, 1397.8],
  [-21.654141, 166.218327, 1398.6],
  [-21.654129, 166.218263, 1399.4],
  [-21.654116, 166.218188, 1400.2],
  [-21.6541, 166.218123, 1401],
  [-21.65408, 166.21807, 1401.6],
  [-21.654059, 166.218005, 1402.4],
  [-21.654038, 166.217941, 1403.2],
  [-21.654014, 166.217887, 1403.8],
  [-21.653984, 166.217823, 1404.4],
  [-21.653953, 166.217759, 1405],
  [-21.65392, 166.217705, 1405.6],
  [-21.653881, 166.217651, 1406.2],
  [-21.653844, 166.217598, 1406.8],
  [-21.653803, 166.217533, 1407.4],
  [-21.653762, 166.217469, 1408],
  [-21.65372, 166.217426, 1408.6],
  [-21.653676, 166.217362, 1409.2],
  [-21.653634, 166.217308, 1409.8],
  [-21.653594, 166.217244, 1410.4],
  [-21.653551, 166.217179, 1411],
  [-21.65351, 166.217126, 1411.6],
  [-21.653468, 166.217061, 1412.2],
  [-21.653424, 166.216997, 1412.8],
  [-21.653379, 166.216943, 1413.4],
  [-21.653337, 166.216879, 1414],
  [-21.653292, 166.216804, 1414.6],
  [-21.653248, 166.216739, 1415.4],
  [-21.653205, 166.216675, 1416],
  [-21.653161, 166.216621, 1416.6],
  [-21.653119, 166.216557, 1417.4],
  [-21.653076, 166.216493, 1418],
  [-21.653039, 166.216439, 1418.8],
  [-21.653012, 166.216375, 1419.6],
  [-21.652996, 166.2163, 1420.2],
  [-21.652996, 166.216224, 1421],
  [-21.653013, 166.21616, 1421.8],
  [-21.653046, 166.216096, 1422.6],
  [-21.653094, 166.216053, 1423.2],
  [-21.653152, 166.21601, 1423.8],
  [-21.653215, 166.215988, 1424.6],
  [-21.653278, 166.215988, 1425.2],
  [-21.653342, 166.215988, 1426],
  [-21.653409, 166.215988, 1426.4],
  [-21.653475, 166.215988, 1426.8],
  [-21.653548, 166.215988, 1426.8],
  [-21.653626, 166.215988, 1426.8],
  [-21.653709, 166.21601, 1426.8],
  [-21.653799, 166.21601, 1427],
  [-21.653893, 166.21601, 1427],
  [-21.653988, 166.21601, 1427],
  [-21.654086, 166.215988, 1427.4],
  [-21.654179, 166.215967, 1427.8],
  [-21.654265, 166.215903, 1428.4],
  [-21.654344, 166.215838, 1429],
  [-21.654413, 166.215763, 1429.8],
  [-21.654479, 166.215699, 1430.4],
  [-21.654536, 166.215613, 1431.2],
  [-21.654576, 166.215549, 1432],
  [-21.654597, 166.215484, 1432.6],
  [-21.654607, 166.215463, 1432.8],
  [-21.654608, 166.215463, 1432.8],
  [-21.654617, 166.21543, 1433],
  [-21.654631, 166.215398, 1433.4],
  [-21.654651, 166.215355, 1433.8],
  [-21.654674, 166.215302, 1434.4],
  [-21.6547, 166.215259, 1434.8],
  [-21.654726, 166.215194, 1435.4],
  [-21.654755, 166.215152, 1435.8],
  [-21.654782, 166.215087, 1436.4],
  [-21.65481, 166.215034, 1437],
  [-21.654837, 166.214969, 1437.6],
  [-21.654864, 166.214905, 1438.2],
  [-21.654891, 166.214851, 1438.8],
  [-21.654918, 166.214787, 1439.4],
  [-21.654942, 166.214712, 1440],
  [-21.654965, 166.214647, 1440.6],
  [-21.654987, 166.214594, 1441.2],
  [-21.65501, 166.214508, 1441.8],
  [-21.65503, 166.214433, 1442.4],
  [-21.655049, 166.214379, 1443],
  [-21.655067, 166.214293, 1443.6],
  [-21.655081, 166.214218, 1444.2],
  [-21.655095, 166.214143, 1444.8],
  [-21.655108, 166.214068, 1445.4],
  [-21.655119, 166.213993, 1446.2],
  [-21.65513, 166.213918, 1446.8],
  [-21.655136, 166.213843, 1447.4],
  [-21.65514, 166.213768, 1448],
  [-21.65514, 166.213692, 1448.8],
  [-21.655136, 166.213607, 1449.4],
  [-21.655127, 166.213531, 1450],
  [-21.655116, 166.213456, 1450.8],
  [-21.655103, 166.213381, 1451.4],
  [-21.655089, 166.213317, 1452.2],
  [-21.655076, 166.213242, 1452.8],
  [-21.65506, 166.213167, 1453.4],
  [-21.655046, 166.213092, 1454.2],
  [-21.655033, 166.213027, 1454.8],
  [-21.65502, 166.212952, 1455.6],
  [-21.655008, 166.212877, 1456.2],
  [-21.654994, 166.212802, 1456.8],
  [-21.654978, 166.212738, 1457.6],
  [-21.654965, 166.212662, 1458.2],
  [-21.654951, 166.212587, 1458.8],
  [-21.654937, 166.212534, 1459.6],
  [-21.654923, 166.212448, 1460.2],
  [-21.654911, 166.212373, 1461],
  [-21.654902, 166.212319, 1461.8],
  [-21.654892, 166.212233, 1462.4],
  [-21.654883, 166.21218, 1463.2],
  [-21.654875, 166.212105, 1464],
  [-21.654865, 166.21204, 1464.6],
  [-21.654855, 166.211965, 1465.4],
  [-21.654841, 166.211901, 1466.2],
  [-21.654828, 166.211847, 1466.8],
  [-21.654819, 166.211783, 1467.4],
  [-21.654809, 166.211708, 1468.2],
  [-21.654801, 166.211643, 1468.8],
  [-21.654788, 166.211579, 1469.6],
  [-21.65477, 166.211525, 1470.4],
  [-21.654747, 166.211461, 1471],
  [-21.654721, 166.211396, 1471.8],
  [-21.654694, 166.211343, 1472.4],
  [-21.654662, 166.211278, 1473],
  [-21.654629, 166.211236, 1473.8],
  [-21.654595, 166.211171, 1474.4],
  [-21.654561, 166.211107, 1475.2],
  [-21.654526, 166.211064, 1475.8],
  [-21.65449, 166.210999, 1476.4],
  [-21.654454, 166.210957, 1477.2],
  [-21.654416, 166.210892, 1477.8],
  [-21.65438, 166.210849, 1478.4],
  [-21.654341, 166.210785, 1479.2],
  [-21.654304, 166.210742, 1479.8],
  [-21.654265, 166.210678, 1480.4],
  [-21.654224, 166.210624, 1481.2],
  [-21.654187, 166.21057, 1481.8],
  [-21.654147, 166.210517, 1482.6],
  [-21.654109, 166.210474, 1483.2],
  [-21.654069, 166.210409, 1483.8],
  [-21.654029, 166.210366, 1484.6],
  [-21.653988, 166.210302, 1485.2],
  [-21.653951, 166.210259, 1486],
  [-21.653911, 166.210195, 1486.6],
  [-21.65387, 166.210152, 1487.4],
  [-21.65383, 166.210098, 1488],
  [-21.653789, 166.210045, 1488.8],
  [-21.653746, 166.209991, 1489.4],
  [-21.653704, 166.209948, 1490.2],
  [-21.65366, 166.209905, 1490.8],
  [-21.653617, 166.209862, 1491.6],
  [-21.653574, 166.209809, 1492.4],
  [-21.653531, 166.209766, 1493],
  [-21.653487, 166.209723, 1493.8],
  [-21.653443, 166.20968, 1494.6],
  [-21.653401, 166.209626, 1495.2],
  [-21.653359, 166.209583, 1496],
  [-21.653316, 166.20954, 1496.8],
  [-21.653275, 166.209487, 1497.4],
  [-21.653235, 166.209465, 1498.2],
  [-21.653194, 166.209412, 1499],
  [-21.653156, 166.209369, 1499.8],
  [-21.653115, 166.209326, 1500.4],
  [-21.653075, 166.209283, 1501.2],
  [-21.653035, 166.209251, 1502],
  [-21.652995, 166.209197, 1502.6],
  [-21.652957, 166.209154, 1503.4],
  [-21.652917, 166.209122, 1504.2],
  [-21.652877, 166.209079, 1504.8],
  [-21.652838, 166.209036, 1505.6],
  [-21.652799, 166.209004, 1506.2],
  [-21.652759, 166.208961, 1507],
  [-21.652718, 166.208907, 1507.6],
  [-21.652679, 166.208864, 1508.4],
  [-21.652647, 166.208822, 1509],
  [-21.65262, 166.208779, 1509.6],
  [-21.652606, 166.208714, 1510.2],
  [-21.6526, 166.20865, 1511],
  [-21.652604, 166.208596, 1511.6],
  [-21.652616, 166.208532, 1512.4],
  [-21.652637, 166.208467, 1513.2],
  [-21.652659, 166.208414, 1513.8],
  [-21.65269, 166.208349, 1514.6],
  [-21.652724, 166.208307, 1515.2],
  [-21.652764, 166.208253, 1516],
  [-21.652808, 166.208221, 1516.6],
  [-21.652854, 166.208178, 1517.2],
  [-21.652901, 166.208135, 1517.8],
  [-21.652949, 166.208103, 1518.2],
  [-21.653, 166.20806, 1518.6],
  [-21.653052, 166.208017, 1519],
  [-21.653107, 166.207963, 1519.4],
  [-21.653166, 166.20791, 1519.8],
  [-21.653228, 166.207856, 1520.2],
  [-21.653291, 166.207813, 1520.6],
  [-21.653355, 166.207749, 1521],
  [-21.653422, 166.207706, 1521.4],
  [-21.653492, 166.207663, 1521.6],
  [-21.653563, 166.207598, 1522],
  [-21.653637, 166.207556, 1522.4],
  [-21.653713, 166.207513, 1522.8],
  [-21.653793, 166.207448, 1523.2],
  [-21.653872, 166.207405, 1523.6],
  [-21.653953, 166.207352, 1524],
  [-21.654038, 166.207309, 1524.4],
  [-21.654122, 166.207266, 1524.8],
  [-21.654208, 166.207234, 1525.2],
  [-21.654294, 166.207191, 1525.6],
  [-21.654381, 166.207126, 1525.8],
  [-21.654467, 166.207083, 1526.2],
  [-21.654554, 166.207041, 1526.6],
  [-21.654643, 166.206987, 1527],
  [-21.654733, 166.206944, 1527.4],
  [-21.654823, 166.206901, 1527.8],
  [-21.654914, 166.206847, 1528.4],
  [-21.655005, 166.206826, 1529],
  [-21.655096, 166.206794, 1529.6],
  [-21.655189, 166.206762, 1530.4],
  [-21.655277, 166.206729, 1531.2],
  [-21.655362, 166.206697, 1531.8],
  [-21.655439, 166.206665, 1532.2],
  [-21.655516, 166.206644, 1532.6],
  [-21.655587, 166.206611, 1533.2],
  [-21.655646, 166.206558, 1533.8],
  [-21.655697, 166.206515, 1534.4],
  [-21.655745, 166.20645, 1535.2],
  [-21.655776, 166.206429, 1535.6],
  [-21.65581, 166.206375, 1536.2],
  [-21.655839, 166.206332, 1536.6],
  [-21.655867, 166.2063, 1537.2],
  [-21.655891, 166.206257, 1537.6],
  [-21.655914, 166.206225, 1537.8],
  [-21.655938, 166.206193, 1538.2],
  [-21.655958, 166.20615, 1538.4],
  [-21.655976, 166.206118, 1538.4],
  [-21.655993, 166.206075, 1538.6],
  [-21.656011, 166.206032, 1539],
  [-21.656025, 166.205978, 1539.6],
  [-21.65604, 166.205957, 1540],
  [-21.656052, 166.205925, 1540.6],
  [-21.65606, 166.205903, 1540.8],
  [-21.656066, 166.205871, 1541.2],
  [-21.656134, 166.205689, 1542.8],
  [-21.656138, 166.205678, 1543],
  [-21.656139, 166.205678, 1543],
  [-21.656166, 166.205614, 1543],
  [-21.656185, 166.205562, 1543],
  [-21.656203, 166.205511, 1543],
  [-21.656217, 166.205459, 1543],
  [-21.656228, 166.205406, 1543],
];

export default stream;
