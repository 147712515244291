import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.750736, 165.85212, 132.8],
  [-10.750573, 165.852131, 133],
  [-10.750412, 165.85212, 133],
  [-10.750249, 165.852163, 132.8],
  [-10.750103, 165.852227, 132.6],
  [-10.749966, 165.852313, 132.2],
  [-10.74981, 165.852356, 131.8],
  [-10.749647, 165.852334, 131.2],
  [-10.749491, 165.85227, 130.6],
  [-10.749347, 165.852195, 130],
  [-10.749222, 165.852088, 129.4],
  [-10.749166, 165.851916, 128.8],
  [-10.749189, 165.851744, 128.2],
  [-10.749239, 165.851594, 127.8],
  [-10.749287, 165.851433, 127.4],
  [-10.749295, 165.851262, 127],
  [-10.749227, 165.851111, 126.4],
  [-10.749118, 165.850972, 126.2],
  [-10.749008, 165.850854, 125.8],
  [-10.748987, 165.850682, 125],
  [-10.749097, 165.850564, 124.4],
  [-10.749239, 165.850457, 124],
  [-10.749387, 165.85036, 123.4],
  [-10.749527, 165.850275, 122.8],
  [-10.749633, 165.850135, 122.2],
  [-10.749661, 165.849963, 121.6],
  [-10.749679, 165.849792, 121],
  [-10.749765, 165.849642, 120.6],
  [-10.749873, 165.849524, 120],
  [-10.749984, 165.849384, 119.6],
  [-10.750083, 165.849245, 119.2],
  [-10.750155, 165.849094, 118.6],
  [-10.7502, 165.848923, 118.2],
  [-10.750213, 165.848762, 117.8],
  [-10.750184, 165.84859, 117.2],
  [-10.750109, 165.84844, 116.6],
  [-10.749988, 165.848311, 115.8],
  [-10.749832, 165.848236, 115],
  [-10.749665, 165.848268, 114.4],
  [-10.749518, 165.848365, 114.2],
  [-10.74938, 165.848472, 113.8],
  [-10.749234, 165.848547, 113.6],
  [-10.749071, 165.848558, 113.4],
  [-10.748914, 165.848504, 113.2],
  [-10.748798, 165.848397, 113],
  [-10.748832, 165.848225, 112.4],
  [-10.74894, 165.848107, 111.8],
  [-10.749056, 165.847979, 111.2],
  [-10.749168, 165.847861, 110.6],
  [-10.749252, 165.84771, 110.2],
  [-10.749323, 165.847549, 109.8],
  [-10.749346, 165.847389, 109.2],
  [-10.749231, 165.847281, 108.6],
  [-10.749069, 165.847238, 108],
  [-10.748906, 165.847185, 107.4],
  [-10.748764, 165.847099, 106.8],
  [-10.74861, 165.847034, 106.2],
  [-10.748477, 165.847131, 105.4],
  [-10.748399, 165.847281, 104.6],
  [-10.748332, 165.847442, 103.8],
  [-10.748256, 165.847603, 103],
  [-10.748123, 165.84771, 102.2],
  [-10.747951, 165.847689, 101.6],
  [-10.747795, 165.847603, 101.2],
  [-10.74765, 165.847507, 100.8],
  [-10.747506, 165.847421, 100.6],
  [-10.747375, 165.847313, 100.8],
  [-10.747259, 165.847185, 101.4],
  [-10.747111, 165.847131, 101.4],
  [-10.746953, 165.847099, 101.2],
  [-10.746793, 165.847077, 101],
  [-10.746635, 165.847045, 100.8],
  [-10.746478, 165.847024, 100.8],
  [-10.746321, 165.847002, 100.6],
];

export default stream;
