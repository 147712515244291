import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.775152, 165.830147, 67.8],
  [-10.775199, 165.830147, 67.8],
  [-10.775245, 165.830115, 67.8],
  [-10.775287, 165.830083, 67.8],
  [-10.775328, 165.830072, 67.8],
  [-10.775369, 165.83004, 67.8],
  [-10.77541, 165.830008, 68],
  [-10.775452, 165.829976, 68],
  [-10.775495, 165.829965, 68],
  [-10.775538, 165.829933, 67.6],
  [-10.775582, 165.829911, 67.6],
  [-10.775627, 165.829901, 67.6],
  [-10.775671, 165.829901, 67.6],
  [-10.775714, 165.829911, 67.4],
  [-10.775756, 165.829911, 67.4],
  [-10.775796, 165.829933, 67.4],
  [-10.775834, 165.829933, 67.4],
  [-10.775871, 165.829943, 67.4],
  [-10.775907, 165.829943, 67.4],
  [-10.775944, 165.829965, 67.4],
  [-10.775979, 165.829965, 67.4],
  [-10.776013, 165.829965, 67.4],
  [-10.776046, 165.829965, 67.4],
  [-10.776078, 165.829965, 67.4],
  [-10.77611, 165.829965, 67.4],
  [-10.77614, 165.829965, 67.4],
  [-10.77617, 165.829965, 67.4],
  [-10.7762, 165.829943, 67.4],
  [-10.776228, 165.829943, 67.4],
  [-10.776256, 165.829943, 67.4],
  [-10.776284, 165.829943, 67.4],
  [-10.776311, 165.829943, 67.4],
  [-10.776339, 165.829943, 67.4],
  [-10.776365, 165.829943, 67.4],
  [-10.77639, 165.829943, 67.4],
  [-10.776415, 165.829965, 67.4],
  [-10.77644, 165.829965, 67.4],
  [-10.776464, 165.829976, 67.4],
  [-10.776488, 165.829976, 67.4],
  [-10.77651, 165.829986, 67.4],
  [-10.776533, 165.829986, 67.4],
  [-10.776557, 165.830008, 67.4],
  [-10.776579, 165.830019, 67.4],
  [-10.776601, 165.830019, 67.4],
  [-10.776623, 165.83004, 67.4],
  [-10.776647, 165.830051, 67.4],
  [-10.776671, 165.830051, 67.4],
  [-10.776697, 165.830072, 67.4],
  [-10.776725, 165.830072, 67.4],
  [-10.776754, 165.830083, 67.4],
  [-10.776784, 165.830094, 67.4],
  [-10.776815, 165.830094, 67.4],
  [-10.776848, 165.830115, 67.4],
  [-10.776882, 165.830115, 67.4],
  [-10.776917, 165.830126, 67.4],
  [-10.776951, 165.830126, 67.4],
  [-10.776987, 165.830147, 67.4],
  [-10.777025, 165.830147, 67.4],
  [-10.777062, 165.830158, 67.4],
  [-10.7771, 165.830169, 67.4],
  [-10.777137, 165.83019, 67.4],
  [-10.777176, 165.83019, 67.4],
  [-10.777212, 165.830201, 67.4],
  [-10.777251, 165.830233, 67.4],
];

export default stream;
