import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.76727, 165.844781, 83.8],
  [-10.767358, 165.844674, 83.8],
  [-10.767432, 165.844545, 83.6],
  [-10.767491, 165.844427, 83.6],
  [-10.767538, 165.844299, 83.6],
  [-10.767566, 165.844181, 83.6],
  [-10.767583, 165.844063, 83.6],
  [-10.767595, 165.843934, 83.8],
  [-10.767608, 165.843816, 83.8],
  [-10.767626, 165.843709, 83.8],
  [-10.76766, 165.84359, 83.6],
  [-10.767711, 165.843494, 83.4],
  [-10.767769, 165.84343, 83.2],
  [-10.767821, 165.843387, 83],
  [-10.767866, 165.843354, 82.8],
  [-10.767899, 165.843344, 82.6],
  [-10.767936, 165.843322, 82.4],
  [-10.76798, 165.843312, 82.2],
  [-10.768028, 165.843279, 82],
  [-10.768084, 165.843269, 81.8],
  [-10.768145, 165.843236, 81.4],
  [-10.768211, 165.843215, 81.2],
  [-10.768265, 165.843215, 81],
  [-10.768304, 165.843215, 80.8],
  [-10.768328, 165.843215, 80.8],
  [-10.768336, 165.843215, 80.6],
  [-10.768345, 165.843215, 80.6],
  [-10.768368, 165.843215, 80.6],
  [-10.768397, 165.843215, 80.4],
  [-10.768415, 165.843215, 80.4],
  [-10.768419, 165.843215, 80.4],
  [-10.768428, 165.843215, 80.4],
  [-10.768431, 165.843215, 80.2],
  [-10.768436, 165.843215, 80.2],
  [-10.76844, 165.843215, 80.2],
  [-10.768453, 165.843215, 80.2],
  [-10.768466, 165.843215, 80.2],
  [-10.768478, 165.843215, 80],
  [-10.768489, 165.843215, 80],
  [-10.768505, 165.843215, 80],
  [-10.768531, 165.843215, 79.8],
  [-10.768566, 165.843236, 79.8],
  [-10.768607, 165.843236, 79.6],
  [-10.768654, 165.843236, 79.4],
  [-10.768708, 165.843247, 79],
  [-10.768765, 165.843269, 78.8],
  [-10.768822, 165.843301, 78.4],
  [-10.768882, 165.843344, 78],
  [-10.768943, 165.843408, 77.6],
  [-10.769013, 165.843462, 77],
  [-10.769105, 165.843494, 76.6],
  [-10.769209, 165.843494, 76.2],
  [-10.769315, 165.843462, 75.8],
  [-10.769416, 165.843419, 75.6],
  [-10.76949, 165.843322, 75],
  [-10.769494, 165.843204, 74.4],
  [-10.769462, 165.843086, 73.8],
  [-10.769421, 165.842957, 73],
  [-10.769394, 165.842839, 72.4],
  [-10.769417, 165.8427, 71.8],
  [-10.76947, 165.842582, 71.4],
  [-10.7695, 165.842443, 71],
  [-10.769482, 165.842303, 70.4],
  [-10.769431, 165.842164, 70],
  [-10.769351, 165.842056, 69.8],
  [-10.769262, 165.841949, 69.8],
  [-10.769181, 165.841831, 69.8],
  [-10.769108, 165.841724, 69.8],
  [-10.769065, 165.841584, 69.8],
];

export default stream;
