import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.810212, 165.810138, 6],
  [-10.810141, 165.810245, 6.2],
  [-10.810086, 165.810353, 6.2],
  [-10.810042, 165.81046, 6.2],
  [-10.81, 165.810578, 6],
  [-10.80997, 165.810707, 5.8],
  [-10.809957, 165.810825, 5.6],
  [-10.809946, 165.810943, 5.6],
  [-10.809914, 165.811071, 5.4],
  [-10.809841, 165.811157, 5],
  [-10.809746, 165.811254, 4.8],
  [-10.809646, 165.811329, 4.4],
  [-10.809571, 165.811436, 4.2],
  [-10.809553, 165.811554, 4.2],
  [-10.809591, 165.811683, 4.2],
  [-10.809668, 165.81179, 3.8],
  [-10.809761, 165.811876, 3.6],
  [-10.80986, 165.811973, 3.2],
  [-10.809943, 165.812058, 3],
  [-10.80997, 165.812198, 2.8],
  [-10.809927, 165.812316, 2.6],
  [-10.809845, 165.812423, 2.6],
  [-10.809752, 165.81252, 2.8],
  [-10.809658, 165.812606, 2.8],
  [-10.809578, 165.812713, 2.8],
  [-10.809523, 165.81282, 2.6],
  [-10.809494, 165.81296, 2.6],
  [-10.809483, 165.813088, 2.6],
  [-10.809478, 165.813206, 2.8],
  [-10.809471, 165.813346, 2.8],
  [-10.809451, 165.813464, 2.6],
  [-10.809428, 165.813593, 2.6],
  [-10.809404, 165.813711, 2.6],
  [-10.809377, 165.81385, 2.8],
  [-10.809349, 165.813968, 2.8],
  [-10.809318, 165.814075, 2.8],
  [-10.809286, 165.814193, 2.8],
  [-10.809259, 165.814322, 2.8],
  [-10.809237, 165.81444, 2.8],
  [-10.809227, 165.81458, 2.6],
  [-10.809234, 165.814698, 2.6],
  [-10.80925, 165.814826, 2.6],
  [-10.809283, 165.814945, 2.6],
  [-10.80932, 165.815052, 2.6],
  [-10.809294, 165.81517, 2.8],
  [-10.809197, 165.815256, 3],
  [-10.809088, 165.815299, 3.6],
  [-10.808984, 165.815341, 4],
  [-10.808909, 165.815438, 4.2],
  [-10.808872, 165.815545, 4.2],
  [-10.808854, 165.815653, 4.2],
  [-10.808846, 165.815771, 4.4],
  [-10.808846, 165.815889, 4.4],
  [-10.80885, 165.815996, 4.4],
];

export default stream;
