import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.777831, 165.830255, 67.2],
  [-10.777902, 165.830169, 67.4],
  [-10.777974, 165.830115, 67.4],
  [-10.778045, 165.83004, 67.4],
  [-10.778114, 165.829965, 67.2],
  [-10.778184, 165.829879, 66.8],
  [-10.778256, 165.829804, 66.6],
  [-10.77833, 165.829729, 66.4],
  [-10.778407, 165.829643, 66.2],
  [-10.778484, 165.829568, 66],
  [-10.778563, 165.829482, 65.8],
  [-10.778643, 165.829396, 65.8],
  [-10.778735, 165.829321, 65.8],
  [-10.778846, 165.829289, 65.8],
  [-10.778963, 165.8293, 65.8],
  [-10.779079, 165.829289, 65.8],
  [-10.779186, 165.829225, 65.8],
  [-10.779285, 165.829171, 65.8],
  [-10.779386, 165.829107, 65.6],
  [-10.779501, 165.829074, 65.6],
  [-10.779622, 165.829064, 65.4],
  [-10.77974, 165.829042, 65.2],
  [-10.77985, 165.828999, 65],
  [-10.779939, 165.828903, 64.8],
  [-10.780013, 165.828795, 64.8],
  [-10.78009, 165.82871, 64.6],
  [-10.780203, 165.828667, 64.8],
  [-10.780327, 165.828677, 65],
  [-10.780447, 165.828677, 65.2],
  [-10.780566, 165.828699, 65.2],
  [-10.780686, 165.828699, 65.4],
  [-10.780802, 165.828699, 65.6],
  [-10.780915, 165.828677, 65.8],
  [-10.781009, 165.828602, 65.6],
  [-10.781072, 165.828506, 65.4],
  [-10.781075, 165.828388, 65.2],
  [-10.78102, 165.82828, 65.2],
  [-10.780951, 165.828173, 65],
  [-10.780882, 165.828087, 64.8],
  [-10.780815, 165.82798, 64.6],
  [-10.780811, 165.827851, 64.4],
  [-10.7809, 165.827776, 64.6],
  [-10.781011, 165.827712, 64.6],
  [-10.781125, 165.827669, 64.8],
  [-10.781229, 165.827615, 64.8],
  [-10.781319, 165.827529, 64.8],
  [-10.781427, 165.827476, 64.6],
  [-10.781549, 165.827476, 64.6],
  [-10.781669, 165.827487, 64.4],
  [-10.781789, 165.827508, 64.4],
  [-10.781913, 165.827519, 64.2],
  [-10.782034, 165.827508, 64.2],
  [-10.78215, 165.827454, 63.8],
  [-10.782256, 165.827379, 63.6],
  [-10.782325, 165.827272, 63.2],
  [-10.782337, 165.827154, 63.2],
  [-10.782333, 165.827025, 63.2],
  [-10.78233, 165.826896, 63.4],
  [-10.782325, 165.826768, 63.4],
  [-10.782321, 165.82665, 63.4],
  [-10.782319, 165.82651, 63.4],
  [-10.78234, 165.826392, 63.2],
  [-10.782411, 165.826296, 63.2],
  [-10.782511, 165.826221, 63.2],
  [-10.782612, 165.826145, 63.2],
  [-10.782717, 165.826081, 63.4],
  [-10.782825, 165.826027, 63.6],
  [-10.78294, 165.825974, 63.6],
  [-10.78306, 165.825974, 63.6],
  [-10.783177, 165.825995, 63.6],
  [-10.783282, 165.826038, 63.4],
  [-10.783378, 165.826135, 63],
  [-10.783469, 165.82621, 62.4],
  [-10.783566, 165.826296, 62],
  [-10.783674, 165.82636, 61.6],
  [-10.783799, 165.826403, 61.2],
  [-10.783929, 165.826424, 61],
  [-10.78406, 165.826403, 60.8],
  [-10.784187, 165.82636, 60.8],
  [-10.784297, 165.826296, 60.8],
  [-10.784371, 165.826188, 61],
  [-10.784413, 165.82607, 61.2],
  [-10.78444, 165.825952, 61.2],
  [-10.784465, 165.825824, 61.2],
  [-10.784491, 165.825706, 61.2],
  [-10.784517, 165.825577, 61.2],
  [-10.784544, 165.825459, 61.2],
  [-10.784569, 165.825351, 61.2],
  [-10.784603, 165.825233, 61.2],
  [-10.784666, 165.825137, 61],
  [-10.784751, 165.825062, 61],
  [-10.784846, 165.824987, 60.8],
  [-10.784944, 165.824912, 60.8],
  [-10.785043, 165.824847, 60.8],
  [-10.785134, 165.824772, 61],
  [-10.785207, 165.824676, 61.4],
  [-10.785251, 165.824579, 61.8],
  [-10.785265, 165.824472, 62.4],
  [-10.785267, 165.824343, 62.8],
  [-10.785299, 165.824236, 63.2],
  [-10.785362, 165.824161, 63.4],
  [-10.785398, 165.824053, 63.6],
  [-10.785405, 165.823946, 63.8],
  [-10.785394, 165.823839, 64],
  [-10.785372, 165.823731, 64],
  [-10.785346, 165.823624, 64.2],
  [-10.785319, 165.823538, 64.4],
  [-10.785298, 165.823431, 64.4],
  [-10.785297, 165.823302, 64.6],
  [-10.785346, 165.823216, 64.6],
  [-10.78541, 165.82312, 64.6],
  [-10.785445, 165.823013, 64.6],
  [-10.785451, 165.822905, 64.4],
  [-10.78544, 165.822809, 64.4],
  [-10.785427, 165.82268, 64.4],
  [-10.785447, 165.822573, 64.4],
  [-10.785524, 165.822487, 64.4],
  [-10.785626, 165.822423, 64.6],
  [-10.785734, 165.82239, 64.6],
  [-10.785848, 165.82238, 64.6],
  [-10.78596, 165.822412, 64.4],
  [-10.786051, 165.822487, 64.2],
  [-10.786105, 165.822594, 63.6],
  [-10.78613, 165.822712, 63],
  [-10.786138, 165.82283, 62.4],
  [-10.786134, 165.82297, 62],
  [-10.78612, 165.823109, 61.4],
  [-10.786099, 165.823227, 60.8],
  [-10.786071, 165.823367, 60.2],
  [-10.786037, 165.823506, 59.4],
  [-10.785998, 165.823646, 58.8],
  [-10.785949, 165.823785, 58],
  [-10.785897, 165.823914, 57.6],
  [-10.785851, 165.824053, 57.6],
  [-10.785818, 165.824193, 57.6],
  [-10.785799, 165.824332, 57.4],
  [-10.785799, 165.824482, 56.8],
  [-10.785811, 165.824622, 56.2],
  [-10.785826, 165.824772, 55.8],
  [-10.785841, 165.824912, 55.6],
  [-10.785856, 165.825051, 55.8],
  [-10.78587, 165.82518, 56.2],
  [-10.785881, 165.825319, 56.4],
  [-10.785893, 165.825459, 56.6],
  [-10.785915, 165.825577, 56.6],
  [-10.786001, 165.825673, 56.4],
  [-10.786123, 165.825716, 56.2],
  [-10.786251, 165.825738, 56.2],
  [-10.786379, 165.825748, 56],
  [-10.7865, 165.825791, 55.6],
  [-10.786627, 165.825824, 54.8],
  [-10.78676, 165.825813, 54.6],
  [-10.786881, 165.825748, 54.6],
  [-10.78698, 165.825652, 54.6],
  [-10.787071, 165.825566, 54.6],
  [-10.78716, 165.825469, 54.2],
  [-10.787257, 165.825384, 54],
  [-10.78735, 165.825287, 54],
  [-10.787444, 165.825201, 54.4],
  [-10.787552, 165.825126, 55],
  [-10.787664, 165.825073, 55.4],
  [-10.787768, 165.825019, 55.6],
  [-10.787841, 165.824922, 55.8],
  [-10.787888, 165.824815, 55.6],
  [-10.78792, 165.824697, 55.6],
  [-10.787953, 165.824579, 55.4],
  [-10.788008, 165.824472, 55.2],
  [-10.788071, 165.824364, 55],
  [-10.788094, 165.824257, 54.6],
  [-10.788086, 165.824118, 54],
  [-10.788057, 165.823989, 53.4],
  [-10.788013, 165.823871, 52.8],
  [-10.787954, 165.823731, 52.4],
  [-10.78789, 165.823624, 52.2],
  [-10.787829, 165.823506, 52],
  [-10.78779, 165.823367, 51.6],
  [-10.787794, 165.823227, 50.8],
  [-10.787828, 165.823098, 49.8],
  [-10.787855, 165.822959, 49.2],
  [-10.787823, 165.822809, 48.4],
  [-10.787744, 165.82268, 47.6],
  [-10.787643, 165.822573, 46.8],
  [-10.787533, 165.822465, 46],
  [-10.78743, 165.822358, 45.2],
  [-10.787316, 165.822251, 44.6],
  [-10.787223, 165.822133, 44],
  [-10.787187, 165.821983, 43.8],
  [-10.787175, 165.821843, 43.6],
  [-10.787187, 165.821693, 43.2],
  [-10.787236, 165.821532, 42.4],
  [-10.787342, 165.821435, 41.6],
  [-10.787488, 165.821403, 40.8],
  [-10.787638, 165.821414, 40],
  [-10.787762, 165.821511, 39.2],
  [-10.787842, 165.821639, 38.6],
  [-10.787894, 165.8218, 38],
  [-10.78793, 165.82195, 37.4],
  [-10.787966, 165.822101, 37],
  [-10.788022, 165.82224, 36.6],
  [-10.788094, 165.82238, 36.4],
  [-10.788183, 165.822498, 36.4],
  [-10.788285, 165.822616, 36.2],
  [-10.788378, 165.822723, 36.4],
  [-10.788444, 165.822852, 36.4],
  [-10.78847, 165.823002, 36.2],
  [-10.78847, 165.823141, 35.8],
  [-10.788486, 165.823281, 35.2],
  [-10.788537, 165.82341, 34.4],
  [-10.788631, 165.823517, 33.6],
  [-10.78877, 165.82357, 33],
  [-10.788906, 165.823517, 32.6],
  [-10.789009, 165.82341, 32.2],
  [-10.789104, 165.823292, 31.8],
  [-10.789199, 165.823184, 31.4],
  [-10.789292, 165.823066, 31],
  [-10.789374, 165.822937, 30.6],
  [-10.789373, 165.822809, 30.4],
  [-10.789306, 165.822669, 30.4],
  [-10.789217, 165.822562, 30.4],
  [-10.789124, 165.822455, 30.4],
  [-10.789032, 165.822347, 30.4],
  [-10.788956, 165.82224, 30.2],
  [-10.788917, 165.822101, 29.8],
  [-10.788942, 165.821961, 29],
  [-10.789015, 165.821843, 28.2],
  [-10.78912, 165.821736, 27.2],
  [-10.789249, 165.821671, 26.4],
  [-10.789398, 165.821661, 25.8],
  [-10.789547, 165.821661, 25.6],
  [-10.789691, 165.821639, 25.4],
  [-10.789834, 165.821596, 25.4],
  [-10.789972, 165.821564, 25.4],
  [-10.790114, 165.821564, 25.4],
  [-10.790251, 165.821586, 25.2],
  [-10.790383, 165.821629, 24.8],
  [-10.790505, 165.821693, 24.4],
  [-10.790625, 165.821768, 24.2],
  [-10.790744, 165.821843, 23.8],
  [-10.790867, 165.821918, 23.4],
  [-10.790998, 165.821961, 23],
  [-10.791136, 165.821983, 22.8],
  [-10.791268, 165.821929, 22.6],
  [-10.791387, 165.821854, 22.2],
  [-10.79151, 165.8218, 21.6],
  [-10.791641, 165.821736, 21],
  [-10.791779, 165.821704, 20.4],
  [-10.791924, 165.821671, 19.8],
  [-10.79207, 165.821693, 19.2],
  [-10.792214, 165.821725, 18.8],
  [-10.792353, 165.821768, 18.4],
  [-10.792488, 165.821832, 17.8],
  [-10.792624, 165.821875, 17.4],
  [-10.792762, 165.821929, 17],
  [-10.792903, 165.821961, 16.6],
  [-10.79305, 165.821983, 16],
  [-10.793198, 165.821961, 15.4],
  [-10.793343, 165.82195, 15],
  [-10.793491, 165.821918, 14.4],
  [-10.793637, 165.821908, 14.2],
  [-10.793774, 165.821854, 14],
  [-10.793906, 165.821779, 13.8],
  [-10.794024, 165.821704, 13.6],
  [-10.79413, 165.821618, 13.6],
  [-10.794216, 165.821489, 13.2],
  [-10.794274, 165.821371, 13],
  [-10.794293, 165.821221, 12.6],
  [-10.794269, 165.821081, 12],
  [-10.794214, 165.820942, 11.6],
  [-10.79414, 165.820824, 11],
  [-10.794059, 165.820695, 10.6],
  [-10.793975, 165.820577, 10],
  [-10.793899, 165.820459, 9.6],
  [-10.793845, 165.82032, 9.2],
  [-10.79383, 165.82018, 9],
  [-10.793884, 165.820041, 9],
  [-10.794008, 165.819976, 8.6],
  [-10.794151, 165.819966, 8.2],
  [-10.794293, 165.819966, 7.6],
  [-10.794438, 165.819976, 7.2],
  [-10.79458, 165.819966, 6.6],
  [-10.794713, 165.819901, 6],
  [-10.794796, 165.819783, 5.6],
  [-10.794822, 165.819633, 5.2],
  [-10.794824, 165.819494, 4.8],
  [-10.794844, 165.819343, 4.2],
  [-10.794931, 165.819236, 3.8],
  [-10.795058, 165.819161, 3.4],
  [-10.79518, 165.819064, 3.2],
  [-10.795295, 165.818989, 3],
  [-10.795427, 165.818925, 3],
  [-10.795565, 165.818893, 3.2],
  [-10.795702, 165.818882, 3.2],
  [-10.79584, 165.818871, 3.2],
];

export default stream;
