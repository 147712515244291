import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.801658, 165.80826, 6],
  [-10.801756, 165.808228, 6.2],
  [-10.801848, 165.808185, 6.4],
  [-10.801931, 165.808121, 6.4],
  [-10.802005, 165.808067, 6.6],
  [-10.802072, 165.807992, 6.8],
  [-10.802131, 165.807906, 7],
  [-10.802184, 165.807831, 7.2],
  [-10.80223, 165.807756, 7.2],
  [-10.80227, 165.80767, 7.4],
  [-10.802304, 165.807595, 7.6],
  [-10.802339, 165.807499, 7.8],
  [-10.802382, 165.807424, 8],
  [-10.802431, 165.807348, 8],
  [-10.802489, 165.807273, 8.2],
  [-10.802553, 165.807209, 8.4],
  [-10.802623, 165.807145, 8.4],
  [-10.8027, 165.807112, 8.6],
  [-10.802783, 165.80707, 8.6],
  [-10.802867, 165.807037, 8.6],
  [-10.802955, 165.807027, 8.6],
  [-10.803045, 165.807016, 8.6],
  [-10.803135, 165.806994, 8.6],
  [-10.803228, 165.806984, 8.6],
  [-10.803321, 165.806984, 8.6],
  [-10.803416, 165.806984, 8.6],
  [-10.803509, 165.806984, 8.6],
  [-10.803605, 165.806984, 8.6],
  [-10.8037, 165.806984, 8.6],
  [-10.803795, 165.806984, 8.6],
  [-10.80389, 165.806984, 8.6],
  [-10.803987, 165.806984, 8.6],
  [-10.804083, 165.806984, 8.6],
  [-10.804179, 165.806984, 8.6],
  [-10.804277, 165.806984, 8.6],
  [-10.804373, 165.806984, 8.6],
  [-10.80447, 165.806984, 8.6],
  [-10.804568, 165.806984, 8.6],
  [-10.804666, 165.806984, 8.6],
  [-10.804763, 165.806984, 8.6],
  [-10.804861, 165.806984, 8.6],
  [-10.80496, 165.806984, 8.6],
  [-10.805058, 165.806984, 8.6],
  [-10.805156, 165.806984, 8.6],
  [-10.805254, 165.806984, 8.6],
  [-10.805353, 165.806984, 8.6],
  [-10.805445, 165.806984, 8.6],
  [-10.805518, 165.806984, 8.6],
  [-10.805571, 165.806984, 8.6],
  [-10.805605, 165.806994, 8.6],
  [-10.805636, 165.806994, 8.6],
  [-10.805672, 165.806994, 8.6],
  [-10.805693, 165.806994, 8.6],
  [-10.805711, 165.806994, 8.6],
  [-10.805722, 165.806994, 8.6],
  [-10.805726, 165.806994, 8.6],
  [-10.805729, 165.806994, 8.6],
  [-10.805731, 165.806994, 8.6],
  [-10.80573, 165.806994, 8.6],
  [-10.805733, 165.806994, 8.6],
  [-10.805754, 165.806994, 8.6],
  [-10.805785, 165.806994, 8.6],
  [-10.805825, 165.806994, 8.6],
  [-10.805869, 165.806994, 8.6],
  [-10.80592, 165.806994, 8.6],
  [-10.805974, 165.806994, 8.6],
  [-10.806033, 165.806994, 8.6],
  [-10.806094, 165.806994, 8.6],
  [-10.806159, 165.806984, 8.6],
  [-10.806228, 165.806984, 8.6],
  [-10.806298, 165.806984, 8.6],
  [-10.806371, 165.806984, 8.6],
  [-10.806445, 165.806984, 8.6],
  [-10.806522, 165.806984, 8.6],
  [-10.806599, 165.806984, 8.6],
  [-10.806678, 165.806984, 8.6],
  [-10.806759, 165.806984, 8.6],
  [-10.806841, 165.806984, 8.6],
  [-10.806924, 165.806984, 8.6],
  [-10.807009, 165.806984, 8.6],
  [-10.807094, 165.806984, 8.6],
  [-10.80718, 165.806984, 8.6],
  [-10.807267, 165.806984, 8.6],
  [-10.807354, 165.806984, 8.6],
  [-10.807443, 165.806984, 8.6],
  [-10.807533, 165.806984, 8.6],
  [-10.807622, 165.806984, 8.6],
  [-10.807712, 165.806984, 8.6],
  [-10.807802, 165.806984, 8.6],
  [-10.807894, 165.806984, 8.6],
  [-10.807985, 165.806984, 8.6],
  [-10.808078, 165.806984, 8.6],
  [-10.80817, 165.806984, 8.6],
  [-10.808263, 165.806984, 8.6],
  [-10.808357, 165.806984, 8.6],
  [-10.80845, 165.806984, 8.6],
  [-10.808543, 165.806984, 8.6],
  [-10.808637, 165.806984, 8.6],
  [-10.808731, 165.806984, 8.6],
  [-10.808825, 165.806994, 8.6],
  [-10.808915, 165.807027, 8.6],
  [-10.809003, 165.807059, 8.6],
  [-10.809088, 165.807102, 8.4],
  [-10.809166, 165.807166, 8.4],
  [-10.809243, 165.807209, 8.4],
  [-10.809316, 165.807284, 8.4],
  [-10.809387, 165.807348, 8.2],
  [-10.809456, 165.807413, 8.2],
  [-10.809525, 165.807488, 8],
  [-10.809589, 165.807563, 7.8],
  [-10.80965, 165.807638, 7.6],
  [-10.809704, 165.807724, 7.4],
  [-10.809749, 165.807821, 7],
  [-10.809782, 165.807928, 6.8],
  [-10.809801, 165.808035, 6.6],
  [-10.809797, 165.808142, 6.4],
  [-10.809772, 165.80825, 6.4],
  [-10.80973, 165.808336, 6.2],
  [-10.809679, 165.808443, 6.2],
  [-10.80962, 165.808539, 6.2],
  [-10.809561, 165.808614, 6.2],
  [-10.809502, 165.8087, 6.2],
  [-10.809446, 165.808797, 6.2],
  [-10.809402, 165.808883, 6.2],
  [-10.809369, 165.80899, 6.2],
  [-10.809355, 165.809097, 6],
  [-10.80938, 165.809194, 6],
  [-10.809434, 165.80928, 6],
  [-10.809505, 165.809366, 6],
  [-10.809583, 165.809419, 6],
  [-10.809667, 165.809484, 6],
  [-10.809752, 165.809548, 6],
  [-10.809839, 165.809591, 6],
  [-10.809927, 165.809655, 6],
  [-10.810016, 165.809698, 6],
];

export default stream;
