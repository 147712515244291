import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.805342, 165.838805, 63],
  [-10.805304, 165.838945, 63],
  [-10.805268, 165.839074, 63],
  [-10.805233, 165.839192, 63],
  [-10.805199, 165.83931, 63],
  [-10.805164, 165.839449, 63],
  [-10.805127, 165.839578, 63.4],
  [-10.80506, 165.839685, 63.8],
  [-10.804961, 165.83976, 64.2],
  [-10.804857, 165.839814, 64.6],
  [-10.804762, 165.839878, 65],
  [-10.804697, 165.839986, 65.4],
  [-10.804648, 165.840071, 65.6],
  [-10.804598, 165.8402, 65.6],
  [-10.80455, 165.840286, 65.4],
  [-10.804511, 165.840415, 65.4],
  [-10.804472, 165.840522, 65.4],
  [-10.804431, 165.84064, 65.4],
  [-10.804403, 165.840747, 65.4],
  [-10.804402, 165.840865, 65.4],
  [-10.804437, 165.840994, 65.6],
  [-10.804502, 165.841101, 65.6],
  [-10.804578, 165.841187, 65.8],
  [-10.804653, 165.841284, 65.8],
  [-10.804727, 165.84137, 66],
  [-10.804802, 165.841466, 66],
  [-10.804875, 165.841552, 66],
  [-10.804936, 165.841659, 66],
  [-10.804899, 165.841767, 66.2],
  [-10.804784, 165.841799, 66.4],
  [-10.804667, 165.841799, 66.6],
  [-10.804549, 165.841788, 66.8],
  [-10.804434, 165.841767, 67],
  [-10.804321, 165.841799, 67.2],
  [-10.804233, 165.841874, 67.2],
  [-10.804177, 165.841981, 67.4],
  [-10.804143, 165.842088, 67.4],
  [-10.804116, 165.842217, 67.4],
  [-10.80409, 165.842324, 67.4],
  [-10.804062, 165.842443, 67.2],
  [-10.804035, 165.842561, 67],
  [-10.804007, 165.842689, 66.8],
  [-10.803979, 165.842807, 66.6],
  [-10.803951, 165.842915, 66.2],
  [-10.803921, 165.843054, 66],
  [-10.803884, 165.843172, 65.6],
  [-10.803845, 165.843301, 65.4],
  [-10.803806, 165.843419, 65.6],
  [-10.803766, 165.843537, 65.6],
  [-10.803727, 165.843666, 65.6],
];

export default stream;
