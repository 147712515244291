import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.704079, 166.966771, 134.8],
  [-11.703996, 166.966771, 134.8],
  [-11.70391, 166.966771, 134.8],
  [-11.703822, 166.966771, 134.8],
  [-11.703732, 166.966771, 134.8],
  [-11.703639, 166.966771, 134.8],
  [-11.703547, 166.966771, 134.8],
  [-11.703453, 166.966771, 134.8],
  [-11.703356, 166.966771, 134.8],
  [-11.703258, 166.966771, 134.8],
  [-11.703161, 166.966771, 134.8],
  [-11.703061, 166.966771, 134.8],
  [-11.702961, 166.966771, 134.8],
  [-11.702859, 166.966771, 134.6],
  [-11.702756, 166.966771, 134.4],
  [-11.702651, 166.966771, 134.4],
  [-11.702548, 166.966803, 134.6],
  [-11.702451, 166.966835, 135],
  [-11.702364, 166.966889, 135.6],
  [-11.702285, 166.966953, 136.2],
  [-11.702211, 166.967018, 136.6],
  [-11.702143, 166.967071, 137.2],
  [-11.702078, 166.967136, 137.6],
  [-11.702015, 166.9672, 138.2],
  [-11.701954, 166.967254, 138.6],
  [-11.701895, 166.967307, 139.2],
  [-11.701836, 166.967361, 139.6],
  [-11.701777, 166.967415, 140],
  [-11.701716, 166.967468, 140.6],
  [-11.701654, 166.9675, 141],
  [-11.701591, 166.967543, 141.6],
  [-11.701527, 166.967576, 142],
  [-11.701461, 166.967597, 142.6],
  [-11.701394, 166.967608, 143],
  [-11.701327, 166.967608, 143.4],
  [-11.701263, 166.967597, 143.8],
  [-11.701201, 166.967576, 144.2],
  [-11.701142, 166.967543, 144.6],
  [-11.701089, 166.9675, 145],
  [-11.701043, 166.967458, 145.6],
  [-11.701003, 166.967415, 146],
  [-11.700971, 166.967361, 146.4],
  [-11.700942, 166.967307, 147],
  [-11.700921, 166.967254, 147.4],
  [-11.700902, 166.9672, 148],
  [-11.700887, 166.967136, 148.4],
  [-11.700877, 166.967093, 148.8],
  [-11.700866, 166.967028, 149.4],
  [-11.700858, 166.966985, 149.8],
  [-11.70085, 166.966921, 150.2],
  [-11.700843, 166.966857, 150.6],
  [-11.700838, 166.966814, 151.2],
  [-11.700831, 166.96676, 151.6],
  [-11.700826, 166.966707, 152],
  [-11.70082, 166.966664, 152.4],
  [-11.700815, 166.966599, 152.8],
  [-11.700808, 166.966556, 153.2],
  [-11.700802, 166.966492, 153.8],
  [-11.700794, 166.966449, 154.2],
  [-11.700784, 166.966406, 154.6],
  [-11.700772, 166.966342, 155],
  [-11.700759, 166.966299, 155.4],
  [-11.700744, 166.966256, 155.8],
  [-11.700726, 166.966202, 156.4],
  [-11.700706, 166.966159, 156.8],
  [-11.700684, 166.966116, 157.2],
  [-11.700658, 166.966074, 157.6],
  [-11.700627, 166.96602, 158],
  [-11.700592, 166.965988, 158.4],
  [-11.700551, 166.965966, 158.8],
  [-11.700508, 166.965934, 159.4],
  [-11.700462, 166.965913, 159.8],
  [-11.700414, 166.965902, 160.2],
  [-11.700366, 166.965902, 160.6],
  [-11.700316, 166.96588, 161.2],
  [-11.700269, 166.96588, 161.6],
  [-11.700221, 166.965902, 162],
  [-11.700175, 166.965902, 162.6],
  [-11.700131, 166.965913, 163],
  [-11.700089, 166.965945, 163.4],
  [-11.700055, 166.965977, 164],
  [-11.700025, 166.966009, 164.4],
  [-11.700001, 166.966052, 164.8],
  [-11.699981, 166.966084, 165.2],
  [-11.699965, 166.966127, 165.8],
  [-11.69995, 166.96617, 166.2],
  [-11.699941, 166.966224, 166.6],
  [-11.699931, 166.966267, 167],
  [-11.699926, 166.96631, 167.4],
  [-11.69992, 166.966363, 168],
  [-11.699918, 166.966406, 168.4],
  [-11.699916, 166.966449, 168.8],
  [-11.699916, 166.966481, 169.2],
  [-11.699916, 166.966524, 169.6],
  [-11.699918, 166.966567, 170.2],
  [-11.699919, 166.966621, 170.6],
  [-11.69992, 166.966674, 171],
  [-11.699923, 166.966707, 171.4],
  [-11.699924, 166.96676, 171.8],
  [-11.699927, 166.966803, 172.2],
  [-11.69993, 166.966846, 172.8],
  [-11.699931, 166.966889, 173.2],
  [-11.699934, 166.966943, 173.6],
  [-11.699934, 166.966985, 174],
  [-11.699933, 166.96705, 174.6],
  [-11.69993, 166.967093, 175],
  [-11.699927, 166.967136, 175.4],
  [-11.69992, 166.967168, 175.8],
  [-11.699912, 166.967211, 176.2],
  [-11.6999, 166.967254, 176.8],
  [-11.699886, 166.967307, 177.2],
  [-11.699867, 166.96735, 177.6],
  [-11.699843, 166.967382, 178],
  [-11.699814, 166.967425, 178.4],
  [-11.699778, 166.967458, 178.8],
  [-11.699739, 166.96749, 179.2],
  [-11.699699, 166.9675, 179.6],
  [-11.699655, 166.967522, 180.2],
  [-11.699611, 166.967533, 180.6],
  [-11.699564, 166.967543, 181],
  [-11.699518, 166.967543, 181.4],
  [-11.699473, 166.967543, 182],
  [-11.699426, 166.967543, 182.4],
  [-11.699381, 166.967543, 182.8],
  [-11.699336, 166.967533, 183.2],
  [-11.699291, 166.967522, 183.8],
  [-11.699249, 166.9675, 184.2],
  [-11.699208, 166.96749, 184.6],
  [-11.699171, 166.967458, 185],
  [-11.699137, 166.967447, 185.6],
  [-11.699109, 166.967393, 186],
  [-11.699085, 166.967361, 186.4],
  [-11.699065, 166.967318, 186.8],
  [-11.699047, 166.967275, 187.4],
  [-11.699034, 166.967232, 187.8],
  [-11.699023, 166.9672, 188.2],
  [-11.699015, 166.967157, 188.8],
  [-11.69901, 166.967103, 189.2],
  [-11.699006, 166.967061, 189.6],
  [-11.699003, 166.967018, 190.2],
  [-11.699003, 166.966964, 190.6],
  [-11.699003, 166.966943, 191],
  [-11.699004, 166.966889, 191.6],
  [-11.699007, 166.966846, 192],
  [-11.69901, 166.966803, 192.4],
  [-11.699013, 166.966771, 192.8],
  [-11.699017, 166.966728, 193.4],
  [-11.699019, 166.966674, 193.8],
  [-11.699023, 166.966631, 194.2],
  [-11.699027, 166.966599, 194.8],
  [-11.69903, 166.966556, 195.2],
  [-11.699033, 166.966513, 195.6],
  [-11.699035, 166.966481, 196.2],
  [-11.699035, 166.966438, 196.6],
  [-11.699035, 166.966406, 197],
  [-11.699033, 166.966363, 197.4],
  [-11.699029, 166.966331, 198],
  [-11.699025, 166.966277, 198.4],
  [-11.699018, 166.966256, 198.8],
  [-11.69901, 166.966202, 199.4],
  [-11.698999, 166.96617, 199.8],
  [-11.698987, 166.966127, 200.2],
  [-11.698972, 166.966095, 200.8],
  [-11.698955, 166.966074, 201.2],
  [-11.698933, 166.966041, 201.6],
  [-11.698907, 166.966009, 202.2],
  [-11.698876, 166.965988, 202.6],
  [-11.698845, 166.965977, 203],
  [-11.698811, 166.965977, 203.6],
  [-11.698776, 166.965966, 204],
  [-11.698742, 166.965966, 204.4],
  [-11.698705, 166.965945, 204.8],
  [-11.698668, 166.965945, 205.2],
  [-11.69863, 166.965945, 205.6],
  [-11.698594, 166.965966, 206.2],
  [-11.698557, 166.965966, 206.6],
  [-11.69852, 166.965977, 207],
  [-11.698488, 166.965988, 207.4],
  [-11.698457, 166.96602, 208],
  [-11.69843, 166.966041, 208.4],
  [-11.698409, 166.966074, 209],
  [-11.698392, 166.966095, 209.4],
  [-11.698375, 166.966127, 209.8],
  [-11.698362, 166.96617, 210.2],
  [-11.698351, 166.966202, 210.6],
  [-11.698342, 166.966234, 211],
  [-11.698335, 166.966277, 211.6],
  [-11.69833, 166.96631, 212],
  [-11.698325, 166.966363, 212.4],
  [-11.698322, 166.966385, 212.8],
  [-11.698319, 166.966438, 213.2],
  [-11.698316, 166.96647, 213.8],
  [-11.698316, 166.966513, 214.2],
  [-11.698316, 166.966546, 214.6],
  [-11.698316, 166.966588, 215],
  [-11.698316, 166.966621, 215.4],
  [-11.698316, 166.966664, 216],
  [-11.698316, 166.966696, 216.4],
  [-11.698318, 166.966739, 216.8],
  [-11.698319, 166.966782, 217.2],
  [-11.698319, 166.966814, 217.6],
  [-11.698319, 166.966857, 218],
  [-11.698321, 166.966889, 218.6],
  [-11.698321, 166.966943, 219],
  [-11.698321, 166.966985, 219.4],
  [-11.698319, 166.967018, 219.8],
  [-11.698316, 166.967061, 220.2],
  [-11.698314, 166.967103, 220.8],
  [-11.69831, 166.967136, 221.2],
  [-11.698306, 166.967179, 221.6],
  [-11.698299, 166.967211, 222],
  [-11.698292, 166.967254, 222.4],
  [-11.698283, 166.967307, 222.8],
  [-11.698274, 166.96734, 223.4],
  [-11.698261, 166.967382, 223.8],
  [-11.698247, 166.967415, 224.2],
  [-11.698231, 166.967458, 224.6],
  [-11.698213, 166.96749, 225],
  [-11.698189, 166.967522, 225.4],
  [-11.698161, 166.967543, 225.8],
  [-11.698126, 166.967576, 226.2],
  [-11.698088, 166.967597, 226.8],
  [-11.69805, 166.967608, 227.2],
  [-11.698009, 166.967629, 227.6],
  [-11.697968, 166.96764, 228],
  [-11.697926, 166.96764, 228.4],
  [-11.697885, 166.96764, 229],
  [-11.697843, 166.96764, 229.4],
  [-11.697802, 166.967629, 229.8],
  [-11.697761, 166.967629, 230.2],
  [-11.697722, 166.967608, 230.6],
  [-11.697685, 166.967597, 231.2],
  [-11.69765, 166.967565, 231.6],
  [-11.697619, 166.967543, 232],
  [-11.697594, 166.967522, 232.4],
  [-11.697571, 166.967468, 232.8],
  [-11.697553, 166.967447, 233.2],
  [-11.697537, 166.967393, 233.8],
  [-11.697524, 166.967361, 234.2],
  [-11.697512, 166.967318, 234.6],
  [-11.697501, 166.967275, 235.2],
  [-11.697493, 166.967243, 235.6],
  [-11.697485, 166.9672, 236],
  [-11.697478, 166.967168, 236.4],
  [-11.697474, 166.967125, 236.8],
  [-11.697469, 166.967071, 237.4],
  [-11.697466, 166.96705, 237.8],
  [-11.697464, 166.966996, 238.2],
  [-11.697461, 166.966953, 238.6],
  [-11.69746, 166.966921, 239.2],
  [-11.697458, 166.966878, 239.6],
  [-11.697458, 166.966846, 240],
  [-11.697458, 166.966803, 240.4],
  [-11.697458, 166.96676, 240.8],
  [-11.697458, 166.966728, 241.4],
  [-11.697458, 166.966674, 241.8],
  [-11.697458, 166.966631, 242.2],
  [-11.697458, 166.966599, 242.6],
  [-11.697458, 166.966556, 243],
  [-11.697458, 166.966524, 243.6],
  [-11.697458, 166.966481, 244],
  [-11.697458, 166.966438, 244.4],
  [-11.697458, 166.966406, 244.8],
  [-11.697454, 166.966363, 245.4],
  [-11.697451, 166.966331, 245.8],
  [-11.697447, 166.966277, 246.2],
  [-11.697441, 166.966256, 246.8],
  [-11.697434, 166.966202, 247.2],
  [-11.697423, 166.96617, 247.6],
  [-11.69741, 166.966127, 248.2],
  [-11.697388, 166.966095, 248.6],
  [-11.697363, 166.966084, 249.2],
  [-11.697333, 166.966074, 249.6],
  [-11.697301, 166.966052, 250],
  [-11.697269, 166.966052, 250.6],
  [-11.697234, 166.966041, 251],
  [-11.697198, 166.966041, 251.4],
  [-11.697162, 166.966041, 251.8],
  [-11.697127, 166.966052, 252.2],
  [-11.697092, 166.966052, 252.6],
  [-11.697059, 166.966074, 253.2],
  [-11.697029, 166.966095, 253.6],
  [-11.697006, 166.966116, 254.2],
  [-11.696989, 166.966149, 254.6],
  [-11.696974, 166.96617, 255],
  [-11.696961, 166.966224, 255.4],
  [-11.69695, 166.966256, 255.8],
  [-11.696939, 166.966277, 256.2],
  [-11.696931, 166.966331, 256.8],
  [-11.696924, 166.966363, 257.2],
  [-11.696919, 166.966406, 257.6],
  [-11.696915, 166.966438, 258],
  [-11.696911, 166.966481, 258.6],
  [-11.696908, 166.966513, 259],
  [-11.696907, 166.966546, 259.4],
  [-11.696906, 166.966588, 259.8],
  [-11.696904, 166.966621, 260.4],
  [-11.696904, 166.966664, 260.8],
  [-11.696904, 166.966696, 261.2],
  [-11.696904, 166.966739, 261.6],
  [-11.696906, 166.966771, 262.2],
  [-11.696906, 166.966814, 262.6],
  [-11.696907, 166.966846, 263],
  [-11.696907, 166.966878, 263.4],
  [-11.696907, 166.966921, 264],
  [-11.696908, 166.966953, 264.4],
  [-11.69691, 166.966996, 264.8],
  [-11.69691, 166.967028, 265.4],
  [-11.69691, 166.967071, 265.8],
  [-11.696908, 166.967103, 266.2],
  [-11.696907, 166.967136, 266.6],
  [-11.696904, 166.967179, 267.2],
  [-11.6969, 166.967211, 267.6],
  [-11.696898, 166.967254, 268],
  [-11.696894, 166.967286, 268.4],
  [-11.696887, 166.967318, 269],
  [-11.69688, 166.967361, 269.4],
  [-11.696873, 166.967393, 269.8],
  [-11.696864, 166.967447, 270.4],
  [-11.696855, 166.967468, 270.8],
  [-11.696843, 166.9675, 271.2],
  [-11.696831, 166.967533, 271.6],
  [-11.696814, 166.967576, 272.2],
  [-11.696797, 166.967608, 272.6],
  [-11.696777, 166.96764, 273],
  [-11.69675, 166.967672, 273.4],
  [-11.696719, 166.967683, 273.8],
  [-11.696686, 166.967704, 274.2],
  [-11.696649, 166.967715, 274.6],
  [-11.696613, 166.967736, 275.2],
  [-11.696576, 166.967747, 275.6],
  [-11.696537, 166.967747, 276],
  [-11.696498, 166.967747, 276.4],
  [-11.696459, 166.967747, 276.8],
  [-11.69642, 166.967747, 277.4],
  [-11.696381, 166.967747, 277.8],
  [-11.696344, 166.967736, 278.2],
  [-11.696306, 166.967715, 278.6],
  [-11.696273, 166.967704, 279.2],
  [-11.69624, 166.967683, 279.6],
  [-11.696212, 166.967651, 280],
  [-11.696189, 166.967629, 280.4],
  [-11.696171, 166.967597, 281],
  [-11.696157, 166.967543, 281.4],
  [-11.696147, 166.967522, 281.8],
  [-11.696137, 166.967468, 282.4],
  [-11.69613, 166.967447, 282.8],
  [-11.696126, 166.967415, 283.4],
  [-11.696122, 166.967361, 283.8],
  [-11.696121, 166.96734, 284.4],
  [-11.696121, 166.967307, 284.8],
  [-11.696121, 166.967254, 285.2],
  [-11.696122, 166.967232, 285.8],
  [-11.696124, 166.9672, 286.2],
  [-11.696128, 166.967157, 286.6],
  [-11.69613, 166.967125, 287.2],
  [-11.696135, 166.967093, 287.6],
  [-11.696139, 166.967061, 288],
  [-11.696144, 166.967018, 288.6],
  [-11.696149, 166.966985, 289],
  [-11.696153, 166.966953, 289.4],
  [-11.69616, 166.966921, 289.8],
  [-11.696165, 166.966878, 290.4],
  [-11.696171, 166.966846, 290.8],
  [-11.696177, 166.966814, 291.2],
  [-11.696184, 166.966782, 291.8],
  [-11.696189, 166.96676, 292.2],
  [-11.696195, 166.966707, 292.6],
  [-11.6962, 166.966674, 293.2],
  [-11.696206, 166.966653, 293.6],
  [-11.69621, 166.966621, 294],
  [-11.696214, 166.966567, 294.6],
  [-11.696216, 166.966546, 295],
  [-11.696216, 166.966513, 295.4],
  [-11.696216, 166.966481, 296],
  [-11.696214, 166.966438, 296.4],
  [-11.696208, 166.966406, 296.8],
  [-11.696198, 166.966374, 297.4],
  [-11.696181, 166.966342, 297.8],
  [-11.696163, 166.966331, 298.4],
  [-11.69614, 166.966299, 298.8],
  [-11.696116, 166.966277, 299.4],
  [-11.696088, 166.966267, 299.8],
  [-11.696061, 166.966256, 300.2],
  [-11.69603, 166.966256, 300.8],
  [-11.696, 166.966256, 301.2],
  [-11.69597, 166.966256, 301.8],
  [-11.695941, 166.966256, 302.2],
  [-11.695913, 166.966267, 302.6],
  [-11.695888, 166.966277, 303.2],
  [-11.695864, 166.96631, 303.6],
  [-11.695839, 166.966331, 304],
  [-11.695817, 166.966342, 304.6],
  [-11.695798, 166.966374, 305],
  [-11.695778, 166.966406, 305.4],
  [-11.695758, 166.966417, 305.8],
  [-11.695739, 166.966449, 306.4],
  [-11.695719, 166.966481, 306.8],
  [-11.6957, 166.966513, 307.2],
  [-11.69568, 166.966524, 307.8],
  [-11.695658, 166.966556, 308.2],
  [-11.695636, 166.966588, 308.6],
  [-11.69561, 166.966599, 309],
  [-11.695583, 166.966631, 309.4],
  [-11.695554, 166.966653, 309.8],
  [-11.695522, 166.966664, 310.2],
  [-11.695488, 166.966674, 310.6],
  [-11.695452, 166.966696, 311.2],
  [-11.695414, 166.966696, 311.6],
  [-11.695378, 166.966707, 312],
  [-11.695341, 166.966728, 312.4],
  [-11.695302, 166.966728, 312.8],
  [-11.695264, 166.966739, 313.2],
  [-11.695227, 166.966739, 313.8],
  [-11.695186, 166.96676, 314.2],
  [-11.695147, 166.96676, 314.6],
  [-11.695107, 166.96676, 315],
  [-11.695068, 166.96676, 315.4],
  [-11.695027, 166.96676, 315.8],
  [-11.694985, 166.966771, 316.2],
  [-11.694942, 166.966771, 316.6],
  [-11.694898, 166.966771, 316.8],
  [-11.694854, 166.966771, 317.2],
  [-11.694807, 166.966771, 317.4],
  [-11.694757, 166.966771, 317.8],
  [-11.694708, 166.966771, 318],
  [-11.694654, 166.966771, 318.2],
  [-11.694598, 166.966771, 318.4],
  [-11.694537, 166.966771, 318.4],
  [-11.694473, 166.966771, 318.4],
  [-11.694404, 166.966771, 318.4],
  [-11.694332, 166.966771, 318.4],
  [-11.694257, 166.966771, 318.4],
  [-11.694179, 166.966771, 318.4],
  [-11.694099, 166.966771, 318.2],
  [-11.694014, 166.966771, 318],
];

export default stream;
