import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.788901, 165.846702, 80],
  [-10.788784, 165.846713, 80],
  [-10.788671, 165.846745, 80],
  [-10.788592, 165.846841, 79.8],
  [-10.788561, 165.846938, 80],
  [-10.788565, 165.847067, 80.6],
  [-10.788584, 165.847174, 81.2],
  [-10.788606, 165.847292, 81.8],
  [-10.78862, 165.847399, 82.6],
  [-10.788612, 165.847507, 83.4],
  [-10.788571, 165.847603, 84.2],
  [-10.788496, 165.847657, 85],
  [-10.788411, 165.847689, 85.6],
  [-10.78832, 165.847689, 86.2],
  [-10.788241, 165.847646, 87],
  [-10.788196, 165.847571, 87.6],
  [-10.788174, 165.847496, 88.2],
  [-10.788167, 165.847421, 88.6],
  [-10.788167, 165.847324, 89],
  [-10.788172, 165.847238, 89.2],
  [-10.788178, 165.847152, 89.6],
  [-10.788179, 165.847067, 89.8],
  [-10.78817, 165.84697, 90],
  [-10.788144, 165.846884, 90.4],
  [-10.788091, 165.84682, 90.6],
  [-10.788013, 165.846756, 90.8],
  [-10.787924, 165.846734, 90.8],
  [-10.787829, 165.846734, 91],
  [-10.787734, 165.846756, 91],
  [-10.787657, 165.84682, 91.4],
  [-10.787618, 165.846916, 92],
  [-10.787607, 165.847002, 92.6],
  [-10.787607, 165.847099, 93.2],
  [-10.787609, 165.847185, 93.8],
  [-10.787605, 165.847281, 94.2],
  [-10.787601, 165.847389, 94.8],
  [-10.787599, 165.847464, 95.2],
  [-10.787597, 165.847549, 95.6],
  [-10.787591, 165.847646, 96],
  [-10.787572, 165.847732, 96.4],
  [-10.787509, 165.847796, 96.6],
  [-10.787419, 165.847796, 96.6],
  [-10.787332, 165.847764, 96.4],
  [-10.787292, 165.847678, 96.8],
  [-10.787283, 165.847582, 97.2],
  [-10.787282, 165.847474, 97.4],
  [-10.787273, 165.847389, 97.8],
  [-10.787232, 165.847292, 98.4],
  [-10.787157, 165.847238, 99],
  [-10.787067, 165.847217, 99.6],
  [-10.786976, 165.847217, 100],
  [-10.786886, 165.847217, 100.6],
  [-10.786797, 165.847206, 101],
  [-10.786724, 165.847152, 101.4],
  [-10.786691, 165.847067, 101.4],
  [-10.786683, 165.84697, 101.4],
  [-10.786689, 165.846884, 101.2],
  [-10.786719, 165.846777, 101.2],
  [-10.786801, 165.846713, 101.6],
  [-10.786901, 165.846713, 102.4],
  [-10.786998, 165.846713, 103],
  [-10.787092, 165.846713, 103.8],
  [-10.787179, 165.846702, 104.4],
  [-10.787245, 165.846637, 104.8],
  [-10.787275, 165.846552, 105],
  [-10.787277, 165.846455, 104.8],
  [-10.787254, 165.846359, 104.6],
  [-10.787202, 165.846273, 104.6],
  [-10.787121, 165.846208, 105],
  [-10.787027, 165.846165, 105.4],
  [-10.786929, 165.846155, 106],
  [-10.786832, 165.846155, 106.6],
  [-10.786737, 165.846155, 107.2],
  [-10.786644, 165.846133, 107.8],
  [-10.786559, 165.846101, 108.4],
  [-10.786484, 165.846047, 109],
  [-10.786423, 165.845983, 109.4],
  [-10.786381, 165.845908, 110],
  [-10.786363, 165.845811, 110.4],
  [-10.786365, 165.845736, 110.8],
  [-10.786377, 165.84565, 111.2],
  [-10.786391, 165.845554, 111.6],
  [-10.786394, 165.845468, 112],
  [-10.786369, 165.845382, 112.4],
  [-10.786312, 165.845307, 113],
  [-10.786239, 165.845275, 113.6],
  [-10.786157, 165.845253, 114.2],
  [-10.786075, 165.845253, 114.6],
  [-10.785991, 165.845253, 115],
  [-10.785908, 165.845253, 115.6],
  [-10.785825, 165.845253, 116],
  [-10.785743, 165.845232, 116.4],
  [-10.785669, 165.8452, 117],
  [-10.785614, 165.845146, 117.4],
  [-10.785587, 165.84505, 118],
  [-10.785578, 165.844975, 118.4],
  [-10.785576, 165.844899, 118.6],
  [-10.785574, 165.844803, 118.8],
  [-10.785578, 165.844717, 119.2],
  [-10.785599, 165.844642, 119.4],
  [-10.785651, 165.844567, 119.6],
  [-10.78573, 165.844513, 120],
  [-10.785816, 165.844502, 120.4],
  [-10.785906, 165.844492, 120.8],
  [-10.785995, 165.844492, 121.2],
  [-10.786086, 165.844492, 121.6],
  [-10.786177, 165.844492, 122],
  [-10.786263, 165.844513, 122.2],
  [-10.78632, 165.844578, 122.6],
  [-10.786336, 165.844674, 122.6],
  [-10.786337, 165.84476, 122.6],
  [-10.78634, 165.844867, 122.6],
  [-10.786379, 165.844964, 122.4],
  [-10.786469, 165.845007, 122.4],
  [-10.786573, 165.844985, 122.6],
  [-10.786653, 165.84491, 122.8],
  [-10.786681, 165.844824, 123],
  [-10.786676, 165.844717, 123.4],
  [-10.786661, 165.84461, 123.8],
  [-10.786662, 165.844502, 124.2],
  [-10.786708, 165.844406, 124.2],
  [-10.786791, 165.844352, 124.6],
  [-10.786891, 165.84432, 125.2],
  [-10.786992, 165.84432, 126],
  [-10.787076, 165.844363, 126.6],
  [-10.787114, 165.844438, 127.2],
  [-10.787119, 165.844535, 127.6],
  [-10.787117, 165.844642, 128.2],
  [-10.787139, 165.844717, 128.6],
  [-10.7872, 165.844792, 129.2],
  [-10.787282, 165.844824, 129.8],
  [-10.78737, 165.844835, 130.2],
  [-10.787455, 165.844824, 130.8],
  [-10.787534, 165.844781, 131],
  [-10.787602, 165.844728, 131],
  [-10.787659, 165.844653, 131.2],
  [-10.787708, 165.844578, 131.2],
  [-10.787754, 165.844492, 131.4],
  [-10.787802, 165.844395, 131.4],
  [-10.787864, 165.84432, 131.2],
  [-10.787939, 165.844245, 131.2],
  [-10.78802, 165.844181, 131.2],
  [-10.788114, 165.844138, 131.2],
  [-10.788222, 165.844116, 131.2],
  [-10.788323, 165.84417, 131.2],
  [-10.788404, 165.844245, 131.2],
  [-10.788445, 165.844352, 131.2],
  [-10.788445, 165.84446, 131.2],
  [-10.788419, 165.844567, 131.2],
  [-10.788366, 165.844674, 131.2],
  [-10.788297, 165.844781, 131.2],
  [-10.788217, 165.844857, 131.2],
  [-10.788136, 165.844942, 131.2],
  [-10.788081, 165.84505, 131.2],
  [-10.788101, 165.845157, 131.2],
  [-10.788195, 165.845232, 131.2],
  [-10.788314, 165.845253, 131.4],
  [-10.788434, 165.845232, 131.4],
  [-10.788553, 165.845232, 131.4],
  [-10.788671, 165.845253, 131.4],
  [-10.788732, 165.845361, 131.4],
  [-10.78865, 165.845436, 131.2],
  [-10.788527, 165.845447, 130.8],
  [-10.7884, 165.845447, 130.6],
  [-10.788274, 165.845436, 130.4],
  [-10.788146, 165.845436, 130],
  [-10.788016, 165.845436, 129.8],
  [-10.787887, 165.845447, 129.4],
  [-10.787767, 165.845511, 129],
  [-10.78769, 165.845618, 128.4],
  [-10.787668, 165.845758, 127.8],
  [-10.787678, 165.845886, 127],
  [-10.787719, 165.846026, 126.2],
  [-10.787792, 165.846155, 125.2],
  [-10.787871, 165.846283, 124.4],
  [-10.78793, 165.846423, 123.6],
  [-10.78795, 165.846573, 123],
  [-10.787952, 165.846734, 122.8],
  [-10.787943, 165.846884, 122.4],
  [-10.78793, 165.847024, 122.2],
  [-10.787918, 165.847174, 122],
  [-10.787916, 165.847324, 121.8],
  [-10.787921, 165.847474, 121.4],
  [-10.787928, 165.847625, 120.8],
  [-10.787945, 165.847764, 120.2],
  [-10.788004, 165.847903, 119.6],
  [-10.788124, 165.848, 118.8],
  [-10.788273, 165.848022, 118],
  [-10.788413, 165.848086, 117.2],
  [-10.788483, 165.848225, 116.4],
  [-10.788477, 165.848376, 116],
  [-10.78838, 165.848504, 115.2],
  [-10.788231, 165.848526, 114.6],
  [-10.788077, 165.848547, 114.2],
  [-10.787939, 165.848622, 113.8],
  [-10.787866, 165.848762, 113.2],
  [-10.787855, 165.848912, 112.8],
  [-10.787867, 165.849062, 112.2],
  [-10.787881, 165.849212, 111.6],
  [-10.787916, 165.849373, 110.8],
  [-10.788009, 165.849491, 109.6],
  [-10.788158, 165.849524, 108.6],
  [-10.788319, 165.849534, 107.8],
  [-10.78842, 165.849663, 107.4],
  [-10.788426, 165.849813, 107.6],
  [-10.78836, 165.849953, 107.6],
  [-10.788217, 165.849996, 106.6],
  [-10.788063, 165.849996, 105.4],
  [-10.787923, 165.850071, 104.4],
  [-10.787853, 165.85021, 104.2],
  [-10.787835, 165.85036, 104.4],
  [-10.787833, 165.850511, 104.6],
  [-10.787835, 165.850672, 104.6],
  [-10.787846, 165.850822, 104.8],
  [-10.787906, 165.85094, 104.6],
  [-10.788022, 165.851036, 104],
  [-10.788163, 165.851058, 102.8],
  [-10.788314, 165.851058, 101.6],
  [-10.788467, 165.851058, 100.6],
  [-10.788621, 165.851058, 99.8],
  [-10.788779, 165.851047, 99],
  [-10.788915, 165.850972, 98.6],
  [-10.789003, 165.850854, 98.4],
  [-10.789028, 165.850693, 98.6],
  [-10.788944, 165.850575, 98.6],
  [-10.788799, 165.850564, 98.6],
  [-10.788655, 165.850564, 98.6],
  [-10.788548, 165.850468, 98.6],
  [-10.788559, 165.850328, 98.6],
  [-10.788659, 165.850242, 98.6],
  [-10.788796, 165.85021, 98.8],
  [-10.788931, 165.85021, 98.4],
  [-10.78907, 165.85021, 97.8],
  [-10.789211, 165.85021, 97],
  [-10.789353, 165.85021, 96.2],
  [-10.789499, 165.850189, 95.6],
  [-10.789638, 165.850146, 95],
  [-10.789715, 165.850028, 95.2],
  [-10.789695, 165.849888, 95.2],
  [-10.789578, 165.849813, 95.2],
  [-10.789439, 165.849813, 95.2],
  [-10.789299, 165.849824, 95.2],
  [-10.789192, 165.849738, 95.2],
  [-10.789174, 165.849609, 95.2],
  [-10.789175, 165.849481, 95.2],
  [-10.789115, 165.849352, 95],
  [-10.789009, 165.849277, 94.6],
  [-10.788876, 165.849245, 93.8],
  [-10.788734, 165.849266, 92.8],
  [-10.788589, 165.849277, 91.6],
  [-10.78844, 165.849277, 90.6],
  [-10.788295, 165.849245, 90.2],
  [-10.788199, 165.849137, 90.2],
  [-10.788201, 165.848987, 90.6],
  [-10.788298, 165.848901, 90.8],
  [-10.788434, 165.848848, 90.4],
  [-10.788576, 165.848848, 89.4],
  [-10.788724, 165.848848, 88.2],
  [-10.788873, 165.848848, 87.2],
  [-10.789023, 165.848869, 86.4],
  [-10.789133, 165.848976, 86.6],
  [-10.789182, 165.849105, 86.8],
  [-10.789233, 165.849245, 86.4],
  [-10.789357, 165.849309, 86.2],
  [-10.789482, 165.849245, 86.4],
  [-10.789535, 165.849127, 86],
  [-10.789542, 165.848976, 85.4],
  [-10.789529, 165.848837, 84.6],
  [-10.789504, 165.848687, 84],
  [-10.789461, 165.848526, 83],
  [-10.789402, 165.848397, 81.8],
  [-10.789362, 165.848236, 81],
  [-10.789389, 165.848086, 80.8],
  [-10.789441, 165.847946, 81],
  [-10.789443, 165.847796, 80.6],
  [-10.789426, 165.847646, 80],
  [-10.789414, 165.847496, 80],
  [-10.789411, 165.847335, 80],
  [-10.789408, 165.847185, 80],
  [-10.789403, 165.847045, 80],
];

export default stream;
