import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.692991, 166.9672, 317.6],
  [-11.692991, 166.967157, 318],
  [-11.692988, 166.967125, 318.4],
  [-11.692984, 166.967071, 318.6],
  [-11.69298, 166.967028, 319],
  [-11.692975, 166.966985, 319.2],
  [-11.692968, 166.966943, 319.6],
  [-11.692961, 166.966889, 319.8],
  [-11.692953, 166.966846, 320.2],
  [-11.692944, 166.966803, 320.4],
  [-11.692933, 166.96676, 320.6],
  [-11.692923, 166.966696, 320.8],
];

export default stream;
