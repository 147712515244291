import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [40.771047, -73.974633, 40.8],
  [40.771004, -73.974354, 40.6],
  [40.770964, -73.974075, 40.8],
  [40.770891, -73.973817, 41.4],
  [40.770811, -73.973565, 42],
  [40.770752, -73.973324, 42.6],
  [40.77073, -73.973061, 43],
  [40.770765, -73.972803, 43.2],
  [40.770846, -73.972573, 43.4],
  [40.770945, -73.972353, 43.4],
  [40.771047, -73.972149, 43.4],
  [40.771138, -73.971918, 43.4],
  [40.771216, -73.971688, 43.8],
  [40.771261, -73.971452, 44],
  [40.771176, -73.971232, 43],
  [40.771039, -73.971049, 41.4],
  [40.770886, -73.970872, 39.8],
  [40.77073, -73.970695, 38.2],
  [40.77058, -73.970507, 36.8],
  [40.770462, -73.970287, 36],
  [40.77043, -73.970019, 37.2],
  [40.770489, -73.969767, 39.6],
  [40.770594, -73.969547, 41.2],
  [40.770714, -73.96937, 42.2],
  [40.770851, -73.969209, 42.8],
  [40.770993, -73.969064, 43.4],
  [40.771138, -73.96892, 43.8],
  [40.771283, -73.96878, 43.8],
  [40.771436, -73.968646, 43.8],
  [40.771589, -73.968517, 43.8],
  [40.771742, -73.968383, 43.6],
  [40.771903, -73.968254, 43.6],
  [40.772053, -73.968126, 43.6],
  [40.772214, -73.967997, 43.6],
  [40.772375, -73.967857, 43.6],
  [40.772536, -73.967739, 43.6],
  [40.772699, -73.967605, 43.6],
  [40.772855, -73.967487, 43.6],
  [40.773018, -73.967353, 43.6],
  [40.773182, -73.967235, 43.6],
  [40.773348, -73.967106, 43.6],
  [40.773512, -73.966983, 43.6],
  [40.773675, -73.96686, 43.6],
  [40.773842, -73.966736, 43.6],
  [40.774008, -73.966624, 43.6],
  [40.774174, -73.9665, 43.6],
  [40.774338, -73.966377, 43.6],
  [40.77451, -73.966264, 43.6],
  [40.774679, -73.966141, 43.6],
  [40.774845, -73.966028, 43.8],
  [40.775014, -73.965915, 43.8],
  [40.775177, -73.965797, 43.8],
  [40.775349, -73.965685, 44],
  [40.775518, -73.965577, 44.2],
  [40.77569, -73.96547, 44.2],
  [40.775856, -73.965363, 44.4],
  [40.776033, -73.965256, 44.6],
  [40.776199, -73.965164, 44.8],
  [40.776376, -73.965073, 45.2],
  [40.776551, -73.964993, 45.6],
  [40.776728, -73.964912, 46.2],
  [40.776899, -73.964837, 46.8],
  [40.777074, -73.964778, 47.4],
  [40.777254, -73.964725, 48.2],
  [40.777425, -73.964692, 49],
  [40.7776, -73.964687, 50.2],
  [40.777777, -73.964725, 51.2],
  [40.777935, -73.964784, 52.2],
  [40.778096, -73.964859, 53],
  [40.778254, -73.96495, 54],
  [40.778399, -73.965057, 54.8],
  [40.778528, -73.965191, 55.4],
  [40.778646, -73.965347, 56],
  [40.778737, -73.965535, 56.6],
  [40.778817, -73.965722, 57],
  [40.778887, -73.965921, 57.4],
  [40.778951, -73.966135, 57.8],
  [40.779016, -73.966339, 58.2],
  [40.779085, -73.966548, 58.6],
  [40.779158, -73.966763, 59],
  [40.779222, -73.966967, 59.2],
  [40.779287, -73.967181, 59.4],
  [40.779351, -73.967396, 59.8],
  [40.779423, -73.967605, 60],
  [40.779507, -73.967814, 60.2],
  [40.779619, -73.967991, 60.6],
  [40.779786, -73.968083, 60.8],
  [40.779957, -73.968142, 61],
  [40.780134, -73.968195, 61.4],
  [40.780306, -73.968233, 61.6],
  [40.780486, -73.968254, 61.8],
  [40.780671, -73.96827, 62.2],
  [40.78085, -73.968249, 62.4],
  [40.781025, -73.968217, 62.8],
  [40.781204, -73.968174, 63],
  [40.781381, -73.968126, 63.4],
  [40.781561, -73.968083, 63.6],
  [40.781735, -73.968029, 63.6],
  [40.78191, -73.967975, 63.6],
];

export default stream;
