import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.789182, 165.846308, 79.8],
  [-10.789174, 165.846412, 80],
  [-10.789165, 165.84653, 80],
  [-10.789116, 165.846627, 80],
  [-10.789015, 165.84668, 80],
];

export default stream;
