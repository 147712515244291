import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.673643, 166.969228, 99.2],
  [-11.673664, 166.969196, 99.6],
  [-11.673686, 166.969153, 99.8],
  [-11.673711, 166.969121, 100.2],
  [-11.673737, 166.969078, 100.6],
  [-11.673764, 166.969045, 100.8],
  [-11.67379, 166.969013, 101.2],
  [-11.673817, 166.96897, 101.4],
  [-11.673844, 166.968938, 101.8],
  [-11.673871, 166.968895, 102.2],
  [-11.6739, 166.968863, 102.4],
  [-11.673933, 166.968831, 102.6],
  [-11.673967, 166.968799, 103],
  [-11.674005, 166.968756, 103.2],
  [-11.674047, 166.968734, 103.4],
  [-11.674095, 166.968713, 103.4],
  [-11.674146, 166.968691, 103.6],
  [-11.674202, 166.968659, 103.6],
  [-11.67426, 166.968648, 103.8],
  [-11.674321, 166.968627, 103.8],
  [-11.674384, 166.968616, 103.8],
  [-11.674445, 166.968584, 104],
  [-11.674498, 166.968541, 104.2],
  [-11.674541, 166.968498, 104.6],
  [-11.674572, 166.968434, 105],
  [-11.674594, 166.968359, 105.4],
  [-11.67461, 166.968294, 105.6],
  [-11.674622, 166.96823, 106],
  [-11.67463, 166.968176, 106.4],
  [-11.674637, 166.968112, 106.8],
  [-11.674643, 166.968048, 107],
  [-11.674646, 166.967994, 107.4],
  [-11.67465, 166.96793, 107.8],
  [-11.674653, 166.967865, 108.2],
  [-11.674655, 166.967812, 108.4],
  [-11.674657, 166.967747, 108.8],
  [-11.674658, 166.967683, 109.2],
  [-11.674659, 166.96764, 109.4],
  [-11.674659, 166.967576, 109.8],
  [-11.674661, 166.967522, 110.2],
  [-11.674661, 166.967468, 110.4],
  [-11.674662, 166.967415, 110.8],
  [-11.674663, 166.96735, 111],
  [-11.674662, 166.967307, 111.4],
  [-11.674658, 166.967243, 111.8],
  [-11.67465, 166.967179, 112],
  [-11.674642, 166.967136, 112.4],
  [-11.674633, 166.967093, 112.8],
  [-11.674621, 166.967028, 113],
  [-11.67461, 166.966985, 113.4],
  [-11.6746, 166.966921, 113.8],
  [-11.674595, 166.966878, 114],
  [-11.674594, 166.966814, 114.4],
  [-11.674596, 166.966771, 114.6],
  [-11.674606, 166.966707, 115],
  [-11.674619, 166.966664, 115.4],
  [-11.674635, 166.966621, 115.6],
  [-11.674653, 166.966567, 116],
  [-11.674673, 166.966513, 116.2],
  [-11.674696, 166.96647, 116.4],
  [-11.67472, 166.966417, 116.8],
  [-11.674748, 166.966374, 117],
  [-11.674779, 166.966331, 117.4],
  [-11.674814, 166.966277, 117.6],
  [-11.674853, 166.966256, 118],
  [-11.674897, 166.966224, 118.2],
  [-11.674944, 166.966192, 118.6],
  [-11.674993, 166.96617, 119],
  [-11.675046, 166.966159, 119.4],
  [-11.675095, 166.966159, 119.8],
  [-11.675145, 166.96617, 120.2],
  [-11.675195, 166.96617, 120.4],
  [-11.675244, 166.966192, 120.8],
  [-11.675292, 166.966192, 121.2],
  [-11.675339, 166.966202, 121.4],
  [-11.675388, 166.966224, 121.8],
  [-11.675435, 166.966234, 122],
  [-11.675481, 166.966267, 122.4],
  [-11.675527, 166.966277, 122.6],
  [-11.675574, 166.966299, 123],
  [-11.675618, 166.966331, 123.4],
  [-11.675664, 166.966342, 123.6],
  [-11.675708, 166.966374, 124],
  [-11.675752, 166.966385, 124.2],
  [-11.675795, 166.966417, 124.6],
  [-11.675838, 166.966449, 125],
  [-11.675878, 166.966481, 125.2],
  [-11.675919, 166.966513, 125.6],
  [-11.675958, 166.966546, 125.8],
  [-11.675995, 166.966567, 126.2],
  [-11.676033, 166.966599, 126.6],
  [-11.676068, 166.966653, 126.8],
  [-11.676102, 166.966674, 127.2],
  [-11.676136, 166.966707, 127.4],
  [-11.676169, 166.96676, 127.8],
  [-11.676202, 166.966803, 128],
  [-11.676233, 166.966835, 128.4],
  [-11.676262, 166.966878, 128.6],
  [-11.67629, 166.966921, 129],
  [-11.676317, 166.966964, 129.2],
  [-11.676341, 166.967018, 129.6],
  [-11.676363, 166.967061, 129.8],
  [-11.676383, 166.967103, 130.2],
  [-11.676401, 166.967168, 130.4],
  [-11.676415, 166.967211, 130.8],
  [-11.676424, 166.967254, 131],
  [-11.676427, 166.967318, 131.4],
  [-11.676426, 166.967361, 131.6],
  [-11.67642, 166.967425, 132],
  [-11.676412, 166.967468, 132.2],
  [-11.676401, 166.967533, 132.6],
  [-11.676388, 166.967576, 132.8],
  [-11.676373, 166.96764, 133.2],
  [-11.676359, 166.967683, 133.4],
  [-11.676344, 166.967747, 133.6],
  [-11.676326, 166.96779, 134],
  [-11.676312, 166.967854, 134.2],
  [-11.676297, 166.967897, 134.4],
  [-11.676288, 166.967962, 134.8],
  [-11.676281, 166.968026, 135],
  [-11.676281, 166.96808, 135.2],
  [-11.676289, 166.968133, 135.6],
  [-11.676301, 166.968187, 135.8],
  [-11.676318, 166.968251, 135.8],
  [-11.67634, 166.968316, 136],
  [-11.676364, 166.968369, 136.2],
  [-11.676393, 166.968434, 136.2],
  [-11.676427, 166.968477, 136.4],
  [-11.676466, 166.968541, 136.4],
  [-11.676511, 166.968606, 136.6],
  [-11.676564, 166.968648, 136.8],
  [-11.676624, 166.968681, 137],
  [-11.676694, 166.968713, 137.2],
  [-11.676766, 166.968713, 137.4],
  [-11.676839, 166.968713, 137.6],
  [-11.676911, 166.968691, 137.8],
  [-11.676981, 166.968681, 138],
  [-11.677051, 166.968648, 138.2],
  [-11.677112, 166.968616, 138.6],
  [-11.677157, 166.968552, 139.2],
  [-11.677182, 166.968498, 139.6],
  [-11.677194, 166.968434, 140.2],
  [-11.677202, 166.968369, 140.6],
  [-11.677207, 166.968316, 141],
  [-11.677212, 166.968251, 141.4],
  [-11.677214, 166.968187, 141.8],
  [-11.677217, 166.968133, 142.2],
  [-11.677222, 166.968069, 142.6],
  [-11.677229, 166.968026, 143],
  [-11.677234, 166.967962, 143.2],
  [-11.677241, 166.967919, 143.6],
  [-11.677245, 166.967854, 144],
  [-11.677249, 166.967812, 144.4],
  [-11.677252, 166.967758, 144.8],
  [-11.677256, 166.967704, 145],
  [-11.677258, 166.967651, 145.4],
  [-11.677261, 166.967608, 145.8],
  [-11.677264, 166.967543, 146],
  [-11.677268, 166.9675, 146.4],
  [-11.677271, 166.967458, 146.8],
  [-11.677275, 166.967415, 147.2],
  [-11.677277, 166.96735, 147.4],
  [-11.677281, 166.967307, 147.8],
  [-11.677283, 166.967254, 148.2],
  [-11.677285, 166.967211, 148.4],
  [-11.677288, 166.967168, 148.8],
  [-11.677291, 166.967125, 149.2],
  [-11.677292, 166.967071, 149.6],
  [-11.677295, 166.967018, 149.8],
  [-11.677297, 166.966964, 150.2],
  [-11.6773, 166.966921, 150.6],
  [-11.677304, 166.966878, 150.8],
  [-11.677308, 166.966835, 151.2],
  [-11.677312, 166.966782, 151.6],
  [-11.677316, 166.966739, 151.8],
  [-11.677319, 166.966696, 152.2],
  [-11.67732, 166.966653, 152.6],
  [-11.677323, 166.966599, 152.8],
  [-11.677327, 166.966556, 153.2],
  [-11.677334, 166.966513, 153.6],
  [-11.677342, 166.96647, 153.8],
  [-11.677354, 166.966417, 154.2],
  [-11.677367, 166.966374, 154.6],
  [-11.677383, 166.966331, 154.8],
  [-11.677399, 166.966299, 155.2],
  [-11.677417, 166.966256, 155.4],
  [-11.677434, 166.966202, 155.8],
  [-11.677452, 166.966159, 156],
  [-11.677469, 166.966116, 156.4],
  [-11.677486, 166.966074, 156.6],
  [-11.677507, 166.96602, 157],
  [-11.677525, 166.965977, 157.2],
  [-11.677547, 166.965934, 157.4],
  [-11.677568, 166.96588, 157.8],
  [-11.677591, 166.965837, 158.2],
  [-11.677617, 166.965795, 158.4],
  [-11.677642, 166.965752, 158.8],
  [-11.677669, 166.965698, 159],
  [-11.677698, 166.965655, 159.4],
  [-11.677731, 166.965623, 159.8],
  [-11.677764, 166.96558, 160.2],
  [-11.6778, 166.965548, 160.4],
  [-11.677839, 166.965516, 160.8],
  [-11.677881, 166.965505, 161.2],
  [-11.677922, 166.965473, 161.6],
  [-11.677967, 166.965462, 161.8],
  [-11.678011, 166.96543, 162.2],
  [-11.678054, 166.965408, 162.6],
  [-11.678099, 166.965408, 162.8],
  [-11.678146, 166.965398, 163.2],
  [-11.678192, 166.965387, 163.6],
  [-11.678239, 166.965387, 163.8],
  [-11.678284, 166.965365, 164.2],
  [-11.678331, 166.965365, 164.6],
  [-11.678377, 166.965365, 164.8],
  [-11.678424, 166.965355, 165.2],
  [-11.678471, 166.965365, 165.6],
  [-11.678516, 166.965365, 165.8],
  [-11.678562, 166.965365, 166.2],
  [-11.678606, 166.965387, 166.6],
  [-11.678648, 166.965398, 167],
  [-11.678685, 166.96543, 167.2],
  [-11.67872, 166.965462, 167.6],
  [-11.67875, 166.965483, 167.8],
  [-11.678778, 166.965537, 168.2],
  [-11.678803, 166.96558, 168.4],
  [-11.678825, 166.965612, 168.6],
  [-11.678846, 166.965655, 169],
  [-11.678865, 166.965698, 169.2],
  [-11.678885, 166.965752, 169.6],
  [-11.678905, 166.965805, 169.8],
  [-11.678924, 166.965859, 170.2],
  [-11.678944, 166.965902, 170.4],
  [-11.678968, 166.965945, 170.8],
  [-11.678991, 166.965988, 171.2],
  [-11.679014, 166.966041, 171.4],
  [-11.679038, 166.966084, 171.8],
  [-11.679061, 166.966127, 172.2],
  [-11.679082, 166.96617, 172.4],
  [-11.6791, 166.966224, 172.8],
  [-11.679116, 166.966267, 173],
  [-11.679131, 166.96631, 173.4],
  [-11.679141, 166.966363, 173.8],
  [-11.679149, 166.966417, 174],
  [-11.679155, 166.96647, 174.4],
  [-11.679156, 166.966513, 174.6],
  [-11.679155, 166.966556, 175],
  [-11.679149, 166.966621, 175.4],
  [-11.67914, 166.966664, 175.6],
  [-11.679129, 166.966707, 176],
  [-11.679115, 166.96676, 176.2],
  [-11.679098, 166.966814, 176.6],
  [-11.679081, 166.966857, 176.8],
  [-11.679062, 166.96691, 177.2],
  [-11.679043, 166.966953, 177.4],
  [-11.679023, 166.966996, 177.8],
  [-11.679003, 166.96705, 178],
  [-11.678983, 166.967093, 178.4],
  [-11.678963, 166.967157, 178.6],
  [-11.678942, 166.9672, 179],
  [-11.678923, 166.967243, 179.4],
  [-11.678904, 166.967286, 179.6],
  [-11.678887, 166.96734, 180],
  [-11.678872, 166.967393, 180.2],
  [-11.678857, 166.967447, 180.6],
  [-11.678845, 166.96749, 181],
  [-11.678833, 166.967543, 181.2],
  [-11.678825, 166.967597, 181.6],
  [-11.67882, 166.96764, 182],
  [-11.678815, 166.967704, 182.4],
  [-11.678813, 166.967747, 182.6],
  [-11.678813, 166.96779, 183],
  [-11.678814, 166.967844, 183.4],
  [-11.678815, 166.967897, 183.6],
  [-11.67882, 166.96794, 184],
  [-11.678822, 166.967994, 184.4],
  [-11.678828, 166.968037, 184.6],
  [-11.678833, 166.96808, 185],
  [-11.678838, 166.968133, 185.4],
  [-11.678844, 166.968187, 185.6],
  [-11.67885, 166.96823, 186],
  [-11.678856, 166.968284, 186.4],
  [-11.678862, 166.968327, 186.6],
  [-11.678869, 166.968369, 187],
  [-11.678876, 166.968423, 187.4],
  [-11.678883, 166.968466, 187.6],
  [-11.678889, 166.96853, 188],
  [-11.678895, 166.968573, 188.2],
  [-11.678901, 166.968616, 188.6],
  [-11.678908, 166.968659, 189],
  [-11.678912, 166.968713, 189.2],
  [-11.678916, 166.968756, 189.6],
  [-11.67892, 166.968799, 190],
  [-11.678921, 166.968863, 190.2],
  [-11.678924, 166.968906, 190.6],
  [-11.678924, 166.968949, 190.8],
  [-11.678923, 166.969002, 191.2],
  [-11.67892, 166.969045, 191.4],
  [-11.678917, 166.96911, 191.8],
  [-11.678912, 166.969153, 192.2],
  [-11.678908, 166.969196, 192.4],
  [-11.678901, 166.969239, 192.8],
  [-11.678895, 166.969292, 193],
  [-11.678888, 166.969346, 193.4],
  [-11.67888, 166.969399, 193.6],
  [-11.67887, 166.969442, 194],
  [-11.678862, 166.969485, 194.2],
  [-11.678852, 166.96955, 194.6],
  [-11.678842, 166.969593, 194.8],
  [-11.678833, 166.969635, 195.2],
  [-11.678822, 166.9697, 195.6],
  [-11.678813, 166.969743, 195.8],
  [-11.678803, 166.969796, 196.2],
  [-11.678793, 166.96985, 196.4],
  [-11.678785, 166.969904, 196.8],
  [-11.678777, 166.969947, 197],
  [-11.67877, 166.97, 197.4],
  [-11.678763, 166.970054, 197.6],
  [-11.678756, 166.970097, 198],
  [-11.678748, 166.970161, 198.2],
  [-11.67874, 166.970204, 198.6],
  [-11.678734, 166.970268, 198.8],
  [-11.678726, 166.970311, 199.2],
  [-11.678718, 166.970376, 199.4],
  [-11.67871, 166.970419, 199.8],
  [-11.6787, 166.970483, 200],
  [-11.678693, 166.970526, 200.4],
  [-11.678684, 166.97059, 200.6],
  [-11.678677, 166.970633, 201],
  [-11.678671, 166.970687, 201.2],
  [-11.678664, 166.970741, 201.6],
  [-11.678657, 166.970794, 202],
  [-11.678651, 166.970848, 202.2],
  [-11.678644, 166.970901, 202.6],
  [-11.67864, 166.970955, 203],
  [-11.678634, 166.971009, 203.2],
  [-11.678629, 166.971062, 203.6],
  [-11.678625, 166.971105, 204],
  [-11.67862, 166.97117, 204.4],
  [-11.678613, 166.971213, 204.8],
  [-11.678606, 166.971256, 205],
  [-11.6786, 166.971298, 205.4],
  [-11.678593, 166.971352, 205.8],
  [-11.678586, 166.971406, 206.2],
  [-11.678579, 166.971459, 206.6],
  [-11.678573, 166.971502, 206.8],
  [-11.678569, 166.971545, 207.2],
  [-11.678563, 166.971588, 207.6],
  [-11.678559, 166.971642, 208],
  [-11.678555, 166.971685, 208.4],
  [-11.678554, 166.971717, 208.6],
  [-11.678551, 166.97176, 209],
  [-11.678551, 166.971803, 209.4],
  [-11.678553, 166.971856, 209.8],
  [-11.678555, 166.971899, 210],
  [-11.678562, 166.971942, 210.4],
  [-11.678569, 166.971985, 210.8],
  [-11.678579, 166.972017, 211],
  [-11.678592, 166.97206, 211.4],
  [-11.678608, 166.972114, 211.6],
  [-11.678621, 166.972157, 212],
  [-11.678637, 166.9722, 212.2],
  [-11.678656, 166.972232, 212.6],
  [-11.678679, 166.972275, 212.8],
  [-11.678707, 166.972328, 213],
  [-11.67874, 166.97235, 213.4],
  [-11.678779, 166.972382, 213.6],
  [-11.678822, 166.972414, 213.6],
  [-11.67887, 166.972446, 213.8],
  [-11.678924, 166.972457, 214],
  [-11.678979, 166.972479, 214],
  [-11.679037, 166.972511, 214.2],
  [-11.6791, 166.972511, 214.2],
  [-11.679166, 166.972522, 214.2],
  [-11.679235, 166.972522, 214.2],
  [-11.679305, 166.972522, 214.2],
  [-11.67938, 166.972522, 214.2],
  [-11.679455, 166.972522, 214.2],
  [-11.679533, 166.972522, 214.2],
  [-11.679612, 166.972522, 214.2],
  [-11.679693, 166.972522, 214.2],
  [-11.679776, 166.972522, 214.2],
  [-11.679859, 166.972522, 214.2],
  [-11.679945, 166.972543, 214.2],
  [-11.680032, 166.972543, 214.2],
  [-11.680119, 166.972543, 214.2],
  [-11.680208, 166.972543, 214.2],
  [-11.680296, 166.972543, 214.2],
  [-11.680387, 166.972543, 214.2],
  [-11.680477, 166.972543, 214.2],
  [-11.680568, 166.972543, 214.2],
  [-11.680661, 166.972543, 214.2],
  [-11.680755, 166.972543, 214.2],
  [-11.680849, 166.972543, 214.2],
  [-11.680942, 166.972554, 214.2],
  [-11.681038, 166.972554, 214.2],
  [-11.681132, 166.972554, 214.2],
  [-11.681229, 166.972554, 214.2],
  [-11.681323, 166.972554, 214.4],
  [-11.681419, 166.972543, 214.4],
  [-11.681512, 166.972522, 214.6],
  [-11.681606, 166.972522, 215],
  [-11.681698, 166.972511, 215.2],
  [-11.681787, 166.972489, 215.6],
  [-11.681872, 166.972457, 216.2],
  [-11.681948, 166.972436, 216.6],
  [-11.682018, 166.972382, 217.2],
  [-11.682074, 166.972339, 218],
  [-11.68212, 166.972275, 218.4],
  [-11.682156, 166.972221, 219],
  [-11.682176, 166.972157, 219.6],
  [-11.68219, 166.972092, 220],
  [-11.682196, 166.972039, 220.6],
  [-11.682199, 166.971974, 221],
  [-11.682199, 166.97191, 221.4],
  [-11.682198, 166.971867, 221.8],
  [-11.682196, 166.971803, 222.2],
  [-11.682194, 166.97176, 222.6],
  [-11.68219, 166.971695, 223],
  [-11.682187, 166.971653, 223.2],
  [-11.682184, 166.971588, 223.6],
  [-11.682182, 166.971545, 224],
  [-11.68218, 166.971502, 224.4],
  [-11.682179, 166.971459, 224.6],
  [-11.68218, 166.971395, 225],
  [-11.682183, 166.971352, 225.4],
  [-11.682187, 166.971298, 225.6],
  [-11.682194, 166.971256, 226],
  [-11.682206, 166.971213, 226.2],
  [-11.682219, 166.971148, 226.6],
  [-11.682241, 166.971105, 226.8],
  [-11.682266, 166.971073, 227.2],
  [-11.682298, 166.97103, 227.4],
  [-11.682332, 166.970977, 227.6],
  [-11.682371, 166.970955, 227.8],
  [-11.682412, 166.970923, 228],
  [-11.682458, 166.970891, 228.2],
  [-11.682506, 166.970859, 228.4],
  [-11.682557, 166.970826, 228.6],
  [-11.682612, 166.970794, 228.8],
  [-11.682668, 166.970773, 228.8],
  [-11.682727, 166.970741, 229],
  [-11.682791, 166.970719, 229.2],
  [-11.682854, 166.970708, 229.2],
  [-11.682921, 166.970687, 229.4],
  [-11.682989, 166.970676, 229.6],
  [-11.683057, 166.970665, 229.8],
  [-11.68313, 166.970644, 230],
  [-11.683202, 166.970644, 230.2],
  [-11.683273, 166.970665, 230.4],
  [-11.683342, 166.970676, 230.6],
  [-11.683407, 166.970708, 230.8],
  [-11.68347, 166.970751, 231],
  [-11.683532, 166.970783, 231.2],
  [-11.68359, 166.970826, 231.4],
  [-11.683649, 166.97088, 231.6],
  [-11.683706, 166.970923, 231.8],
  [-11.683764, 166.970966, 232],
  [-11.683826, 166.971009, 232.2],
  [-11.68389, 166.971041, 232.6],
  [-11.683956, 166.971073, 232.8],
  [-11.684027, 166.971105, 233],
  [-11.684098, 166.971116, 233.2],
  [-11.684171, 166.971138, 233.4],
  [-11.684243, 166.971138, 233.6],
  [-11.684317, 166.971148, 233.8],
  [-11.684389, 166.97117, 234],
  [-11.684462, 166.97118, 234.2],
  [-11.684534, 166.971213, 234.4],
  [-11.6846, 166.971223, 234.8],
  [-11.684663, 166.971277, 235.2],
  [-11.684718, 166.97132, 235.6],
  [-11.684763, 166.971363, 236],
  [-11.684798, 166.971427, 236.4],
  [-11.684826, 166.971481, 236.8],
  [-11.684848, 166.971545, 237.2],
  [-11.684863, 166.97161, 237.8],
  [-11.684875, 166.971663, 238.2],
  [-11.684885, 166.971728, 238.6],
  [-11.684893, 166.971771, 239],
  [-11.6849, 166.971835, 239.4],
  [-11.684905, 166.971878, 239.8],
  [-11.684911, 166.971942, 240.2],
  [-11.684916, 166.971985, 240.6],
  [-11.68492, 166.972049, 240.8],
  [-11.684926, 166.972092, 241.2],
  [-11.68493, 166.972146, 241.6],
  [-11.684934, 166.972189, 242],
  [-11.684939, 166.972253, 242.4],
  [-11.684944, 166.972296, 242.6],
  [-11.684951, 166.972339, 243],
  [-11.684959, 166.972382, 243.4],
  [-11.684967, 166.972436, 243.8],
  [-11.684978, 166.972479, 244],
  [-11.684989, 166.972522, 244.4],
  [-11.685002, 166.972564, 244.8],
  [-11.685015, 166.972618, 245.2],
  [-11.685032, 166.97265, 245.4],
  [-11.685049, 166.972693, 245.8],
  [-11.685065, 166.972736, 246.2],
  [-11.685081, 166.972779, 246.4],
  [-11.685099, 166.972811, 246.8],
  [-11.685115, 166.972854, 247.2],
  [-11.685132, 166.972908, 247.6],
  [-11.685151, 166.972951, 247.8],
  [-11.685168, 166.972983, 248.2],
  [-11.685187, 166.973026, 248.6],
  [-11.685207, 166.973069, 248.8],
  [-11.685226, 166.973101, 249.2],
  [-11.685246, 166.973144, 249.6],
  [-11.685266, 166.973176, 249.8],
  [-11.685288, 166.97323, 250.2],
  [-11.685309, 166.973273, 250.6],
  [-11.685332, 166.973305, 250.8],
  [-11.685355, 166.973348, 251.2],
  [-11.685379, 166.97338, 251.6],
  [-11.685403, 166.973423, 251.8],
  [-11.685428, 166.973455, 252.2],
  [-11.685455, 166.973498, 252.4],
  [-11.685485, 166.97353, 252.8],
  [-11.685514, 166.973562, 253],
  [-11.685545, 166.973605, 253.4],
  [-11.685577, 166.973637, 253.6],
  [-11.685614, 166.97367, 253.8],
  [-11.685652, 166.973702, 254.2],
  [-11.685693, 166.973723, 254.4],
  [-11.685736, 166.973755, 254.6],
  [-11.685783, 166.973788, 254.6],
  [-11.685832, 166.97382, 254.8],
  [-11.685884, 166.973852, 255],
  [-11.685939, 166.973863, 255.2],
  [-11.685997, 166.973895, 255.2],
  [-11.686056, 166.973916, 255.4],
  [-11.686119, 166.973938, 255.6],
  [-11.686181, 166.973959, 255.6],
  [-11.686247, 166.973991, 255.8],
  [-11.686311, 166.974002, 256],
  [-11.686377, 166.974034, 256.2],
  [-11.686441, 166.974066, 256.6],
  [-11.686503, 166.974099, 256.8],
  [-11.686564, 166.97412, 257],
  [-11.686622, 166.974152, 257.4],
  [-11.68668, 166.974206, 257.8],
  [-11.686737, 166.974227, 258],
  [-11.686794, 166.97426, 258.4],
  [-11.686851, 166.974292, 258.6],
  [-11.686908, 166.974324, 259],
  [-11.686967, 166.974356, 259.4],
  [-11.687026, 166.974367, 259.8],
  [-11.687086, 166.974388, 260],
  [-11.687145, 166.974399, 260.4],
  [-11.687205, 166.974399, 260.8],
  [-11.687264, 166.974399, 261.2],
  [-11.687322, 166.974388, 261.4],
  [-11.687378, 166.974367, 261.8],
  [-11.687433, 166.974356, 262],
  [-11.687487, 166.974324, 262.2],
  [-11.687539, 166.974292, 262.6],
  [-11.68759, 166.97426, 262.8],
  [-11.687641, 166.974227, 263],
  [-11.687692, 166.974206, 263.2],
  [-11.687742, 166.974152, 263.4],
  [-11.687793, 166.97412, 263.8],
  [-11.687842, 166.974077, 264],
  [-11.687892, 166.974045, 264.2],
  [-11.687942, 166.974002, 264.4],
  [-11.687991, 166.97397, 264.6],
  [-11.688042, 166.973927, 265],
  [-11.688092, 166.973884, 265.2],
  [-11.688142, 166.97383, 265.4],
  [-11.688191, 166.973809, 265.6],
  [-11.688242, 166.973755, 265.8],
  [-11.688292, 166.973712, 266.2],
  [-11.688343, 166.97367, 266.4],
  [-11.688392, 166.973627, 266.6],
  [-11.688445, 166.973594, 267],
  [-11.688496, 166.973541, 267.2],
  [-11.688545, 166.973498, 267.4],
  [-11.688592, 166.973455, 267.8],
  [-11.688639, 166.973412, 268],
  [-11.688685, 166.973358, 268.2],
  [-11.688732, 166.973315, 268.6],
  [-11.688776, 166.973273, 268.8],
  [-11.688821, 166.973208, 269],
  [-11.688867, 166.973165, 269.2],
  [-11.688913, 166.973122, 269.6],
  [-11.688957, 166.973069, 269.8],
  [-11.689004, 166.973026, 270],
  [-11.689053, 166.972983, 270.4],
  [-11.689102, 166.97294, 270.6],
  [-11.689151, 166.972886, 270.8],
  [-11.689202, 166.972854, 271.2],
  [-11.689256, 166.972811, 271.4],
  [-11.689311, 166.972779, 271.6],
  [-11.689366, 166.972736, 272],
  [-11.689422, 166.972704, 272.2],
  [-11.68948, 166.972672, 272.4],
  [-11.689538, 166.97265, 272.6],
  [-11.689597, 166.972618, 273],
  [-11.689656, 166.972586, 273.2],
  [-11.689713, 166.972554, 273.4],
  [-11.689772, 166.972522, 273.6],
  [-11.689833, 166.972489, 274],
  [-11.68989, 166.972457, 274.2],
  [-11.689948, 166.972414, 274.4],
  [-11.690004, 166.972382, 274.8],
  [-11.690059, 166.97235, 275],
  [-11.690113, 166.972307, 275.4],
  [-11.690163, 166.972264, 275.6],
  [-11.690205, 166.972221, 276],
  [-11.690242, 166.972167, 276.4],
  [-11.690267, 166.972114, 276.8],
  [-11.690281, 166.972049, 277.2],
  [-11.690287, 166.971985, 277.6],
  [-11.690289, 166.971931, 278],
  [-11.690287, 166.971878, 278.2],
  [-11.690282, 166.971824, 278.6],
  [-11.690275, 166.97176, 279],
  [-11.690269, 166.971717, 279.2],
  [-11.690262, 166.971653, 279.6],
  [-11.69026, 166.971588, 280],
  [-11.690264, 166.971545, 280.4],
  [-11.690274, 166.971481, 280.8],
  [-11.690294, 166.971438, 281.2],
  [-11.690322, 166.971395, 281.6],
  [-11.690357, 166.971363, 282],
  [-11.690397, 166.971331, 282.2],
  [-11.69044, 166.97132, 282.6],
  [-11.690483, 166.971288, 283],
  [-11.690529, 166.971277, 283.4],
  [-11.690573, 166.971256, 283.8],
  [-11.690619, 166.971256, 284],
  [-11.690664, 166.971245, 284.4],
  [-11.69071, 166.971245, 284.8],
  [-11.690755, 166.971223, 285],
  [-11.690801, 166.971223, 285.4],
  [-11.690845, 166.971213, 285.8],
  [-11.690892, 166.971213, 286],
  [-11.690938, 166.971191, 286.4],
  [-11.690983, 166.97118, 286.8],
  [-11.691028, 166.97118, 287],
  [-11.691072, 166.97117, 287.4],
  [-11.691115, 166.971148, 287.8],
  [-11.691156, 166.971138, 288.2],
  [-11.691195, 166.971116, 288.6],
  [-11.691231, 166.971084, 289],
  [-11.691265, 166.971062, 289.2],
  [-11.691297, 166.97103, 289.6],
  [-11.691327, 166.970998, 290],
  [-11.691353, 166.970966, 290.4],
  [-11.69138, 166.970934, 290.8],
  [-11.691403, 166.970901, 291],
  [-11.691426, 166.97088, 291.4],
  [-11.69145, 166.970826, 291.8],
  [-11.691471, 166.970794, 292],
  [-11.691493, 166.970751, 292.4],
  [-11.691516, 166.970719, 292.6],
  [-11.691537, 166.970687, 293],
  [-11.691561, 166.970644, 293.2],
  [-11.691585, 166.970612, 293.6],
  [-11.691612, 166.970569, 293.8],
  [-11.691642, 166.970537, 294.2],
  [-11.691671, 166.970505, 294.4],
  [-11.691703, 166.970462, 294.8],
  [-11.691737, 166.970429, 295],
  [-11.691769, 166.970397, 295.4],
  [-11.691803, 166.970376, 295.6],
  [-11.691838, 166.970344, 296],
  [-11.691872, 166.97029, 296.2],
  [-11.691905, 166.970268, 296.6],
  [-11.691938, 166.970215, 296.8],
  [-11.691968, 166.970193, 297.2],
  [-11.691997, 166.97014, 297.6],
  [-11.692024, 166.970097, 297.8],
  [-11.692047, 166.970054, 298.2],
  [-11.692067, 166.97, 298.4],
  [-11.69208, 166.969957, 298.8],
  [-11.69209, 166.969914, 299.2],
  [-11.692094, 166.969872, 299.4],
  [-11.692091, 166.969807, 299.6],
  [-11.692086, 166.969764, 300],
  [-11.692078, 166.969711, 300.2],
  [-11.692067, 166.969657, 300.4],
  [-11.692054, 166.969603, 300.6],
  [-11.69204, 166.96955, 301],
  [-11.692025, 166.969507, 301.2],
  [-11.692011, 166.969442, 301.4],
  [-11.691996, 166.969378, 301.6],
  [-11.691984, 166.969335, 302],
  [-11.691974, 166.969271, 302.2],
  [-11.691968, 166.969217, 302.6],
  [-11.691965, 166.969153, 302.8],
  [-11.691966, 166.96911, 303.2],
  [-11.691972, 166.969045, 303.4],
  [-11.691982, 166.968981, 303.8],
  [-11.691997, 166.968917, 304],
  [-11.692017, 166.968874, 304.4],
  [-11.692043, 166.968831, 304.8],
  [-11.69207, 166.968766, 305.2],
  [-11.6921, 166.968724, 305.4],
  [-11.692131, 166.968681, 305.8],
  [-11.692163, 166.968648, 306.2],
  [-11.692197, 166.968606, 306.4],
  [-11.692232, 166.968552, 306.8],
  [-11.692267, 166.968509, 307.2],
  [-11.692302, 166.968477, 307.4],
  [-11.692338, 166.968434, 307.8],
  [-11.692374, 166.968402, 308],
  [-11.69241, 166.968359, 308.4],
  [-11.692446, 166.968327, 308.8],
  [-11.692481, 166.968284, 309],
  [-11.692518, 166.968251, 309.4],
  [-11.692552, 166.968209, 309.6],
  [-11.692589, 166.968176, 310],
  [-11.692623, 166.968133, 310.4],
  [-11.692658, 166.96808, 310.6],
  [-11.692691, 166.968048, 311],
  [-11.692724, 166.968005, 311.4],
  [-11.692755, 166.967973, 311.6],
  [-11.692786, 166.96793, 312],
  [-11.692815, 166.967887, 312.4],
  [-11.692842, 166.967844, 312.6],
  [-11.692868, 166.967812, 313],
  [-11.69289, 166.967758, 313.4],
  [-11.69291, 166.967715, 313.6],
  [-11.692927, 166.967672, 314],
  [-11.692941, 166.967629, 314.4],
  [-11.692953, 166.967565, 314.8],
  [-11.692964, 166.967522, 315.2],
  [-11.692972, 166.967468, 315.4],
  [-11.692979, 166.967425, 315.8],
  [-11.692984, 166.967382, 316.2],
  [-11.692988, 166.96734, 316.6],
];

export default stream;
