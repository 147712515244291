import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.760771, 165.846316, 135.6],
  [-10.760633, 165.84623, 135.4],
  [-10.760482, 165.846155, 135.4],
  [-10.760315, 165.846155, 135.4],
  [-10.760168, 165.84623, 135.2],
  [-10.760032, 165.846337, 135.2],
  [-10.759903, 165.846444, 135.2],
  [-10.759791, 165.846519, 135.2],
  [-10.7597, 165.846605, 135.2],
  [-10.759628, 165.84667, 135.2],
  [-10.75957, 165.846713, 135.2],
  [-10.759536, 165.846756, 135.2],
  [-10.759522, 165.846809, 135.4],
  [-10.759517, 165.84682, 135.4],
  [-10.759515, 165.84682, 135.4],
  [-10.759511, 165.846852, 135.4],
  [-10.759509, 165.846863, 135.4],
  [-10.759512, 165.846895, 135.6],
  [-10.75952, 165.846938, 135.6],
  [-10.759531, 165.84697, 135.6],
  [-10.759544, 165.847002, 135.8],
  [-10.75956, 165.847045, 135.8],
  [-10.759578, 165.847077, 136],
  [-10.759597, 165.847131, 136],
  [-10.759614, 165.847174, 136.2],
  [-10.759626, 165.847206, 136.2],
  [-10.759631, 165.847238, 136.4],
  [-10.759633, 165.847249, 136.4],
  [-10.759633, 165.84726, 136.4],
  [-10.759633, 165.847281, 136.4],
  [-10.759631, 165.847313, 136.6],
  [-10.75963, 165.847313, 136.6],
  [-10.759626, 165.847324, 136.6],
  [-10.759621, 165.847356, 136.6],
  [-10.75961, 165.847389, 136.6],
  [-10.759595, 165.847421, 136.6],
  [-10.759575, 165.847442, 136.6],
  [-10.75955, 165.847474, 136.8],
  [-10.759521, 165.847507, 136.8],
  [-10.759488, 165.847539, 136.8],
  [-10.759453, 165.847582, 136.8],
  [-10.759414, 165.847614, 136.8],
  [-10.759373, 165.847657, 136.8],
  [-10.75933, 165.847689, 136.8],
  [-10.759284, 165.847721, 136.8],
  [-10.759237, 165.847764, 136.8],
  [-10.759187, 165.847796, 136.8],
  [-10.759136, 165.847839, 136.8],
  [-10.759083, 165.847893, 136.8],
  [-10.759028, 165.847925, 136.8],
  [-10.758971, 165.847968, 136.6],
  [-10.758913, 165.848, 136.6],
  [-10.758855, 165.848043, 136.6],
  [-10.758794, 165.848075, 136.6],
  [-10.758731, 165.848118, 136.6],
  [-10.758667, 165.84815, 136.6],
  [-10.758599, 165.848182, 136.6],
  [-10.758527, 165.848215, 136.6],
  [-10.758452, 165.848225, 136.6],
  [-10.758377, 165.848215, 136.6],
  [-10.758303, 165.848182, 136.6],
  [-10.758232, 165.84815, 136.6],
  [-10.758169, 165.848107, 136.6],
  [-10.758117, 165.848043, 136.6],
  [-10.75808, 165.848, 136.6],
  [-10.758056, 165.847968, 136.6],
  [-10.758043, 165.847936, 136.6],
  [-10.75804, 165.847936, 136.6],
  [-10.758033, 165.847925, 136.6],
  [-10.758021, 165.847903, 136.6],
  [-10.758004, 165.847871, 136.6],
  [-10.757982, 165.847861, 136.6],
  [-10.757957, 165.847828, 136.6],
  [-10.757927, 165.847796, 136.6],
  [-10.757896, 165.847753, 136.6],
  [-10.757862, 165.847721, 136.6],
  [-10.757827, 165.847689, 136.6],
  [-10.757793, 165.847646, 136.6],
  [-10.757756, 165.847603, 136.6],
  [-10.757715, 165.847549, 136.6],
  [-10.75767, 165.847528, 136.6],
  [-10.757616, 165.847496, 136.6],
  [-10.757553, 165.847474, 136.6],
  [-10.757494, 165.847507, 136.6],
  [-10.75744, 165.847549, 136.4],
  [-10.757385, 165.847603, 136.4],
  [-10.757328, 165.847646, 136.4],
  [-10.757272, 165.847689, 136.2],
  [-10.757215, 165.847732, 136.2],
  [-10.757158, 165.847796, 136.2],
  [-10.757111, 165.847861, 136.2],
];

export default stream;
