import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.693837, 166.966771, 318],
  [-11.693747, 166.966771, 318],
  [-11.693655, 166.966771, 318],
  [-11.693561, 166.966771, 318],
  [-11.693467, 166.966771, 318],
];

export default stream;

