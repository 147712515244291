import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.662258, 166.983143, 12.8],
  [-11.662294, 166.982929, 12.8],
  [-11.662319, 166.982714, 12.6],
  [-11.662325, 166.982499, 12.6],
  [-11.662304, 166.982285, 12.8],
  [-11.662243, 166.98207, 13],
  [-11.662135, 166.981888, 13.4],
  [-11.661983, 166.981738, 14],
  [-11.661814, 166.98162, 14.8],
  [-11.661637, 166.981512, 15.4],
  [-11.661458, 166.981405, 16.2],
  [-11.661279, 166.981298, 16.8],
  [-11.661102, 166.98119, 17.6],
  [-11.660933, 166.981083, 18.2],
  [-11.660762, 166.980965, 18.8],
  [-11.660583, 166.980869, 19.4],
  [-11.660406, 166.980761, 20],
  [-11.660232, 166.980675, 20.4],
  [-11.660064, 166.980547, 21],
  [-11.65991, 166.980429, 21.6],
  [-11.659789, 166.980257, 22.2],
  [-11.659726, 166.980064, 22.6],
  [-11.659702, 166.97986, 23],
  [-11.659689, 166.979667, 23.4],
  [-11.659616, 166.979463, 23.8],
  [-11.65945, 166.979356, 24],
  [-11.659254, 166.979302, 24.2],
  [-11.659072, 166.979206, 24.6],
  [-11.65892, 166.979066, 24.8],
  [-11.658782, 166.978916, 25.2],
  [-11.658669, 166.978744, 25.8],
  [-11.658614, 166.978551, 26],
  [-11.658636, 166.978337, 26.2],
  [-11.6587, 166.978143, 26.4],
  [-11.658786, 166.97795, 26.6],
  [-11.658888, 166.977768, 26.6],
  [-11.659029, 166.977618, 27],
  [-11.659227, 166.977553, 27.2],
  [-11.659396, 166.977457, 27.6],
  [-11.659449, 166.977253, 27.8],
  [-11.659454, 166.977038, 27.8],
  [-11.65949, 166.976824, 28],
];

export default stream;
