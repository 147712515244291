import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [40.791703, -73.960841, 62.6],
  [40.791695, -73.960605, 62.6],
  [40.791644, -73.960385, 62.6],
  [40.791504, -73.960229, 62.4],
  [40.79133, -73.960159, 63],
  [40.791147, -73.960133, 63.6],
  [40.790968, -73.960106, 64.4],
  [40.790788, -73.960095, 65.2],
  [40.790614, -73.960068, 65.8],
  [40.790442, -73.960041, 66.4],
  [40.790265, -73.959988, 66.6],
  [40.790101, -73.959897, 66],
  [40.789956, -73.959757, 64.4],
  [40.789828, -73.959564, 62],
  [40.789675, -73.959398, 59.6],
  [40.789484, -73.959285, 58.2],
  [40.789281, -73.959215, 57],
  [40.789074, -73.959215, 56.2],
  [40.788873, -73.95929, 56.2],
  [40.788698, -73.959162, 56.2],
  [40.788567, -73.958947, 56.2],
];

export default stream;
