import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.791097, 165.840715, 65.8],
  [-10.791006, 165.84079, 65.8],
  [-10.790915, 165.840865, 65.6],
  [-10.790824, 165.840951, 65.4],
  [-10.79073, 165.841026, 65.2],
  [-10.790633, 165.841101, 65.2],
  [-10.790534, 165.841177, 65.4],
  [-10.790437, 165.841241, 65.4],
  [-10.790342, 165.841316, 65.4],
  [-10.790247, 165.841391, 65.6],
  [-10.79015, 165.841466, 65.4],
  [-10.790049, 165.841531, 65.4],
  [-10.78995, 165.841606, 65.2],
  [-10.789856, 165.841681, 65.2],
  [-10.789769, 165.841767, 64.8],
  [-10.789685, 165.841863, 64.6],
  [-10.789594, 165.841938, 64.4],
  [-10.789497, 165.842035, 64],
  [-10.789395, 165.84211, 63.6],
  [-10.789294, 165.842185, 63.2],
  [-10.789193, 165.842271, 63],
  [-10.7891, 165.842367, 62.6],
  [-10.789009, 165.842475, 62.4],
  [-10.788912, 165.842561, 62],
  [-10.78881, 165.842657, 61.8],
  [-10.788721, 165.842743, 61.8],
  [-10.788642, 165.84285, 62],
  [-10.78857, 165.842957, 62.2],
  [-10.788498, 165.843065, 62.6],
  [-10.788413, 165.843161, 62.8],
  [-10.788299, 165.843204, 63],
  [-10.788179, 165.843236, 63.4],
  [-10.788061, 165.843236, 63.6],
  [-10.787941, 165.843247, 63.8],
  [-10.787826, 165.843247, 64.2],
  [-10.787709, 165.843269, 64.4],
  [-10.787598, 165.843269, 64.6],
  [-10.787485, 165.843269, 65],
  [-10.787379, 165.843215, 65.2],
  [-10.787292, 165.843161, 65.4],
  [-10.787212, 165.843086, 65.4],
  [-10.787135, 165.84299, 65.4],
  [-10.787051, 165.842925, 65.4],
  [-10.786947, 165.842882, 65.4],
  [-10.786836, 165.842872, 65.4],
  [-10.786721, 165.842872, 65.2],
  [-10.786603, 165.842872, 65.2],
  [-10.786487, 165.842882, 65.4],
  [-10.786372, 165.842904, 65.4],
  [-10.786247, 165.842904, 65.6],
  [-10.786143, 165.842915, 65.6],
  [-10.786027, 165.842925, 65.6],
  [-10.785913, 165.842947, 65.8],
  [-10.785797, 165.842957, 66],
  [-10.785683, 165.842957, 66],
  [-10.785569, 165.842979, 66.2],
  [-10.785457, 165.84299, 66.4],
  [-10.785343, 165.84299, 66.4],
  [-10.78523, 165.843011, 66.6],
  [-10.785116, 165.843011, 66.6],
  [-10.785002, 165.843011, 66.8],
  [-10.78489, 165.843022, 67],
  [-10.784777, 165.843022, 67],
  [-10.784674, 165.842979, 67],
  [-10.784623, 165.842872, 67],
  [-10.78461, 165.842764, 66.8],
  [-10.784615, 165.842636, 66.8],
  [-10.784678, 165.84255, 66.6],
  [-10.784793, 165.842518, 66.6],
  [-10.784903, 165.842485, 66.6],
  [-10.784946, 165.842378, 66.6],
  [-10.784944, 165.84226, 66.6],
  [-10.784895, 165.842153, 66.6],
  [-10.784783, 165.842121, 66.6],
  [-10.784664, 165.842121, 66.6],
  [-10.784546, 165.842131, 66.6],
  [-10.784462, 165.842046, 66.6],
  [-10.784375, 165.84197, 66.6],
  [-10.784262, 165.841981, 66.6],
  [-10.784208, 165.842088, 66.6],
  [-10.784255, 165.842196, 66.6],
  [-10.784354, 165.84226, 66.6],
  [-10.784392, 165.842367, 66.6],
  [-10.78438, 165.842485, 66.6],
  [-10.78428, 165.84255, 66.6],
  [-10.784187, 165.842625, 66.6],
  [-10.784172, 165.842743, 66.4],
  [-10.784175, 165.842872, 66.4],
  [-10.784106, 165.842957, 66.4],
  [-10.783991, 165.84299, 66.4],
  [-10.783869, 165.843011, 66.4],
  [-10.783749, 165.84299, 66.4],
  [-10.783627, 165.84299, 66.4],
  [-10.783507, 165.84299, 66.4],
  [-10.783385, 165.84299, 66.4],
];

export default stream;
