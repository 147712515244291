import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [51.50698, -0.128206, 24.4],
  [51.506835, -0.128408, 24.4],
  [51.506712, -0.128673, 24.4],
  [51.50661, -0.128942, 24.4],
  [51.506508, -0.129206, 24.2],
  [51.506395, -0.129476, 24],
  [51.506283, -0.129749, 23.8],
  [51.506175, -0.130012, 23.2],
  [51.506057, -0.13029, 22.4],
  [51.505945, -0.130569, 22],
  [51.505832, -0.130839, 21.6],
  [51.505714, -0.131112, 21.2],
  [51.505596, -0.131393, 20.8],
  [51.505478, -0.131668, 20.4],
  [51.505371, -0.131933, 20.2],
  [51.505253, -0.132214, 19.8],
  [51.505135, -0.132491, 19.4],
  [51.505017, -0.132781, 19.2],
  [51.504904, -0.133057, 18.8],
  [51.504786, -0.133335, 18.4],
  [51.504673, -0.133609, 18.2],
  [51.504561, -0.133889, 17.8],
  [51.504443, -0.134158, 17.6],
  [51.504335, -0.134432, 17.2],
  [51.504217, -0.134718, 17],
  [51.50426, -0.13461, 17],
  [51.504142, -0.134887, 16.8],
  [51.50403, -0.135163, 16.6],
  [51.503917, -0.135437, 16.2],
  [51.503804, -0.135716, 16],
  [51.503692, -0.135997, 15.8],
  [51.503574, -0.136273, 15.4],
  [51.503466, -0.136545, 15.2],
  [51.503354, -0.136822, 15],
  [51.503236, -0.137107, 14.6],
  [51.503123, -0.137378, 14.4],
  [51.503016, -0.137655, 14.2],
  [51.502898, -0.13794, 14],
  [51.502785, -0.138217, 13.8],
  [51.502678, -0.138491, 13.4],
  [51.502565, -0.138768, 13.2],
  [51.502452, -0.139041, 13],
  [51.50234, -0.139318, 12.8],
  [51.502227, -0.139604, 12.6],
  [51.502174, -0.139909, 12.4],
  [51.502238, -0.140228, 12.4],
  [51.502292, -0.140542, 12.6],
  [51.502292, -0.140862, 12.4],
  [51.502286, -0.141195, 12.4],
  [51.502286, -0.141518, 12.4],
  [51.502297, -0.141837, 12.4],
  [51.502313, -0.14216, 12],
  [51.502318, -0.14249, 11.8],
  [51.502334, -0.14282, 11.6],
  [51.502345, -0.143139, 11.8],
  [51.502361, -0.143459, 11.8],
  [51.502372, -0.143782, 12],
  [51.502388, -0.1441, 12.2],
  [51.502399, -0.144427, 12.4],
  [51.502415, -0.144742, 12.6],
  [51.502431, -0.145057, 13],
  [51.502447, -0.145373, 13.2],
  [51.502458, -0.145694, 13.4],
  [51.502474, -0.146, 13.6],
  [51.50249, -0.146314, 13.8],
  [51.502501, -0.146634, 14],
  [51.502512, -0.146936, 14.2],
  [51.502522, -0.147257, 14.4],
  [51.502533, -0.147565, 14.6],
  [51.502544, -0.147876, 14.8],
  [51.502554, -0.14819, 15],
  [51.502565, -0.148494, 15.2],
  [51.502576, -0.148804, 15.4],
  [51.502587, -0.149118, 15.6],
  [51.502592, -0.149426, 15.8],
  [51.502554, -0.149667, 16],
  [51.502463, -0.149674, 15.8],
  [51.502442, -0.149521, 15.6],
  [51.502436, -0.149356, 15.6],
  [51.502436, -0.149175, 15.6],
  [51.502431, -0.14897, 15.4],
  [51.502431, -0.148762, 15.4],
  [51.502436, -0.148524, 15.2],
  [51.502436, -0.148273, 15.2],
  [51.502436, -0.14802, 15],
  [51.502426, -0.147749, 14.8],
  [51.502415, -0.147469, 14.6],
  [51.50241, -0.147184, 14.4],
  [51.502399, -0.1469, 14.2],
  [51.502383, -0.146618, 14],
  [51.502372, -0.146303, 13.8],
  [51.502361, -0.14601, 13.6],
  [51.502345, -0.145699, 13.4],
  [51.502334, -0.145393, 13.2],
  [51.502318, -0.145086, 13],
  [51.502308, -0.144766, 12.6],
  [51.502292, -0.144443, 12.4],
  [51.502275, -0.144125, 12.2],
  [51.502265, -0.143806, 12],
  [51.502249, -0.143491, 11.8],
  [51.502233, -0.143164, 11.8],
  [51.502222, -0.142848, 11.6],
  [51.502206, -0.142532, 11.8],
  [51.502195, -0.142194, 12],
  [51.50219, -0.141891, 12.4],
  [51.502174, -0.141575, 12.4],
  [51.502168, -0.141254, 12.4],
  [51.502174, -0.140939, 12.4],
  [51.502184, -0.140612, 12.4],
  [51.502136, -0.140309, 12.4],
  [51.502002, -0.140093, 12],
  [51.501803, -0.140027, 12],
  [51.501605, -0.140054, 12.2],
  [51.501406, -0.140022, 12.2],
  [51.50124, -0.139846, 12.2],
  [51.501079, -0.139668, 12.4],
  [51.500908, -0.139498, 12.6],
  [51.500741, -0.139329, 12.6],
  [51.50058, -0.13915, 12.8],
  [51.500457, -0.13891, 12.6],
  [51.500468, -0.13859, 12.4],
  [51.500511, -0.138282, 12.4],
  [51.500537, -0.13796, 12.4],
  [51.50057, -0.137642, 12.4],
  [51.500602, -0.137322, 12.2],
  [51.500629, -0.137007, 12.2],
  [51.500661, -0.136701, 12.2],
  [51.500693, -0.136372, 12],
  [51.500725, -0.136065, 12],
  [51.500757, -0.135743, 12],
  [51.50079, -0.135417, 11.8],
  [51.500822, -0.135115, 11.8],
  [51.500854, -0.134793, 11.8],
  [51.500881, -0.134468, 11.8],
  [51.500913, -0.134153, 11.8],
  [51.50094, -0.133843, 12],
  [51.500972, -0.133521, 12.4],
  [51.501009, -0.133204, 12.8],
  [51.501036, -0.132903, 13.2],
  [51.501074, -0.132587, 13.8],
  [51.501106, -0.13229, 14.4],
  [51.501133, -0.131984, 15],
  [51.50117, -0.131689, 15.4],
  [51.501197, -0.131383, 15.6],
  [51.501229, -0.131076, 15.6],
  [51.501256, -0.13078, 15.6],
  [51.501283, -0.130472, 15.8],
  [51.501294, -0.13015, 15.8],
  [51.501283, -0.129842, 15.6],
  [51.501245, -0.129385, 15.6],
  [51.501224, -0.129081, 15.8],
  [51.501208, -0.128881, 15.8],
  [51.501192, -0.128588, 15.8],
  [51.50117, -0.128292, 15.8],
  [51.501144, -0.12796, 15.8],
  [51.501117, -0.127643, 15.8],
  [51.50109, -0.127348, 15.8],
  [51.501068, -0.127028, 15.8],
  [51.501068, -0.12671, 16],
  [51.501079, -0.126404, 16.2],
];

export default stream;
