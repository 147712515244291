import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.647164, 166.939125, 2.4],
  [-11.647106, 166.93901, 2.4],
  [-11.647035, 166.9389, 2.4],
  [-11.646964, 166.938789, 2.4],
  [-11.646897, 166.938678, 2.4],
  [-11.646831, 166.938569, 2.4],
  [-11.646765, 166.938462, 2.4],
  [-11.646698, 166.938356, 2.4],
  [-11.646632, 166.938251, 2.4],
  [-11.646568, 166.938146, 2.4],
  [-11.646506, 166.938042, 2.4],
  [-11.646443, 166.937939, 2.4],
  [-11.646373, 166.937837, 2.4],
  [-11.6463, 166.937744, 2.4],
  [-11.646213, 166.937661, 2.4],
  [-11.646114, 166.937595, 2.4],
  [-11.646012, 166.937534, 2.4],
  [-11.645918, 166.937464, 2.4],
  [-11.645839, 166.937376, 2.4],
  [-11.645779, 166.937275, 2.2],
  [-11.645736, 166.937165, 2.2],
  [-11.645706, 166.937052, 2.2],
  [-11.64568, 166.936935, 2.2],
  [-11.645656, 166.936821, 2.2],
  [-11.645631, 166.936708, 2.2],
  [-11.6456, 166.936597, 2.2],
  [-11.64556, 166.936487, 2.2],
  [-11.645509, 166.936383, 2.2],
  [-11.645444, 166.936287, 2.2],
  [-11.645365, 166.936203, 2.2],
  [-11.64528, 166.936126, 2.2],
  [-11.64519, 166.936055, 2.2],
  [-11.645098, 166.935988, 2.2],
  [-11.645003, 166.935926, 2.2],
  [-11.644904, 166.935866, 2.2],
  [-11.644805, 166.935811, 2.2],
  [-11.644701, 166.935758, 2.2],
  [-11.644601, 166.93571, 2.2],
  [-11.644495, 166.935665, 2.2],
  [-11.644388, 166.935626, 2.2],
  [-11.644279, 166.935596, 2.2],
  [-11.644169, 166.935581, 2.2],
  [-11.644056, 166.93558, 2.2],
  [-11.643943, 166.93559, 2.2],
  [-11.643832, 166.935608, 2.2],
  [-11.643721, 166.93563, 2.2],
  [-11.643611, 166.935654, 2.2],
  [-11.6435, 166.935676, 2.2],
  [-11.643387, 166.935687, 2.2],
  [-11.643276, 166.9357, 2.2],
  [-11.643165, 166.935702, 2.2],
  [-11.643057, 166.935665, 2.2],
  [-11.642957, 166.935616, 2.2],
  [-11.642848, 166.935615, 2.4],
  [-11.642781, 166.9357, 2.8],
  [-11.642827, 166.935791, 3.6],
  [-11.642915, 166.935847, 4.2],
  [-11.643003, 166.93589, 4.8],
  [-11.64309, 166.935927, 5.4],
  [-11.643177, 166.935956, 5.8],
  [-11.643266, 166.935974, 6.4],
  [-11.643353, 166.935985, 6.8],
  [-11.643438, 166.935993, 7.2],
  [-11.643522, 166.936001, 7.6],
  [-11.643604, 166.93601, 8],
  [-11.643683, 166.936025, 8.4],
];

export default stream;
