import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.803581, 165.844181, 65.6],
  [-10.803546, 165.844288, 65.6],
  [-10.803512, 165.844406, 65.6],
  [-10.803479, 165.844535, 65.6],
  [-10.803449, 165.844642, 65.6],
  [-10.803418, 165.84476, 65.6],
  [-10.803391, 165.844857, 65.4],
  [-10.803369, 165.844932, 65.4],
  [-10.803353, 165.844985, 65.4],
  [-10.80334, 165.845017, 65.4],
  [-10.803322, 165.84505, 65.4],
  [-10.803321, 165.84505, 65.4],
  [-10.803318, 165.845071, 65.4],
  [-10.803314, 165.845114, 65.4],
  [-10.803308, 165.845157, 65.4],
  [-10.8033, 165.845221, 65.4],
  [-10.803287, 165.845275, 65.4],
  [-10.803258, 165.845339, 65.4],
  [-10.80321, 165.845404, 65.6],
  [-10.803149, 165.845447, 65.6],
  [-10.803078, 165.84549, 65.6],
  [-10.803, 165.845522, 65.6],
  [-10.80291, 165.845554, 65.6],
  [-10.802847, 165.845618, 65.6],
  [-10.802783, 165.845672, 65.6],
  [-10.802709, 165.845736, 65.6],
  [-10.802618, 165.845768, 65.6],
  [-10.802518, 165.845801, 65.6],
  [-10.802426, 165.845833, 65.6],
  [-10.80235, 165.845908, 65.6],
  [-10.802282, 165.845983, 65.6],
  [-10.802207, 165.846058, 65.6],
  [-10.802115, 165.846123, 65.6],
  [-10.802015, 165.846165, 65.6],
  [-10.801916, 165.846198, 65.6],
  [-10.801814, 165.846241, 65.6],
  [-10.80172, 165.846305, 65.6],
  [-10.801638, 165.84638, 65.6],
  [-10.801577, 165.846466, 65.6],
  [-10.801521, 165.846573, 65.6],
  [-10.801464, 165.84667, 65.6],
  [-10.801405, 165.846777, 65.6],
  [-10.801344, 165.846863, 65.6],
  [-10.801283, 165.846959, 65.6],
  [-10.801219, 165.847067, 65.6],
  [-10.801159, 165.847152, 65.6],
  [-10.801098, 165.84726, 65.6],
  [-10.80104, 165.847356, 65.6],
  [-10.800981, 165.847464, 65.6],
  [-10.80092, 165.847571, 65.6],
  [-10.800858, 165.847657, 65.6],
  [-10.800793, 165.847764, 65.6],
  [-10.800723, 165.847871, 65.6],
  [-10.80067, 165.847968, 65.6],
  [-10.800614, 165.848075, 65.6],
  [-10.800558, 165.848182, 65.6],
  [-10.800501, 165.84829, 65.6],
  [-10.800445, 165.848397, 65.6],
  [-10.800387, 165.848483, 65.6],
  [-10.80033, 165.84859, 65.6],
  [-10.800255, 165.84873, 65.6],
  [-10.800201, 165.848794, 65.6],
  [-10.800102, 165.848848, 65.8],
  [-10.799989, 165.84888, 66],
  [-10.799878, 165.848912, 66.2],
  [-10.799765, 165.848944, 66.6],
  [-10.799655, 165.848955, 66.8],
  [-10.799546, 165.848987, 67],
  [-10.799438, 165.848998, 67.2],
  [-10.799329, 165.849019, 67.6],
  [-10.799221, 165.849019, 67.8],
  [-10.799112, 165.849019, 68],
  [-10.799004, 165.849019, 68.2],
  [-10.7989, 165.849051, 68.4],
  [-10.798801, 165.849084, 68.6],
  [-10.798711, 165.849137, 68.8],
  [-10.798624, 165.849202, 69],
  [-10.79853, 165.849245, 69.4],
  [-10.798431, 165.849298, 69.6],
  [-10.798329, 165.84932, 69.8],
  [-10.798227, 165.849341, 70],
  [-10.798123, 165.849352, 70.2],
  [-10.798019, 165.849373, 70.4],
  [-10.797916, 165.849384, 70.8],
  [-10.797812, 165.849384, 71],
  [-10.797708, 165.849395, 71.2],
  [-10.797606, 165.849416, 71.2],
  [-10.797502, 165.849416, 71.4],
  [-10.797397, 165.849416, 71.8],
  [-10.797285, 165.849416, 72],
  [-10.797191, 165.849395, 72],
  [-10.797088, 165.849373, 72],
  [-10.796989, 165.84932, 71.8],
  [-10.796892, 165.849277, 71.6],
  [-10.796774, 165.849202, 71.2],
  [-10.796703, 165.849159, 70.8],
  [-10.796617, 165.849062, 70.2],
  [-10.796535, 165.848976, 69.6],
  [-10.796459, 165.848869, 69],
  [-10.796394, 165.848762, 68.4],
  [-10.796337, 165.848633, 68],
  [-10.796288, 165.848515, 67.8],
  [-10.796243, 165.848397, 67.8],
  [-10.796206, 165.848258, 67.6],
  [-10.796181, 165.848129, 67.4],
  [-10.796168, 165.848, 67.4],
  [-10.796154, 165.847861, 67.2],
  [-10.796134, 165.847732, 67],
  [-10.796108, 165.847603, 67],
  [-10.796074, 165.847474, 66.8],
  [-10.796039, 165.847335, 66.6],
  [-10.79601, 165.847217, 66.6],
  [-10.795999, 165.847077, 66.4],
  [-10.795989, 165.846938, 66.2],
  [-10.795952, 165.84682, 66.2],
  [-10.795903, 165.846702, 66],
  [-10.795844, 165.846573, 65.8],
  [-10.795787, 165.846455, 65.8],
  [-10.795742, 165.846337, 65.6],
  [-10.7957, 165.846208, 65.6],
  [-10.79567, 165.84609, 65.6],
  [-10.795673, 165.845951, 65.8],
  [-10.79571, 165.845833, 66],
  [-10.795767, 165.845726, 66.2],
  [-10.795831, 165.845618, 66.4],
  [-10.795897, 165.845511, 66.6],
  [-10.795966, 165.845414, 66.8],
  [-10.79604, 165.845329, 67.2],
  [-10.796119, 165.845221, 67.4],
  [-10.79619, 165.845146, 67.6],
  [-10.796263, 165.84505, 68],
  [-10.796339, 165.844975, 68.2],
  [-10.796414, 165.844878, 68.4],
  [-10.796486, 165.844803, 68.8],
  [-10.796553, 165.844717, 69],
  [-10.796608, 165.84462, 69.4],
  [-10.79665, 165.844513, 69.4],
  [-10.796675, 165.844406, 69.2],
  [-10.796685, 165.844288, 69.2],
  [-10.796679, 165.844181, 69],
  [-10.796658, 165.844063, 68.4],
  [-10.796628, 165.843955, 68],
  [-10.796587, 165.843827, 67.4],
  [-10.796549, 165.843709, 66.6],
];

export default stream;
