import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.676671, 166.958306, 373.2],
  [-11.676745, 166.958241, 373.2],
  [-11.676782, 166.958145, 372.6],
  [-11.676777, 166.958038, 371.4],
  [-11.676757, 166.95792, 369.8],
  [-11.67673, 166.957812, 368.2],
  [-11.676695, 166.957673, 366.8],
  [-11.676659, 166.957555, 365.6],
  [-11.676623, 166.957415, 365.2],
  [-11.676591, 166.957276, 365.8],
  [-11.676557, 166.957158, 367.2],
  [-11.676522, 166.95704, 368.8],
  [-11.676491, 166.956943, 370.4],
  [-11.67647, 166.956857, 372],
  [-11.676456, 166.956761, 373.2],
  [-11.676448, 166.956686, 374.4],
  [-11.676446, 166.956621, 375.4],
  [-11.676446, 166.956557, 376.2],
  [-11.676446, 166.956514, 377],
  [-11.676448, 166.956471, 377.6],
  [-11.676451, 166.956428, 378.2],
  [-11.676454, 166.956396, 378.8],
  [-11.676456, 166.956364, 379.2],
  [-11.676459, 166.956332, 379.6],
  [-11.676463, 166.9563, 380],
  [-11.676466, 166.956289, 380.6],
  [-11.67647, 166.956257, 381],
  [-11.676473, 166.956224, 381.4],
  [-11.676477, 166.956214, 381.6],
  [-11.676481, 166.956182, 382],
  [-11.676486, 166.956149, 382.4],
  [-11.676489, 166.956139, 382.8],
  [-11.676494, 166.956106, 383.2],
  [-11.676498, 166.956085, 383.6],
  [-11.676502, 166.956064, 384],
  [-11.676507, 166.956042, 384.4],
  [-11.676511, 166.95601, 384.8],
  [-11.676517, 166.955978, 385.2],
  [-11.676522, 166.955967, 385.6],
  [-11.676526, 166.955935, 385.8],
  [-11.676532, 166.955924, 386.2],
  [-11.676537, 166.955892, 386.6],
  [-11.676542, 166.95587, 387],
  [-11.676548, 166.955849, 387.4],
  [-11.676553, 166.955827, 387.8],
  [-11.676558, 166.955795, 388.2],
  [-11.676565, 166.955785, 388.6],
  [-11.676569, 166.955752, 389],
  [-11.676574, 166.955742, 389.4],
  [-11.676581, 166.955709, 389.8],
  [-11.676588, 166.955677, 390],
  [-11.676593, 166.955667, 390.4],
  [-11.676599, 166.955634, 390.8],
  [-11.676604, 166.955613, 391.2],
  [-11.676611, 166.955581, 391.6],
  [-11.676617, 166.95557, 392],
  [-11.676623, 166.955538, 392.4],
  [-11.676629, 166.955527, 392.8],
  [-11.676636, 166.955495, 393.2],
  [-11.676642, 166.955484, 393.4],
  [-11.676648, 166.955452, 393.8],
  [-11.676655, 166.955431, 394.2],
  [-11.676662, 166.955398, 394.6],
  [-11.676668, 166.955388, 395],
  [-11.676676, 166.955355, 395.4],
  [-11.676683, 166.955323, 395.8],
  [-11.67669, 166.955312, 396.2],
  [-11.676697, 166.95528, 396.6],
  [-11.676705, 166.95527, 396.8],
  [-11.676711, 166.955237, 397.2],
  [-11.676719, 166.955216, 397.6],
  [-11.676726, 166.955184, 398],
  [-11.676735, 166.955173, 398.4],
  [-11.676743, 166.955141, 398.8],
  [-11.676752, 166.95513, 399.2],
  [-11.67676, 166.955098, 399.6],
  [-11.676768, 166.955087, 400],
  [-11.676776, 166.955055, 400.4],
  [-11.676785, 166.955034, 400.6],
  [-11.676793, 166.955001, 401],
  [-11.676802, 166.954991, 401.4],
  [-11.676812, 166.954958, 401.8],
  [-11.676821, 166.954948, 402.2],
  [-11.676831, 166.954926, 402.6],
  [-11.67684, 166.954894, 403],
  [-11.676849, 166.954883, 403.4],
  [-11.67686, 166.954851, 403.8],
  [-11.67687, 166.95484, 404.2],
  [-11.67688, 166.954808, 404.6],
  [-11.676891, 166.954798, 405],
  [-11.676902, 166.954776, 405.4],
  [-11.676912, 166.954744, 405.6],
  [-11.676923, 166.954733, 406],
  [-11.676934, 166.954712, 406.4],
  [-11.676946, 166.95469, 406.8],
  [-11.676958, 166.954669, 407.2],
  [-11.67697, 166.954658, 407.6],
  [-11.676981, 166.954626, 408],
  [-11.676994, 166.954604, 408.4],
  [-11.677006, 166.954594, 408.8],
  [-11.677018, 166.954583, 409.2],
  [-11.67703, 166.954551, 409.6],
  [-11.677044, 166.954529, 410],
  [-11.677056, 166.954519, 410.4],
  [-11.677069, 166.954497, 410.8],
  [-11.677083, 166.954486, 411.2],
  [-11.677096, 166.954454, 411.6],
  [-11.67711, 166.954443, 412],
  [-11.677123, 166.954422, 412.4],
  [-11.677136, 166.954411, 412.8],
  [-11.67715, 166.954401, 413],
  [-11.677163, 166.954379, 413.4],
  [-11.677178, 166.954347, 413.8],
  [-11.677191, 166.954336, 414.2],
  [-11.677205, 166.954315, 414.6],
  [-11.67722, 166.954304, 415],
  [-11.677233, 166.954293, 415.4],
  [-11.677248, 166.954272, 415.8],
  [-11.677261, 166.954261, 416.2],
  [-11.677276, 166.95424, 416.6],
  [-11.677291, 166.954207, 417],
  [-11.677304, 166.954197, 417.4],
  [-11.677319, 166.954186, 417.8],
  [-11.677334, 166.954165, 418.2],
  [-11.677348, 166.954154, 418.6],
  [-11.677363, 166.954132, 419],
  [-11.677376, 166.954122, 419.2],
  [-11.677393, 166.954111, 419.6],
  [-11.677406, 166.954079, 420],
  [-11.677422, 166.954057, 420.4],
  [-11.677435, 166.954046, 420.8],
  [-11.677452, 166.954025, 421.2],
  [-11.677466, 166.954014, 421.6],
  [-11.677481, 166.954004, 422],
  [-11.677496, 166.953982, 422.4],
  [-11.677511, 166.953971, 422.8],
  [-11.677525, 166.953939, 423.2],
  [-11.677541, 166.953918, 423.4],
  [-11.677559, 166.953907, 423.8],
  [-11.677576, 166.953896, 424.2],
  [-11.677595, 166.953875, 424.6],
  [-11.677614, 166.953864, 425],
  [-11.677634, 166.953843, 425.2],
  [-11.677654, 166.953832, 425.6],
  [-11.677674, 166.95381, 426],
  [-11.677694, 166.953789, 426.4],
  [-11.677716, 166.953768, 426.8],
  [-11.677737, 166.953757, 427],
  [-11.67776, 166.953735, 427.4],
  [-11.677781, 166.953725, 427.8],
  [-11.677803, 166.953714, 428.2],
  [-11.677826, 166.953692, 428.6],
  [-11.677847, 166.953682, 429],
  [-11.677869, 166.95366, 429.2],
  [-11.67789, 166.95365, 429.6],
  [-11.677912, 166.953628, 430],
  [-11.677932, 166.953617, 430.4],
  [-11.67795, 166.953585, 430.8],
  [-11.677968, 166.953574, 431.2],
  [-11.677984, 166.953553, 431.6],
  [-11.678, 166.953542, 432],
  [-11.678014, 166.953521, 432.4],
  [-11.678026, 166.953499, 432.8],
  [-11.678036, 166.953478, 433.2],
  [-11.678047, 166.953467, 433.8],
  [-11.678055, 166.953446, 434.2],
  [-11.678062, 166.953424, 434.6],
  [-11.678067, 166.953403, 435],
  [-11.678073, 166.953392, 435.4],
  [-11.678077, 166.953371, 435.8],
  [-11.678078, 166.953338, 436.2],
  [-11.678079, 166.953328, 436.6],
  [-11.678079, 166.953317, 437],
  [-11.678078, 166.953295, 437.4],
  [-11.678077, 166.953263, 437.8],
  [-11.678075, 166.953253, 438.2],
  [-11.678073, 166.953231, 438.6],
  [-11.67807, 166.95321, 439],
  [-11.678066, 166.953188, 439.4],
  [-11.67806, 166.953177, 439.8],
  [-11.678056, 166.953145, 440.2],
  [-11.67805, 166.953124, 440.4],
  [-11.678044, 166.953113, 440.8],
  [-11.678038, 166.953081, 441.2],
  [-11.678031, 166.95307, 441.6],
  [-11.678024, 166.953049, 442],
  [-11.678018, 166.953027, 442.4],
  [-11.678009, 166.953006, 442.8],
  [-11.678001, 166.952995, 443.2],
  [-11.677993, 166.952963, 443.6],
  [-11.677985, 166.952941, 444],
  [-11.677977, 166.95292, 444.4],
  [-11.677968, 166.952899, 444.8],
  [-11.67796, 166.952888, 445.2],
  [-11.67795, 166.952856, 445.4],
  [-11.677941, 166.952834, 445.8],
  [-11.677933, 166.952813, 446.2],
  [-11.677924, 166.952791, 446.6],
  [-11.677914, 166.95278, 447],
  [-11.677905, 166.952748, 447.4],
  [-11.677895, 166.952738, 447.8],
  [-11.677885, 166.952705, 448.2],
  [-11.677875, 166.952684, 448.6],
  [-11.677866, 166.952673, 449],
  [-11.677857, 166.952641, 449.2],
  [-11.677846, 166.95263, 449.6],
  [-11.677836, 166.952598, 450],
  [-11.677827, 166.952577, 450.4],
  [-11.677818, 166.952566, 450.8],
  [-11.677808, 166.952534, 451.2],
  [-11.677799, 166.952523, 451.6],
  [-11.67779, 166.952491, 452],
  [-11.67778, 166.952469, 452.4],
  [-11.677773, 166.952437, 452.6],
  [-11.677764, 166.952426, 453],
  [-11.677756, 166.952394, 453.4],
  [-11.677749, 166.952384, 453.8],
  [-11.677743, 166.952351, 454.2],
  [-11.677737, 166.952319, 454.6],
  [-11.677732, 166.952308, 454.8],
  [-11.677727, 166.952276, 455.2],
  [-11.677721, 166.952244, 455.6],
  [-11.677717, 166.952212, 456],
  [-11.677712, 166.952201, 456.2],
  [-11.677706, 166.952169, 456.6],
  [-11.677704, 166.952137, 457],
  [-11.677698, 166.952105, 457.2],
  [-11.677693, 166.952072, 457.6],
  [-11.677688, 166.952051, 458],
  [-11.677678, 166.952019, 458.4],
  [-11.677667, 166.951997, 458.8],
  [-11.677657, 166.951965, 459.2],
  [-11.677645, 166.951944, 459.6],
  [-11.67763, 166.951922, 460],
  [-11.677615, 166.95189, 460.4],
  [-11.677602, 166.951879, 460.8],
  [-11.677586, 166.951858, 461.2],
  [-11.677571, 166.951847, 461.6],
  [-11.677555, 166.951836, 462],
  [-11.677539, 166.951804, 462.4],
  [-11.677521, 166.951783, 462.8],
  [-11.677504, 166.951772, 463.2],
  [-11.677485, 166.951751, 463.4],
  [-11.677468, 166.95174, 463.8],
  [-11.677449, 166.951729, 464.2],
  [-11.67743, 166.951729, 464.6],
  [-11.677411, 166.951708, 465],
  [-11.677393, 166.951697, 465.4],
  [-11.677372, 166.951675, 465.8],
  [-11.677352, 166.951665, 466.2],
  [-11.677334, 166.951654, 466.6],
  [-11.677313, 166.951632, 466.8],
  [-11.677293, 166.951622, 467.2],
  [-11.677272, 166.951622, 467.6],
  [-11.677252, 166.9516, 468],
  [-11.677232, 166.95159, 468.4],
  [-11.677212, 166.951568, 468.8],
  [-11.67719, 166.951557, 469.2],
  [-11.67717, 166.951557, 469.6],
  [-11.677148, 166.951547, 470],
  [-11.677128, 166.951525, 470.2],
  [-11.677107, 166.951514, 470.6],
  [-11.677087, 166.951493, 471],
  [-11.677065, 166.951493, 471.4],
  [-11.677044, 166.951482, 471.8],
  [-11.677024, 166.951461, 472.2],
  [-11.677001, 166.95145, 472.6],
  [-11.676981, 166.951439, 473],
  [-11.676959, 166.951439, 473.4],
  [-11.676938, 166.951418, 473.6],
  [-11.676916, 166.951407, 474],
  [-11.676895, 166.951386, 474.4],
  [-11.676875, 166.951375, 474.8],
  [-11.676852, 166.951375, 475.2],
  [-11.676832, 166.951364, 475.6],
  [-11.676811, 166.951343, 476],
  [-11.676789, 166.951332, 476.4],
  [-11.676768, 166.951311, 476.8],
  [-11.676746, 166.951311, 477],
  [-11.676726, 166.9513, 477.4],
  [-11.676705, 166.951278, 477.8],
  [-11.676683, 166.951268, 478.2],
  [-11.676663, 166.951257, 478.6],
  [-11.676642, 166.951236, 479],
  [-11.676621, 166.951225, 479.4],
  [-11.676601, 166.951203, 479.8],
  [-11.67658, 166.951203, 480],
  [-11.67656, 166.951193, 480.4],
  [-11.676538, 166.951171, 480.8],
  [-11.676518, 166.95116, 481.2],
  [-11.676498, 166.95115, 481.6],
  [-11.676477, 166.951128, 482],
  [-11.676455, 166.951118, 482.4],
  [-11.676436, 166.951096, 482.8],
  [-11.676415, 166.951085, 483],
  [-11.676393, 166.951064, 483.4],
  [-11.676373, 166.951053, 483.8],
  [-11.676353, 166.951053, 484.2],
  [-11.676332, 166.951042, 484.6],
  [-11.67631, 166.951021, 485],
  [-11.67629, 166.95101, 485.4],
  [-11.67627, 166.950989, 485.8],
  [-11.676249, 166.950978, 486],
  [-11.676228, 166.950967, 486.4],
  [-11.676207, 166.950946, 486.8],
  [-11.676187, 166.950935, 487.2],
  [-11.676165, 166.950914, 487.6],
  [-11.676145, 166.950903, 488],
  [-11.676123, 166.950881, 488.4],
  [-11.676102, 166.950881, 488.8],
  [-11.67608, 166.950871, 489.2],
  [-11.67606, 166.95086, 489.4],
  [-11.676037, 166.950839, 489.8],
  [-11.676017, 166.950828, 490.2],
  [-11.675994, 166.950828, 490.6],
  [-11.675972, 166.950806, 491],
  [-11.67595, 166.950796, 491.4],
  [-11.675928, 166.950774, 491.8],
  [-11.675905, 166.950774, 492.2],
  [-11.675882, 166.950763, 492.4],
  [-11.67586, 166.950753, 492.8],
  [-11.675837, 166.950753, 493.2],
  [-11.675813, 166.950731, 493.6],
  [-11.675789, 166.950731, 494],
  [-11.675766, 166.950731, 494.4],
  [-11.67574, 166.950721, 494.8],
  [-11.675716, 166.950721, 495],
  [-11.675689, 166.950721, 495.4],
  [-11.675664, 166.950721, 495.8],
  [-11.675638, 166.950721, 496.2],
  [-11.675612, 166.950721, 496.4],
  [-11.675585, 166.950721, 496.8],
  [-11.675557, 166.950721, 497.2],
  [-11.675528, 166.950721, 497.6],
  [-11.6755, 166.950721, 498],
  [-11.675472, 166.950721, 498.2],
  [-11.675444, 166.950721, 498.6],
  [-11.675416, 166.950721, 499],
  [-11.675389, 166.950721, 499.4],
];

export default stream;
