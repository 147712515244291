import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [51.49837, -0.075973, 10.2],
  [51.498515, -0.075721, 9.6],
  [51.498654, -0.075468, 8.8],
  [51.498762, -0.07517, 7.4],
  [51.498858, -0.074861, 6.2],
  [51.498923, -0.074528, 5.4],
  [51.498912, -0.074187, 5.4],
  [51.498853, -0.073859, 5.4],
  [51.498794, -0.073533, 5.4],
  [51.49888, -0.07325, 5.4],
  [51.499073, -0.073166, 5.4],
  [51.499277, -0.073119, 5.4],
  [51.499475, -0.073057, 5.4],
  [51.499652, -0.073126, 4.8],
  [51.499701, -0.073441, 4.6],
  [51.499733, -0.073757, 4.6],
  [51.499765, -0.074077, 4.6],
  [51.499802, -0.074389, 4.6],
  [51.499835, -0.074706, 4.6],
  [51.499872, -0.075015, 4.6],
  [51.499878, -0.075328, 4.6],
  [51.499765, -0.07558, 4.6],
  [51.499609, -0.075759, 4.6],
  [51.499454, -0.07595, 4.6],
  [51.499336, -0.0762, 4.6],
  [51.499245, -0.076477, 4.6],
  [51.499191, -0.076774, 4.6],
  [51.499202, -0.077083, 4.6],
  [51.499255, -0.077383, 4.6],
  [51.499341, -0.077661, 4.6],
  [51.49947, -0.077885, 4.6],
  [51.499642, -0.078019, 4.6],
  [51.499835, -0.07809, 4.6],
  [51.500022, -0.078097, 4.6],
  [51.500216, -0.078036, 4.6],
  [51.500398, -0.077946, 4.6],
  [51.500575, -0.077832, 4.6],
  [51.500757, -0.077719, 4.8],
  [51.500934, -0.077609, 5.2],
  [51.500988, -0.077338, 6.2],
  [51.500924, -0.077057, 6.2],
  [51.500988, -0.076799, 6.2],
  [51.501165, -0.076698, 6.2],
  [51.501342, -0.076604, 7],
  [51.501514, -0.076496, 10],
  [51.501675, -0.076395, 13],
  [51.501825, -0.076304, 15.6],
  [51.501975, -0.076215, 16],
  [51.50212, -0.076125, 16],
  [51.502275, -0.076031, 16],
  [51.502431, -0.075934, 16],
  [51.502587, -0.075965, 16],
  [51.502651, -0.076226, 16],
  [51.502715, -0.07649, 16],
  [51.50279, -0.076754, 16],
  [51.502855, -0.077021, 16],
  [51.503, -0.077165, 16],
  [51.503171, -0.077067, 16],
  [51.503338, -0.076931, 16],
  [51.503509, -0.076808, 16],
  [51.503681, -0.076686, 16],
  [51.503847, -0.076562, 16],
  [51.504019, -0.076436, 16],
  [51.504191, -0.076313, 16],
  [51.504362, -0.076192, 16],
  [51.504539, -0.07607, 16],
  [51.504711, -0.075949, 16],
  [51.504883, -0.075825, 16],
  [51.505054, -0.075704, 16],
  [51.505231, -0.075582, 16],
  [51.505408, -0.075459, 16],
  [51.50558, -0.075337, 16],
  [51.505757, -0.075214, 16],
  [51.505934, -0.075091, 16],
  [51.506111, -0.074966, 16],
  [51.506283, -0.074843, 16],
  [51.50646, -0.074718, 16],
  [51.506637, -0.074593, 16],
  [51.506808, -0.074478, 16],
  [51.506996, -0.074414, 16],
  [51.507189, -0.074363, 16],
  [51.507377, -0.074312, 16],
  [51.507565, -0.074263, 16],
  [51.507758, -0.074223, 16],
  [51.507946, -0.074185, 16],
  [51.508139, -0.074146, 16],
  [51.508327, -0.074105, 16],
  [51.50852, -0.074061, 16],
  [51.508707, -0.074016, 16],
  [51.508895, -0.07397, 16],
  [51.509094, -0.073944, 16],
  [51.509276, -0.074018, 16.2],
  [51.509421, -0.074202, 17.4],
  [51.509555, -0.074422, 17.8],
  [51.509662, -0.074662, 18.2],
];

export default stream;
