import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.780701, 165.833881, 65.6],
  [-10.780673, 165.834031, 65.6],
  [-10.780551, 165.83416, 65.6],
  [-10.780442, 165.834278, 65.6],
  [-10.780381, 165.834428, 65.6],
  [-10.780369, 165.8346, 65.6],
  [-10.780404, 165.83475, 65.6],
  [-10.780474, 165.8349, 65.6],
  [-10.780587, 165.835029, 65.6],
  [-10.780732, 165.835104, 65.6],
  [-10.78089, 165.835147, 65.6],
  [-10.781045, 165.83519, 65.6],
];

export default stream;
