import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [51.507865, -0.126803, 24.4],
  [51.507994, -0.126559, 24.2],
  [51.508123, -0.126308, 24.2],
  [51.508246, -0.126064, 24.2],
  [51.508369, -0.125815, 24.2],
  [51.508498, -0.125559, 24.2],
  [51.508622, -0.125313, 24.2],
  [51.50875, -0.125062, 24.2],
  [51.508868, -0.124805, 24.4],
  [51.508986, -0.124551, 24.4],
  [51.50911, -0.124304, 24.4],
  [51.509238, -0.124061, 24.4],
  [51.509362, -0.123816, 24.6],
  [51.509491, -0.123564, 24.6],
  [51.509609, -0.123311, 24.6],
  [51.509727, -0.123052, 24.6],
  [51.509839, -0.12279, 24.6],
  [51.509957, -0.122529, 24.6],
  [51.51007, -0.122273, 24.6],
  [51.510183, -0.122009, 24.6],
  [51.51029, -0.12174, 24.6],
  [51.510386, -0.121456, 24.6],
  [51.510478, -0.121175, 24.6],
  [51.510623, -0.12114, 24.6],
  [51.510574, -0.121452, 24.6],
  [51.510478, -0.121716, 24.6],
  [51.510376, -0.122, 24.6],
  [51.510268, -0.122272, 24.6],
  [51.51015, -0.12253, 24.6],
  [51.510032, -0.122794, 24.6],
  [51.50992, -0.123046, 24.6],
  [51.509807, -0.1233, 24.6],
  [51.509689, -0.12357, 24.6],
  [51.509571, -0.123817, 24.6],
  [51.509442, -0.124066, 24.6],
  [51.509308, -0.124321, 24.4],
  [51.509185, -0.124565, 24.4],
  [51.509067, -0.124808, 24.4],
  [51.508943, -0.125066, 24.4],
  [51.50882, -0.125329, 24.4],
  [51.508697, -0.125576, 24.4],
  [51.508584, -0.125844, 24.2],
  [51.508563, -0.126159, 24.2],
  [51.508573, -0.126478, 24.4],
  [51.508579, -0.126802, 24.4],
  [51.508579, -0.12711, 24.6],
  [51.508461, -0.127348, 24.4],
  [51.508268, -0.127323, 24.4],
  [51.508074, -0.127242, 24.4],
];

export default stream;
