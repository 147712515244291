import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.750827, 165.852195, 132.8],
  [-10.750823, 165.852388, 132.8],
  [-10.750891, 165.852571, 132.8],
  [-10.751045, 165.852699, 132.8],
  [-10.751183, 165.852828, 132.6],
  [-10.751161, 165.853032, 132.4],
  [-10.751027, 165.853171, 132.2],
  [-10.750848, 165.853257, 132],
  [-10.750661, 165.853332, 131.6],
  [-10.750465, 165.853386, 131.4],
  [-10.750266, 165.853397, 131.2],
  [-10.750065, 165.853397, 130.8],
  [-10.749869, 165.853354, 130.6],
  [-10.749676, 165.853289, 130.4],
  [-10.749504, 165.853182, 130.2],
  [-10.749358, 165.853043, 130],
  [-10.749202, 165.852914, 130],
  [-10.749014, 165.852849, 130],
  [-10.748821, 165.852785, 129.8],
  [-10.748685, 165.852646, 129.6],
  [-10.748597, 165.852463, 129.6],
  [-10.748452, 165.852334, 129.2],
  [-10.748252, 165.852334, 128.6],
  [-10.748059, 165.85241, 127.8],
  [-10.747941, 165.85256, 127.6],
  [-10.747947, 165.852774, 128],
  [-10.747981, 165.852968, 128.4],
  [-10.747983, 165.853171, 128.8],
  [-10.747883, 165.853332, 128.8],
  [-10.747732, 165.853461, 128.8],
  [-10.747599, 165.853611, 128.8],
  [-10.747562, 165.853804, 129.2],
  [-10.747583, 165.853997, 129.8],
  [-10.747614, 165.854191, 130.2],
  [-10.747653, 165.854373, 130.6],
  [-10.747698, 165.854577, 130.8],
  [-10.747763, 165.854759, 130.8],
];

export default stream;
