import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-21.736552, 166.181635, 13],
  [-21.736549, 166.181635, 13],
  [-21.736547, 166.181645, 13],
  [-21.736544, 166.181645, 13],
  [-21.736539, 166.181678, 13],
  [-21.736527, 166.18171, 13],
  [-21.736512, 166.181731, 13],
  [-21.736494, 166.181785, 13],
  [-21.736476, 166.181828, 13],
  [-21.736454, 166.181871, 13],
  [-21.736431, 166.181935, 13],
  [-21.736411, 166.181999, 13],
  [-21.736387, 166.182053, 13],
  [-21.736363, 166.182117, 13],
  [-21.736338, 166.182193, 13],
  [-21.736313, 166.182268, 13],
  [-21.736287, 166.182332, 13],
  [-21.736258, 166.182407, 13],
  [-21.736232, 166.182482, 13],
  [-21.736203, 166.182557, 13.2],
  [-21.736174, 166.182632, 13.2],
  [-21.736146, 166.182729, 13.2],
  [-21.736118, 166.182804, 13.2],
  [-21.736087, 166.182879, 13.2],
  [-21.736056, 166.182976, 13.4],
  [-21.736028, 166.183051, 13.4],
  [-21.735997, 166.183137, 13.4],
  [-21.735966, 166.183212, 13.6],
  [-21.735935, 166.183308, 13.6],
  [-21.735906, 166.183383, 13.8],
  [-21.735875, 166.18348, 14],
  [-21.735844, 166.183555, 14.2],
  [-21.735816, 166.183641, 14.4],
  [-21.735785, 166.183716, 14.6],
  [-21.735757, 166.183791, 14.8],
  [-21.735727, 166.183888, 15],
  [-21.735698, 166.183963, 15.2],
  [-21.735668, 166.184038, 15.4],
  [-21.73564, 166.184113, 15.6],
  [-21.735612, 166.184188, 15.8],
  [-21.735583, 166.184274, 16],
  [-21.735554, 166.184349, 16.2],
  [-21.735526, 166.184424, 16.4],
  [-21.735497, 166.184499, 16.6],
  [-21.73547, 166.184574, 17],
  [-21.735443, 166.184649, 17.2],
  [-21.735419, 166.184725, 17.4],
  [-21.735393, 166.1848, 17.6],
  [-21.735369, 166.184864, 18],
  [-21.735345, 166.184939, 18.2],
  [-21.735321, 166.185014, 18.6],
  [-21.735298, 166.185079, 19],
  [-21.735278, 166.185154, 19.2],
  [-21.735255, 166.185218, 19.6],
  [-21.735232, 166.185272, 20],
  [-21.73521, 166.185336, 20.4],
  [-21.735186, 166.1854, 20.8],
  [-21.735163, 166.185454, 21.2],
  [-21.735143, 166.185508, 21.8],
  [-21.735125, 166.185551, 22.2],
  [-21.73511, 166.185615, 22.6],
  [-21.735097, 166.185658, 23],
  [-21.735085, 166.185701, 23.4],
  [-21.735077, 166.185755, 23.8],
  [-21.735072, 166.185797, 24.2],
  [-21.735068, 166.18584, 24.6],
  [-21.735066, 166.185873, 24.8],
  [-21.735063, 166.185915, 25.2],
  [-21.735066, 166.185958, 25.4],
  [-21.73507, 166.185991, 25.8],
  [-21.735074, 166.186044, 26.2],
  [-21.735081, 166.186066, 26.4],
  [-21.73509, 166.186119, 26.8],
  [-21.735102, 166.186141, 27],
  [-21.735113, 166.186194, 27.4],
  [-21.735127, 166.186227, 27.8],
  [-21.73514, 166.186248, 28],
  [-21.735156, 166.186302, 28.4],
  [-21.735171, 166.186334, 28.6],
  [-21.735188, 166.186355, 29],
  [-21.735203, 166.186409, 29.2],
  [-21.73522, 166.186441, 29.6],
  [-21.735238, 166.186463, 29.8],
  [-21.735255, 166.186495, 30.2],
  [-21.735271, 166.186538, 30.4],
  [-21.735283, 166.186559, 30.6],
  [-21.735289, 166.186559, 30.6],
  [-21.735293, 166.186559, 30.6],
  [-21.735301, 166.186591, 30.8],
  [-21.735312, 166.186602, 31],
  [-21.735324, 166.186634, 31.2],
  [-21.735334, 166.186666, 31.4],
  [-21.735348, 166.186699, 31.6],
  [-21.735361, 166.186731, 31.8],
  [-21.735377, 166.186774, 32.2],
  [-21.735391, 166.186806, 32.4],
  [-21.735404, 166.186827, 32.6],
  [-21.735418, 166.186881, 32.8],
  [-21.735431, 166.186924, 33.2],
  [-21.73544, 166.186967, 33.4],
  [-21.73545, 166.187021, 33.6],
  [-21.735459, 166.187063, 33.8],
  [-21.735464, 166.187106, 34],
  [-21.735469, 166.187149, 34.2],
  [-21.735473, 166.187214, 34.4],
  [-21.735473, 166.187257, 34.4],
  [-21.735474, 166.187321, 34.6],
  [-21.735473, 166.187364, 34.8],
  [-21.73547, 166.187428, 34.8],
  [-21.735467, 166.187493, 35],
  [-21.735463, 166.187568, 35],
  [-21.735456, 166.187621, 35.2],
  [-21.73545, 166.187686, 35.2],
  [-21.73544, 166.187761, 35.4],
  [-21.735431, 166.187836, 35.4],
  [-21.735419, 166.1879, 35.4],
  [-21.735407, 166.187975, 35.4],
  [-21.735393, 166.188051, 35.4],
  [-21.735381, 166.188126, 35.6],
  [-21.735365, 166.188222, 35.6],
  [-21.73535, 166.188297, 35.6],
  [-21.735334, 166.188372, 35.8],
  [-21.735318, 166.188469, 35.8],
  [-21.735301, 166.188544, 35.8],
  [-21.735283, 166.188619, 36],
  [-21.735266, 166.188705, 36],
  [-21.735247, 166.188791, 36.2],
  [-21.735228, 166.188877, 36.4],
  [-21.735207, 166.188952, 36.4],
  [-21.735184, 166.189048, 36.6],
  [-21.735161, 166.189123, 36.6],
  [-21.735139, 166.189209, 36.8],
  [-21.735116, 166.189284, 36.8],
  [-21.735093, 166.189381, 37],
  [-21.735068, 166.189456, 37],
  [-21.735043, 166.189553, 37.2],
  [-21.735018, 166.189638, 37.2],
  [-21.734994, 166.189713, 37.4],
  [-21.734968, 166.18981, 37.6],
  [-21.734944, 166.189885, 37.6],
  [-21.734919, 166.189971, 37.8],
  [-21.734896, 166.190057, 37.8],
  [-21.734872, 166.190143, 38],
  [-21.734846, 166.190218, 38.2],
  [-21.734823, 166.190314, 38.4],
  [-21.734801, 166.1904, 38.4],
  [-21.734781, 166.190475, 38.6],
  [-21.734756, 166.190572, 38.8],
  [-21.734736, 166.190647, 39],
  [-21.734715, 166.190743, 39.2],
  [-21.734692, 166.190819, 39.2],
  [-21.734671, 166.190904, 39.4],
  [-21.734648, 166.190979, 39.6],
  [-21.734628, 166.191055, 39.8],
  [-21.734605, 166.191151, 40],
  [-21.734583, 166.191226, 40.2],
  [-21.734562, 166.191301, 40.4],
  [-21.734542, 166.191398, 40.6],
  [-21.73452, 166.191473, 40.8],
  [-21.734502, 166.191548, 41],
  [-21.734483, 166.191623, 41.2],
  [-21.734463, 166.19172, 41.4],
  [-21.734444, 166.191795, 41.6],
  [-21.734426, 166.19187, 41.8],
  [-21.734412, 166.191945, 42],
  [-21.734394, 166.19202, 42.2],
  [-21.734381, 166.192095, 42.6],
  [-21.734366, 166.19217, 42.8],
  [-21.734353, 166.192245, 43.2],
  [-21.734341, 166.192321, 43.4],
  [-21.73433, 166.192406, 43.8],
  [-21.734321, 166.192482, 44.2],
  [-21.734311, 166.192535, 44.4],
  [-21.734303, 166.192621, 44.8],
  [-21.734295, 166.192675, 45],
  [-21.73429, 166.19275, 45.4],
  [-21.734284, 166.192814, 45.6],
  [-21.734276, 166.192878, 46],
  [-21.734271, 166.192932, 46.2],
  [-21.734266, 166.193007, 46.4],
  [-21.734259, 166.193072, 46.6],
  [-21.734253, 166.193136, 46.8],
  [-21.734248, 166.1932, 47.2],
  [-21.734241, 166.193275, 47.4],
  [-21.734239, 166.193329, 47.6],
  [-21.734232, 166.193393, 47.8],
  [-21.734229, 166.193469, 47.8],
  [-21.734225, 166.193533, 48],
  [-21.734221, 166.193608, 48.2],
  [-21.734217, 166.193672, 48.4],
  [-21.734213, 166.193748, 48.6],
  [-21.734209, 166.193801, 48.8],
  [-21.734205, 166.193887, 49],
  [-21.734203, 166.193962, 49.2],
  [-21.734198, 166.194016, 49.4],
  [-21.734194, 166.194091, 49.6],
  [-21.73419, 166.194177, 49.8],
  [-21.734186, 166.19423, 50],
  [-21.734182, 166.194305, 50.2],
  [-21.73418, 166.194381, 50.4],
  [-21.734176, 166.194445, 50.6],
  [-21.734169, 166.19452, 50.8],
  [-21.734166, 166.194595, 51],
  [-21.734158, 166.194659, 51.2],
  [-21.734153, 166.194735, 51.4],
  [-21.734145, 166.19481, 51.8],
  [-21.734137, 166.194874, 52],
  [-21.734127, 166.194949, 52.2],
  [-21.734119, 166.195024, 52.6],
  [-21.734113, 166.195089, 52.8],
  [-21.734106, 166.195164, 53.2],
  [-21.734103, 166.195228, 53.4],
  [-21.734097, 166.195282, 53.8],
  [-21.734094, 166.195368, 54],
  [-21.73409, 166.195421, 54.4],
  [-21.734083, 166.195486, 54.6],
  [-21.734078, 166.19555, 55],
  [-21.734068, 166.195604, 55.4],
  [-21.734059, 166.195668, 55.6],
  [-21.734047, 166.195732, 56],
  [-21.734031, 166.195775, 56.4],
  [-21.734011, 166.19584, 56.6],
  [-21.733987, 166.195883, 57],
  [-21.733954, 166.195925, 57.2],
  [-21.733918, 166.195958, 57.4],
  [-21.733874, 166.19599, 57.8],
  [-21.733824, 166.196022, 58],
  [-21.733771, 166.196022, 58],
  [-21.733716, 166.196022, 58.2],
  [-21.733658, 166.196022, 58.4],
  [-21.733602, 166.196001, 58.4],
  [-21.733543, 166.19599, 58.6],
  [-21.733481, 166.195968, 58.6],
  [-21.733418, 166.195958, 58.6],
  [-21.733355, 166.195925, 58.8],
  [-21.733291, 166.195915, 58.8],
  [-21.733223, 166.195883, 58.8],
  [-21.733156, 166.19585, 58.8],
  [-21.733088, 166.195818, 59],
  [-21.733017, 166.195786, 59],
  [-21.732947, 166.195775, 59],
  [-21.732875, 166.195743, 59.2],
  [-21.732798, 166.195732, 59.2],
  [-21.732722, 166.195711, 59.4],
  [-21.732644, 166.195711, 59.6],
  [-21.732568, 166.1957, 59.8],
  [-21.732489, 166.1957, 60],
  [-21.732413, 166.195711, 60.2],
  [-21.732338, 166.195732, 60.6],
  [-21.73227, 166.195754, 60.8],
  [-21.732206, 166.195786, 61.2],
  [-21.732144, 166.19584, 61.6],
  [-21.732086, 166.195861, 62.2],
  [-21.732035, 166.195915, 62.6],
  [-21.731986, 166.195958, 63],
  [-21.731941, 166.19599, 63.4],
  [-21.7319, 166.196033, 64],
  [-21.73186, 166.196076, 64.4],
  [-21.731822, 166.196108, 64.8],
  [-21.731789, 166.19614, 65],
  [-21.731774, 166.196172, 65.2],
  [-21.731772, 166.196172, 65.2],
  [-21.73177, 166.196172, 65.2],
  [-21.73176, 166.196183, 65.4],
  [-21.731746, 166.196204, 65.6],
  [-21.73173, 166.196215, 65.8],
  [-21.731723, 166.196237, 65.8],
  [-21.731707, 166.196247, 66],
  [-21.7317, 166.196247, 66.2],
  [-21.731688, 166.196258, 66.2],
  [-21.73168, 166.19628, 66.2],
  [-21.73167, 166.19628, 66.4],
  [-21.731665, 166.19629, 66.4],
  [-21.731664, 166.19629, 66.4],
  [-21.731657, 166.19629, 66.6],
  [-21.731648, 166.196312, 66.6],
  [-21.731638, 166.196322, 66.8],
  [-21.731625, 166.196344, 66.8],
  [-21.731607, 166.196355, 67],
  [-21.731587, 166.196365, 67.2],
  [-21.731565, 166.196398, 67.4],
  [-21.731539, 166.196419, 67.8],
  [-21.731515, 166.19644, 68],
  [-21.731488, 166.196473, 68.2],
  [-21.731461, 166.196505, 68.4],
  [-21.731434, 166.196537, 68.6],
  [-21.731406, 166.196569, 69],
  [-21.731375, 166.196601, 69.2],
  [-21.731347, 166.196634, 69.4],
  [-21.731316, 166.196655, 69.6],
  [-21.731284, 166.196687, 70],
  [-21.731253, 166.196719, 70.2],
  [-21.731219, 166.196752, 70.4],
  [-21.731186, 166.196784, 70.6],
  [-21.731151, 166.196827, 71],
  [-21.731118, 166.196859, 71.2],
  [-21.731083, 166.196891, 71.4],
  [-21.731047, 166.196923, 71.6],
  [-21.731013, 166.196966, 72],
  [-21.730977, 166.196998, 72.2],
  [-21.730941, 166.197031, 72.4],
  [-21.730905, 166.197073, 72.6],
  [-21.730869, 166.197106, 72.8],
  [-21.73083, 166.197149, 73.2],
  [-21.730792, 166.197181, 73.4],
  [-21.730756, 166.197224, 73.6],
  [-21.730717, 166.197256, 73.8],
  [-21.730681, 166.197299, 74.2],
  [-21.730643, 166.197331, 74.4],
  [-21.730604, 166.197374, 74.6],
  [-21.730567, 166.197428, 74.8],
  [-21.730528, 166.197449, 75],
  [-21.73049, 166.197503, 75.2],
  [-21.730453, 166.197546, 75.4],
  [-21.730415, 166.197588, 75.8],
  [-21.730378, 166.197631, 76],
  [-21.73034, 166.197685, 76.2],
  [-21.730301, 166.197728, 76.4],
  [-21.730265, 166.197771, 76.6],
  [-21.730229, 166.197814, 76.6],
  [-21.730191, 166.197867, 76.8],
  [-21.730155, 166.197921, 77],
  [-21.730116, 166.197975, 77.2],
  [-21.73008, 166.198028, 77.2],
  [-21.730044, 166.198093, 77.4],
  [-21.730006, 166.198157, 77.4],
  [-21.72997, 166.1982, 77.6],
  [-21.729931, 166.198275, 77.6],
  [-21.729894, 166.198339, 77.8],
  [-21.729855, 166.198404, 77.8],
  [-21.729817, 166.198457, 78],
  [-21.729778, 166.198533, 78],
  [-21.729741, 166.198597, 78.2],
  [-21.7297, 166.198661, 78.4],
  [-21.729663, 166.198736, 78.6],
  [-21.729624, 166.198801, 78.8],
  [-21.729584, 166.198876, 79],
  [-21.729546, 166.19893, 79.2],
  [-21.72951, 166.199005, 79.4],
  [-21.729475, 166.199069, 79.6],
  [-21.729442, 166.199144, 79.8],
  [-21.729407, 166.199208, 80],
  [-21.729373, 166.199273, 80.2],
  [-21.72934, 166.199348, 80.4],
  [-21.729306, 166.199402, 80.8],
  [-21.729271, 166.199487, 81],
  [-21.729238, 166.199541, 81.2],
  [-21.729203, 166.199605, 81.6],
  [-21.729165, 166.19967, 81.8],
  [-21.729128, 166.199723, 82],
  [-21.72909, 166.199788, 82.4],
  [-21.729053, 166.199852, 82.6],
  [-21.729012, 166.199895, 83],
  [-21.728972, 166.19996, 83.4],
  [-21.728932, 166.200002, 83.6],
  [-21.728892, 166.200045, 84],
  [-21.72885, 166.200088, 84.4],
  [-21.72881, 166.200142, 84.8],
  [-21.72877, 166.200185, 85],
  [-21.728729, 166.200228, 85.4],
  [-21.728689, 166.20026, 85.8],
  [-21.728652, 166.200303, 86.2],
  [-21.728613, 166.200335, 86.4],
  [-21.728575, 166.200367, 86.8],
  [-21.728539, 166.20041, 87.2],
  [-21.728503, 166.200442, 87.4],
  [-21.728467, 166.200475, 87.8],
  [-21.72843, 166.200507, 88.2],
  [-21.728396, 166.200539, 88.4],
  [-21.728362, 166.20056, 88.8],
  [-21.728327, 166.200593, 89.2],
  [-21.728294, 166.200625, 89.4],
  [-21.72826, 166.200657, 89.8],
  [-21.728221, 166.2007, 90.2],
  [-21.728169, 166.200753, 90.6],
  [-21.728109, 166.200796, 91.2],
  [-21.728041, 166.200861, 92],
  [-21.72797, 166.200914, 92.6],
  [-21.727897, 166.200979, 93.4],
  [-21.72782, 166.201054, 94.4],
  [-21.72774, 166.201118, 95.2],
  [-21.727654, 166.201193, 96.2],
  [-21.727564, 166.201247, 97.2],
  [-21.727472, 166.201333, 98],
  [-21.727372, 166.201386, 98.8],
  [-21.727269, 166.20144, 99.6],
  [-21.727158, 166.201494, 100.4],
  [-21.727045, 166.201547, 101],
  [-21.726936, 166.201569, 101.6],
  [-21.726826, 166.201601, 102.2],
  [-21.726719, 166.201644, 102.8],
  [-21.726613, 166.201676, 103.4],
  [-21.726507, 166.201708, 103.8],
  [-21.726405, 166.201741, 104.4],
  [-21.726303, 166.201773, 104.8],
  [-21.7262, 166.201805, 105.2],
  [-21.726098, 166.201837, 105.6],
  [-21.725995, 166.201859, 106],
  [-21.725892, 166.201891, 106.4],
  [-21.725791, 166.201912, 106.8],
  [-21.725688, 166.201934, 107.2],
  [-21.725585, 166.201966, 107.6],
  [-21.725484, 166.201998, 108],
  [-21.725385, 166.20203, 108.6],
  [-21.725287, 166.202073, 109],
  [-21.72519, 166.202105, 109.6],
  [-21.725096, 166.202148, 110.4],
  [-21.725005, 166.20218, 111],
  [-21.724917, 166.202234, 111.8],
  [-21.724831, 166.202277, 112.4],
  [-21.724745, 166.202309, 113.2],
  [-21.724663, 166.202352, 113.8],
  [-21.724581, 166.202384, 114.6],
  [-21.724501, 166.202427, 115.2],
  [-21.724423, 166.20247, 116],
  [-21.724345, 166.202502, 116.6],
  [-21.724269, 166.202545, 117.2],
  [-21.72419, 166.202577, 118],
  [-21.724113, 166.20261, 118.6],
];

export default stream;
