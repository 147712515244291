import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.681755, 166.92746, 21.6],
  [-11.681908, 166.927385, 21.6],
  [-11.682062, 166.92731, 21.6],
  [-11.68223, 166.927278, 21.6],
  [-11.6824, 166.927332, 21.2],
  [-11.682548, 166.927418, 21],
  [-11.682699, 166.927525, 20.6],
  [-11.682871, 166.927568, 20.2],
  [-11.683055, 166.927557, 19.4],
  [-11.683236, 166.927536, 18.8],
  [-11.683415, 166.927568, 18.2],
  [-11.683576, 166.927675, 17.6],
  [-11.683756, 166.927718, 17.2],
  [-11.683944, 166.927696, 17.2],
  [-11.684126, 166.927643, 17],
  [-11.684302, 166.927589, 17],
  [-11.68446, 166.927482, 16.8],
  [-11.684555, 166.927332, 16.6],
  [-11.684609, 166.927128, 16.2],
  [-11.684626, 166.926945, 15.6],
  [-11.684652, 166.926763, 14.8],
  [-11.684759, 166.926591, 14.4],
  [-11.684899, 166.926473, 14],
  [-11.685074, 166.926398, 13.6],
  [-11.68525, 166.926452, 13.2],
  [-11.685402, 166.926581, 13],
  [-11.685571, 166.926666, 12.6],
  [-11.685758, 166.926699, 12],
  [-11.685953, 166.926699, 11.4],
  [-11.686139, 166.926656, 10.6],
  [-11.686289, 166.926538, 9.8],
];

export default stream;
