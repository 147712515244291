import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.692467, 166.930679, 0.4],
  [-11.692477, 166.930861, 0.4],
  [-11.692473, 166.931055, 0.4],
  [-11.69239, 166.931216, 0.6],
  [-11.692232, 166.931312, 0.6],
  [-11.692052, 166.931355, 0.6],
  [-11.691875, 166.931398, 0.6],
  [-11.691701, 166.931462, 0.6],
  [-11.691545, 166.93157, 0.4],
  [-11.691403, 166.931677, 0.4],
  [-11.691252, 166.931784, 0.4],
  [-11.691076, 166.931827, 0.6],
  [-11.690897, 166.931795, 1],
  [-11.690737, 166.93172, 1.4],
  [-11.690581, 166.931634, 2],
  [-11.690423, 166.931548, 2.6],
  [-11.690248, 166.931537, 3.2],
  [-11.690098, 166.931612, 4],
  [-11.689971, 166.931741, 4.6],
  [-11.689855, 166.931859, 5.2],
  [-11.689744, 166.931977, 5.8],
  [-11.689611, 166.932074, 6.4],
  [-11.689452, 166.932106, 7],
  [-11.689316, 166.932009, 7.4],
  [-11.689239, 166.931859, 7.8],
  [-11.689185, 166.931709, 8],
  [-11.689087, 166.93158, 8.6],
  [-11.688929, 166.931537, 9.2],
  [-11.688775, 166.93158, 9.8],
  [-11.688657, 166.931709, 9.6],
  [-11.688568, 166.931838, 9.4],
  [-11.688485, 166.931999, 9],
  [-11.688376, 166.932138, 8.8],
  [-11.68823, 166.932224, 9],
  [-11.688066, 166.932267, 9.4],
  [-11.687896, 166.932288, 10],
  [-11.687726, 166.932288, 10.8],
  [-11.687558, 166.932267, 11.4],
  [-11.687403, 166.932213, 12],
  [-11.687283, 166.932106, 12.2],
  [-11.687227, 166.931934, 12.6],
  [-11.687254, 166.931784, 12.8],
  [-11.68734, 166.931634, 13],
  [-11.687435, 166.931494, 13.2],
  [-11.68748, 166.931323, 13.4],
  [-11.687419, 166.931173, 13.8],
  [-11.687313, 166.931033, 14.2],
  [-11.687208, 166.930915, 14.4],
  [-11.687177, 166.930743, 14.8],
  [-11.68723, 166.930593, 15.4],
  [-11.687283, 166.930422, 16],
  [-11.687242, 166.930261, 16.2],
  [-11.687158, 166.930121, 16],
  [-11.687049, 166.929992, 16],
  [-11.686901, 166.929907, 16.2],
  [-11.686733, 166.929917, 16.6],
  [-11.686566, 166.929971, 17.2],
  [-11.686402, 166.92995, 17.8],
  [-11.686247, 166.929874, 18.2],
  [-11.686108, 166.929778, 18.8],
  [-11.68597, 166.929692, 19.2],
  [-11.685825, 166.929617, 19.8],
  [-11.68567, 166.929574, 20.4],
  [-11.685513, 166.929595, 21],
  [-11.685376, 166.929692, 21.6],
  [-11.685257, 166.92981, 22],
  [-11.685143, 166.929917, 22.4],
  [-11.685015, 166.930025, 23],
  [-11.684868, 166.9301, 23.4],
  [-11.684706, 166.930132, 24],
  [-11.684551, 166.930164, 24.6],
  [-11.684415, 166.930261, 25.2],
  [-11.68429, 166.930346, 25.6],
];

export default stream;
