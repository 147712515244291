import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.800752, 165.809945, 5.2],
  [-10.800644, 165.809945, 5],
  [-10.80054, 165.809966, 5],
  [-10.800445, 165.81002, 5],
  [-10.800368, 165.810095, 5.2],
  [-10.800308, 165.810181, 5.2],
  [-10.800244, 165.810256, 5.6],
  [-10.80016, 165.81032, 6],
  [-10.800067, 165.810353, 6.4],
  [-10.799975, 165.810363, 6.8],
  [-10.79988, 165.810363, 7],
  [-10.79979, 165.810363, 7],
  [-10.799698, 165.810363, 7],
  [-10.799605, 165.810353, 7],
  [-10.799514, 165.810342, 6.8],
  [-10.799424, 165.81031, 6.8],
  [-10.799337, 165.810256, 6.6],
  [-10.799251, 165.810213, 6.6],
  [-10.799166, 165.81017, 6.4],
  [-10.799082, 165.810106, 6.2],
  [-10.798998, 165.810063, 6],
  [-10.798913, 165.809999, 5.8],
  [-10.798824, 165.809945, 5.6],
  [-10.798733, 165.809891, 5.6],
  [-10.798638, 165.809848, 5.4],
  [-10.798537, 165.809816, 5.2],
  [-10.798435, 165.809784, 5],
  [-10.798329, 165.809773, 5],
  [-10.798222, 165.809773, 4.8],
  [-10.798121, 165.809805, 4.6],
  [-10.79803, 165.809859, 4.4],
  [-10.797943, 165.809945, 4],
  [-10.797844, 165.809988, 3.8],
  [-10.797733, 165.809988, 3.6],
  [-10.797626, 165.809956, 3.6],
  [-10.797529, 165.809913, 3.6],
  [-10.797452, 165.809838, 3.8],
  [-10.7974, 165.809741, 4.2],
  [-10.79736, 165.809634, 4.4],
  [-10.797322, 165.809548, 4.6],
  [-10.797281, 165.809451, 4.8],
  [-10.797227, 165.809366, 4.8],
  [-10.797164, 165.80928, 5],
  [-10.797098, 165.809205, 5],
  [-10.797033, 165.809151, 5],
  [-10.796967, 165.809076, 5],
  [-10.796902, 165.80899, 5],
  [-10.796831, 165.808915, 5],
  [-10.796752, 165.808861, 5],
  [-10.79666, 165.808829, 5],
  [-10.796562, 165.808808, 5],
  [-10.796463, 165.808808, 5],
  [-10.796367, 165.80884, 5],
  [-10.79628, 165.808883, 5],
  [-10.796202, 165.808947, 5],
  [-10.796129, 165.809011, 5],
  [-10.796048, 165.809076, 5],
  [-10.795951, 165.809087, 5],
  [-10.795856, 165.809076, 5],
  [-10.795765, 165.809022, 5],
  [-10.795672, 165.80899, 5],
  [-10.795573, 165.808979, 5],
  [-10.795476, 165.80899, 5],
  [-10.795384, 165.809022, 5],
  [-10.795305, 165.809087, 5],
  [-10.795236, 165.809162, 5],
  [-10.795178, 165.809237, 5],
  [-10.795133, 165.809333, 5],
  [-10.795097, 165.809419, 5],
  [-10.795071, 165.809526, 5],
  [-10.795054, 165.809623, 5],
  [-10.795046, 165.80973, 5],
  [-10.795047, 165.809816, 5],
  [-10.795066, 165.809923, 5],
  [-10.795121, 165.809999, 5.2],
  [-10.795195, 165.810063, 5.4],
  [-10.795278, 165.810106, 5.8],
  [-10.795362, 165.810159, 6.2],
  [-10.795446, 165.810181, 6.6],
  [-10.795528, 165.810213, 6.8],
  [-10.795609, 165.810245, 7.2],
  [-10.795688, 165.810277, 7.6],
  [-10.795763, 165.81031, 8],
  [-10.795833, 165.810342, 8.2],
  [-10.795899, 165.810385, 8.6],
  [-10.795955, 165.810428, 8.8],
  [-10.795994, 165.810503, 9],
  [-10.79601, 165.810578, 9],
  [-10.795995, 165.810653, 8.8],
  [-10.795949, 165.810717, 8.4],
  [-10.795881, 165.810782, 8],
  [-10.795801, 165.810825, 7.4],
  [-10.795714, 165.810868, 7],
  [-10.795621, 165.810921, 6.4],
  [-10.795524, 165.810943, 6],
  [-10.795424, 165.810996, 5.4],
  [-10.795327, 165.81105, 5.2],
  [-10.79524, 165.811114, 5],
  [-10.795173, 165.811211, 5],
  [-10.795128, 165.811318, 5],
  [-10.79511, 165.811425, 4.8],
  [-10.795105, 165.811543, 4.8],
  [-10.795076, 165.811629, 4.8],
  [-10.795005, 165.811726, 4.8],
  [-10.794925, 165.811801, 4.8],
  [-10.794866, 165.811876, 4.8],
  [-10.794849, 165.811983, 4.8],
  [-10.794866, 165.812091, 4.8],
  [-10.794906, 165.812198, 4.8],
  [-10.794962, 165.812294, 4.8],
  [-10.795026, 165.81237, 4.8],
  [-10.795095, 165.812445, 4.8],
  [-10.795167, 165.81252, 4.8],
];

export default stream;
