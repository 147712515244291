import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.647086, 166.949143, 10.4],
  [-11.646964, 166.949165, 10.4],
  [-11.646899, 166.949272, 10.4],
  [-11.64685, 166.94939, 10.4],
  [-11.646794, 166.949508, 10.4],
  [-11.646732, 166.949615, 10.4],
  [-11.646642, 166.949723, 10.6],
  [-11.64653, 166.949787, 10.8],
  [-11.646414, 166.94983, 10.8],
  [-11.64631, 166.949905, 11.2],
  [-11.646233, 166.950002, 11.4],
  [-11.646192, 166.95012, 11.4],
  [-11.646181, 166.950248, 11.4],
  [-11.646193, 166.950377, 11.6],
  [-11.646243, 166.950485, 11.4],
  [-11.646347, 166.950549, 11.2],
];

export default stream;
