import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.791736, 165.840501, 65.8],
  [-10.791902, 165.840501, 65.6],
  [-10.792068, 165.84049, 65.8],
  [-10.792232, 165.840458, 66.2],
  [-10.792394, 165.840447, 66.6],
  [-10.792553, 165.840425, 66.8],
  [-10.792716, 165.840415, 66.6],
  [-10.792878, 165.840393, 66.4],
  [-10.793044, 165.840383, 65.8],
  [-10.79321, 165.84035, 65.2],
  [-10.793374, 165.840307, 64.4],
  [-10.79354, 165.840243, 63.6],
  [-10.79371, 165.840211, 63],
  [-10.793888, 165.8402, 63.2],
  [-10.794062, 165.840211, 63.8],
  [-10.794228, 165.840243, 64.4],
  [-10.794397, 165.840265, 65.2],
  [-10.794559, 165.840243, 65.6],
  [-10.794723, 165.840211, 65.6],
  [-10.794883, 165.8402, 65.6],
];

export default stream;
