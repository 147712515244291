import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.684039, 166.930765, 27],
  [-11.684078, 166.930915, 27.6],
  [-11.68415, 166.931065, 28],
  [-11.684227, 166.931205, 28.6],
  [-11.684299, 166.931355, 29.2],
  [-11.684313, 166.931505, 29.6],
  [-11.684208, 166.931634, 30],
  [-11.684061, 166.931688, 30.2],
  [-11.683913, 166.931752, 30.2],
  [-11.683787, 166.93187, 30.4],
  [-11.683672, 166.931977, 30.4],
  [-11.683588, 166.932138, 30.6],
  [-11.683571, 166.932299, 30.8],
  [-11.683606, 166.932471, 31],
  [-11.683656, 166.932632, 31.4],
  [-11.683696, 166.932803, 31.8],
  [-11.683708, 166.932975, 32.2],
  [-11.683678, 166.933136, 32.6],
  [-11.683625, 166.933297, 33.2],
  [-11.68356, 166.933447, 33.8],
  [-11.683497, 166.933597, 34.4],
  [-11.683446, 166.933769, 35],
  [-11.683444, 166.933919, 35.6],
  [-11.683513, 166.934069, 36.2],
  [-11.683629, 166.934198, 36.6],
  [-11.683771, 166.934273, 37.2],
  [-11.683929, 166.934252, 38],
  [-11.684069, 166.934198, 38.6],
  [-11.684211, 166.934112, 39.2],
  [-11.684365, 166.934091, 40],
  [-11.684522, 166.934069, 40.6],
  [-11.684681, 166.934037, 41.2],
  [-11.684825, 166.933984, 41.8],
  [-11.684956, 166.933887, 42.4],
  [-11.68508, 166.933801, 43.2],
  [-11.685199, 166.933694, 43.8],
  [-11.685328, 166.933597, 44.4],
  [-11.68547, 166.933533, 44.8],
  [-11.685626, 166.933554, 45],
  [-11.685773, 166.933608, 44.8],
  [-11.685925, 166.933672, 44.8],
  [-11.686088, 166.933662, 45],
  [-11.686244, 166.933597, 45.6],
  [-11.686398, 166.933554, 46.2],
  [-11.686563, 166.933554, 46.8],
  [-11.686723, 166.933565, 47.2],
  [-11.686884, 166.933608, 47.8],
  [-11.687032, 166.933662, 48.4],
  [-11.687152, 166.933769, 49],
  [-11.687184, 166.93393, 49.8],
  [-11.687165, 166.934091, 50.4],
  [-11.687148, 166.934252, 51],
  [-11.687215, 166.934391, 51.6],
  [-11.687344, 166.934488, 52.2],
  [-11.687494, 166.934531, 52.8],
  [-11.68764, 166.934488, 53.6],
  [-11.687766, 166.934391, 54.2],
  [-11.687918, 166.934359, 54.8],
  [-11.688053, 166.934434, 55.4],
  [-11.688166, 166.934541, 55.8],
  [-11.688256, 166.934681, 56],
  [-11.688306, 166.934831, 56.4],
  [-11.688296, 166.934981, 56.6],
  [-11.688253, 166.935153, 56.8],
  [-11.688211, 166.935303, 57.2],
  [-11.688183, 166.935475, 57.8],
  [-11.688109, 166.935614, 58.4],
  [-11.687971, 166.935689, 58.8],
  [-11.687814, 166.935732, 59.2],
  [-11.687676, 166.935829, 59.8],
  [-11.687621, 166.935979, 60],
  [-11.687584, 166.936129, 60.4],
  [-11.687478, 166.936258, 60.8],
  [-11.687327, 166.936312, 61.4],
  [-11.687172, 166.936355, 61.8],
  [-11.68701, 166.936387, 62.2],
  [-11.686847, 166.936408, 62.6],
  [-11.686685, 166.936408, 63.2],
  [-11.686528, 166.936376, 63.8],
  [-11.68637, 166.936333, 64.4],
  [-11.686214, 166.93628, 64.8],
  [-11.686057, 166.936269, 65.2],
  [-11.685897, 166.936312, 65.2],
  [-11.685745, 166.936355, 65.2],
  [-11.685588, 166.93644, 65.2],
  [-11.685437, 166.936494, 63.8],
  [-11.685273, 166.936569, 62.4],
  [-11.685104, 166.936644, 60.8],
  [-11.684931, 166.93673, 59.4],
  [-11.684754, 166.936816, 58.2],
  [-11.684574, 166.936891, 57.2],
  [-11.684389, 166.936988, 56.8],
  [-11.684209, 166.937063, 56.8],
  [-11.684035, 166.937138, 57.6],
  [-11.683858, 166.937213, 59],
  [-11.683689, 166.937288, 60.6],
  [-11.683528, 166.937363, 62.2],
  [-11.683371, 166.937428, 63.6],
  [-11.683222, 166.937492, 65],
  [-11.683071, 166.937567, 65.2],
  [-11.682921, 166.937631, 65.2],
  [-11.682768, 166.937706, 65.2],
  [-11.682613, 166.93776, 65.2],
  [-11.682457, 166.937835, 65.2],
  [-11.682306, 166.937921, 65.2],
  [-11.682214, 166.93805, 65.2],
  [-11.682206, 166.938232, 65.2],
  [-11.682215, 166.938404, 65.2],
  [-11.682171, 166.938576, 65],
  [-11.682031, 166.938683, 64.8],
  [-11.681868, 166.938758, 64.4],
  [-11.681759, 166.938897, 63.8],
  [-11.681645, 166.939026, 63.2],
  [-11.681479, 166.939101, 62.6],
  [-11.681301, 166.939155, 62.4],
  [-11.681111, 166.939187, 62],
  [-11.680925, 166.939198, 61.6],
  [-11.680748, 166.939133, 61.2],
  [-11.68061, 166.939015, 60.6],
  [-11.680544, 166.938833, 60.2],
  [-11.680579, 166.938651, 60.4],
  [-11.680602, 166.938468, 60.6],
  [-11.680529, 166.938286, 60.4],
  [-11.680421, 166.938146, 60],
  [-11.680268, 166.938039, 59.6],
  [-11.68008, 166.938039, 59],
  [-11.679906, 166.938114, 58.4],
  [-11.679735, 166.938211, 58],
  [-11.679575, 166.938318, 57.8],
  [-11.679434, 166.938436, 57.8],
];

export default stream;
