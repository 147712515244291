import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-10.758051, 165.834063, 68],
  [-10.757887, 165.834053, 68],
  [-10.757722, 165.83402, 68],
  [-10.757564, 165.833988, 68.2],
  [-10.757404, 165.833956, 68.2],
  [-10.757245, 165.833913, 68.2],
  [-10.757082, 165.833892, 68.4],
  [-10.756922, 165.833913, 68.6],
  [-10.756774, 165.833988, 69],
  [-10.756642, 165.834085, 69.4],
  [-10.756512, 165.834171, 69.8],
  [-10.756373, 165.834246, 70],
  [-10.756224, 165.834289, 70],
  [-10.756071, 165.834342, 70],
  [-10.755917, 165.834385, 70],
  [-10.755765, 165.834428, 70],
  [-10.75562, 165.834503, 70],
  [-10.755483, 165.834578, 70],
  [-10.755369, 165.834707, 70],
  [-10.755271, 165.834846, 70],
  [-10.755193, 165.834975, 70],
  [-10.75515, 165.835147, 70],
  [-10.755149, 165.835297, 70],
  [-10.755169, 165.835469, 70],
  [-10.755203, 165.83564, 70],
  [-10.755249, 165.835791, 70],
  [-10.755316, 165.835941, 70],
  [-10.755416, 165.836059, 70],
  [-10.755554, 165.836155, 70],
  [-10.755703, 165.836231, 70],
  [-10.755845, 165.836306, 70],
  [-10.755946, 165.836445, 70],
  [-10.755999, 165.836595, 70],
  [-10.756023, 165.836767, 70],
  [-10.756024, 165.836939, 70],
  [-10.755995, 165.837089, 70],
  [-10.755906, 165.837228, 70],
  [-10.755765, 165.837314, 69.8],
  [-10.755614, 165.837379, 69.8],
  [-10.755472, 165.837464, 70],
  [-10.75538, 165.837604, 70],
  [-10.755344, 165.837775, 70],
  [-10.755334, 165.837926, 70.2],
  [-10.755333, 165.838097, 70.4],
  [-10.755332, 165.838258, 70.4],
  [-10.755303, 165.83843, 70.6],
  [-10.755233, 165.838569, 70.8],
  [-10.755126, 165.838687, 71],
  [-10.754996, 165.838784, 71.4],
  [-10.754847, 165.838838, 71.6],
  [-10.754689, 165.838859, 72],
  [-10.754533, 165.838838, 72],
  [-10.754375, 165.838805, 72],
  [-10.754217, 165.838795, 72],
  [-10.754061, 165.838752, 72],
  [-10.753909, 165.838698, 72],
  [-10.753765, 165.838623, 72.2],
  [-10.75364, 165.838537, 72.4],
  [-10.75353, 165.838408, 72.6],
  [-10.75343, 165.83828, 72.8],
  [-10.753331, 165.838151, 73],
  [-10.753223, 165.838033, 73.4],
  [-10.753095, 165.837936, 73.6],
  [-10.752941, 165.837926, 74],
  [-10.752793, 165.837969, 74.2],
  [-10.75266, 165.838065, 74.4],
  [-10.752541, 165.838172, 74.6],
  [-10.752432, 165.83828, 74.8],
  [-10.752332, 165.838398, 75.2],
  [-10.752245, 165.838516, 75.6],
  [-10.752182, 165.838677, 76.2],
  [-10.752129, 165.838805, 76.6],
  [-10.752079, 165.838966, 77.2],
  [-10.752028, 165.839095, 77.8],
  [-10.751964, 165.839235, 78.4],
  [-10.75187, 165.839363, 79],
  [-10.751735, 165.839406, 79.6],
  [-10.751589, 165.839406, 80],
  [-10.751445, 165.839374, 80.2],
  [-10.751302, 165.839342, 80.6],
  [-10.751156, 165.83931, 80.8],
  [-10.75101, 165.839342, 81.2],
  [-10.750876, 165.839406, 81.6],
  [-10.750756, 165.839492, 82],
  [-10.750645, 165.839589, 82.6],
  [-10.750551, 165.839707, 83.2],
  [-10.750463, 165.839835, 83.8],
  [-10.750363, 165.839943, 84.4],
  [-10.750234, 165.839996, 85.4],
  [-10.750093, 165.839986, 86.2],
  [-10.749955, 165.839953, 87],
  [-10.749819, 165.839975, 87.8],
  [-10.7497, 165.840029, 88.6],
  [-10.749604, 165.840136, 89.4],
  [-10.749521, 165.840243, 90],
  [-10.749444, 165.840372, 90.6],
  [-10.749373, 165.840468, 91.2],
  [-10.749302, 165.840597, 91.8],
  [-10.749232, 165.840715, 92.2],
  [-10.749163, 165.840844, 92.8],
  [-10.749094, 165.840962, 93.4],
  [-10.749022, 165.841069, 93.8],
  [-10.748947, 165.841187, 94],
  [-10.748876, 165.841316, 93.8],
  [-10.748807, 165.841445, 93.8],
  [-10.748737, 165.841573, 93.6],
  [-10.748667, 165.841713, 93.6],
  [-10.748602, 165.841842, 93.6],
  [-10.748539, 165.842003, 93.6],
];

export default stream;
