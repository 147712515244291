import { LatLngAlt } from "../../../types.js";

const stream: LatLngAlt[] = [
  [-11.637381, 166.955495, 4.8],
  [-11.637571, 166.955527, 4.8],
  [-11.637754, 166.955527, 4.8],
  [-11.637941, 166.955527, 4.8],
  [-11.638132, 166.955527, 5],
  [-11.638321, 166.955506, 5],
  [-11.638468, 166.955398, 5],
  [-11.638482, 166.955216, 5.2],
  [-11.638424, 166.955034, 5.2],
  [-11.638338, 166.954873, 5.4],
  [-11.638185, 166.954776, 6],
  [-11.63801, 166.954819, 6.4],
  [-11.637908, 166.95498, 7],
  [-11.637905, 166.955162, 7.4],
  [-11.637913, 166.955345, 7.6],
  [-11.637921, 166.955527, 7.6],
  [-11.637931, 166.95572, 7.6],
  [-11.637936, 166.955903, 8.4],
  [-11.637935, 166.956085, 9.6],
  [-11.637935, 166.956267, 11.2],
  [-11.637947, 166.956439, 12.6],
  [-11.637986, 166.956611, 14.2],
  [-11.638061, 166.956761, 15.2],
  [-11.638175, 166.956879, 16],
  [-11.638324, 166.956857, 17.8],
  [-11.638413, 166.956729, 19.4],
  [-11.638476, 166.956578, 20.8],
  [-11.638531, 166.956428, 22.2],
  [-11.638581, 166.956289, 23.4],
  [-11.638631, 166.956139, 24.8],
  [-11.638678, 166.955978, 26],
  [-11.638725, 166.955849, 27.2],
  [-11.638771, 166.955688, 28.4],
  [-11.638818, 166.955559, 29.6],
  [-11.638867, 166.955398, 30.8],
  [-11.638919, 166.95527, 32],
  [-11.638975, 166.95513, 33.2],
  [-11.639042, 166.95498, 34.6],
  [-11.639132, 166.954873, 35.6],
  [-11.639261, 166.954776, 36.2],
];

export default stream;
